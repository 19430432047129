import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { useTheme } from '../../theming/useTheme';

const componentConfigurationKey = 'decorationLine';

interface DecorationLineProps {
  height?: number;
}

export const decorationLineHeight = 2;

export interface DecorationLineComponentThemeProps {
  cardDecorationColor: string;
}

const getDecorationLineStyle = (cardDecorationColor: string, height: number) => {
  return StyleSheet.create({
    style: {
      height: `${height}px`,
      width: '100%',
      backgroundColor: cardDecorationColor,
    },
  });
};

export const DecorationLine: React.FunctionComponent<DecorationLineProps> = props => {
  const { height = decorationLineHeight } = props;
  const { cardDecorationColor } = useTheme<DecorationLineComponentThemeProps>(componentConfigurationKey);
  const decorationLineStyle = getDecorationLineStyle(cardDecorationColor, height);
  return (<div className={css(decorationLineStyle.style)} />);
};
