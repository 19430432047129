import { RATIO } from 'mm-theme-configuration/dist/consts';
import { createImageSrc, CroppingData } from '../partials/image/image.utils';

export interface PageImage {
  host: string;
  path: string;
  cropping?: CroppingData;
  aspectRatio?: RATIO;
}

export const createImageAttributes = (pageImage: PageImage) => {
  const { host, aspectRatio = { x: 16, y: 9 }, cropping = null, path } = pageImage;
  const imageAspectRatioX = aspectRatio.x;
  const imageAspectRatioY = aspectRatio.y;
  const imageWidth = 1440;
  const drp = 1;
  const imageUrl = createImageSrc(host, path, imageWidth, aspectRatio, drp, cropping);
  const imageHeight = (Number(imageWidth) * imageAspectRatioY) / imageAspectRatioX;
  return {
    imageUrl,
    imageHeight,
    imageWidth,
  };
};
