import * as React from 'react';
import { StyleSheet } from 'aphrodite/no-important';
import { RecommendationCardPlaceHolder } from './RecommendationCardPlaceHolder';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';

export interface PostRecommendationsDataProps {
  sectionName: string;
  sectionCards: CardComponentDataProps[];
  onScrolledIntoView: () => void;
}

export interface PostRecommendationsThemeProps {
  baseUnit: BaseUnit;
}

export const useRecommendationScrollTrigger = (onScrolledIntoView: () => void) => {
  const wrapper = React.useRef(null);

  React.useEffect(() => {
    if (!wrapper.current || typeof IntersectionObserver === 'undefined') return;

    const options = {
      rootMargin: '0px 0px 100px 0px',
      threshold: 0.1,
    };

    const obsrever = new IntersectionObserver(e => {
      if (e[0].isIntersecting) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        obsrever.unobserve(wrapper.current!);
        onScrolledIntoView();
      }
    }, options);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    obsrever.observe(wrapper.current! as HTMLElement);
  }, [onScrolledIntoView]);

  return wrapper;
};

export const mapThemeProps = (theme: PostRecommendationsThemeProps) => {
  return {
    baseUnit: theme.baseUnit,
  };
};

export const getSectionStyle = (baseUnit: BaseUnit) => StyleSheet.create({
  section: {
    margin: `0 ${baseUnit}px`,
    [MEDIA_BREAKPOINTS.large]: {
      margin: `0 ${baseUnit}px 0 ${baseUnit + 80}px`,
    },
  },
});

const createArrayWithPlaceHoldersNumberLength = (numberOfPlaceHolders: number) => new Array(numberOfPlaceHolders).fill(0);

export const createPlaceholders = (numberOfPlaceHolders: number) => {
  return createArrayWithPlaceHoldersNumberLength(numberOfPlaceHolders).map((item, index) => <RecommendationCardPlaceHolder key={index} />);
};
