import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { MandatoryAdditionalStyle } from '../../components.utils';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { BlockWidths } from '../../components/pageLayouts/BlockTypes.utils';
import { createBlockStyle } from '../blockMutualStyle';
import { useTheme } from '../../../theming/useTheme';
import { ArticleBlockThemeProps, componentConfigurationKey } from '../ArticleBlock.theme';
import { Kasda } from '../../../Kasda/Kasda';

interface PlaybuzzComponentDataProps {
  html: string;
  dataId?: string;
  defaultBlocksWidths: BlockWidths;
}

const PlaybuzzScript = "(function(d,s,id){var js,fjs=d.getElementsByTagName(s)[0];if(d.getElementById(id))return;js=d.createElement(s);js.id=id;js.src='https://embed.playbuzz.com/sdk.js';fjs.parentNode.insertBefore(js,fjs);}(document,'script','playbuzz-sdk'));";

type PlaybuzzComponentProps = PlaybuzzComponentDataProps & MandatoryAdditionalStyle;
const getBlockStyle = (defaultBlocksWidths: BlockWidths, placeholderBackgroundColor: string) => {
  return StyleSheet.create({
    style: {
      ...createBlockStyle(defaultBlocksWidths),
      backgroundColor: placeholderBackgroundColor,
    },
  }).style;
};

export const PlaybuzzComponent: React.FunctionComponent<PlaybuzzComponentProps> = props => {
  const { html, additionalStyle, dataId, defaultBlocksWidths } = props;
  const { placeholderBackgroundColor } = useTheme<ArticleBlockThemeProps>(componentConfigurationKey);
  const style = getBlockStyle(defaultBlocksWidths, placeholderBackgroundColor);

  React.useEffect(() => {
    // @ts-ignore TS2339
    if (typeof window.Playbuzz !== 'undefined') {
      // @ts-ignore TS2339
      window.Playbuzz.render();
    }
  }, []);

  return (
    <ErrorBoundary>
      <Kasda.Script marker="playbuzz">
        <script>
          {PlaybuzzScript}
        </script>
      </Kasda.Script>
      {/* eslint-disable-next-line react/no-danger */}
      <figure data-mm-id={dataId} dangerouslySetInnerHTML={{ __html: html }} className={css(additionalStyle.style, style)} />
    </ErrorBoundary>
  );
};

export const Playbuzz = (props: PlaybuzzComponentProps) => <ErrorBoundary><PlaybuzzComponent {...props} /></ErrorBoundary>;
