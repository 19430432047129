import React from 'react';
import { HeaderHeights, Header, MainWrapperForNavigation, ThreeRowsHeader, Drawer } from 'mm-ui-components';
import { MainWrapperConfigThemeKey } from 'mm-ui-components/dist/src/base/wrappers/mainWrapper/mainWrapper.theme';
import { useSelector } from 'react-redux';
import { FooterConnected } from './FooterConnected';
import { getHeader, getLatestArticles, getSecondaryLogo, getSubHeader } from '../../store/navigations/navigation/header/header.selectors';
import { getMenu } from '../../store/navigations/navigation/menu/menu.selectors';
import { getEditionEndpoint, getPagePath, getPropertyEndpoint, getSiteName } from '../../store/config/config.selectors';
import { isPropertyForThreeRowsHeader, isSiProperty } from '../../store/config/config.utils';

interface NavigationLayoutProps {
  mainWrapperConfigThemeKey: MainWrapperConfigThemeKey;
  isFullBleed?: boolean;
  noMarginTop?: boolean;
}

const HeaderConnected: React.FunctionComponent = () => {
  const header = useSelector(getHeader);
  const menu = useSelector(getMenu);
  const pagePath = useSelector(getPagePath);
  const siteName = useSelector(getSiteName);
  const editionEndpoint = useSelector(getEditionEndpoint);
  const propertyEndpoint = useSelector(getPropertyEndpoint);
  const connectedComponent = isSiProperty(propertyEndpoint) || isPropertyForThreeRowsHeader(propertyEndpoint)
  ? <ThreeRowsHeader {...header} menu={menu} propertyEndpoint={propertyEndpoint} siteName={siteName} />
  : <Header {...header} menu={menu} pagePath={pagePath} editionEndpoint={editionEndpoint} />;
  return connectedComponent;
};

export const NavigationLayout: React.FunctionComponent<NavigationLayoutProps> = props => {
  const secondaryLogo = useSelector(getSecondaryLogo);
  const latestArticles = useSelector(getLatestArticles);
  const { children, mainWrapperConfigThemeKey, isFullBleed, noMarginTop = false } = props;
  const doesSubHeaderExist = !!useSelector(getSubHeader) && !secondaryLogo;

  return (
    <HeaderHeights doesSubHeaderExist={doesSubHeaderExist}>
      <HeaderConnected />
      {latestArticles && <Drawer articles={latestArticles} />}
      <MainWrapperForNavigation configThemeKey={mainWrapperConfigThemeKey} isFullBleed={isFullBleed} noMarginTop={noMarginTop}>
        {children}
      </MainWrapperForNavigation>
      <FooterConnected />
    </HeaderHeights>
  );
};
