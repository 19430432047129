import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { LogoProps } from '../../components/navigation/header/oldHeader/Logo';
import { Link } from '../../partials/link/Link';
import { HeaderHeightsPerBreakpoint } from '../../components/navigation/header/oldHeader/oldHeader.utils';
import { baseUnit } from '../../../theming/baseUnitDefinition';

const getLogoStyle = (logoHeights: HeaderHeightsPerBreakpoint) => {
  return StyleSheet.create({
    base: {
      height: `${logoHeights.small * baseUnit}px`,
      alignItems: 'center',
    },
    largeSvg: {
      [MEDIA_BREAKPOINTS.large]: {
        display: 'inline-flex',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        display: 'none',
      },
      [MEDIA_BREAKPOINTS.small]: {
        display: 'none',
      },
    },
    smallSvg: {
      [MEDIA_BREAKPOINTS.large]: {
        display: 'none',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        display: 'inline-flex',
      },
      [MEDIA_BREAKPOINTS.small]: {
        display: 'inline-flex',
      },
    },
    image: {
      height: '100%',
    },
  });
};

export const getSvgWidthAndHeight = (viewBoxValue: string) => {
  const viewBowDimensions = viewBoxValue.split(' ');
  return {
    width: Number(viewBowDimensions[2]),
    height: Number(viewBowDimensions[3]),
  };
};

const defaultLogoHeights = ({ large: 24, medium: 24, small: 24 });

export const AMPLogo: React.FunctionComponent<LogoProps> = props => {
  const { href, alt, svgLarge, svgSmall, svgLargeViewBox, svgSmallViewBox, logoHeights = defaultLogoHeights } = props;
  const style = getLogoStyle(logoHeights);
  const largeSvgWidthAndHeight = getSvgWidthAndHeight(svgLargeViewBox);
  const smallSvgWidthAndHeight = getSvgWidthAndHeight(svgSmallViewBox);
  return (
    <React.Fragment>
      <Link className={css(style.largeSvg, style.base)} href={href}>
        {/*
  // @ts-ignore TS2339: Property 'amp-img' does not exist on type 'JSX.IntrinsicElements'. */}
        <amp-img layout="fixed" class={css(style.image)} alt={alt} src={`data:image/svg+xml,${encodeURIComponent(svgLarge)}`} height={`${logoHeights.small * baseUnit}`} width={`${(logoHeights.small * baseUnit * largeSvgWidthAndHeight.width) / largeSvgWidthAndHeight.height}`} />
      </Link>
      <Link className={css(style.smallSvg, style.base)} href={href}>
        {/*
  // @ts-ignore TS2339: Property 'amp-img' does not exist on type 'JSX.IntrinsicElements'. */}
        <amp-img layout="fixed" class={css(style.image)} alt={alt} src={`data:image/svg+xml,${encodeURIComponent(svgSmall)}`} height={`${logoHeights.small * baseUnit}`} width={`${(logoHeights.small * baseUnit * smallSvgWidthAndHeight.width) / smallSvgWidthAndHeight.height}`} />
      </Link>
    </React.Fragment>
  );
};
