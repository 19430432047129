import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { useTheme } from '../../../../../../theming/useTheme';
import { ChevronIcon, Directions } from '../../ChevronIcon';
import { DropDownMenu } from '../DropDownMenu';
import { Span, semanticCSS } from '../../../../../../typography/semanticTags';
import { NavigationLink } from '../primaryHeader/PrimaryHeader';
import { componentConfigThemeKey, HeaderThemeProps } from '../../Header.theme';

export interface MoreLinksProps {
  moreLinksText: string;
  links: NavigationLink[];
  pagePath: string;
}

const getStyle = (titleColor: string, menuBackground: string) => {
  return StyleSheet.create({
    wrapper: {
      position: 'relative',
      height: '100%',
      display: 'flex',
    },
    button: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'transparent',
      border: 'none',
      outline: 'none',
    },
    buttonTitle: {
      color: titleColor,
      marginRight: '9px',
    },
    menuBody: {
      listStyle: 'none',
      width: '100%',
      position: 'absolute',
      backgroundColor: menuBackground,
    },
  });
};

export const MoreLinks: React.FunctionComponent<MoreLinksProps> = props => {
  const { moreLinksText, links, pagePath } = props;
  const { secondRowSubMenuBackgroundColor, secondRowLinkCurrentColor, secondRowLinkFontSizeLarge, secondRowLinkFontSizeMedium, secondRowLinkFontSizeSmall } = useTheme<HeaderThemeProps>(componentConfigThemeKey);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const toggleMenu = React.useCallback(() => {
    setIsMenuOpen(!isMenuOpen);
  }, [isMenuOpen]);


  const style = getStyle(secondRowLinkCurrentColor, secondRowSubMenuBackgroundColor);
  const chevronDirection = isMenuOpen ? Directions.UP : Directions.DOWN;

  return (
    <div className={css(style.wrapper)}>
      <button type="button" className={css(style.button)} onClick={toggleMenu}>
        <Span styles={semanticCSS(style.buttonTitle)} fontSizeLarge={secondRowLinkFontSizeLarge} fontSizeMedium={secondRowLinkFontSizeMedium} fontSizeSmall={secondRowLinkFontSizeSmall}>{moreLinksText}</Span>
        <ChevronIcon direction={chevronDirection} />
      </button>
      {
        isMenuOpen && <DropDownMenu configThemeKey="moreLinks" menuLinks={links} highlightActive activeEndpoint={pagePath} />
      }
    </div>
  );
};
