import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { AdditionalStyleSheet } from '../../components.utils';

export type SVGComponent = React.FunctionComponent<
  React.SVGProps<SVGSVGElement>
>;

export interface IconProps {
  icon: SVGComponent;
  additionalStyle?: AdditionalStyleSheet;
  height?: number;
  width?: number;
  hasButton?: boolean;
  onClick?: () => void;
}

const getIconStyle = (hasButton: boolean) => {
  return StyleSheet.create({
    icon: {
      cursor: hasButton ? 'pointer' : 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      background: 'unset',
      border: 'unset',
      padding: '0px',
    },
  });
};

export const Icon: React.FunctionComponent<IconProps> = props => {
  const {
    icon: SVGIcon,
    additionalStyle,
    height = 22,
    width = 22,
    hasButton = true,
    onClick,
  } = props;
  const iconStyles = getIconStyle(hasButton);

  return (
    <>
      {hasButton ? (
        <button
          type="button"
          onClick={onClick}
          className={css(iconStyles.icon)}
        >
          <SVGIcon
            height={height}
            width={width}
            className={css(additionalStyle?.style)}
          />
        </button>
      ) : (
        <div className={css(iconStyles.icon)}>
          <SVGIcon
            height={height}
            width={width}
            className={css(additionalStyle?.style)}
          />
        </div>
      )}
    </>
  );
};
