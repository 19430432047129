import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { ImageStyleSheet } from '../image.types';

const placeholderStyle = StyleSheet.create({
  style: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    transition: 'opacity 0.2s ease-in-out',
    willChange: 'opacity, transform',
  },
  initial: {
    opacity: 1,
  },
  ready: {
    opacity: 0,
  },
}) as ImageStyleSheet;

export const defaultBlurRadius = 40;

interface BlurredImageComponentProps {
  src: string | undefined;
  blur: string | number;
  getRefCallback?: (ref: React.RefObject<HTMLCanvasElement>) => void;
  status: string;
}

interface BlurredImageComponentState {
  shouldShowCanvas: boolean;
}

export class BlurredImageComponent extends React.Component<BlurredImageComponentProps, BlurredImageComponentState> {
  img: any;

  canvasRef: React.RefObject<HTMLCanvasElement>;

  constructor(props: BlurredImageComponentProps) {
    super(props);
    this.state = { shouldShowCanvas: true };
    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    this.createImage();

    const { getRefCallback } = this.props;
    if (typeof getRefCallback === 'function') {
      getRefCallback(this.canvasRef);
    }
  }

  componentWillUnmount() {
    if (this.img) {
      this.img.onload = null;
    }
  }

  getCanvas(): any {
    return this.canvasRef.current;
  }

  getDimensions() {
    return this.getCanvas().getBoundingClientRect();
  }

  setCanvasDimensions(width: number, height: number) {
    this.getCanvas().width = width;
    this.getCanvas().height = height;
  }

  drawImageOnCanvas = (event: React.ChangeEvent) => {
    const { blur } = this.props;
    const ctx = this.getCanvas().getContext('2d');
    const { width, height } = this.getDimensions();
    this.setCanvasDimensions(width, height);
    ctx.filter = `blur(${blur}px)`;
    ctx.drawImage(event.target, 0, 0, width, height);
  };

  removeCanvas = () => {
    this.setState({ shouldShowCanvas: false });
  };


  createImage() {
    const { src } = this.props;
    this.img = new (window as any).Image();
    this.img.onload = this.drawImageOnCanvas;
    this.img.src = src;
  }

  render() {
    const { status } = this.props;
    const { shouldShowCanvas } = this.state;
    return shouldShowCanvas ? <canvas className={css(placeholderStyle.style, placeholderStyle[status])} ref={this.canvasRef} onTransitionEnd={this.removeCanvas} /> : null;
  }
}

export const BlurredImage = BlurredImageComponent;
