import React from 'react';
import { ALIGNMENT, ALIGNMENT_OPTIONS, TypographySize } from 'mm-theme-configuration/dist/consts';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useTheme } from '../../theming/useTheme';
import { getStyleBySize } from '../../typography/semanticTags/semanticTags.utils';
import { TranslatedFontSize } from '../../theming/fontSizeTranslator';
import { MEDIA_BREAKPOINTS } from '../../mediaQueries.const';
import { BaseUnit } from '../../theming/baseUnitDefinition';
import { BlockWidths } from '../components/pageLayouts/BlockTypes.utils';
import { createBlockStyle } from './blockMutualStyle';
import { createBlockStyleSheet } from '../components/pageLayouts/utils';
import { ArticleContentThemeProps, componentConfigurationKey as componentConfigurationKeyForArticleContent } from '../components/pageLayouts/ArticleContent';

const componentConfigurationKey = 'articleDisclaimer';

interface DisclaimerProps {
  text: string;
  defaultBlocksWidths: BlockWidths;
}

interface DisclaimerThemeProps {
  textColor: string;
  dividersColor: string;
  fontSizeLarge: TypographySize;
  fontSizeMedium: TypographySize;
  fontSizeSmall: TypographySize;
  showDividersInLargeScreen: boolean;
  showDividersInMediumScreen: boolean;
  showDividersInSmallScreen: boolean;
  horizontalGapInLargeScreen: number;
  horizontalGapInMediumScreen: number;
  horizontalGapInSmallScreen: number;
  textAlignment: ALIGNMENT;
  baseUnit: BaseUnit;
  fontSizesStyles: TranslatedFontSize;
}

const getWrapperStyle = (
  {
    textColor,
    dividersColor,
    showDividersInSmallScreen,
    showDividersInMediumScreen,
    showDividersInLargeScreen,
    horizontalGapInLargeScreen,
    horizontalGapInMediumScreen,
    horizontalGapInSmallScreen,
    textAlignment,
    baseUnit,
  }: DisclaimerThemeProps,
) => {
  const dividersStyleForBeforeAndAfterPseudoElements = {
    content: '""',
    display: 'block',
    borderTop: `1px solid ${dividersColor}`,
  };
  return StyleSheet.create({
    style: {
      color: textColor,
      fontStyle: 'italic',
      display: 'block',
      textAlign: textAlignment === ALIGNMENT_OPTIONS.CENTER ? 'center' : 'left',
      [MEDIA_BREAKPOINTS.large]: showDividersInLargeScreen ? {
        ':before': {
          ...dividersStyleForBeforeAndAfterPseudoElements,
          marginBottom: `${horizontalGapInLargeScreen * baseUnit}px`,
        },
        ':after': {
          ...dividersStyleForBeforeAndAfterPseudoElements,
          marginTop: `${horizontalGapInLargeScreen * baseUnit}px`,
        },
      }
        : {
          padding: `${horizontalGapInLargeScreen * baseUnit}px 0`,
        },
      [MEDIA_BREAKPOINTS.medium]: showDividersInMediumScreen ? {
        ':before': {
          ...dividersStyleForBeforeAndAfterPseudoElements,
          marginBottom: `${horizontalGapInMediumScreen * baseUnit}px`,
        },
        ':after': {
          ...dividersStyleForBeforeAndAfterPseudoElements,
          marginTop: `${horizontalGapInMediumScreen * baseUnit}px`,
        },
      }
        : {
          padding: `${horizontalGapInMediumScreen * baseUnit}px 0`,
        },
      [MEDIA_BREAKPOINTS.small]: showDividersInSmallScreen ? {
        ':before': {
          ...dividersStyleForBeforeAndAfterPseudoElements,
          marginBottom: `${horizontalGapInSmallScreen * baseUnit}px`,
        },
        ':after': {
          ...dividersStyleForBeforeAndAfterPseudoElements,
          marginTop: `${horizontalGapInSmallScreen * baseUnit}px`,
        },
      }
        : {
          padding: `${horizontalGapInSmallScreen * baseUnit}px 0`,
        },
    },
  }).style;
};

const getDefaultBlocksWidthsStyle = (defaultBlocksWidths: BlockWidths) => {
  return StyleSheet.create({
    style: {
      ...createBlockStyle(defaultBlocksWidths),
    },
  }).style;
};
export const Disclaimer: React.FunctionComponent<DisclaimerProps> = props => {
  const { text, defaultBlocksWidths } = props;
  const theme = useTheme<DisclaimerThemeProps>(componentConfigurationKey);
  const {
    fontSizeLarge,
    fontSizeMedium,
    fontSizeSmall,
    fontSizesStyles,
    baseUnit,
  } = theme;
  const { marginBetweenBlocksFactor } = useTheme<ArticleContentThemeProps>(componentConfigurationKeyForArticleContent);
  const wrapperStyle = getWrapperStyle(theme);
  const textStyle = getStyleBySize(fontSizesStyles, { fontSizeSmall, fontSizeMedium, fontSizeLarge });
  const defaultBlocksWidthsStyle = getDefaultBlocksWidthsStyle(defaultBlocksWidths);

  return (
    <small
      className={css(wrapperStyle, textStyle, createBlockStyleSheet(baseUnit, marginBetweenBlocksFactor).style, defaultBlocksWidthsStyle)}
    >
      {text}
    </small>
  );
};
