import { TypographySize } from 'mm-theme-configuration/dist/consts';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { TranslatedFontSize } from '../../../theming/fontSizeTranslator';

export const componentConfigurationKey = 'image';

export interface ImageThemeProps {
  iconColor: string;
  showIcon: boolean;
  textColor: string;
  showBorderBottom: boolean;
  borderBottomColor: string;
  fontSizeLarge: TypographySize;
  fontSizeMedium: TypographySize;
  fontSizeSmall: TypographySize;
  baseUnit: BaseUnit;
  fontSizesStyles: TranslatedFontSize;
  captionPaddingFactor: number;
  backgroundColor: string;
}
