const HOURS_AGO = 'Hours Ago';
const MINUTES_AGO = 'Minutes Ago';

const ONE_HOUR_AGO = '1 Hour Ago';
const ONE_MINUTE_AGO = '1 Minute Ago';

export const getPublishDateOrHoursDisplay = (publishDateISO: string, publishDateFormatted: string) => {
  const publishedDate = new Date(publishDateISO);
  const publishedDateSecondsDiff = (new Date().getTime() - publishedDate.getTime()) / 1000;
  const publisehdDateMinutesDiff = Math.round(publishedDateSecondsDiff / 60);
  const publisehdDateHoursDiff = Math.round(publishedDateSecondsDiff / (60 * 60));

  if (publisehdDateMinutesDiff < 60) {
    // minutes
    const minutesDiff = Math.round(publishedDateSecondsDiff / 60);
    if (minutesDiff === 1) {
      return ONE_MINUTE_AGO;
    }
    return `${minutesDiff} ${MINUTES_AGO}`;
  }

  if (publisehdDateHoursDiff === 1) {
    return ONE_HOUR_AGO;
  }

  if (publisehdDateHoursDiff < 24) {
    // hours
    return `${publisehdDateHoursDiff} ${HOURS_AGO}`;
  }
  return publishDateFormatted;
};

export const getFormattedPublishDate = (publishDateISO: string, publishDateFormatted: string, useFormatMinutesHoursAgo = false) => {
  return useFormatMinutesHoursAgo ? getPublishDateOrHoursDisplay(publishDateISO, publishDateFormatted) : publishDateFormatted;
};

export const sameDate = (date1: string, date2: string) => {
  if (date1 && date2) {
    return new Date(date1).toLocaleDateString() === new Date(date2).toLocaleDateString();
  }

  return false;
};
