import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { TypographySize } from 'mm-theme-configuration/src/consts';
import { Link } from '../../partials/link/Link';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { useTheme } from '../../../theming/useTheme';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { P } from '../../../typography/semanticTags/P';
import { semanticCSS } from '../../../typography/semanticTags';
import { Thumbnail } from '../../partials/image/image.types';
import { createImageWidths } from '../../partials/image/image.utils';
import { getStyleBySize } from '../../../typography/semanticTags/semanticTags.utils';
import { TranslatedFontSize } from '../../../theming/fontSizeTranslator';
import { AuthorImage } from './AuthorImage/AuthorImage';
import { getFormattedPublishDate } from '../../../utils/time.utils';
import { shouldDisplayUpdatedAt } from './author.utils';

export const componentConfigurationKey = 'HoverAuthorsWithRoundImage';

const largeScreenImageSize = 80;
const smallScreenImageSize = 50;

interface Author {
  name: string;
  byline?: string;
  link?: string;
  image?: Thumbnail | null;
}

interface HoverAuthorsWithRoundImageProps {
  authors: Author[] | null;
  updatedText: string;
  createdDateFormatted: string;
  createdTimeAndDateFormatted: string;
  createdAtISO: string;
  updatedDateFormatted: string;
  updatedTimeAndDateFormatted: string;
  updatedAtISO: string;
  showUpdatedAt: boolean;
  marginTopFactor?: number;
  useFormatMinutesHoursAgo?: boolean;
}

interface HoverAuthorsWithRoundImageThemeProps {
  authorColor: string;
  dateColor: string;
  bylineColor: string;
  separatorColor: string;
  baseUnit: BaseUnit;
  authorFontSizeLarge: TypographySize;
  authorFontSizeMedium: TypographySize;
  authorFontSizeSmall: TypographySize;
  dateFontSizeLarge: TypographySize;
  dateFontSizeMedium: TypographySize;
  dateFontSizeSmall: TypographySize;
  bylineFontSizeLarge: TypographySize;
  bylineFontSizeMedium: TypographySize;
  bylineFontSizeSmall: TypographySize;
  showTime: boolean;
  showTimestamp: boolean;
  fontSizesStyles: TranslatedFontSize;
}

const getStyles = (baseUnit: BaseUnit, marginTopFactor?: number) => {
  return StyleSheet.create({
    wrapper: {
      display: 'grid',
      [MEDIA_BREAKPOINTS.large]: {
        width: '160px',
        position: 'absolute',
        left: `${baseUnit}px`,
        gridRowGap: `${baseUnit * 2}px`,
        marginTop: marginTopFactor ? `${marginTopFactor * baseUnit}px` : 'unset',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        width: 'unset',
        position: 'relative',
        left: 'unset',
        transform: 'unset',
        gridTemplateColumns: '1fr 1fr',
        justifyItems: 'center',
        gridRowGap: `${baseUnit}px`,
        maxWidth: '360px',
        margin: 'auto',
        marginTop: `${baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        width: 'unset',
        position: 'relative',
        left: 'unset',
        transform: 'unset',
        gridTemplateColumns: '1fr 1fr',
        justifyItems: 'center',
        gridRowGap: `${baseUnit}px`,
        maxWidth: '260px',
        margin: 'auto',
        marginTop: `${baseUnit}px`,
      },
    },
  });
};

const getImageStyle = () => {
  return StyleSheet.create({
    oddLink: {
      [MEDIA_BREAKPOINTS.medium]: {
        gridColumn: '1 / 3',
      },
      [MEDIA_BREAKPOINTS.small]: {
        gridColumn: '1 / 3',
      },
    },
    link: {
      display: 'grid',
      justifyItems: 'center',
      [MEDIA_BREAKPOINTS.large]: {
        gridTemplateRows: `${largeScreenImageSize}px auto`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        gridTemplateRows: `${smallScreenImageSize}px auto`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        gridTemplateRows: `${smallScreenImageSize}px auto`,
      },
    },
  });
};

const getTextsStyle = (
  baseUnit: BaseUnit,
  authorColor: string,
  dateColor: string,
  bylineColor: string,
  separatorColor: string,
) => StyleSheet.create({
  authorText: {
    textAlign: 'center',
    color: authorColor,
    [MEDIA_BREAKPOINTS.large]: {
      marginTop: `${baseUnit / 2}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      marginTop: `${baseUnit / 2}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      marginTop: `${baseUnit / 4}px`,
    },
  },
  date: {
    display: 'block',
    textAlign: 'center',
    color: dateColor,
  },
  byline: {
    textAlign: 'center',
    color: bylineColor,
  },
  separator: {
    height: '1px',
    width: '100%',
    backgroundColor: separatorColor,
    margin: `${baseUnit / 4}px auto ${baseUnit / 4}px`,
  },
});

const getAuthorTexts = (
  name: string,
  byline: string | undefined,
  updatedText: string,
  createdDate: string,
  createdAtISO: string,
  updatedDate: string,
  updatedAtISO: string,
  showUpdatedAt: boolean,
  showDate: boolean,
  themeProps: HoverAuthorsWithRoundImageThemeProps,
  useFormatMinutesHoursAgo?: boolean,
) => {
  const {
    baseUnit,
    authorColor,
    dateColor,
    bylineColor,
    separatorColor,
    authorFontSizeLarge,
    authorFontSizeSmall,
    authorFontSizeMedium,
    dateFontSizeSmall,
    dateFontSizeMedium,
    dateFontSizeLarge,
    bylineFontSizeLarge,
    bylineFontSizeMedium,
    bylineFontSizeSmall,
    fontSizesStyles,
  } = themeProps;
  const hasByline = typeof byline === 'string' && byline.trim().length > 0;
  const hasSeparator = hasByline && showDate;
  const styles = getTextsStyle(
    baseUnit,
    authorColor,
    dateColor,
    bylineColor,
    separatorColor,
  );
  const fontSizes = {
    fontSizeLarge: dateFontSizeLarge,
    fontSizeMedium: dateFontSizeMedium,
    fontSizeSmall: dateFontSizeSmall,
  };

  return (
    <div>
      <P
        styles={semanticCSS(styles.authorText)}
        fontSizeLarge={authorFontSizeLarge}
        fontSizeMedium={authorFontSizeMedium}
        fontSizeSmall={authorFontSizeSmall}
      >
        {name}
      </P>
      {hasByline && (
        <P
          styles={semanticCSS(styles.byline)}
          fontSizeLarge={bylineFontSizeLarge}
          fontSizeMedium={bylineFontSizeMedium}
          fontSizeSmall={bylineFontSizeSmall}
        >
          {byline}
        </P>
      )}
      {hasSeparator && <div className={css(styles.separator)} />}
      {showDate ? (
        <time
          dateTime={createdAtISO}
          className={css(styles.date, getStyleBySize(fontSizesStyles, fontSizes))}
        >
          {getFormattedPublishDate(createdAtISO, createdDate, useFormatMinutesHoursAgo)}
        </time>
      ) : null}
      {showDate && shouldDisplayUpdatedAt(showUpdatedAt, updatedDate, createdDate) ? (
        <time
          dateTime={updatedAtISO}
          className={css(styles.date, getStyleBySize(fontSizesStyles, fontSizes))}
        >
          {` | ${updatedText} ${getFormattedPublishDate(updatedAtISO, updatedDate, useFormatMinutesHoursAgo)}`}
        </time>
      ) : null}
    </div>
  );
};

const getAuthors = (
  authors: Author[],
  createdDate: string,
  createdAtISO: string,
  updatedDate: string,
  updatedAtISO: string,
  updatedText: string,
  showUpdatedAt: boolean,
  themeProps: HoverAuthorsWithRoundImageThemeProps,
  useFormatMinutesHoursAgo?: boolean,
) => {
  const isOddList = authors.length % 2 !== 0;
  const styles = getImageStyle();
  const imageWidths = createImageWidths(50, 50, 80);

  const getLinkClass = (index: number) => {
    return isOddList && index === 0 ? css(styles.link, styles.oddLink) : css(styles.link);
  };

  return authors.map((author: Author, index: number) => {
    const isFirstAuthor = index === 0;
    const showTimestampForAuthor = themeProps.showTimestamp && isFirstAuthor;
    return (
      <Link key={index} href={author.link} className={getLinkClass(index)}>
        {author.image && (
          <AuthorImage
            widthSizes={{ small: smallScreenImageSize, medium: smallScreenImageSize, large: largeScreenImageSize }}
            image={author.image}
            imageWidths={imageWidths}
          />
        )}
        {getAuthorTexts(
          author.name,
          author.byline,
          updatedText,
          createdDate,
          createdAtISO,
          updatedDate,
          updatedAtISO,
          showUpdatedAt,
          showTimestampForAuthor,
          themeProps,
          useFormatMinutesHoursAgo,
        )}
      </Link>
    );
  });
};

export const HoverAuthorsWithRoundImage: React.FunctionComponent<
  HoverAuthorsWithRoundImageProps
> = props => {
  const {
    authors,
    updatedText,
    showUpdatedAt,
    createdDateFormatted,
    createdTimeAndDateFormatted,
    createdAtISO,
    updatedDateFormatted,
    updatedTimeAndDateFormatted,
    updatedAtISO,
    marginTopFactor,
    useFormatMinutesHoursAgo,
  } = props;
  const themeProps = useTheme<HoverAuthorsWithRoundImageThemeProps>(
    componentConfigurationKey,
  );
  const { baseUnit, showTime } = themeProps;
  const styles = getStyles(baseUnit, marginTopFactor);
  const updatedAtFormatted = showTime ? updatedTimeAndDateFormatted : updatedDateFormatted;
  const createdAtFormatted = showTime ? createdTimeAndDateFormatted : createdDateFormatted;

  return (
    <div className={css(styles.wrapper)}>
      {authors
        && getAuthors(
          authors,
          createdAtFormatted,
          createdAtISO,
          updatedAtFormatted,
          updatedAtISO,
          updatedText,
          showUpdatedAt,
          themeProps,
          useFormatMinutesHoursAgo,
        )}
    </div>
  );
};
