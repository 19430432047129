import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { RATIO, TypographySize } from 'mm-theme-configuration/dist/consts';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { OnTopCard, OnTopCardComponentThemeProps, componentConfigThemeKey as onTopCardConfigThemeKey } from '../../partials/cards/onTopCard/OnTopCard';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { createImageWidths, createImageHeightCalculationMethods } from '../../partials/image/image.utils';
import { HorizontalCard } from '../../partials/cards/horizontalCard/HorizontalCard';
import { componentConfigThemeKey as horizontalCardConfigThemeKey, HorizontalCardThemeProps } from '../../partials/cards/horizontalCard/horizontalCard.theme';
import { useTheme } from '../../../theming/useTheme';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { getLayoutComponentPadding, LayoutComponentBaseProps } from '../pageLayouts/layout.utils';

interface OneOnTopThreeHorizontalArticleListDataProps extends LayoutComponentBaseProps {
  cards: Array<CardComponentDataProps>;
}
export const componentConfigThemeKey = 'oneOTCThreeHC';

export interface OneOTCThreeHCThemeProps {
  titleFontSizeLarge: TypographySize;
  titleFontSizeMedium: TypographySize;
  titleFontSizeSmall: TypographySize;
  OTCTitleFontSizeSmall: TypographySize;
  metadataFontSizeLarge: TypographySize;
  metadataFontSizeMedium: TypographySize;
  metadataFontSizeSmall: TypographySize;
  authorColor: string;
  showDescriptionInLargeScreen: boolean;
  showDescriptionInMediumScreen: boolean;
  insideCardSmallHorizontalGap: number;
  aspectRatioSmallForHC: RATIO;
  aspectRatioLargeForHC: RATIO;
}
const imageWidthsOnTopCard = createImageWidths(360, 720, 540);
const imageWidthsHorizontalCard = createImageWidths(180, 180, 180);

const getStyles = (baseUnit: BaseUnit) => {
  return StyleSheet.create({
    section: {
      [MEDIA_BREAKPOINTS.large]: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridColumnGap: `${0.5 * baseUnit}px`,
      },
    },
    horizontalCards: {
      display: 'grid',
      gridTemplateRows: '1fr 1fr 1fr',
      gridRowGap: `${0.5 * baseUnit}px`,
      [MEDIA_BREAKPOINTS.large]: {
        marginTop: 0,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        marginTop: `${0.5 * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        marginTop: `${0.5 * baseUnit}px`,
        paddingLeft: `${0.5 * baseUnit}px`,
        paddingRight: `${0.5 * baseUnit}px`,
      },
    },
  });
};

export const OneOnTopThreeHorizontalArticleList: React.FunctionComponent<OneOnTopThreeHorizontalArticleListDataProps> = props => {
  const { cards, paddingFactors = { large: 0.5, medium: 0.5, small: 0 } } = props;
  const { baseUnit } = useTheme();
  const styles = getStyles(baseUnit);
  const paddingStyle = getLayoutComponentPadding(paddingFactors);

  const onTopCardHeightCalculation = createImageHeightCalculationMethods('ASPECT_RATIO', 'ASPECT_RATIO', '100%_HEIGHT');

  const themeProps = useTheme<OneOTCThreeHCThemeProps>(componentConfigThemeKey);
  const { titleFontSizeLarge,
    titleFontSizeMedium,
    titleFontSizeSmall,
    OTCTitleFontSizeSmall,
    metadataFontSizeLarge,
    metadataFontSizeMedium,
    metadataFontSizeSmall,
    authorColor,
    showDescriptionInLargeScreen,
    showDescriptionInMediumScreen,
    insideCardSmallHorizontalGap,
    aspectRatioSmallForHC,
    aspectRatioLargeForHC } = themeProps;
  const themeContextHC = useThemeOverride<HorizontalCardThemeProps>({
    titleFontSizeLarge,
    titleFontSizeMedium,
    titleFontSizeSmall,
    metadataFontSizeLarge,
    metadataFontSizeMedium,
    metadataFontSizeSmall,
    showDescriptionInMediumScreen,
    showDescriptionInLargeScreen,
    aspectRatioSmall: aspectRatioSmallForHC,
    aspectRatioLarge: aspectRatioLargeForHC,
  }, horizontalCardConfigThemeKey);
  const themeContextOTC = useThemeOverride<OnTopCardComponentThemeProps>(
    { titleFontSizeSmall: OTCTitleFontSizeSmall,
      horizontalGapSmall: insideCardSmallHorizontalGap,
      authorColor }, onTopCardConfigThemeKey,
  );

  return (
    <ErrorBoundary>
      {cards && cards.length > 0
      && (
      <div className={css(styles.section, paddingStyle)}>
        <ThemeContext.Provider value={themeContextOTC}>
          <OnTopCard {...cards[0]} imageWidths={imageWidthsOnTopCard} imageHeightCalculationMethods={onTopCardHeightCalculation} lazyLoad />
        </ThemeContext.Provider>
        <div className={css(styles.horizontalCards)}>
          <ThemeContext.Provider value={themeContextHC}>
            <HorizontalCard {...cards[1]} imageWidths={imageWidthsHorizontalCard} />
            <HorizontalCard {...cards[2]} imageWidths={imageWidthsHorizontalCard} />
            <HorizontalCard {...cards[3]} imageWidths={imageWidthsHorizontalCard} />
          </ThemeContext.Provider>
        </div>
      </div>
      )}
    </ErrorBoundary>
  );
};
