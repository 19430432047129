import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { useTheme } from '../../../theming/useTheme';
import { semanticCSS, H3, P, H4 } from '../../../typography/semanticTags';
import { Link } from '../../partials/link/Link';
import { componentConfigThemeKey } from './About.theme';
import { LINKS, SUB_TITLES, TEXT } from './About.strings';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';

interface AboutContactThemeProps {
  subHeadlineColor: string;
  textColor: string;
  paragraphLinkColor: string;
}

const getStyles = (subHeadlineColor: string, textColor: string, paragraphLinkColor: string) => {
  return StyleSheet.create({
    container: {
      marginBottom: '40px',
      [MEDIA_BREAKPOINTS.small]: {
        marginBottom: '16px',
      },
    },
    headline: {
      marginBottom: '8px',
      color: subHeadlineColor,
      [MEDIA_BREAKPOINTS.small]: {
        color: paragraphLinkColor,
      },
    },
    subHeadline: {
      color: subHeadlineColor,
      marginBottom: '8px',
    },
    text: {
      color: textColor,
    },
    contactText: {
      color: textColor,
      marginBottom: '8px',
    },
    link: {
      color: paragraphLinkColor,
    },
  });
};

export const AboutContact = () => {
  const { subHeadlineColor, textColor, paragraphLinkColor } = useTheme<AboutContactThemeProps>(componentConfigThemeKey);
  const { WRITE, ABOUT_FANSIDED, CONTACT } = SUB_TITLES;
  const { OPENINGS, CONTACT_LINK, LEGAL } = LINKS;
  const { SUB_TITLE, AD, WRITE_US } = TEXT.CONTACT;
  const styles = getStyles(subHeadlineColor, textColor, paragraphLinkColor);
  return (
    <ErrorBoundary>
      <div className={css(styles.container)}>
        <H3 styles={semanticCSS(styles.headline)}>{WRITE}</H3>
        <P styles={semanticCSS(styles.text)}>
          {TEXT.WRITE}
          <Link href={OPENINGS.LINK} className={css(styles.link)}>{OPENINGS.TEXT}</Link>
          {TEXT.WRITE_AFTER}
        </P>
      </div>
      <div className={css(styles.container)}>
        <H3 styles={semanticCSS(styles.headline)}>{ABOUT_FANSIDED}</H3>
        <P styles={semanticCSS(styles.text)}>{TEXT.ABOUT_FANSIDED}</P>
      </div>
      <div className={css(styles.container)}>
        <H3 styles={semanticCSS(styles.headline)}>{CONTACT}</H3>
        <H4 styles={semanticCSS(styles.subHeadline)}>{SUB_TITLE}</H4>
        <P styles={semanticCSS(styles.contactText)}>
          {AD}
          <Link href={`mailto: ${CONTACT_LINK.TEXT}`} className={css(styles.link)}>{CONTACT_LINK.TEXT}</Link>
          {WRITE_US}
        </P>
        <P>
          <Link href={LEGAL.LINK} className={css(styles.link)}>{LEGAL.TEXT}</Link>
        </P>
      </div>
    </ErrorBoundary>
  );
};
