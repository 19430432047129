import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { CSSTransition } from 'react-transition-group';
import { MenuLink } from '../../oldHeader/primaryHeader/menu/links/MenuLink';
import { LinkButtonDataProps } from '../../../utils';
import { getActiveEdition } from '../../oldHeader/primaryHeader/Editions';
import { BaseUnit } from '../../../../../../theming/baseUnitDefinition';
import { MEDIA_BREAKPOINTS } from '../../../../../../mediaQueries.const';
import {
  linkEnterDelay,
  linkEnterDuration, linkEnterTimeOut, linkExitDelay, linkExitDuration, linkExitTimeOut,
  menuEnterDuration, menuEnterTimeOut,
  menuExitDelay,
  menuExitDuration, menuExitTimeOut,
} from '../../oldHeader/primaryHeader/menu/links/menuLinks.utils';
import { MenuButton } from '../../oldHeader/primaryHeader/menu/links/MenuButton';
import { useTheme } from '../../../../../../theming/useTheme';
import { ErrorBoundary } from '../../../../../errorHandling/ErrorBoundary';
import { HeaderHeightsPerBreakpoint } from '../../oldHeader/oldHeader.utils';
import { NavigationLink } from '../../oldHeader/primaryHeader/PrimaryHeader';
import { MenuLinkDataProps } from '../../oldHeader/primaryHeader/menu/links/MenuSubLink';
import { useFirstRowHeaderHeights } from '../../useHeaderHeights';
import { componentConfigThemeKey, HeaderThemeProps } from '../../Header.theme';

export interface MenuLinksDataProps {
  menuLinks: MenuLinkDataProps[];
  buttons?: Array<LinkButtonDataProps>;
  isFixedHeights?: boolean;
}

export interface MenuLinksThemeProps {
  backgroundColor: string;
  baseUnit: BaseUnit;
}

interface MenuLinksLogicProps {
  isMenuOpen: boolean;
  onClick: (index: number) => void;
  currentOpenLink: number;
}

const getMenuLinksStyle = (backgroundColor: string, baseUnit: BaseUnit, headerHeights: HeaderHeightsPerBreakpoint, isFixedHeights: boolean) => StyleSheet.create({
  style: {
    padding: 0,
    margin: 0,
    backgroundColor,
    position: 'fixed',
    width: '100%',
    zIndex: 2,
    left: 0,
    overflowY: 'auto',
    [MEDIA_BREAKPOINTS.medium]: {
      top: isFixedHeights ? '88px' : `${headerHeights.medium * baseUnit}px`,
      height: `calc(100vh - ${headerHeights.medium * baseUnit}px)`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      top: isFixedHeights ? '88px' : `${headerHeights.small * baseUnit}px`,
      height: `calc(100vh - ${headerHeights.small * baseUnit}px)`,
    },
  },
  enter: {
    opacity: 0,
    transform: 'translate(-100%)',
  },
  enterActive: {
    opacity: 1,
    transform: 'translate(0)',
    transition: `transform ${menuEnterDuration}ms, opacity ${menuEnterDuration}ms`,
    transitionTimingFunction: 'ease-out',
  },
  exit: {
    opacity: 1,
    transform: 'translate(0)',
  },
  exitActive: {
    opacity: 0,
    transform: 'translate(-100%)',
    transition: `transform ${menuExitDuration}ms ease-in , opacity ${menuExitDuration}ms ease-in`,
    transitionDelay: `${menuExitDelay}ms`,
  },
});

const getMenuLinkStyle = () => StyleSheet.create({
  enter: {
    opacity: 0.5,
    transform: 'translate(-100%)',

  },
  enterActive: {
    opacity: 1,
    transform: 'translate(0)',
    transition: `transform ${linkEnterDuration}ms ease-out, opacity ${linkEnterDuration}ms ease-in`,
    transitionDelay: `${linkEnterDelay}ms`,

  },
  exit: {
    transform: 'translate(0)',
    opacity: 1,
  },
  exitActive: {
    opacity: 0.5,
    transform: 'translate(-100%)',
    transition: `transform ${linkExitDuration}ms ease-in, opacity ${linkExitDuration}ms ease-in`,
    transitionDelay: `${linkExitDelay}ms`,
  },
});

interface MenuLinkAnimationWrapperProps {
  shouldAppear: boolean;
}

const MenuLinkAnimationWrapper: React.FunctionComponent<MenuLinkAnimationWrapperProps> = props => {
  const { children, shouldAppear } = props;
  const style = getMenuLinkStyle();
  return (
    <CSSTransition
      unmountOnExit
      mountOnEnter
      in={shouldAppear}
      timeout={{
        enter: linkEnterTimeOut,
        exit: linkExitTimeOut,
      }}
      classNames={{
        enter: css(style.enter),
        enterActive: css(style.enterActive),
        exit: css(style.exit),
        exitActive: css(style.exitActive),
      }}
    >
      {children}
    </CSSTransition>
  );
};

export const createMenuLink = (onClick: (index: number) => void, currentOpenLink: number, shouldAppear: boolean, startIndex = 0) => (menuLink: MenuLinkDataProps, index: number) => {
  return (
    <MenuLinkAnimationWrapper shouldAppear={shouldAppear} key={index}>
      {(state: string) => (
        <MenuLink
          key={index}
          link={menuLink.link}
          childrenLinks={menuLink.childrenLinks}
          index={startIndex + index}
          onClick={onClick}
          isLinkOpen={(currentOpenLink !== -1) && (currentOpenLink === startIndex + index)}
          startChevronIconAnimation={(state === 'entering') || (state === 'entered')}
        />
      )}
    </MenuLinkAnimationWrapper>
  );
};

export const createEditionLink = (onClick: (index: number) => void, currentOpenLink: number, shouldAppear: boolean, startIndex = 0) => (menuLink: MenuLinkDataProps, index: number) => {
  return (
    <MenuLinkAnimationWrapper shouldAppear={shouldAppear} key={index}>
      {(state: string) => (
        <MenuLink
          key={index}
          link={menuLink.link}
          childrenLinks={menuLink.childrenLinks}
          index={startIndex + index}
          onClick={onClick}
          isLinkOpen={(currentOpenLink !== -1) && (currentOpenLink === startIndex + index)}
          startChevronIconAnimation={(state === 'entering') || (state === 'entered')}
        />
      )}
    </MenuLinkAnimationWrapper>
  );
};

const createMenuButton = (shouldAppear: boolean) => ({ text, onClick, attributes, cssClassName }: LinkButtonDataProps, key: number) => (
  <MenuLinkAnimationWrapper shouldAppear={shouldAppear} key={key}>
    <MenuButton text={text} onClick={onClick} attributes={attributes} cssClassName={cssClassName} />
  </MenuLinkAnimationWrapper>
);

export const createEditionsLinks = (editions: NavigationLink[], editionEndpoint: string) => {
  const activeEdition = getActiveEdition(editions, editionEndpoint);
  return [{
    link: {
      href: activeEdition.link.href,
      text: activeEdition.link.text,
    },
    childrenLinks: editions.map(edition => ({
      link: {
        href: edition.link.href,
        text: edition.link.text,
      },
      childrenLinks: null,
    })),
  }];
};

export const MenuLinks: React.FunctionComponent<MenuLinksDataProps & MenuLinksLogicProps> = props => {
  const { menuLinks, isMenuOpen, onClick, currentOpenLink, buttons = [], children, isFixedHeights = false } = props;
  const { hamburgerMenuLinksBackgroundColor, baseUnit } = useTheme<HeaderThemeProps>(componentConfigThemeKey);
  const headerHeights = useFirstRowHeaderHeights();
  const style = getMenuLinksStyle(hamburgerMenuLinksBackgroundColor, baseUnit, headerHeights, isFixedHeights);
  return (
    <ErrorBoundary>
      <CSSTransition
        in={isMenuOpen}
        unmountOnExit
        mountOnEnter
        timeout={{
          enter: menuEnterTimeOut,
          exit: menuExitTimeOut,
        }}
        classNames={{
          enter: css(style.enter),
          enterActive: css(style.enterActive),
          exit: css(style.exit),
          exitActive: css(style.exitActive),
        }}
      >
        {(state: string) => (
          <ul className={css(style.style)}>
            {menuLinks.map(createMenuLink(onClick, currentOpenLink, (state === 'entering') || (state === 'entered')))}
            {buttons.map(createMenuButton((state === 'entering') || (state === 'entered')))}
            {children}
          </ul>
        )}
      </CSSTransition>
    </ErrorBoundary>
  );
};
