import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { AdditionalStyleSheet } from '../../../components.utils';
import { BaseUnit } from '../../../../theming/baseUnitDefinition';
import { useTheme } from '../../../../theming/useTheme';
import { SocialButton } from '../../ShareMenu/ShareMenu';

const TRANSITION_LENGTH = 500;

interface SquareShareButtonProps {
  additionalStyle: AdditionalStyleSheet;
  shareIconColor: string;
  onClick: () => void;
  shareConfig: SocialButton[];
  isOpen: boolean;
}

const getShareIconStyles = (fillColor: string, isOpen: boolean) => {
  return StyleSheet.create({
    base: {
      fill: fillColor,
      opacity: 1,
      width: '100%',
      transition: `${TRANSITION_LENGTH}ms`,
      ...(isOpen && {
        transform: 'translateY(-30px)',
        opacity: 0,
      }),
    },
  });
};

const getCloseIconStyles = (fillColor: string, baseUnit: BaseUnit, isOpen: boolean) => {
  return StyleSheet.create({
    base: {
      position: 'absolute',
      width: '100%',
      height: 0,
    },
    line: {
      position: 'absolute',
      bottom: 0,
      height: '1px',
      backgroundColor: fillColor,
      opacity: 0,
      transition: `${TRANSITION_LENGTH}ms`,
      width: '100%',
    },
    leftLine: {
      left: 0,
      transformOrigin: 'left bottom',
      ...(isOpen && {
        opacity: 1,
        transform: 'rotate(-45deg)',
        width: '130%',
      }),
    },
    rightLine: {
      right: 0,
      transformOrigin: 'right bottom',
      ...(isOpen && {
        opacity: 1,
        transform: 'rotate(45deg)',
        width: '130%',
      }),
    },
  });
};

export const SquareShareButton: React.FunctionComponent<SquareShareButtonProps> = ({ shareIconColor, additionalStyle, onClick, isOpen }) => {
  const { baseUnit } = useTheme();
  const shareIconStyles = getShareIconStyles(shareIconColor, isOpen);
  const closeIconStyles = getCloseIconStyles(shareIconColor, baseUnit, isOpen);

  return (
    <div className={css(additionalStyle.style)} onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" className={css(shareIconStyles.base)} viewBox="0 0 40 40">
        <g fillRule="evenodd">
          <path d="M18.75 0L8.125 10.709 9.658 12.247 17.655 4.229 17.655 31.25 19.846 31.25 19.846 4.229 27.841 12.247 29.375 10.709 18.751 0z" transform="translate(1.25)" />
          <path d="M35.357 20L35.357 37.778 2.143 37.778 2.143 20 0 20 0 40 37.5 40 37.5 20z" transform="translate(1.25)" />
        </g>
      </svg>
      <div className={css(closeIconStyles.base)}>
        <div className={css(closeIconStyles.line, closeIconStyles.leftLine)} />
        <div className={css(closeIconStyles.line, closeIconStyles.rightLine)} />
      </div>
    </div>
  );
};
