import { Footer, AmpFooter } from 'mm-ui-components';
import { useSelector } from 'react-redux';
import React from 'react';
import {
  getAppsSection,
  getBottomFooterBar,
  getLinksSection,
  getSocialSection,
} from '../../store/navigations/ampNavigation/footer-old/footer.selectors';
import { getFooter } from '../../store/navigations/navigation/footer/footer.selectors';

export const OldFooterConnected: React.FunctionComponent = () => {
  const linksSection = useSelector(getLinksSection);
  const oldSocialSection = useSelector(getSocialSection);
  const appsSection = useSelector(getAppsSection);
  const bottomFooterBar = useSelector(getBottomFooterBar);
  return <AmpFooter {...{ linksSection, oldSocialSection, appsSection, bottomFooterBar }} />;
};

export const FooterConnected: React.FunctionComponent = () => {
  const footer = useSelector(getFooter);
  return <Footer {...footer} />;
};
