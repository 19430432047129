import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { createImageWidths, ImageWidths } from '../../partials/image/image.utils';
import { baseUnit } from '../../../theming/baseUnitDefinition';
import { useTheme } from '../../../theming/useTheme';
import { HorizontalCard } from '../../partials/cards/horizontalCard/HorizontalCard';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { getLayoutComponentPadding, LayoutComponentBaseProps } from '../pageLayouts/layout.utils';

export const componentConfigThemeKey = 'nineHC';
const maximumNumberOfCards = 9;

interface NineHCDataProps extends LayoutComponentBaseProps {
    cards: CardComponentDataProps[];
    imageWidths?: ImageWidths;
    useFormatMinutesHoursAgo?: boolean;
}

export interface NineHCThemeProps {
    largeHorizontalGap: number;
    mediumHorizontalGap: number;
    smallHorizontalGap: number;
}

const getStyle = (themeProps: NineHCThemeProps) => {
  const { largeHorizontalGap, mediumHorizontalGap, smallHorizontalGap } = themeProps;
  return StyleSheet.create({
    wrapper: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto',
      height: 'fit-content',
      [MEDIA_BREAKPOINTS.large]: {
        gridRowGap: `${baseUnit * largeHorizontalGap}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        gridRowGap: `${baseUnit * mediumHorizontalGap}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        gridRowGap: `${baseUnit * smallHorizontalGap}px`,
      },
    },
  });
};

const defaultImageWidths = createImageWidths(180, 180, 360);

export const NineHC: React.FunctionComponent<NineHCDataProps> = props => {
  const { cards, paddingFactors, useFormatMinutesHoursAgo, imageWidths = defaultImageWidths } = props;
  const themeProps = useTheme<NineHCThemeProps>(componentConfigThemeKey);
  const style = getStyle(themeProps);
  const paddingStyle = getLayoutComponentPadding(paddingFactors);
  return (
    <ErrorBoundary>
      { cards && cards.length > 0
      && (
      <div className={css(paddingStyle, style.wrapper)}>
        {cards.map((card => (<HorizontalCard key={card.id} {...card} imageWidths={imageWidths} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />))).slice(0, maximumNumberOfCards)}
      </div>
      )}
    </ErrorBoundary>
  );
};
