import React from 'react';
import { useSelector } from 'react-redux';
import { AppContent, Error, ErrorBoundary } from 'mm-ui-components';
import { Store } from 'redux';
import { Theme } from '../../components/connectedComponents/Theme';
import { MetaTags } from '../../components/connectedComponents/MetaTags';
import { HeadScripts } from '../../components/connectedComponents/HeadScripts';
import { Page } from '../Page';
import {
  getErrorButton,
  getErrorContent,
  getErrorImage,
  getErrorSecondaryTitle,
  getErrorTitle,
  getMetadataDescription,
  getMetadataTitle,
  getSearchEnabled,
} from '../../store/template/errorPage/errorPageMecca/errorPageMecca.selectors';
import { ErrorReporter } from '../../errorReporting/errorReporter.type';
import { AppActions, AppState } from '../../store/initialStateRegistration';
import { useMeasureHydrateTime } from '../useMeasureHydrateTime';
import { NavigationLayout } from '../../components/connectedComponents/NavigationLayout';
import { getPropertyEndpoint } from '../../store/config/config.selectors';

interface ErrorPageProps {
  store: Store<AppState, AppActions>;
  errorProvider: ErrorReporter;
}

export const ErrorPageMeccaComponent: React.FunctionComponent<ErrorPageProps> = ({ store, errorProvider }) => {
  useMeasureHydrateTime();
  return (
    <Page store={store} errorProvider={errorProvider}>
      <ErrorBoundary>
        <ErrorPageContentConnected />
      </ErrorBoundary>
    </Page>
  );
};

export const ErrorPageContentConnected: React.FunctionComponent = () => {
  const image = useSelector(getErrorImage);
  const title = useSelector(getErrorTitle);
  const secondaryTitle = useSelector(getErrorSecondaryTitle);
  const content = useSelector(getErrorContent);
  const button = useSelector(getErrorButton);
  const metaTitle = useSelector(getMetadataTitle);
  const metaDescription = useSelector(getMetadataDescription);
  const searchEnabled = useSelector(getSearchEnabled);
  const propertyEndpoint = useSelector(getPropertyEndpoint);

  const searchObject = searchEnabled ? { search: { propertyEndpoint } } : undefined;

  return (
    <React.Fragment>
      <MetaTags title={metaTitle} description={metaDescription} pageImage={null} />
      <HeadScripts />
      <Theme>
        <AppContent>
          <NavigationLayout mainWrapperConfigThemeKey="mainContent">
            <Error {...{ image, title, secondaryTitle, content, button, ...searchObject }} />
          </NavigationLayout>
        </AppContent>
      </Theme>
    </React.Fragment>
  );
};
