const animationFactor = 100;
export const menuEnterDuration = animationFactor * 2;
export const menuExitDuration = animationFactor * 3;
export const menuExitDelay = animationFactor * 3;
export const menuEnterTimeOut = menuEnterDuration;
export const menuExitTimeOut = menuExitDuration + menuExitDelay;

export const linkEnterDuration = animationFactor * 2;
export const linkEnterDelay = animationFactor;
export const linkExitDuration = animationFactor * 3;
export const linkExitDelay = animationFactor * 3;
export const linkEnterTimeOut = linkEnterDuration + linkEnterDelay;
export const linkExitTimeOut = linkExitDuration + linkExitDelay;

export const chevronIconEnterDuration = animationFactor * 3;
export const chevronIconEnterDelay = animationFactor * 3;
export const chevronIconExitDuration = animationFactor * 3;
export const chevronIconEnterTimeOut = chevronIconEnterDuration + chevronIconEnterDelay;
export const chevronIconExitTimeOut = chevronIconExitDuration;

export const subLinkEnterDuration = animationFactor * 2;
export const subLinksTotalDelay = animationFactor * 2;
export const subLinkEnterTimeOut = subLinkEnterDuration + subLinksTotalDelay;
