import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { CSSTransition } from 'react-transition-group';
import { ChevronIcon, Directions } from '../../../../ChevronIcon';
import {
  chevronIconEnterDelay,
  chevronIconEnterDuration,
  chevronIconEnterTimeOut,
  chevronIconExitDuration,
  chevronIconExitTimeOut,
} from './menuLinks.utils';
import { generateLinkStyleFromTheme, TextLinkComponent } from '../../../../../../../partials/link/TextLink';
import { useTheme } from '../../../../../../../../theming/useTheme';
import { MEDIA_BREAKPOINTS } from '../../../../../../../../mediaQueries.const';
import { H4, semanticCSS } from '../../../../../../../../typography/semanticTags';
import { BaseUnit } from '../../../../../../../../theming/baseUnitDefinition';
import { MenuSubLinks } from './MenuSubLinks';
import { MenuLinkDataProps } from './MenuSubLink';
import { componentConfigThemeKey, HeaderThemeProps } from '../../../../Header.theme';

interface MenuLinkLogicProps {
  index: number;
  onClick: (index: number) => void;
  isLinkOpen: boolean;
  startChevronIconAnimation: boolean;
}

export const getMenuLinkStyle = (baseUnit: BaseUnit, linkColor: string) => StyleSheet.create({
  style: {
    color: linkColor,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [MEDIA_BREAKPOINTS.medium]: {
      margin: `${baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      margin: `${baseUnit}px`,
    },
  },
  li: {
    listStyleType: 'none',
  },
  label: {
    color: linkColor,
    flex: '0 0 auto',
    display: 'inline-flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100%',
  },
  enter: {
    opacity: 0,
  },
  enterActive: {
    opacity: 1,
    transition: `opacity ${chevronIconEnterDuration}ms ease-out`,
    transitionDelay: `${chevronIconEnterDelay}ms`,
  },
  exit: {
    opacity: 1,
  },
  exitActive: {
    opacity: 0,
    transition: `opacity ${chevronIconExitDuration}ms ease-in`,
  },
  chevronIcon: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row-reverse',
  },
});

export const MenuLink: React.FunctionComponent<MenuLinkDataProps & MenuLinkLogicProps> = props => {
  const { hamburgerMenuLinksNormalColor, hamburgerMenuLinksActiveColor, baseUnit, hamburgerMenuLinksFontSizeMedium, hamburgerMenuLinksFontSizeSmall } = useTheme<HeaderThemeProps>(componentConfigThemeKey);
  const { link, onClick, index, isLinkOpen, childrenLinks, startChevronIconAnimation } = props;
  const linkColor = isLinkOpen ? hamburgerMenuLinksActiveColor : hamburgerMenuLinksNormalColor;
  const linkStyle = generateLinkStyleFromTheme({ idleColor: linkColor });
  const style = getMenuLinkStyle(baseUnit, linkColor);
  const doSubLinksExist = childrenLinks && childrenLinks.length !== 0;
  const { href } = link;
  const isFolder = href === '-';
  const LabelComponent: React.FunctionComponent = () => (
    <H4
      fontSizeMedium={hamburgerMenuLinksFontSizeMedium}
      fontSizeSmall={hamburgerMenuLinksFontSizeSmall}
      styles={semanticCSS(style.label)}
    >
      {link.text}
    </H4>
  );
  return (
    <React.Fragment>
      <li className={css(style.li)}>
        <div className={css(style.style)}>
          {isFolder ? (
            <LabelComponent />
          ) : (
            <TextLinkComponent href={href} linkStyle={linkStyle}>
              <LabelComponent />
            </TextLinkComponent>
          )}

          {doSubLinksExist ? (
            <div className={css(style.chevronIcon)} onClick={() => onClick(index)}>
              <CSSTransition
                in={startChevronIconAnimation}
                unmountOnExit
                mountOnEnter
                timeout={{
                  enter: chevronIconEnterTimeOut,
                  exit: chevronIconExitTimeOut,
                }}
                classNames={{
                  enter: css(style.enter),
                  enterActive: css(style.enterActive),
                  exit: css(style.exit),
                  exitActive: css(style.exitActive),
                }}
              >
                <ChevronIcon direction={isLinkOpen ? Directions.UP : Directions.DOWN} color={linkColor} />
              </CSSTransition>
            </div>
          ) : null}
        </div>
        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
        {doSubLinksExist && isLinkOpen ? <MenuSubLinks links={childrenLinks!} isLinkOpen={isLinkOpen} /> : null}
      </li>
    </React.Fragment>
  );
};
