import * as React from 'react';
import { PageDescription } from './PageDescription';
import { FacebookMeta } from './FacebookMeta';
import { OpenGraph, OpenGraphTypes } from './OpenGraph';
import { TwitterShare } from './TwitterShare';
import { ItemsListSchema } from './ItemsListSchema';
import { createImageAttributes, PageImage } from './metaTags.utils';
import { CardComponentDataProps } from '../partials/cards/cards.utils';
import { HreflangItem, HreflangTags } from './HreflangTags';
import { RobotsMeta } from './RobotsMeta';
import { BreadCrumbsSchema } from './BreadcrumbsSchema';

interface MetaHeaderTagsProps {
  canonicalUrl: string;
  favicon: PageImage;
  description: string | null;
  facebookAppId: string | null;
  facebookPageId: string | null;
  title: string;
  twitterAccount: string | null;
  siteName: string;
  pageImage: PageImage | null;
  articles: Array<CardComponentDataProps> | null;
  logo: PageImage;
  ampURL: string | null;
  prevCanonicalUrl: string | null;
  locale: string;
  breadcrumbs?: {
    homeDisplayName: string;
    homeLink: string;
    categoryDisplayName: string;
    categoryLink: string;
    thirdDisplayName?: string;
    thirdLink?: string;
  } | null;
  alternateUrls: HreflangItem[];
  siTwitterAndOgTitle: string;
    noIndex?: boolean;
}
export const MetaHeaderTags: React.FunctionComponent<MetaHeaderTagsProps> = ({
  canonicalUrl,
  favicon,
  description,
  facebookAppId,
  facebookPageId,
  title,
  twitterAccount,
  siteName,
  pageImage,
  articles,
  logo,
  ampURL,
  prevCanonicalUrl,
  locale,
  alternateUrls,
  siTwitterAndOgTitle,
  noIndex,
  breadcrumbs,
}) => {
  const { imageHeight = null, imageUrl = null, imageWidth = null } = pageImage ? createImageAttributes(pageImage) : {};
  const logoImageAttributes = logo ? createImageAttributes(logo) : { imageUrl: '' };
  const faviconImageAttributes = favicon ? createImageAttributes(favicon) : { imageUrl: '' };
  const metaDescription = description || '';
  return (
    <React.Fragment>
      <PageDescription pageUrl={canonicalUrl} favicon={faviconImageAttributes.imageUrl} description={metaDescription} title={title} ampURL={ampURL} prevUrl={prevCanonicalUrl} nextUrl={null} />
      <FacebookMeta appId={facebookAppId} pageId={facebookPageId} />
      <OpenGraph pageUrl={canonicalUrl} image={imageUrl} title={siTwitterAndOgTitle || title} siteName={siteName} imageHeight={imageHeight} imageWidth={imageWidth} description={metaDescription} type={OpenGraphTypes.Website} />
      <TwitterShare title={siTwitterAndOgTitle || title} description={metaDescription} account={twitterAccount} image={imageUrl} pageUrl={canonicalUrl} imageHeight={imageHeight} imageWidth={imageWidth} />
      <ItemsListSchema publisherName={siteName} logoUrl={logoImageAttributes.imageUrl} itemListElement={articles} />
      <HreflangTags locale={locale} canonicalUrl={canonicalUrl} alternateUrls={alternateUrls} />
      { noIndex && <RobotsMeta noIndex={noIndex} />}
      { breadcrumbs && <BreadCrumbsSchema {...breadcrumbs} />}
    </React.Fragment>
  );
};
