import * as React from 'react';
import { css } from 'aphrodite/no-important';
import { Helmet } from 'react-helmet';
import { AdditionalStyleSheet } from '../../components.utils';

interface AMPContentEnrichmentProps {
    additionalStyle: AdditionalStyleSheet;
    height: string;
    width: string;
    url: string;
    articleId: string;
}

export const AMPContentEnrichment: React.FunctionComponent<AMPContentEnrichmentProps> = props => {
  const { additionalStyle, height, width, url, articleId } = props;
  return (
    <React.Fragment>
      <Helmet>
        <script async={undefined} custom-element="amp-iframe" src="https://cdn.ampproject.org/v0/amp-iframe-0.1.js" />
      </Helmet>
      <div dangerouslySetInnerHTML={{
        __html: `<figure class="${css(additionalStyle.style)}">
        <amp-iframe
          width="${width}"
          height="${height}"
          resizable
          sandbox="allow-scripts allow-same-origin allow-top-navigation"
          layout="responsive"
          src="https://assets.minutemediacdn.com/content-enrichment/amp.html?url=${url}&articleId=${articleId}"
        >
        <div overflow tabIndex="0" role="button" aria-label="Read more">
                  Read more!
        </div>
        </amp-iframe>
      </figure>
      ` }}
      />
    </React.Fragment>
  );
};
