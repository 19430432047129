import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { ListWithHeading } from '../../partials/lists/listWithHeading/ListWithHeading';
import { OnTopCard } from '../../partials/cards/onTopCard/OnTopCard';
import { useTheme } from '../../../theming/useTheme';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { createImageWidths } from '../../partials/image/image.utils';
import { LayoutComponentBaseProps, getLayoutComponentPadding } from '../pageLayouts/layout.utils';

const createStyles = (baseUnit: BaseUnit) => StyleSheet.create({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  cardWrapper: {
    boxSizing: 'border-box',
    [MEDIA_BREAKPOINTS.large]: {
      paddingRight: `${baseUnit / 4}px`,
      flex: '0 0 66.67%',
    },
    [MEDIA_BREAKPOINTS.medium]: {
      paddingRight: `${baseUnit / 4}px`,
      flex: '0 0 66.67%',
    },
    [MEDIA_BREAKPOINTS.small]: {
      paddingRight: '0',
      flex: '0 0 100%',
      paddingBottom: `${baseUnit / 4}px`,
    },
  },
  listWrapper: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    [MEDIA_BREAKPOINTS.large]: {
      paddingLeft: `${baseUnit / 4}px`,
      flex: '0 1 33.33%',
    },
    [MEDIA_BREAKPOINTS.medium]: {
      paddingLeft: `${baseUnit / 4}px`,
      flex: '0 1 33.33%',
    },
    [MEDIA_BREAKPOINTS.small]: {
      paddingLeft: '0',
      flex: '0 1 100%',
      paddingTop: `${baseUnit / 4}px`,
    },
  },
});

interface HeadlinesArticleListComponentDataProps extends LayoutComponentBaseProps {
  cardsProps: CardComponentDataProps[];
  displayName: string;
}

interface HeadlinesArticleListComponentThemeProps {
  baseUnit: BaseUnit;
}

const imageWidths = createImageWidths(360, 720, 720);

export const HeadlinesArticleList: React.FunctionComponent<HeadlinesArticleListComponentDataProps> = ({ cardsProps, displayName, paddingFactors }) => {
  const { baseUnit } = useTheme<HeadlinesArticleListComponentThemeProps>();
  const styles = createStyles(baseUnit);
  const paddingStyle = getLayoutComponentPadding(paddingFactors);
  const [onTopCardProps, ...listProps] = cardsProps || [];
  return (
    <ErrorBoundary>
      { cardsProps && cardsProps.length > 0
      && (
      <div className={css(paddingStyle, styles.container)}>
        <div className={css(styles.cardWrapper)}>
          <OnTopCard {...onTopCardProps} imageWidths={imageWidths} />
        </div>
        <div className={css(styles.listWrapper)}>
          <ListWithHeading listProps={listProps} displayName={displayName} itemNumLines={2} />
        </div>
      </div>
      )}
    </ErrorBoundary>
  );
};
