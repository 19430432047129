import * as React from 'react';
import { css } from 'aphrodite/no-important';
import { SEMANTIC_TAG } from 'mm-theme-configuration/dist/consts';
import {
  SemanticTagDataProps,
  SemanticTagThemeProps,
  getStyleBySize,
  getFontSizesBySemanticTag,
} from '../semanticTags/semanticTags.utils';
import { useTheme } from '../../theming/useTheme';

interface DynamicSemanticTagProps {
  semanticTag: SEMANTIC_TAG;
}
export const DynamicSemanticTag: React.FunctionComponent<SemanticTagDataProps & DynamicSemanticTagProps> = props => {
  const { semanticTag } = props;
  const defaultFontSizes = getFontSizesBySemanticTag(semanticTag);
  const { fontSizesStyles } = useTheme<SemanticTagThemeProps>();
  const {
    children,
    styles = [],
    fontSizeSmall = defaultFontSizes.fontSizeSmall,
    fontSizeMedium = defaultFontSizes.fontSizeMedium,
    fontSizeLarge = defaultFontSizes.fontSizeLarge,
    ...attributes
  } = props;
  const styleBySize = getStyleBySize(fontSizesStyles, { fontSizeSmall, fontSizeMedium, fontSizeLarge });
  const SemanticTag = semanticTag;
  delete attributes.semanticTag;

  return (
    <SemanticTag className={css(styleBySize, ...styles)} {...attributes}>{children}</SemanticTag>
  );
};
