import * as React from 'react';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';

export const ArrowSvg = ({ arrowIconColor }: { arrowIconColor: string }) => {
  return (
    <ErrorBoundary>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_5735_282275" style={{ maskType: 'alpha' } as any} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
          <rect width="16" height="16" fill="#0A1529" />
        </mask>
        <g mask="url(#mask0_5735_282275)">
          <path d="M4.7535 16L3.3335 14.58L9.9135 8L3.3335 1.42L4.7535 0L12.7535 8L4.7535 16Z" fill={arrowIconColor} />
        </g>
      </svg>
    </ErrorBoundary>
  );
};
