import * as React from 'react';

interface AuthorsWithRoundImageClipMaskProps {
  clipPathSmall: string;
  clipPathLarge: string;
}

export const AuthorsWithRoundImageClipMask: React.FunctionComponent<AuthorsWithRoundImageClipMaskProps> = ({ clipPathLarge, clipPathSmall }) => (
  <svg width="0" height="0" style={{ position: 'absolute' }}>
    <defs>
      <clipPath id={clipPathLarge}>
        <path d="M34.5,0C25,0,16.4,3.7,10,9.6c5.8,6.9,9.2,15.8,9.2,25.4S15.8,53.5,10,60.4c6.4,6,15,9.6,24.5,9.6C54.1,70,70,54.3,70,35 S54.1,0,34.5,0z" />
      </clipPath>
      <clipPath id={clipPathSmall}>
        <path d="M24.6,0C17.8,0,11.6,2.6,7,6.9c4.2,4.9,6.6,11.3,6.6,18.1S11.2,38.2,7,43.1c4.6,4.3,10.8,6.9,17.6,6.9 C38.6,50,50,38.8,50,25S38.6,0,24.6,0z" />
      </clipPath>
    </defs>
  </svg>
);
