import { Palette, ThemeConfiguration } from 'mm-theme-configuration/dist/interfaces';
import { Image } from '../template/template.state.types';
import { AdSlots, INJECT_ADS, InjectAdsAction } from './config.actions';

interface Theme {
  palette: Palette;
  components: ThemeConfiguration[];
}

export interface ExternalScript {
  attributes: object | null;
  loadingType: 'defer' | 'async' | 'blocking';
  name: string;
  source: string;
  type: 'URL' | 'code';
}

export type ExternalScripts = Array<ExternalScript>;

export interface ConfigState {
    externalScripts: ExternalScripts;
    cmpId: string | null;
    facebookAppId: string | null;
    facebookPageId: string | null;
    siteName: string;
    canonicalURL: string;
    favicon: Image;
    language: string;
    twitterAccount: string | null;
    template: string;
    logo: Image;
    theme: Theme;
    adSlots: AdSlots;
    imagesCDNHost: string;
    ampURL: string | null;
    prevCanonicalUrl: string | null;
    ampDfpProperty: string;
    pagePath: string;
    propertyEndpoint: string;
    pageType: string;
    timeZone: string;
    locale: string;
    midnightInTimeZone: string;
    editionEndpoint: string;
    edition: string;
    experiments: Array<string>;
    resourceID: string | null;
    useFormatMinutesHoursAgo: boolean;
    isMobileViewer: boolean;
    isFannation: boolean;
    property: string;
    authorIds: Array<string>;
}

type ConfigAction = InjectAdsAction

export const configReducer = (state: ConfigState = {} as ConfigState, action: ConfigAction) => {
  switch (action.type) {
    case INJECT_ADS: {
      return {
        ...state,
        adSlots: action.adSlotsToInject,
      };
    }
    default:
      return state;
  }
};
