import React, { SyntheticEvent, useEffect, useRef } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useTheme } from '../../../../theming/useTheme';
import { Span } from '../../../../typography/semanticTags';
import { TriangleIcon, Directions } from '../../navigation/header/TriangleIcon';
import { ArticleBlockThemeProps, componentConfigurationKey } from '../../../articleComponents/ArticleBlock.theme';

interface BreadcrumbButtonProps {
  modalRef: React.RefObject<HTMLUListElement>;
  displayName: string;
  onClick: (e: any) => void;
  setHover: (hover: boolean) => void;
  hover: boolean;
  showChildren: boolean;
  style: any;
}

const createStyle = (isActive: boolean, breadCrumbsInactiveTextColor: string, breadCrumbsTextColor: string) => {
  return StyleSheet.create({
    buttonText: {
      marginLeft: '5px',
      display: 'inline-block',
    },
    button: {
      color: isActive ? breadCrumbsInactiveTextColor : breadCrumbsTextColor,
    },
  });
};

export const BreadcrumbButton = ({ modalRef, displayName, onClick, setHover, hover, showChildren, style }: BreadcrumbButtonProps) => {
  const {
    breadCrumbsTextColor,
    breadCrumbsInactiveTextColor,
    breadCrumbsFontSizeLarge,
    breadCrumbsFontSizeMedium,
    breadCrumbsFontSizeSmall,
  } = useTheme<ArticleBlockThemeProps>(componentConfigurationKey);

  const styles = createStyle(showChildren, breadCrumbsInactiveTextColor, breadCrumbsTextColor);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleModal = (e: any) => {
      // @ts-ignore
      if (modalRef.current && !modalRef.current.contains(e.target) && buttonRef.current && !buttonRef.current.contains(e.target)) {
        onClick(false);
      }
    };

    document.addEventListener('mousedown', handleModal);
    return () => {
      document.removeEventListener('mousedown', handleModal);
    };
  }, [buttonRef, modalRef, onClick]);

  const handleClick = (e: SyntheticEvent) => {
    e.preventDefault();
    onClick(!showChildren);
  };

  return (
    <button
      type="button"
      ref={buttonRef}
      onClick={handleClick}
      className={css(style, styles.button)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Span
        fontSizeLarge={breadCrumbsFontSizeLarge}
        fontSizeMedium={breadCrumbsFontSizeMedium}
        fontSizeSmall={breadCrumbsFontSizeSmall}
      >
        {displayName}
      </Span>
      <div className={css(styles.buttonText)}>
        <TriangleIcon
          direction={showChildren ? Directions.UP : Directions.DOWN}
          color={
            hover || showChildren
              ? breadCrumbsInactiveTextColor
              : breadCrumbsTextColor
          }
        />
      </div>
    </button>
  );
};
