import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { TYPOGRAPHY_SIZE } from 'mm-theme-configuration/dist/consts';
import { useTheme } from '../../../../theming/useTheme';
import { ErrorBoundary } from '../../../errorHandling/ErrorBoundary';
import { Link } from '../../../partials/link/Link';
import { getStyleBySize } from '../../../../typography/semanticTags/semanticTags.utils';
import { componentConfigThemeKey, HeaderThemeProps } from './Header.theme';
import { BaseUnit } from '../../../../theming/baseUnitDefinition';

export interface CtaDataProps {
  href: string;
  text: string;
}

const height = 25;

const getStyle = (
  backgroundColorNormal: string,
  textColorNormal: string,
  borderColorNormal: string,
  backgroundColorHover: string,
  textColorHover: string,
  borderColorHover: string,
  backgroundColorActive: string,
  textColorActive: string,
  borderColorActive: string,
  baseUnit: BaseUnit,
  borderRadius: number,
) => {
  return StyleSheet.create({
    ctaButton: {
      borderRadius: `${borderRadius}px`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: backgroundColorNormal,
      color: textColorNormal,
      border: `${borderColorNormal} 1px solid`,
      cursor: 'pointer',
      borderWidth: '1px',
      textDecoration: 'none',
      height: `${height}px`,
      ':hover': {
        backgroundColor: backgroundColorHover,
        color: textColorHover,
        border: `${borderColorHover} 1px solid`,
      },
      ':active': {
        backgroundColor: backgroundColorActive,
        color: textColorActive,
        border: `${borderColorActive} 1px solid`,
      },
      padding: `0 ${baseUnit / 2}px`,
    },

  });
};

export const CtaButton: React.FunctionComponent<CtaDataProps> = props => {
  const { href, text } = props;
  const { fontSizesStyles, ctaBackgroundColorNormal, ctaTextColorNormal, ctaBorderColorNormal, ctaBackgroundColorHover, ctaTextColorHover, ctaBorderColorHover, ctaBackgroundColorActive, ctaTextColorActive, ctaBorderColorActive, baseUnit, ctaBorderRadius } = useTheme<HeaderThemeProps>(componentConfigThemeKey);
  const style = getStyle(ctaBackgroundColorNormal, ctaTextColorNormal, ctaBorderColorNormal, ctaBackgroundColorHover, ctaTextColorHover, ctaBorderColorHover, ctaBackgroundColorActive, ctaTextColorActive, ctaBorderColorActive, baseUnit, ctaBorderRadius);
  const linkTextStyle = getStyleBySize(fontSizesStyles, { fontSizeSmall: TYPOGRAPHY_SIZE.NORMAL, fontSizeMedium: TYPOGRAPHY_SIZE.NORMAL, fontSizeLarge: TYPOGRAPHY_SIZE.NORMAL });
  return (
    <ErrorBoundary>
      <Link className={css(style.ctaButton, linkTextStyle)} href={href} target="_blank">
        {text}
      </Link>
    </ErrorBoundary>
  );
};
