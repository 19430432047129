import { PLATFORM_REGEXES } from '../constants';
import { URLParser } from '../utils/URLParser';

export const referrerIncludesPlatform = (platform) => {
  return (traffic) => {
    if (traffic.referrer === '') {
      return false;
    }
    const referrerObject = new URLParser(traffic.referrer);
    const { hostname } = referrerObject;
    return PLATFORM_REGEXES[platform].some(regex => regex.test(hostname));
  };
};
