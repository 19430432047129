import { StyleSheet } from 'aphrodite/no-important';
import { Colors } from 'mm-theme-configuration';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';


export const createStyles = (colors: Colors, siteSecondaryColor: string, sitePrimaryColor: string, borderRadius: number) => {
  const primaryWithOpacity70 = `${sitePrimaryColor}b3`;
  return StyleSheet.create({
    paddingWrapper: {
      padding: '0 10px',
      display: 'grid',
    },
    cardWrapper: {
      display: 'flex',
      borderRadius: `${borderRadius}px`,
      overflow: 'hidden',
      width: '100%',
      [MEDIA_BREAKPOINTS.small]: {
        flexDirection: 'column',
      },
      [MEDIA_BREAKPOINTS.small]: {
        flexDirection: 'column',
      },
    },
    textWrapper: {
      backgroundColor: colors.white,
      padding: '20px',
      boxSizing: 'border-box',
      width: '50%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      textAlign: 'center',
      [MEDIA_BREAKPOINTS.medium]: {
        padding: '20px ',
        width: '100%',
      },
      [MEDIA_BREAKPOINTS.small]: {
        padding: '10px 10px 20px 10px',
      },

    },
    buttonText: {
      backgroundColor: siteSecondaryColor,
      alignSelf: 'center',
      padding: '10px',
      borderRadius: '4px',
      maxWidth: '90%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    h3: {
      alignSelf: 'center',
      paddingBottom: '10px',
    },
    imageWrapper: {
      backgroundColor: primaryWithOpacity70,
      width: '50%',
      height: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [MEDIA_BREAKPOINTS.medium]: {
        width: '100%',
      },
      [MEDIA_BREAKPOINTS.small]: {
        width: '100%',
      },
    },
    backgroundImage: {
      zIndax: '-1',
      width: '100%',
      height: '200px',
      opacity: 0.35,
      objectFit: 'cover',
      objectPosition: 'top',
      [MEDIA_BREAKPOINTS.medium]: {
        height: '170px',
      },
      [MEDIA_BREAKPOINTS.small]: {
        height: '120px',
        objectPosition: 'center',
      },
    },
    svgImg: {
      width: '16px',
      height: '16px',
      paddingLeft: '5px',
    },
    text: {
      position: 'absolute',
      color: colors.white,
      maxWidth: '45%',
      textAlign: 'center',
      [MEDIA_BREAKPOINTS.small]: {
        maxWidth: '95%',
        padding: '0px 20px',

      },
    },
  });
};
