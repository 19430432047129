import * as React from 'react';
import { Helmet } from 'react-helmet';

interface AMPGoogleAnalyticsProps {
  analyticsConfigURL: string;
  articleAuthor: string;
  articleTitle: string;
  articlePublicationTime: string;
  articleIntro: boolean;
  contentTags: Array<string>;
  siteName: string;
  property: string;
  markupFormat: string;
  language: string;
  articleId: string;
  articleTemplate: Array<string>;
  distributionChannels: Array<string>;
  mainMedia: string;
  commercialTags: Array<string> | null;
  articleWordCount: number;
  articleContentCount: { [key: string]: number };
  ownerUsername: string;
  experiments: Array<string>;
  isSlideshow: boolean;
  mainCategoryEmbed: boolean;
  topic?: string | null;
}

const createPageViewParams = ({ articleAuthor, articleId, articlePublicationTime, articleTemplate, articleTitle, articleIntro, contentTags, distributionChannels, mainMedia, language, markupFormat, commercialTags, articleContentCount, articleWordCount, siteName, property, ownerUsername, experiments, isSlideshow, mainCategoryEmbed, topic }: AMPGoogleAnalyticsProps) => ({
  siteName,
  property,
  articleAuthor,
  articleId,
  articlePublicationTime,
  articleTemplate,
  articleTitle,
  contentTags,
  distributionChannels,
  mainMedia,
  language,
  markupFormat,
  commercialTags,
  articleWordCount,
  articleContentCount,
  ownerUsername,
  experiments,
  isSlideshow,
  mainCategoryEmbed,
  topic,
  articleIntro,
});

export const AMPConfigAnalytics: React.FunctionComponent<AMPGoogleAnalyticsProps> = props => {
  const { analyticsConfigURL } = props;
  const scriptJson = {
    vars: createPageViewParams(props),
  };

  return (
    <React.Fragment>
      <Helmet>
        <script async={undefined} custom-element="amp-iframe" src="https://cdn.ampproject.org/v0/amp-iframe-0.1.js" />
      </Helmet>
      {/*
    // @ts-ignoreTS2339: Property 'amp-analytics' does not exist on type 'JSX.IntrinsicElements'. */}
      <amp-analytics id="analytics" config={analyticsConfigURL} data-credentials="include">
        {/* eslint-disable-next-line react/no-danger */}
        <script type="application/json" dangerouslySetInnerHTML={{ __html: JSON.stringify(scriptJson) }} />
        {/*
    // @ts-ignoreTS2339: Property 'amp-analytics' does not exist on type 'JSX.IntrinsicElements'. */}
      </amp-analytics>
    </React.Fragment>
  );
};
