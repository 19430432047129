import * as React from 'react';
import { StyleSheet } from 'aphrodite/no-important';
import { semanticCSS, Span } from '../../../../../typography/semanticTags';
import { Link } from '../../../../partials/link/Link';
import { ErrorBoundary } from '../../../../errorHandling/ErrorBoundary';
import { Author } from '../../author.utils';

interface AuthorsListProps {
    by: string;
    authors: Author[] | null;
    textColor: string;
}
const getTextStyle = (textColor: string) => StyleSheet.create({
  textWithColor: {
    color: textColor,
  },
});

const getAuthors = (authors: Author[] | null, style: { [key: string]: any }) => authors && authors.map((author: Author, index: number) => {
  const prefix = index >= 1 ? ', ' : '';
  const authorText = `${prefix}${author.name}`;
  return (
    typeof author.link === 'string'
      ? <Link key={index} href={author.link}><Span styles={semanticCSS(style.textWithColor)}>{authorText}</Span></Link>
      : <Span key={index} styles={semanticCSS(style.textWithColor)}>{authorText}</Span>
  );
});

export const AuthorsWithRoundImageAuthorList: React.FunctionComponent<AuthorsListProps> = props => {
  const { authors, by, textColor } = props;
  const textStyle = getTextStyle(textColor);

  return (
    <ErrorBoundary>
      <React.Fragment>
        <Span styles={semanticCSS(textStyle.textWithColor)}>{`${by} `}</Span>
        {getAuthors(authors, textStyle)}
      </React.Fragment>
    </ErrorBoundary>
  );
};
