import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Colors } from 'mm-theme-configuration';
import { ALIGNMENT, ALIGNMENT_OPTIONS, TYPOGRAPHY_SIZE } from 'mm-theme-configuration/dist/consts';
import { P, semanticCSS } from '../../../typography/semanticTags';
import { useTheme } from '../../../theming/useTheme';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { AdditionalStyleSheet } from '../../components.utils';

interface HeadlineProps {
  title: string;
  titleAlignment?: ALIGNMENT;
  additionalStyle?: AdditionalStyleSheet;
  titleColor?: string;
}

const getStyles = (colors: Colors, alignment: ALIGNMENT, titleColor?: string) => {
  return StyleSheet.create({
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      [MEDIA_BREAKPOINTS.large]: {
        width: alignment === ALIGNMENT_OPTIONS.START ? '1080px' : '1060px',
      },
    },
    title: {
      color: titleColor || colors.darkGrey,
      flexShrink: 0,
    },
    divider: {
      background: titleColor || colors.darkGrey,
      height: '1px',
      width: '100%',
    },
    dividerLeft: {
      marginRight: '20px',
    },
    dividerRight: {
      marginLeft: '20px',
    },
  });
};


export const Headline: React.FunctionComponent<HeadlineProps> = props => {
  const { title, titleAlignment = ALIGNMENT_OPTIONS.CENTER, additionalStyle, titleColor } = props;
  const { colors }: {colors: Colors} = useTheme();

  const styles = getStyles(colors, titleAlignment, titleColor);
  const largeFontSizes = {
    fontSizeSmall: TYPOGRAPHY_SIZE.LARGE,
    fontSizeMedium: TYPOGRAPHY_SIZE.LARGE,
    fontSizeLarge: TYPOGRAPHY_SIZE.LARGE,
  };

  return (
    <div className={css(styles.wrapper, additionalStyle?.style)}>
      {titleAlignment === ALIGNMENT_OPTIONS.CENTER && <div className={css(styles.divider, styles.dividerLeft)} />}
      <P {...largeFontSizes} styles={semanticCSS(styles.title)}>{title}</P>
      <div className={css(styles.divider, styles.dividerRight)} />
    </div>
  );
};
