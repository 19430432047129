import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { TYPOGRAPHY_SIZE } from 'mm-theme-configuration/dist/consts';
import { SectionWithTitle } from './SectionWithTitle';
import {
  SectionHeaderComponentThemeProps,
} from '../../wrappers/sectionHeader/SectionHeader';
import { SecondarySectionTitle } from '../titles/SecondarySectionTitle';
import { ScrollableArticleList } from './ScrollableArticleList';
import { getLayoutComponentPadding, LayoutComponentBaseProps } from '../pageLayouts/layout.utils';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { AdditionalStyleSheet } from '../../components.utils';
import { useTheme } from '../../../theming/useTheme';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { DecorationLineComponentThemeProps } from '../../partials/DecorationLine';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { baseUnit } from '../../../theming/baseUnitDefinition';
import { Headline } from '../headline/Headline';

export const componentConfigThemeKey = 'wideScrollableArticleList';
interface WideScrollableArticleListComponentDataProps extends LayoutComponentBaseProps {
    cardsProps: CardComponentDataProps[];
    cardsSectionDisplayName: string;
    useFormatMinutesHoursAgo?: boolean;
    showHeadline?: boolean;
    hasBackground?: boolean;
}

export interface WideScrollableArticleListComponentThemeProps {
    scrollableListBackgroundColor: string;
    wideScrollableListTitleColor: string;
    wideScrollableListCardTitleColor: string;
    wideScrollableListCardTitleColorOnHover: string;
}


const createStyles = ({
  titleBackgroundColor,
  titleColor,
  largeHorizontalGap,
  mediumHorizontalGap,
  smallHorizontalGap,
  largeWrapperGap,
  mediumWrapperGap,
  smallWrapperGap,
  largeMarginBottomFactor,
  mediumMarginBottomFactor,
  smallMarginBottomFactor,
}: SectionHeaderComponentThemeProps) => {
  return StyleSheet.create({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      flexWrap: 'wrap',
      flexDirection: 'column',
      [MEDIA_BREAKPOINTS.large]: {
        marginBottom: `${baseUnit * largeMarginBottomFactor}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        marginBottom: `${baseUnit * mediumMarginBottomFactor}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        marginBottom: `${baseUnit * smallMarginBottomFactor}px`,
      },
    },
    titleItem: {
      flex: '1 1 auto',
      color: titleColor,
      display: 'inline-flex',
      alignItems: 'center',
      width: '100%',
      position: 'relative',
      zIndex: 0,
      backgroundColor: titleBackgroundColor,
      [MEDIA_BREAKPOINTS.large]: {
        padding: `${largeHorizontalGap * baseUnit}px ${largeWrapperGap * baseUnit}px ${largeHorizontalGap * baseUnit}px ${largeWrapperGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        padding: `${mediumHorizontalGap * baseUnit}px ${mediumWrapperGap * baseUnit}px ${mediumHorizontalGap * baseUnit}px ${mediumWrapperGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        padding: `${smallHorizontalGap * baseUnit}px ${smallWrapperGap * baseUnit}px ${smallHorizontalGap * baseUnit}px ${smallWrapperGap * baseUnit}px`,
      },
    },
  });
};
export const WideScrollableArticleList = ({ cardsProps, cardsSectionDisplayName = '', paddingFactors, useFormatMinutesHoursAgo, showHeadline = false, hasBackground = true }: WideScrollableArticleListComponentDataProps) => {
  const { scrollableListBackgroundColor, wideScrollableListCardTitleColor, wideScrollableListCardTitleColorOnHover, wideScrollableListTitleColor } = useTheme<WideScrollableArticleListComponentThemeProps>(componentConfigThemeKey);
  const themeProps = useTheme<SectionHeaderComponentThemeProps>('sectionHeader');
  const themeContext = useThemeOverride<DecorationLineComponentThemeProps>({ cardDecorationColor: scrollableListBackgroundColor }, 'decorationLine');
  const backgroundColor = hasBackground ? scrollableListBackgroundColor : 'transparent';
  const additionalStyle = StyleSheet.create({ style: { backgroundColor, width: '100%', marginBottom: '20px' } }) as AdditionalStyleSheet;
  const style = createStyles({ ...themeProps, titleBackgroundColor: backgroundColor, titleColor: wideScrollableListTitleColor });

  const theme = {
    cardTitleColorTypeNormal: wideScrollableListCardTitleColor,
    cardBackgroundColor: backgroundColor,
    cardTitleColorTypeHover: wideScrollableListCardTitleColorOnHover,
    titleFontSizeLarge: TYPOGRAPHY_SIZE.BIG,
    titleFontSizeSmall: TYPOGRAPHY_SIZE.BIG,
    titleFontSizeMedium: TYPOGRAPHY_SIZE.BIG,
    showAuthorInLargeScreen: false,
    showAuthorInMediumScreen: false,
    showAuthorInSmallScreen: false,
    showDecorationLine: false,
  };
  const paddingStyle = getLayoutComponentPadding(paddingFactors);

  return (

    <SectionWithTitle additionalStyle={StyleSheet.create({ style: { width: '100%', backgroundColor, padding: '20px 0', maxWidth: 'none' } }) as AdditionalStyleSheet}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div>
          { cardsSectionDisplayName ? (
            <div className={css(paddingStyle, style.container)}>
              <div className={css(style.titleItem)}>
                {showHeadline ? <Headline title={cardsSectionDisplayName} additionalStyle={additionalStyle} titleAlignment="START" titleColor={wideScrollableListTitleColor} /> : (
                  <SecondarySectionTitle additionalStyle={additionalStyle}>
                    {cardsSectionDisplayName}
                  </SecondarySectionTitle>
                )}
              </div>
            </div>
          ) : null}
          <ThemeContext.Provider value={themeContext}>
            <ScrollableArticleList
              cardsProps={cardsProps}
              isWide
              cardTheme={theme}
              useFormatMinutesHoursAgo={useFormatMinutesHoursAgo}
            />
          </ThemeContext.Provider>
        </div>
      </div>
    </SectionWithTitle>
  );
};
