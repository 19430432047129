import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { BaseUnit } from '../../../../../theming/baseUnitDefinition';

interface LinkHoverTrigger {
  ref: React.RefObject<HTMLElement>;
  index: number;
  type: 'link' | 'button';
}

interface Action {
  type: 'addLink' | 'addButton';
  data: LinkHoverTrigger;
}

const getLinkType = (type: string): 'link' | 'button' => {
  return type === 'button' ? 'button' : 'link';
};

export type RegisterLinkWithSubMenu = (ref: React.RefObject<HTMLElement>, index: number, isButton?: boolean) => void;
type GetHoverPlaceholders = () => React.ReactElement[];

type UseLinksWithSubMenuHover = (onHoverLink: (element: HTMLElement, index: number, type: 'link' | 'button') => void, ulRef: React.RefObject<HTMLUListElement>, firstRowFinalHeightInLargeScreen: number, baseUnit: BaseUnit) => [RegisterLinkWithSubMenu, GetHoverPlaceholders];

const initialState = [] as LinkHoverTrigger[];

const reducer = (state: LinkHoverTrigger[], action: Action) => {
  if (action) {
    switch (action.type) {
      case 'addLink':
        return [...state, action.data];

      case 'addButton': {
        const buttonIndex = state.findIndex(link => link.ref.current === action.data.ref.current);
        if (buttonIndex < 0) {
          return [...state, action.data];
        }
        if (state[buttonIndex].index !== action.data.index) {
          return [...state.slice(0, buttonIndex), action.data, ...state.slice(buttonIndex + 1)];
        }

        return state;
      }

      default: return state;
    }
  }

  return state;
};

export const useLinksWithSubMenuHover: UseLinksWithSubMenuHover = (onHoverLink, ulRef, firstRowFinalHeightInLargeScreen, baseUnit) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const registerLinkWithSubMenu = (ref: React.RefObject<HTMLElement>, index: number, isButton = false) => {
    if (ref.current === null) return;

    const data = {
      ref,
      index,
      type: isButton ? getLinkType('button') : getLinkType('link'),
    };
    if (!isButton) {
      if (!state.find(element => element.ref.current === ref.current)) {
        dispatch({ data, type: 'addLink' });
      }
    } else {
      dispatch({ data, type: 'addButton' });
    }
  };

  const getHoverPlaceholders = () => {
    return state.filter(trigger => {
      const { ref } = trigger;
      if (!ref.current || !ulRef.current) return false;
      const visibleArea = ulRef.current.getBoundingClientRect().top + 5;
      if (trigger.type !== 'button' && ref.current.getBoundingClientRect().top > visibleArea) return false;
      return true;
    }).map((trigger, index) => {
      const { ref } = trigger;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const rect = ref.current!.getBoundingClientRect();
      const { style } = StyleSheet.create({
        style: {
          position: 'absolute',
          width: `${rect.width + baseUnit}px`,
          height: `${firstRowFinalHeightInLargeScreen}px`,
          top: `-${firstRowFinalHeightInLargeScreen / 2}px`,
          left: `${rect.left - baseUnit / 2}px`,
          cursor: 'pointer',
        },
      });

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const linkActualIndex = state.find(element => element.ref === ref)!.index;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return <div className={css(style)} key={index} onMouseEnter={() => onHoverLink(ref.current!, linkActualIndex, trigger.type)} onClick={() => (ref.current ? ref.current.click() : null)} />;
    });
  };

  return [registerLinkWithSubMenu, getHoverPlaceholders];
};
