import React from 'react';
import { css, StyleDeclarationValue, StyleSheet } from 'aphrodite/no-important';
import { BlockWidths } from '../pageLayouts/BlockTypes.utils';
import { baseUnit } from '../../../theming/baseUnitDefinition';
import { createBlockStyle } from '../../articleComponents/blockMutualStyle';
import { useViewabilityHandler } from '../../../viewability/viewabilityHandler';

interface SportradarDataProps {
  dataId: string;
  language: string;
  widgetId: string;
  teamUid?: string;
  uniqueTeamId?: string;
  playerId?: string;
  seasonId?: string;
  uniqueTournamentID?: string;
  seasonType?: string;
  pastGames?: string;
  matchId?: string;
  statType?: string;
  defaultBlocksWidths?: BlockWidths;
  sportradarProps?: string;
  style?: StyleDeclarationValue;
}

const createWrapperStyle = (defaultBlocksWidths?: BlockWidths) => StyleSheet.create({
  style: {
    marginTop: baseUnit,
    ...defaultBlocksWidths ? createBlockStyle(defaultBlocksWidths) : {},
  },
}).style;

export const Sportradar: React.FunctionComponent<SportradarDataProps> = props => {
  const { dataId, language, widgetId, teamUid, uniqueTeamId, playerId, seasonId, uniqueTournamentID, seasonType,
    pastGames, matchId, statType, defaultBlocksWidths, sportradarProps, style } = props;

  const widgetVariables = [
    ...(teamUid ? [`teamUid: ${teamUid}`] : []),
    ...(uniqueTeamId ? [`uniqueTeamId: ${uniqueTeamId}`] : []),
    ...(playerId ? [`playerId: ${playerId}`] : []),
    ...(seasonId ? [`seasonId: ${seasonId}`] : []),
    ...(uniqueTournamentID ? [`uniqueTournamentID: ${uniqueTournamentID}`] : []),
    ...(seasonType ? [`seasonType: '${seasonType}'`] : []),
    ...(pastGames ? [`pastGames: ${pastGames}`] : []),
    ...(matchId ? [`matchId: ${matchId}`] : []),
    ...(statType ? [`statType: '${statType}'`] : []),
  ].join(', ');
  const eventLabel = `widgetId: ${widgetId}, ${sportradarProps || widgetVariables}`;

  const viewabilityCallback = React.useCallback(() => {
    // @ts-ignore
    window.mmClientApi = window.mmClientApi || [];
    // @ts-ignore
    window.mmClientApi.push('analytics', {
      event_category: 'Article',
      event_action: 'sportradar widget viewable impression',
      event_label: eventLabel,
    });
  }, [eventLabel]);

  const sportradarScript = `(function(a,b,c,d,e,f,g,h,i){a[e]||(i=a[e]=function(){(a[e].q=a[e].q||[]).push(arguments)},
    i.l=1*new Date,i.o=f,g=b.createElement(c),h=b.getElementsByTagName(c)[0],g.async=1,g.src=d,g.setAttribute("n",e),
    h.parentNode.insertBefore(g,h))})(window,document,"script",
    "https://widgets.media.sportradar.com/minutemedia/widgetloader","SIR", { language: '${language}' });
    SIR('addWidget', '#sr-widget-${dataId}', '${widgetId}', {border: false, ${sportradarProps || widgetVariables}})`;

  React.useEffect(() => {
    const scriptElement = document.createElement('script');
    scriptElement.innerHTML = sportradarScript;
    document.head.appendChild(scriptElement);

    window.mmClientApi = window.mmClientApi || [];
    window.mmClientApi.push('analytics', {
      event_category: 'Article',
      event_action: 'sportradar widget embed',
      event_label: eventLabel,
    });

    const liveStats = document.getElementsByClassName('sr-us-common-linescore-layout__live-stats')[0];

    if (liveStats) {
      // @ts-ignore
      liveStats.style.display = 'none';
    }
  }, [eventLabel, sportradarScript]);

  const wrapperStyle = createWrapperStyle(defaultBlocksWidths);
  const ref = useViewabilityHandler(viewabilityCallback, 0.1);

  return (
    <div data-mm-id={dataId} ref={ref} className={css(wrapperStyle, style)}>
      <div id={`sr-widget-${dataId}`} />
    </div>
  );
};
