import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { AdditionalStyleSheet } from '../../components.utils';

const getStyles = () => {
  return StyleSheet.create({
    icon: {
      display: 'block',
      width: '24px',
    },
  });
};

interface ArrowDownAnimationProps {
  additionalStyle?: AdditionalStyleSheet;
}

export const ArrowDownAnimation: React.FunctionComponent<ArrowDownAnimationProps> = props => {
  const { additionalStyle } = props;
  const styles = getStyles();

  return (
    <img className={css(styles.icon, additionalStyle?.style)} src="https://images2.minutemediacdn.com/image/upload/v1730818593/production-assets/arrow-down.gif" alt="" />
  );
};
