import { CardComponentDataProps } from '../../partials/cards/cards.utils';

const isNewChunk = (index: number, chunkSize: number) => index % chunkSize === 0;

export const getArticlesInChunks = (articles: any[], chunkSize: number) => {
  let currentChunk: CardComponentDataProps[] = [];
  return articles.reduce((chunks: CardComponentDataProps[][], item, index) => {
    if (isNewChunk(index, chunkSize)) {
      currentChunk = [item];
      chunks.push(currentChunk);
    } else {
      currentChunk.push(item);
    }
    return chunks;
  }, []);
};
