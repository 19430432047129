import { ScreenSize } from 'mm-theme-configuration/dist/consts';

type MEDIA_BREAKPOINTS = {
  [key in ScreenSize]: string
}

export const HUGE_SCREEN_SIZE = 1440 as const;
export const LARGE_SCREEN_SIZE = 1080 as const;
export const MEDIUM_SCREEN_SIZE = 1079;
export const SMALL_SCREEN_SIZE = 719;

export type ContentWidth = typeof HUGE_SCREEN_SIZE | typeof LARGE_SCREEN_SIZE | typeof MEDIUM_SCREEN_SIZE | typeof SMALL_SCREEN_SIZE;

export const MEDIA_BREAKPOINTS: MEDIA_BREAKPOINTS = {
  large: `@media (min-width: ${LARGE_SCREEN_SIZE}px)`,
  medium: `@media (max-width: ${MEDIUM_SCREEN_SIZE}px)`,
  small: `@media (max-width:${SMALL_SCREEN_SIZE}px)`,
};
