import { CoreParams } from 'mm-client-core';
import { Reducer } from 'redux';
import { FunctionComponent } from 'react';
import { AppState } from '../store/initialStateRegistration';
import { ClientAPIEventNames, GET_PAGE_DATA } from '../client/clientAPI/events';

interface Registration {
    component: any;
    rootReducer: any;
}

const validateRegistration = (registration: Registration) => {
    if (!registration.component) {
        throw new Error('missing property "component" in TemplateRegistration');
    }
    if (!registration.rootReducer) {
        throw new Error('missing property "rootReducer" in TemplateRegistration');
    }
};

export class TemplateRegistration {
    // @ts-ignore TS2564
    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-empty-function
    component: FunctionComponent<any>;

    getCoreParams: (state: AppState) => (CoreParams | {});

    clientAPIEvents: Array<ClientAPIEventNames>;

    rootReducer: any;

    constructor() {
        this.getCoreParams = () => ({});
        this.clientAPIEvents = [];
        return this;
    }

    private setClientAPIEventForCore() {
        this.addClientAPIEvent(GET_PAGE_DATA);
    }

    setComponent(component: FunctionComponent<any>) {
        this.component = component;
        return this;
    }

    setRootReducer(rootReducer: any) {
        this.rootReducer = rootReducer;
        return this;
    }


    addClientAPIEvent(clientAPIEvent: ClientAPIEventNames) {
        this.clientAPIEvents = [
            ...this.clientAPIEvents,
            clientAPIEvent,
        ];
        return this;
    }

    setCoreParams(getCoreParams: (state: AppState) => CoreParams) {
        this.getCoreParams = getCoreParams;
        this.setClientAPIEventForCore();
        return this;
    }

    build() {
        const clientAPIEventsWithoutDuplicates = [...new Set(this.clientAPIEvents)];
        const registration = {
            component: this.component,
            getCoreParams: this.getCoreParams,
            clientAPIEvents: clientAPIEventsWithoutDuplicates,
            rootReducer: this.rootReducer as Reducer<AppState>,
        };
        validateRegistration(registration);
        return registration;
    }
}
