import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { MandatoryAdditionalStyle } from '../../components.utils';
import { useTheme } from '../../../theming/useTheme';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { BlockWidths } from '../../components/pageLayouts/BlockTypes.utils';
import { createBlockStyle } from '../blockMutualStyle';
import { ArticleBlockThemeProps, componentConfigurationKey } from '../ArticleBlock.theme';
import { Kasda } from '../../../Kasda/Kasda';

interface IframeEmbedDataProps {
  html: string;
  height: string;
  dataId?: string;
  defaultBlocksWidths: BlockWidths;
}

export type IframeEmbedProps = IframeEmbedDataProps & MandatoryAdditionalStyle;

const createIframeEmbedWrapperStyle = (placeholderBackgroundColor: string, height: string, defaultBlocksWidths: BlockWidths) => {
  return StyleSheet.create({
    style: {
      ...createBlockStyle(defaultBlocksWidths),
      backgroundColor: placeholderBackgroundColor,
      paddingTop: `${height}px`,
      position: 'relative',
    },
  });
};

const createIframeEmbedStyle = () => StyleSheet.create({
  style: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
});

const parseIframeHtml = (html: string) => {
  const iframeStyle = createIframeEmbedStyle();
  return html
    .replace(/<iframe/, `<iframe class="${css(iframeStyle.style)}"`);
};

export const IframeEmbed: React.FunctionComponent<IframeEmbedProps> = props => {
  const { html, dataId, height, additionalStyle, defaultBlocksWidths } = props;
  const { placeholderBackgroundColor } = useTheme<ArticleBlockThemeProps>(componentConfigurationKey);
  const iframeWrapperStyle = createIframeEmbedWrapperStyle(placeholderBackgroundColor, height, defaultBlocksWidths);
  const isRiddleV1 = html.indexOf('riddle_target') > -1;
  const isRiddleV2 = html.indexOf('riddle2-wrapper') > -1;
  return (
    <ErrorBoundary>
      { (isRiddleV1 || isRiddleV2) && (
        <Kasda.Script>
          <script src={isRiddleV1 ? 'https://www.riddle.com/embed/files/js/embed.js' : 'https://www.riddle.com/embed/build-embedjs/embedV2.js'} />
        </Kasda.Script>
      )}
      {/* eslint-disable-next-line react/no-danger */}
      <figure data-mm-id={dataId} className={css(iframeWrapperStyle.style, additionalStyle.style)} dangerouslySetInnerHTML={{ __html: parseIframeHtml(html) }} />
    </ErrorBoundary>
  );
};
