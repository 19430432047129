import { BaseUnit } from '../../../theming/baseUnitDefinition';

export const componentConfigurationKey = 'sectionDivider';

export interface SectionDividerThemeProps {
    baseUnit: BaseUnit;
    dividerLogoColor: string;
    dividerLineColor: string;
    dividerShowIcon: boolean;
}

export const getTrianglesSvg = (color: string) => `<?xml version="1.0" encoding="utf-8"?>
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.31514 12.5L15.9999 25.8165L25.6847 12.5H6.31514Z" stroke="${color}"/>
<path d="M6.31514 6.49996L15.9999 19.8165L25.6847 6.49996H6.31514Z" stroke="${color}"/>
</svg>`;
