import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { TypographySize } from 'mm-theme-configuration/dist/consts';
import { AnimationDirection, AnimationDirectionType } from '../menuAnimations.util';
import { MEDIA_BREAKPOINTS } from '../../../../../mediaQueries.const';
import { BaseUnit } from '../../../../../theming/baseUnitDefinition';
import { NavigationLink } from '../oldHeader/primaryHeader/PrimaryHeader';
import { LinkWithSubMenu } from './LinkWithSubMenu';
import { RegularHeaderLink } from './RegularHeaderLink';
import { Logo, LogoDataProps } from './Logo';
import { useTheme } from '../../../../../theming/useTheme';
import { componentConfigThemeKey, HeaderThemeProps } from '../Header.theme';

interface ScrollableNavProps {
  menuFoldState: AnimationDirectionType;
  animationDuration: number;
  links: NavigationLink[] | null;
  linkColorTypeActive: string;
  linkColorTypeHover: string;
  linkColorTypeNormal: string;
  currentLinkColor: string;
  pagePath: string;
  baseUnit: BaseUnit;
  linkFontSizeLarge: TypographySize;
  linkFontSizeMedium: TypographySize;
  linkFontSizeSmall: TypographySize;
  verticalGapInLargeScreen: number;
  verticalGapInMediumScreen: number;
  verticalGapInSmallScreen: number;
  secondRowBackgroundColor: string;
  secondRowHeightInMediumScreen: number;
  secondRowHeightInSmallScreen: number;
  linkDropdownIconColor: string;
  openLinkIndex: number;
  onMenuToggle: (element: HTMLElement, index: number) => void;
  boxShadow: string;
  logo: LogoDataProps | null;
}

const getStyles = (
  secondRowBackgroundColor: string,
  baseUnit: number,
  secondRowHeightInMediumScreen: number,
  secondRowHeightInSmallScreen: number,
  boxShadow: string,
  verticalGapInMediumScreen: number,
  verticalGapInSmallScreen: number,
  logo: LogoDataProps | null,
) => {
  return StyleSheet.create({
    scrollableNavWrapper: {
      boxShadow,
      backgroundColor: secondRowBackgroundColor,
      alignItems: 'center',
      zIndex: 50,
      [MEDIA_BREAKPOINTS.large]: {
        display: 'none',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        height: `${secondRowHeightInMediumScreen * baseUnit}px`,
        display: 'grid',
        gridTemplateRows: '1fr',
        gridTemplateColumns: logo ? 'max-content auto' : 'auto',
        gridColumnGap: `${baseUnit * verticalGapInMediumScreen}px`,
        paddingLeft: `${baseUnit * verticalGapInMediumScreen}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        height: `${secondRowHeightInSmallScreen * baseUnit}px`,
        display: 'grid',
        gridTemplateRows: '1fr',
        gridTemplateColumns: logo ? 'max-content auto' : 'auto',
        gridColumnGap: `${baseUnit * verticalGapInMediumScreen}px`,
        paddingLeft: `${baseUnit * verticalGapInSmallScreen}px`,
      },
    },
    scrollableUl: {
      display: 'flex',
      padding: 0,
      overflowX: 'auto',
    },
  });
};

const getLinksStyle = (
  baseUnit: BaseUnit,
  verticalGapInLargeScreen: number,
  verticalGapInMediumScreen: number,
  verticalGapInSmallScreen: number,
) => StyleSheet.create({
  li: {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    ':not(:first-child)': {
      [MEDIA_BREAKPOINTS.large]: {
        marginLeft: `${baseUnit * verticalGapInLargeScreen}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        marginLeft: `${baseUnit * verticalGapInMediumScreen}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        marginLeft: `${baseUnit * verticalGapInSmallScreen}px`,
      },
    },
    ':last-child': {
      [MEDIA_BREAKPOINTS.medium]: {
        paddingRight: `${baseUnit * verticalGapInMediumScreen}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        paddingRight: `${baseUnit * verticalGapInSmallScreen}px`,
      },
    },
  },
});

const getLinks = (
  links: NavigationLink[],
  activeStateColor: string,
  hoverStateColor: string,
  idleColor: string,
  currentLinkColor: string,
  pagePath: string,
  baseUnit: BaseUnit,
  linkFontSizeLarge: TypographySize,
  linkFontSizeMedium: TypographySize,
  linkFontSizeSmall: TypographySize,
  verticalGapInLargeScreen: number,
  verticalGapInMediumScreen: number,
  verticalGapInSmallScreen: number,
  linkDropdownIconColor: string,
  onMenuToggle: (element: HTMLElement, index: number) => void,
  openLinkIndex: number,
) => {
  return links.map((navigationLink, index) => {
    const isActive = pagePath === navigationLink.link.href;
    const style = getLinksStyle(baseUnit, verticalGapInLargeScreen, verticalGapInMediumScreen, verticalGapInSmallScreen);
    const hasSubMenu = (navigationLink.children || []).length > 0;
    const { href } = navigationLink.link;
    const fontStyles = { linkFontSizeLarge, linkFontSizeMedium, linkFontSizeSmall };

    return (
      <li key={index} className={css(style.li)}>
        {
          hasSubMenu
            ? <LinkWithSubMenu currentLinkColor={currentLinkColor} activeStateColor={activeStateColor} title={navigationLink.link.text} hoverStateColor={hoverStateColor} toggleMenu={onMenuToggle} idleColor={openLinkIndex === index ? hoverStateColor : idleColor} index={index} linkDropdownIconColor={linkDropdownIconColor} {...fontStyles} isOpen={openLinkIndex === index} href="-" subLinks={navigationLink.children || []} />
            : <RegularHeaderLink currentLinkColor={currentLinkColor} activeStateColor={activeStateColor} hoverStateColor={hoverStateColor} idleColor={idleColor} isActive={isActive} href={href} text={navigationLink.link.text} {...fontStyles} />
        }
      </li>
    );
  });
};

const createHeaderSecondRowStyleWithAnimation = (animationDuration: number) => {
  return StyleSheet.create({
    foldingUp: {
      [MEDIA_BREAKPOINTS.medium]: {
        transition: `${animationDuration}ms`,
        transform: 'translateY(-100%)',
      },
      [MEDIA_BREAKPOINTS.small]: {
        transition: `${animationDuration}ms`,
        transform: 'translateY(-100%)',
      },
    },
    foldingDown: {
      [MEDIA_BREAKPOINTS.medium]: {
        transition: `${animationDuration}ms`,
        transform: 'translateY(0)',
        transitionDelay: `${animationDuration}ms`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        transition: `${animationDuration}ms`,
        transform: 'translateY(0)',
        transitionDelay: `${animationDuration}ms`,
      },
    },
  });
};

const getHeaderSecondRowStyleWithAnimation = (menuFoldState: AnimationDirectionType, animationDuration: number) => {
  const style = createHeaderSecondRowStyleWithAnimation(animationDuration);
  return menuFoldState === AnimationDirection.UP ? style.foldingUp : style.foldingDown;
};

export const ScrollableNav: React.FunctionComponent<ScrollableNavProps> = props => {
  const {
    menuFoldState,
    animationDuration,
    links,
    linkColorTypeActive,
    linkColorTypeHover,
    linkColorTypeNormal,
    currentLinkColor,
    pagePath,
    baseUnit,
    linkFontSizeLarge,
    linkFontSizeMedium,
    linkFontSizeSmall,
    verticalGapInLargeScreen,
    verticalGapInMediumScreen,
    verticalGapInSmallScreen,
    secondRowBackgroundColor,
    secondRowHeightInMediumScreen,
    secondRowHeightInSmallScreen,
    linkDropdownIconColor,
    onMenuToggle,
    openLinkIndex,
    boxShadow,
    logo,
  } = props;

  const { secondRowLogoHeightInSmallScreen, secondRowLogoHeightInMediumScreen, secondRowLogoHeightInLargeScreen } = useTheme<HeaderThemeProps>(componentConfigThemeKey);
  const styles = getStyles(secondRowBackgroundColor, baseUnit, secondRowHeightInMediumScreen, secondRowHeightInSmallScreen, boxShadow, verticalGapInMediumScreen, verticalGapInSmallScreen, logo);

  return (
    <nav className={css(getHeaderSecondRowStyleWithAnimation(menuFoldState, animationDuration), styles.scrollableNavWrapper)}>
      {logo ? (
        <Logo
          {...logo}
          logoHeightInLargeScreen={secondRowLogoHeightInLargeScreen}
          logoHeightInMediumScreen={secondRowLogoHeightInMediumScreen}
          logoHeightInSmallScreen={secondRowLogoHeightInSmallScreen}
          reduceHeightAnimationDuration={animationDuration}
        />
      )
        : null}
      <ul className={css(styles.scrollableUl)}>
        {getLinks(links || [], linkColorTypeActive, linkColorTypeHover, linkColorTypeNormal, currentLinkColor, pagePath, baseUnit, linkFontSizeLarge, linkFontSizeMedium, linkFontSizeSmall, verticalGapInLargeScreen, verticalGapInMediumScreen, verticalGapInSmallScreen, linkDropdownIconColor, onMenuToggle, openLinkIndex)}
      </ul>
    </nav>
  );
};
