import * as React from 'react';
import { Kasda } from '../../Kasda/Kasda';

export interface TwitterShareProps {
  title: string;
  description: string;
  account: string | null;
  image: string | null;
  pageUrl: string;
  imageHeight: number | null;
  imageWidth: number | null;
}

export const TwitterShare: React.FunctionComponent<TwitterShareProps> = ({ title, description, account, image, pageUrl, imageWidth, imageHeight }) => (
  <Kasda.Meta>
    <meta property="twitter:title" content={title.replace(/"/g, "'")} />
    <meta property="twitter:description" content={description} />
    {image ? <meta property="twitter:image" content={image} /> : null}
    <meta property="twitter:url" content={pageUrl} />
    <meta property="twitter:card" content="summary_large_image" />
    {imageWidth ? <meta property="twitter:image:width" content={`${imageWidth}`} /> : null}
    {imageHeight ? <meta property="twitter:image:height" content={`${imageHeight}`} /> : null}
    {account ? <meta property="twitter:site" content={account} /> : null}
  </Kasda.Meta>
);
