import * as React from 'react';
import { css } from 'aphrodite/no-important';
import { ArticleContentBaseProps, ArticleContentThemeProps, componentConfigurationKey } from './ArticleContent';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { useTheme } from '../../../theming/useTheme';
import {
  ImageBlock,
  QuoteBlock,
  HTMLBlock,
  ListItemTitleBlock,
  GenericBlock,
  JwBlock,
  GiphyBlock,
  IframeEmbedBlock,
  AMPAdBlock,
  MMPlayerBlock,
  Block,
  ApesterEmbedBlock,
  VimeoBlock,
  BlockWidths,
  ContentRecommendationsBlockProps,
  AMPContentEnrichmentBlock,
  FeedsRecommendationsBlockProps,
  TableOfContentsBlock,
  TableBlock,
  ImportedEmbeddedContentBlock, FAQBlock,
} from './BlockTypes.utils';
import { Paragraph } from '../../articleComponents/Paragraph';
import { ListItemTitle } from '../../articleComponents/ListItemTitle';
import { AMPImage } from '../../AMPArticleComponents';
import { AMPYoutube } from '../../AMPArticleComponents/media/AMPYoutube';
import { AMPTwitter } from '../../AMPArticleComponents/media/AMPTwitter';
import { AMPMediaValidator } from '../../AMPArticleComponents/media/AMPMediaValidator';
import { AMPInstagram } from '../../AMPArticleComponents/media/AMPInstagram';
import { AMPFacebook } from '../../AMPArticleComponents/media/AMPFacebook';
import { AMPPlaybuzz } from '../../AMPArticleComponents/media/AMPPlaybuzz';
import { AMPDailymotion } from '../../AMPArticleComponents/media/AMPDailymotion';
import { AMPJWPlayer } from '../../AMPArticleComponents/media/AMPJWPlayer';
import { AMPAd } from '../../AMPArticleComponents/ads/AMPAd';
import { AMPSoundclound } from '../../AMPArticleComponents/media/AMPSoundclound';
import { AMPIframe } from '../../AMPArticleComponents/media/AMPIframe';
import { AMPMMPlayer } from '../../AMPArticleComponents/media/AMPMMPlayer';
import { AMPVimeo } from '../../AMPArticleComponents/media/AMPVimeo';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { AMPGfycat } from '../../AMPArticleComponents/media/AMPGfycat';
import { AMPApester } from '../../AMPArticleComponents/media/AMPApester';
import { AMPContentEnrichment } from '../../AMPArticleComponents/media/AMPContentEnrichment';
import { Quote } from '../../articleComponents/Quote';
import { ArticleDividerWithIcon } from '../../articleComponents/divider/ArticleDividerWithIcon';
import { AMPQuoteIconForDivider } from '../../articleComponents/divider/AMPQuoteIconForDivider';
import { createBlockStyleSheet } from './utils';
import { ConditionalImageLinkWrapper } from '../../partials/image/ConditionalImageLinkWrapper';
import { RelatedTopics } from '../../articleComponents/relatedTopics/RelatedTopics';
import { AMPMMPlayerABTest90minEN } from '../../AMPArticleComponents/media/AMPMMPlayerABTest90minEN';
import { ImportedTable } from '../../articleComponents/ImportedTable';
import { TableOfContents } from '../../articleComponents/tableOfContents/TableOfContents';
import { Table } from '../../articleComponents/table/Table';
import { AMPImportedEmbeddedContent } from '../../articleComponents/importedEmbeddedContent/AMPImportedEmbeddedContent';
import { AMPMMPlayerABTestTSC } from '../../AMPArticleComponents/media/AMPMMPlayerABTestTSC';
import { FAQ } from '../../articleComponents/FAQ';
import { SuggestedPosts } from '../../articleComponents/suggestedPosts/SuggestedPosts';

interface ArticleAmpContentDataProps extends ArticleContentBaseProps {
  videoPlaylistId: string | null;
  videoPlayerId: string | null;
  defaultBlocksWidths: BlockWidths;
  abTestFor90minMMPlayer?: boolean;
  abTestForTheSmokingCuban?: boolean;
  ampAdData: string;
  property: string;
  articleId: string;
  experiment?: string;
}

const createBlocksComponents = (body: Array<Block>, baseUnit: BaseUnit, defaultBlocksWidths: BlockWidths, marginFactorBetweenTitleAndBlock: number, marginFactorBetweenBlocks: number, ampAdData: string, property: string, articleId: string) => {
  let isPreviousElementTitle = false;
  return body.map((block, index) => {
    const { type } = block;
    const regularBlockStyle = createBlockStyleSheet(baseUnit, marginFactorBetweenBlocks);
    const afterTitleBlockStyle = createBlockStyleSheet(baseUnit, marginFactorBetweenTitleAndBlock);
    const blockStyle = isPreviousElementTitle ? afterTitleBlockStyle : regularBlockStyle;
    isPreviousElementTitle = false;

    switch (type) {
      case 'ad': {
        const { adWidth, adHeight, dataId, adUnitPath } = block as AMPAdBlock;
        return <AMPAd key={dataId} adUnit={adUnitPath} width={adWidth} height={adHeight} additionalStyle={blockStyle} ampAdData={ampAdData} property={property} />;
      }
      case 'image': {
        const { image, dataId } = block as ImageBlock;
        return <ConditionalImageLinkWrapper dataId={dataId} link={image.link || null} key={`link-${dataId}`}><AMPImage key={dataId} {...image} additionalStyle={blockStyle} /></ConditionalImageLinkWrapper>;
      }
      case 'title': {
        isPreviousElementTitle = true;
        const { listItemNumber, text, dataId } = block as ListItemTitleBlock;
        return <ListItemTitle key={dataId} listItemNumber={listItemNumber} text={text} additionalStyle={blockStyle} dataId={dataId} defaultBlocksWidths={defaultBlocksWidths} />;
      }
      case 'inline-text': {
        const { html, dataId } = block as HTMLBlock;
        return (
          <Paragraph key={dataId} html={html} additionalStyle={blockStyle} dataId={dataId} defaultBlocksWidths={defaultBlocksWidths} />
        );
      }
      case 'quote': {
        const { text, cite, dataId } = block as QuoteBlock;
        return <Quote key={dataId} text={text} cite={cite} additionalStyle={blockStyle} dataId={dataId} defaultBlocksWidths={defaultBlocksWidths} />;
      }
      case 'youtube': {
        const { dataId, mediaId } = block as HTMLBlock;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return <AMPMediaValidator key={dataId} mediaId={mediaId}><AMPYoutube mediaId={mediaId!} additionalStyle={blockStyle} /></AMPMediaValidator>;
      }
      case 'twitter': {
        const { mediaId, dataId } = block as HTMLBlock;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return <AMPMediaValidator key={dataId} mediaId={mediaId}><AMPTwitter mediaId={mediaId!} additionalStyle={blockStyle} /></AMPMediaValidator>;
      }
      case 'instagram': {
        const { mediaId, dataId } = block as HTMLBlock;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return <AMPMediaValidator key={dataId} mediaId={mediaId}><AMPInstagram mediaId={mediaId!} additionalStyle={blockStyle} /></AMPMediaValidator>;
      }
      case 'facebook': {
        const { mediaId, dataId } = block as HTMLBlock;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return <AMPMediaValidator key={dataId} mediaId={mediaId}><AMPFacebook mediaId={mediaId!} additionalStyle={blockStyle} /></AMPMediaValidator>;
      }
      case 'jw': {
        const { playerId, videoId, dataId } = block as JwBlock;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return <AMPJWPlayer key={dataId} videoId={videoId} playerId={playerId!} additionalStyle={blockStyle} />;
      }
      case 'giphy': {
        const { dataId } = block as GiphyBlock;
        const className = css(blockStyle.style);
        // @ts-ignore TS2339
        return <amp-img key={dataId} alt={block.caption} src={block.url} layout="responsive" width={block.width} height={block.height} class={className} />;
      }
      case 'playbuzz': {
        const { mediaId, dataId } = block as HTMLBlock;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return <AMPMediaValidator key={dataId} mediaId={mediaId}><AMPPlaybuzz mediaId={mediaId!} additionalStyle={blockStyle} /></AMPMediaValidator>;
      }
      case 'dailyMotion': {
        const { mediaId, dataId } = block as HTMLBlock;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return <AMPMediaValidator key={dataId} mediaId={mediaId}><AMPDailymotion mediaId={mediaId!} additionalStyle={blockStyle} /></AMPMediaValidator>;
      }
      case 'divider': {
        const { dataId } = block as GenericBlock;
        return <ArticleDividerWithIcon key={dataId} additionalStyle={blockStyle} dataId={dataId} defaultBlocksWidths={defaultBlocksWidths} iconComponent={AMPQuoteIconForDivider} />;
      }
      case 'soundcloud': {
        const { mediaId, dataId } = block as HTMLBlock;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return <AMPMediaValidator key={dataId} mediaId={mediaId}><AMPSoundclound mediaId={mediaId!} additionalStyle={blockStyle} /></AMPMediaValidator>;
      }
      case 'iframeEmbed': {
        const { src, dataId, height } = block as IframeEmbedBlock;
        return <AMPIframe key={dataId} height={height} src={src} additionalStyle={blockStyle} />;
      }
      case 'mmPlayer': {
        const { dataId, playerId, mediaId } = block as MMPlayerBlock;
        return <AMPMMPlayer key={dataId} playlistId={mediaId} additionalStyle={blockStyle} playerId={playerId} />;
      }
      case 'vimeo': {
        const { dataId, mediaId } = block as VimeoBlock;
        return <AMPMediaValidator key={dataId} mediaId={mediaId}><AMPVimeo key={dataId} mediaId={mediaId} additionalStyle={blockStyle} /></AMPMediaValidator>;
      }
      case 'gfycat': {
        const { dataId, mediaId } = block as HTMLBlock;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return <AMPMediaValidator key={dataId} mediaId={mediaId}><AMPGfycat key={dataId} additionalStyle={blockStyle} mediaId={mediaId!} /></AMPMediaValidator>;
      }
      case 'apester': {
        const { dataId, mediaId } = block as ApesterEmbedBlock;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return <AMPMediaValidator key={dataId} mediaId={mediaId}><AMPApester key={dataId} additionalStyle={blockStyle} mediaId={mediaId!} /></AMPMediaValidator>;
      }
      case 'relatedPosts': {
        const { articles, dataId, sourceType } = block as ContentRecommendationsBlockProps;
        const total_blocks = body ? body.filter(element => element.type !== 'injectedBlock').length : 0;
        return <SuggestedPosts key={dataId} articles={articles} defaultBlocksWidths={defaultBlocksWidths} dataId={dataId} total_blocks={total_blocks} sourceType={sourceType} blockPosition={index} />;
      }
      case 'mm-content-embed': {
        const { dataId, height, width, url } = block as AMPContentEnrichmentBlock;
        return <AMPContentEnrichment key={dataId} height={height} additionalStyle={blockStyle} width={width} url={url} articleId={articleId} />;
      }
      case 'imported-table': {
        const { html, dataId } = block as HTMLBlock;
        return <ImportedTable key={dataId} html={html} additionalStyle={blockStyle} dataId={dataId} defaultBlocksWidths={defaultBlocksWidths} />;
      }
      case 'relatedTopics': {
        const { title, pages, dataId } = block as FeedsRecommendationsBlockProps;
        return (
          <RelatedTopics key={dataId} title={title} pages={pages} marginBetweenBlocksFactor={marginFactorBetweenBlocks} defaultBlocksWidths={defaultBlocksWidths} dataId={dataId} />
        );
      }
      case 'table-of-contents': {
        const { dataId, title, headings } = block as TableOfContentsBlock;
        return (
          <TableOfContents
            headings={headings}
            title={title}
            dataId={dataId}
            defaultBlocksWidths={defaultBlocksWidths}
            additionalStyle={blockStyle}
            key={dataId}
          />
        );
      }
      case 'table': {
        const { data, dataId } = block as TableBlock;
        return (
          <Table
            key={dataId}
            data={data}
            additionalStyle={blockStyle}
            dataId={dataId}
            defaultBlocksWidths={defaultBlocksWidths}
          />
        );
      }
      case 'imported-embedded-content': {
        const { dataId } = block as ImportedEmbeddedContentBlock;
        return (
          <AMPImportedEmbeddedContent
            key={dataId}
            {...block as ImportedEmbeddedContentBlock}
            id={dataId}
            defaultBlocksWidths={defaultBlocksWidths}
            additionalStyle={blockStyle}
          />
        );
      }
      case 'faq': {
        const { dataId, question, answer } = block as FAQBlock;
        return (
          <FAQ
            key={dataId}
            dataId={dataId}
            question={question}
            answer={answer}
            defaultBlocksWidths={defaultBlocksWidths}
            questionAdditionalStyle={regularBlockStyle}
            answerAdditionalStyle={afterTitleBlockStyle}
          />
        );
      }
      default: return null;
    }
  });
};

const createMMPlayer = (videoPlaylistId: string, videoPlayerId: string, baseUnit: BaseUnit) => <AMPMMPlayer key="first-amp-mmPlayer" playlistId={videoPlaylistId} additionalStyle={createBlockStyleSheet(baseUnit, 1)} playerId={videoPlayerId} />;

export const AMPArticleContentComponent: React.FunctionComponent<ArticleAmpContentDataProps> = props => {
  const { videoPlaylistId, videoPlayerId, body, defaultBlocksWidths, ampAdData, property, abTestFor90minMMPlayer = false, abTestForTheSmokingCuban = false, articleId } = props;
  const { baseUnit, marginBetweenBlocksFactorAfterTitle, marginBetweenBlocksFactor } = useTheme<ArticleContentThemeProps>(componentConfigurationKey);
  const AMPPlayer: React.FunctionComponent = () => {
    if (abTestFor90minMMPlayer) {
      return <AMPMMPlayerABTest90minEN additionalStyle={createBlockStyleSheet(baseUnit, 1)} />;
    }
    if (abTestForTheSmokingCuban) {
      return <AMPMMPlayerABTestTSC additionalStyle={createBlockStyleSheet(baseUnit, 1)} />;
    }
    if (videoPlaylistId && videoPlayerId) {
      return createMMPlayer(videoPlaylistId, videoPlayerId, baseUnit);
    }
    return null;
  };
  return (
    <div>
      <AMPPlayer />
      {body ? createBlocksComponents(body, baseUnit, defaultBlocksWidths, marginBetweenBlocksFactorAfterTitle, marginBetweenBlocksFactor, ampAdData, property, articleId) : null }
    </div>
  );
};

export const AMPArticleContent = (props: ArticleAmpContentDataProps) => <ErrorBoundary><AMPArticleContentComponent {...props} /></ErrorBoundary>;
