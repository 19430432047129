import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useTheme } from '../../../../theming/useTheme';
import { BaseUnit } from '../../../../theming/baseUnitDefinition';
import { semanticCSS, Span } from '../../../../typography/semanticTags';
import { BlockWidths } from '../../../components/pageLayouts/BlockTypes.utils';
import { AdditionalStyleSheet, Style } from '../../../components.utils';
import { createBlockStyle, PaddingOverride } from '../../blockMutualStyle';
import { LinkWithButtonStyle } from '../../../components/buttons/LinkWithButtonStyle';
import { ArticleBlockThemeProps, componentConfigurationKey } from '../../ArticleBlock.theme';

export interface SlideshowTopBarAmpProps {
  prevLink: string;
  prevText: string;
  nextLink: string;
  nextText: string;
  ofText: string;
  currentSlide: number | null;
  totalSlides: number;
  blockWidths: BlockWidths;
  overrideBlockPadding?: PaddingOverride;
  anchorId: string;
}

const getStyles = (baseUnit: BaseUnit, slideNumberTextColor: string, dividerColor: string, blockStyle: Style) => {
  return StyleSheet.create({
    wrapper: {
      ...blockStyle,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: `${baseUnit}px auto 0 auto`,
      position: 'relative',
    },
    divider: {
      height: '1px',
      backgroundColor: dividerColor,
      width: '100%',
      margin: `0 ${baseUnit / 2}px`,
    },
    currentSlide: {
      whiteSpace: 'nowrap',
      color: slideNumberTextColor,
    },
    anchor: {
      position: 'absolute',
      top: '-25px',
    },
  });
};

const getLinkStyle = (baseUnit: BaseUnit, shouldShow: boolean, backgroundColor: string, disabledBackgroundColor: string, textColor: string, disabledTextColor: string) => {
  return StyleSheet.create({
    style: {
      padding: `${baseUnit / 2}px ${baseUnit}px`,
      backgroundColor: shouldShow ? backgroundColor : disabledBackgroundColor,
      color: shouldShow ? textColor : disabledTextColor,
    },
  }) as AdditionalStyleSheet;
};

export const SlideshowTopBarAmp: React.FunctionComponent<SlideshowTopBarAmpProps> = ({
  prevLink,
  nextLink,
  currentSlide,
  totalSlides,
  prevText,
  nextText,
  ofText,
  blockWidths,
  overrideBlockPadding,
  anchorId,
}) => {
  const themeProps = useTheme<ArticleBlockThemeProps>(componentConfigurationKey);
  const { baseUnit, slideshowButtonBackgroundColor, slideshowButtonDisabledBackgroundColor, slideshowButtonTextColor, slideshowButtonDisabledTextColor, slideshowNumberColor, slideshowDividerColor } = themeProps;
  const showPrev = !!prevLink;
  const showNext = !!nextLink;
  const blockStyle = createBlockStyle(blockWidths, overrideBlockPadding);
  const styles = getStyles(baseUnit, slideshowNumberColor, slideshowDividerColor, blockStyle);

  const prevStyle = getLinkStyle(baseUnit, showPrev, slideshowButtonBackgroundColor, slideshowButtonDisabledBackgroundColor, slideshowButtonTextColor, slideshowButtonDisabledTextColor);
  const nextStyle = getLinkStyle(baseUnit, showNext, slideshowButtonBackgroundColor, slideshowButtonDisabledBackgroundColor, slideshowButtonTextColor, slideshowButtonDisabledTextColor);

  const onKeyboarEvent = React.useCallback((e: KeyboardEvent) => {
    if (e.key === 'ArrowLeft' && showPrev) {
      window.location.href = prevLink!;
    } else if (e.key === 'ArrowRight' && showNext) {
      window.location.href = nextLink!;
    }
  }, [showNext, showPrev, nextLink, prevLink]);

  React.useEffect(() => {
    window.addEventListener('keyup', onKeyboarEvent);

    return () => {
      window.removeEventListener('keyup', onKeyboarEvent);
    };
  }, [onKeyboarEvent]);

  const anchorIdAttribute = anchorId ? { id: anchorId } : {};

  return (
    <div className={css(styles.wrapper)}>
      <div className={css(styles.anchor)} {...anchorIdAttribute} />
      <LinkWithButtonStyle href={prevLink} additionalStyle={prevStyle} disabled={!showPrev}>
        <Span>{prevText}</Span>
      </LinkWithButtonStyle>
      <div className={css(styles.divider)} />
      {currentSlide !== null
      && <Span styles={semanticCSS(styles.currentSlide)}>{`${currentSlide} ${ofText} ${totalSlides}`}</Span>}
      <div className={css(styles.divider)} />
      <LinkWithButtonStyle href={nextLink} additionalStyle={nextStyle} disabled={!showNext}>
        <Span>{nextText}</Span>
      </LinkWithButtonStyle>
    </div>
  );
};
