import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { useTheme } from '../../../theming/useTheme';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { baseUnit } from '../../../theming/baseUnitDefinition';
import { getLayoutComponentPadding, LayoutComponentBaseProps } from '../../components/pageLayouts/layout.utils';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';

export const componentConfigurationKey = 'sectionHeaderWithBackground';

export interface SectionHeaderWithBackgroundThemeProps {
    titleColor: string;
    titleBackgroundColor: string;
    borderTopColor: string;
    borderTopWidth: number;
    borderBottomColor: string;
    borderBottomWidth: number;
    showBorderTop: boolean;
    showBorderBottom: boolean;
    largeVerticalGap: number;
    mediumVerticalGap: number;
    smallVerticalGap: number;
    largeHorizontalGap: number;
    mediumHorizontalGap: number;
    smallHorizontalGap: number;
    largeWrapperGap: number;
    mediumWrapperGap: number;
    smallWrapperGap: number;
    largeMarginBottomFactor: number;
    mediumMarginBottomFactor: number;
    smallMarginBottomFactor: number;
}

export interface SectionHeaderWithBackgroundDataProps {
    backgroundComponent: React.FunctionComponent;
}

export type SectionHeaderWithBackgroundComponentProps = SectionHeaderWithBackgroundDataProps & LayoutComponentBaseProps;
const createStyles = ({
  titleColor,
  titleBackgroundColor,
  borderTopColor,
  showBorderTop,
  showBorderBottom,
  borderTopWidth,
  largeHorizontalGap,
  mediumHorizontalGap,
  smallHorizontalGap,
  largeWrapperGap,
  mediumWrapperGap,
  smallWrapperGap,
  borderBottomColor,
  borderBottomWidth,
  largeMarginBottomFactor,
  mediumMarginBottomFactor,
  smallMarginBottomFactor }: SectionHeaderWithBackgroundThemeProps) => {
  return StyleSheet.create({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      flexWrap: 'wrap',
      flexDirection: 'column',
      [MEDIA_BREAKPOINTS.large]: {
        marginBottom: `${baseUnit * largeMarginBottomFactor}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        marginBottom: `${baseUnit * mediumMarginBottomFactor}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        marginBottom: `${baseUnit * smallMarginBottomFactor}px`,
      },
    },
    titleItem: {
      flex: '1 1 auto',
      color: titleColor,
      display: 'inline-flex',
      alignItems: 'center',
      width: '100%',
      position: 'relative',
      zIndex: 0,
      borderTop: showBorderTop ? `${baseUnit * borderTopWidth}px solid ${borderTopColor}` : 'unset',
      borderBottom: showBorderBottom ? `${baseUnit * borderBottomWidth}px solid ${borderBottomColor}` : 'unset',
      backgroundColor: 'transparent',
      [MEDIA_BREAKPOINTS.large]: {
        padding: `${largeHorizontalGap * baseUnit}px ${largeWrapperGap * baseUnit}px ${largeHorizontalGap * baseUnit}px ${largeWrapperGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        padding: `${mediumHorizontalGap * baseUnit}px ${mediumWrapperGap * baseUnit}px ${mediumHorizontalGap * baseUnit}px ${mediumWrapperGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        padding: `${smallHorizontalGap * baseUnit}px ${smallWrapperGap * baseUnit}px ${smallHorizontalGap * baseUnit}px ${smallWrapperGap * baseUnit}px`,
      },
    },
    titleWrapper: {
      padding: '5px 10px',
      backgroundColor: titleBackgroundColor,
    },
  });
};

export const SectionHeaderWithBackground: React.FunctionComponent<SectionHeaderWithBackgroundComponentProps> = props => {
  const themeProps = useTheme<SectionHeaderWithBackgroundThemeProps>(componentConfigurationKey);
  const { children, paddingFactors, backgroundComponent } = props;
  const BackImageComponent = backgroundComponent;
  const style = createStyles(themeProps);
  const paddingStyle = getLayoutComponentPadding(paddingFactors);
  return (
    <ErrorBoundary>
      <div className={css(paddingStyle, style.container)}>
        <div className={css(style.titleItem)}>
          <BackImageComponent />
          <div className={css(style.titleWrapper)}>{children}</div>
        </div>
      </div>
    </ErrorBoundary>
  );
};
