import * as React from 'react';
import { css } from 'aphrodite/no-important';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import {
  PostRecommendationsDataProps,
  useRecommendationScrollTrigger,
  getSectionStyle,
  createPlaceholders,
  PostRecommendationsThemeProps,
} from './PostRecommendations.utils';
import { PostRecommendationsHeader } from './PostRecommendationsHeader';
import { SixSixArticleList } from '../articleLists/SixSixArticleList';
import { useTheme } from '../../../theming/useTheme';
import { EventProvider } from '../../../analytics/analytics';

export const SixSixPostRecommendations: React.FunctionComponent<PostRecommendationsDataProps> = props => {
  const { sectionName, sectionCards, onScrolledIntoView } = props;
  const { baseUnit } = useTheme<PostRecommendationsThemeProps>();
  const memoizedOnScrolledIntoView = React.useCallback(onScrolledIntoView, []);
  const wrapper = useRecommendationScrollTrigger(memoizedOnScrolledIntoView);
  const sectionStyle = getSectionStyle(baseUnit);

  return (
    <ErrorBoundary>
      <section ref={wrapper} className={css(sectionStyle.section)}>
        <EventProvider category="Recommendations" action="click">
          <PostRecommendationsHeader header={sectionName} />
          {sectionCards && sectionCards.length !== 0 ? <SixSixArticleList cardsProps={sectionCards} /> : createPlaceholders(12)}
        </EventProvider>
      </section>
    </ErrorBoundary>
  );
};
