import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';

const getStyle = (showImageHeightByAspectRatio: boolean) => StyleSheet.create({
  wrap: {
    position: 'relative',
    display: showImageHeightByAspectRatio ? 'block' : 'flex',
  },
});

interface RelativeWrapperProps {
  showImageHeightByAspectRatio?: boolean;
  children: React.ReactNode;
}

export const RelativeWrapper = ({ showImageHeightByAspectRatio = false, children }: RelativeWrapperProps) => {
  const style = getStyle(showImageHeightByAspectRatio);
  return (
    <div className={css(style.wrap)}>
      {children}
    </div>
  );
};

interface ConditionalVideoWrapProps {
  shouldWarp: boolean;
  showImageHeightByAspectRatio?: boolean;
}

export const ConditionalRelativeWrap: React.FunctionComponent<ConditionalVideoWrapProps> = ({ shouldWarp, showImageHeightByAspectRatio, children }) => (shouldWarp ? <RelativeWrapper showImageHeightByAspectRatio={showImageHeightByAspectRatio}>{children}</RelativeWrapper> : <React.Fragment>{children}</React.Fragment>);
