import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { BaseUnit } from '../../theming/baseUnitDefinition';
import { useTheme } from '../../theming/useTheme';
import { MEDIA_BREAKPOINTS } from '../../mediaQueries.const';

interface AMPRecommendationsAdvertisementProps {
    outbrainWidgetID: string;
    experiment?: string;
}

export const getStyle = (baseUnit: BaseUnit) => StyleSheet.create({
  wrapper: {
    [MEDIA_BREAKPOINTS.small]: {
      margin: `0 ${baseUnit}px`,
    },
  },
});

export const AMPRecommendationsAdvertisement: React.FunctionComponent<AMPRecommendationsAdvertisementProps> = props => {
  const { outbrainWidgetID, experiment } = props;
  const { baseUnit } = useTheme();
  const style = getStyle(baseUnit);
  return (
  // @ts-ignore
    <amp-embed
      width="100"
      height="100"
      type="outbrain"
      layout="responsive"
      data-widgetIds={outbrainWidgetID}
      data-block-on-consent="default"
      data-external-id={experiment}
      class={css(style.wrapper)}
    />
  );
};
