import { Store } from 'redux';
import { setEventEmitter } from './eventEmitters';
import { AppActions, AppState } from '../../store/initialStateRegistration';
import {
  ClientAPIReadEventNames,
  ClientAPIWriteEventNames,
  handlerCreatorsForReadEvents,
  handlerCreatorsForWriteEvents,
} from './handlerCreators';
import { ClientAPIEventNames } from './events';

declare global {
  interface Window {
    mmClientApi?: Array<any> | { push: (eventName: string, params: any) => void };
    __mmClientApiSubscriptions__?: Map<string, any>;
  }
}

const isNewChunk = (index: number, chunkSize: number) => index % chunkSize === 0;

const getSubscribedEventsInChuncks = (items: any[], chunkSize: number) => {
  let currentChunk: any[] = [];
  return items.reduce((chunks: any[][], item, index) => {
    if (isNewChunk(index, chunkSize)) {
      currentChunk = [item];
      chunks.push(currentChunk);
    } else {
      currentChunk.push(item);
    }
    return chunks;
  }, []);
};

const handleEvent = (eventName: string, params: any) => {
  if (!window.__mmClientApiSubscriptions__) {
    window.__mmClientApiSubscriptions__ = new Map();
  }
  if (!window.__mmClientApiSubscriptions__.get(eventName)) {
    window.__mmClientApiSubscriptions__.set(eventName, []);
  }
  window.__mmClientApiSubscriptions__.get(eventName).push(params);
};

const innerInitClientApi = () => {
  const isMMClientApiUsedBeforeInitialization = window.mmClientApi && Array.isArray(window.mmClientApi);
  const isMMClientApiInitialized = window.mmClientApi && !Array.isArray(window.mmClientApi) && window.mmClientApi.push;
  const initialization = () => {
    window.mmClientApi = {
      push: (eventName: string, params: any) => handleEvent(eventName, params),
    };
  };
  if (isMMClientApiUsedBeforeInitialization) {
    getSubscribedEventsInChuncks((window.mmClientApi as Array<any>), 2).forEach(([eventName, params]) => handleEvent(eventName, params));
    initialization();
  } else if (!isMMClientApiInitialized) {
    initialization();
  }
};

export const initClientApiForWriteEvents = (clientAPIEvents: Array<ClientAPIEventNames>, store: Store<AppState, AppActions>) => {
  const clientAPIWriteEvents = Object.keys(handlerCreatorsForWriteEvents);
  const handlerByEventName = (eventName: ClientAPIWriteEventNames) => handlerCreatorsForWriteEvents[eventName](store);
  innerInitClientApi();
  clientAPIEvents.forEach(name => {
    if (clientAPIWriteEvents.includes(name)) {
      setEventEmitter(name, handlerByEventName(name as ClientAPIWriteEventNames));
    }
  });
};

export const initClientApiForReadEvents = (clientAPIEvents: Array<ClientAPIEventNames>, store: Store<AppState, AppActions>) => {
  const clientAPIReadEvents = Object.keys(handlerCreatorsForReadEvents);
  const handlerByEventName = (eventName: ClientAPIReadEventNames) => handlerCreatorsForReadEvents[eventName](store);
  innerInitClientApi();
  clientAPIEvents.forEach(name => {
    if (clientAPIReadEvents.includes(name)) {
      setEventEmitter(name, handlerByEventName(name as ClientAPIReadEventNames));
    }
  });
};
