import { TypographySize } from 'mm-theme-configuration/dist/consts';

export const componentConfigThemeKey = 'threeRowsHeader';

export interface ThreeRowsHeaderThemeProps {
    firstLineBackgroundColor: string;
    secondRowHeightInLargeScreen: number;
    secondRowHeightInSmallScreen: number;
    secondRowHeightInMediumScreen: number;
    secondRowLinkFontSizeLarge: TypographySize;
    secondRowLinkFontSizeMedium: TypographySize;
    secondRowLinkFontSizeSmall: TypographySize;
    secondLineTextColor: string;
    secondLineBackgroundColor: string;
    secondLineSelectedIndicatorColor: string;
    thirdLineTextColor: string;
    thirdLineTextColorHover: string;
    thirdLineBackgroundColor: string;
    thirdLineSelectedIndicatorColor: string;
    signInTextColor: string;
    signInBackgroundColor: string;
    signInBorderColor: string;
    subscribeTextColor: string;
    subscribeBackgroundColor: string;
    subscribeBorderColor: string;
    iconColor: string;
}
