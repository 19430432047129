import * as React from 'react';
import { css } from 'aphrodite/no-important';
import {
  getBackgroundColorStyle,
  getSectionStyle,
  SectionStyleProps,
  SectionThemeProps,
  SectionWidthProps,
} from '../pageLayouts/layout.utils';
import { useTheme } from '../../../theming/useTheme';
import { LARGE_SCREEN_SIZE } from '../../../mediaQueries.const';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';

const componentConfigThemeKey = 'section';

const getMaxWidth = (theme: SectionWidthProps, themeConfigurationPropKey: keyof SectionWidthProps | undefined) => {
  if (typeof themeConfigurationPropKey === 'string' && themeConfigurationPropKey in theme) {
    return theme[themeConfigurationPropKey];
  }

  return LARGE_SCREEN_SIZE;
};

export const Section: React.FunctionComponent<SectionStyleProps> = props => {
  const { children, themeConfigurationPropKey } = props;
  const theme = useTheme<SectionThemeProps>(componentConfigThemeKey);
  const sectionStyle = getSectionStyle(getMaxWidth(theme, themeConfigurationPropKey));
  const additionalStyle = getBackgroundColorStyle(theme.backgroundColor);

  return (
    <ErrorBoundary>
      <section className={css(sectionStyle, additionalStyle)}>
        {children}
      </section>
    </ErrorBoundary>
  );
};
