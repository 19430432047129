import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useTheme } from '../../../theming/useTheme';
import {
  SecondaryLinksLogicProps,
} from '../../components/navigation/header/oldHeader/secondaryHeader/SecondaryLinks';
import { maxVisibleLinksOnLargeAndXLarge } from '../../components/navigation/header/oldHeader/secondaryHeader/SecondaryHeader';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { AMPMoreLinks } from './AMPMoreLinks';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { AMPSecondaryLink } from './AMPSecondaryLink';
import { NavigationLink } from '../../components/navigation/header/oldHeader/primaryHeader/PrimaryHeader';
import { HeaderHeightsPerBreakpoint } from '../../components/navigation/header/oldHeader/oldHeader.utils';
import { HeaderThemeProps } from '../../components/navigation/header/Header.theme';

interface AMPSecondaryLinksDataProps {
  moreLinksText: string;
  links: NavigationLink[];
  pagePath: string;
  headerHeights: HeaderHeightsPerBreakpoint;
}

export type AMPSecondaryLinksProps = AMPSecondaryLinksDataProps & SecondaryLinksLogicProps;

export const getContainerStyle = (baseUnit: BaseUnit) => {
  return StyleSheet.create({
    container: {
      display: 'grid',
      gridAutoFlow: 'column',
      alignItems: 'center',
      height: '100%',
      gridColumnGap: `${baseUnit}px`,
      [MEDIA_BREAKPOINTS.medium]: {
        overflowX: 'auto',
      },
      [MEDIA_BREAKPOINTS.small]: {
        overflowX: 'auto',
      },
    },
  });
};

export const AMPSecondaryLinks: React.FunctionComponent<AMPSecondaryLinksProps> = props => {
  const { moreLinksText, links, pagePath, headerHeights } = props;
  const { baseUnit } = useTheme<HeaderThemeProps>();
  const moreLinks = links.length > maxVisibleLinksOnLargeAndXLarge ? links.slice(maxVisibleLinksOnLargeAndXLarge - 1) : [];
  const style = getContainerStyle(baseUnit);
  return (
    <React.Fragment>
      <div className={css(style.container)}>
        {links.map((link, index) => <AMPSecondaryLink link={link} index={index} key={index} linksLength={links.length} pagePath={pagePath} />)}
      </div>
      {moreLinks.length > 0 ? <AMPMoreLinks links={moreLinks} moreLinksText={moreLinksText} pagePath={pagePath} headerHeights={headerHeights} /> : null}
    </React.Fragment>
  );
};
