import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import {
  Author, clickableLabelsStyles,
  MainCategoryData,
  showMetadataInLargeScreenSize,
  showMetadataInMediumScreenSize,
  showMetadataInSmallScreenSize,
} from '../cards.utils';
import { useTheme } from '../../../../theming/useTheme';
import { componentConfigThemeKey, HorizontalCardThemeProps } from './horizontalCard.theme';
import { TextNextToImage } from '../TextNextToImage';
import { DynamicSemanticTag } from '../../../../typography/semanticTags/DynamicSemanticTag';
import { semanticCSS } from '../../../../typography/semanticTags';
import { Link } from '../../link/Link';
import { MEDIA_BREAKPOINTS } from '../../../../mediaQueries.const';
import { baseUnit } from '../../../../theming/baseUnitDefinition';

interface MainCategoryProps{
  isClickable: boolean;
  link? : string;
  displayName? : string;
}

const createMainCategoryStyle = (
  showMainCategoryInLargeScreen: boolean,
  showMainCategoryInMediumScreen: boolean,
  showMainCategoryInSmallScreen: boolean,
  mainCategoryHorizontalGapLarge: number,
  mainCategoryHorizontalGapMedium: number,
  mainCategoryHorizontalGapSmall: number,
  mainCategoryVerticalGapLarge: number,
  mainCategoryVerticalGapMedium: number,
  mainCategoryVerticalGapSmall: number,
  mainCategoryBackgroundColor: string,
  mainCategoryTextColor: string,
  horizontalGapLarge: number,
  horizontalGapMedium: number,
  horizontalGapSmall: number,
) => StyleSheet.create({
  style: {
    position: 'relative',
    backgroundColor: mainCategoryBackgroundColor,
    color: mainCategoryTextColor,
    width: 'fit-content',
    [MEDIA_BREAKPOINTS.large]: {
      display: showMainCategoryInLargeScreen ? 'block' : 'none',
      padding: `${mainCategoryHorizontalGapLarge * baseUnit}px ${mainCategoryVerticalGapLarge * baseUnit}px`,
      marginBottom: `${horizontalGapLarge * baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      display: showMainCategoryInMediumScreen ? 'block' : 'none',
      padding: `${mainCategoryHorizontalGapMedium * baseUnit}px ${mainCategoryVerticalGapMedium * baseUnit}px`,
      marginBottom: `${horizontalGapMedium * baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      display: showMainCategoryInSmallScreen ? 'block' : 'none',
      padding: `${mainCategoryHorizontalGapSmall * baseUnit}px ${mainCategoryVerticalGapSmall * baseUnit}px`,
      marginBottom: `${horizontalGapSmall * baseUnit}px`,
    },
  },
}).style;

const MainCategory: React.FunctionComponent<MainCategoryProps> = ({ isClickable, link, displayName }) => {
  const { showMainCategoryInLargeScreen,
    showMainCategoryInMediumScreen,
    showMainCategoryInSmallScreen,
    mainCategoryHorizontalGapLarge,
    mainCategoryHorizontalGapMedium,
    mainCategoryHorizontalGapSmall,
    mainCategoryVerticalGapLarge,
    mainCategoryVerticalGapMedium,
    mainCategoryVerticalGapSmall,
    mainCategoryBackgroundColor,
    mainCategoryTextColor,
    horizontalGapLarge,
    horizontalGapMedium,
    mainCategorySemanticTag,
    mainCategoryFontSizeLarge,
    mainCategoryFontSizeMedium,
    mainCategoryFontSizeSmall,
    horizontalGapSmall } = useTheme<HorizontalCardThemeProps>(componentConfigThemeKey);
  const mainCategoryStyle = createMainCategoryStyle(
    showMainCategoryInLargeScreen,
    showMainCategoryInMediumScreen,
    showMainCategoryInSmallScreen,
    mainCategoryHorizontalGapLarge,
    mainCategoryHorizontalGapMedium,
    mainCategoryHorizontalGapSmall,
    mainCategoryVerticalGapLarge,
    mainCategoryVerticalGapMedium,
    mainCategoryVerticalGapSmall,
    mainCategoryBackgroundColor,
    mainCategoryTextColor,
    horizontalGapLarge,
    horizontalGapMedium,
    horizontalGapSmall,
  );
  const { labelsLink } = clickableLabelsStyles();

  return (
    <DynamicSemanticTag
      semanticTag={mainCategorySemanticTag}
      fontSizeLarge={mainCategoryFontSizeLarge}
      fontSizeMedium={mainCategoryFontSizeMedium}
      fontSizeSmall={mainCategoryFontSizeSmall}
      styles={semanticCSS(mainCategoryStyle)}
    >
      {isClickable ? <Link className={css(labelsLink)} href={link} title={displayName} /> : null}
      {displayName}
    </DynamicSemanticTag>
  );
};

interface TextElementProps {
  title: string;
  brandName?: string;
  description?: string;
  timestamp?: string;
  timestampISO?: string;
  sponsoredText?: string;
  mainCategory: MainCategoryData | null;
  showMainCategory: boolean;
  isMainCategoryClickable: boolean;
  authors: Author[];
  isAnyAuthorClickable: boolean;
  useFormatMinutesHoursAgo?: boolean;
}

export const HorizontalCardTextElement: React.FunctionComponent<TextElementProps> = ({
  title,
  brandName,
  description,
  timestamp,
  timestampISO,
  sponsoredText,
  mainCategory,
  showMainCategory,
  isMainCategoryClickable,
  isAnyAuthorClickable,
  authors,
  useFormatMinutesHoursAgo,
}) => {
  const {
    titleFontSizeLarge,
    titleFontSizeSmall,
    titleFontSizeMedium,
    showAuthorInLargeScreen,
    showAuthorInMediumScreen,
    showAuthorInSmallScreen,
    showDateInLargeScreen,
    showDateInMediumScreen,
    showDateInSmallScreen,
    showDescriptionInLargeScreen,
    showDescriptionInMediumScreen,
    showDescriptionInSmallScreen,
    authorColor,
    dateColor,
    pipeColor,
    descriptionColor,
    metadataFontSizeLarge,
    metadataFontSizeMedium,
    metadataFontSizeSmall,
    showMetadataOnBottom,
    metadataMarginTop,
    descriptionFontSizeLarge,
    descriptionFontSizeMedium,
    descriptionFontSizeSmall,
    horizontalGapLarge,
    horizontalGapMedium,
    horizontalGapSmall,
    verticalGapLarge,
    verticalGapMedium,
    verticalGapSmall,
    titleSemanticTag,
    descriptionSemanticTag,
    metadataSemanticTag,
    numberOfLinesForTitle,
    numberOfLinesForDescription,
    numberOfLinesForAuthors,
    titleLineHeightLarge,
    titleLineHeightMedium,
    titleLineHeightSmall,
  } = useTheme<HorizontalCardThemeProps>(componentConfigThemeKey);
  const showMetadataInLargeScreen = showMetadataInLargeScreenSize(showAuthorInLargeScreen, showDateInLargeScreen);
  const showMetadataInMediumScreen = showMetadataInMediumScreenSize(showAuthorInMediumScreen, showDateInMediumScreen);
  const showMetadataInSmallScreen = showMetadataInSmallScreenSize(showAuthorInSmallScreen, showDateInSmallScreen);
  const mainCategoryProps = {
    displayName: mainCategory?.displayName,
    link: mainCategory?.link,
    isClickable: isMainCategoryClickable,
  };
  return (
    <TextNextToImage
      {...{
        title,
        brandName,
        authors,
        description,
        timestamp,
        timestampISO,
        sponsoredText,
        horizontalGapLarge,
        horizontalGapMedium,
        horizontalGapSmall,
        verticalGapLarge,
        verticalGapMedium,
        verticalGapSmall,
        titleSemanticTag,
        titleFontSizeMedium,
        titleFontSizeSmall,
        titleFontSizeLarge,
        numberOfLinesForTitle,
        showDescriptionInLargeScreen,
        showDescriptionInMediumScreen,
        showDescriptionInSmallScreen,
        descriptionSemanticTag,
        descriptionFontSizeInSmallScreen: descriptionFontSizeSmall,
        descriptionFontSizeInLargeScreen: descriptionFontSizeMedium,
        descriptionFontSizeInMediumScreen: descriptionFontSizeLarge,
        descriptionColor,
        numberOfLinesForDescription,
        showAuthorInLargeScreen,
        showAuthorInMediumScreen,
        showAuthorInSmallScreen,
        showDateInLargeScreen,
        showDateInMediumScreen,
        showDateInSmallScreen,
        metadataSemanticTag,
        metadataFontSizeLarge,
        metadataFontSizeMedium,
        metadataFontSizeSmall,
        showMetadataInLargeScreen,
        showMetadataInMediumScreen,
        showMetadataInSmallScreen,
        showMetadataOnBottom,
        metadataMarginTop,
        authorColor,
        numberOfLinesForAuthors,
        pipeColor,
        dateColor,
        isClickableAuthors: isAnyAuthorClickable,
        useFormatMinutesHoursAgo,
        titleLineHeightLarge,
        titleLineHeightMedium,
        titleLineHeightSmall,
      }}
    >
      {showMainCategory ? <MainCategory {...mainCategoryProps} /> : null}
    </TextNextToImage>
  );
};
