import { predicates } from '../predicates/index';
import { selectors } from '../selectors/index';
import { TRAFFIC_SOURCES } from '../constants';

export const influencer = {
  rule: predicates.or(
    predicates.searchParamsIncludeKey('a_aid'),
    predicates.and(
      predicates.pathnameIncludesKey('partners'),
      predicates.pathnameIncludesAfterKey('partners'),
    ),
  ),
  extractor: (traffic) => {
    const trafficSource = TRAFFIC_SOURCES.INFLUENCER;
    const trafficId = selectors.searchParamValue(traffic, 'a_aid')
      || selectors.afterPathnameKey(traffic, 'partners');

    return {
      trafficSource,
      trafficId,
    };
  },
};
