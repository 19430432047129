import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { BlockWidths } from '../components/pageLayouts/BlockTypes.utils';
import { createBlockStyle } from '../articleComponents/blockMutualStyle';
import { AdditionalStyleSheet } from '../components.utils';

interface OpenwebLiveblogProps {
  defaultBlocksWidths: BlockWidths;
  additionalStyle: AdditionalStyleSheet;
}

const getStyle = (defaultBlocksWidths: BlockWidths) => {
  return StyleSheet.create({
    style: {
      ...createBlockStyle(defaultBlocksWidths),
    },
  }).style;
};

export const OpenwebLiveblog: React.FunctionComponent<OpenwebLiveblogProps> = props => {
  const { defaultBlocksWidths, additionalStyle } = props;
  return <div className={css(additionalStyle.style, getStyle(defaultBlocksWidths))} id="mm-poc-openweb-liveblog" />;
};
