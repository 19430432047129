import * as React from 'react';
import { css } from 'aphrodite/no-important';
import { getSectionStyle, MaxWidthValues } from '../pageLayouts/layout.utils';
import { OptionalAdditionalStyle } from '../../components.utils';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';

interface SectionWithTitleDataProps {
  maxWidth?: MaxWidthValues;
}

export type SectionWithTitleProps = SectionWithTitleDataProps & OptionalAdditionalStyle;

export const SectionWithTitle: React.FunctionComponent<SectionWithTitleProps> = props => {
  const { maxWidth, children, additionalStyle } = props;
  const sectionStyle = getSectionStyle(maxWidth);

  return (
    <ErrorBoundary>
      {
      additionalStyle ? (
        <section className={css(sectionStyle, additionalStyle.style)}>
          {children}
        </section>
      )
        : (
          <section className={css(sectionStyle)}>
            {children}
          </section>
        )
    }
    </ErrorBoundary>
  );
};
