import * as React from 'react';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { createImageWidths } from '../../partials/image/image.utils';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { LayoutComponentBaseProps } from '../pageLayouts/layout.utils';
import { OnTopCard, OnTopCardComponentThemeProps, componentConfigThemeKey as fullWidthOTCcomponentConfigThemeKey } from '../../partials/cards/onTopCard/OnTopCard';

interface FullWidthOTCDataProps extends LayoutComponentBaseProps{
    cards: Array<CardComponentDataProps> | null;
}

const imageWidths = createImageWidths(540, 1080, 1080);

export const FullWidthOTC: React.FunctionComponent<FullWidthOTCDataProps> = props => {
  const { cards } = props;
  const themeContext = useThemeOverride<OnTopCardComponentThemeProps>({
    titleFontSizeLarge: 'gigantic',
    titleFontSizeMedium: 'gigantic',
    titleFontSizeSmall: 'gigantic',
    metadataFontSizeLarge: 'big',
    metadataFontSizeMedium: 'big',
    metadataFontSizeSmall: 'big',
    titleAlignment: 'CENTER',
    metadataAlignment: 'CENTER',
    aspectRatioLarge: { x: 2, y: 1 },
    aspectRatioMedium: { x: 2, y: 1 },
    aspectRatioSmall: { x: 4, y: 3 },
    verticalGapLarge: 1,
    verticalGapMedium: 1,
    verticalGapSmall: 1,
    horizontalGapLarge: 2,
    horizontalGapMedium: 0.75,
    horizontalGapSmall: 0.75,
  }, fullWidthOTCcomponentConfigThemeKey);
  return (
    <ErrorBoundary>
      {cards && cards.length > 0
            && (
              <ThemeContext.Provider value={themeContext}>
                <OnTopCard {...cards[0]} imageWidths={imageWidths} lazyLoad />
              </ThemeContext.Provider>
            ) }
    </ErrorBoundary>
  );
};
