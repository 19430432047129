import { predicates } from '../predicates/index';
import { selectors } from '../selectors/index';
import { TRAFFIC_SOURCES } from '../constants';

export const syndication = {
  rule: predicates.and(
    predicates.pathnameIncludesKey('synd'),
    predicates.searchParamsIncludeKey('partner'),
  ),
  extractor: (traffic) => {
    const trafficSource = TRAFFIC_SOURCES.SYNDICATION;
    const trafficId = selectors.searchParamValue(traffic, 'partner');

    return {
      trafficSource,
      trafficId,
    };
  },
};
