export function markPerformance(name: string) {
  if (typeof window !== 'undefined' && typeof window.performance !== 'undefined' && typeof window.performance.mark !== 'undefined') {
    window.performance.mark(name);
  }
}

export function measurePerformance(name: string, start: string, end: string) {
  if (typeof window !== 'undefined' && typeof window.performance !== 'undefined' && typeof window.performance.measure !== 'undefined') {
    window.performance.measure(name, start, end);
  }
}
