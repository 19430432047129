import React from 'react';
import { StyleSheet } from 'aphrodite/no-important';
import { useTheme } from '../../../theming/useTheme';
import { H1, P, semanticCSS } from '../../../typography/semanticTags';
import { componentConfigThemeKey } from './About.theme';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { TITLE } from './About.strings';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';

interface AboutIntroThemeProps {
  titleColor: string;
  textColor: string;
}

export interface AboutIntroProps {
  about: string;
}

const getStyles = (titleColor: string, textColor: string) => StyleSheet.create({
  title: {
    color: titleColor,
    lineHeight: '50px',
    marginTop: '32px',
    marginBottom: '10px',
    [MEDIA_BREAKPOINTS.small]: {
      marginTop: '16px',
    },
  },
  text: {
    color: textColor,
    marginBottom: '24px',
  },
});

export const AboutIntro = ({ about }: AboutIntroProps) => {
  const { titleColor, textColor } = useTheme<AboutIntroThemeProps>(componentConfigThemeKey);
  const styles = getStyles(titleColor, textColor);

  return (
    <ErrorBoundary>
      <H1 styles={semanticCSS(styles.title)}>{TITLE}</H1>
      <P styles={semanticCSS(styles.text)}>{about}</P>
    </ErrorBoundary>
  );
};
