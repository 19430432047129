import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';

export enum Directions {
  UP = '180deg',
  DOWN = '0deg',
  LEFT = '90deg',
  RIGHT = '270deg',
}

interface ChevronIconProps {
  direction?: Directions;
  color?: string;
}

const getStyle = (direction: Directions) => StyleSheet.create({
  caret: {
    transform: `rotate(${direction})`,
    transition: 'transform 400ms',
  },
});

export const ChevronIcon: React.FunctionComponent<ChevronIconProps> = (props: ChevronIconProps) => {
  const { direction = Directions.DOWN, color = '#FFF' } = props;

  return (
    <svg className={css(getStyle(direction).caret)} width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="M11,8.09909655 L6.5,13 L5,11.3663655 L8.09099026,8 L5,4.63363448 L6.5,3 L11,7.90090345 L10.9090097,8 L11,8.09909655 Z" fill={color} fillRule="nonzero" transform="rotate(90 8 8)" />
      </g>
    </svg>
  );
};
