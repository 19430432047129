import * as React from 'react';
import { css, CSSProperties, StyleSheet } from 'aphrodite/no-important';
import { ALIGNMENT } from 'mm-theme-configuration/src/consts';
import { ALIGNMENT_OPTIONS, TYPOGRAPHY_SIZE } from 'mm-theme-configuration/dist/consts';
import { extractWrapperAndContentFromHtml, MandatoryAdditionalStyle } from '../components.utils';
import { useTheme } from '../../theming/useTheme';
import { ErrorBoundary } from '../errorHandling/ErrorBoundary';
import { semanticCSS } from '../../typography/semanticTags';
import { BlockWidths } from '../components/pageLayouts/BlockTypes.utils';
import { createBlockStyle } from './blockMutualStyle';
import { TranslatedFontSize } from '../../theming/fontSizeTranslator';
import { MEDIA_BREAKPOINTS } from '../../mediaQueries.const';
import { ArticleBlockThemeProps, componentConfigurationKey } from './ArticleBlock.theme';
import { BaseUnit } from '../../theming/baseUnitDefinition';
import { QuoteIcon } from './QuoteIcon';
import { getStyleBySize } from '../../typography/semanticTags/semanticTags.utils';


interface QuoteComponentDataProps {
  text: string;
  dataId?: string;
  defaultBlocksWidths: BlockWidths;
  cite: string;
}

type QuoteComponentProps = QuoteComponentDataProps & MandatoryAdditionalStyle;

const createCiteStyle = (fontSizesStyles: TranslatedFontSize): { [key: string]: CSSProperties } => {
  const citeFontSize = fontSizesStyles[TYPOGRAPHY_SIZE.BIG];
  const { style: { fontFamily, fontWeight }, breakpoints: { large, medium, small } } = citeFontSize;
  const fontFamilyConverted = fontFamily as unknown as CSSProperties;
  const fontWeightConverted = fontWeight as unknown as CSSProperties;
  return {
    fontFamily: fontFamilyConverted,
    fontWeight: fontWeightConverted,
    [MEDIA_BREAKPOINTS.large]: {
      ...large,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      ...medium,
    },
    [MEDIA_BREAKPOINTS.small]: {
      ...small,
    },
  };
};

const getStyles = (baseUnit: BaseUnit, textColor: string, citeColor: string, borderColor: string, alignment: ALIGNMENT, quoteShowIconAndBorder: boolean, fontSizesStyles: TranslatedFontSize, defaultBlocksWidths: BlockWidths) => {
  const citeFontsSizes = createCiteStyle(fontSizesStyles);

  return StyleSheet.create({
    wrapper: {
      position: 'relative',
      marginTop: `${baseUnit}px`,
      borderBottom: quoteShowIconAndBorder ? `1px solid ${borderColor}` : 'unset',
      ...createBlockStyle(defaultBlocksWidths),
    },
    text: {
      fontStyle: 'italic',
      textAlign: alignment === ALIGNMENT_OPTIONS.CENTER ? 'center' : 'left',
      color: textColor,
      margin: `${baseUnit * 0.5}px 0px`,
    },
    cite: {
      textAlign: alignment === ALIGNMENT_OPTIONS.CENTER ? 'center' : 'left',
      color: citeColor,
      marginTop: `${baseUnit * 0.5}px`,
      display: 'block',
      width: 'auto',
      ...citeFontsSizes,
    },
    quoteTop: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
      ':before': {
        content: '""',
        display: 'block',
        height: '1px',
        borderTop: `1px solid ${borderColor}`,
        flex: '2 0 0',
      },
      ':after': {
        content: '""',
        display: 'block',
        height: '1px',
        borderTop: `1px solid ${borderColor}`,
        flex: '2 0 0',
      },
    },
  });
};

export const Quote: React.FunctionComponent<QuoteComponentProps> = props => {
  const { text, additionalStyle, dataId, defaultBlocksWidths, cite } = props;
  const { quoteTextColor, quoteCiteColor, quoteFontSizeLarge, quoteFontSizeMedium, quoteFontSizeSmall, quoteShowCite, quoteShowApostrophes, quoteAlignment, quoteIconColor, quoteBorderColor, quoteShowIconAndBorder, baseUnit, fontSizesStyles } = useTheme<ArticleBlockThemeProps>(componentConfigurationKey);
  const styles = getStyles(baseUnit, quoteTextColor, quoteCiteColor, quoteBorderColor, quoteAlignment, quoteShowIconAndBorder, fontSizesStyles, defaultBlocksWidths);
  const { wrapper: Wrapper, content } = extractWrapperAndContentFromHtml(text);
  const styleBySize = getStyleBySize(fontSizesStyles, { fontSizeSmall: quoteFontSizeSmall, fontSizeMedium: quoteFontSizeMedium, fontSizeLarge: quoteFontSizeLarge });

  return (
    <ErrorBoundary>
      <blockquote data-mm-id={dataId} className={css(styles.wrapper, additionalStyle.style)}>
        { quoteShowIconAndBorder ? (
          <div className={css(styles.quoteTop)}>
            <QuoteIcon iconColor={quoteIconColor} baseUnit={baseUnit} />
          </div>
        ) : null}
        <Wrapper
          {...{
            dangerouslySetInnerHTML: { __html: quoteShowApostrophes ? `"${content}"` : content },
            className: css(semanticCSS(styles.text), styleBySize),
            key: dataId,
          }}
        />
        {
          quoteShowCite && cite ? (
            <cite className={css(styles.cite)}>
                      -&nbsp;
              {cite}
            </cite>
          ) : null
          }
      </blockquote>
    </ErrorBoundary>
  );
};
