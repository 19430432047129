import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useTheme } from '../../theming/useTheme';
import { MEDIA_BREAKPOINTS } from '../../mediaQueries.const';
import { baseUnit } from '../../theming/baseUnitDefinition';
import { ErrorBoundary } from '../errorHandling/ErrorBoundary';
import { Link } from '../partials/link/Link';
import { semanticCSS, Span } from '../../typography/semanticTags';
import { MarginFactor } from '../components/pageLayouts/BlockTypes.utils';
import { ArticleBlockThemeProps, componentConfigurationKey } from './ArticleBlock.theme';
import { EventContext } from '../../analytics/analytics';

export interface BreadCrumbsProps {
  homeDisplayName: string;
  homeLink: string;
  categoryDisplayName: string;
  categoryLink: string;
  marginFactor: MarginFactor;
  isActive: boolean;
  isBottom?: boolean;
}

const creatStyle = (
  breadCrumbsTextColor: string,
  breadCrumbsInactiveTextColor: string,
  marginFactor: MarginFactor,
  isBottom: boolean,
) => {
  const { largeMarginFactor, mediumMarginFactor, smallMarginFactor } = marginFactor;
  return StyleSheet.create({
    root: { display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: isBottom ? baseUnit : 0,
      [MEDIA_BREAKPOINTS.large]: {
        marginLeft: largeMarginFactor * baseUnit,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        marginLeft: mediumMarginFactor * baseUnit,
      },
      [MEDIA_BREAKPOINTS.small]: {
        marginLeft: smallMarginFactor * baseUnit,
      },
    },
    text: {
      color: breadCrumbsTextColor,
      width: 'fit-content',
      display: 'flex',
      alignItems: 'flex-end',
      [MEDIA_BREAKPOINTS.large]: {
        marginLeft: 0.5 * baseUnit,
        marginRight: 0.5 * baseUnit,
        marginTop: 0.75 * baseUnit,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        marginLeft: 0.5 * baseUnit,
        marginRight: 0.5 * baseUnit,
        marginTop: 0.5 * baseUnit,
      },
      [MEDIA_BREAKPOINTS.small]: {
        marginLeft: 0.5 * baseUnit,
        marginRight: 0.5 * baseUnit,
        marginTop: 0.5 * baseUnit,
      },
    },
    link: {
      color: breadCrumbsTextColor,
      width: 'fit-content',
      display: 'block',
      [MEDIA_BREAKPOINTS.large]: {
        marginTop: 0.75 * baseUnit,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        marginTop: 0.5 * baseUnit,
      },
      [MEDIA_BREAKPOINTS.small]: {
        marginTop: 0.5 * baseUnit,
      },
      ':hover': {
        textDecoration: 'underline',
      },
    },
    inactive: {
      color: breadCrumbsInactiveTextColor,
      width: 'fit-content',
      display: 'block',
      [MEDIA_BREAKPOINTS.large]: {
        marginTop: 0.75 * baseUnit,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        marginTop: 0.5 * baseUnit,
      },
      [MEDIA_BREAKPOINTS.small]: {
        marginTop: 0.5 * baseUnit,
      },
    },
  });
};

export const BreadCrumbs: React.FunctionComponent<BreadCrumbsProps> = props => {
  const {
    homeDisplayName,
    homeLink,
    categoryDisplayName,
    categoryLink, marginFactor, isActive,
    isBottom = false,
  } = props;
  const {
    breadCrumbsTextColor,
    breadCrumbsInactiveTextColor,
    breadCrumbsFontSizeLarge,
    breadCrumbsFontSizeMedium,
    breadCrumbsFontSizeSmall,
  } = useTheme<ArticleBlockThemeProps>(componentConfigurationKey);
  const style = creatStyle(breadCrumbsTextColor, breadCrumbsInactiveTextColor, marginFactor, isBottom);
  const analyticsEventContext = {
    category: 'Article',
    action: 'Breadcrumbs clicked',
  };
  return (
    <ErrorBoundary>
      <EventContext.Provider value={analyticsEventContext}>
        <div className={css(style.root)}>
          <Link href={homeLink} className={css(style.link)}>
            <Span
              fontSizeLarge={breadCrumbsFontSizeLarge}
              fontSizeMedium={breadCrumbsFontSizeMedium}
              fontSizeSmall={breadCrumbsFontSizeSmall}
            >
              {homeDisplayName}
            </Span>
          </Link>
          <Span
            styles={semanticCSS(style.text)}
            fontSizeLarge={breadCrumbsFontSizeLarge}
            fontSizeMedium={breadCrumbsFontSizeMedium}
            fontSizeSmall={breadCrumbsFontSizeSmall}
          >
                        /
          </Span>
          {isActive ? (
            <Link href={categoryLink} className={css(style.link)}>
              <Span
                fontSizeLarge={breadCrumbsFontSizeLarge}
                fontSizeMedium={breadCrumbsFontSizeMedium}
                fontSizeSmall={breadCrumbsFontSizeSmall}
              >
                {categoryDisplayName}
              </Span>
            </Link>
          ) : (
            <Span
              styles={semanticCSS(style.inactive)}
              fontSizeLarge={breadCrumbsFontSizeLarge}
              fontSizeMedium={breadCrumbsFontSizeMedium}
              fontSizeSmall={breadCrumbsFontSizeSmall}
            >
              {categoryDisplayName}
            </Span>
          )}
        </div>
      </EventContext.Provider>
    </ErrorBoundary>
  );
};
