import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { TYPOGRAPHY_SIZE, TypographySize } from 'mm-theme-configuration/dist/consts';
import { useTheme } from '../../../../../theming/useTheme';
import { DropDownMenuLink } from './DropDownMenuLink';
import { ErrorBoundary } from '../../../../errorHandling/ErrorBoundary';
import { MEDIA_BREAKPOINTS } from '../../../../../mediaQueries.const';
import { createStyleForBoxShadow } from '../../../../../theming/boxShadow';
import { BaseUnit } from '../../../../../theming/baseUnitDefinition';
import { TranslatedFontSize } from '../../../../../theming/fontSizeTranslator';
import { NavigationLink } from './primaryHeader/PrimaryHeader';
import { componentConfigThemeKey, HeaderThemeProps } from '../Header.theme';

interface DropDownMenuProps {
  configThemeKey: string;
}

export interface DropDownMenuBaseProps {
  configThemeKey?: string;
  menuLinks: NavigationLink[];
  highlightActive?: boolean;
  activeEndpoint: string;
}

type ThemedDropDownMenu = DropDownMenuProps & DropDownMenuBaseProps;

const getStyle = (baseUnit: BaseUnit, menuBackgroundColor: string) => {
  return StyleSheet.create({
    menuWrapper: {
      position: 'absolute',
      top: '100%',
      width: '100%',
      minWidth: '160px',
      boxSizing: 'border-box',
      background: menuBackgroundColor,
      listStyle: 'none',
      padding: 0,
      margin: 0,
      display: 'flex',
      flexDirection: 'column',
      zIndex: 100,
      [MEDIA_BREAKPOINTS.large]: {
        padding: `${baseUnit / 4}px ${baseUnit / 2}px`,
      },
    },
  });
};

const getLinksList = (
  menuLinks: NavigationLink[],
  linkColor: string,
  hoverColor: string,
  baseUnit: BaseUnit,
  configThemeKey: string | undefined,
  activeEndpoint: string,
  fontSizesStyles: TranslatedFontSize,
  firstRowLinkFontSizeSmall: TypographySize,
  firstRowLinkFontSizeMedium: TypographySize,
  firstRowLinkFontSizeLarge: TypographySize,
) => {
  const isActive = (navigationLink: NavigationLink) => navigationLink.link.href === activeEndpoint;
  return menuLinks.map((navigationLink, index) => (
    <DropDownMenuLink
      key={index}
      href={navigationLink.link.href}
      text={navigationLink.link.text}
      linkColor={linkColor}
      hoverColor={hoverColor}
      activeColor={hoverColor}
      baseUnit={baseUnit}
      isActive={isActive(navigationLink)}
      configThemeKey={configThemeKey}
      fontSizesStyles={fontSizesStyles}
      fontSizeSmall={firstRowLinkFontSizeSmall}
      fontSizeMedium={firstRowLinkFontSizeMedium}
      fontSizeLarge={firstRowLinkFontSizeLarge}
    />
  ));
};

export const DropDownMenu: React.FunctionComponent<ThemedDropDownMenu> = props => {
  const { menuLinks, activeEndpoint, configThemeKey } = props;
  const themeProps = useTheme<HeaderThemeProps>(componentConfigThemeKey);
  const { editionsBoxShadowTypeNormal, baseUnit, fontSizesStyles, editionsIconNormalColor, editionsIconHoverColor, firstRowLinkFontSizeSmall = TYPOGRAPHY_SIZE.NORMAL, firstRowLinkFontSizeMedium = TYPOGRAPHY_SIZE.NORMAL, firstRowLinkFontSizeLarge = TYPOGRAPHY_SIZE.NORMAL, editionsMenuBackgroundColor } = themeProps;
  const style = getStyle(baseUnit, editionsMenuBackgroundColor);
  const boxShadowStyle = createStyleForBoxShadow({ boxShadowTypeNormal: editionsBoxShadowTypeNormal });
  return (
    <ErrorBoundary>
      <ul className={css(style.menuWrapper, boxShadowStyle.style)}>
        {getLinksList(menuLinks, editionsIconNormalColor, editionsIconHoverColor, baseUnit, configThemeKey, activeEndpoint, fontSizesStyles, firstRowLinkFontSizeSmall, firstRowLinkFontSizeMedium, firstRowLinkFontSizeLarge)}
      </ul>
    </ErrorBoundary>
  );
};
