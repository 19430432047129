import React, { useEffect, useState } from 'react';
import { AnimationDirection, AnimationDirectionType } from './menuAnimations.util';
import { HeaderHeightsPerBreakpoint } from './oldHeader/oldHeader.utils';
import { baseUnit } from '../../../../theming/baseUnitDefinition';

interface HeaderAnimationProps {
    headerHeights: HeaderHeightsPerBreakpoint;
}

export interface HeaderAnimationContext {
  menuFoldState: AnimationDirectionType;
  animationDuration: number;
}

const animationDuration = 200;

const HeaderAnimationContextInitialValue = {
  menuFoldState: AnimationDirection.NONE,
  animationDuration,
};

export const HeaderAnimationContext: React.Context<HeaderAnimationContext> = React.createContext(HeaderAnimationContextInitialValue);

export const HeaderAnimation: React.FunctionComponent<HeaderAnimationProps> = ({ children, headerHeights }) => {
  const firstMenuFoldThreshold = 640;
  const secondMenuFoldThreshold = 100;
  const scrollUpThreshold = -400;

  const [scrollTicking, setScrollTicking] = useState(false);
  const [scrolledUpPixels, setScrolledUpPixels] = useState(0);
  const [menuFirstFolded, setMenuFirstFolded] = useState(false);
  const [menuFoldState, setMenuFoldState] = useState(HeaderAnimationContextInitialValue.menuFoldState);

  const isScrollingUp = (deltaY: number) => {
    return deltaY < 0;
  };

  const isScrollingDown = (deltaY: number) => {
    return deltaY > 0;
  };

  const getMenuAnimation = (originalScrollPosition: number): AnimationDirectionType => {
    const currentScrollPosition = window.scrollY;
    const deltaY = currentScrollPosition - originalScrollPosition;
    if (currentScrollPosition > firstMenuFoldThreshold && !menuFirstFolded) {
      setMenuFirstFolded(true);
      return AnimationDirection.UP;
    }

    if (isScrollingUp(deltaY)) {
      setScrolledUpPixels(scrolledUpPixels + deltaY);
      if (scrolledUpPixels < scrollUpThreshold || currentScrollPosition <= headerHeights.small * baseUnit || currentScrollPosition <= headerHeights.medium) {
        return AnimationDirection.DOWN;
      }
    }

    if (isScrollingDown(deltaY) && menuFirstFolded && currentScrollPosition > secondMenuFoldThreshold) {
      setScrolledUpPixels(0);
      return AnimationDirection.UP;
    }

    return menuFoldState;
  };

  const scrollHandler = () => {
    if (!scrollTicking) {
      const originalY = window.scrollY;
      setScrollTicking(true);
      setTimeout(() => {
        const menuAnimation = getMenuAnimation(originalY);
        if (menuAnimation !== menuFoldState) {
          setMenuFoldState(menuAnimation);
        }
        setScrollTicking(false);
      }, 100);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);

    return () => window.removeEventListener('scroll', scrollHandler);
  });

  return (
    <HeaderAnimationContext.Provider value={{ menuFoldState, animationDuration }}>
      { children }
    </HeaderAnimationContext.Provider>
  );
};
