import { BaseUnit } from '../../../theming/baseUnitDefinition';

export const componentConfigThemeKeyForContent = 'mainContent' as const;
export const componentConfigThemeKeyForArticleContent = 'mainArticleContent' as const;

export type MainWrapperConfigThemeKey = typeof componentConfigThemeKeyForContent | typeof componentConfigThemeKeyForArticleContent;

export interface MainWrapperThemeProps {
  baseUnit: BaseUnit;
  backgroundColor: string;
  paddingFactor: number;
  maxWidth: number;
}
