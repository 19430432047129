import * as React from 'react';
import { ErrorReporting } from './ErrorReporting';

interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<{}, ErrorBoundaryState> {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    hasError: false,
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: object) {
    const errorReporter = this.context;
    errorReporter.reportException(error, info);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    return (hasError ? <div className="has-error" /> : <React.Fragment>{children}</React.Fragment>
    );
  }
}

ErrorBoundary.contextType = ErrorReporting;
