import device from 'current-device';
import { trafficSourceInfo } from 'traffic-source-analyzer';
import { AppDefaultNavigationState, AppState } from '../../../store/initialStateRegistration';
import {
  getEdition,
  getExperiments,
  getFacebookAppId,
  getImagesCdnHost,
  getIsFannation,
  getLanguage,
  getPageType,
  getResourceID,
  getSiteName,
} from '../../../store/config/config.selectors';
import { getCountryCode, getWazimoParams } from '../../../templates/templates.utils';
import {
  getArticleId,
  getBlockNames,
  getBody,
  getChannels,
  getCommercialTags,
  getTopic,
  getTags,
} from '../../../store/template/postPage/postPageAmsterdam/postPageAmsterdam.selectors';
import { getIdentifier } from '../userIdentifier';
import { readEventErrorReporter } from './readEventErrorReporter';
import { GET_PAGE_DATA } from '../events';
import { getSubHeader } from '../../../store/navigations/navigation/header/header.selectors';
import {
  getCustomVertical,
  getFiniteScrollArticlesURLs,
} from '../../../store/template/postPage/postPageOdesa/postPageOdesa.selectors';

function getCookieByName(name: string) {
  // @ts-ignore
  return (document.cookie.match(`(^|; )${name}=([^;]*)`) || 0)[2];
}

const createPageDataEventHandler = (state: AppState) => ({
  siteName: getSiteName(state),
  countryCode: getCountryCode(),
  blockNames: getBlockNames(state),
  blockMMDataIDs: (getBody(state) || []).map(block => block.dataId),
  language: getLanguage(state),
  tags: getCommercialTags(state),
  pageType: getPageType(state),
  articleId: getArticleId(state),
  distributionChannels: getChannels(state),
  platform: device.type,
  trafficSource: trafficSourceInfo.trafficSource,
  trafficSourceAndId: trafficSourceInfo.trafficSourceAndId,
  trafficId: trafficSourceInfo.trafficId,
  mmUserIdentifier: getIdentifier(),
  usState: getCookieByName('stateCode'),
  resourceID: getResourceID(state),
  facebookAppID: getFacebookAppId(state),
  imagesCDNHost: getImagesCdnHost(state),
  testValue: trafficSourceInfo.testValue,
  affiliateId: trafficSourceInfo.affiliateId,
  trafficSourceGA: trafficSourceInfo.trafficSourceGA,
  property: getEdition(state),
  subheaderLogoInLargeScreen: getSubHeader(state as AppDefaultNavigationState)?.logoForLargeScreen?.src,
  isArticleInFiniteScrollPage: (getFiniteScrollArticlesURLs(state) || []).length > 0,
  experiments: getExperiments(state),
  topic: getTopic(state),
  vertical: getCustomVertical(state),
  sessionId: getCookieByName('mm-session-id'),
  mmcookieUserID: getCookieByName('mm-user-id'),
  contentTags: getTags(state),
  isFannation: getIsFannation(state),
  ...getWazimoParams(),
});

export const getPageDataProtectedEventHandler = readEventErrorReporter(GET_PAGE_DATA, createPageDataEventHandler);
