import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { baseUnit } from '../../../theming/baseUnitDefinition';

interface WrapperForCardProps {
  boxShadowStyle: {
    style: object;
  };
  borderRadius: number;
  paddingLarge?: number;
  paddingMedium?: number;
  paddingSmall?: number;
}

const createStyle = ({ borderRadius, paddingLarge = 0, paddingMedium = 0, paddingSmall = 0 }: WrapperForCardProps) => StyleSheet.create({
  wrapper: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
    borderRadius: `${borderRadius}px`,
    boxSizing: 'border-box',
    background: 'transparent',
    [MEDIA_BREAKPOINTS.large]: {
      padding: `${paddingLarge * baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      padding: `${paddingMedium * baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      padding: `${paddingSmall * baseUnit}px`,
    },
  },
});

export const WrapperForCard: React.FunctionComponent<WrapperForCardProps> = props => {
  const { boxShadowStyle, children } = props;
  const style = createStyle(props);
  return (
    <article className={css(boxShadowStyle.style, style.wrapper)}>
      {children}
    </article>
  );
};
