import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { VerticalCard } from '../../partials/cards/verticalCard/VerticalCard';
import { createImageWidths } from '../../partials/image/image.utils';
import { useTheme } from '../../../theming/useTheme';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { baseUnit } from '../../../theming/baseUnitDefinition';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { VerticalCardThemeProps, componentConfigThemeKey as verticalCardConfigThemeKey } from '../../partials/cards/verticalCard/VerticalCard.theme';

export const componentConfigThemeKey = 'fourVCForOneThird';

interface FourVCDataProps {
    cards: Array<CardComponentDataProps>;
}

export interface FourVCThemeProps {
    largeHorizontalGap: number;
    mediumVerticalGap: number;
    mediumHorizontalGap: number;
    smallHorizontalGap: number;
}

const createStyle = (themeProps: FourVCThemeProps) => {
  const { largeHorizontalGap, mediumHorizontalGap, mediumVerticalGap, smallHorizontalGap } = themeProps;
  return StyleSheet.create({
    wrapper: {
      display: 'grid',
      [MEDIA_BREAKPOINTS.large]: {
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto',
        gridRowGap: `${largeHorizontalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: 'auto',
        gridRowGap: `${mediumHorizontalGap * baseUnit}px`,
        gridColumnGap: `${mediumVerticalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto',
        gridRowGap: `${smallHorizontalGap * baseUnit}px`,
      },
    },
  });
};

const imageWidths = createImageWidths(360, 360, 360);

export const FourVCForOneThird: React.FunctionComponent<FourVCDataProps> = props => {
  const { cards } = props;
  const themeProps = useTheme<FourVCThemeProps>(componentConfigThemeKey);
  const style = createStyle(themeProps);
  const themeContextVC = useThemeOverride<VerticalCardThemeProps>({
    titleFontSizeLarge: 'bigger',
    titleFontSizeMedium: 'bigger',
    titleFontSizeSmall: 'bigger',
    titleSemanticTag: 'h4',
    metadataSemanticTag: 'h5',
    metadataFontSizeLarge: 'big',
    metadataFontSizeMedium: 'big',
    metadataFontSizeSmall: 'big',
    showAuthorInLargeScreen: false,
    showDateInLargeScreen: false,
  }, verticalCardConfigThemeKey);
  return (
    <ErrorBoundary>
      { cards && cards.length > 0
      && (
      <div className={css(style.wrapper)}>
        <ThemeContext.Provider value={themeContextVC}>
          <VerticalCard {...cards[0]} imageWidths={imageWidths} />
          <VerticalCard {...cards[1]} imageWidths={imageWidths} />
          <VerticalCard {...cards[2]} imageWidths={imageWidths} />
          <VerticalCard {...cards[3]} imageWidths={imageWidths} />
        </ThemeContext.Provider>
      </div>
      ) }
    </ErrorBoundary>
  );
};
