import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { ImageProps, AMPImage } from './AMPImage';
import { useTheme } from '../../theming/useTheme';
import { AdditionalStyleSheet } from '../components.utils';
import { Media, HTMLCoverBlock, JwCoverBlock } from '../articleComponents/articleMainImage/ArticleMainImage';
import { AMPJWPlayer } from './media/AMPJWPlayer';
import { JwBlock, HTMLBlock, MMPlayerBlock } from '../components/pageLayouts/BlockTypes.utils';
import { AMPYoutube } from './media/AMPYoutube';
import { AMPMMPlayer } from './media/AMPMMPlayer';
import { BaseUnit } from '../../theming/baseUnitDefinition';

interface AMPCoverDataProps {
  media?: HTMLCoverBlock | JwCoverBlock | null;
}

type AMPMainImageProps = ImageProps & AMPCoverDataProps;

const getStyles = () => StyleSheet.create({
  wrapper: {
    position: 'relative',
  },
});

const getMainImageStyle = (baseUnit: BaseUnit) => {
  return StyleSheet.create({
    style: {
      marginTop: baseUnit,
      marginBottom: baseUnit * 0.25,
      paddingRight: baseUnit,
      paddingLeft: baseUnit,
    },
  });
};

const getBlockStyle = () => {
  return StyleSheet.create({
    style: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
    },
  });
};

const getMedia = (media: Media) => {
  const { type } = media;
  const blockStyle = getBlockStyle() as AdditionalStyleSheet;

  switch (type) {
    case 'jw': {
      const { videoId, playerId } = media as JwBlock;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return <AMPJWPlayer videoId={videoId} playerId={playerId!} additionalStyle={blockStyle} />;
    }

    case 'youtube': {
      const { mediaId } = media as HTMLBlock;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return <AMPYoutube mediaId={mediaId!} additionalStyle={blockStyle} />;
    }

    case 'mmPlayer': {
      const { mediaId, playerId } = media as MMPlayerBlock;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return <AMPMMPlayer playlistId={mediaId!} playerId={playerId} additionalStyle={blockStyle} />;
    }

    default: return null;
  }
};

export const AMPCover: React.FunctionComponent<AMPMainImageProps> = props => {
  const { baseUnit } = useTheme();
  const { media } = props;
  const styles = getStyles();
  const additionalStyle = getMainImageStyle(baseUnit) as AdditionalStyleSheet;
  return (
    <div className={css(styles.wrapper)}>
      <AMPImage preload {...props} additionalStyle={additionalStyle} />
      {media && getMedia(media)}
    </div>
  );
};
