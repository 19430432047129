import { css, StyleSheet } from 'aphrodite/no-important';
import React from 'react';
import { SocialLink } from '../navigation/footer/AdditionalFooterSections/FooterSocialLinks';
import { AuthorPremiumThemeProps, componentConfigThemeKey } from './AuthorPremium.theme';
import { baseUnit } from '../../../theming/baseUnitDefinition';
import { useTheme } from '../../../theming/useTheme';
import { Link } from '../../partials/link/Link';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';

interface AuthorPremiumSocialLinksProps {
  socialButtons: SocialLink[];
}

const createSocialButtonStyle = (socialIconsHoverColor: string, socialIconsNormalColor: string) => {
  return StyleSheet.create({
    style: {
      height: `${baseUnit * 1}px`,
      fill: socialIconsNormalColor,
      ':hover': {
        fill: socialIconsHoverColor,
      },
    },
  }).style;
};

const createSocialButtonsWrapperStyle = (numSocialLinks: number) => {
  return StyleSheet.create({
    style: {
      marginTop: `${baseUnit * 1.5}px`,
      display: 'grid',
      gridColumnGap: `${baseUnit * 1.5}px`,
      gridTemplateColumns: `repeat(${numSocialLinks}, auto)`,
    },
  }).style;
};

export const AuthorPremiumSocialLinks: React.FunctionComponent<AuthorPremiumSocialLinksProps> = props => {
  const { socialButtons } = props;
  const { socialIconsHoverColor, socialIconsNormalColor } = useTheme<AuthorPremiumThemeProps>(componentConfigThemeKey);
  const socialButtonStyle = createSocialButtonStyle(socialIconsHoverColor, socialIconsNormalColor);
  const socialButtonsWrapperStyle = createSocialButtonsWrapperStyle(socialButtons.length);
  return (
    <ErrorBoundary>
      <div className={css(socialButtonsWrapperStyle)}>
        {socialButtons.map((link, index) => {
          const svg = link.src.replace(/<svg/g, `<svg class="${css(socialButtonStyle)}"`);
          return (
            <Link
              href={link.href}
              key={index}
              rel="noopener"
              target="_blank"
              dangerouslySetInnerHTML={{ __html: svg }}
              alt={link.alt}
            />
          );
        })}
      </div>
    </ErrorBoundary>
  );
};
