import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { hex2rgba } from '../../theming/theming.utils';

const CardOverlayStyles = (cardOverlayColor: string) => {
  return StyleSheet.create({
    style: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      bottom: '0px',
      background: `linear-gradient(0deg, ${hex2rgba(cardOverlayColor, 0.7)} 0%, rgba(0,0,0,0) 100%)`,
    },
  });
};

interface CardOverlayComponentProps {
  overlayColor: string;
}

export const CardOverlay: React.FunctionComponent<CardOverlayComponentProps> = ({ overlayColor }) => {
  const cardOverlayStyle = CardOverlayStyles(overlayColor);
  return (
    <div className={css(cardOverlayStyle.style)} />
  );
};
