import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Colors } from 'mm-theme-configuration';
import { TYPOGRAPHY_SIZE } from 'mm-theme-configuration/dist/consts';
import { CardComponentDataProps } from '../cards.utils';
import { LetterCardText } from './LetterCardText';
import { Link } from '../../link/Link';
import { baseUnit } from '../../../../theming/baseUnitDefinition';
import { MEDIA_BREAKPOINTS } from '../../../../mediaQueries.const';
import { Image } from '../../image/Image';
import { createImageAspectRatios, createImageWidths } from '../../image/image.utils';
import { useTheme } from '../../../../theming/useTheme';
import { P, semanticCSS } from '../../../../typography/semanticTags';
import { TranslatedFontSize } from '../../../../theming/fontSizeTranslator';

interface LetterCardProps {
    card: CardComponentDataProps;
    title: string;
}


const createStyle = (colors: Colors, fontSizesStyles: TranslatedFontSize) => StyleSheet.create({
  wrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    background: 'transparent',
    [MEDIA_BREAKPOINTS.large]: {
      padding: `${baseUnit * 1.2}px ${baseUnit * 2}px ${baseUnit * 2}px ${baseUnit * 2}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      padding: `${baseUnit * 1.2}px ${baseUnit * 2}px ${baseUnit * 2}px ${baseUnit * 2}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      padding: `${baseUnit * 1.2}px ${baseUnit}px ${baseUnit * 2}px ${baseUnit}px`,
    },
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 40px 40px 40px',
    border: `1px solid ${colors.darkGrey}`,
    borderTop: '0px',
    [MEDIA_BREAKPOINTS.large]: {
      flexDirection: 'row',
    },
    [MEDIA_BREAKPOINTS.medium]: {
      flexDirection: 'row',
    },
    [MEDIA_BREAKPOINTS.small]: {
      flexDirection: 'column',
    },
  },
  image: {
    height: '100%',
    width: '100%',
    [MEDIA_BREAKPOINTS.large]: {
      maxWidth: '272px',
      marginRight: `${baseUnit * 2}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      maxWidth: '272px',
      marginRight: `${baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      maxWidth: '100%',
      marginRight: 0,
      marginBottom: `${baseUnit}px`,
    },
  },
  title: {
    color: colors.darkGrey,
    flexShrink: 0,
    paddingBottom: '24px',
    margin: '0px 20px',

  },
  border: {
    width: '100%',
    borderTop: `1px solid ${colors.darkGrey}`,
    marginTop: `calc(${fontSizesStyles.large.breakpoints.large.lineHeight}/2)`,
    [MEDIA_BREAKPOINTS.medium]: {
      marginTop: `calc(${fontSizesStyles.large.breakpoints.medium.lineHeight}/2)`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      marginTop: `calc(${fontSizesStyles.large.breakpoints.small.lineHeight}/2)`,
    },
  },
  borderLeft: {
    borderLeft: `1px solid ${colors.darkGrey}`,
  },
  borderRight: {
    borderRight: `1px solid ${colors.darkGrey}`,

  },
  titleWrapper: {
    display: 'flex',
  },
});

export const LetterCard = ({ card, title }: LetterCardProps) => {
  const imageWidths = createImageWidths(180, 180, 335);
  const { colors, fontSizesStyles }: {colors: Colors; fontSizesStyles: TranslatedFontSize } = useTheme();
  const style = createStyle(colors, fontSizesStyles);

  const largeFontSizes = {
    fontSizeSmall: TYPOGRAPHY_SIZE.LARGE,
    fontSizeMedium: TYPOGRAPHY_SIZE.LARGE,
    fontSizeLarge: TYPOGRAPHY_SIZE.LARGE,
  };

  return (
    <article className={css(style.wrapper)}>
      <div className={css(style.titleWrapper)}>
        <div className={css(style.border, style.borderLeft)} />
        <P {...largeFontSizes} styles={semanticCSS(style.title)}>{title}</P>
        <div className={css(style.border, style.borderRight)} />
      </div>
      <Link
        href={card.articleUrl}
        className={css(style.card)}
      >
        <div className={css(style.image)}>
          <Image
            {...card.image}
            imageWidths={imageWidths}
            imageAspectRatios={createImageAspectRatios({ x: 1, y: 1 }, { x: 1, y: 1 }, { x: 1, y: 1 })}
          />
        </div>
        <LetterCardText card={card} />
      </Link>
    </article>
  );
};
