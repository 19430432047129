import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { TypographySize } from 'mm-theme-configuration/dist/consts';
import { useTheme } from '../../../theming/useTheme';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { baseUnit } from '../../../theming/baseUnitDefinition';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import {
  componentConfigThemeKey as onTopCardConfigThemeKey,
  OnTopCard,
  OnTopCardComponentThemeProps,
} from '../../partials/cards/onTopCard/OnTopCard';
import { createImageWidths } from '../../partials/image/image.utils';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';

export const componentConfigThemeKey = 'oneThreeOTC';

export interface OneThreeOTCThemeProps {
  largeVerticalGap: number;
  largeHorizontalGap: number;
  mediumVerticalGap: number;
  mediumHorizontalGap: number;
  smallHorizontalGap: number;
  minorCardsSmallHorizontalCard: number;
  titleFontSizeLarge: TypographySize;
  titleFontSizeMedium: TypographySize;
  titleFontSizeSmall: TypographySize;
}

interface OneThreeOTCDataProps {
  cards: Array<CardComponentDataProps>;
}

const imageWidthsFirstCard = createImageWidths(360, 720, 1080);
const imageWidthsSecondAndThirdCards = createImageWidths(360, 360, 360);
const imageWidthsFourthCard = createImageWidths(360, 720, 360);

const getStyle = (theme: OneThreeOTCThemeProps) => {
  const { largeHorizontalGap, largeVerticalGap, mediumHorizontalGap, mediumVerticalGap, smallHorizontalGap, minorCardsSmallHorizontalCard } = theme;
  const firstCardArea = 'first-card';
  const adArea = 'ad';
  const minorCardsArea = 'minor-cards';
  return StyleSheet.create({
    wrapper: {
      [MEDIA_BREAKPOINTS.large]: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto',
        gridColumnGap: `${largeVerticalGap * baseUnit}px`,
        gridRowGap: `${largeHorizontalGap * baseUnit}px`,
        gridTemplateAreas: `'${firstCardArea}' '${adArea}' '${minorCardsArea}'`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto',
        gridColumnGap: `${mediumVerticalGap * baseUnit}px`,
        gridRowGap: `${mediumHorizontalGap * baseUnit}px`,
        gridTemplateAreas: `'${firstCardArea}' '${adArea}' '${minorCardsArea}'`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto',
        gridRowGap: `${smallHorizontalGap * baseUnit}px`,
        gridTemplateAreas: `'${firstCardArea}' '${adArea}' '${minorCardsArea}'`,
      },
    },
    minorCardsWrapper: {
      [MEDIA_BREAKPOINTS.large]: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gridTemplateRows: 'auto',
        gridColumnGap: `${largeVerticalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gridTemplateRows: 'auto',
        gridColumnGap: `${mediumVerticalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto',
        gridRowGap: `${minorCardsSmallHorizontalCard * baseUnit}px`,
      },
    },
    firstCard: {
      gridArea: firstCardArea,
    },
    ad: {
      gridArea: adArea,
    },
    minorCards: {
      gridArea: minorCardsArea,
    },
  });
};
export const OneThreeOTC: React.FunctionComponent<OneThreeOTCDataProps> = props => {
  const { cards, children } = props;
  const themeProps = useTheme<OneThreeOTCThemeProps>(componentConfigThemeKey);
  const style = getStyle(themeProps);
  const { titleFontSizeLarge, titleFontSizeMedium, titleFontSizeSmall } = themeProps;
  const themeContextFirstCard = useThemeOverride<OnTopCardComponentThemeProps>({
    titleFontSizeLarge,
    titleFontSizeMedium,
    titleFontSizeSmall,
    metadataFontSizeLarge: 'big',
    metadataFontSizeMedium: 'big',
    metadataFontSizeSmall: 'big',
    aspectRatioLarge: { x: 2, y: 1 },
    aspectRatioSmall: { x: 4, y: 5 },
    titleAlignment: 'CENTER',
    metadataAlignment: 'CENTER',
    verticalGapLarge: 1,
    verticalGapMedium: 1,
    verticalGapSmall: 1,
    horizontalGapLarge: 2,
    horizontalGapMedium: 0.75,
    horizontalGapSmall: 0.75,
  }, onTopCardConfigThemeKey);
  const themeContextMinorCards = useThemeOverride<OnTopCardComponentThemeProps>({
    titleFontSizeLarge,
    titleFontSizeMedium,
    titleFontSizeSmall,
    metadataFontSizeLarge: 'big',
    metadataFontSizeMedium: 'big',
    metadataFontSizeSmall: 'big',
    aspectRatioLarge: { x: 4, y: 5 },
    aspectRatioMedium: { x: 4, y: 5 },
    aspectRatioSmall: { x: 4, y: 5 },
    titleAlignment: 'CENTER',
    metadataAlignment: 'CENTER',
    verticalGapLarge: 1,
    verticalGapMedium: 1,
    verticalGapSmall: 1,
    horizontalGapLarge: 2,
    horizontalGapMedium: 0.75,
    horizontalGapSmall: 0.75,
  }, onTopCardConfigThemeKey);
  return (
    <ErrorBoundary>
      {cards && cards.length > 0
        && (
        <div className={css(style.wrapper)}>
          <ThemeContext.Provider value={themeContextFirstCard}>
            <div className={css(style.firstCard)}>
              <OnTopCard {...cards[0]} imageWidths={imageWidthsFirstCard} />
            </div>
          </ThemeContext.Provider>
          <div className={css(style.ad)}>
            { children }
          </div>
          <div className={css(style.minorCards, style.minorCardsWrapper)}>
            <ThemeContext.Provider value={themeContextMinorCards}>
              <OnTopCard {...cards[1]} imageWidths={imageWidthsSecondAndThirdCards} />
              <OnTopCard {...cards[2]} imageWidths={imageWidthsSecondAndThirdCards} />
              <OnTopCard {...cards[3]} imageWidths={imageWidthsFourthCard} />
            </ThemeContext.Provider>
          </div>
        </div>
        )}
    </ErrorBoundary>
  );
};
