export interface HeaderRowHeightFactors {
  large: number;
  medium: number;
  small: number;
}

export const reducedHeaderFirstRowHeightFactors: HeaderRowHeightFactors = {
  large: 0.75,
  medium: (2 / 3),
  small: (2 / 3),
};
export const reducedHeaderSecondRowHeightFactors: HeaderRowHeightFactors = {
  large: 0.5,
  medium: (2 / 3),
  small: (2 / 3),
};
