import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { VerticalCard } from '../../partials/cards/verticalCard/VerticalCard';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { useTheme } from '../../../theming/useTheme';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { createImageWidths } from '../../partials/image/image.utils';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { getLayoutComponentPadding, LayoutComponentBaseProps } from '../pageLayouts/layout.utils';
import { VerticalCardThemeProps, componentConfigThemeKey as verticalCardConfigThemeKey } from '../../partials/cards/verticalCard/VerticalCard.theme';

export const componentConfigThemeKey = 'fourVC';

export interface FourVCThemeProps {
    largeVerticalGap: number;
    largeHorizontalGap: number;
    mediumVerticalGap: number;
    mediumHorizontalGap: number;
    smallHorizontalGap: number;
    baseUnit: BaseUnit;
}

interface FourVCDataProps extends LayoutComponentBaseProps{
    cards: Array<CardComponentDataProps>;
}

const createStyle = (themeProps: FourVCThemeProps) => {
  const { largeHorizontalGap, largeVerticalGap, mediumHorizontalGap, mediumVerticalGap, smallHorizontalGap, baseUnit } = themeProps;
  return StyleSheet.create({
    wrapper: {
      display: 'grid',
      [MEDIA_BREAKPOINTS.large]: {
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: 'auto',
        gridRowGap: `${largeHorizontalGap * baseUnit}px`,
        gridColumnGap: `${largeVerticalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: 'auto',
        gridRowGap: `${mediumHorizontalGap * baseUnit}px`,
        gridColumnGap: `${mediumVerticalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto',
        gridRowGap: `${smallHorizontalGap * baseUnit}px`,
      },
    },
  });
};

const imageWidths = createImageWidths(360, 360, 540);

export const FourVC: React.FunctionComponent<FourVCDataProps> = props => {
  const { cards, paddingFactors } = props;
  const paddingStyle = getLayoutComponentPadding(paddingFactors);
  const themeProps = useTheme<FourVCThemeProps>(componentConfigThemeKey);
  const style = createStyle(themeProps);
  const themeContextVC = useThemeOverride<VerticalCardThemeProps>({
    titleFontSizeLarge: 'large',
    titleFontSizeMedium: 'bigger',
    titleFontSizeSmall: 'large',
    metadataFontSizeLarge: 'bigger',
    metadataFontSizeMedium: 'big',
    metadataFontSizeSmall: 'bigger',
  }, verticalCardConfigThemeKey);
  return (
    <ErrorBoundary>
      {cards && cards.length > 0
        && (
        <div className={css(paddingStyle, style.wrapper)}>
          <ThemeContext.Provider value={themeContextVC}>
            <VerticalCard {...cards[0]} imageWidths={imageWidths} />
            <VerticalCard {...cards[1]} imageWidths={imageWidths} />
            <VerticalCard {...cards[2]} imageWidths={imageWidths} />
            <VerticalCard {...cards[3]} imageWidths={imageWidths} />
          </ThemeContext.Provider>
        </div>
        ) }
    </ErrorBoundary>
  );
};
