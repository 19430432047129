import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { TypographySize } from 'mm-theme-configuration/dist/consts';
import { useTheme } from '../../../theming/useTheme';
import { AuthorsList } from '../../articleComponents/author/AuthorsList';
import { H4, semanticCSS } from '../../../typography/semanticTags';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { getFormattedPublishDate } from '../../../utils/time.utils';

export const componentConfigurationKey = 'articleTitle';

export interface ArticleAuthorTitleThemeProps {
  metaDataColor: string;
  baseUnit: BaseUnit;
  showTimestamp: boolean;
  authorTitleSizeInSmallScreen: TypographySize;
  authorTitleSizeMediumScreen: TypographySize;
  authorTitleSizInLargeScreen: TypographySize;
}

export interface ArticleAuthorsTitleProps {
  by: string;
  authors: AuthorsList | null;
  updatedAt: string;
  updatedAtISO: string;
  showUpdatedAt: boolean;
  createdAt: string;
  createdAtISO: string;
  useFormatMinutesHoursAgo?: boolean;
}

const createStyle = (metaDataColor: string, baseUnit: number) => {
  return StyleSheet.create({
    wrapper: {
      marginTop: `${baseUnit * 0.5}px`,
    },
    metaData: {
      color: metaDataColor,
    },
    sidesPadding: {
      marginTop: `${baseUnit * 0.5}px`,
      marginLeft: 0,
      marginRight: `${baseUnit * 4}px`,
      [MEDIA_BREAKPOINTS.small]: {
        marginRight: `${baseUnit * 3}px`,
        marginLeft: `${baseUnit}px`,
      },
    },
  });
};

const shouldDisplayAuthors = (authors: AuthorsList | null) => {
  return authors && authors.length > 0;
};

export const ArticleAuthorsTitle: React.FunctionComponent<ArticleAuthorsTitleProps> = props => {
  const { by, updatedAt, updatedAtISO, showUpdatedAt, createdAt, createdAtISO, useFormatMinutesHoursAgo, authors } = props;
  const { baseUnit } = useTheme();
  const { metaDataColor, showTimestamp, authorTitleSizeInSmallScreen, authorTitleSizeMediumScreen, authorTitleSizInLargeScreen } = useTheme<ArticleAuthorTitleThemeProps>(componentConfigurationKey);
  const style = createStyle(metaDataColor, baseUnit);
  const hasAuthors = shouldDisplayAuthors(authors);
  const separator = hasAuthors ? ' | ' : '';

  return (
    <div className={css(style.wrapper)}>
      <H4 styles={semanticCSS(style.metaData, style.sidesPadding)} fontSizeLarge={authorTitleSizInLargeScreen} fontSizeMedium={authorTitleSizeMediumScreen} fontSizeSmall={authorTitleSizeInSmallScreen}>
        {hasAuthors && <AuthorsList by={by} authors={authors} />}
        {showTimestamp && !showUpdatedAt ? <time dateTime={createdAtISO}>{`${separator}${getFormattedPublishDate(createdAtISO, createdAt, useFormatMinutesHoursAgo)}`}</time> : null}
        {showTimestamp && showUpdatedAt ? <time dateTime={updatedAtISO}>{` ${separator}${getFormattedPublishDate(updatedAtISO, updatedAt, useFormatMinutesHoursAgo)}`}</time> : null}
      </H4>
    </div>
  );
};
