import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { TYPOGRAPHY_SIZE } from 'mm-theme-configuration/dist/consts';
import { MEDIA_BREAKPOINTS } from '../../../../mediaQueries.const';
import { AuthorsWithRoundImageClipMask } from './components/AuthorsWithRoundImageClipMask';
import { useTheme } from '../../../../theming/useTheme';
import { BaseUnit } from '../../../../theming/baseUnitDefinition';
import { AuthorsWithRoundImageAuthorList } from './components/AuthorsWithRoundImageAuthorList';
import { TranslatedFontSize } from '../../../../theming/fontSizeTranslator';
import { getStyleBySize } from '../../../../typography/semanticTags/semanticTags.utils';
import { Author, CLIP_PATH_LARGE, CLIP_PATH_SMALL, shouldDisplayUpdatedAt } from '../author.utils';
import AuthorsWithRoundImageAuthorImages from './components/AuthorsWithRoundImageAuthorImages';
import { getFormattedPublishDate } from '../../../../utils/time.utils';

export const componentConfigurationKey = 'authorsWithRoundImage';

interface AuthorsWithRoundImageProps {
  by: string;
  updatedText: string;
  authors: Author[] | null;
  updatedAt: string;
  updatedAtISO: string;
  createdAt: string;
  createdAtISO: string;
  showUpdatedAt: boolean;
  shouldHideAuthorImage?: boolean;
  useFormatMinutesHoursAgo?: boolean;
}

interface AuthorsWithRoundImageThemeProps {
  textColor: string;
  largeVerticalMarginFactor: number;
  mediumVerticalMarginFactor: number;
  smallVerticalMarginFactor: number;
  largeHorizontalMarginFactor: number;
  mediumHorizontalMarginFactor: number;
  smallHorizontalMarginFactor: number;
  baseUnit: BaseUnit;
  showTimestamp: boolean;
  fontSizesStyles: TranslatedFontSize;
}

const getTextStyle = (
  baseUnit: BaseUnit,
  textColor: string,
  smallVerticalMarginFactor: number,
  mediumVerticalMarginFactor: number,
  largeVerticalMarginFactor: number,
  smallHorizontalMarginFactor: number,
  mediumHorizontalMarginFactor: number,
  largeHorizontalMarginFactor: number,
) => StyleSheet.create({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    [MEDIA_BREAKPOINTS.large]: {
      margin: `${baseUnit * largeVerticalMarginFactor}px ${baseUnit * largeHorizontalMarginFactor}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      margin: `${baseUnit * mediumVerticalMarginFactor}px ${baseUnit * mediumHorizontalMarginFactor}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      margin: `${baseUnit * smallVerticalMarginFactor}px ${baseUnit * smallHorizontalMarginFactor}px`,
    },
  },
  textWithColor: {
    color: textColor,
  },
});

const shouldDisplayAuthors = (authors: Author[] | null) => {
  return authors && authors.length > 0;
};

export const AuthorsWithRoundImage: React.FunctionComponent<
  AuthorsWithRoundImageProps
> = props => {
  const {
    authors,
    by,
    updatedText,
    updatedAt,
    updatedAtISO,
    createdAt,
    createdAtISO,
    showUpdatedAt,
    shouldHideAuthorImage,
    useFormatMinutesHoursAgo,
    children,
  } = props;
  const {
    baseUnit,
    textColor,
    smallVerticalMarginFactor,
    mediumVerticalMarginFactor,
    largeVerticalMarginFactor,
    smallHorizontalMarginFactor,
    mediumHorizontalMarginFactor,
    largeHorizontalMarginFactor,
    showTimestamp,
    fontSizesStyles,
  } = useTheme<AuthorsWithRoundImageThemeProps>(componentConfigurationKey);
  const textStyle = getTextStyle(
    baseUnit,
    textColor,
    smallVerticalMarginFactor,
    mediumVerticalMarginFactor,
    largeVerticalMarginFactor,
    smallHorizontalMarginFactor,
    mediumHorizontalMarginFactor,
    largeHorizontalMarginFactor,
  );
  const hasAuthors = shouldDisplayAuthors(authors);
  const fontSizes = {
    fontSizeLarge: TYPOGRAPHY_SIZE.NORMAL,
    fontSizeMedium: TYPOGRAPHY_SIZE.NORMAL,
    fontSizeSmall: TYPOGRAPHY_SIZE.NORMAL,
  };
  return (
    <React.Fragment>
      <AuthorsWithRoundImageClipMask
        clipPathLarge={CLIP_PATH_LARGE}
        clipPathSmall={CLIP_PATH_SMALL}
      />
      <div className={css(textStyle.wrapper)}>
        {!shouldHideAuthorImage && (
          <AuthorsWithRoundImageAuthorImages
            authors={authors}
            componentConfigurationKey={componentConfigurationKey}
          />
        )}
        <div>
          <div>
            {hasAuthors ? (
              <AuthorsWithRoundImageAuthorList
                by={by}
                authors={authors}
                textColor={textColor}
              />
            ) : null}
          </div>
          {showTimestamp ? (
            <time
              dateTime={createdAtISO}
              className={css(textStyle.textWithColor, getStyleBySize(fontSizesStyles, fontSizes))}
            >
              {getFormattedPublishDate(createdAtISO, createdAt, useFormatMinutesHoursAgo)}
            </time>
          ) : null}
          {showTimestamp && shouldDisplayUpdatedAt(showUpdatedAt, updatedAt, createdAt) ? (
            <time
              dateTime={updatedAtISO}
              className={css(textStyle.textWithColor, getStyleBySize(fontSizesStyles, fontSizes))}
            >
              {` | ${updatedText} ${getFormattedPublishDate(updatedAtISO, updatedAt, useFormatMinutesHoursAgo)}`}
            </time>
          ) : null}
        </div>
        {children}
      </div>
    </React.Fragment>
  );
};
