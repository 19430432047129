import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useTheme } from '../../theming/useTheme';
import { ErrorBoundary } from '../errorHandling/ErrorBoundary';
import { H5, semanticCSS } from '../../typography/semanticTags';
import { BaseUnit } from '../../theming/baseUnitDefinition';
import { Link } from '../partials/link/Link';
import { Thumbnail } from '../partials/image/image.types';
import { getSvgWidthAndHeight } from './navigation/AMPLogo';
import { ArticleBlockThemeProps, componentConfigurationKey } from '../articleComponents/ArticleBlock.theme';

const logoHeight = 40;

export interface SponsorComponentDataProps {
  text: string;
  image?: Thumbnail;
  logo?: string;
  brandName?: string;
  href?: string;
  logoViewBox?: string;
}

const createSponsorStyle = (baseUnit: BaseUnit, textColor: string, sponsorBackgroundColor: string) => StyleSheet.create({
  text: {
    color: textColor,
    marginBottom: `${0.5 * baseUnit}px`,
  },
  wrapper: {
    textAlign: 'center',
    padding: `${baseUnit}px 0px`,
    backgroundColor: sponsorBackgroundColor,
  },
});

export const AMPSponsor: React.FunctionComponent<SponsorComponentDataProps> = props => {
  const { text, logo, brandName, href, logoViewBox } = props;
  const { sponsorTextColor, sponsorBackgroundColor, baseUnit } = useTheme<ArticleBlockThemeProps>(componentConfigurationKey);
  const style = createSponsorStyle(baseUnit, sponsorTextColor, sponsorBackgroundColor);
  const svgWidthAndHeight = logoViewBox ? getSvgWidthAndHeight(logoViewBox) : null;

  return (
    <ErrorBoundary>
      <div className={css(style.wrapper)}>
        <H5 styles={semanticCSS(style.text)}>
          {text}
        </H5>
        <Link href={href} rel="nofollow sponsored">
          {/*
  // @ts-ignore TS2339. */}
          {svgWidthAndHeight ? <amp-img class={css(style.image)} src={`data:image/svg+xml,${encodeURIComponent(logo)}`} alt={brandName} layout="fixed" height={`${logoHeight}`} width={`${(logoHeight * svgWidthAndHeight.width) / svgWidthAndHeight.height}`} /> : null}
        </Link>
      </div>
    </ErrorBoundary>
  );
};
