import * as React from 'react';
import { css } from 'aphrodite/no-important';
import { Helmet } from 'react-helmet';
import { AdditionalStyleSheet } from '../../components.utils';

interface AMPMMPlayerProps {
    additionalStyle: AdditionalStyleSheet;
    playerId?: string;
    playlistId?: string;
}

export const AMPMMPlayer: React.FunctionComponent<AMPMMPlayerProps> = props => {
  const { playerId, playlistId, additionalStyle } = props;
  return playerId && playlistId
    ? (
      <React.Fragment>
        <Helmet>
          <script
            async={undefined}
            custom-element="amp-minute-media-player"
            src="https://cdn.ampproject.org/v0/amp-minute-media-player-0.1.js"
          />
        </Helmet>
        <figure className={css(additionalStyle.style)}>
          {/*
         // @ts-ignore TS2339 */}
          <amp-minute-media-player
            width="16"
            height="9"
            layout="responsive"
            data-content-type="specific"
            data-content-id={`${playlistId}#${playerId}`}
          />
        </figure>
      </React.Fragment>
    ) : null;
};
