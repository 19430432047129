import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Colors } from 'mm-theme-configuration/dist/interfaces';
import { TYPOGRAPHY_SIZE } from 'mm-theme-configuration/dist/consts';
import { NavigationLink } from '../oldHeader/primaryHeader/PrimaryHeader';
import { Link } from '../../../../partials/link/Link';
import { useTheme } from '../../../../../theming/useTheme';
import { TranslatedFontSize } from '../../../../../theming/fontSizeTranslator';
import { getStyleBySize } from '../../../../../typography/semanticTags/semanticTags.utils';
import { MEDIA_BREAKPOINTS } from '../../../../../mediaQueries.const';
import {
  componentConfigThemeKey as threeRowsHeaderConfigThemeKey,
  ThreeRowsHeaderThemeProps,
} from './ThreeRowsHeader.theme';
import { isCurrentPage } from './header.util';
import { ThirdHeader } from './ThreeRowsHeader';
import { MegaMenuThirdRow } from './MegaMenuThirdRow';

interface ThirdRowHeaderProps {
  thirdHeader: ThirdHeader;
  windowsDetails: {origin: string; path: string; innerWidth: number};
}

const getThirdRowStyle = (
  thirdLineTextColor: string,
  thirdLineTextColorHover: string,
  thirdLineBackgroundColor: string,
  thirdLineSelectedIndicatorColor: string,
  colors: Colors,
) => {
  return StyleSheet.create({
    wrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0 16px',
      height: '48px',
      textTransform: 'capitalize',
      backgroundColor: thirdLineBackgroundColor,
      borderBottom: `1px solid ${colors.lightGrey}`,
      boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.20)',
      [MEDIA_BREAKPOINTS.medium]: {
        justifyContent: 'flex-start',
        whiteSpace: 'nowrap',
      },
      [MEDIA_BREAKPOINTS.small]: {
        overflowX: 'scroll',
        overflowY: 'hidden',
      },
    },
    linksList: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      columnGap: '16px',
      listStyle: 'none',
      whiteSpace: 'nowrap',
      padding: '0px',
      '::-webkit-scrollbar': {
        display: 'none',
      },
      scrollbarWidth: 'none',
    },
    title: {
      textTransform: 'uppercase',
      // @ts-ignore
      fontWeight: '600 !important',
    },
    separator: {
      backgroundColor: thirdLineTextColor,
      height: '12px',
      width: '1px',
      alignSelf: 'center',
    },
    liItem: {
      [MEDIA_BREAKPOINTS.small]: {
        paddingTop: '0',
      },
    },
    link: {
      color: thirdLineTextColor,
      height: '48px',
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      textDecoration: 'none',
      cursor: 'pointer',
      ':hover': {
        color: thirdLineTextColorHover,
      },
      [MEDIA_BREAKPOINTS.small]: {
        position: 'unset',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: '16px',
      },
    },
    indicator: {
      height: '3px',
      backgroundColor: thirdLineSelectedIndicatorColor,
      display: 'block',
      width: '100%',
      bottom: '0',
      position: 'absolute',
      [MEDIA_BREAKPOINTS.small]: {
        display: 'unset',
        position: 'unset',
      },
    },
  });
};

export const ThirdRowHeader: React.FunctionComponent<ThirdRowHeaderProps> = props => {
  const { thirdHeader, windowsDetails } = props;
  const { links, feedName } = thirdHeader;
  const { fontSizesStyles, colors }: {colors: Colors; fontSizesStyles: TranslatedFontSize } = useTheme();
  const {
    thirdLineTextColor,
    thirdLineTextColorHover,
    thirdLineBackgroundColor,
    thirdLineSelectedIndicatorColor } = useTheme<ThreeRowsHeaderThemeProps>(threeRowsHeaderConfigThemeKey);
  const fontStyle = getStyleBySize(fontSizesStyles, { fontSizeSmall: TYPOGRAPHY_SIZE.TINY, fontSizeMedium: TYPOGRAPHY_SIZE.TINY, fontSizeLarge: TYPOGRAPHY_SIZE.TINY });
  const style = getThirdRowStyle(thirdLineTextColor, thirdLineTextColorHover, thirdLineBackgroundColor, thirdLineSelectedIndicatorColor, colors);

  const hideScrollbarStyle = `
    .scrollBarWithHidden::-webkit-scrollbar {
      display: none;
    }

    .scrollBarWithHidden {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  `;

  return (
    <>
      <style dangerouslySetInnerHTML={{ __html: hideScrollbarStyle }} />
      <div className={`${css(style.wrapper, fontStyle)} scrollBarWithHidden`}>
        <ul className={css(style.linksList)}>
          {feedName && (
            <>
              <li className={css(style.title)}>{feedName}</li>
              <li className={css(style.separator)} />
            </>
          )}
          {links.map((navigationLink: NavigationLink, index: number) => {
            return (
              <li key={index} className={css(style.link, style.liItem)}>
                {navigationLink.link.href === '-' ? (
                  <MegaMenuThirdRow liLink={navigationLink} megaMenu={navigationLink.children} iconColor={thirdLineTextColor} windowsDetails={windowsDetails} />
                ) : (
                  <Link className={css(style.link, fontStyle)} href={navigationLink.link.href}>
                    <span>{navigationLink.link.text}</span>
                  </Link>
                )}
                {isCurrentPage(navigationLink, windowsDetails) && <span className={css(style.indicator)} />}
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};
