import * as React from 'react';

interface EventContextProps {
  category: string;
  action: string;
}

export const EventContext: React.Context<EventContextProps> = React.createContext({
  category: 'default category',
  action: '',
});

export const EventProvider: React.FunctionComponent<EventContextProps> = ({ children, category: newCategory, action: newAction }) => {
  const { category, action } = React.useContext(EventContext);
  const newValue = { category: newCategory || category, action: newAction || action };
  return <EventContext.Provider value={newValue}>{children}</EventContext.Provider>;
};
