import React from 'react';
import { MainCategoryContext, ThemeContext } from './ThemeProviderWithFonts';
import { ErrorReporting } from '../base/errorHandling/ErrorReporting';
import { baseUnit } from '../theming/baseUnitDefinition';

export const useTheme = <T extends object>(key?: string): T => {
  const reporter = React.useContext(ErrorReporting);
  const themeContext = React.useContext(ThemeContext);
  const mainCategoryContext = React.useContext(MainCategoryContext);

  // eslint-disable-next-line no-mixed-operators
  if (key && themeContext[key] || !key) {
    return {
      ...(key && themeContext[key]),
      fontSizesStyles: themeContext.fontSizesStyles,
      colors: themeContext.colors,
      baseUnit,
      ...mainCategoryContext,
    };
  }

  reporter.reportWarning(`cannot find key: ${key} in configuration`);
  return {} as T;
};
