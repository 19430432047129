import { InjectButtonParams } from './navigation.utils';

export const INJECT_BUTTON_TO_FOOTER_LINKS_SECTION = 'inject button to footer links section';
export interface InjectButtonToFooterLinksSectionAction {
  type: typeof INJECT_BUTTON_TO_FOOTER_LINKS_SECTION;
  text: string;
  onClick?: string;
  cssClassName?: string;
  attributes?: {[key: string]: string};
}
export const injectButtonToFooterLinksSection = (params: InjectButtonParams): InjectButtonToFooterLinksSectionAction => {
  const { text, onClick, cssClassName, attributes } = params;
  return {
    type: INJECT_BUTTON_TO_FOOTER_LINKS_SECTION,
    text,
    onClick,
    cssClassName,
    attributes,
  };
};

export const INJECT_VIDEO_LOGO = 'inject video logo';
export interface InjectVideoLogoParams {
  videoPath: string;
}
export interface InjectVideoLogoAction {
  type: typeof INJECT_VIDEO_LOGO;
  videoPath: string;
}
export const injectVideoLogo = (params: InjectVideoLogoParams): InjectVideoLogoAction => {
  const { videoPath } = params;
  return {
    type: INJECT_VIDEO_LOGO,
    videoPath,
  };
};

export const INJECT_BUTTON_TO_MENU = 'inject button to menu';
export interface InjectButtonToMenuAction {
  type: typeof INJECT_BUTTON_TO_MENU;
  onClick?: string;
  cssClassName?: string;
  attributes?: {[key: string]: string};
  text: string;
}
export const injectButtonToMenu = (params: InjectButtonParams): InjectButtonToMenuAction => {
  const { onClick, text, cssClassName, attributes } = params;
  return {
    type: INJECT_BUTTON_TO_MENU,
    onClick,
    text,
    cssClassName,
    attributes,
  };
};

export const INJECT_ACCESSIBILITY_ICON = 'inject accessibility icon';
export interface InjectAccessibilityIconAction {
  type: typeof INJECT_ACCESSIBILITY_ICON;
}
export const injectAccessibilityIcon = (): InjectAccessibilityIconAction => {
  return {
    type: INJECT_ACCESSIBILITY_ICON,
  };
};
