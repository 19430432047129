import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useTheme } from '../../../../../../theming/useTheme';
import { PrimaryLinks } from './PrimaryLinks';
import { Logo, LogoProps } from '../Logo';
import { Editions } from './Editions';
import { CtaButton, CtaDataProps } from '../../CtaButton';
import { Menu } from './menu/Menu';
import {
  HeaderHeightsPerBreakpoint,
  reducedHeaderFirstRowHeightFactorsForOldHeader,
} from '../oldHeader.utils';
import { ErrorBoundary } from '../../../../../errorHandling/ErrorBoundary';
import { HUGE_SCREEN_SIZE, MEDIA_BREAKPOINTS } from '../../../../../../mediaQueries.const';
import { VideoLogo, InjectedVideoLogoApiProps } from './VideoLogo';
import { createStyleForBoxShadow } from '../../../../../../theming/boxShadow';
import { BaseUnit } from '../../../../../../theming/baseUnitDefinition';
import { AnimationDirection, AnimationDirectionType } from '../../menuAnimations.util';
import { LinkButtonDataProps } from '../../../utils';
import { MenuLinkDataProps } from './menu/links/MenuSubLink';
import { componentConfigThemeKey, HeaderThemeProps } from '../../Header.theme';

interface MenuData {
  links: MenuLinkDataProps[];
  buttons?: Array<LinkButtonDataProps>;
}

interface Link {
  text: string;
  href: string;
}

export interface NavigationLink {
  link: Link;
  children: NavigationLink[] | null;
}

export interface PrimaryHeaderDataProps {
  links: NavigationLink[] | null;
  logo: LogoProps;
  editions: NavigationLink[] | null;
  cta: CtaDataProps | null;
  menu: MenuData | null;
  videoLogo: InjectedVideoLogoApiProps | null;
  menuAnimation?: AnimationDirectionType;
  reduceHeightAnimationDuration?: number;
}

interface PrimaryHeaderProps extends PrimaryHeaderDataProps{
  pagePath: string;
  editionEndpoint: string;
  headerHeights: HeaderHeightsPerBreakpoint;
  logoHeights: HeaderHeightsPerBreakpoint;
}

const getStyle = (baseUnit: BaseUnit, backgroundColor: string, menuAnimation: AnimationDirectionType, reduceHeightAnimationDuration: number, headerHeights: HeaderHeightsPerBreakpoint) => {
  return StyleSheet.create({
    headerWrapper: {
      position: 'relative',
      backgroundColor,
      zIndex: 100,
    },
    header: {
      [MEDIA_BREAKPOINTS.large]: {
        height: menuAnimation === AnimationDirection.UP ? `${headerHeights.large * baseUnit * reducedHeaderFirstRowHeightFactorsForOldHeader.large}px` : `${headerHeights.large * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        height: `${headerHeights.medium * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        height: `${headerHeights.small * baseUnit}px`,
      },
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: `${HUGE_SCREEN_SIZE}px`,
      margin: '0 auto',
      transition: `${reduceHeightAnimationDuration}ms`,
    },
    itemsBasicContainer: {
      display: 'grid',
      gridAutoFlow: 'column',
      height: '100%',
      width: 'fit-content',
      alignItems: 'center',
      gridColumnGap: `${baseUnit}px`,
    },
    itemsRightContainer: {
      [MEDIA_BREAKPOINTS.large]: {
        paddingRight: `${baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        paddingRight: `${baseUnit / 2}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        paddingRight: `${baseUnit / 2}px`,
      },
    },
    itemsLeftContainer: {
      paddingLeft: `${baseUnit}px`,
    },
  });
};

export const PrimaryHeader: React.FunctionComponent<PrimaryHeaderProps> = props => {
  const { links, logo, editions, cta, menu, videoLogo, pagePath, editionEndpoint, menuAnimation = AnimationDirection.NONE, reduceHeightAnimationDuration = 0, headerHeights, logoHeights } = props;
  const { baseUnit, firstRowBackgroundColor, firstRowBoxShadow } = useTheme<HeaderThemeProps>(componentConfigThemeKey);
  const style = getStyle(baseUnit, firstRowBackgroundColor, menuAnimation, reduceHeightAnimationDuration, headerHeights);
  const boxShadowStyle = createStyleForBoxShadow({ boxShadowTypeNormal: firstRowBoxShadow });

  return (
    <ErrorBoundary>
      <div className={css(style.headerWrapper, boxShadowStyle.style)}>
        <div className={css(style.header)}>
          <div className={css(style.itemsBasicContainer, style.itemsLeftContainer)}>
            {menu ? <Menu links={menu.links} editions={editions} editionEndpoint={editionEndpoint} buttons={menu.buttons} headerHeights={headerHeights} /> : null}
            {videoLogo
              ? <VideoLogo {...videoLogo} href={logo.href} menuAnimation={menuAnimation} reduceHeightAnimationDuration={reduceHeightAnimationDuration} headerHeights={headerHeights} />
              : <Logo {...logo} logoHeights={{ large: logoHeights.large, medium: logoHeights.medium, small: logoHeights.small }} menuAnimation={menuAnimation} reduceHeightAnimationDuration={reduceHeightAnimationDuration} />}
            {links ? <PrimaryLinks links={links} pagePath={pagePath} /> : null}
          </div>
          <div className={css(style.itemsBasicContainer, style.itemsRightContainer)}>
            {cta ? <CtaButton {...cta} /> : null}
            {editions ? <Editions editions={editions} editionEndpoint={editionEndpoint} /> : null}
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};
