import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { useTheme } from '../../../theming/useTheme';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';

interface SafeArticleContentWrapperProps {
  minHeightFactor: number;
}

const getStyles = (factor: number, baseUnit: BaseUnit) => StyleSheet.create({
  wrapper: {
    position: 'relative',
    [MEDIA_BREAKPOINTS.large]: {
      minHeight: `${factor * baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      minHeight: 'unset',
    },
    [MEDIA_BREAKPOINTS.small]: {
      minHeight: 'unset',
    },
  },
});

export const SafeArticleContentWrapper: React.FunctionComponent<SafeArticleContentWrapperProps> = ({ children, minHeightFactor }) => {
  const { baseUnit } = useTheme();
  const styles = getStyles(minHeightFactor, baseUnit);
  return <div className={css(styles.wrapper)}>{children}</div>;
};
