import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { TypographySize, RATIO } from 'mm-theme-configuration/dist/consts';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { OnTopCard, OnTopCardComponentThemeProps, componentConfigThemeKey as onTopCardConfigThemeKey } from '../../partials/cards/onTopCard/OnTopCard';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { createImageWidths, createImageHeightCalculationMethods } from '../../partials/image/image.utils';
import { HorizontalCard } from '../../partials/cards/horizontalCard/HorizontalCard';
import { componentConfigThemeKey as horizontalCardConfigThemeKey, HorizontalCardThemeProps } from '../../partials/cards/horizontalCard/horizontalCard.theme';
import { useTheme } from '../../../theming/useTheme';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { getLayoutComponentPadding, LayoutComponentBaseProps } from '../pageLayouts/layout.utils';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { baseUnit } from '../../../theming/baseUnitDefinition';

interface OneOTCThreeHCVerticalDataProps extends LayoutComponentBaseProps {
  cards: Array<CardComponentDataProps>;
}
export const componentConfigThemeKey = 'oneOTCThreeHCVertical';

export interface OneOTCThreeHCVerticalThemeProps {
  largeHorizontalGap: number;
  mediumHorizontalGap: number;
  smallHorizontalGap: number;
  titleFontSizeLargeForHorizontalCard: TypographySize;
  titleFontSizeMediumForHorizontalCard: TypographySize;
  titleFontSizeSmallForHorizontalCard: TypographySize;
  titleFontSizeSmallForOnTopCard: TypographySize;
  metadataFontSizeSmallForOnTopCard: TypographySize;
  mainCategoryFontSizeSmallForOnTopCard: TypographySize;
  verticalGapLargeForOnTopCard: number;
  horizontalGapLargeForOnTopCard: number;
  verticalGapMediumForOnTopCard: number;
  horizontalGapMediumForOnTopCard: number;
  metadataFontSizeLargeForHorizontalCard: TypographySize;
  metadataFontSizeMediumForHorizontalCard: TypographySize;
  metadataFontSizeSmallForHorizontalCard: TypographySize;
  mainCategoryFontSizeSmallForHorizontalCard: TypographySize;
  verticalGapLargeForHorizontalCard: number;
  verticalGapMediumForHorizontalCard: number;
  verticalGapSmallForHorizontalCard: number;
  horizontalGapLargeForHorizontalCard: number;
  horizontalGapMediumForHorizontalCard: number;
  aspectRatioSmallForHorizontalCard: RATIO;
  aspectRatioMediumForHorizontalCard: RATIO;
  aspectRatioLargeForHorizontalCard: RATIO;
  horizontalCardProportionsLarge: RATIO;
  horizontalCardProportionsMedium: RATIO;
  horizontalCardProportionsSmall: RATIO;
  showAuthorInLargeScreen: boolean;
  showAuthorInMediumScreen: boolean;
  showAuthorInSmallScreen: boolean;
  showDescriptionInLargeScreen: boolean;
  showDescriptionInMediumScreen: boolean;
  showDescriptionInSmallScreen: boolean;
  showDateInLargeScreen: boolean;
  showDateInMediumScreen: boolean;
  showDateInSmallScreen: boolean;
  dividerColor: string;
  dividerWidth: number;
  showDividersInLargeScreen: boolean;
  showDividersInMediumScreen: boolean;
  showDividersInSmallScreen: boolean;
}
const imageWidthsOnTopCard = createImageWidths(360, 720, 540);
const imageWidthsHorizontalCard = createImageWidths(360, 360, 360);

const getStyles = (themeProps: OneOTCThreeHCVerticalThemeProps) => {
  const { largeHorizontalGap, mediumHorizontalGap, smallHorizontalGap, dividerColor, dividerWidth, showDividersInLargeScreen, showDividersInMediumScreen, showDividersInSmallScreen } = themeProps;
  const dividerStyle = {
    backgroundColor: dividerColor,
    display: 'block',
    width: '100%',
    height: `${dividerWidth}px`,
  };
  return StyleSheet.create({
    section: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      [MEDIA_BREAKPOINTS.large]: {
        gridRowGap: `${largeHorizontalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        gridRowGap: `${mediumHorizontalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        gridRowGap: `${smallHorizontalGap * baseUnit}px`,
      },
    },
    horizontalCards: {
      display: 'grid',
      [MEDIA_BREAKPOINTS.large]: {
        gridTemplateRows: showDividersInLargeScreen ? 'repeat(2, 1fr auto) 1fr' : '1fr 1fr 1fr',
        gridRowGap: `${largeHorizontalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        gridTemplateRows: showDividersInMediumScreen ? 'repeat(2, 1fr auto) 1fr' : '1fr 1fr 1fr',
        gridRowGap: `${mediumHorizontalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        gridTemplateRows: showDividersInSmallScreen ? 'repeat(2, 1fr auto) 1fr' : '1fr 1fr 1fr',
        gridRowGap: `${smallHorizontalGap * baseUnit}px`,
      },
    },
    dividerBetweenCards: {
      [MEDIA_BREAKPOINTS.large]: showDividersInLargeScreen ? dividerStyle : { display: 'none' },
      [MEDIA_BREAKPOINTS.medium]: showDividersInMediumScreen ? dividerStyle : { display: 'none' },
      [MEDIA_BREAKPOINTS.small]: showDividersInSmallScreen ? dividerStyle : { display: 'none' },
    },
  });
};

export const OneOTCThreeHCVertical: React.FunctionComponent<OneOTCThreeHCVerticalDataProps> = props => {
  const { cards, paddingFactors = { large: 0.5, medium: 0.5, small: 0 } } = props;
  const themeProps = useTheme<OneOTCThreeHCVerticalThemeProps>(componentConfigThemeKey);
  const {
    titleFontSizeSmallForOnTopCard,
    metadataFontSizeSmallForOnTopCard,
    mainCategoryFontSizeSmallForOnTopCard,
    verticalGapLargeForOnTopCard,
    horizontalGapLargeForOnTopCard,
    verticalGapMediumForOnTopCard,
    horizontalGapMediumForOnTopCard,
    titleFontSizeLargeForHorizontalCard,
    titleFontSizeMediumForHorizontalCard,
    titleFontSizeSmallForHorizontalCard,
    metadataFontSizeLargeForHorizontalCard,
    metadataFontSizeMediumForHorizontalCard,
    metadataFontSizeSmallForHorizontalCard,
    mainCategoryFontSizeSmallForHorizontalCard,
    verticalGapLargeForHorizontalCard,
    verticalGapMediumForHorizontalCard,
    verticalGapSmallForHorizontalCard,
    horizontalGapLargeForHorizontalCard,
    horizontalGapMediumForHorizontalCard,
    aspectRatioSmallForHorizontalCard,
    aspectRatioMediumForHorizontalCard,
    aspectRatioLargeForHorizontalCard,
    horizontalCardProportionsSmall,
    horizontalCardProportionsMedium,
    horizontalCardProportionsLarge,
    showAuthorInLargeScreen,
    showAuthorInMediumScreen,
    showAuthorInSmallScreen,
    showDescriptionInLargeScreen,
    showDescriptionInMediumScreen,
    showDescriptionInSmallScreen,
    showDateInLargeScreen,
    showDateInMediumScreen,
    showDateInSmallScreen,
    showDividersInSmallScreen,
    showDividersInMediumScreen,
    showDividersInLargeScreen,
  } = themeProps;
  const showDivider = showDividersInLargeScreen || showDividersInMediumScreen || showDividersInSmallScreen;
  const styles = getStyles(themeProps);
  const paddingStyle = getLayoutComponentPadding(paddingFactors);

  const onTopCardHeightCalculation = createImageHeightCalculationMethods('ASPECT_RATIO', 'ASPECT_RATIO', 'ASPECT_RATIO');

  const themeContextOTC = useThemeOverride<OnTopCardComponentThemeProps>({
    titleFontSizeSmall: titleFontSizeSmallForOnTopCard,
    metadataFontSizeSmall: metadataFontSizeSmallForOnTopCard,
    mainCategoryFontSizeSmall: mainCategoryFontSizeSmallForOnTopCard,
    verticalGapLarge: verticalGapLargeForOnTopCard,
    horizontalGapLarge: horizontalGapLargeForOnTopCard,
    verticalGapMedium: verticalGapMediumForOnTopCard,
    horizontalGapMedium: horizontalGapMediumForOnTopCard,
  }, onTopCardConfigThemeKey);
  const themeContextHC = useThemeOverride<HorizontalCardThemeProps>({
    titleFontSizeLarge: titleFontSizeLargeForHorizontalCard,
    titleFontSizeMedium: titleFontSizeMediumForHorizontalCard,
    titleFontSizeSmall: titleFontSizeSmallForHorizontalCard,
    metadataFontSizeLarge: metadataFontSizeLargeForHorizontalCard,
    metadataFontSizeMedium: metadataFontSizeMediumForHorizontalCard,
    metadataFontSizeSmall: metadataFontSizeSmallForHorizontalCard,
    mainCategoryFontSizeSmall: mainCategoryFontSizeSmallForHorizontalCard,
    verticalGapLarge: verticalGapLargeForHorizontalCard,
    verticalGapMedium: verticalGapMediumForHorizontalCard,
    verticalGapSmall: verticalGapSmallForHorizontalCard,
    horizontalGapLarge: horizontalGapLargeForHorizontalCard,
    horizontalGapMedium: horizontalGapMediumForHorizontalCard,
    aspectRatioSmall: aspectRatioSmallForHorizontalCard,
    aspectRatioMedium: aspectRatioMediumForHorizontalCard,
    aspectRatioLarge: aspectRatioLargeForHorizontalCard,
    cardProportionsSmall: horizontalCardProportionsSmall,
    cardProportionsMedium: horizontalCardProportionsMedium,
    cardProportionsLarge: horizontalCardProportionsLarge,
    showAuthorInLargeScreen,
    showAuthorInMediumScreen,
    showAuthorInSmallScreen,
    showDescriptionInLargeScreen,
    showDescriptionInMediumScreen,
    showDescriptionInSmallScreen,
    showDateInLargeScreen,
    showDateInMediumScreen,
    showDateInSmallScreen,
  }, horizontalCardConfigThemeKey);

  return (
    <ErrorBoundary>
      {cards && cards.length > 0
      && (
      <div className={css(styles.section, paddingStyle)}>
        <ThemeContext.Provider value={themeContextOTC}>
          <OnTopCard {...cards[0]} imageWidths={imageWidthsOnTopCard} imageHeightCalculationMethods={onTopCardHeightCalculation} lazyLoad />
        </ThemeContext.Provider>
        <div className={css(styles.horizontalCards)}>
          <ThemeContext.Provider value={themeContextHC}>
            <HorizontalCard {...cards[1]} imageWidths={imageWidthsHorizontalCard} />
            {showDivider ? <div className={css(styles.dividerBetweenCards)} /> : null}
            <HorizontalCard {...cards[2]} imageWidths={imageWidthsHorizontalCard} />
            {showDivider ? <div className={css(styles.dividerBetweenCards)} /> : null}
            <HorizontalCard {...cards[3]} imageWidths={imageWidthsHorizontalCard} />
          </ThemeContext.Provider>
        </div>
      </div>
      )}
    </ErrorBoundary>
  );
};
