import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useTheme } from '../../theming/useTheme';
import { MEDIA_BREAKPOINTS } from '../../mediaQueries.const';
import {
  componentConfigThemeKeyForArticleContent,
  MainWrapperThemeProps,
} from '../wrappers/mainWrapper/mainWrapper.theme';
import { useHeaderHeights } from '../components/navigation/header/useHeaderHeights';

export const AMPMainWrapper: React.FunctionComponent = ({ children }) => {
  const { baseUnit, backgroundColor } = useTheme<MainWrapperThemeProps>(componentConfigThemeKeyForArticleContent);
  const headerHeights = useHeaderHeights();

  const style = {
    backgroundColor,
    paddingBottom: `${baseUnit}px`,
    [MEDIA_BREAKPOINTS.large]: {
      paddingTop: `${headerHeights.large * baseUnit + baseUnit / 4}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      paddingTop: `${headerHeights.medium * baseUnit + baseUnit / 4}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      paddingTop: `${headerHeights.small * baseUnit + baseUnit / 4}px`,
    },
  };

  return (
    <main
      className={css(StyleSheet.create({ style }).style)}
    >
      {children}
    </main>
  );
};
