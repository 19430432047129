import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { RATIO, SEMANTIC_TAG, TypographySize } from 'mm-theme-configuration/dist/consts';
import { useTheme } from '../../../../theming/useTheme';
import { createStyleForBoxShadow } from '../../../../theming/boxShadow';
import { MEDIA_BREAKPOINTS } from '../../../../mediaQueries.const';
import { baseUnit } from '../../../../theming/baseUnitDefinition';
import { Link } from '../../../partials/link/Link';

interface TitleOnTopImageWrapperDataProps {
  link: string | undefined;
  isSponsoredLink?: boolean;
}

export const componentConfigThemeKey = 'sectionOnTopTitle';

export interface SectionOnTopTitleThemeProps {
  titleColorTypeNormal: string;
  titleColorTypeHover: string;
  titleColorTypeActive: string;
  borderRadius: number;
  boxShadowTypeNormal: string;
  boxShadowTypeActive: string;
  boxShadowTypeHover: string;
  borderWidthTypeNormal: number;
  borderWidthTypeHover: number;
  borderWidthTypeActive: number;
  borderColorTypeNormal: string;
  borderColorTypeHover: string;
  borderColorTypeActive: string;
  aspectRatioLarge: RATIO;
  aspectRatioMedium: RATIO;
  aspectRatioSmall: RATIO;
  overlayColor: string;
  titleFontSizeLarge: TypographySize;
  titleFontSizeMedium: TypographySize;
  titleFontSizeSmall: TypographySize;
  subtitleFontSizeLarge: TypographySize;
  subtitleFontSizeMedium: TypographySize;
  subtitleFontSizeSmall: TypographySize;
  wrapperGapLarge: number;
  wrapperGapMedium: number;
  wrapperGapSmall: number;
  verticalGapLarge: number;
  verticalGapMedium: number;
  verticalGapSmall: number;
  horizontalGapLarge: number;
  horizontalGapMedium: number;
  horizontalGapSmall: number;
  titleSemanticTag: SEMANTIC_TAG;
  subtitleSemanticTag: SEMANTIC_TAG;
}

const createWrapperStyle = (theme: SectionOnTopTitleThemeProps) => StyleSheet.create({
  style: {
    display: 'flex',
    width: '100%',
    height: '100%',
    borderRadius: `${theme.borderRadius}px`,
    boxSizing: 'border-box',
    background: 'transparent',
    [MEDIA_BREAKPOINTS.large]: {
      padding: `${theme.wrapperGapLarge * baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      padding: `${theme.wrapperGapMedium * baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      padding: `${theme.wrapperGapSmall * baseUnit}px`,
    },
  },
}).style;

const createInnerWrapperStyle = (theme: SectionOnTopTitleThemeProps) => StyleSheet.create({
  style: {
    width: '100%',
    height: '100%',
    position: 'relative',
    textDecoration: 'none',
    color: theme.titleColorTypeNormal,
    display: 'block',
    '-webkit-mask-image': '-webkit-radial-gradient(white, black)',
    borderRadius: `${theme.borderRadius}px`,
  },
}).style;

const createAdditionalInnerWrapperForLinkStyle = (theme: SectionOnTopTitleThemeProps) => StyleSheet.create({
  style: {
    ':hover': {
      color: theme.titleColorTypeHover,
    },
    ':active': {
      color: theme.titleColorTypeActive,
    },
  },
}).style;

export const SectionOnTopTitleWrapper: React.FunctionComponent<TitleOnTopImageWrapperDataProps> = props => {
  const { link, children, isSponsoredLink = false } = props;
  const theme = useTheme<SectionOnTopTitleThemeProps>(componentConfigThemeKey);
  const { boxShadowTypeNormal, boxShadowTypeHover, boxShadowTypeActive, borderColorTypeActive, borderColorTypeHover, borderColorTypeNormal, borderWidthTypeActive, borderWidthTypeNormal, borderWidthTypeHover } = theme;
  const borderBoxShadowForLink = {
    borderColorTypeActive,
    borderColorTypeHover,
    borderColorTypeNormal,
    borderWidthTypeActive,
    borderWidthTypeHover,
    borderWidthTypeNormal,
  };
  const boxShadowForLinkStyle = createStyleForBoxShadow({
    boxShadowTypeNormal,
    boxShadowTypeHover,
    boxShadowTypeActive,
    borderBoxShadow: borderBoxShadowForLink,
  });
  const borderBoxShadowForNotLink = {
    borderColorTypeActive: borderColorTypeNormal,
    borderColorTypeHover: borderColorTypeNormal,
    borderColorTypeNormal,
    borderWidthTypeActive: borderWidthTypeNormal,
    borderWidthTypeHover: borderWidthTypeNormal,
    borderWidthTypeNormal,
  };
  const boxShadowForNotLinkStyle = createStyleForBoxShadow({
    boxShadowTypeNormal,
    boxShadowTypeHover: boxShadowTypeNormal,
    boxShadowTypeActive: boxShadowTypeNormal,
    borderBoxShadow: borderBoxShadowForNotLink,
  });

  const wrapperStyle = createWrapperStyle(theme);
  const innerWrapperStyle = createInnerWrapperStyle(theme);
  const additionalInnerWrapperForLinkStyle = createAdditionalInnerWrapperForLinkStyle(theme);
  const boxShadowStyle = link ? boxShadowForLinkStyle.style : boxShadowForNotLinkStyle.style;
  const relForSponsoredLink = isSponsoredLink ? { rel: 'nofollow sponsored' } : {};
  return (
    <div className={css(boxShadowStyle, wrapperStyle)}>
      {link
        ? (
          <Link
            className={css(innerWrapperStyle, additionalInnerWrapperForLinkStyle, boxShadowStyle)}
            href={link}
            {...relForSponsoredLink}
          >
            {children}
          </Link>
        )
        : (
          <div className={css(innerWrapperStyle, boxShadowStyle)}>{children}</div>
        )}
    </div>
  );
};
