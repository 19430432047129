import { SEMANTIC_TAG, TypographySize } from 'mm-theme-configuration/dist/consts';
import { BaseUnit } from '../../../../theming/baseUnitDefinition';
import { TranslatedFontSize } from '../../../../theming/fontSizeTranslator';

export const componentConfigThemeKey = 'header';

export interface HeaderThemeProps {
    firstRowBackgroundColor: string;
    firstRowHeightInSmallScreen: number;
    firstRowHeightInMediumScreen: number;
    firstRowHeightInLargeScreen: number;
    firstRowLogoHeightInSmallScreen: number;
    firstRowLogoHeightInMediumScreen: number;
    firstRowLogoHeightInLargeScreen: number;
    firstRowLinkFontSizeLarge: TypographySize;
    firstRowLinkFontSizeMedium: TypographySize;
    firstRowLinkFontSizeSmall: TypographySize;
    firstRowLinkColorTypeNormal: string;
    firstRowLinkColorTypeHover: string;
    firstRowLinkColorTypeActive: string;
    firstRowLinkActiveBorderBottom: string;
    firstRowHoverOtherLinksColors: string;
    firstRowLinkCurrentColor: string;
    firstRowLinkDropdownIconColor: string;
    firstRowSocialLinksColor: string;
    firstRowSocialLinksHoverColor: string;
    firstRowBoxShadow: string;
    firstRowSubMenuBackgroundColor: string;
    firstRowSubMenuLinksFontSize: TypographySize;
    secondRowBackgroundColor: string;
    secondRowHeightInLargeScreen: number;
    secondRowHeightInSmallScreen: number;
    secondRowHeightInMediumScreen: number;
    secondRowLogoHeightInSmallScreen: number;
    secondRowLogoHeightInMediumScreen: number;
    secondRowLogoHeightInLargeScreen: number;
    secondRowLinkFontSizeLarge: TypographySize;
    secondRowLinkFontSizeMedium: TypographySize;
    secondRowLinkFontSizeSmall: TypographySize;
    secondRowLinkColorTypeNormal: string;
    secondRowLinkColorTypeHover: string;
    secondRowLinkColorTypeActive: string;
    secondRowLinkCurrentColor: string;
    secondRowLinkDropdownIconColor: string;
    secondRowHoverOtherLinksColors: string;
    secondRowSocialLinksColor: string;
    secondRowSocialLinksHoverColor: string;
    secondRowBoxShadow: string;
    secondRowSubMenuBackgroundColor: string;
    secondRowSubMenuLinksFontSize: TypographySize;
    linkSemanticTag: SEMANTIC_TAG;
    editionsIconNormalColor: string;
    editionsIconHoverColor: string;
    editionsBoxShadowTypeNormal: string;
    editionsSeparatorColor: string;
    editionsMenuBackgroundColor: string;
    editionsMenuWidthInLargeScreen: number;
    editionsMenuWidthInMediumScreen: number;
    editionsMenuWidthInSmallScreen: number;
    verticalGapInSmallScreen: number;
    verticalGapInMediumScreen: number;
    verticalGapInLargeScreen: number;
    hamburgerMenuIconColor: string;
    hamburgerMenuDividerColor: string;
    hamburgerMenuLinksBackgroundColor: string;
    hamburgerMenuAmpCloseIconColor: string;
    hamburgerMenuLinksNormalColor: string;
    hamburgerMenuLinksActiveColor: string;
    hamburgerMenuLinksFontSizeMedium: TypographySize;
    hamburgerMenuLinksFontSizeSmall: TypographySize;
    hamburgerMenuSubLinksNormalColor: string;
    hamburgerMenuSubLinksFontSizeMedium: TypographySize;
    hamburgerMenuSubLinksFontSizeSmall: TypographySize;
    hamburgerMenuSocialLinksTitleColor: string;
    hamburgerMenuSocialLinksColor: string;
    hamburgerMenuSocialLinksHoverColor: string;
    hamburgerMenuSocialLinksTitleFontSizeLarge: TypographySize;
    hamburgerMenuSocialLinksTitleFontSizeMedium: TypographySize;
    hamburgerMenuSocialLinksTitleFontSizeSmall: TypographySize;
    ctaBackgroundColorNormal: string;
    ctaTextColorNormal: string;
    ctaBorderColorNormal: string;
    ctaBackgroundColorHover: string;
    ctaTextColorHover: string;
    ctaBorderColorHover: string;
    ctaBackgroundColorActive: string;
    ctaTextColorActive: string;
    ctaBorderColorActive: string;
    ctaBorderRadius: number;
    baseUnit: BaseUnit;
    fontSizesStyles: TranslatedFontSize;
}
