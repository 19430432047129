import { AppState } from '../../../store/initialStateRegistration';
import { getPaletteColors, getPaletteFonts, getPaletteTypography } from '../../../store/config/config.selectors';
import { readEventErrorReporter } from './readEventErrorReporter';
import { GET_PALETTE_DATA } from '../events';

const stylesheetUrlTypeName = 'stylesheetUrl' as const;
const fontFaceTypeName = 'fontFace' as const;

type FontTypeStylesheetUrl = {
  family: string;
  weights: string[];
  type: typeof stylesheetUrlTypeName;
  stylesheetUrl: string;
}

interface FontFaceURLData {
  url: string;
  type: string;
}

type FontTypeFontFace = {
  family: string;
  weight: string;
  type: typeof fontFaceTypeName;
  fontURLs: Array<FontFaceURLData>;
}
type Font = FontTypeFontFace | FontTypeStylesheetUrl;
type Fonts = Array<Font>;

type ScreenSize = 'large' | 'medium' | 'small';
type TypographySize = 'gigantic' | 'huge' | 'large' | 'bigger' | 'big' | 'normal' | 'tiny';

type FontSizeMap = {
  [key in TypographySize]: {
    screenSizes: {
      [key in ScreenSize]: number
    };
    fontWeight: number;
    fontFamily: string;
  }
};

const COLORS = {
  PRIMARY: 'primary' as const,
  SECONDARY: 'secondary' as const,
  DARK_GREY: 'darkGrey' as const,
  GREY: 'grey' as const,
  LIGHT_GREY: 'lightGrey' as const,
  BLACK: 'black' as const,
  WHITE: 'white' as const,
  BLUE: 'blue' as const,
};

interface Colors {
  [COLORS.PRIMARY]: string;
  [COLORS.SECONDARY]: string;
  [COLORS.DARK_GREY]: string;
  [COLORS.GREY]: string;
  [COLORS.LIGHT_GREY]: string;
  [COLORS.BLACK]: string;
  [COLORS.WHITE]: string;
  [COLORS.BLUE]: string;
}

interface PaletteData {
  colors: Colors;
  fonts: Fonts;
  typography: FontSizeMap;
}

const createPaletteDataEventHandler = (state: AppState): PaletteData => ({
  colors: getPaletteColors(state),
  fonts: getPaletteFonts(state),
  typography: getPaletteTypography(state),
});

export const getPaletteDataProtectedEventHandler = readEventErrorReporter(GET_PALETTE_DATA, createPaletteDataEventHandler);
