import { influencer } from './influencer';
import { app } from './app';
import { embedPartner } from './embedPartner';
import { syndication } from './syndication';
import { paid } from './paid';
import { social } from './social';
import { trPartner } from './trPartner';
import { organic } from './organic';
import { navigation } from './navigation';
import { rss } from './rss';
import { share } from './share';
import { email } from './email';
import { referral } from './referral';
import { direct } from './direct';

// order of pipelines is important
export const pipelines = [
  influencer,
  app,
  embedPartner,
  syndication,
  paid,
  social,
  trPartner,
  organic,
  navigation,
  rss,
  share,
  email,
  referral,
  direct,
];
