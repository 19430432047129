import * as React from 'react';
import { StyleSheet } from 'aphrodite/no-important';
import { Iframe } from './Iframe';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { AdditionalStyleSheet, Style } from '../../components.utils';
import { BlockWidths } from '../../components/pageLayouts/BlockTypes.utils';
import { createBlockStyle } from '../blockMutualStyle';

export interface IframeDataProps {
  html: string;
  dataId?: string;
  defaultBlocksWidths: BlockWidths;
}

type ArticleIframeDataProps = IframeDataProps & AdditionalStyleSheet;

const createStyle = (style: Style, defaultBlocksWidths: BlockWidths) => {
  return StyleSheet.create({
    style: {
      ...style,
      ...createBlockStyle(defaultBlocksWidths),
    },
  }) as AdditionalStyleSheet;
};

export const ArticleIframe: React.FunctionComponent<ArticleIframeDataProps> = props => {
  const { style, defaultBlocksWidths } = props;
  const additionalStyle = createStyle(style, defaultBlocksWidths);
  return <ErrorBoundary><Iframe {...props} additionalStyle={additionalStyle} /></ErrorBoundary>;
};
