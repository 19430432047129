import * as React from 'react';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';

export const SearchSvg = () => {
  return (
    <ErrorBoundary>
      <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Icon-/-Search" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
          <g id="search" transform="translate(1.000000, 1.000000)" stroke="currentColor" strokeWidth="1.5">
            <circle id="Oval" cx="8.88888889" cy="8.88888889" r="8.88888889" />
            <path d="M20,20 L15.1666667,15.1666667" id="Path" />
          </g>
        </g>
      </svg>
    </ErrorBoundary>
  );
};
