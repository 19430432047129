import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { useTheme } from '../../../../../../../../theming/useTheme';
import { ErrorBoundary } from '../../../../../../../errorHandling/ErrorBoundary';
import { componentConfigThemeKey, HeaderThemeProps } from '../../../../Header.theme';

interface MenuIconLogicProps {
  isMenuOpen: boolean;
  onClick: () => void;
}

const getMenuIconStyle = (isOpen: boolean, color: string) => StyleSheet.create({
  style: {
    height: '16px',
  },
  wrapper: {
    width: '24px',
    height: '24px',
    position: 'relative',
    transition: '.5s ease-in-out',
    cursor: 'pointer',
  },
  span: {
    display: 'block',
    position: 'absolute',
    height: '2px',
    width: '100%',
    backgroundColor: color,
    opacity: 1,
    left: 0,
    transition: '.25s ease-in-out',
    transformOrigin: 'right center',
  },
  firstSpan: (() => {
    return (
      isOpen
        ? { transform: 'rotate(-45deg)', width: '130%', left: '-8px', top: '0px' }
        : { top: '4px' }
    );
  })(),
  secondSpan: (() => {
    return (
      isOpen
        ? { top: '7px', transform: 'scaleX(0.1)', opacity: 0 }
        : { top: '12px' }
    );
  })(),
  thirdSpan: (() => {
    return (
      isOpen
        ? { transform: 'rotate(45deg)', top: '22px', width: '130%', left: '-8px' }
        : { top: '20px' }
    );
  })(),
});

export const MenuIcon: React.FunctionComponent<MenuIconLogicProps> = props => {
  const { isMenuOpen, onClick } = props;
  const { hamburgerMenuIconColor } = useTheme<HeaderThemeProps>(componentConfigThemeKey);
  const style = getMenuIconStyle(isMenuOpen, hamburgerMenuIconColor);
  return (
    <ErrorBoundary>
      <div className={css(style.wrapper)} onClick={onClick}>
        <span className={css(style.span, style.firstSpan)} />
        <span className={css(style.span, style.secondSpan)} />
        <span className={css(style.span, style.thirdSpan)} />
      </div>
    </ErrorBoundary>
  );
};
