import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { TypographySize } from 'mm-theme-configuration/dist/consts';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { createImageWidths } from '../../partials/image/image.utils';
import { baseUnit } from '../../../theming/baseUnitDefinition';
import { useTheme } from '../../../theming/useTheme';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { getLayoutComponentPadding, LayoutComponentBaseProps } from '../pageLayouts/layout.utils';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { VerticalCard } from '../../partials/cards/verticalCard/VerticalCard';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { MainCategoryThemeProps, VerticalCardThemeProps, componentConfigThemeKey as verticalCardConfigThemeKey } from '../../partials/cards/verticalCard/VerticalCard.theme';

export const componentConfigThemeKey = 'threeThreeVC';
const maximumNumberOfCards = 6;

interface ThreeThreeVCDataProps extends LayoutComponentBaseProps {
    cards: CardComponentDataProps[] | null;
    useFormatMinutesHoursAgo?: boolean;
}


export interface ThreeThreeVCThemeProps extends MainCategoryThemeProps {
    largeVerticalGap: number;
    largeHorizontalGap: number;
    mediumVerticalGap: number;
    mediumHorizontalGap: number;
    smallHorizontalGap: number;
    titleFontSizeLarge: TypographySize;
    titleFontSizeMedium: TypographySize;
    titleFontSizeSmall: TypographySize;
    metadataFontSizeLarge: TypographySize;
    metadataFontSizeMedium: TypographySize;
    metadataFontSizeSmall: TypographySize;
}

const getStyle = (themeProps: ThreeThreeVCThemeProps) => {
  const { largeHorizontalGap, largeVerticalGap, mediumHorizontalGap, mediumVerticalGap, smallHorizontalGap } = themeProps;
  return StyleSheet.create({
    wrapper: {
      display: 'grid',
      marginBottom: `${baseUnit}px`,
      [MEDIA_BREAKPOINTS.large]: {
        gridTemplateColumns: '1fr 1fr 1fr',
        gridColumnGap: `${baseUnit * largeVerticalGap}px`,
        gridRowGap: `${baseUnit * largeHorizontalGap}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        gridColumnGap: `${baseUnit * mediumVerticalGap}px`,
        gridTemplateColumns: '1fr 1fr',
        gridRowGap: `${baseUnit * mediumHorizontalGap}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        gridTemplateColumns: '1fr',
        gridRowGap: `${baseUnit * smallHorizontalGap}px`,
      },
    },
  });
};

const imageWidths = createImageWidths(360, 360, 360);

export const ThreeThreeVC: React.FunctionComponent<ThreeThreeVCDataProps> = props => {
  const { cards, paddingFactors, useFormatMinutesHoursAgo } = props;
  const themeProps = useTheme<ThreeThreeVCThemeProps>(componentConfigThemeKey);

  const style = getStyle(themeProps);
  const paddingStyle = getLayoutComponentPadding(paddingFactors);
  const { titleFontSizeLarge,
    titleFontSizeMedium,
    titleFontSizeSmall,
    metadataFontSizeLarge,
    metadataFontSizeMedium,
    metadataFontSizeSmall } = themeProps;
  const themeContextVerticalCard = useThemeOverride<VerticalCardThemeProps>({
    titleFontSizeLarge,
    titleFontSizeMedium,
    titleFontSizeSmall,
    metadataFontSizeLarge,
    metadataFontSizeMedium,
    metadataFontSizeSmall,
  }, verticalCardConfigThemeKey);
  return (
    <ErrorBoundary>
      {cards && cards.length > 0
      && (
      <div className={css(style.wrapper, paddingStyle)}>
        <ThemeContext.Provider value={themeContextVerticalCard}>
          {cards.map((card => (<VerticalCard key={card.id} {...card} imageWidths={imageWidths} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />))).slice(0, maximumNumberOfCards)}
        </ThemeContext.Provider>
      </div>
      )}
    </ErrorBoundary>
  );
};
