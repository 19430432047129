const intersectionObserverOptions = {
  root: null,
  rootMargin: '0px',
  threshold: [0, 0.05, 0.1],
};

export const createIntersectionObserver = (
  callback: Function,
  target: HTMLCanvasElement | null,
) => {
  // @ts-ignore
  const observer = new window.IntersectionObserver(callback, intersectionObserverOptions);

  if (target) {
    observer.observe(target);
  }
};
