import { StyleSheet } from 'aphrodite/no-important';
import { LARGE_SCREEN_SIZE, HUGE_SCREEN_SIZE, MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { baseUnit } from '../../../theming/baseUnitDefinition';

type PaddingFactors = {
  large: number;
  medium: number;
  small: number;
}

export interface LayoutComponentBaseProps {
  paddingFactors?: PaddingFactors;
}

export type MaxWidthValues = typeof LARGE_SCREEN_SIZE | typeof HUGE_SCREEN_SIZE;

export interface SectionStyleProps {
  themeConfigurationPropKey?: keyof SectionWidthProps;
}

export interface SectionWidthProps {
  firstSectionMaxWidth?: MaxWidthValues;
}

interface SectionStyle {
  backgroundColor: string;
}

export type SectionThemeProps = SectionWidthProps & SectionStyle;

export const getSectionStyle = (maxWidth: MaxWidthValues = LARGE_SCREEN_SIZE) => {
  return StyleSheet.create({
    sectionStyle: {
      maxWidth: `${maxWidth}px`,
      width: '-webkit-fill-available',
    },
  }).sectionStyle;
};

export const getLayoutComponentPadding = (paddingFactors: PaddingFactors | undefined = { large: 0.5, medium: 0.5, small: 0.5 }) => {
  return StyleSheet.create({
    padding: {
      [MEDIA_BREAKPOINTS.large]: {
        paddingLeft: `${paddingFactors.large * baseUnit}px`,
        paddingRight: `${paddingFactors.large * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        paddingLeft: `${paddingFactors.medium * baseUnit}px`,
        paddingRight: `${paddingFactors.medium * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        paddingLeft: `${paddingFactors.small * baseUnit}px`,
        paddingRight: `${paddingFactors.small * baseUnit}px`,
      },
    },
  }).padding;
};

export const getBackgroundColorStyle = (backgroundColor: string) => {
  return StyleSheet.create({
    style: {
      backgroundColor,
    },
  }).style;
};
