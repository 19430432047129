import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { useTheme } from '../../../../../theming/useTheme';
import { footerSectionPadding } from '../footer.utils';
import { TextLinkComponent, ThemedLinkStyle, generateLinkStyleFromTheme } from '../../../../partials/link/TextLink';
import { MEDIA_BREAKPOINTS } from '../../../../../mediaQueries.const';
import { P } from '../../../../../typography/semanticTags';
import { LinkButton } from '../LinkButton';
import { BaseUnit } from '../../../../../theming/baseUnitDefinition';
import { LinkButtonDataProps } from '../../utils';
import { componentConfigurationKey, FooterThemeProps } from '../Footer.theme';

interface Link {
  text: string;
  href: string;
}

interface NavigationLink {
  link: Link;
  children: NavigationLink | null;
}

export interface FooterLinksSectionBaseProps {
  title: string;
  buttons?: Array<LinkButtonDataProps>;
  links?: NavigationLink[];
}

type LinksSectionProps = FooterLinksSectionBaseProps & ThemedLinkStyle;

const getStyle = (baseUnit: BaseUnit) => {
  const padding = footerSectionPadding(baseUnit);
  return StyleSheet.create({
    section: {
      ...padding,
    },
    gridWrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
    linksWrapper: {
      display: 'inline-grid',
      marginTop: `${baseUnit * 0.5}px`,
      [MEDIA_BREAKPOINTS.large]: {
        gridTemplateColumns: 'repeat(5, auto)',
        width: '100%',
        justifyContent: 'space-between',
        gridGap: `${baseUnit * 0.5}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        gridTemplateColumns: 'repeat(4, auto)',
        gridGap: `${baseUnit * 0.5}px ${baseUnit * 2}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        gridTemplateColumns: 'auto auto',
        gridGap: `${baseUnit * 0.5}px ${baseUnit * 2}px`,
      },
    },
  });
};

export const FooterLinksSection: React.FunctionComponent<LinksSectionProps> = props => {
  const { linksIdleColor, linksActiveStateColor, linksHoverStateColor, baseUnit } = useTheme<FooterThemeProps>(componentConfigurationKey);
  const finalProps = { ...{ idleColor: linksIdleColor, activeStateColor: linksActiveStateColor, hoverStateColor: linksHoverStateColor, baseUnit }, ...props };
  const { links, buttons } = finalProps;
  const style = getStyle(baseUnit);
  const linkStyle = generateLinkStyleFromTheme({
    ...finalProps,
  });

  return (
    <div className={css(style.section)}>
      <div className={css(style.gridWrapper)}>
        <div className={css(style.linksWrapper)}>
          {links ? links.map((navigationLink, index) => <TextLinkComponent key={index} href={navigationLink.link.href} linkStyle={linkStyle}><P>{navigationLink.link.text}</P></TextLinkComponent>) : null}
          {buttons ? buttons.map((button, index) => <LinkButton key={index} text={button.text} onClick={button.onClick} color={linksIdleColor} hoverColor={linksHoverStateColor} attributes={button.attributes} cssClassName={button.cssClassName} />) : null}
        </div>
      </div>
    </div>
  );
};
