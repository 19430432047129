import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { Helmet } from 'react-helmet';
import { SocialButton } from '../articleComponents/ShareMenu/ShareMenu';
import { ErrorBoundary } from '../errorHandling/ErrorBoundary';
import { useTheme } from '../../theming/useTheme';
import { AMPSocialButton } from './AMPSocialButton';
import { BaseUnit } from '../../theming/baseUnitDefinition';

interface AMPSocialContainerDataProps {
  shareConfig: SocialButton[];
}

const getStyle = (baseUnit: BaseUnit) => StyleSheet.create({
  wrapper: {
    margin: `${baseUnit}px`,
  },
});

const getSocialButtons = (shareConfig: SocialButton[], baseUnit: BaseUnit) => {
  return shareConfig.map((data, index) => <AMPSocialButton key={index} {...data} baseUnit={baseUnit} />);
};

export const AMPSocialContainer: React.FunctionComponent<AMPSocialContainerDataProps> = props => {
  const { baseUnit } = useTheme();
  const { shareConfig } = props;
  const styles = getStyle(baseUnit);
  return (
    <ErrorBoundary>
      <Helmet>
        <script async={undefined} custom-element="amp-social-share" src="https://cdn.ampproject.org/v0/amp-social-share-0.1.js" />
      </Helmet>
      <div className={css(styles.wrapper)}>{getSocialButtons(shareConfig, baseUnit)}</div>
    </ErrorBoundary>
  );
};
