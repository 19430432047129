import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { MEDIA_BREAKPOINTS } from '../../../../../mediaQueries.const';
import { Link } from '../../../../partials/link/Link';
import { baseUnit } from '../../../../../theming/baseUnitDefinition';
import { useAnimationLogoHeightsAndWidths, AnimationLogoHeightsAndWidths } from './useAnimationLogoHeightsAndWidths';
import { AdditionalStyleSheet } from '../../../../components.utils';

export interface LogoDataProps {
  src: string;
  svgViewBox: string;
  alt: string;
  href: string;
}

interface LogoLogicProps {
  logoHeightInSmallScreen: number;
  logoHeightInMediumScreen: number;
  logoHeightInLargeScreen: number;
  reduceHeightAnimationDuration?: number;
  additionalStyleImage?: AdditionalStyleSheet;
  additionalStyleLink?: AdditionalStyleSheet;
  imageAdditionalStyles?: AdditionalStyleSheet;
}

type LogoProps = LogoDataProps & LogoLogicProps;

const getLogoStyle = (logoHeightInMediumScreen: number, logoHeightInSmallScreen: number, reduceHeightAnimationDuration: number, largeLogoHeightsAndWidths: AnimationLogoHeightsAndWidths, additionalStyleImage?: AdditionalStyleSheet, additionalStyleLink?: AdditionalStyleSheet) => {
  return StyleSheet.create({
    base: {
      display: 'inline-flex',
      [MEDIA_BREAKPOINTS.large]: {
        height: `${largeLogoHeightsAndWidths.height}px`,
        width: `${largeLogoHeightsAndWidths.width}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        height: `${logoHeightInMediumScreen * baseUnit}px`,
        width: 'auto',
      },
      [MEDIA_BREAKPOINTS.small]: {
        height: `${logoHeightInSmallScreen * baseUnit}px`,
        width: 'auto',
        ...additionalStyleLink?.style,
      },
      alignItems: 'center',
      justifyContent: 'flex-start',
      transition: `${reduceHeightAnimationDuration}ms`,
    },
    image: {
      height: '100%',
      [MEDIA_BREAKPOINTS.small]: {
        ...additionalStyleImage?.style,
      },
    },
  });
};

const getImageResource = (svg: string) => {
  return `data:image/svg+xml,${encodeURIComponent(svg)}`;
};

export const Logo: React.FunctionComponent<LogoProps> = props => {
  const { href, alt, src, logoHeightInLargeScreen, logoHeightInMediumScreen, logoHeightInSmallScreen, reduceHeightAnimationDuration = 0, additionalStyleImage, additionalStyleLink, imageAdditionalStyles } = props;
  const [ref, largeLogoHeightsAndWidths] = useAnimationLogoHeightsAndWidths(logoHeightInLargeScreen);
  const style = getLogoStyle(logoHeightInMediumScreen, logoHeightInSmallScreen, reduceHeightAnimationDuration, largeLogoHeightsAndWidths, additionalStyleImage, additionalStyleLink);
  return (
    <React.Fragment>
      <Link className={css(style.base)} href={href}>
        <img ref={ref} className={css(style.image, imageAdditionalStyles?.style)} alt={alt} src={getImageResource(src)} />
      </Link>
    </React.Fragment>
  );
};
