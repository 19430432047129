import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import {
  ALIGNMENT,
  RATIO,
  TypographySize,
  SEMANTIC_TAG,
  ALIGNMENT_OPTIONS,
} from 'mm-theme-configuration/dist/consts';
import { CardOverlay } from '../../CardOverlay';
import { Image } from '../../image/Image';
import { useTheme } from '../../../../theming/useTheme';
import { PlayIcon } from '../../PlayIcon';
import { WrapperForCard } from '../WrapperForCard';
import { Link } from '../../link/Link';
import { semanticCSS } from '../../../../typography/semanticTags';
import { createStyleForBoxShadow } from '../../../../theming/boxShadow';
import {
  CardComponentDataProps,
  CardComponentPropsFromLayout,
  listAuthors,
  showAuthorInAnyScreenSize,
  showDateInAnyScreenSize,
  showMetadataInAnyScreenSize,
  showMetadataInLargeScreenSize, showMetadataInMediumScreenSize, showMetadataInSmallScreenSize,
  showPipeInAnyScreenSize,
  CardComponentSponsoredProps,
  shouldShowSponsored,
  clickableLabelsStyles,
  Author,
} from '../cards.utils';
import { DynamicSemanticTag } from '../../../../typography/semanticTags/DynamicSemanticTag';
import { createImageAspectRatios } from '../../image/image.utils';
import { MEDIA_BREAKPOINTS } from '../../../../mediaQueries.const';
import { baseUnit } from '../../../../theming/baseUnitDefinition';
import { ellipsify } from '../../../../typography/ellipsify';
import { SponsoredTextVertical } from '../../SponsoredTextVertical';
import { getFormattedPublishDate } from '../../../../utils/time.utils';

export const componentConfigThemeKey = 'onTopCard';

export interface OnTopCardComponentThemeProps {
  cardTitleColorTypeNormal: string;
  cardTitleColorTypeHover: string;
  cardTitleColorTypeActive: string;
  borderRadius: number;
  boxShadowTypeNormal: string;
  boxShadowTypeActive: string;
  boxShadowTypeHover: string;
  borderWidthTypeNormal: number;
  borderWidthTypeHover: number;
  borderWidthTypeActive: number;
  borderColorTypeNormal: string;
  borderColorTypeHover: string;
  borderColorTypeActive: string;
  aspectRatioLarge: RATIO;
  aspectRatioMedium: RATIO;
  aspectRatioSmall: RATIO;
  overlayColor: string;
  titleFontSizeLarge: TypographySize;
  titleFontSizeMedium: TypographySize;
  titleFontSizeSmall: TypographySize;
  titleAlignment: ALIGNMENT;
  metadataAlignment: ALIGNMENT;
  pipeColor: string;
  showAuthorInSmallScreen: boolean;
  showAuthorInMediumScreen: boolean;
  showAuthorInLargeScreen: boolean;
  showDateInSmallScreen: boolean;
  showDateInMediumScreen: boolean;
  showDateInLargeScreen: boolean;
  metadataFontSizeLarge: TypographySize;
  metadataFontSizeMedium: TypographySize;
  metadataFontSizeSmall: TypographySize;
  authorColor: string;
  dateColor: string;
  wrapperGapLarge: number;
  wrapperGapMedium: number;
  wrapperGapSmall: number;
  verticalGapLarge: number;
  verticalGapMedium: number;
  verticalGapSmall: number;
  horizontalGapLarge: number;
  horizontalGapMedium: number;
  horizontalGapSmall: number;
  titleSemanticTag: SEMANTIC_TAG;
  metadataSemanticTag: SEMANTIC_TAG;
  showMainCategoryInLargeScreen: boolean;
  showMainCategoryInMediumScreen: boolean;
  showMainCategoryInSmallScreen: boolean;
  mainCategorySemanticTag: SEMANTIC_TAG;
  mainCategoryFontSizeLarge: TypographySize;
  mainCategoryFontSizeMedium: TypographySize;
  mainCategoryFontSizeSmall: TypographySize;
  mainCategoryTextColor: string;
  mainCategoryBackgroundColor: string;
  mainCategoryVerticalGapLarge: number;
  mainCategoryVerticalGapMedium: number;
  mainCategoryVerticalGapSmall: number;
  mainCategoryHorizontalGapLarge: number;
  mainCategoryHorizontalGapMedium: number;
  mainCategoryHorizontalGapSmall: number;
  mainCategoryAlignment: ALIGNMENT;
  numberOfLinesForTitle: number;
  numberOfLinesForAuthors: number;
  isUpdatedAtTimestamp: boolean;
  isMainCategoryEnabled: boolean;
  isClickableLabels: boolean;
}

type OnTopCardProps = CardComponentDataProps & CardComponentPropsFromLayout & CardComponentSponsoredProps;

const createCardStyles = (cardTextColorTypeNormal: string, cardTextColorTypeHover: string, cardTextColorTypeActive: string, borderRadius: number) => StyleSheet.create({
  card: {
    width: '100%',
    height: '100%',
    position: 'relative',
    textDecoration: 'none',
    color: cardTextColorTypeNormal,
    ':hover': {
      color: cardTextColorTypeHover,
    },
    ':active': {
      color: cardTextColorTypeActive,
    },
    display: 'block',
    '-webkit-mask-image': '-webkit-radial-gradient(white, black)',
    borderRadius: `${borderRadius}px`,
  },
});

const createFooterStyle = (metadataAlignment: ALIGNMENT) => StyleSheet.create({
  style: {
    justifySelf: metadataAlignment === ALIGNMENT_OPTIONS.CENTER ? 'center' : 'left',
  },
}).style;


const createMetadataStyle = (horizontalGapLarge: number, horizontalGapMedium: number, horizontalGapSmall: number,
  showMetadataInLargeScreen: boolean, showMetadataInMediumScreen: boolean, showMetadataInSmallScreen: boolean) => StyleSheet.create({
  style: {
    gridTemplateColumns: 'auto auto max-content',
    [MEDIA_BREAKPOINTS.large]: {
      display: showMetadataInLargeScreen ? 'inline-grid' : 'none',
      marginTop: `${baseUnit * horizontalGapLarge}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      display: showMetadataInMediumScreen ? 'inline-grid' : 'none',
      marginTop: `${baseUnit * horizontalGapMedium}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      display: showMetadataInSmallScreen ? 'inline-grid' : 'none',
      marginTop: `${baseUnit * horizontalGapSmall}px`,
    },
  },
}).style;

const createTitleAlignmentStyle = (titleAlignment: ALIGNMENT) => {
  switch (titleAlignment) {
    case ALIGNMENT_OPTIONS.CENTER:
      return StyleSheet.create({
        style: {
          textAlign: 'center',
        },
      }).style;
    case ALIGNMENT_OPTIONS.START:
    default:
      return StyleSheet.create({
        style: {
          textAlign: 'left',
        },
      }).style;
  }
};

const createAuthorsStyle = (showAuthorSmallScreen: boolean, showAuthorMediumScreen: boolean,
  showAuthorLargeScreen: boolean, authorColor: string, numberOfLinesForAuthors: number) => StyleSheet.create({
  style: {
    position: 'relative',
    color: authorColor,
    height: 'fit-content',
    alignSelf: 'flex-end',
    [MEDIA_BREAKPOINTS.large]: {
      marginRight: `${baseUnit * 0.25}px`,
      ...showAuthorLargeScreen ? ellipsify(numberOfLinesForAuthors).style : { display: 'none' },
    },
    [MEDIA_BREAKPOINTS.medium]: {
      marginRight: `${baseUnit * 0.25}px`,
      ...showAuthorMediumScreen ? ellipsify(numberOfLinesForAuthors).style : { display: 'none' },
    },
    [MEDIA_BREAKPOINTS.small]: {
      marginRight: `${baseUnit * 0.25}px`,
      ...showAuthorSmallScreen ? ellipsify(numberOfLinesForAuthors).style : { display: 'none' },
    },
  },
}).style;

const createDateStyle = (showDateSmallScreen: boolean, showDateMediumScreen: boolean,
  showDateLargeScreen: boolean, dateColor: string) => StyleSheet.create({
  style: {
    color: dateColor,
    alignSelf: 'flex-end',
    [MEDIA_BREAKPOINTS.large]: {
      display: showDateLargeScreen ? 'inline-block' : 'none',
    },
    [MEDIA_BREAKPOINTS.medium]: {
      display: showDateMediumScreen ? 'inline-block' : 'none',
    },
    [MEDIA_BREAKPOINTS.small]: {
      display: showDateSmallScreen ? 'inline-block' : 'none',
    },
  },
}).style;

const createPipeStyle = (showDateSmallScreen: boolean, showDateMediumScreen: boolean, showDateLargeScreen: boolean,
  showAuthorSmallScreen: boolean, showAuthorMediumScreen: boolean, showAuthorLargeScreen: boolean, pipeColor: string) => StyleSheet.create({
  style: {
    color: pipeColor,
    alignSelf: 'flex-end',
    [MEDIA_BREAKPOINTS.large]: {
      display: showDateLargeScreen && showAuthorLargeScreen ? 'block' : 'none',
      marginRight: `${baseUnit * 0.25}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      display: showDateMediumScreen && showAuthorMediumScreen ? 'block' : 'none',
      marginRight: `${baseUnit * 0.25}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      display: showDateSmallScreen && showAuthorSmallScreen ? 'block' : 'none',
      marginRight: `${baseUnit * 0.25}px`,
    },
  },
}).style;

const createTextWrapperStyle = (horizontalGapLarge: number, horizontalGapMedium: number, horizontalGapSmall: number, verticalGapLarge: number, verticalGapMedium: number, verticalGapSmall: number) => StyleSheet.create({
  style: {
    overflow: 'hidden',
    width: '-webkit-fill-available',
    display: 'grid',
    backgroundColor: 'transparent',
    position: 'absolute',
    bottom: 0,
    [MEDIA_BREAKPOINTS.large]: {
      margin: `${baseUnit * horizontalGapLarge}px ${baseUnit * verticalGapLarge}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      margin: `${baseUnit * horizontalGapMedium}px ${baseUnit * verticalGapMedium}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      margin: `${baseUnit * horizontalGapSmall}px ${baseUnit * verticalGapSmall}px`,
    },
  },
}).style;

const createMainCategoryStyle = (
  showMainCategoryInLargeScreen: boolean,
  showMainCategoryInMediumScreen: boolean,
  showMainCategoryInSmallScreen: boolean,
  mainCategoryHorizontalGapLarge: number,
  mainCategoryHorizontalGapMedium: number,
  mainCategoryHorizontalGapSmall: number,
  mainCategoryVerticalGapLarge: number,
  mainCategoryVerticalGapMedium: number,
  mainCategoryVerticalGapSmall: number,
  mainCategoryBackgroundColor: string,
  mainCategoryTextColor: string,
  mainCategoryAlignment: ALIGNMENT,
  horizontalGapLarge: number,
  horizontalGapMedium: number,
  horizontalGapSmall: number,
) => StyleSheet.create({
  style: {
    position: 'relative',
    backgroundColor: mainCategoryBackgroundColor,
    color: mainCategoryTextColor,
    width: 'fit-content',
    [MEDIA_BREAKPOINTS.large]: {
      display: showMainCategoryInLargeScreen ? 'block' : 'none',
      padding: `${mainCategoryHorizontalGapLarge * baseUnit}px ${mainCategoryVerticalGapLarge * baseUnit}px`,
      margin: mainCategoryAlignment === ALIGNMENT_OPTIONS.CENTER ? `${horizontalGapLarge * baseUnit}px auto` : `${horizontalGapLarge * baseUnit}px 0`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      display: showMainCategoryInMediumScreen ? 'block' : 'none',
      padding: `${mainCategoryHorizontalGapMedium * baseUnit}px ${mainCategoryVerticalGapMedium * baseUnit}px`,
      margin: mainCategoryAlignment === ALIGNMENT_OPTIONS.CENTER ? `${horizontalGapMedium * baseUnit}px auto` : `${horizontalGapMedium * baseUnit}px 0`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      display: showMainCategoryInSmallScreen ? 'block' : 'none',
      padding: `${mainCategoryHorizontalGapSmall * baseUnit}px ${mainCategoryVerticalGapSmall * baseUnit}px`,
      margin: mainCategoryAlignment === ALIGNMENT_OPTIONS.CENTER ? `${horizontalGapSmall * baseUnit}px auto` : `${horizontalGapSmall * baseUnit}px 0`,
    },
  },
}).style;

interface ArticleLinkProps{
  isClickableLayout: boolean;
  articleUrl: string;
  title: string;
  onClick?: React.MouseEventHandler;
}

const ArticleLink: React.FunctionComponent<ArticleLinkProps> = ({ children, isClickableLayout, articleUrl, title, onClick }) => {
  const { articleLink } = clickableLabelsStyles();
  const { cardTitleColorTypeNormal,
    cardTitleColorTypeHover,
    cardTitleColorTypeActive,
    boxShadowTypeNormal,
    boxShadowTypeActive,
    boxShadowTypeHover,
    borderColorTypeActive,
    borderColorTypeHover,
    borderColorTypeNormal,
    borderWidthTypeActive,
    borderWidthTypeHover,
    borderWidthTypeNormal,
    borderRadius } = useTheme<OnTopCardComponentThemeProps>(componentConfigThemeKey);
  const borderBoxShadow = {
    borderColorTypeActive,
    borderColorTypeHover,
    borderColorTypeNormal,
    borderWidthTypeActive,
    borderWidthTypeHover,
    borderWidthTypeNormal,
  };
  const cardStyles = createCardStyles(cardTitleColorTypeNormal, cardTitleColorTypeHover, cardTitleColorTypeActive, borderRadius);
  const boxShadowStyle = createStyleForBoxShadow({ boxShadowTypeNormal, boxShadowTypeHover, boxShadowTypeActive, borderBoxShadow });
  return (
    isClickableLayout
      ? (
        <div className={css(cardStyles.card, boxShadowStyle.style)}>
          <Link className={css(articleLink)} href={articleUrl} onClick={onClick} title={title} />
          {children}
        </div>
      )
      : (<Link className={css(cardStyles.card, boxShadowStyle.style)} href={articleUrl} onClick={onClick}>{children}</Link>)
  );
};

interface MainCategoryProps{
  isMainCategoryClickable: boolean;
  link? : string;
  displayName? : string;
}

const MainCategory: React.FunctionComponent<MainCategoryProps> = ({ isMainCategoryClickable, link, displayName }) => {
  const { showMainCategoryInLargeScreen,
    showMainCategoryInMediumScreen,
    showMainCategoryInSmallScreen,
    mainCategoryHorizontalGapLarge,
    mainCategoryHorizontalGapMedium,
    mainCategoryHorizontalGapSmall,
    mainCategoryVerticalGapLarge,
    mainCategoryVerticalGapMedium,
    mainCategoryVerticalGapSmall,
    mainCategoryBackgroundColor,
    mainCategoryTextColor,
    mainCategoryAlignment,
    horizontalGapLarge,
    horizontalGapMedium,
    horizontalGapSmall,
    mainCategorySemanticTag,
    mainCategoryFontSizeLarge,
    mainCategoryFontSizeMedium,
    mainCategoryFontSizeSmall } = useTheme<OnTopCardComponentThemeProps>(componentConfigThemeKey);
  const mainCategoryStyle = createMainCategoryStyle(
    showMainCategoryInLargeScreen,
    showMainCategoryInMediumScreen,
    showMainCategoryInSmallScreen,
    mainCategoryHorizontalGapLarge,
    mainCategoryHorizontalGapMedium,
    mainCategoryHorizontalGapSmall,
    mainCategoryVerticalGapLarge,
    mainCategoryVerticalGapMedium,
    mainCategoryVerticalGapSmall,
    mainCategoryBackgroundColor,
    mainCategoryTextColor,
    mainCategoryAlignment,
    horizontalGapLarge,
    horizontalGapMedium,
    horizontalGapSmall,
  );
  const { labelsLink } = clickableLabelsStyles();

  return (
    <DynamicSemanticTag
      semanticTag={mainCategorySemanticTag}
      fontSizeLarge={mainCategoryFontSizeLarge}
      fontSizeMedium={mainCategoryFontSizeMedium}
      fontSizeSmall={mainCategoryFontSizeSmall}
      styles={semanticCSS(mainCategoryStyle)}
    >
      {isMainCategoryClickable ? <Link className={css(labelsLink)} href={link} title={displayName} /> : null}
      { displayName}
    </DynamicSemanticTag>
  );
};

interface AuthorsProps{
  isAnyAuthorClickable: boolean;
  authors: Author[];
}

const Authors: React.FunctionComponent<AuthorsProps> = ({ isAnyAuthorClickable, authors }) => {
  const { numberOfLinesForAuthors,
    showAuthorInLargeScreen,
    showAuthorInMediumScreen,
    showAuthorInSmallScreen,
    authorColor } = useTheme<OnTopCardComponentThemeProps>(componentConfigThemeKey);
  const authorsStyle = createAuthorsStyle(showAuthorInSmallScreen, showAuthorInMediumScreen, showAuthorInLargeScreen, authorColor, numberOfLinesForAuthors);
  const { labelsLink } = clickableLabelsStyles();
  const listClickableAuthors = (authorsArray: Array<Author>) => {
    return authorsArray.map((author, index) => {
      return (
        <React.Fragment key={index}>
          {author.link ? <Link href={author.link} className={css(labelsLink)} title={author.name} /> : null}
          {(index === authorsArray.length - 1) ? author.name : `${author.name}, `}
        </React.Fragment>
      );
    });
  };
  return (
    <div className={css(authorsStyle)}>
      {isAnyAuthorClickable ? listClickableAuthors(authors) : listAuthors(authors)}
    </div>
  );
};

export const OnTopCard: React.FunctionComponent<OnTopCardProps> = props => {
  const {
    title,
    image,
    articleUrl,
    coverType,
    imageHeightCalculationMethods,
    imageWidths,
    lazyLoad = false,
    authors,
    mainCategory,
    logo,
    sponsoredText,
    brandName,
    updatedAt,
    updatedAtISO,
    createdAt,
    createdAtISO,
    onClick,
    useFormatMinutesHoursAgo,
  } = props;
  const {
    boxShadowTypeNormal,
    boxShadowTypeActive,
    boxShadowTypeHover,
    borderRadius,
    borderColorTypeActive,
    borderColorTypeHover,
    borderColorTypeNormal,
    borderWidthTypeActive,
    borderWidthTypeHover,
    borderWidthTypeNormal,
    titleFontSizeLarge,
    titleFontSizeSmall,
    titleFontSizeMedium,
    showAuthorInSmallScreen,
    showAuthorInMediumScreen,
    showAuthorInLargeScreen,
    aspectRatioLarge,
    aspectRatioMedium,
    aspectRatioSmall,
    metadataFontSizeLarge,
    metadataFontSizeMedium,
    metadataFontSizeSmall,
    metadataAlignment,
    titleAlignment,
    horizontalGapLarge,
    horizontalGapMedium,
    horizontalGapSmall,
    overlayColor,
    verticalGapLarge,
    verticalGapMedium,
    verticalGapSmall,
    wrapperGapLarge,
    wrapperGapMedium,
    wrapperGapSmall,
    titleSemanticTag,
    metadataSemanticTag,
    showDateInSmallScreen,
    showDateInMediumScreen,
    showDateInLargeScreen,
    dateColor,
    pipeColor,
    showMainCategoryInLargeScreen,
    showMainCategoryInMediumScreen,
    showMainCategoryInSmallScreen,
    numberOfLinesForTitle,
    isUpdatedAtTimestamp,
    isMainCategoryEnabled,
    isClickableLabels,
  } = useTheme<OnTopCardComponentThemeProps>(componentConfigThemeKey);

  const borderBoxShadow = {
    borderColorTypeActive,
    borderColorTypeHover,
    borderColorTypeNormal,
    borderWidthTypeActive,
    borderWidthTypeHover,
    borderWidthTypeNormal,
  };

  const showSponsored = shouldShowSponsored(sponsoredText, brandName);
  const boxShadowStyle = createStyleForBoxShadow({ boxShadowTypeNormal, boxShadowTypeHover, boxShadowTypeActive, borderBoxShadow });
  const titleAlignmentStyle = createTitleAlignmentStyle(titleAlignment);
  const showMetadataInLargeScreen = showMetadataInLargeScreenSize(showAuthorInLargeScreen, showDateInLargeScreen);
  const showMetadataInMediumScreen = showMetadataInMediumScreenSize(showAuthorInMediumScreen, showDateInMediumScreen);
  const showMetadataInSmallScreen = showMetadataInSmallScreenSize(showAuthorInSmallScreen, showDateInSmallScreen);
  const footerStyle = createFooterStyle(metadataAlignment);
  const metadataStyle = createMetadataStyle(horizontalGapLarge, horizontalGapMedium, horizontalGapSmall, showMetadataInLargeScreen, showMetadataInMediumScreen, showMetadataInSmallScreen);
  const dateStyle = createDateStyle(showDateInSmallScreen, showDateInMediumScreen, showDateInLargeScreen, dateColor);
  const pipeStyle = createPipeStyle(showDateInSmallScreen, showDateInMediumScreen, showDateInLargeScreen, showAuthorInSmallScreen, showAuthorInMediumScreen, showAuthorInLargeScreen, pipeColor);
  const showAuthor = showAuthorInAnyScreenSize(authors, showAuthorInLargeScreen, showAuthorInMediumScreen, showAuthorInSmallScreen);
  const showDate = showDateInAnyScreenSize(showDateInLargeScreen, showDateInMediumScreen, showDateInSmallScreen);
  const showMainCategory = isMainCategoryEnabled && mainCategory && mainCategory.displayName && (showMainCategoryInSmallScreen || showMainCategoryInMediumScreen || showMainCategoryInLargeScreen);
  // @ts-ignore TS2345
  const { style } = StyleSheet.create(ellipsify(numberOfLinesForTitle));
  const textWrapperStyle = createTextWrapperStyle(horizontalGapLarge, horizontalGapMedium, horizontalGapSmall, verticalGapLarge, verticalGapMedium, verticalGapSmall);
  const timestamp = isUpdatedAtTimestamp ? updatedAt : createdAt;
  const timestampISO = isUpdatedAtTimestamp ? updatedAtISO : createdAtISO;
  const isMainCategoryClickable = isClickableLabels && mainCategory?.link && mainCategory?.isActive;
  const isAnyAuthorClickable = isClickableLabels && authors?.some(author => !!author.link);
  const isClickableLayout = (showMainCategory && isMainCategoryClickable) || (showAuthor && isAnyAuthorClickable);

  const articleLinkProps = {
    isClickableLayout,
    articleUrl,
    title,
    onClick,
  };

  const mainCategoryProps = {
    isMainCategoryClickable: !!isMainCategoryClickable,
    link: mainCategory?.link,
    displayName: mainCategory?.displayName,
  };

  const authorsProps = {
    isAnyAuthorClickable,
    authors,
  };

  return (
    <WrapperForCard
      boxShadowStyle={boxShadowStyle}
      borderRadius={borderRadius}
      paddingLarge={wrapperGapLarge}
      paddingMedium={wrapperGapMedium}
      paddingSmall={wrapperGapSmall}
    >
      <ArticleLink {...articleLinkProps}>
        <Image {...image} lazyLoad={lazyLoad} imageAspectRatios={createImageAspectRatios(aspectRatioSmall, aspectRatioMedium, aspectRatioLarge)} imageHeightCalculationMethods={imageHeightCalculationMethods} imageWidths={imageWidths} />
        <CardOverlay overlayColor={overlayColor} />
        <div className={css(textWrapperStyle)}>
          <header>
            {showMainCategory ? <MainCategory {...mainCategoryProps} /> : null}
            <DynamicSemanticTag
              semanticTag={titleSemanticTag}
              fontSizeLarge={titleFontSizeLarge}
              fontSizeMedium={titleFontSizeMedium}
              fontSizeSmall={titleFontSizeSmall}
              styles={semanticCSS(titleAlignmentStyle, style)}
            >
              {title}
            </DynamicSemanticTag>
          </header>
          { showMetadataInAnyScreenSize(showAuthor, showDate) ? (
            <footer className={css(footerStyle)}>
              <DynamicSemanticTag
                semanticTag={metadataSemanticTag}
                fontSizeLarge={metadataFontSizeLarge}
                fontSizeMedium={metadataFontSizeMedium}
                fontSizeSmall={metadataFontSizeSmall}
                styles={semanticCSS(metadataStyle)}
              >
                { showAuthor ? <Authors {...authorsProps} /> : null }
                { showPipeInAnyScreenSize(showAuthor, showDate) ? (<div className={css(pipeStyle)}>|</div>) : null }
                { (showDate && timestampISO && timestamp) ? (<time dateTime={timestampISO} className={css(dateStyle)}>{getFormattedPublishDate(timestampISO, timestamp, useFormatMinutesHoursAgo)}</time>) : null }
              </DynamicSemanticTag>
            </footer>
          ) : null}
          { showSponsored ? <SponsoredTextVertical sponsoredText={sponsoredText} brandName={brandName} logo={logo} /> : null}
        </div>
        {coverType === 'video' ? <PlayIcon /> : null}
      </ArticleLink>
    </WrapperForCard>
  );
};
