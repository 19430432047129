import { sendEventToGoogleAnalytics, sendPageViewToGoogleAnalytics } from './googleAnalyticsProvider';
import { setEventEmitter } from './eventEmitters/eventEmitters.utils';
import { getAnalyticsConsent, getGdprApplies, readPublisherConsentCookie } from '../init.utils';

interface AnalyticsEvent {
  event_category: string;
  event_action: string;
  event_label: string;

  [key: string]: any;
}

interface PageViewEventToAnalytics {
  url: string;
}

export interface StateAnalyticsParams {
  articleAuthor?: string;
  articleTitle?: string;
  articleIntro?: boolean;
  articlePublicationTime?: string;
  siteName?: string;
  property?: string;
  countryCode: string;
  articleContentCount?: { [key: string]: number };
  articleWordCount?: number;
  language?: string;
  pageType: string;
  articleId?: string;
  articleTemplate?: Array<string>;
  channels?: Array<string>;
  contentTags?: Array<string>;
  mainMedia?: string;
  commercialTags?: Array<string>;
  dfpProperty?: string;
  ownerUsername?: string;
  authorsUsernames?: Array<string>;
  articleURL?: string;
  createdAt?: string;
  updatedAt?: string;
  experiments?: Array<string>;
  isSlideshow?: boolean;
  finiteScrollDataExists?: boolean;
  mainCategoryEmbed?: boolean;
  numberOfArticleOnPage?: number;
  vertical?: string | null;
  topic?: string | null;
  seoTitle?: string | null;
}

export interface ClientParams {
  platform: string;
  trafficSource: string;
  trafficSourceAndId: string;
  trafficId: string;
  mmUserIdentifier: string;
  testValue: string;
  affiliateId: string;
  trafficSourceGA: string;
  isArticleInFiniteScrollPage: boolean;
  emailSlice?: string | null;
  wzguid?: string | null;
  sessionId: string;
  mmcookieUserID: string;
}

export const createAnalyticsParams = ({
  articleAuthor,
  articleTitle,
  articleIntro,
  articlePublicationTime,
  siteName,
  countryCode,
  articleContentCount,
  articleWordCount,
  trafficId,
  language,
  pageType,
  articleId,
  articleTemplate,
  channels,
  contentTags,
  mainMedia,
  commercialTags,
  dfpProperty,
  platform,
  trafficSource,
  trafficSourceAndId,
  mmUserIdentifier,
  property,
  ownerUsername,
  authorsUsernames,
  articleURL,
  createdAt,
  updatedAt,
  experiments,
  isSlideshow = false,
  testValue,
  affiliateId,
  trafficSourceGA,
  finiteScrollDataExists,
  mainCategoryEmbed,
  isArticleInFiniteScrollPage,
  numberOfArticleOnPage = 1,
  vertical,
  topic,
  emailSlice,
  wzguid,
  sessionId,
  mmcookieUserID,
  seoTitle,
}: StateAnalyticsParams & ClientParams) => ({
  geoDetected: countryCode,
  analyticsConsent: getAnalyticsConsent(readPublisherConsentCookie()),
  markupFormat: 'web',
  gdprApplies: getGdprApplies(),
  trafficReferrer: document.referrer,
  trafficId,
  articleAuthor,
  articleTitle,
  articleIntro,
  articlePublicationTime,
  siteName,
  articleContentCount,
  articleWordCount,
  language,
  platform,
  contentTags,
  pageType,
  test: testValue,
  affiliateId,
  articleId,
  articleTemplate,
  distributionChannels: channels,
  mmUserIdentifier,
  trafficSource,
  trafficSourceAndId,
  mainMedia,
  commercialTags,
  dfpProperty,
  property,
  ownerUsername,
  authorsUsernames,
  articleURL: articleURL || window.location.href,
  createdAt,
  updatedAt,
  experiments,
  isSlideshow,
  trafficSourceGA,
  finiteScrollDataExists: finiteScrollDataExists || false,
  mainCategoryEmbed: !!mainCategoryEmbed,
  isArticleInFiniteScrollPage,
  numberOfArticleOnPage,
  vertical,
  topic,
  emailSlice,
  wzguid,
  sessionId,
  mmcookieUserID,
  seoTitle,
});

export function sendPageView(params: StateAnalyticsParams, eventName: string) {
  window.mmClientApi = window.mmClientApi || [];
  window.mmClientApi.push('getPageData', (pageDataObject: ClientParams) => {
    sendPageViewToGoogleAnalytics(createAnalyticsParams({
      ...params,
      platform: pageDataObject.platform,
      trafficSource: pageDataObject.trafficSource,
      trafficSourceAndId: pageDataObject.trafficSourceAndId,
      trafficId: pageDataObject.trafficId,
      mmUserIdentifier: pageDataObject.mmUserIdentifier,
      testValue: pageDataObject.testValue,
      affiliateId: pageDataObject.affiliateId,
      trafficSourceGA: pageDataObject.trafficSourceGA,
      isArticleInFiniteScrollPage: pageDataObject.isArticleInFiniteScrollPage,
      sessionId: pageDataObject.sessionId,
      mmcookieUserID: pageDataObject.mmcookieUserID,
      wzguid: pageDataObject.wzguid,
      emailSlice: pageDataObject.emailSlice,
    }), eventName);
  });
}

function extractGAParams(params: AnalyticsEvent) {
  const { event_category, event_action, event_label, ...additionalParams } = params;
  sendEventToGoogleAnalytics(event_category, event_action, event_label, additionalParams);
}

export function analyticsEventEmitter() {
  setEventEmitter('analytics', (params: AnalyticsEvent) => extractGAParams(params));
}

export function pageViewEventToAnalyticsEventEmitter(paramsForPageViewEvent: StateAnalyticsParams) {
  setEventEmitter('sendPageViewEventToAnalytics', (params: PageViewEventToAnalytics) => sendPageView({
    ...paramsForPageViewEvent,
    articleURL: params.url,
  }, 'GTM data ready to GA'));
}

export function initAnalytics(params: StateAnalyticsParams) {
  sendPageView(params, 'GTM data ready to GA');
  analyticsEventEmitter();
  pageViewEventToAnalyticsEventEmitter(params);
}
