import React, { PropsWithChildren } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { BlockWidths } from '../../components/pageLayouts/BlockTypes.utils';
import { createBlockStyle } from '../blockMutualStyle';
import { baseUnit } from '../../../theming/baseUnitDefinition';
import { useViewabilityHandler } from '../../../viewability/viewabilityHandler';

export interface SuggestedPostsWrapperProps {
    defaultBlocksWidths: BlockWidths;
    dataId: string;
    numberOfLinks?: number;
    total_blocks: number;
    sourceType: string;
    blockPosition: number;
}

const createWrapperStyle = (defaultBlocksWidths: BlockWidths) => StyleSheet.create({
  style: {
    marginTop: baseUnit,
    ...createBlockStyle(defaultBlocksWidths),
  },
}).style;

export const SuggestedPostsWrapper = (props: PropsWithChildren<SuggestedPostsWrapperProps>) => {
  const { defaultBlocksWidths, children, dataId, numberOfLinks, total_blocks, sourceType, blockPosition } = props;
  const viewabilityCallback = React.useCallback(() => {
    // @ts-ignore
    window.mmClientApi = window.mmClientApi || [];
    // @ts-ignore
    window.mmClientApi.push('analytics', {
      event_category: 'Article',
      event_action: `${sourceType}_recommendation_links_viewable_impression`,
      event_label: `after_block=[${blockPosition}];total_blocks=[${total_blocks}];number_of_links=[${numberOfLinks}]`,
    });
  }, [blockPosition, numberOfLinks, sourceType, total_blocks]);

  const ref = useViewabilityHandler(viewabilityCallback, 0.5);
  const wrapperStyle = createWrapperStyle(defaultBlocksWidths);
  return (
    <aside ref={ref} className={css(wrapperStyle)} data-mm-id={dataId}>
      {children}
    </aside>
  );
};
