import { SELF_CLOSING_TAGS } from './components.constants';

export interface Style {
    [key: string]: string;
}

export interface AdditionalStyleSheet {
    style: Style;
}

export interface MandatoryAdditionalStyle {
    additionalStyle: AdditionalStyleSheet;
}

export interface OptionalAdditionalStyle {
    additionalStyle?: AdditionalStyleSheet;
}

export const isSelfClosingTag = (tagName: string) => SELF_CLOSING_TAGS.includes(tagName.toLowerCase());

export const extractWrapperAndContentFromHtml = (html: string, isParagraph = true) => {
  const regex = /^<(\w+).*?>/;
  const match = html?.match(regex);

  const wrapperElement = isParagraph ? 'p' : 'div';

  if (!match || isSelfClosingTag(match[1])) {
    return { wrapper: wrapperElement, content: html || '' };
  }

  // Since block-level elements, like div and lists, are not allowed inside a 'p' tag,
  // we need to replace the 'p' tag with a 'div' tag.
  const wrapper = match[1] === 'p' ? wrapperElement : match[1];

  const indexOfLastElement = html.lastIndexOf('<');
  return {
    wrapper,
    content: html.substring(0, indexOfLastElement).replace(match[0], ''),
  };
};

export const injectScriptTagToHead = (scriptSrc: string, type?: string) => {
  const script = document.createElement('script');
  script.src = scriptSrc;
  script.async = true;
  if (type) script.type = type;
  document.head.appendChild(script);
};

const extractDeviceFromUserAgent = (userAgent: string) => {
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
    return 'tablet';
  }
  if (/Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(userAgent)) {
    return 'mobile';
  }
  return 'desktop';
};

export const getIsMobileClient = () => {
  const userDevice = extractDeviceFromUserAgent(navigator?.userAgent);
  return userDevice === 'mobile';
};
