import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { RATIO } from 'mm-theme-configuration/dist/consts';
import { useTheme } from '../../../../theming/useTheme';
import {
  clickableLabelsStyles,
} from '../cards.utils';
import { WrapperForCard } from '../WrapperForCard';
import { Link } from '../../link/Link';
import { createStyleForBoxShadow } from '../../../../theming/boxShadow';
import { MEDIA_BREAKPOINTS } from '../../../../mediaQueries.const';
import { componentConfigThemeKey, HorizontalCardThemeProps } from './horizontalCard.theme';
import { getLineHeightBySize } from '../../../../typography/semanticTags/semanticTags.utils';
import { HorizontalCardProps } from './horizontalCard.props';

const createStyle = (cardBackgroundColor: string, cardTextColorTypeNormal: string, cardTextColorTypeHover: string, cardTextColorTypeActive: string, borderRadius: number, shouldReverseImageAlignment: boolean, cardProportionsLarge: RATIO, cardProportionsMedium: RATIO, cardProportionsSmall: RATIO, lineHeightSmallScreen: number) => {
  return StyleSheet.create({
    text: {
      overflow: 'hidden',
      [MEDIA_BREAKPOINTS.large]: {
        height: 'fit-content',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        height: 'fit-content',
      },
      [MEDIA_BREAKPOINTS.small]: {
        height: `${lineHeightSmallScreen * 3}px`,
      },
    },
    wrapper: {
      width: '100%',
      height: '100%',
      textDecoration: 'none',
      color: cardTextColorTypeNormal,
      backgroundColor: cardBackgroundColor,
      '-webkit-mask-image': '-webkit-radial-gradient(white, black)',
      borderRadius: `${borderRadius}px`,
      position: 'relative',
      ':hover': {
        color: cardTextColorTypeHover,
      },
      ':active': {
        color: cardTextColorTypeActive,
      },
      display: 'grid',
      [MEDIA_BREAKPOINTS.large]: {
        gridTemplateColumns: shouldReverseImageAlignment ? `${cardProportionsLarge.y}fr ${cardProportionsLarge.x}fr` : `${cardProportionsLarge.x}fr ${cardProportionsLarge.y}fr`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        gridTemplateColumns: shouldReverseImageAlignment ? `${cardProportionsMedium.y}fr ${cardProportionsMedium.x}fr` : `${cardProportionsMedium.x}fr ${cardProportionsMedium.y}fr`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        gridTemplateColumns: shouldReverseImageAlignment ? `${cardProportionsSmall.y}fr ${cardProportionsSmall.x}fr` : `${cardProportionsSmall.x}fr ${cardProportionsSmall.y}fr`,
      },
    },
  });
};


interface ArticleLinkProps {
  articleUrl: string;
  title: string;
  isClickableLayout: boolean;
  onClick?: React.MouseEventHandler;
  ampAnalyticsAction?: string;
  ampAnalyticsCategory?: string;
  ampAnalyticsLabel?: string;
}

const ArticleLink: React.FunctionComponent<ArticleLinkProps> = ({ articleUrl, title, isClickableLayout, onClick, ampAnalyticsAction, ampAnalyticsCategory, ampAnalyticsLabel, children }) => {
  const { cardBackgroundColor,
    cardTitleColorTypeNormal,
    cardTitleColorTypeHover,
    cardTitleColorTypeActive,
    borderRadius,
    shouldReverseImageAlignment,
    cardProportionsLarge,
    cardProportionsMedium,
    cardProportionsSmall,
    fontSizesStyles,
    titleFontSizeSmall,
  } = useTheme<HorizontalCardThemeProps>(componentConfigThemeKey);
  const titleLineHeight = getLineHeightBySize(fontSizesStyles, titleFontSizeSmall, 'small');
  const titleStyle = createStyle(cardBackgroundColor, cardTitleColorTypeNormal, cardTitleColorTypeHover, cardTitleColorTypeActive, borderRadius, shouldReverseImageAlignment, cardProportionsLarge, cardProportionsMedium, cardProportionsSmall, titleLineHeight);
  const { articleLink } = clickableLabelsStyles();

  return (
    isClickableLayout
      ? (
        <div className={css(titleStyle.wrapper)}>
          <Link
            className={css(articleLink)}
            title={title}
            href={articleUrl}
            onClick={onClick}
            data-vars-action={ampAnalyticsAction}
            data-vars-category={ampAnalyticsCategory}
            data-vars-label={ampAnalyticsLabel}
          />
          {children}
        </div>
      )
      : (
        <Link
          className={css(titleStyle.wrapper)}
          href={articleUrl}
          onClick={onClick}
          data-vars-action={ampAnalyticsAction}
          data-vars-category={ampAnalyticsCategory}
          data-vars-label={ampAnalyticsLabel}
        >
          {children}
        </Link>
      )
  );
};

type HorizontalCardWithImageProps = HorizontalCardProps & {isClickableLayout: boolean};

export const HorizontalCardWrapper: React.FunctionComponent<HorizontalCardWithImageProps> = props => {
  const {
    title,
    articleUrl,
    onClick,
    ampAnalyticsAction,
    ampAnalyticsCategory,
    ampAnalyticsLabel,
    isClickableLayout,
    children,
  } = props;
  const {
    boxShadowTypeNormal,
    boxShadowTypeActive,
    boxShadowTypeHover,
    borderRadius,
    borderColorTypeActive,
    borderColorTypeHover,
    borderColorTypeNormal,
    borderWidthTypeActive,
    borderWidthTypeHover,
    borderWidthTypeNormal,
    wrapperGapLarge,
    wrapperGapMedium,
    wrapperGapSmall,
  } = useTheme<HorizontalCardThemeProps>(componentConfigThemeKey);
  const borderBoxShadow = {
    borderColorTypeActive,
    borderColorTypeHover,
    borderColorTypeNormal,
    borderWidthTypeActive,
    borderWidthTypeHover,
    borderWidthTypeNormal,
  };

  const boxShadowStyle = createStyleForBoxShadow({
    boxShadowTypeNormal,
    boxShadowTypeHover,
    boxShadowTypeActive,
    borderBoxShadow,
  });

  const articleLinkProps = {
    title,
    articleUrl,
    isClickableLayout,
    onClick,
    ampAnalyticsAction,
    ampAnalyticsCategory,
    ampAnalyticsLabel,
  };

  return (
    <WrapperForCard
      boxShadowStyle={boxShadowStyle}
      borderRadius={borderRadius}
      paddingLarge={wrapperGapLarge}
      paddingMedium={wrapperGapMedium}
      paddingSmall={wrapperGapSmall}
    >
      <ArticleLink {...articleLinkProps}>
        {children}
      </ArticleLink>
    </WrapperForCard>
  );
};
