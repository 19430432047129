import { ErrorPageHongKongState } from './errorPageHongKong/errorPageHongKong.initialState';
import { ErrorPageMeccaState } from './errorPageMecca/errorPageMecca.initialState';

export type ErrorPageAction = { type: string };

type ErrorPageState = ErrorPageHongKongState | ErrorPageMeccaState

export const errorPageReducer = (state: ErrorPageState = {} as ErrorPageState, action: ErrorPageAction) => {
  switch (action.type) {
    default:
      return state;
  }
};
