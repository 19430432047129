import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { TypographySize } from 'mm-theme-configuration/dist/consts';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { useTheme } from '../../../theming/useTheme';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { createImageWidths } from '../../partials/image/image.utils';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { getLayoutComponentPadding, LayoutComponentBaseProps } from '../pageLayouts/layout.utils';
import { HorizontalCard } from '../../partials/cards/horizontalCard/HorizontalCard';
import { componentConfigThemeKey as horizontalCardConfigThemeKey, HorizontalCardThemeProps } from '../../partials/cards/horizontalCard/horizontalCard.theme';
import { ThreeVC } from './ThreeVC';
import { VerticalCard } from '../../partials/cards/verticalCard/VerticalCard';
import { VerticalCardThemeProps, componentConfigThemeKey as verticalCardConfigThemeKey } from '../../partials/cards/verticalCard/VerticalCard.theme';

export const componentConfigThemeKey = 'oneHCThreeVC';

export interface OneHCThreeVCThemeProps {
  largeHorizontalGap: number;
  mediumHorizontalGap: number;
  smallHorizontalGap: number;
  baseUnit: BaseUnit;
  showDescriptionInLargeScreen: boolean;
  showDescriptionInMediumScreen: boolean;
  showDescriptionInSmallScreen: boolean;
  showMainCategoryInHCLargeScreen: boolean;
  showMainCategoryInHCMediumScreen: boolean;
  showMainCategoryInHCSmallScreen: boolean;
  showMainCategoryInVCLargeScreen: boolean;
  showMainCategoryInVCMediumScreen: boolean;
  showMainCategoryInVCSmallScreen: boolean;
  shouldReverseImageAlignment: boolean;
  verticalTitleFontSizeLarge: TypographySize;
  verticalTitleFontSizeMedium: TypographySize;
  verticalTitleFontSizeSmall: TypographySize;
  verticalMetadataFontSizeLarge: TypographySize;
  verticalMetadataFontSizeMedium: TypographySize;
  verticalMetadataFontSizeSmall: TypographySize;
  verticalDescriptionFontSizeLarge: TypographySize;
  verticalDescriptionFontSizeMedium: TypographySize;
  verticalDescriptionFontSizeSmall: TypographySize;
  horizontalTitleFontSizeLarge: TypographySize;
  horizontalTitleFontSizeMedium: TypographySize;
  horizontalTitleFontSizeSmall: TypographySize;
  horizontalMetadataFontSizeLarge: TypographySize;
  horizontalMetadataFontSizeMedium: TypographySize;
  horizontalMetadataFontSizeSmall: TypographySize;
  horizontalDescriptionFontSizeLarge: TypographySize;
  horizontalDescriptionFontSizeMedium: TypographySize;
  horizontalDescriptionFontSizeSmall: TypographySize;
}

interface OneHCThreeVCDataProps extends LayoutComponentBaseProps {
  cards: Array<CardComponentDataProps>;
  useFormatMinutesHoursAgo?: boolean;
}

const createStyle = (themeProps: OneHCThreeVCThemeProps) => {
  const { largeHorizontalGap, mediumHorizontalGap, smallHorizontalGap, baseUnit } = themeProps;
  return StyleSheet.create({
    wrapper: {
      display: 'grid',
      [MEDIA_BREAKPOINTS.large]: {
        gridRowGap: `${largeHorizontalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        gridRowGap: `${mediumHorizontalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        gridRowGap: `${smallHorizontalGap * baseUnit}px`,
      },
    },
    hcCard: {
      [MEDIA_BREAKPOINTS.large]: {
        display: 'unset',

      },
      [MEDIA_BREAKPOINTS.medium]: {
        display: 'unset',
      },
      [MEDIA_BREAKPOINTS.small]: {
        display: 'none',
      },
    },
    vcCard: {
      [MEDIA_BREAKPOINTS.large]: {
        display: 'none',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        display: 'none',
      },
      [MEDIA_BREAKPOINTS.small]: {
        display: 'unset',
      },
    },
  });
};

const imageWidthsHC = createImageWidths(360, 540, 720);

export const OneHCThreeVC: React.FunctionComponent<OneHCThreeVCDataProps> = props => {
  const { cards, paddingFactors, useFormatMinutesHoursAgo } = props;
  const paddingStyle = getLayoutComponentPadding(paddingFactors);
  const themeProps = useTheme<OneHCThreeVCThemeProps>(componentConfigThemeKey);
  const { showDescriptionInLargeScreen,
    showDescriptionInMediumScreen,
    showDescriptionInSmallScreen,
    shouldReverseImageAlignment,
    verticalTitleFontSizeLarge,
    verticalTitleFontSizeMedium,
    verticalTitleFontSizeSmall,
    verticalMetadataFontSizeLarge,
    verticalMetadataFontSizeMedium,
    verticalMetadataFontSizeSmall,
    verticalDescriptionFontSizeLarge,
    verticalDescriptionFontSizeMedium,
    verticalDescriptionFontSizeSmall,
    horizontalTitleFontSizeLarge,
    horizontalTitleFontSizeMedium,
    horizontalTitleFontSizeSmall,
    horizontalMetadataFontSizeLarge,
    horizontalMetadataFontSizeMedium,
    horizontalMetadataFontSizeSmall,
    horizontalDescriptionFontSizeLarge,
    horizontalDescriptionFontSizeMedium,
    horizontalDescriptionFontSizeSmall,
    showMainCategoryInHCLargeScreen,
    showMainCategoryInHCMediumScreen,
    showMainCategoryInHCSmallScreen,
    showMainCategoryInVCLargeScreen,
    showMainCategoryInVCMediumScreen,
    showMainCategoryInVCSmallScreen,
  } = themeProps;

  const style = createStyle(themeProps);
  const [firstCard, ...rest] = cards || [];
  const themeContextHC = useThemeOverride<HorizontalCardThemeProps>({
    shouldReverseImageAlignment,
    titleFontSizeLarge: verticalTitleFontSizeLarge,
    titleFontSizeMedium: verticalTitleFontSizeMedium,
    titleFontSizeSmall: verticalTitleFontSizeSmall,
    metadataFontSizeLarge: verticalMetadataFontSizeLarge,
    metadataFontSizeMedium: verticalMetadataFontSizeMedium,
    metadataFontSizeSmall: verticalMetadataFontSizeSmall,
    descriptionFontSizeLarge: verticalDescriptionFontSizeLarge,
    descriptionFontSizeMedium: verticalDescriptionFontSizeMedium,
    descriptionFontSizeSmall: verticalDescriptionFontSizeSmall,
    showDescriptionInLargeScreen,
    showDescriptionInMediumScreen,
    showDescriptionInSmallScreen,
    cardProportionsLarge: { x: 2, y: 1 },
    cardProportionsMedium: { x: 2, y: 1 },
    verticalGapLarge: 1,
    verticalGapMedium: 1,
    verticalGapSmall: 1,
    horizontalGapLarge: 0.75,
    horizontalGapMedium: 0.75,
    horizontalGapSmall: 0.75,
    isMainCategoryEnabled: true,
    showMainCategoryInSmallScreen: showMainCategoryInHCSmallScreen,
    showMainCategoryInMediumScreen: showMainCategoryInHCMediumScreen,
    showMainCategoryInLargeScreen: showMainCategoryInHCLargeScreen,
  }, horizontalCardConfigThemeKey);


  const themeContextVc = useThemeOverride<VerticalCardThemeProps>({
    titleFontSizeLarge: horizontalTitleFontSizeLarge,
    titleFontSizeMedium: horizontalTitleFontSizeMedium,
    titleFontSizeSmall: horizontalTitleFontSizeSmall,
    metadataFontSizeLarge: horizontalMetadataFontSizeLarge,
    metadataFontSizeMedium: horizontalMetadataFontSizeMedium,
    metadataFontSizeSmall: horizontalMetadataFontSizeSmall,
    descriptionFontSizeInLargeScreen: horizontalDescriptionFontSizeLarge,
    descriptionFontSizeInSmallScreen: horizontalDescriptionFontSizeSmall,
    descriptionFontSizeInMediumScreen: horizontalDescriptionFontSizeMedium,
    isMainCategoryEnabled: true,
    showMainCategoryInSmallScreen: showMainCategoryInVCSmallScreen,
    showMainCategoryInMediumScreen: showMainCategoryInVCMediumScreen,
    showMainCategoryInLargeScreen: showMainCategoryInVCLargeScreen,
    showDescriptionInLargeScreen,
    showDescriptionInMediumScreen,
    showDescriptionInSmallScreen,
  }, verticalCardConfigThemeKey);


  return (
    <ErrorBoundary>
      {cards && cards.length > 0
        && (
          <div className={css(paddingStyle, style.wrapper)}>
            <ThemeContext.Provider value={themeContextHC}>
              <ThemeContext.Provider value={themeContextVc}>
                <div className={css(style.vcCard)}>
                  <VerticalCard {...firstCard} imageWidths={imageWidthsHC} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />
                </div>
              </ThemeContext.Provider>
              <div className={css(style.hcCard)}>
                <HorizontalCard {...firstCard} imageWidths={imageWidthsHC} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />
              </div>
            </ThemeContext.Provider>
            <ThemeContext.Provider value={themeContextVc}>
              <ThreeVC cards={rest} paddingFactors={{ large: 0, medium: 0, small: 0 }} addTextPropToVCTheme={false} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />
            </ThemeContext.Provider>
          </div>
        )}
    </ErrorBoundary>
  );
};
