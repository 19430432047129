import * as React from 'react';
import { StyleSheet } from 'aphrodite/no-important';
import { createImageWidths, ImageWidth } from '../partials/image/image.utils';
import { Image } from '../partials/image/Image';
import { AdditionalStyleSheet, Style } from '../components.utils';
import { ErrorBoundary } from '../errorHandling/ErrorBoundary';
import { Thumbnail, FULL_BLEED_IMAGE_SIZE_TYPE, ImageSizeType } from '../partials/image/image.types';
import { BlocksPaddingOverride, BlockWidths } from '../components/pageLayouts/BlockTypes.utils';
import { createBlockStyle } from './blockMutualStyle';
import { ImageWithCaptionAndIcon } from '../partials/image/ImageWithCaptionAndIcon/ImageWithCaptionAndIcon';

export interface ArticleImageProps {
  dataId?: string;
  image: Thumbnail;
  style: Style;
  defaultBlocksWidths: BlockWidths;
  overrideBlockWidths?: BlockWidths | undefined;
  sizeType: ImageSizeType;
  blocksPaddingOverride?: BlocksPaddingOverride;
  imageWidthSmall?: ImageWidth;
  imageWidthMedium?: ImageWidth;
  imageWidthLarge?: ImageWidth;
}

const createFullBleedStyle = (style: Style) => {
  return {
    ...style,
  };
};

const createStyle = (style: Style, defaultBlocksWidths: BlockWidths, overrideBlockWidths: BlockWidths | undefined, blocksPaddingOverride: BlocksPaddingOverride | undefined) => {
  const blockStyle = overrideBlockWidths ? createBlockStyle(overrideBlockWidths, blocksPaddingOverride) : createBlockStyle(defaultBlocksWidths, blocksPaddingOverride);
  return {
    ...style,
    ...blockStyle,
  };
};

const createImageAdditionalStyle = (style: Style) => {
  return StyleSheet.create({
    style: {
      ...style,
    },
  }) as AdditionalStyleSheet;
};

export const ArticleImage: React.FunctionComponent<ArticleImageProps> = props => {
  const { style, image, dataId, defaultBlocksWidths, overrideBlockWidths, sizeType, blocksPaddingOverride, imageWidthSmall = 540, imageWidthMedium = 720, imageWidthLarge = 720 } = props;

  const largeScreenImageWidth = sizeType === FULL_BLEED_IMAGE_SIZE_TYPE ? 1440 : imageWidthLarge;
  const imageWidths = createImageWidths(imageWidthSmall as ImageWidth, imageWidthMedium as ImageWidth, largeScreenImageWidth as ImageWidth);
  const imageWithCaptionAndIconStyle = sizeType === FULL_BLEED_IMAGE_SIZE_TYPE ? createFullBleedStyle(style)
    : createStyle(style, defaultBlocksWidths, overrideBlockWidths, blocksPaddingOverride);
  const additionalStyle = createImageAdditionalStyle(imageWithCaptionAndIconStyle);
  const hasCreditOrCaption = typeof image.credit === 'string' || typeof image.caption === 'string';

  return hasCreditOrCaption
    ? (
      <ErrorBoundary>
        <ImageWithCaptionAndIcon image={image} lazyLoad style={imageWithCaptionAndIconStyle} dataId={dataId} imageWidths={imageWidths} sizeType={sizeType} defaultBlocksWidths={defaultBlocksWidths} overrideBlockWidths={overrideBlockWidths} />
      </ErrorBoundary>
    )
    : (
      <ErrorBoundary>
        <Image {...image} lazyLoad additionalStyle={additionalStyle} dataId={dataId} imageWidths={imageWidths} />
      </ErrorBoundary>
    );
};
