import { Thumbnail } from 'mm-ui-components/dist/src/base/partials/image/image.types';

export interface AnnouncementState {
    title: string;
    seoDescription: string;
    path: string;
    image: Thumbnail;
}

export const announcementReducer = (state: AnnouncementState = {} as AnnouncementState, action: {type: string}) => {
    switch (action.type) {
        default:
            return state;
    }
};
