import { BOX_SHADOW_TYPES } from 'mm-theme-configuration/dist/consts';
import { StyleSheet } from 'aphrodite/no-important';

type ELEVATION_TYPES_MAP = {
    [key in BOX_SHADOW_TYPES]: string
}
interface BorderBoxShadow {
  borderWidthTypeNormal: number;
  borderWidthTypeHover: number;
  borderWidthTypeActive: number;
  borderColorTypeNormal: string;
  borderColorTypeHover: string;
  borderColorTypeActive: string;
}
interface BoxShadowPropsTypes {
  boxShadowTypeNormal?: string;
  boxShadowTypeHover?: string;
  boxShadowTypeActive?: string;
  boxShadowTypeDisable?: string;
  borderBoxShadow?: BorderBoxShadow;
}

const noBorderBoxShadow = {
  borderWidthTypeNormal: 0,
  borderWidthTypeHover: 0,
  borderWidthTypeActive: 0,
  borderColorTypeNormal: '',
  borderColorTypeHover: '',
  borderColorTypeActive: '',
};

const elevationTypesMap: ELEVATION_TYPES_MAP = {
  LOW: '0 2px 4px 0 rgba(0,0,0,0.2)',
  HIGH: '0 8px 8px -8px rgba(0,0,0,0.2), 0 4px 20px 0px rgba(0,0,0,0.2), 0 12px 16px 0px rgba(0,0,0,0.2)',
  NONE: '0 0 0 0',
};

export const getElevationType = (key: string) => elevationTypesMap[key as BOX_SHADOW_TYPES];

export const createStyleForBoxShadow = ({ boxShadowTypeNormal = elevationTypesMap.NONE, boxShadowTypeHover, boxShadowTypeActive, boxShadowTypeDisable, borderBoxShadow = noBorderBoxShadow }: BoxShadowPropsTypes) => {
  const { borderColorTypeActive, borderColorTypeHover, borderColorTypeNormal, borderWidthTypeActive, borderWidthTypeHover, borderWidthTypeNormal } = borderBoxShadow;
  return StyleSheet.create({
    style: {
      transition: '0.2s box-shadow ease-in-out',
      willChange: 'box-shadow',
      boxShadow: `${boxShadowTypeNormal}, 0 0 0 ${borderWidthTypeNormal}px ${borderColorTypeNormal}`,
      ':hover': {
        boxShadow: `${boxShadowTypeHover || boxShadowTypeNormal}, 0 0 0 ${borderWidthTypeHover}px ${borderColorTypeHover}`,
      },
      ':active': {
        boxShadow: `${boxShadowTypeActive || boxShadowTypeNormal}, 0 0 0 ${borderWidthTypeActive}px ${borderColorTypeActive}`,
      },
      ':disabled': {
        boxShadow: boxShadowTypeDisable || boxShadowTypeNormal,
      },
    },
  });
};
