import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { useTheme } from '../../../theming/useTheme';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { baseUnit } from '../../../theming/baseUnitDefinition';
import {
  componentConfigThemeKey as horizontalCardConfigThemeKey,
  HorizontalCardThemeProps,
} from '../../partials/cards/horizontalCard/horizontalCard.theme';
import { HorizontalCardProps } from '../../partials/cards/horizontalCard/horizontalCard.props';
import { createImageWidths } from '../../partials/image/image.utils';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import {
  componentConfigurationKey as SectionHeaderConfigThemeKey,
  SectionHeader,
  SectionHeaderComponentThemeProps,
} from '../../wrappers/sectionHeader/SectionHeader';
import { H3 } from '../../../typography/semanticTags';
import { RelatedPostsWrapperProps } from './RelatedPostsWrapper';
import { RelatedPostsThemeProps } from './relatedPosts.theme';
import { TypographySizes } from '../../../typography/semanticTags/semanticTags.utils';
import { useViewabilityHandler } from '../../../viewability/viewabilityHandler';

export const componentConfigThemeKey = 'relatedPosts';

export interface RelatedPostsProps {
  title: string;
  articles: Array<CardComponentDataProps>;
  sourceType: string;
}

export type RelatedPostsWithCardProps = RelatedPostsProps & RelatedPostsWrapperProps;

const createWrapperStyle = (backgroundColor: string, largeHorizontalGap: number, mediumHorizontalGap: number, smallHorizontalGap: number) => StyleSheet.create({
  style: {
    backgroundColor,
    display: 'grid',
    [MEDIA_BREAKPOINTS.large]: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto 1fr 1fr 1fr 1fr',
      gridRowGap: `${largeHorizontalGap * baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto 1fr 1fr 1fr 1fr',
      gridRowGap: `${mediumHorizontalGap * baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto 1fr 1fr 1fr 1fr',
      gridRowGap: `${smallHorizontalGap * baseUnit}px`,
    },
  },
}).style;

const imageWidths = createImageWidths(180, 360, 540);

const getAnalyticsEventParams = (cardNumber: number) => ({
  category: 'Article',
  action: 'related posts clicked',
  label: `link=[${cardNumber}]`,
});

export const RelatedPostsWithCard = (Card: React.FunctionComponent<HorizontalCardProps>): React.FunctionComponent<RelatedPostsWithCardProps> => {
  const RelatedPostsComponent: React.FunctionComponent<RelatedPostsProps & RelatedPostsWrapperProps> = props => {
    const relatedPostsAnalytics = (cardNumber: number) => {
      const analyticsEventParams = getAnalyticsEventParams(cardNumber);
      const sendClickAnalytics = () => {
        window.mmClientApi = window.mmClientApi || [];
        window.mmClientApi.push('analytics', {
          event_category: analyticsEventParams.category,
          event_action: analyticsEventParams.action,
          event_label: analyticsEventParams.label,
        });
      };
      return sendClickAnalytics;
    };

    const viewabilityCallback = React.useCallback(() => {
      // @ts-ignore
      window.mmClientApi = window.mmClientApi || [];
      // @ts-ignore
      window.mmClientApi.push('analytics', {
        event_category: 'Article',
        event_action: 'related posts viewable impression',
        event_label: '',
      });
    }, []);

    const { title, articles, dataId } = props;
    const {
      backgroundColor,
      largeHorizontalGap,
      mediumHorizontalGap,
      smallHorizontalGap,
      cardProportionsLarge,
      cardProportionsMedium,
      cardProportionsSmall,
      descriptionFontSizeLarge,
      descriptionFontSizeMedium,
      descriptionFontSizeSmall,
      metadataFontSizeLarge,
      metadataFontSizeMedium,
      metadataFontSizeSmall,
      mainCategoryFontSizeLarge,
      mainCategoryFontSizeMedium,
      mainCategoryFontSizeSmall,
      cardBorderColorTypeActive,
      cardBorderColorTypeHover,
      cardBorderColorTypeNormal,
      cardBorderWidthTypeActive,
      cardBorderWidthTypeHover,
      cardBorderWidthTypeNormal,
      cardTitleColorTypeActive,
      cardTitleColorTypeHover,
      cardTitleColorTypeNormal,
      pipeColor,
    } = useTheme<RelatedPostsThemeProps>(componentConfigThemeKey);
    const themeContextHC = useThemeOverride<HorizontalCardThemeProps>({
      cardBackgroundColor: backgroundColor,
      borderColorTypeActive: cardBorderColorTypeActive,
      borderColorTypeHover: cardBorderColorTypeHover,
      borderColorTypeNormal: cardBorderColorTypeNormal,
      borderWidthTypeActive: cardBorderWidthTypeActive,
      borderWidthTypeHover: cardBorderWidthTypeHover,
      borderWidthTypeNormal: cardBorderWidthTypeNormal,
      boxShadowTypeActive: 'none',
      boxShadowTypeHover: 'none',
      boxShadowTypeNormal: 'none',
      cardTitleColorTypeActive,
      cardTitleColorTypeHover,
      cardTitleColorTypeNormal,
      metadataFontSizeLarge,
      metadataFontSizeMedium,
      metadataFontSizeSmall,
      descriptionFontSizeLarge,
      descriptionFontSizeMedium,
      descriptionFontSizeSmall,
      cardProportionsLarge,
      cardProportionsMedium,
      cardProportionsSmall,
      mainCategoryFontSizeLarge,
      mainCategoryFontSizeMedium,
      mainCategoryFontSizeSmall,
      showImageHeightByAspectRatio: true,
      pipeColor,
      showAuthorInLargeScreen: false,
      showAuthorInMediumScreen: false,
      showAuthorInSmallScreen: false,
      showDescriptionInLargeScreen: false,
      showDescriptionInMediumScreen: false,
      showDescriptionInSmallScreen: false,
      showDateInLargeScreen: false,
      showDateInMediumScreen: false,
      showDateInSmallScreen: false,
      aspectRatioLarge: { x: 1, y: 1 },
      aspectRatioMedium: { x: 1, y: 1 },
      aspectRatioSmall: { x: 1, y: 1 },
      horizontalGapLarge: 0,
      horizontalGapMedium: 0,
      horizontalGapSmall: 0,
      titleFontSizeLarge: TypographySizes.NORMAL,
      titleFontSizeMedium: TypographySizes.NORMAL,
      titleFontSizeSmall: TypographySizes.NORMAL,
    }, horizontalCardConfigThemeKey);

    const themeContextSectionHeader = useThemeOverride<SectionHeaderComponentThemeProps>({
      titleColor: cardTitleColorTypeActive,
      titleBackgroundColor: backgroundColor,
      largeMarginBottomFactor: 0,
      mediumMarginBottomFactor: 0,
      smallMarginBottomFactor: 0,
      largeHorizontalGap: 0,
      mediumHorizontalGap: 0,
      smallHorizontalGap: 0,
      largeWrapperGap: 0,
      mediumWrapperGap: 0,
      smallWrapperGap: 0,
    }, SectionHeaderConfigThemeKey);
    const wrapperStyle = createWrapperStyle(backgroundColor, largeHorizontalGap, mediumHorizontalGap, smallHorizontalGap);
    const article1Props = articles?.length > 0 && articles[0];
    const article2Props = articles?.length > 1 && articles[1];
    const article3Props = articles?.length > 2 && articles[2];
    const article4Props = articles?.length > 3 && articles[3];
    const analyticsEventParamsForArticle1 = getAnalyticsEventParams(0);
    const analyticsEventParamsForArticle2 = getAnalyticsEventParams(1);
    const analyticsEventParamsForArticle3 = getAnalyticsEventParams(2);
    const analyticsEventParamsForArticle4 = getAnalyticsEventParams(3);
    const ref = useViewabilityHandler(viewabilityCallback, 0.5);

    return (
      <ErrorBoundary>
        <div ref={ref} className={css(wrapperStyle)} data-mm-id={dataId}>
          <ThemeContext.Provider value={themeContextSectionHeader}>
            <SectionHeader
              moreButtonSvg={null}
              moreButtonText={null}
              paddingFactors={{ large: 0, medium: 0, small: 0 }}
            >
              <H3
                fontSizeLarge={TypographySizes.LARGE}
                fontSizeSmall={TypographySizes.LARGE}
                fontSizeMedium={TypographySizes.LARGE}
              >
                {title}
              </H3>
            </SectionHeader>
          </ThemeContext.Provider>
          <ThemeContext.Provider value={themeContextHC}>
            {article1Props && (
            <Card
              {...article1Props}
              imageWidths={imageWidths}
              onClick={relatedPostsAnalytics(0)}
              ampAnalyticsLabel={analyticsEventParamsForArticle1.label}
              ampAnalyticsCategory={analyticsEventParamsForArticle1.category}
              ampAnalyticsAction={analyticsEventParamsForArticle1.action}
            />
            )}
            {article2Props && (
            <Card
              {...article2Props}
              imageWidths={imageWidths}
              onClick={relatedPostsAnalytics(1)}
              ampAnalyticsLabel={analyticsEventParamsForArticle2.label}
              ampAnalyticsCategory={analyticsEventParamsForArticle2.category}
              ampAnalyticsAction={analyticsEventParamsForArticle2.action}
            />
            )}
            {article3Props && (
            <Card
              {...article3Props}
              imageWidths={imageWidths}
              onClick={relatedPostsAnalytics(2)}
              ampAnalyticsLabel={analyticsEventParamsForArticle3.label}
              ampAnalyticsCategory={analyticsEventParamsForArticle3.category}
              ampAnalyticsAction={analyticsEventParamsForArticle3.action}
            />
            )}
            {article4Props && (
            <Card
              {...article4Props}
              imageWidths={imageWidths}
              onClick={relatedPostsAnalytics(3)}
              ampAnalyticsLabel={analyticsEventParamsForArticle4.label}
              ampAnalyticsCategory={analyticsEventParamsForArticle4.category}
              ampAnalyticsAction={analyticsEventParamsForArticle4.action}
            />
            )}
          </ThemeContext.Provider>
        </div>
      </ErrorBoundary>
    );
  };
  return RelatedPostsComponent;
};
