import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useTheme } from '../../theming/useTheme';
import { MEDIA_BREAKPOINTS } from '../../mediaQueries.const';
import { baseUnit } from '../../theming/baseUnitDefinition';
import { ErrorBoundary } from '../errorHandling/ErrorBoundary';
import { Link } from '../partials/link/Link';
import { semanticCSS, Span } from '../../typography/semanticTags';
import { MarginFactor } from '../components/pageLayouts/BlockTypes.utils';
import { ArticleBlockThemeProps, componentConfigurationKey } from './ArticleBlock.theme';
import { EventContext } from '../../analytics/analytics';

export interface MainCategoryProps {
  displayName: string;
  link: string;
  marginFactor: MarginFactor;
  isActive: boolean;
}

const creatStyle = (horizontalGapLarge: number,
  horizontalGapMedium: number,
  horizontalGapSmall: number,
  verticalGapLarge: number,
  verticalGapMedium: number,
  verticalGapSmall: number,
  backgroundColor: string,
  textColor: string,
  marginFactor: MarginFactor) => {
  const { largeMarginFactor, mediumMarginFactor, smallMarginFactor } = marginFactor;
  return StyleSheet.create({
    style: {
      backgroundColor,
      color: textColor,
      width: 'fit-content',
      display: 'block',
      [MEDIA_BREAKPOINTS.large]: {
        padding: `${horizontalGapLarge * baseUnit}px ${verticalGapLarge * baseUnit}px`,
        marginLeft: `${largeMarginFactor * baseUnit}px`,
        marginTop: `${0.75 * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        padding: `${horizontalGapMedium * baseUnit}px ${verticalGapMedium * baseUnit}px`,
        marginLeft: `${mediumMarginFactor * baseUnit}px`,
        marginTop: `${0.5 * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        padding: `${horizontalGapSmall * baseUnit}px ${verticalGapSmall * baseUnit}px`,
        marginLeft: `${smallMarginFactor * baseUnit}px`,
        marginTop: `${0.5 * baseUnit}px`,
      },
    },
  }).style;
};

export const MainCategory: React.FunctionComponent<MainCategoryProps> = props => {
  const { displayName, link, marginFactor, isActive } = props;
  const { mainCategoryFontSizeLarge, mainCategoryFontSizeMedium, mainCategoryFontSizeSmall, mainCategoryTextColor, mainCategoryBackgroundColor, mainCategoryVerticalGapLarge, mainCategoryVerticalGapMedium, mainCategoryVerticalGapSmall, mainCategoryHorizontalGapLarge, mainCategoryHorizontalGapMedium, mainCategoryHorizontalGapSmall } = useTheme<ArticleBlockThemeProps>(componentConfigurationKey);
  const style = creatStyle(mainCategoryHorizontalGapLarge, mainCategoryHorizontalGapMedium, mainCategoryHorizontalGapSmall, mainCategoryVerticalGapLarge, mainCategoryVerticalGapMedium, mainCategoryVerticalGapSmall, mainCategoryBackgroundColor, mainCategoryTextColor, marginFactor);
  const analyticsEventContext = {
    category: 'Article',
    action: 'Main Category clicked',
  };
  return (
    <ErrorBoundary>
      <EventContext.Provider value={analyticsEventContext}>
        {
        isActive
          ? (
            <Link href={link} className={css(style)}>
              <Span fontSizeLarge={mainCategoryFontSizeLarge} fontSizeMedium={mainCategoryFontSizeMedium} fontSizeSmall={mainCategoryFontSizeSmall}>
                {displayName}
              </Span>
            </Link>
          )
          : (

            <Span styles={semanticCSS(style)} fontSizeLarge={mainCategoryFontSizeLarge} fontSizeMedium={mainCategoryFontSizeMedium} fontSizeSmall={mainCategoryFontSizeSmall}>
              {displayName}
            </Span>
          )
      }
      </EventContext.Provider>
    </ErrorBoundary>
  );
};
