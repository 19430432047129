import * as React from 'react';
import { css } from 'aphrodite/no-important';
import { Helmet } from 'react-helmet';
import { AdditionalStyleSheet } from '../../components.utils';

interface AMPMMPlayerProps {
    additionalStyle: AdditionalStyleSheet;
}

const ampExperimentHtml = '<script type="application/json">{"player-experiment":{"variants":{"A": 50, "B": 50}}}</script>';

export const AMPMMPlayerABTestTSC: React.FunctionComponent<AMPMMPlayerProps> = props => {
  const { additionalStyle } = props;
  // @ts-ignore
  return (
    <React.Fragment>
      <Helmet>
        <script async={undefined} custom-element="amp-experiment" src="https://cdn.ampproject.org/v0/amp-experiment-0.1.js" />
        <script async={undefined} custom-element="amp-minute-media-player" src="https://cdn.ampproject.org/v0/amp-minute-media-player-0.1.js" />
        <script async={undefined} custom-element="amp-video-docking" src="https://cdn.ampproject.org/v0/amp-video-docking-0.1.js" />
        <script async={undefined} custom-element="amp-video-iframe" src="https://cdn.ampproject.org/v0/amp-video-iframe-0.1.js" />
      </Helmet>
      {/* @ts-ignore TS2339 */}
      <amp-experiment dangerouslySetInnerHTML={{ __html: ampExperimentHtml }} />
      <figure id="playerExperiment-A" className={css(additionalStyle.style)}>
        {/* @ts-ignore TS2339 */}
        <amp-minute-media-player
          width="16"
          height="9"
          data-content-type="specific"
          data-content-id="01f4c5gzqt3bae8tbt#01gs3easvjs4vm6p51"
          layout="responsive"
          data-block-on-consent="default"
        />
      </figure>
      <figure id="playerExperiment-B" className={css(additionalStyle.style)}>
        {/* @ts-ignore TS2339 */}
        <amp-video-iframe
          src="https://vms-players.minutemediaservices.com/amp-video-iframe-content-mp.html"
          width="16"
          height="9"
          layout="responsive"
          data-param-player-id="01gs3eaq8mkz8bnr74"
          data-param-content-id="01f4c5gzqt3bae8tbt"
          data-block-on-consent="default"
          dock="#mm-dock-slot"
        />
        <div style={{ width: '100%', position: 'fixed', bottom: '1%', marginLeft: '38%' }}>
          {/* @ts-ignore */ }
          <amp-layout style={{ width: '60%', aspectRatio: '16/9', right: '0' }} id="mm-dock-slot" />
        </div>
      </figure>
    </React.Fragment>
  );
};
