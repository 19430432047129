import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import {
  EditionsDataProps,
  getActiveEdition,
} from '../../components/navigation/header/oldHeader/primaryHeader/Editions';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { AMPSideBarLink } from './AMPSideBarLink';
import { navigationLinkToMenuLinkDataProps } from '../../components/navigation/header/oldHeader/primaryHeader/menu/links/MenuLinks';

interface AMPMenuEditionsThemeProps {
  dividerColor: string;
  baseUnit: BaseUnit;
}

type AMPMenuEditionsProps = AMPMenuEditionsThemeProps & EditionsDataProps;

const createStyle = (dividerColor: string, baseUnit: BaseUnit) => StyleSheet.create({
  divider: {
    backgroundColor: dividerColor,
    height: '1px',
    [MEDIA_BREAKPOINTS.medium]: {
      margin: `0 ${baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      margin: `0 ${baseUnit}px`,
    },
  },
});

export const AMPMenuEditions: React.FunctionComponent<AMPMenuEditionsProps> = props => {
  const { editions, dividerColor, baseUnit, editionEndpoint } = props;
  const activeEdition = getActiveEdition(editions, editionEndpoint);
  const { text } = activeEdition.link;
  const notActivatedEditions = editions.filter(edition => edition.link.text !== text).map(navigationLinkToMenuLinkDataProps);
  const style = createStyle(dividerColor, baseUnit);
  return (
    <React.Fragment>
      <div className={css(style.divider)} />
      {/*
  // @ts-ignore TS2339: Property 'amp-accordion' does not exist on type 'JSX.IntrinsicElements'. */}
      <amp-accordion id="editions-accordion" disable-session-states={undefined}>
        <AMPSideBarLink link={activeEdition.link} childrenLinks={notActivatedEditions} />
        {/*
  // @ts-ignore TS2339: Property 'amp-accordion' does not exist on type 'JSX.IntrinsicElements'. */}
      </amp-accordion>
    </React.Fragment>

  );
};
