import { checkAllRules } from './checkAllRules';
import {
  TRAFFIC_INFO_KEY, TRAFFIC_SOURCES,
} from './constants';
import { LocalStorage } from './utils/LocalStorage';
import { getTrafficSourceValue } from './trafficSourceValue';
import { getPageURL } from './pageURL/pageURL';

const analyze = () => {
  const traffic = {
    url: window.location.href,
    referrer: document.referrer,
  };
  const { trafficSource: newSource, trafficId: newId } = checkAllRules(traffic);
  const sessionLength = 30;

  let trafficSource = newSource;
  let trafficId = newId;

  const trafficInfo = LocalStorage.getItem(TRAFFIC_INFO_KEY);
  if (trafficInfo !== null) {
    const { trafficSource: oldSource, trafficId: oldId, timestamp } = trafficInfo;
    const timestampValue = new Date(timestamp).getTime();
    const sessionPassed = new Date(timestampValue + sessionLength * 60000) < new Date();
    const isTrafficSourceNavigation = newSource === TRAFFIC_SOURCES.NAVIGATION;
    if (isTrafficSourceNavigation) {
      if (sessionPassed) {
        trafficSource = TRAFFIC_SOURCES.DIRECT;
        trafficId = null;
      } else {
        trafficSource = oldSource;
        trafficId = oldId;
      }
    }
  }
  LocalStorage.setItem(TRAFFIC_INFO_KEY, { trafficSource, trafficId, timestamp: new Date() });
};

export const trafficSourceAnalysis = () => {
  analyze();
  const trafficInfo = LocalStorage.getItem(TRAFFIC_INFO_KEY) || {};
  const trafficSourceGA = trafficInfo.trafficSource;
  const { trafficId } = trafficInfo;
  const { test, affiliateId } = getPageURL();
  const trafficSource = getTrafficSourceValue(trafficSourceGA || '', trafficId || '', affiliateId || '');
  const trafficSourceAndId = trafficId ? `${trafficSource}-${trafficId}` : trafficSource;
  return {
    trafficSourceGA: trafficSourceGA || '',
    trafficId: trafficId || '',
    trafficSourceAndId: trafficSourceAndId || '',
    testValue: test || '',
    affiliateId: affiliateId || '',
    trafficSource: trafficSource || '',
  };
};
