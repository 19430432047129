import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { getLayoutComponentPadding, LayoutComponentBaseProps } from '../pageLayouts/layout.utils';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { useTheme } from '../../../theming/useTheme';
import {
  componentConfigThemeKey as onTopCardConfigThemeKey,
  OnTopCard,
  OnTopCardComponentThemeProps,
} from '../../partials/cards/onTopCard/OnTopCard';
import { createImageHeightCalculationMethods, createImageWidths } from '../../partials/image/image.utils';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { VerticalCard } from '../../partials/cards/verticalCard/VerticalCard';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { VerticalCardThemeProps, componentConfigThemeKey as verticalCardConfigThemeKey } from '../../partials/cards/verticalCard/VerticalCard.theme';

export const componentConfigThemeKey = 'oneOTCFourVC';

interface OneOTCFourVCProps extends LayoutComponentBaseProps {
    cards: CardComponentDataProps[];
}

export interface OneOTCFourVCThemeProps {
    largeVerticalGap: number;
    largeHorizontalGap: number;
    mediumVerticalGap: number;
    mediumHorizontalGap: number;
    smallHorizontalGap: number;
    baseUnit: number;
    authorColor: string;
}

const createStyle = (themeProps: OneOTCFourVCThemeProps) => {
  const { largeHorizontalGap, largeVerticalGap, mediumHorizontalGap, mediumVerticalGap, smallHorizontalGap, baseUnit } = themeProps;
  const firstCardArea = 'first_card_area';
  const secondCardArea = 'second_card_area';
  const thirdCardArea = 'third_card_area';
  const fourthCardArea = 'fourth_card_area';
  const fifthCardArea = 'fifth_card_area';
  return StyleSheet.create({
    wrapper: {
      display: 'grid',
      [MEDIA_BREAKPOINTS.large]: {
        gridTemplateColumns: '2fr 1fr 1fr',
        gridTemplateRows: '1fr 1fr',
        gridColumnGap: `${largeVerticalGap * baseUnit}px`,
        gridRowGap: `${largeHorizontalGap * baseUnit}px`,
        gridTemplateAreas: `'${firstCardArea} ${secondCardArea} ${thirdCardArea}' '${firstCardArea} ${fourthCardArea} ${fifthCardArea}'`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: 'auto auto auto',
        gridColumnGap: `${mediumVerticalGap * baseUnit}px`,
        gridRowGap: `${mediumHorizontalGap * baseUnit}px`,
        gridTemplateAreas: `'${firstCardArea} ${firstCardArea}' '${secondCardArea} ${thirdCardArea}' '${fourthCardArea} ${fifthCardArea}'`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        gridTemplateColumns: '1fr',
        gridRowGap: `${smallHorizontalGap * baseUnit}px`,
        gridTemplateAreas: `'${firstCardArea}' '${secondCardArea}' '${thirdCardArea}' '${fourthCardArea}' '${fifthCardArea}'`,

      },
    },
    firstCard: {
      gridArea: firstCardArea,
    },
    secondCard: {
      gridArea: secondCardArea,
    },
    thirdCard: {
      gridArea: thirdCardArea,
    },
    fourthCard: {
      gridArea: fourthCardArea,
    },
    fifthCard: {
      gridArea: fifthCardArea,
    },
    minorCard: {
      [MEDIA_BREAKPOINTS.small]: {
        paddingLeft: `${0.5 * baseUnit}px`,
        paddingRight: `${0.5 * baseUnit}px`,
      },
    },
  });
};

const imageWidthsForMainCard = createImageWidths(360, 720, 540);
const imageWidthsForMinorCard = createImageWidths(360, 360, 360);

const onTopCardImageHeightCalculationMethods = createImageHeightCalculationMethods('ASPECT_RATIO', 'ASPECT_RATIO', '100%_HEIGHT');

export const OneOTCFourVC: React.FunctionComponent<OneOTCFourVCProps> = props => {
  const { cards, paddingFactors = { large: 0.5, medium: 0.5, small: 0 } } = props;
  const paddingStyle = getLayoutComponentPadding(paddingFactors);
  const themeProps = useTheme<OneOTCFourVCThemeProps>(componentConfigThemeKey);
  const styles = createStyle(themeProps);
  const { authorColor } = themeProps;
  const themeContextFirstCard = useThemeOverride<OnTopCardComponentThemeProps>({
    titleFontSizeLarge: 'large',
    titleFontSizeMedium: 'large',
    titleFontSizeSmall: 'bigger',
    metadataFontSizeLarge: 'bigger',
    metadataFontSizeMedium: 'bigger',
    metadataFontSizeSmall: 'big',
    horizontalGapSmall: 0.5,
    horizontalGapMedium: 0.5,
    horizontalGapLarge: 0.5,
    aspectRatioSmall: { x: 4, y: 3 },
    aspectRatioLarge: { x: 3, y: 4 },
    authorColor,
  }, onTopCardConfigThemeKey);
  const themeContextMinorCard = useThemeOverride<VerticalCardThemeProps>({
    titleFontSizeLarge: 'bigger',
    titleFontSizeMedium: 'bigger',
    titleFontSizeSmall: 'bigger',
    metadataFontSizeLarge: 'big',
    metadataFontSizeMedium: 'big',
    metadataFontSizeSmall: 'big',
    horizontalGapSmall: 0.5,
    horizontalGapMedium: 0.5,
    horizontalGapLarge: 0.5,
  }, verticalCardConfigThemeKey);
  return (
    <ErrorBoundary>
      {cards && cards.length > 0 && (
      <div className={css(paddingStyle, styles.wrapper)}>
        <ThemeContext.Provider value={themeContextFirstCard}>
          <div className={css(styles.firstCard)}>
            <OnTopCard {...cards[0]} imageWidths={imageWidthsForMainCard} imageHeightCalculationMethods={onTopCardImageHeightCalculationMethods} lazyLoad />
          </div>
        </ThemeContext.Provider>
        <ThemeContext.Provider value={themeContextMinorCard}>
          <div className={css(styles.secondCard, styles.minorCard)}>
            <VerticalCard {...cards[1]} imageWidths={imageWidthsForMinorCard} />
          </div>
          <div className={css(styles.thirdCard, styles.minorCard)}>
            <VerticalCard {...cards[2]} imageWidths={imageWidthsForMinorCard} />
          </div>
          <div className={css(styles.fourthCard, styles.minorCard)}>
            <VerticalCard {...cards[3]} imageWidths={imageWidthsForMinorCard} />
          </div>
          <div className={css(styles.fifthCard, styles.minorCard)}>
            <VerticalCard {...cards[4]} imageWidths={imageWidthsForMinorCard} />
          </div>
        </ThemeContext.Provider>
      </div>
      )}
    </ErrorBoundary>
  );
};
