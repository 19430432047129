import { TranslatedFontSize } from '../../../theming/fontSizeTranslator';

export const SMALL_SCREEN_MAX_NUMBER_OF_LINKS_TO_DISPLAY = 10;
const SMALL_SCREEN_TOTAL_LINKS_GAP = 90;

export interface Link {
  text: string;
  href: string;
}

export interface Logo {
  src?: string;
  alt?: string;
}

export const getListByAlphabetOrder = (links: Link[]) => links.sort((linkA, linkB) => {
  const textA = linkA.text.toUpperCase();
  const textB = linkB.text.toUpperCase();
  return textA.localeCompare(textB);
});

export const getContainerHeightSmallScreen = (fontSizesStyles: TranslatedFontSize) => {
  const lineHeightSmallScreen = fontSizesStyles.bigger.breakpoints.small.lineHeight;
  const lineHeightNumberSmallScreen = Number(lineHeightSmallScreen.replace(/[^0-9]/g, ''));
  return lineHeightNumberSmallScreen * SMALL_SCREEN_MAX_NUMBER_OF_LINKS_TO_DISPLAY + SMALL_SCREEN_TOTAL_LINKS_GAP;
};
