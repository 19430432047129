import * as React from 'react';
import { css, StyleSheet, StyleDeclaration } from 'aphrodite/no-important';
import { ErrorBoundary } from '../../../errorHandling/ErrorBoundary';
import { useTheme } from '../../../../theming/useTheme';
import { MEDIA_BREAKPOINTS } from '../../../../mediaQueries.const';

const componentConfigurationKey = 'copyLinkButton';

interface CopyLinkButtonDataProps {
  canonical: string;
  buttonClass: StyleDeclaration;
}

interface CopyLinkButtonThemeProps {
  color: string;
}

const getStyles = () => {
  return StyleSheet.create({
    button: {
      position: 'relative',
      overflow: 'hidden',
      [MEDIA_BREAKPOINTS.large]: {
        width: '30px',
        height: '30px',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        width: '30px',
        height: '30px',
      },
      [MEDIA_BREAKPOINTS.small]: {
        width: '20px',
        height: '20px',
      },
    },
    copyLinkIcon: {
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      transition: '200ms ease-in-out',
      [MEDIA_BREAKPOINTS.large]: {
        width: '30px',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        width: '30px',
      },
      [MEDIA_BREAKPOINTS.small]: {
        width: '20px',
      },
    },
    copyLinkIconAction: {
      left: '-150%',
    },
    successIcon: {
      left: '150%',
    },
    successIconAction: {
      left: '50%',
    },
  });
};

const copyToClipboard = (text: string) => {
  const el = document.createElement('textarea');
  el.value = text;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.opacity = '0';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const CopyLinkButton: React.FunctionComponent<CopyLinkButtonDataProps> = props => {
  const { canonical, buttonClass } = props;
  const { color } = useTheme<CopyLinkButtonThemeProps>(componentConfigurationKey);
  const styles = getStyles();
  let timeout: NodeJS.Timeout;

  const [wasCopied, setWasCopied] = React.useState(false);

  const onClickCopy = () => {
    copyToClipboard(canonical);
    clearTimeout(timeout);
    setWasCopied(true);
    timeout = setTimeout(() => {
      setWasCopied(false);
    }, 1000);
  };

  const copyIconAction = wasCopied ? styles.copyLinkIconAction : styles.copyLinkIcon;
  const succcessIconAction = wasCopied ? styles.successIconAction : styles.successIcon;

  return (
    <ErrorBoundary>
      <button type="button" className={css(styles.button, buttonClass)} onClick={onClickCopy}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" className={css(styles.copyLinkIcon, copyIconAction)} fill={color}>
          <path d="M23.906 30H0V5.625h5.625V0H30v24.375h-1.875v-.001h-4.219V30zM5.625 24.375V7.5h-3.75v20.624h20.156v-3.75H7.5v.001H5.625zm22.5-22.501H7.5v20.625h20.625V1.874z" />
        </svg>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" className={css(styles.copyLinkIcon, succcessIconAction)}>
          <g fill={color}>
            <path d="M59.5,10.8" />
            <path d="M23.1,56L1,32.9c-1.4-1.4-1.3-3.7,0.1-5.1c1.4-1.4,3.7-1.4,5,0.1l17,17.8L57.9,9.1c1.4-1.4,3.6-1.5,5-0.1 c1.4,1.4,1.4,3.7,0.1,5.1L23.1,56z" />
            <path d="M23.4,43.4" />
          </g>
        </svg>
      </button>
    </ErrorBoundary>
  );
};
