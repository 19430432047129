import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { WrapperForCard } from '../WrapperForCard';
import { useTheme } from '../../../../theming/useTheme';
import { clickableLabelsStyles } from '../cards.utils';
import { Link } from '../../link/Link';
import { decorationLineHeight } from '../../DecorationLine';
import { createStyleForBoxShadow } from '../../../../theming/boxShadow';
import { componentConfigThemeKey, VerticalCardThemeProps } from './VerticalCard.theme';
import { VerticalCardProps } from './VerticalCard.props';

const createLinkStyle = (
  cardBackgroundColor: string,
  cardTextColorTypeNormal: string,
  cardTextColorTypeHover: string,
  cardTextColorTypeActive: string,
  borderRadius: number,
  showDecorationLine: boolean,
) => {
  return StyleSheet.create({
    wrapper: {
      width: '100%',
      height: '100%',
      textDecoration: 'none',
      color: cardTextColorTypeNormal,
      backgroundColor: cardBackgroundColor,
      '-webkit-mask-image': '-webkit-radial-gradient(white, black)',
      borderRadius: `${borderRadius}px`,
      position: 'relative',
      ':hover': {
        color: cardTextColorTypeHover,
      },
      ':active': {
        color: cardTextColorTypeActive,
      },
      display: 'grid',
      gridTemplateRows: showDecorationLine ? `min-content ${decorationLineHeight}px auto` : 'min-content auto',
    },
  }).wrapper;
};

interface ArticleLinkProps {
    articleUrl: string;
    title: string;
    isClickableLayout: boolean;
    showDecorationLine: boolean;
    onClick?: React.MouseEventHandler;
}

const ArticleLink: React.FunctionComponent<ArticleLinkProps> = ({ articleUrl, title, onClick, isClickableLayout, showDecorationLine, children }) => {
  const { cardBackgroundColor, cardTitleColorTypeNormal, cardTitleColorTypeHover, cardTitleColorTypeActive, borderRadius } = useTheme<VerticalCardThemeProps>(componentConfigThemeKey);
  const linkStyle = createLinkStyle(cardBackgroundColor, cardTitleColorTypeNormal, cardTitleColorTypeHover, cardTitleColorTypeActive, borderRadius, showDecorationLine);
  const { articleLink } = clickableLabelsStyles();

  return (
    isClickableLayout
      ? (
        <div className={css(linkStyle)}>
          <Link className={css(articleLink)} title={title} href={articleUrl} onClick={onClick} />
          {children}
        </div>
      )
      : (
        <Link
          className={css(linkStyle)}
          href={articleUrl}
          onClick={onClick}
        >
          {children}
        </Link>
      )
  );
};

type VerticalCardWithImageProps = VerticalCardProps & {isClickableLayout: boolean};

export const VerticalCardWrapper: React.FunctionComponent<VerticalCardWithImageProps> = props => {
  const {
    showDecorationLine = true,
    title,
    articleUrl,
    onClick,
    isClickableLayout,
    children,
  } = props;
  const {
    borderRadius,
    borderColorTypeActive,
    borderColorTypeHover,
    borderColorTypeNormal,
    borderWidthTypeActive,
    borderWidthTypeHover,
    borderWidthTypeNormal,
    boxShadowTypeNormal,
    boxShadowTypeHover,
    boxShadowTypeActive,
    wrapperGapLarge,
    wrapperGapMedium,
    wrapperGapSmall,
  } = useTheme<VerticalCardThemeProps>(componentConfigThemeKey);

  const borderBoxShadow = {
    borderColorTypeActive,
    borderColorTypeHover,
    borderColorTypeNormal,
    borderWidthTypeActive,
    borderWidthTypeHover,
    borderWidthTypeNormal,
  };

  const boxShadowStyle = createStyleForBoxShadow({
    boxShadowTypeNormal,
    boxShadowTypeHover,
    boxShadowTypeActive,
    borderBoxShadow,
  });

  const articleLinkProps = {
    onClick,
    articleUrl,
    title,
    isClickableLayout,
    showDecorationLine,
  };

  return (
    <WrapperForCard
      boxShadowStyle={boxShadowStyle}
      borderRadius={borderRadius}
      paddingLarge={wrapperGapLarge}
      paddingMedium={wrapperGapMedium}
      paddingSmall={wrapperGapSmall}
    >
      <ArticleLink {...articleLinkProps}>
        {children}
      </ArticleLink>
    </WrapperForCard>
  );
};
