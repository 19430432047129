import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { MandatoryAdditionalStyle } from '../../components.utils';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { BlockWidths } from '../../components/pageLayouts/BlockTypes.utils';
import { createBlockStyle } from '../blockMutualStyle';


export interface GiphyComponentDataProps {
    url: string;
    width: number;
    height: number;
    description: string;
    dataId?: string;
    defaultBlocksWidths: BlockWidths;
}

type GiphyComponentProps = GiphyComponentDataProps & MandatoryAdditionalStyle;

const getStyle = (width: number, height: number, defaultBlocksWidths: BlockWidths) => {
  return StyleSheet.create({
    wrapper: {
      ...createBlockStyle(defaultBlocksWidths),
    },
    figure: {
      position: 'relative',
      overflow: 'hidden',
      margin: 0,
      boxSizing: 'border-box',
      flex: '1 1 100%',
      minWidth: '0%',
      paddingTop: `calc(100% / (${width / height}))`,
    },
    image: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
    },
  });
};

export const GiphyComponent: React.FunctionComponent<GiphyComponentProps> = props => {
  const { additionalStyle, url, width, height, description, dataId, defaultBlocksWidths } = props;
  const style = getStyle(width, height, defaultBlocksWidths);

  return (
    <div data-mm-id={dataId} className={css(additionalStyle.style, style.wrapper)}>
      <figure className={css(style.figure)}>
        <img src={url} className={css(style.image)} alt={description} />
      </figure>
    </div>
  );
};

export const Giphy = (props: GiphyComponentProps) => <ErrorBoundary><GiphyComponent {...props} /></ErrorBoundary>;
