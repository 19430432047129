import { TypographySize } from 'mm-theme-configuration/dist/consts';
import * as React from 'react';
import { css, StyleDeclarationValue, StyleSheet } from 'aphrodite/no-important';
import { generateLinkStyleFromTheme, LinkStyle, TextLinkComponent } from '../../partials/link/TextLink';
import { ArrowSvg } from './ArrowSvg';
import { H4, semanticCSS } from '../../../typography/semanticTags';
import { Link, SvgLink } from './SectionHeader';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { useTheme } from '../../../theming/useTheme';

export const componentConfigurationKey = 'moreButton';

export interface MoreButtonThemeProps {
  showArrow: boolean;
  idleColor: string;
  hoverStateColor: string;
  activeStateColor: string;
  fontSizeLarge: TypographySize;
  fontSizeMedium: TypographySize;
  fontSizeSmall: TypographySize;
}

export interface MoreButtonProps {
  moreButtonText: Link | null;
  moreButtonSvg: SvgLink | null;
}

const createLinkTextStyles = (idleColor: string, hoverStateColor: string, activeStateColor: string) => StyleSheet.create({
  linkText: {
    [MEDIA_BREAKPOINTS.large]: {
      ':hover': {
        textDecoration: 'underline',
      },
    },
    [MEDIA_BREAKPOINTS.medium]: {
      ':hover': {
        color: 'initial',
      },
    },
    [MEDIA_BREAKPOINTS.small]: {
      ':hover': {
        color: 'initial',
      },
    },
  },
  moreButtonSvg: {
    fill: idleColor,
    width: '30px',
    height: '25px',
    ':hover': {
      fill: hoverStateColor,
    },
    ':active': {
      fill: activeStateColor,
    },
  },
  moreButtonStroke: {
    stroke: idleColor,
    ':hover': {
      stroke: hoverStateColor,
    },
    ':active': {
      stroke: activeStateColor,
    },
  },
});

const createMoreButtonText = (href: string, text: string, titleStyle: StyleDeclarationValue, linkStyle: LinkStyle, fontSizeLarge: TypographySize, fontSizeMedium: TypographySize, fontSizeSmall: TypographySize) => {
  return (
    <TextLinkComponent href={href} linkStyle={linkStyle}>
      <H4
        fontSizeLarge={fontSizeLarge}
        fontSizeMedium={fontSizeMedium}
        fontSizeSmall={fontSizeSmall}
        styles={semanticCSS(titleStyle)}
      >
        {text}
      </H4>
    </TextLinkComponent>
  );
};

export const MoreButton: React.FunctionComponent<MoreButtonProps> = props => {
  const { moreButtonSvg, moreButtonText } = props;
  const themeProps = useTheme<MoreButtonThemeProps>(componentConfigurationKey);
  const { showArrow, idleColor, hoverStateColor, activeStateColor, fontSizeLarge, fontSizeMedium, fontSizeSmall } = themeProps;
  const style = createLinkTextStyles(idleColor, hoverStateColor, activeStateColor);
  const additionalStyle = {
    justifyContent: 'flex-end',
    marginLeft: 'auto',
  };
  const linkStyle = generateLinkStyleFromTheme({
    idleColor,
    activeStateColor,
    hoverStateColor,
  }, additionalStyle);

  if (moreButtonSvg) {
    const svg = moreButtonSvg.src
      .replace(/<g/g, `<g class="${css(style.moreButtonStroke)}"`)
      .replace(/<svg/g, `<svg class="${css(style.moreButtonSvg)}"`);
    return (
      <TextLinkComponent href={moreButtonSvg.href} linkStyle={linkStyle}>
        <div dangerouslySetInnerHTML={{ __html: svg }} />
      </TextLinkComponent>
    );
  }
  if (moreButtonText) {
    if (showArrow) {
      return (
        <TextLinkComponent href={moreButtonText.href} linkStyle={linkStyle}>
          <ArrowSvg arrowColor={idleColor} alt={moreButtonText.text} />
        </TextLinkComponent>
      );
    }
    return createMoreButtonText(moreButtonText.href, moreButtonText.text, style.linkText, linkStyle, fontSizeLarge, fontSizeMedium, fontSizeSmall);
  }
  return null;
};
