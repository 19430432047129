"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CONFIGURATION_TYPES = exports.SEMANTIC_TAG_VALUES = exports.ALIGNMENT_OPTIONS = exports.BOOLEAN_VALUES = exports.BOX_SHADOW_TYPES_VALUES = exports.COLORS = exports.BLUE = exports.WHITE = exports.BLACK = exports.LIGHT_GREY = exports.GREY = exports.DARK_GREY = exports.SECONDARY = exports.PRIMARY = exports.TYPOGRAPHY_SIZE = exports.SCREEN_SIZE = void 0;
exports.SCREEN_SIZE = {
    LARGE: 'large',
    MEDIUM: 'medium',
    SMALL: 'small',
};
exports.TYPOGRAPHY_SIZE = {
    GIGANTIC: 'gigantic',
    HUGE: 'huge',
    LARGE: 'large',
    BIGGER: 'bigger',
    BIG: 'big',
    NORMAL: 'normal',
    TINY: 'tiny',
};
exports.PRIMARY = 'primary';
exports.SECONDARY = 'secondary';
exports.DARK_GREY = 'darkGrey';
exports.GREY = 'grey';
exports.LIGHT_GREY = 'lightGrey';
exports.BLACK = 'black';
exports.WHITE = 'white';
exports.BLUE = 'blue';
exports.COLORS = {
    PRIMARY: exports.PRIMARY,
    SECONDARY: exports.SECONDARY,
    DARK_GREY: exports.DARK_GREY,
    GREY: exports.GREY,
    LIGHT_GREY: exports.LIGHT_GREY,
    BLACK: exports.BLACK,
    WHITE: exports.WHITE,
    BLUE: exports.BLUE,
};
exports.BOX_SHADOW_TYPES_VALUES = {
    LOW: 'LOW',
    HIGH: 'HIGH',
    NONE: 'NONE',
};
exports.BOOLEAN_VALUES = {
    FALSE: false,
    TRUE: true,
};
exports.ALIGNMENT_OPTIONS = {
    CENTER: 'CENTER',
    START: 'START',
};
exports.SEMANTIC_TAG_VALUES = {
    H1: 'h1',
    H2: 'h2',
    H3: 'h3',
    H4: 'h4',
    H5: 'h5',
    H6: 'h6',
    P: 'p',
    SPAN: 'span',
};
exports.CONFIGURATION_TYPES = {
    COLOR: 'COLOR',
    TYPOGRAPHY: 'TYPOGRAPHY',
    NUMBER: 'NUMBER',
    BOX_SHADOW: 'BOX_SHADOW',
    BOOLEAN: 'BOOLEAN',
    ALIGNMENT: 'ALIGNMENT',
    RATIO: 'RATIO',
    SEMANTIC_TAG: 'SEMANTIC_TAG',
    FACTOR: 'FACTOR',
};
