import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useTheme } from '../../theming/useTheme';

const componentConfigurationKey = 'postMainContent';

interface PostMainContentThemeProps {
  backgroundColor: string;
}

const createStyle = (backgroundColor: string) => StyleSheet.create({
  style: {
    backgroundColor,
    margin: '0 auto',
    maxWidth: '100%',
    minWidth: '100%',
  },
});

export const FullWidthPostMainContent: React.FunctionComponent = ({ children }) => {
  const { backgroundColor } = useTheme<PostMainContentThemeProps>(componentConfigurationKey);

  return (
    <article className={css(createStyle(backgroundColor).style)}>
      {children}
    </article>
  );
};
