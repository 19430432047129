import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { baseUnit } from '../../../theming/baseUnitDefinition';
import { CurrentGroup, FeedGroup, FEED_GROUP_ALL_KEY, getFeedGroupByKey, useLinkButtonThemeContextOverride, useSectionHeaderThemeContextOverride } from './sitemap.utils';
import { SectionHeader } from '../../wrappers/sectionHeader/SectionHeader';
import { H2, H6 } from '../../../typography/semanticTags';
import { LinkWithButtonStyle } from '../buttons/LinkWithButtonStyle';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { AdditionalStyleSheet } from '../../components.utils';

const FEED_COUNT_LIMIT = 10;

const createStyles = () => StyleSheet.create({
  feedGroup: {
    marginTop: baseUnit,
  },
  feedsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: baseUnit / 2,
  },
});

interface CreateFeedStylesProps {
  spacing?: number;
}

export const createFeedStyles = ({ spacing }: CreateFeedStylesProps = {}) => StyleSheet.create({
  style: {
    minWidth: 'auto',
    padding: `0 ${spacing || (baseUnit / 2)}px`,
    height: baseUnit * 1.5,
    whiteSpace: 'nowrap',
  },
}) as AdditionalStyleSheet;

export interface FeedGroupListProps {
  feedGroups: FeedGroup[];
  currentGroup: CurrentGroup;
  moreButtonLabel: string;
}

export const FeedGroupList = ({ feedGroups, currentGroup, moreButtonLabel }: FeedGroupListProps) => {
  const styles = createStyles();
  const feedStyles = createFeedStyles();
  const moreButtonStyles = createFeedStyles({ spacing: 5 });
  const feedGroupsToRender = currentGroup === FEED_GROUP_ALL_KEY
    ? feedGroups.map(feedGroup => ({ ...feedGroup, feeds: feedGroup.feeds.slice(0, FEED_COUNT_LIMIT) }))
    : [getFeedGroupByKey(feedGroups, currentGroup)].filter(value => value !== undefined) as FeedGroup[];
  const themeContextSectionHeader = useSectionHeaderThemeContextOverride();
  const themeContextLinkButton = useLinkButtonThemeContextOverride();

  return (
    <React.Fragment>
      {
        feedGroupsToRender.map(feedGroup => (
          <div key={feedGroup.key} className={css(styles.feedGroup)}>
            <ThemeContext.Provider value={themeContextSectionHeader}>
              <SectionHeader moreButtonText={null} moreButtonSvg={null} paddingFactors={{ large: 0, medium: 0, small: 0 }}>
                <H2>
                  {feedGroup.displayName}
                </H2>
              </SectionHeader>
            </ThemeContext.Provider>
            <ThemeContext.Provider value={themeContextLinkButton}>
              <div className={css(styles.feedsContainer)}>
                {feedGroup.feeds.length
                  ? (
                    <React.Fragment>
                      {feedGroup.feeds.map(feed => (
                        <LinkWithButtonStyle
                          href={feed.link}
                          target="_blank"
                          key={feed.link}
                          additionalStyle={feedStyles}
                        >
                          {feed.name}
                        </LinkWithButtonStyle>
                      ))}
                      {currentGroup === FEED_GROUP_ALL_KEY && (
                        <LinkWithButtonStyle href={feedGroup.link} secondary additionalStyle={moreButtonStyles}>{moreButtonLabel}</LinkWithButtonStyle>
                      )}
                    </React.Fragment>
                  ) : (
                    <H6>There are no feed pages under this letter</H6>
                  )}
              </div>
            </ThemeContext.Provider>
          </div>
        ))
      }
    </React.Fragment>
  );
};
