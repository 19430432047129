import { isInternalPM, isShinez, isWazimo } from './pm';
import { isInfluencers, isJunGroup, isPaidTraffic } from './paid';
import { isDirect, isEmail, isReferral, isSEO, isSocial } from './organic';

export const getTrafficSourceValue = (source: string, medium: string, affiliateId: string) => {
  if (isInternalPM(medium) || isWazimo() || isShinez()) {
    return 'PM';
  }
  if (isInfluencers(affiliateId, medium) || isJunGroup(source) || isPaidTraffic(medium)) {
    return 'Paid';
  }
  if (isSocial(source)) {
    return 'Social';
  }
  if (isSEO(source, medium)) {
    return 'SEO';
  }
  if (isReferral(source, medium)) {
    return 'Referral';
  }
  if (isEmail(medium)) {
    return 'Email';
  }
  if (isDirect(source)) {
    return 'Direct';
  }
  return 'Other';
};
