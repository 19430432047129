import React, { useMemo, useState } from 'react';
import { css, StyleDeclarationValue, StyleSheet } from 'aphrodite/no-important';
import { Span } from '../../../typography/semanticTags';
import { useTheme } from '../../../theming/useTheme';
import { TypographySizes } from '../../../typography/semanticTags/semanticTags.utils';
import { Sportradar } from './Sportradar';
import { buildSportradarFromName, buildSportradarProps } from './sportradar.utils';
import { SportradarPropsType } from './sportradar.types';
import { Tag } from '../tag/Tag';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';

const componentConfigThemeKey = 'sportradar';
interface SportradarThemeProps {
  titleColor: string;
  backgroundColor: string;
}

interface SportradarFactoryProps {
  sportradarList?: SportradarPropsType[];
  wrapperStyle?: StyleDeclarationValue;
  widgetStyle?: StyleDeclarationValue;
  name?: string;
  sportradarProps?: SportradarPropsType;
  buttonTitle?: string;
  buttonUrl?: string;
}

const createStyle = (backgroundColor: string, titleColor: string) => StyleSheet.create({
  container: {
    backgroundColor,
    boxShadow: '0 0 3px rgba(0,0,0,.2)',
    marginBottom: '20px',
    padding: '20px 20px',
  },
  title: {
    color: titleColor,
    marginBottom: '10px',
  },
  buttonContainer: {
    marginTop: '10px',
  },
});

export const SportradarFactory = ({
  sportradarList,
  wrapperStyle,
  widgetStyle,
  name,
  sportradarProps,
  buttonTitle,
  buttonUrl,
}: SportradarFactoryProps) => {
  const [error, setError] = useState(false);
  const { backgroundColor, titleColor } = useTheme<SportradarThemeProps>(componentConfigThemeKey);
  const style = createStyle(backgroundColor, titleColor);
  const widgets = useMemo((): SportradarPropsType[] => {
    if (sportradarList?.length) {
      return sportradarList;
    }

    if (!name && !sportradarList?.length) {
      console.error('Something went wrong with the sportradar widget');
      setError(true);
      return [];
    }
    setError(false);

    return buildSportradarFromName(name as string, sportradarProps || {});
  }, [sportradarList, name, sportradarProps]);

  return (
    <ErrorBoundary>
      {widgets.length && !error
        ? widgets.map((item: SportradarPropsType, index: number) => {
          return (
            <div
              className={css(style.container, wrapperStyle)}
              key={`sr-wrapper-${index}`}
            >
              {item.title ? (
                <Span styles={[style.title]} fontSizeLarge={TypographySizes.BIGGER} fontSizeMedium={TypographySizes.BIGGER} fontSizeSmall={TypographySizes.BIGGER}>{item.title}</Span>
              ) : null}
              <Sportradar
                dataId={item.dataId as string}
                widgetId={item.widgetId as string}
                language={item.language || 'en_us'}
                sportradarProps={buildSportradarProps(widgets, item, index)}
                style={widgetStyle}
              />
              {buttonTitle && buttonUrl ? (
                <div className={css(style.buttonContainer)}>
                  <Tag link={buttonUrl} label={buttonTitle} />
                </div>
              ) : null}
            </div>
          );
        })
        : null}
    </ErrorBoundary>
  );
};
