import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { TypographySize } from 'mm-theme-configuration/dist/consts';
import { Image } from '../../partials/image/Image';
import { createImageWidths, useTransformCreditHTML } from '../../partials/image/image.utils';
import { useTheme } from '../../../theming/useTheme';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { semanticCSS, H1 } from '../../../typography/semanticTags';
import { AdditionalStyleSheet } from '../../components.utils';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { SocialButton } from '../ShareMenu/ShareMenu';
import { ArticleOnTopTitleShareButton } from './ArticleOnTopTitleShareMenu/ArticleOnTopTitleShareButton';
import { CardOverlay } from '../../partials/CardOverlay';
import { Thumbnail } from '../../partials/image/image.types';
import { ellipsify } from '../../../typography/ellipsify';
import { CameraIcon } from '../../partials/image/ImageWithCaptionAndIcon/cameraIcon';
import { HTMLCoverBlock, JwCoverBlock, Media } from './ArticleMainImage';
import { Jw } from '../media/Jw';
import { Iframe } from '../media/Iframe';
import { MMPlayer } from '../media/MMPlayer';
import { SponsorComponentDataProps } from '../Sponsor';
import { SponsorWithBackground } from '../SponsorWithBackground';
import { getStyleBySize } from '../../../typography/semanticTags/semanticTags.utils';
import { TranslatedFontSize } from '../../../theming/fontSizeTranslator';
import { MMPlayerBlock } from '../../components/pageLayouts/BlockTypes.utils';

const componentConfigurationKey = 'articleOnTopTitle';

interface ArticleOnTopTitleThemeProps {
  baseUnit: BaseUnit;
  creditColor: string;
  titleColor: string;
  titleWithMediaColor: string;
  shareIconColor: string;
  shareIconColorForMediaCover: string;
  overlayColor: string;
  titleFontSizeLarge: TypographySize;
  titleFontSizeSmall: TypographySize;
  creditFontSizeLarge: TypographySize;
  creditFontSizeSmall: TypographySize;
  numberOfLinesForTitle: number;
  fontSizesStyles: TranslatedFontSize;
}

interface ArticleOnTopTitleProps {
  title: string;
  image: Thumbnail;
  media: Media | null;
  siteName: string;
  templateName: string;
  canonical: string;
  shareConfig: SocialButton[];
  sponsor?: SponsorComponentDataProps | null;
}

const getBlockStyle = () => {
  return StyleSheet.create({
    style: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      margin: 0,
    },
  });
};

const getMedia = (media: Media, siteName: string, templateName: string) => {
  const additionalStyle = getBlockStyle() as AdditionalStyleSheet;
  const { type } = media;
  switch (type) {
    case 'jw': {
      const { html, videoId } = media as JwCoverBlock;
      return <Jw html={html} additionalStyle={additionalStyle} videoId={videoId} siteName={siteName} templateName={templateName} />;
    }

    case 'youtube': {
      const { html } = media as HTMLCoverBlock;
      return <Iframe html={html} additionalStyle={additionalStyle} />;
    }

    case 'mmPlayer': {
      const { html, version } = media as MMPlayerBlock;
      return <MMPlayer html={html} version={version} additionalStyle={additionalStyle} />;
    }
    default: return null;
  }
};

const imageWidths = createImageWidths(540, 720, 1440);

const getStyles = ({ baseUnit, creditColor, titleColor, titleWithMediaColor }: ArticleOnTopTitleThemeProps) => {
  return StyleSheet.create({
    relativeElement: {
      position: 'relative',
    },
    creditWrapper: {
      position: 'absolute',
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
      [MEDIA_BREAKPOINTS.large]: {
        bottom: `${baseUnit}px`,
        left: `${baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        bottom: `${baseUnit / 2}px`,
        left: `${baseUnit / 2}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        bottom: `${baseUnit / 4}px`,
        left: `${baseUnit / 4}px`,
      },
    },
    credit: {
      color: creditColor,
      marginLeft: `${baseUnit / 2}px`,
    },
    title: {
      color: titleColor,
      position: 'absolute',
      width: '100%',
      textAlign: 'center',
      boxSizing: 'border-box',
      [MEDIA_BREAKPOINTS.large]: {
        padding: `0 ${baseUnit * 2}px`,
        bottom: `${baseUnit * 4}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        padding: `0 ${baseUnit * 2}px`,
        bottom: `${baseUnit * 4}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        padding: `0 ${baseUnit}px`,
        bottom: `${baseUnit * 2.5}px`,
      },
    },
    titleWithMedia: {
      color: titleWithMediaColor,
      textAlign: 'center',
      [MEDIA_BREAKPOINTS.large]: {
        margin: `${baseUnit * 2}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        margin: `${baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        margin: `${baseUnit}px`,
      },
    },
    wrapperOfSponsorForMMMediaCover: {
      marginTop: '16px',
    },
  });
};

const getShareIconStyle = (baseUnit: BaseUnit): AdditionalStyleSheet => {
  return StyleSheet.create({
    style: {
      cursor: 'pointer',
      position: 'absolute',
      [MEDIA_BREAKPOINTS.large]: {
        bottom: `${baseUnit}px`,
        right: `${baseUnit}px`,
        height: '30px',
        maxWidth: '30px',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        bottom: `${baseUnit / 2}px`,
        right: `${baseUnit / 2}px`,
        height: '30px',
        maxWidth: '30px',
      },
      [MEDIA_BREAKPOINTS.small]: {
        bottom: '9px',
        right: `${baseUnit / 4}px`,
        height: '20px',
        maxWidth: '20px',
      },
    },
  }) as AdditionalStyleSheet;
};

export const ArticleOnTopTitle: React.FunctionComponent<ArticleOnTopTitleProps> = props => {
  const { image, title, shareConfig, canonical, media, siteName, templateName, sponsor } = props;
  const themeProps = useTheme<ArticleOnTopTitleThemeProps>(componentConfigurationKey);
  const { creditColor, fontSizesStyles } = themeProps;
  const { creditFontSizeLarge, creditFontSizeSmall, titleFontSizeLarge, titleFontSizeSmall, baseUnit, shareIconColor, shareIconColorForMediaCover, overlayColor, numberOfLinesForTitle } = themeProps;
  const hasCredit = typeof image.credit === 'string';
  const creditHTML = useTransformCreditHTML(image.credit);
  const styles = getStyles(themeProps);
  const shareIconStyle = getShareIconStyle(baseUnit);
  const { style } = StyleSheet.create(ellipsify(numberOfLinesForTitle));
  const creditFontsStyleBySize = getStyleBySize(fontSizesStyles, { fontSizeSmall: creditFontSizeSmall, fontSizeMedium: creditFontSizeLarge, fontSizeLarge: creditFontSizeLarge });

  const aspectRatios = {
    large: { x: 16, y: 9 },
    medium: { x: 16, y: 9 },
    small: media ? { x: 16, y: 9 } : { x: 4, y: 3 },
  };

  const getPaddingBottomForShareIconsWrapperWithMediaCover = () => {
    const addingBottomForShareIconsWrapperWithMediaCover = {
      large: 60,
      medium: 0,
      small: 45,
    };
    const addSponsorPadding = (basePadding: typeof addingBottomForShareIconsWrapperWithMediaCover) => ({
      large: basePadding.large + (4.25 * baseUnit),
      medium: basePadding.medium + (4.25 * baseUnit),
      small: basePadding.small + (2.25 * baseUnit),
    });
    return sponsor ? addSponsorPadding(addingBottomForShareIconsWrapperWithMediaCover) : addingBottomForShareIconsWrapperWithMediaCover;
  };

  return media ? (
    <header>
      <div className={css(styles.relativeElement)}>
        <Image {...image} imageWidths={imageWidths} imageAspectRatios={aspectRatios} />
        {getMedia(media, siteName, templateName)}
      </div>
      <div {...(media.type === 'mmPlayer' ? { className: css(styles.wrapperOfSponsorForMMMediaCover) } : {})}>
        {sponsor ? <SponsorWithBackground {...sponsor} /> : null}
      </div>
      <div className={css(styles.relativeElement)}>
        <H1 styles={semanticCSS(styles.titleWithMedia, style)} fontSizeLarge={titleFontSizeLarge} fontSizeMedium={titleFontSizeLarge} fontSizeSmall={titleFontSizeSmall}>{title}</H1>
        {shareConfig.length > 0 && <ArticleOnTopTitleShareButton canonical={canonical} shareConfig={shareConfig} shareIconColor={media ? shareIconColorForMediaCover : shareIconColor} shareIconStyle={shareIconStyle} paddingBottomForShareIconsWrapper={getPaddingBottomForShareIconsWrapperWithMediaCover()} />}
      </div>
    </header>
  ) : (
    <header>
      <div className={css(styles.relativeElement)}>
        <Image {...image} imageWidths={imageWidths} imageAspectRatios={aspectRatios} />
        <CardOverlay overlayColor={overlayColor} />
        <H1 styles={semanticCSS(styles.title, style)} fontSizeLarge={titleFontSizeLarge} fontSizeMedium={titleFontSizeLarge} fontSizeSmall={titleFontSizeSmall}>{title}</H1>
        {hasCredit && (
        <div className={css(styles.creditWrapper)}>
          <CameraIcon color={creditColor} />
          <figcaption className={css(creditFontsStyleBySize, styles.credit)} dangerouslySetInnerHTML={{ __html: creditHTML }} />
        </div>
        )}
        {shareConfig.length > 0 && <ArticleOnTopTitleShareButton canonical={canonical} shareConfig={shareConfig} shareIconColor={media ? shareIconColorForMediaCover : shareIconColor} shareIconStyle={shareIconStyle} />}
      </div>
      {sponsor ? <SponsorWithBackground {...sponsor} /> : null}
    </header>
  );
};
