import * as React from 'react';
import { css } from 'aphrodite/no-important';
import { Helmet } from 'react-helmet';
import { AdditionalStyleSheet } from '../../components.utils';

interface AMPJWPlayerProps {
  additionalStyle: AdditionalStyleSheet;
  playerId: string;
  videoId: string;
}

export const AMPJWPlayer: React.FunctionComponent<AMPJWPlayerProps> = props => {
  const { playerId, videoId, additionalStyle } = props;
  return (
    <React.Fragment>
      <Helmet>
        <script async={undefined} custom-element="amp-jwplayer" src="https://cdn.ampproject.org/v0/amp-jwplayer-0.1.js" />
      </Helmet>
      <figure className={css(additionalStyle.style)}>
        {/*
         // @ts-ignore TS2339 */}
        <amp-jwplayer width="16" height="9" layout="responsive" data-media-id={videoId} data-player-id={playerId} />
      </figure>
    </React.Fragment>
  );
};
