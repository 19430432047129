import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { MandatoryAdditionalStyle } from '../../components.utils';
import { useTheme } from '../../../theming/useTheme';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { ArticleBlockThemeProps, componentConfigurationKey } from '../ArticleBlock.theme';
import { Kasda } from '../../../Kasda/Kasda';

export interface JwDataProps {
  html: string;
  siteName: string;
  templateName: string;
  videoId: string;
  dataId?: string;
}

type JwProps = JwDataProps & MandatoryAdditionalStyle;

const createStyle = (backgroundColor: string) => StyleSheet.create({
  style: {
    backgroundColor,
  },
});

const StyleForMontiElement: React.FunctionComponent = () => {
  return (
    /* eslint-disable-next-line react/no-danger */
    <style dangerouslySetInnerHTML={{
      __html: `
  .monti-placeholder-mm-article-player {
    margin: 0px;
  }
` }}
    />
  );
};

export const Jw: React.FunctionComponent<JwProps> = props => {
  const { html, additionalStyle, siteName, templateName, videoId, dataId } = props;
  const { placeholderBackgroundColor } = useTheme<ArticleBlockThemeProps>(componentConfigurationKey);
  const JwComponentStyle = createStyle(placeholderBackgroundColor);
  const figureRef = React.useRef() as React.RefObject<HTMLDivElement>;

  React.useEffect(() => {
    const initMonti = () => {
      if (figureRef.current !== null && figureRef.current.firstElementChild) {
        const jwHtmlRef = figureRef.current.firstElementChild;
        // @ts-ignore TS2339
        window.monti().initiate({
          config_name: `Editor Embedded player in ${siteName}`,
          uniq_key: 'mm-article-player',
          publisher_contribution: siteName,
          content_id: videoId,
          content_type: 'curated',
          player_pos: jwHtmlRef,
          category_name: templateName,
          monetization: {
            vpaid_mode: 'ENABLED',
            strategy: 'on_player_load',
            ad_type: 'auto_dynamic_tag',
            ad_request_timeout: '10000',
            ad_break_slots: {
              on: [0],
            },
          },
          brand_color: '#EEEEEE',
          playback_method: 'none',
          powered_by_strip: false,
        });
      }
    };
    // @ts-ignore TS2339
    if (typeof window.monti !== 'undefined') {
      initMonti();
    } else {
      window.addEventListener('mplayerPlayerLoaded', initMonti);
    }
  }, [figureRef, siteName, templateName, videoId]);

  return (
    <ErrorBoundary>
      <Kasda.Script>
        <script async defer src="https://www.oo-syringe.com/prod/players/mplayer_wrapper_v3_latest.js" />
      </Kasda.Script>
      {/* eslint-disable-next-line react/no-danger */}
      <figure ref={figureRef} data-mm-id={dataId} className={css(JwComponentStyle.style, additionalStyle.style)} dangerouslySetInnerHTML={{ __html: html }} />
      <StyleForMontiElement />
    </ErrorBoundary>
  );
};
