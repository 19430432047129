import React from 'react';
import { useSelector } from 'react-redux';
import { ErrorBoundary, MetaHeaderTags } from 'mm-ui-components';
import { PageImage } from 'mm-ui-components/dist/src/base/metaHeaderTags/metaTags.utils';
import {
  getAmpURL,
  getCanonicalUrl,
  getFacebookAppId,
  getFacebookPageId,
  getFavicon,
  getLocale,
  getLogo,
  getPrevCanonicalUrl,
  getSiteName,
  getTwitterAccount,
} from '../../store/config/config.selectors';
import { Article, HreflangItem } from '../../store/template/template.state.types';

interface MetaTagsConnectedProps {
  articles?: Article[] | null;
  description: string | null;
  title: string;
  pageImage: PageImage | null;
  alternateUrls?: HreflangItem[];
  siTwitterAndOgTitle?: string;
  noIndex?: boolean;
  breadcrumbs?: {
    homeDisplayName: string;
    homeLink: string;
    categoryDisplayName: string;
    categoryLink: string;
    thirdDisplayName?: string;
    thirdLink?: string;
  } | null;
}

export const MetaTagsConnected: React.FunctionComponent<MetaTagsConnectedProps> = props => {
  const {
    articles = [],
    description,
    title,
    pageImage,
    alternateUrls = [],
    siTwitterAndOgTitle = '',
    noIndex,
    breadcrumbs,
  } = props;
  const facebookAppId = useSelector(getFacebookAppId);
  const facebookPageId = useSelector(getFacebookPageId);
  const siteName = useSelector(getSiteName);
  const canonicalUrl = useSelector(getCanonicalUrl);
  const favicon = useSelector(getFavicon);
  const twitterAccount = useSelector(getTwitterAccount);
  const logo = useSelector(getLogo);
  const ampURL = useSelector(getAmpURL);
  const prevCanonicalUrl = useSelector(getPrevCanonicalUrl);
  const locale = useSelector(getLocale);

  return (
    <MetaHeaderTags {...{
      facebookAppId,
      facebookPageId,
      description,
      siteName,
      canonicalUrl,
      favicon,
      title,
      twitterAccount,
      pageImage,
      logo,
      ampURL,
      articles,
      prevCanonicalUrl,
      locale,
      alternateUrls,
      siTwitterAndOgTitle,
      noIndex,
      breadcrumbs,
    }}
    />
  );
};

export const MetaTags: React.FunctionComponent<MetaTagsConnectedProps> = props => <ErrorBoundary><MetaTagsConnected {...props} /></ErrorBoundary>;
