import { Breadcrumb, MainCategory } from './MultiLayerBreadcrumbs';
import { MODAL_LINK_MAPPING, TAG } from './breadcrumbs.strings';

const formatDisplayName = (pathSegment: string) => {
  const dashToSpace = pathSegment?.replace(/-/g, ' ') || '';
  return dashToSpace
    .replace(/\bon fannation\b/gi, 'ON SI')
    .replace(/\bfannation\b/gi, 'ON SI');
};

// Note if using in post page need to fix this function
export const createBreadcrumbData = (
  canonicalURL: string,
  mainCategory?: MainCategory,
): Breadcrumb[] => {
  const baseLink = new URL(canonicalURL);
  const paths = baseLink.pathname.split('/').filter(Boolean);

  if (paths.length === 0) {
    return [];
  }

  const linkList: Breadcrumb[] = [
    { displayName: 'Home', path: baseLink.origin },
  ];
  // add children for wellness and culture
  if (paths.length === 1 && (paths.includes('wellness') || paths.includes('culture'))) {
    const children = MODAL_LINK_MAPPING[paths[0]];
    linkList.push({ displayName: formatDisplayName(paths[0]), path: canonicalURL, children });
    return linkList;
  }

  if (paths.includes('bleav') && (paths.includes('ncaaf') || paths.includes('mlb'))) {
    const index = paths.indexOf('ncaaf') !== -1 ? paths.indexOf('ncaaf') : paths.indexOf('mlb');
    // if category is last in path add dropdown
    if (paths.length - 1 === index) {
      const children = MODAL_LINK_MAPPING[paths[index]];
      const pathList = [
        {
          displayName: 'BLEAV ON SI',
          path: `${baseLink.origin}/fannation/bleav/`,
        },
        {
          displayName: formatDisplayName(paths[index]),
          path: canonicalURL,
          children,
        },
      ];

      linkList.push(...pathList);
    }
    // if category is not last just add link
    if (paths.length - 1 !== index) {
      const pathList = [
        {
          displayName: 'BLEAV ON SI',
          path: `${baseLink.origin}/fannation/bleav/`,
        },
        {
          displayName: formatDisplayName(paths[index]),
          path: `${baseLink.origin}/fannation/bleav/${paths[index]}/`,
        },
      ];

      linkList.push(...pathList);
    }
  }

  // have more than two
  if (paths.length >= 2 || !mainCategory) {
    if (paths[0] !== TAG) {
      const isOnSI = paths[0] === 'onsi';
      const pathname = isOnSI ? 'fannation' : paths[0];
      const displayName = isOnSI ? 'ON SI' : formatDisplayName(paths[0]);
      linkList.push({ displayName, path: `${baseLink.origin}/${pathname}` });
    }

    if (paths.length >= 2) {
      linkList.push({ displayName: formatDisplayName(paths[1]), path: `${baseLink.origin}/${paths[0]}/${paths[1]}` });
    }
  }

  if (mainCategory && mainCategory.isActive && mainCategory.displayName !== 'Wellness' && mainCategory.displayName !== 'Culture' && mainCategory.displayName !== 'NCAAF' && mainCategory.displayName !== 'MLB') {
    linkList.push({ displayName: mainCategory.displayName, path: mainCategory.link });
  }

  return linkList;
};
