import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { Image } from '../../../partials/image/Image';
import { ErrorBoundary } from '../../../errorHandling/ErrorBoundary';
import { createStyleForBoxShadow } from '../../../../theming/boxShadow';
import { MEDIA_BREAKPOINTS } from '../../../../mediaQueries.const';
import { Thumbnail } from '../../../partials/image/image.types';
import { AdditionalStyleSheet } from '../../../components.utils';
import { ImageWidth } from '../../../partials/image/image.utils';

export interface AdditionalImageStyles {
  style?: Record<string, any>;
}

interface AuthorImageDataProps {
  image: Thumbnail;
  imageWidths: { small: ImageWidth; medium: ImageWidth; large: ImageWidth };
  additionalStyles?: AdditionalImageStyles;
  boxShadow?: string;
  wrapperStyles?: { wrapper?: Record<string, any>};
  aspectRatio?: { x: number; y: number };
  widthSizes: {small: number; medium: number; large: number};
}

const getImageAdditionalStyle = (sizes: any) => {
  return StyleSheet.create({
    style: {
      borderRadius: '100%',
      display: 'block',
      overflow: 'hidden',
      [MEDIA_BREAKPOINTS.large]: {
        width: `${sizes.large}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        width: `${sizes.medium}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        width: `${sizes.small}px`,
      },
    },
  });
};

export const AuthorImage: React.FunctionComponent<AuthorImageDataProps> = props => {
  const {
    image,
    widthSizes,
    imageWidths,
    wrapperStyles = { wrapper: null },
    boxShadow,
    aspectRatio = { x: 1, y: 1 },
  } = props;

  const boxShadowStyle = boxShadow ? createStyleForBoxShadow({ boxShadowTypeNormal: boxShadow }) : { style: null };
  const additionalStyle = getImageAdditionalStyle(widthSizes);

  return (
    <ErrorBoundary>
      <div className={css(wrapperStyles?.wrapper, boxShadowStyle.style)}>
        <Image
          {...image}
          aspectRatio={aspectRatio}
          additionalStyle={additionalStyle as AdditionalStyleSheet}
          imageWidths={imageWidths}
        />
      </div>
    </ErrorBoundary>
  );
};
