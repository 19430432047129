import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { BottomFooterBar, BottomFooterBarDataProps } from './BottomFooterBar';
import { useTheme } from '../../../../theming/useTheme';
import { HUGE_SCREEN_SIZE, MEDIA_BREAKPOINTS } from '../../../../mediaQueries.const';
import { Logo, LogoDataProps } from '../header/header/Logo';
import { generateSeparatorSections } from './footer.utils';
import { FooterLinksSection, FooterLinksSectionBaseProps } from './FooterLinksSection';
import { ErrorBoundary } from '../../../errorHandling/ErrorBoundary';
import { componentConfigurationKey, FooterThemeProps } from './Footer.theme';
import { BaseUnit } from '../../../../theming/baseUnitDefinition';

interface FooterWithLogoProps {
  linksSection: FooterLinksSectionBaseProps | null;
  bottomFooterBar: BottomFooterBarDataProps;
  logo: LogoDataProps;
}

const createStyles = (backgroundColor: string, separatorColor: string, separatorHeight: number, baseUnit: BaseUnit) => {
  return StyleSheet.create({
    footerWrapper: {
      backgroundColor,
      [MEDIA_BREAKPOINTS.large]: {
        paddingTop: `${0.25 * baseUnit}px`,
        paddingBottom: `${0.25 * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        paddingTop: `${0.25 * baseUnit}px`,
        paddingBottom: `${0.25 * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        paddingTop: `${0.25 * baseUnit}px`,
        paddingBottom: `${0.25 * baseUnit}px`,
      },
    },
    footerContent: {
      [MEDIA_BREAKPOINTS.large]: {
        padding: `0 ${baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        padding: `0 ${baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        padding: `0 ${baseUnit * 0.75}px`,
      },
      margin: '0 auto',
      maxWidth: `${HUGE_SCREEN_SIZE}px`,
    },
    logoSeparator: {
      height: `${separatorHeight}px`,
      width: '100%',
      backgroundColor: separatorColor,
      ':first-child': {
        marginRight: `${baseUnit}px`,
      },
      ':last-child': {
        marginLeft: `${baseUnit}px`,
      },
    },
    logoWrapper: {
      paddingTop: `${baseUnit}px`,
      display: 'flex',
      alignItems: 'center',
    },
  });
};

const getSeparatorStyle = (separatorColor: string, separatorHeight: number) => {
  return StyleSheet.create({
    separator: {
      height: `${separatorHeight}px`,
      backgroundColor: separatorColor,
    },
  });
};

const logoBaseUnitHeight = 1.2;
const SEPARATOR_HEIGHT = 1;

const getLinksSection = (linksSection: FooterLinksSectionBaseProps | null) => {
  return linksSection ? <FooterLinksSection key="footer-links" {...linksSection as FooterLinksSectionBaseProps} /> : null;
};

const getFooterItems = (linksSection: FooterLinksSectionBaseProps | null, separatorColor: string, separatorHeight: number, bottomFooterBar: BottomFooterBarDataProps) => {
  const separatorStyle = getSeparatorStyle(separatorColor, separatorHeight).separator;
  return [
    getLinksSection(linksSection),
    <BottomFooterBar key="bottom-bar" {...bottomFooterBar} />,
  ]
    .filter(section => section !== null)
    .reduce(generateSeparatorSections(separatorStyle), []);
};

export const Footer: React.FunctionComponent<FooterWithLogoProps> = props => {
  const { linksSection, logo, bottomFooterBar } = props;
  const { backgroundColor, separatorColor, baseUnit } = useTheme<FooterThemeProps>(componentConfigurationKey);
  const styles = createStyles(backgroundColor, separatorColor, SEPARATOR_HEIGHT, baseUnit);

  return (
    <ErrorBoundary>
      <footer className={css(styles.footerWrapper)}>
        <div className={css(styles.footerContent)}>
          <div className={css(styles.logoWrapper)}>
            <div className={css(styles.logoSeparator)} />
            {
              logo && <Logo {...logo} logoHeightInSmallScreen={logoBaseUnitHeight} logoHeightInMediumScreen={logoBaseUnitHeight} logoHeightInLargeScreen={logoBaseUnitHeight} />
            }
            <div className={css(styles.logoSeparator)} />
          </div>
          {getFooterItems(linksSection, separatorColor, SEPARATOR_HEIGHT, bottomFooterBar)}
        </div>
      </footer>
    </ErrorBoundary>
  );
};
