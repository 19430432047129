import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { TypographySize } from 'mm-theme-configuration/dist/consts';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { VerticalCard } from '../../partials/cards/verticalCard/VerticalCard';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { useTheme } from '../../../theming/useTheme';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { createImageWidths } from '../../partials/image/image.utils';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { getLayoutComponentPadding, LayoutComponentBaseProps } from '../pageLayouts/layout.utils';
import { VerticalCardThemeProps, componentConfigThemeKey as verticalCardConfigThemeKey,
} from '../../partials/cards/verticalCard/VerticalCard.theme';

export const componentConfigThemeKey = 'threeVC';

export interface ThreeVCThemeProps {
    largeVerticalGap: number;
    mediumVerticalGap: number;
    smallHorizontalGap: number;
    innerHorizontalGapLarge: number;
    innerHorizontalGapMedium: number;
    innerHorizontalGapSmall: number;
    innerVerticalGapLarge: number;
    innerVerticalGapMedium: number;
    innerVerticalGapSmall: number;
    titleFontSizeLarge: TypographySize;
    titleFontSizeMedium: TypographySize;
    titleFontSizeSmall: TypographySize;
    metadataFontSizeLarge: TypographySize;
    metadataFontSizeMedium: TypographySize;
    metadataFontSizeSmall: TypographySize;
    baseUnit: BaseUnit;
}

interface ThreeVCDataProps extends LayoutComponentBaseProps{
    cards: Array<CardComponentDataProps>;
    addTextPropToVCTheme?: boolean;
    useFormatMinutesHoursAgo?: boolean;
}

const createStyle = (themeProps: ThreeVCThemeProps) => {
  const { largeVerticalGap, mediumVerticalGap, smallHorizontalGap, baseUnit } = themeProps;
  return StyleSheet.create({
    wrapper: {
      [MEDIA_BREAKPOINTS.large]: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gridTemplateRows: 'auto',
        gridColumnGap: `${largeVerticalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gridTemplateRows: 'auto',
        gridColumnGap: `${mediumVerticalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        display: 'grid',
        gridTemplateColumns: 'auto',
        gridTemplateRows: 'min-content',
        gridRowGap: `${smallHorizontalGap * baseUnit}px`,
      },
    },
  });
};

const imagesWidths = createImageWidths(360, 360, 360);

export const ThreeVC: React.FunctionComponent<ThreeVCDataProps> = props => {
  const { cards, paddingFactors, useFormatMinutesHoursAgo, addTextPropToVCTheme = true } = props;
  const paddingStyle = getLayoutComponentPadding(paddingFactors);
  const themeProps = useTheme<ThreeVCThemeProps>(componentConfigThemeKey);
  const {
    titleFontSizeLarge,
    titleFontSizeMedium,
    titleFontSizeSmall,
    innerHorizontalGapLarge,
    innerHorizontalGapMedium,
    innerHorizontalGapSmall,
    innerVerticalGapLarge,
    innerVerticalGapMedium,
    innerVerticalGapSmall,
    metadataFontSizeLarge,
    metadataFontSizeMedium,
    metadataFontSizeSmall,
  } = themeProps;
  const style = createStyle(themeProps);
  const vcThemeTextProps = {
    titleFontSizeLarge,
    titleFontSizeMedium,
    titleFontSizeSmall,
    metadataFontSizeLarge,
    metadataFontSizeMedium,
    metadataFontSizeSmall,
  };
  const themeContextVC = useThemeOverride<VerticalCardThemeProps>({
    ...(addTextPropToVCTheme ? vcThemeTextProps : {}),
    verticalGapLarge: innerVerticalGapLarge,
    verticalGapMedium: innerVerticalGapMedium,
    verticalGapSmall: innerVerticalGapSmall,
    horizontalGapLarge: innerHorizontalGapLarge,
    horizontalGapMedium: innerHorizontalGapMedium,
    horizontalGapSmall: innerHorizontalGapSmall,
  }, verticalCardConfigThemeKey);
  return (
    <ErrorBoundary>
      {cards && cards.length > 0
            && (
            <div className={css(paddingStyle, style.wrapper)}>
              <ThemeContext.Provider value={themeContextVC}>
                { cards.slice(0, 3).map((card, index) => {
                  return (
                    <React.Fragment key={index}>
                      {card && <VerticalCard {...card} imageWidths={imagesWidths} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />}
                    </React.Fragment>
                  );
                })}
              </ThemeContext.Provider>
            </div>
            ) }
    </ErrorBoundary>
  );
};
