import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { MEDIA_BREAKPOINTS } from '../../mediaQueries.const';
import { BaseUnit } from '../../theming/baseUnitDefinition';

interface QuoteIconProps {
  iconColor: string;
  baseUnit: BaseUnit;
}

const getStyles = (baseUnit: BaseUnit) => {
  return StyleSheet.create({
    icon: {
      margin: 'auto',
      display: 'block',
      [MEDIA_BREAKPOINTS.large]: {
        width: '24px',
        height: '24px',
        flex: `0 0 ${(24 + baseUnit / 4) * 2}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        width: '16px',
        height: '16px',
        flex: `0 0 ${(16 + baseUnit / 4) * 2}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        width: '16px',
        height: '16px',
        flex: `0 0 ${(16 + baseUnit / 4) * 2}px`,
      },
    },
  });
};

export const QuoteIcon: React.FunctionComponent<QuoteIconProps> = props => {
  const { iconColor, baseUnit } = props;
  const styles = getStyles(baseUnit);
  return (
    <svg className={css(styles.icon)} viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M9.0788,1 L16.00005,1 L16.00005,5.6195 C16.00005,8.41675 15.5258,10.498 14.5773,11.86525 C13.6283,13.2325 12.2058,14.27725 10.3088,15 L9.0498,12.313 C11.44405,11.52775 12.6413,9.90875 12.6413,7.458 L9.0788,7.458 L9.0788,1 Z M0.029,1 L6.95025,1 L6.95025,5.6195 C6.95025,8.41675 6.476,10.498 5.5275,11.86525 C4.5785,13.2325 3.156,14.27725 1.259,15 L-8.8817842e-15,12.313 C2.39425,11.52775 3.5915,9.90875 3.5915,7.458 L0.029,7.458 L0.029,1 Z" fill={iconColor} fillRule="nonzero" />
      </g>
    </svg>
  );
};
