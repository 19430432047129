import * as React from 'react';
import { css } from 'aphrodite/no-important';
import { Helmet } from 'react-helmet';
import { AdditionalStyleSheet } from '../../components.utils';

interface AMPIframeProps {
    src: string;
    additionalStyle: AdditionalStyleSheet;
    height: string;
}

export const AMPIframe: React.FunctionComponent<AMPIframeProps> = props => {
  const { src, additionalStyle, height } = props;
  return (
    <React.Fragment>
      <Helmet>
        <script async={undefined} custom-element="amp-iframe" src="https://cdn.ampproject.org/v0/amp-iframe-0.1.js" />
      </Helmet>
      <figure className={css(additionalStyle.style)}>
        {/*
         // @ts-ignore TS2339 */}
        <amp-iframe height={height} sandbox="allow-scripts allow-same-origin" layout="fixed-height" frameborder="0" src={src} />
      </figure>
    </React.Fragment>
  );
};
