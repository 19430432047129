import { StyleSheet } from 'aphrodite/no-important';
import { Colors } from 'mm-theme-configuration';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';

export const createStyles = (colors: Colors, displayShowMoreButton: boolean, showAllLinks: boolean, linksContainerHeightSmallScreen: number) => {
  const { primary, grey } = colors;
  return StyleSheet.create({
    placeholder: {
      backgroundColor: colors.white,
      padding: '30px 20px',
    },
    container: {
      borderTop: `4px solid ${grey}`,
      borderBottom: `4px solid ${grey}`,
      padding: '20px 10px',
      flexDirection: 'column',
      display: 'flex',
    },
    titleWrapper: {
      display: 'flex',
      flexDirection: 'row',
      gap: '10px',
      alignItems: 'center',
      marginBottom: '10px',
    },
    image: {
      width: '44px',
      height: '44px',
    },
    title: {
      color: primary,
      margin: '0px',
    },
    linksWrapper: {
      width: '100%',
      display: 'grid',
      gap: '10px',
      [MEDIA_BREAKPOINTS.large]: {
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        height: '100%',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        height: '100%',
      },
      [MEDIA_BREAKPOINTS.small]: {
        gridTemplateColumns: '1fr',
        height: showAllLinks || !displayShowMoreButton ? '100%' : `${linksContainerHeightSmallScreen}px`,
        overflow: 'hidden',
      },
    },
    linkText: {
      color: primary,
      margin: '0px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    button: {
      color: primary,
      margin: '0px',
      textDecorationLine: 'underline',
      [MEDIA_BREAKPOINTS.large]: {
        display: 'none',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        display: 'none',
      },
      [MEDIA_BREAKPOINTS.small]: {
        display: displayShowMoreButton ? 'flex' : 'none',
        marginTop: '10px',
      },
    },
  });
};
