import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { ErrorBoundary } from '../../../../errorHandling/ErrorBoundary';
import { useTheme } from '../../../../../theming/useTheme';
import { SocialLink, SocialSectionDataProps } from '../../footer/AdditionalFooterSections/FooterSocialLinks';
import { MEDIA_BREAKPOINTS } from '../../../../../mediaQueries.const';
import { LogoDataProps } from './Logo';
import { InjectedVideoLogoApiProps } from './VideoLogo';
import { componentConfigThemeKey, HeaderThemeProps } from '../Header.theme';
import { HeaderAnimation, HeaderAnimationContext } from '../HeaderAnimation';
import { AnimationDirection, AnimationDirectionType } from '../menuAnimations.util';
import { ScrollableNav } from './ScrollableNav';
import { NavigationLink } from '../oldHeader/primaryHeader/PrimaryHeader';
import { ScrollableNavDropdown } from './ScrollableNavDropdown';
import { EditionsIconDataProps } from './EditionsIcon';
import { MenuData, RowWithFixedNav } from './RowWithFixedNav';
import { useHeaderHeights } from '../useHeaderHeights';
import { CtaDataProps } from '../CtaButton';
import { reducedHeaderFirstRowHeightFactors, reducedHeaderSecondRowHeightFactors } from './header.utils';

interface SubHeader {
  logoForLargeScreen: LogoDataProps | null;
  logoForSmallScreen: LogoDataProps | null;
  links: NavigationLink[] | null;
  socialSection?: SocialLink[] | null | undefined;
  moreButtonText: string;
}

export interface HeaderWithSubMenusDataProps {
  links: NavigationLink[] | null;
  logo: LogoDataProps;
  secondaryLogo?: LogoDataProps | null;
  editions: NavigationLink[] | null;
  menu: MenuData | null;
  videoLogo?: InjectedVideoLogoApiProps;
  pagePath: string;
  editionEndpoint: string;
  oldSocialSection?: SocialSectionDataProps | null | undefined;
  socialSection?: SocialLink[] | null | undefined;
  moreButtonText: string;
  editionsIcon: EditionsIconDataProps | null;
  subHeader: SubHeader | null;
  cta: CtaDataProps | null;
  showAccessibilityIcon?: boolean;
}

const zIndexForFirstRow = 200;
const zIndexForSecondRow = 100;

const getHeaderStyles = () => {
  return StyleSheet.create({
    style: {
      position: 'fixed',
      width: '100%',
      zIndex: 1500,
      top: 0,
    },
  }).style;
};

const createHeaderStyleWithAnimation = (animationDuration: number) => {
  return StyleSheet.create({
    foldingUp: {
      [MEDIA_BREAKPOINTS.medium]: {
        transition: `${animationDuration}ms`,
        transform: 'translateY(-100%)',
        transitionDelay: `${animationDuration}ms`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        transition: `${animationDuration}ms`,
        transform: 'translateY(-100%)',
        transitionDelay: `${animationDuration}ms`,
      },
    },
    foldingDown: {
      [MEDIA_BREAKPOINTS.medium]: {
        transform: 'translateY(0)',
      },
      [MEDIA_BREAKPOINTS.small]: {
        transform: 'translateY(0)',
      },
    },
  });
};

const getHeaderAnimationStyle = (menuFoldState: AnimationDirectionType, animationDuration: number) => {
  const style = createHeaderStyleWithAnimation(animationDuration);
  return menuFoldState === AnimationDirection.UP ? style.foldingUp : style.foldingDown;
};

const getChildrenByIndex = (links: NavigationLink[] | null, index: number) => {
  if (!links || !links[index] || !links[index].children) return [];

  return links[index].children || [];
};

export const HeaderComponent: React.FunctionComponent<HeaderWithSubMenusDataProps> = props => {
  const { links, pagePath, subHeader, logo, secondaryLogo, moreButtonText, editionsIcon, editionEndpoint, editions, menu, oldSocialSection, socialSection, videoLogo, cta, showAccessibilityIcon } = props;
  const { menuFoldState, animationDuration } = React.useContext<HeaderAnimationContext>(HeaderAnimationContext);
  const themeProps = useTheme<HeaderThemeProps>(componentConfigThemeKey);
  const {
    secondRowLinkColorTypeActive,
    secondRowLinkColorTypeHover,
    secondRowLinkColorTypeNormal,
    secondRowLinkCurrentColor,
    baseUnit,
    firstRowLinkFontSizeLarge,
    firstRowLinkFontSizeMedium,
    firstRowLinkFontSizeSmall,
    secondRowLinkFontSizeLarge,
    secondRowLinkFontSizeMedium,
    secondRowLinkFontSizeSmall,
    verticalGapInLargeScreen,
    verticalGapInMediumScreen,
    verticalGapInSmallScreen,
    firstRowLinkDropdownIconColor,
    secondRowLinkDropdownIconColor,
    firstRowSubMenuBackgroundColor,
    secondRowSubMenuBackgroundColor,
    secondRowBackgroundColor,
    secondRowHeightInMediumScreen,
    secondRowHeightInSmallScreen,
    secondRowHeightInLargeScreen,
    firstRowSubMenuLinksFontSize,
    secondRowSubMenuLinksFontSize,
    firstRowLinkCurrentColor,
    firstRowLinkColorTypeNormal,
    firstRowLinkColorTypeHover,
    firstRowLinkColorTypeActive,
    firstRowLogoHeightInSmallScreen,
    firstRowLogoHeightInMediumScreen,
    firstRowLogoHeightInLargeScreen,
    firstRowHeightInSmallScreen,
    firstRowHeightInMediumScreen,
    firstRowHeightInLargeScreen,
    firstRowBackgroundColor,
    secondRowLogoHeightInSmallScreen,
    secondRowLogoHeightInMediumScreen,
    secondRowLogoHeightInLargeScreen,
    firstRowBoxShadow,
    secondRowBoxShadow,
    firstRowSocialLinksColor,
    firstRowSocialLinksHoverColor,
    secondRowSocialLinksColor,
    secondRowSocialLinksHoverColor,
  } = themeProps;

  const [scrollableMenuIndex, setScrollableMenuIndex] = React.useState(-1);
  const headerStyle = getHeaderStyles();

  const closeSubMenu = () => {
    setScrollableMenuIndex(-1);
  };

  React.useEffect(() => {
    window.addEventListener('scroll', closeSubMenu);

    return () => window.removeEventListener('scroll', closeSubMenu);
  });

  const scrollableLinks = subHeader ? subHeader.links : links;

  const onScrollableNavMenuToggle = (element: HTMLElement, index: number) => {
    if (!scrollableLinks) return;
    if (index !== scrollableMenuIndex) {
      setScrollableMenuIndex(index);
    } else {
      setScrollableMenuIndex(-1);
    }
  };

  return (
    <React.Fragment>
      <header className={css(getHeaderAnimationStyle(menuFoldState, animationDuration), headerStyle)}>
        <RowWithFixedNav {...{
          cta,
          logo,
          secondaryLogo,
          moreButtonText,
          editionsIcon,
          editionEndpoint,
          editions,
          menu,
          oldSocialSection,
          socialSection,
          videoLogo,
          links,
          pagePath,
          linkFontSizeLarge: firstRowLinkFontSizeLarge,
          linkFontSizeMedium: firstRowLinkFontSizeMedium,
          linkFontSizeSmall: firstRowLinkFontSizeSmall,
          subMenuLinksFontSize: firstRowSubMenuLinksFontSize,
          subMenuBackgroundColor: firstRowSubMenuBackgroundColor,
          linkDropdownIconColor: firstRowLinkDropdownIconColor,
          linkCurrentColor: firstRowLinkCurrentColor,
          linkColorTypeNormal: firstRowLinkColorTypeNormal,
          linkColorTypeHover: firstRowLinkColorTypeHover,
          linkColorTypeActive: firstRowLinkColorTypeActive,
          logoHeightInSmallScreen: firstRowLogoHeightInSmallScreen,
          logoHeightInMediumScreen: firstRowLogoHeightInMediumScreen,
          logoHeightInLargeScreen: firstRowLogoHeightInLargeScreen,
          rowHeightInSmallScreen: firstRowHeightInSmallScreen,
          rowHeightInMediumScreen: firstRowHeightInMediumScreen,
          rowHeightInLargeScreen: firstRowHeightInLargeScreen,
          rowBackgroundColor: firstRowBackgroundColor,
          rowBoxShadow: firstRowBoxShadow,
          socialLinksColor: firstRowSocialLinksColor,
          socialLinksHoverColor: firstRowSocialLinksHoverColor,
          hideInSmallAndMediumScreens: false,
          zIndex: zIndexForFirstRow,
          showAccessibilityIcon,
          reducedHeaderHeightFactors: reducedHeaderFirstRowHeightFactors,
        }}
        />
        {!secondaryLogo && subHeader ? (
          <RowWithFixedNav {...{
            cta: null,
            secondaryLogo: null,
            logo: subHeader.logoForLargeScreen,
            moreButtonText: subHeader.moreButtonText,
            editionsIcon: null,
            editionEndpoint,
            editions: null,
            menu: null,
            socialSection: subHeader.socialSection,
            links: subHeader.links,
            pagePath,
            linkFontSizeLarge: secondRowLinkFontSizeLarge,
            linkFontSizeMedium: secondRowLinkFontSizeMedium,
            linkFontSizeSmall: secondRowLinkFontSizeSmall,
            subMenuLinksFontSize: secondRowSubMenuLinksFontSize,
            subMenuBackgroundColor: secondRowSubMenuBackgroundColor,
            linkDropdownIconColor: secondRowLinkDropdownIconColor,
            linkCurrentColor: secondRowLinkCurrentColor,
            linkColorTypeNormal: secondRowLinkColorTypeNormal,
            linkColorTypeHover: secondRowLinkColorTypeHover,
            linkColorTypeActive: secondRowLinkColorTypeActive,
            logoHeightInSmallScreen: secondRowLogoHeightInSmallScreen,
            logoHeightInMediumScreen: secondRowLogoHeightInMediumScreen,
            logoHeightInLargeScreen: secondRowLogoHeightInLargeScreen,
            rowHeightInSmallScreen: secondRowHeightInSmallScreen,
            rowHeightInMediumScreen: secondRowHeightInMediumScreen,
            rowHeightInLargeScreen: secondRowHeightInLargeScreen,
            rowBackgroundColor: secondRowBackgroundColor,
            rowBoxShadow: secondRowBoxShadow,
            socialLinksColor: secondRowSocialLinksColor,
            socialLinksHoverColor: secondRowSocialLinksHoverColor,
            hideInSmallAndMediumScreens: true,
            zIndex: zIndexForSecondRow,
            showAccessibilityIcon: false,
            reducedHeaderHeightFactors: reducedHeaderSecondRowHeightFactors,
          }}
          />
        ) : null}
        {!secondaryLogo && subHeader && (
        <ScrollableNav
          menuFoldState={menuFoldState}
          animationDuration={animationDuration}
          links={scrollableLinks}
          linkColorTypeActive={secondRowLinkColorTypeActive}
          linkColorTypeHover={secondRowLinkColorTypeHover}
          linkColorTypeNormal={secondRowLinkColorTypeNormal}
          currentLinkColor={secondRowLinkCurrentColor}
          pagePath={pagePath}
          baseUnit={baseUnit}
          linkFontSizeLarge={secondRowLinkFontSizeLarge}
          linkFontSizeMedium={secondRowLinkFontSizeMedium}
          linkFontSizeSmall={secondRowLinkFontSizeSmall}
          verticalGapInLargeScreen={verticalGapInLargeScreen}
          verticalGapInMediumScreen={verticalGapInMediumScreen}
          verticalGapInSmallScreen={verticalGapInSmallScreen}
          secondRowBackgroundColor={secondRowBackgroundColor}
          secondRowHeightInMediumScreen={secondRowHeightInMediumScreen}
          secondRowHeightInSmallScreen={secondRowHeightInSmallScreen}
          linkDropdownIconColor={secondRowLinkDropdownIconColor}
          openLinkIndex={scrollableMenuIndex}
          onMenuToggle={onScrollableNavMenuToggle}
          boxShadow={secondRowBoxShadow}
          logo={subHeader ? subHeader.logoForSmallScreen : null}
        />
        )}
      </header>
      {
        scrollableMenuIndex >= 0 && (
          <ScrollableNavDropdown
            firstRowHeightInMediumScreen={firstRowHeightInMediumScreen}
            firstRowHeightInSmallScreen={firstRowHeightInSmallScreen}
            secondRowHeightInMediumScreen={secondRowHeightInMediumScreen}
            secondRowHeightInSmallScreen={secondRowHeightInSmallScreen}
            backgroundColor={secondRowSubMenuBackgroundColor}
            links={getChildrenByIndex(scrollableLinks, scrollableMenuIndex)}
            pagePath={pagePath}
            linkColorTypeActive={secondRowLinkColorTypeActive}
            linkColorTypeHover={secondRowLinkColorTypeHover}
            linkColorTypeNormal={secondRowLinkColorTypeNormal}
            currentLinkColor={secondRowLinkCurrentColor}
            linkFontSize={secondRowSubMenuLinksFontSize}
          />
        )
      }
    </React.Fragment>
  );
};

export const Header: React.FunctionComponent<HeaderWithSubMenusDataProps> = props => {
  const headerHeights = useHeaderHeights();
  return (
    <ErrorBoundary>
      <HeaderAnimation headerHeights={headerHeights}>
        <HeaderComponent {...props} />
      </HeaderAnimation>
    </ErrorBoundary>
  );
};
