import * as React from 'react';
import { css } from 'aphrodite/no-important';
import { Helmet } from 'react-helmet';
import { AMPMediaBaseProps } from './AMPMedia.utils';

export const AMPInstagram: React.FunctionComponent<AMPMediaBaseProps> = props => {
  const { mediaId, additionalStyle } = props;
  return (
    <React.Fragment>
      <Helmet>
        <script async={undefined} custom-element="amp-instagram" src="https://cdn.ampproject.org/v0/amp-instagram-0.1.js" />
      </Helmet>
      <figure className={css(additionalStyle.style)}>
        {/*
         // @ts-ignore TS2339 */}
        <amp-instagram data-shortcode={mediaId} width="1" height="1" layout="responsive" />
      </figure>
    </React.Fragment>
  );
};
