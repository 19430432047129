import { ThemeConfiguration } from 'mm-theme-configuration/dist/interfaces';
import { ConfigurationField } from 'mm-theme-configuration/src/interfaces';

export type Instructions = {
  [key: string]: Function;
};

function translatePropertyValueByType(instructions: Instructions, { value, type }: ConfigurationField) {
  if (typeof instructions === 'undefined') {
    throw new Error('must supply instructions to the transformation');
  }
  if (typeof instructions[type] !== 'function') {
    throw new Error(`there is no instruction function to type: ${type}`);
  }
  return instructions[type](value);
}

function translateProperty(instructions: Instructions, property: ConfigurationField) {
  return {
    [property.id]: translatePropertyValueByType(instructions, property),
  };
}

function translateComponent(instructions: Instructions, themeProperties: ConfigurationField[]) {
  return themeProperties.reduce((accumulator, componentProperty) => ({
    ...accumulator,
    ...translateProperty(instructions, componentProperty),
  }), {});
}

export function translate(instructions: Instructions, themeConfiguration: ThemeConfiguration[]): { [key: string]: any } {
  return themeConfiguration.reduce((accumulator, component) => (
    {
      ...accumulator,
      [component.id]: translateComponent(instructions, component.fields),
    }), {});
}
