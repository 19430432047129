import React from 'react';

export const getNumberIconOne = (color: string) => (
  <svg width="50" height="71" viewBox="0 0 50 71" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M27.0649 60.0767V13.8767L30.0749 17.1667H16.0049V11.0767H33.9949V60.0767H27.0649Z" fill={color} />
  </svg>
);

export const getNumberIconTwo = (color: string) => (
  <svg width="50" height="71" viewBox="0 0 50 71" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.19984 60.3569V55.5969L28.1498 36.3469C29.9232 34.6669 31.2298 33.1969 32.0698 31.9369C32.9565 30.6302 33.5398 29.4402 33.8198 28.3669C34.1465 27.2469 34.3098 26.1735 34.3098 25.1469C34.3098 22.6269 33.4232 20.6435 31.6498 19.1969C29.8765 17.7502 27.2865 17.0269 23.8798 17.0269C21.2665 17.0269 18.9098 17.4702 16.8098 18.3569C14.7098 19.1969 12.8898 20.5269 11.3498 22.3469L6.58984 18.2169C8.45651 15.8369 10.9532 14.0169 14.0798 12.7569C17.2532 11.4502 20.7298 10.7969 24.5098 10.7969C27.9165 10.7969 30.8798 11.3569 33.3998 12.4769C35.9198 13.5502 37.8565 15.1135 39.2098 17.1669C40.6098 19.2202 41.3098 21.6469 41.3098 24.4469C41.3098 26.0335 41.0998 27.5969 40.6798 29.1369C40.2598 30.6769 39.4665 32.3102 38.2998 34.0369C37.1332 35.7635 35.4532 37.7002 33.2598 39.8469L15.4798 56.9969L13.7998 54.2669H43.4098V60.3569H8.19984Z" fill={color} />
  </svg>
);

export const getNumberIconThree = (color: string) => (
  <svg width="50" height="71" viewBox="0 0 50 71" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.72 60.3569C21.2666 60.3569 17.9066 59.8202 14.64 58.7469C11.42 57.6735 8.78331 56.2269 6.72998 54.4069L9.94998 48.8769C11.5833 50.4169 13.7066 51.6769 16.32 52.6569C18.9333 53.6369 21.7333 54.1269 24.72 54.1269C28.36 54.1269 31.1833 53.3569 33.19 51.8169C35.2433 50.2302 36.27 48.1069 36.27 45.4469C36.27 42.8802 35.29 40.8269 33.33 39.2869C31.4166 37.7002 28.36 36.9069 24.16 36.9069H20.24V32.0069L34.8 14.0869L35.78 16.8869H8.96998V10.7969H41.31V15.5569L26.82 33.4069L23.18 31.2369H25.49C31.4166 31.2369 35.85 32.5669 38.79 35.2269C41.7766 37.8869 43.27 41.2702 43.27 45.3769C43.27 48.1302 42.5933 50.6502 41.24 52.9369C39.8866 55.2235 37.8333 57.0435 35.08 58.3969C32.3733 59.7035 28.92 60.3569 24.72 60.3569Z" fill={color} />
  </svg>
);

export const getNumberIconFour = (color: string) => (
  <svg width="50" height="71" viewBox="0 0 50 71" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.33496 48.0367V43.1367L28.395 11.0767H35.955L11.105 43.1367L7.53496 42.0167H46.665V48.0367H3.33496ZM30.565 60.0767V48.0367L30.775 42.0167V31.3767H37.355V60.0767H30.565Z" fill={color} />
  </svg>
);
