
type EventHandler = (params: any) => void;

declare global {
    interface Window {
      __mmClientApiSubscriptions__?: Map<string, any>;
    }
}

export const injectArticleBlockByIndexEvent = 'injectArticleBlockByIndexEvent' as const;
export const viewabilityArticleBlockEvent = 'viewabilityArticleBlockEvent' as const;
export const injectVideoLogoSuccessfully = 'injectVideoLogoSuccessfully' as const;

type EventName = typeof injectArticleBlockByIndexEvent | typeof viewabilityArticleBlockEvent | typeof injectVideoLogoSuccessfully;

export const setEventEmitter = (eventName: EventName, eventHandler: EventHandler) => {
  if (window.__mmClientApiSubscriptions__ && window.__mmClientApiSubscriptions__.get(eventName)) {
    window.__mmClientApiSubscriptions__.get(eventName).forEach((params: any) => {
      try {
        eventHandler(params);
      } catch (e) {
        console.error(`ClientAPI Error. The error is in callback for one of the following events: "${injectArticleBlockByIndexEvent}", "${viewabilityArticleBlockEvent}" or "${injectVideoLogoSuccessfully}". Please check the callback value.`, e);
      }
    });
  }
};
