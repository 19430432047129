import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { RATIO, TypographySize } from 'mm-theme-configuration/dist/consts';
import { useTheme } from '../../../theming/useTheme';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import {
  componentConfigThemeKey as onTopCardConfigThemeKey,
  OnTopCard,
  OnTopCardComponentThemeProps,
} from '../../partials/cards/onTopCard/OnTopCard';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { baseUnit } from '../../../theming/baseUnitDefinition';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import {
  createImageHeightCalculationMethods,
  createImageWidths,
  ImageHeightCalculationMethods,
} from '../../partials/image/image.utils';
import { LayoutComponentBaseProps, getLayoutComponentPadding } from '../pageLayouts/layout.utils';

export const componentConfigThemeKey = 'twoOneTwoArticleList';

export interface TwoOneTwoArticleListThemeProps {
  largeVerticalGap: number;
  largeHorizontalGap: number;
  mediumVerticalGap: number;
  mediumHorizontalGap: number;
  smallHorizontalGap: number;
  minorCardsRatioLarge: RATIO;
  minorCardsRatioMedium: RATIO;
  aspectRatioMainImage: RATIO;
  aspectRatioMinorImage: RATIO;
  titleFontSizeMainCard: TypographySize;
  titleFontSizeMinorCard: TypographySize;
}

interface TwoOneTwoArticleListDataComponent extends LayoutComponentBaseProps {
  cards: Array<CardComponentDataProps>;
  useFormatMinutesHoursAgo?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const createStyle = ({ largeHorizontalGap, largeVerticalGap, mediumHorizontalGap, mediumVerticalGap, smallHorizontalGap, minorCardsRatioLarge, minorCardsRatioMedium }: TwoOneTwoArticleListThemeProps) => StyleSheet.create({
  wrapper: {
    display: 'grid',
    [MEDIA_BREAKPOINTS.large]: {
      gridTemplateColumns: '1fr 2fr 1fr',
      gridTemplateRows: `${minorCardsRatioLarge.x}fr ${minorCardsRatioLarge.y}fr`,
      gridColumnGap: `${largeVerticalGap * baseUnit}px`,
      gridRowGap: `${largeHorizontalGap * baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      gridTemplateColumns: `${minorCardsRatioMedium.x}fr ${minorCardsRatioMedium.y}fr`,
      gridTemplateRows: 'auto auto auto',
      gridColumnGap: `${mediumVerticalGap * baseUnit}px`,
      gridRowGap: `${mediumHorizontalGap * baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      gridTemplateColumns: '1fr',
      gridRowGap: `${smallHorizontalGap * baseUnit}px`,
    },
  },
  secondCard: {
    [MEDIA_BREAKPOINTS.large]: {
      gridRowStart: '2',
      gridRowEnd: '3',
      gridColumnStart: '1',
    },
    [MEDIA_BREAKPOINTS.small]: {
      gridRowStart: 'unset',
      gridRowEnd: 'unset',
      gridColumnStart: 'unset',
    },
  },
  mainCard: {
    [MEDIA_BREAKPOINTS.large]: {
      gridRowStart: '1',
      gridRowEnd: '3',
      gridColumnStart: '2',
    },
    [MEDIA_BREAKPOINTS.medium]: {
      gridRowStart: '2',
      gridRowEnd: '3',
      gridColumnStart: '1',
      gridColumnEnd: '3',
    },
    [MEDIA_BREAKPOINTS.small]: {
      order: '-1',
      gridRowStart: 'unset',
      gridRowEnd: 'unset',
      gridColumnStart: 'unset',
      gridColumnEnd: 'unset',
    },
  },
  fourthCard: {
    [MEDIA_BREAKPOINTS.medium]: {
      gridRowStart: '3',
      gridRowEnd: '4',
      gridColumnStart: '1',
      gridColumnEnd: '2',
    },
    [MEDIA_BREAKPOINTS.small]: {
      gridRowStart: 'unset',
      gridRowEnd: 'unset',
      gridColumnStart: 'unset',
      gridColumnEnd: 'unset',
    },
  },
  fifthCard: {
    [MEDIA_BREAKPOINTS.large]: {
      gridColumnStart: '3',
    },
    [MEDIA_BREAKPOINTS.medium]: {
      gridRowStart: '3',
      gridRowEnd: '4',
      gridColumnStart: '2',
      gridColumnEnd: '3',
    },
    [MEDIA_BREAKPOINTS.small]: {
      gridRowStart: 'unset',
      gridRowEnd: 'unset',
      gridColumnStart: 'unset',
      gridColumnEnd: 'unset',
    },
  },
});

const addImageHeightCalculationMethodsProp = (cardProps: CardComponentDataProps, imageHeightCalculationMethods: ImageHeightCalculationMethods) => ({
  ...cardProps,
  imageHeightCalculationMethods,
});

const imageWidthsMainCard = createImageWidths(360, 720, 540);
const imageWidthsMinorCard = createImageWidths(360, 360, 360);

export const TwoOneTwoArticleList: React.FunctionComponent<TwoOneTwoArticleListDataComponent> = props => {
  const { cards, paddingFactors, useFormatMinutesHoursAgo = false } = props;
  const themeProps = useTheme<TwoOneTwoArticleListThemeProps>(componentConfigThemeKey);
  const firstCard = cards && addImageHeightCalculationMethodsProp(cards[1], createImageHeightCalculationMethods('ASPECT_RATIO', 'ASPECT_RATIO', '100%_HEIGHT'));
  const secondCard = cards && addImageHeightCalculationMethodsProp(cards[2], createImageHeightCalculationMethods('ASPECT_RATIO', '100%_HEIGHT', '100%_HEIGHT'));
  const mainCard = cards && addImageHeightCalculationMethodsProp(cards[0], createImageHeightCalculationMethods('ASPECT_RATIO', 'ASPECT_RATIO', 'ASPECT_RATIO'));
  const fourthCard = cards && addImageHeightCalculationMethodsProp(cards[3], createImageHeightCalculationMethods('ASPECT_RATIO', 'ASPECT_RATIO', '100%_HEIGHT'));
  const fifthCard = cards && addImageHeightCalculationMethodsProp(cards[4], createImageHeightCalculationMethods('ASPECT_RATIO', '100%_HEIGHT', '100%_HEIGHT'));
  const style = createStyle(themeProps);
  const paddingStyle = getLayoutComponentPadding(paddingFactors);
  const themeContextMainCard = useThemeOverride<OnTopCardComponentThemeProps>({
    titleFontSizeMedium: themeProps.titleFontSizeMainCard,
    titleFontSizeLarge: themeProps.titleFontSizeMainCard,
    titleFontSizeSmall: themeProps.titleFontSizeMainCard,
    horizontalGapSmall: 0.25,
    horizontalGapMedium: 0.5,
    horizontalGapLarge: 0.5,
    aspectRatioSmall: themeProps.aspectRatioMainImage,
    aspectRatioMedium: themeProps.aspectRatioMainImage,
    aspectRatioLarge: themeProps.aspectRatioMainImage,
  }, onTopCardConfigThemeKey);
  const themeContextMinorCards = useThemeOverride<OnTopCardComponentThemeProps>({
    titleFontSizeMedium: themeProps.titleFontSizeMinorCard,
    titleFontSizeLarge: themeProps.titleFontSizeMinorCard,
    titleFontSizeSmall: themeProps.titleFontSizeMinorCard,
    horizontalGapSmall: 0.25,
    horizontalGapMedium: 0.25,
    horizontalGapLarge: 0.25,
    aspectRatioSmall: themeProps.aspectRatioMinorImage,
    aspectRatioMedium: themeProps.aspectRatioMinorImage,
    aspectRatioLarge: themeProps.aspectRatioMinorImage,
  }, onTopCardConfigThemeKey);
  return (
    <ErrorBoundary>
      {cards && cards.length > 0
      && (
      <div className={css(paddingStyle, style.wrapper)}>
        <ThemeContext.Provider value={themeContextMinorCards}>
          <OnTopCard {...firstCard} imageWidths={imageWidthsMinorCard} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />
          <div className={css(style.secondCard)}>
            <OnTopCard {...secondCard} imageWidths={imageWidthsMinorCard} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />
          </div>
        </ThemeContext.Provider>
        <ThemeContext.Provider value={themeContextMainCard}>
          <div className={css(style.mainCard)}>
            <OnTopCard {...mainCard} imageWidths={imageWidthsMainCard} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />
          </div>
        </ThemeContext.Provider>
        <ThemeContext.Provider value={themeContextMinorCards}>
          <div className={css(style.fourthCard)}>
            <OnTopCard {...fourthCard} imageWidths={imageWidthsMinorCard} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />
          </div>
          <div className={css(style.fifthCard)}>
            <OnTopCard {...fifthCard} imageWidths={imageWidthsMinorCard} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />
          </div>
        </ThemeContext.Provider>
      </div>
      )}
    </ErrorBoundary>
  );
};
