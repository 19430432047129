import * as React from 'react';
import { SlideshowBottomBarAmp } from './SlideshowBottomBarAmp';
import { SlideshowTopBarAmp, SlideshowTopBarAmpProps } from './SlideshowTopBarAmp';

type SlideshowAmpProps = SlideshowTopBarAmpProps;

export const SlideshowAmp: React.FunctionComponent<SlideshowAmpProps> = ({ children, ...topBarProps }) => {
  const { nextLink, nextText, totalSlides } = topBarProps;

  if (totalSlides === 0) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return (
    <React.Fragment>
      <SlideshowTopBarAmp {...topBarProps} />
      {children}
      <SlideshowBottomBarAmp nextLink={nextLink} nextText={nextText} />
    </React.Fragment>
  );
};
