import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { SocialSectionDataProps } from '../../../footer/AdditionalFooterSections/FooterSocialLinks';
import { H4, semanticCSS } from '../../../../../../typography/semanticTags';
import { Link } from '../../../../../partials/link/Link';
import { useTheme } from '../../../../../../theming/useTheme';
import { BaseUnit } from '../../../../../../theming/baseUnitDefinition';
import { componentConfigThemeKey, HeaderThemeProps } from '../../Header.theme';

interface MenuSocialLinksProps {
  oldSocialSection: SocialSectionDataProps;
  socialSection?: SocialSectionDataProps;
  showSocialTitle: boolean;
}

const getStyles = (titleColor: string, baseUnit: BaseUnit, socialLinksColor: string, socialLinksHoverColor: string) => StyleSheet.create({
  wrapper: {
    padding: `${baseUnit / 2}px ${baseUnit}px`,
  },
  title: {
    color: titleColor,
    marginBottom: `${baseUnit}px`,
  },
  ul: {
    listStyle: 'none',
    display: 'inline-grid',
    gridAutoFlow: 'column',
    gridGap: `${baseUnit}px`,
  },
  svg: {
    height: '16px',
    fill: socialLinksColor,
    ':hover': {
      fill: socialLinksHoverColor,
    },
  },
});

export const MenuSocialLinks: React.FunctionComponent<MenuSocialLinksProps> = props => {
  const { socialSection, oldSocialSection, showSocialTitle } = props;
  const { hamburgerMenuSocialLinksTitleColor, baseUnit, hamburgerMenuSocialLinksColor, hamburgerMenuSocialLinksHoverColor, hamburgerMenuSocialLinksTitleFontSizeLarge, hamburgerMenuSocialLinksTitleFontSizeMedium, hamburgerMenuSocialLinksTitleFontSizeSmall } = useTheme<HeaderThemeProps>(componentConfigThemeKey);

  const styles = getStyles(hamburgerMenuSocialLinksTitleColor, baseUnit, hamburgerMenuSocialLinksColor, hamburgerMenuSocialLinksHoverColor);
  return socialSection
    ? (
      <div className={css(styles.wrapper)}>
        {showSocialTitle
          && <H4 styles={semanticCSS(styles.title)} fontSizeLarge={hamburgerMenuSocialLinksTitleFontSizeLarge} fontSizeMedium={hamburgerMenuSocialLinksTitleFontSizeMedium} fontSizeSmall={hamburgerMenuSocialLinksTitleFontSizeSmall}>{socialSection.title}</H4>}
        <ul className={css(styles.ul)}>
          {
                socialSection.imageLinks.map((link, index) => {
                  const svg = link.src.replace(/<svg/g, `<svg class="${css(styles.svg)}"`);
                  return (
                    <li key={index}>
                      <Link href={link.href} rel="noopener" dangerouslySetInnerHTML={{ __html: svg }} alt={link.alt} />
                    </li>
                  );
                })
            }
        </ul>
      </div>
    )
    : (
      <div className={css(styles.wrapper)}>
        <H4 styles={semanticCSS(styles.title)}>{oldSocialSection.title}</H4>
        <ul className={css(styles.ul)}>
          {oldSocialSection.imageLinks.map((link, index) => <li key={index}><Link href={link.href}><img alt={link.alt} src={link.src} height="16" /></Link></li>)}
        </ul>
      </div>
    );
};
