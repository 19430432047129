import { Store } from 'redux';
import { AppActions, AppState } from '../../store/initialStateRegistration';
import {
  injectArticleBlockByIndexArray,
} from '../../store/template/postPage/postPage.actions';
import { EventHandler } from './eventEmitters';
import {
  GET_PAGE_DATA,
  GET_PALETTE_DATA,
  INJECT_ACCESSIBILITY_ICON_TO_HEADER,
  INJECT_ADS,
  INJECT_ARTICLE_BLOCK_BY_INDEX_ARRAY,
  INJECT_BUTTON_TO_FOOTER_LINKS_SECTION,
  INJECT_BUTTON_TO_MENU,
  INJECT_VIDEO_LOGO,
} from './events';
import {
  injectAccessibilityIcon,
  injectButtonToFooterLinksSection,
  injectButtonToMenu,
  injectVideoLogo,
  InjectVideoLogoParams,
} from '../../store/navigations/navigation.actions';
import { InjectButtonParams } from '../../store/navigations/navigation.utils';
import { getPageDataProtectedEventHandler } from './readEventHandlers/getPageData';
import { getPaletteDataProtectedEventHandler } from './readEventHandlers/getPaletteData';
import { AdSlots, injectAds } from '../../store/config/config.actions';
import { InjectArticleBlockByIndexArrayParams } from '../../store/template/postPage/types';

export type ClientAPIWriteEventNames =
  typeof INJECT_ARTICLE_BLOCK_BY_INDEX_ARRAY
  | typeof INJECT_VIDEO_LOGO
  | typeof INJECT_BUTTON_TO_FOOTER_LINKS_SECTION
  | typeof INJECT_BUTTON_TO_MENU
  | typeof INJECT_ACCESSIBILITY_ICON_TO_HEADER
  | typeof INJECT_ADS;
export type ClientAPIReadEventNames = typeof GET_PAGE_DATA | typeof GET_PALETTE_DATA;

type HandlerCreator = (store: Store<AppState, AppActions>) => EventHandler;
type HandlerCreatorsForWriteEvents = { [key in ClientAPIWriteEventNames]: HandlerCreator }
type HandlerCreatorsForReadEvents = { [key in ClientAPIReadEventNames]: HandlerCreator }

export const handlerCreatorsForWriteEvents: HandlerCreatorsForWriteEvents = {
  [INJECT_ARTICLE_BLOCK_BY_INDEX_ARRAY]: (store: Store<AppState, AppActions>) => (params: InjectArticleBlockByIndexArrayParams) => store.dispatch(injectArticleBlockByIndexArray(params)),
  [INJECT_VIDEO_LOGO]: (store: Store<AppState, AppActions>) => (params: InjectVideoLogoParams) => store.dispatch(injectVideoLogo(params)),
  [INJECT_BUTTON_TO_FOOTER_LINKS_SECTION]: (store: Store<AppState, AppActions>) => (params: InjectButtonParams) => store.dispatch(injectButtonToFooterLinksSection(params)),
  [INJECT_BUTTON_TO_MENU]: (store: Store<AppState, AppActions>) => (params: InjectButtonParams) => store.dispatch(injectButtonToMenu(params)),
  [INJECT_ACCESSIBILITY_ICON_TO_HEADER]: (store: Store<AppState, AppActions>) => () => store.dispatch(injectAccessibilityIcon()),
  [INJECT_ADS]: (store: Store<AppState, AppActions>) => (params: AdSlots) => store.dispatch(injectAds(params)),
};

export const handlerCreatorsForReadEvents: HandlerCreatorsForReadEvents = {
  [GET_PAGE_DATA]: getPageDataProtectedEventHandler,
  [GET_PALETTE_DATA]: getPaletteDataProtectedEventHandler,
};
