import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { BlocksPaddingOverride, BlockWidths } from '../../components/pageLayouts/BlockTypes.utils';
import { PaddingOverride } from '../blockMutualStyle';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { baseUnit } from '../../../theming/baseUnitDefinition';
import { AdditionalStyleSheet, Style } from '../../components.utils';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { RawHtmlContent } from '../RawHtmlContent';

const createBlockStyleForImageCover = (defaultBlocksWidths: BlockWidths, paddingOverride: PaddingOverride = { largePadding: 1, mediumPadding: 1, smallPadding: 1 }) => {
  const { largeWidth, mediumWidth, smallWidth } = defaultBlocksWidths;
  const { largePadding, mediumPadding, smallPadding } = paddingOverride;
  return {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '100%',
    boxSizing: 'border-box',
    [MEDIA_BREAKPOINTS.large]: {
      width: `${largeWidth}px`,
      marginRight: `${baseUnit * largePadding}px`,
      marginLeft: `${baseUnit * largePadding}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      width: `${mediumWidth}px`,
      marginRight: `${baseUnit * mediumPadding}px`,
      marginLeft: `${baseUnit * mediumPadding}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      width: `${smallWidth}px`,
      marginRight: `${baseUnit * smallPadding}px`,
      marginLeft: `${baseUnit * smallPadding}px`,
    },
  } as Style;
};

const getCoverStyles = (defaultBlocksWidths: BlockWidths, paddingOverride: PaddingOverride = { largePadding: 1, mediumPadding: 1, smallPadding: 1 }) => {
  const { largePadding, mediumPadding, smallPadding } = paddingOverride;

  return StyleSheet.create({
    wrapper: {
      position: 'relative',
      ...createBlockStyleForImageCover(defaultBlocksWidths),
      [MEDIA_BREAKPOINTS.large]: {
        marginRight: `${baseUnit * largePadding}px`,
        marginLeft: `${baseUnit * largePadding}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        marginRight: `${baseUnit * mediumPadding}px`,
        marginLeft: `${baseUnit * mediumPadding}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        marginRight: `${baseUnit * smallPadding}px`,
        marginLeft: `${baseUnit * smallPadding}px`,
      },
    },
  });
};

const getMainImageStyle = () => {
  return {
    marginTop: `${baseUnit}px`,
    marginBottom: `${baseUnit * 0.25}px`,
  };
};

const createImageAdditionalStyle = (style: Style) => {
  return StyleSheet.create({
    style: {
      ...style,
    },
  }) as AdditionalStyleSheet;
};

interface RawHtmlArticleMainImageProps {
  rawHtml: string;
  defaultBlocksWidths: BlockWidths;
  paddingOverride?: BlocksPaddingOverride;
}

export const RawHtmlArticleMainImage: React.FunctionComponent<RawHtmlArticleMainImageProps> = props => {
  const { rawHtml, defaultBlocksWidths, paddingOverride } = props;
  const coverStyles = getCoverStyles(defaultBlocksWidths, paddingOverride);
  const mainImageStyle = getMainImageStyle();
  const additionalStyle = createImageAdditionalStyle(mainImageStyle);

  return (
    <ErrorBoundary>
      {rawHtml
          && (
          <div className={css(coverStyles.wrapper)}>
            <RawHtmlContent html={rawHtml} defaultBlocksWidths={defaultBlocksWidths} additionalStyle={additionalStyle} blocksPaddingOverride={{ largePadding: 0, mediumPadding: 0, smallPadding: 0 }} />
          </div>
          )}
    </ErrorBoundary>
  );
};
