import { MouseEventHandler } from 'react';
import { StyleSheet } from 'aphrodite/no-important';
import { RATIO } from 'mm-theme-configuration/dist/consts';
import { Thumbnail } from '../image/image.types';
import { ImageHeightCalculationMethods, ImageWidths } from '../image/image.utils';

export interface Author {
    name: string;
    link?: string;
}

export interface MainCategoryData {
    displayName: string;
    link: string;
    isActive: boolean;
}

export interface CardComponentDataProps {
    id: string | number;
    title: string;
    articleUrl: string;
    image: Thumbnail;
    authors: Author[];
    mainCategory: MainCategoryData | null;
    featuredAt: string;
    coverType?: string;
    showDecorationLine?: boolean;
    numberOfLines?: number;
    lazyLoad?: boolean;
    description?: string;
    createdAt?: string;
    createdAtISO?: string;
    updatedAt?: string;
    updatedAtISO?: string;
    onClick?: MouseEventHandler;
    ampAnalyticsAction?: string;
    ampAnalyticsLabel?: string;
    ampAnalyticsCategory?: string;
    useFormatMinutesHoursAgo?: boolean;
}

export interface CardComponentSponsoredProps {
    sponsoredText?: string;
    brandName?: string;
    logo?: string;
}

export interface CardComponentPropsFromLayout {
    imageHeightCalculationMethods?: ImageHeightCalculationMethods;
    imageWidths: ImageWidths;
}

export const listAuthors = (authors: Array<Author>) => {
  return authors.map(author => author.name).join(', ');
};
export const isRatioIs4On5 = (aspectRatio: RATIO) => aspectRatio.x === 4 && aspectRatio.y === 5;
export const showMetadataInAnyScreenSize = (showAuthor: boolean, showDate: boolean) => showAuthor || showDate;

export const showAuthorInAnyScreenSize = (authors: Array<Author>, showAuthorInLargeScreen: boolean, showAuthorInMediumScreen: boolean, showAuthorInSmallScreen: boolean) => authors && authors.length > 0 && (showAuthorInLargeScreen || showAuthorInMediumScreen || showAuthorInSmallScreen);

export const showDateInAnyScreenSize = (showDateInLargeScreen: boolean, showDateInMediumScreen: boolean, showDateInSmallScreen: boolean) => showDateInLargeScreen || showDateInMediumScreen || showDateInSmallScreen;

export const showDescriptionInAnyScreenSize = (showDescriptionInLargeScreen: boolean, showDescriptionInMediumScreen: boolean, showDescriptionInSmallScreen: boolean) => showDescriptionInLargeScreen || showDescriptionInMediumScreen || showDescriptionInSmallScreen;

export const showPipeInAnyScreenSize = (showAuthor: boolean, showDate: boolean) => showAuthor && showDate;

export const showMetadataInLargeScreenSize = (showAuthorInLargeScreen: boolean, showDateInLargeScreen: boolean) => showAuthorInLargeScreen || showDateInLargeScreen;

export const showMetadataInMediumScreenSize = (showAuthorInMediumScreen: boolean, showDateInMediumScreen: boolean) => showAuthorInMediumScreen || showDateInMediumScreen;

export const showMetadataInSmallScreenSize = (showAuthorInSmallScreen: boolean, showDateInSmallScreen: boolean) => showAuthorInSmallScreen || showDateInSmallScreen;

export const shouldShowSponsored = (...params: (string | undefined)[]) => params.every(str => typeof str === 'string' && str.trim() !== '');

export const clickableLabelsStyles = () => StyleSheet.create({
  articleLink: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 100,
  },
  labelsLink: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 200,
  },
  categoryLink: {
    color: 'inherit',
  },
});
