import * as React from 'react';

export type Threshold = 0 | 0.1 | 0.5 | 1
export interface ViewablityHandlerSubscription {
  threshold: Threshold;
  handler: ViewablityHandler;
}
export type ViewablityHandler = (element: Element) => void
export type ViewabilitySubscriptions = WeakMap<Element, ViewablityHandlerSubscription[]>
interface ViewabilityHandlerContextProps {
  intersectionObserver?: IntersectionObserver;
  viewabilityHandlers?: ViewabilitySubscriptions;
}

function getIntersectionThresholds() {
  const thresholds: number[] = [];
  for (let i = 0; i <= 1.0; i += 0.01) {
    thresholds.push(i);
  }
  return thresholds;
}

const options = {
  root: null,
  threshold: getIntersectionThresholds(),
};


function isIntersectionAtBottomOfElementBiggerThanViewPort(subscriptionThreshold: Threshold, entry: IntersectionObserverEntry) {
  return subscriptionThreshold === 1 && entry.boundingClientRect.bottom === entry.intersectionRect.bottom;
}

function isIntersectingAboveThreshold(subscriptionThreshold: Threshold, entry: IntersectionObserverEntry) {
  return subscriptionThreshold < entry.intersectionRatio;
}

const observerCallback = (subscriptions: ViewabilitySubscriptions) => (e: IntersectionObserverEntry[], observer: IntersectionObserver) => {
  e.forEach(entry => {
    if (entry.isIntersecting) {
      const elementSubscriptions = subscriptions.get(entry.target) || [];
      elementSubscriptions.filter(sub => sub && (isIntersectingAboveThreshold(sub.threshold, entry)
        || (isIntersectionAtBottomOfElementBiggerThanViewPort(sub.threshold, entry))))
        .forEach(sub => {
          observer.unobserve(entry.target);
          sub.handler(entry.target);
          delete elementSubscriptions[elementSubscriptions.findIndex(item => item === sub)];
        });
    }
  });
};

const ViewabilityHandlerContext = React.createContext<ViewabilityHandlerContextProps>({});

export const useViewabilityHandler = (handler: ViewablityHandler, threshold: Threshold): React.RefObject<HTMLDivElement> => {
  const ref = React.useRef(null);
  const context = React.useContext(ViewabilityHandlerContext);
  const { intersectionObserver, viewabilityHandlers } = context;

  React.useEffect(() => {
    if (!ref.current || !intersectionObserver || !viewabilityHandlers) return;
    const element = ref.current as unknown as HTMLElement;

    const subscription = { handler, threshold };
    viewabilityHandlers.set(element, [...(viewabilityHandlers.get(element) || []), subscription]);
    element.classList.add('viewablity-block');
    intersectionObserver.observe(element);
  }, [handler, intersectionObserver, threshold, viewabilityHandlers]);

  return ref as unknown as React.RefObject<HTMLDivElement>;
};

export const ViewabilityProvider: React.FunctionComponent = ({ children }) => {
  const { intersectionObserver, viewabilityHandlers } = React.useContext(ViewabilityHandlerContext);

  const context = React.useMemo(() => {
    const newViewabilityHandlers = viewabilityHandlers || new WeakMap<Element, ViewablityHandlerSubscription[]>();
    return {
      viewabilityHandlers: newViewabilityHandlers,
      intersectionObserver: (typeof IntersectionObserver !== 'undefined' && (intersectionObserver || new IntersectionObserver(observerCallback(newViewabilityHandlers), options))) || undefined,
    };
  }, [intersectionObserver, viewabilityHandlers]);

  if ((typeof intersectionObserver !== 'undefined' && typeof viewabilityHandlers !== 'undefined') || (typeof IntersectionObserver === 'undefined')) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return <ViewabilityHandlerContext.Provider value={context}>{children}</ViewabilityHandlerContext.Provider>;
};

interface ViewabilityElementProps {
  viewabilityCallback: ViewablityHandler;
  threshold?: Threshold;
}

const ViewabilityConsumer: React.FunctionComponent<ViewabilityElementProps> = props => {
  const { children, viewabilityCallback, threshold = 0 } = props;
  const ref = useViewabilityHandler(viewabilityCallback, threshold);
  return (
    <div ref={ref}>
      {children}
    </div>
  );
};

export const ViewabilityElement: React.FunctionComponent<ViewabilityElementProps> = props => {
  return (
    <ViewabilityProvider>
      <ViewabilityConsumer {...props} />
    </ViewabilityProvider>
  );
};
