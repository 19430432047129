import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { useTheme } from '../../../../theming/useTheme';
import { DecorationLine } from '../../DecorationLine';
import { ListItem } from '../listItem/ListItem';
import { LIST_STYLE, LIST_WRAPPER_STYLE } from '../lists.const';
import { MEDIA_BREAKPOINTS } from '../../../../mediaQueries.const';
import { createStyleForBoxShadow } from '../../../../theming/boxShadow';
import { H1, semanticCSS } from '../../../../typography/semanticTags';
import { BaseUnit } from '../../../../theming/baseUnitDefinition';
import { CardComponentDataProps } from '../../cards/cards.utils';

const componentConfigurationKey = 'listWithHeading';
const createStyles = (
  titleColor: string,
  backgroundColor: string,
  baseUnit: BaseUnit,
  borderWidth: number,
  borderRadius: number,
  borderColor: string,
) => StyleSheet.create({
  listWrapper: {
    ...LIST_WRAPPER_STYLE,
    backgroundColor,
    borderRadius: `${borderRadius}px`,
    border: `${borderWidth}px solid ${borderColor}`,
    overflow: 'hidden',
  },
  list: {
    ...LIST_STYLE,
    [MEDIA_BREAKPOINTS.small]: {
      position: 'relative',
    },
  },
  title: {
    color: titleColor,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: `${baseUnit / 2}px`,
    [MEDIA_BREAKPOINTS.large]: {
      padding: `${baseUnit / 2}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      padding: `${baseUnit / 2}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      padding: `${baseUnit / 2}px`,
    },
  },
});

interface ListSectionComponentDataProps {
  listProps: CardComponentDataProps[];
  displayName: string;
  itemNumLines?: number;
}

interface ListSectionComponentThemeProps {
  baseUnit: BaseUnit;
  titleColor: string;
  backgroundColor: string;
  borderWidth: number;
  borderRadius: number;
  borderColor: string;
  boxShadowTypeNormal: string;
  boxShadowTypeHover: string;
  boxShadowTypeActive: string;
}

export const ListWithHeading: React.FunctionComponent<ListSectionComponentDataProps> = ({ listProps = [], displayName = '', itemNumLines = 1 }) => {
  const {
    titleColor,
    backgroundColor,
    baseUnit,
    borderWidth,
    borderRadius,
    borderColor,
    boxShadowTypeNormal,
    boxShadowTypeActive,
    boxShadowTypeHover,
  } = useTheme<ListSectionComponentThemeProps>(componentConfigurationKey);

  const styles = createStyles(
    titleColor,
    backgroundColor,
    baseUnit,
    borderWidth,
    borderRadius,
    borderColor,
  );
  const boxShadowStyle = createStyleForBoxShadow({ boxShadowTypeNormal, boxShadowTypeHover, boxShadowTypeActive });

  const createHeading = () => (
    <H1 styles={semanticCSS(styles.title)}>
      {displayName}
    </H1>
  );

  return (
    <div className={css(
      boxShadowStyle.style,
      styles.listWrapper,
    )}
    >
      <DecorationLine />
      <div className={css(styles.list)}>
        {createHeading()}
        {listProps.map((listItemProps, index) => (<ListItem {...listItemProps} numberOfLines={itemNumLines} key={index} />))}
      </div>
    </div>
  );
};
