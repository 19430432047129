import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import {
  componentConfigThemeKey as headlineCardThemeKey,
  HeadlineCard,
  HeadlineCardThemeProps,
} from '../../partials/cards/headlineCard/HeadlineCard';
import { useTheme } from '../../../theming/useTheme';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { createStyleForBoxShadow } from '../../../theming/boxShadow';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';

export const componentConfigThemeKey = 'fourHeadlineCards';

interface FourHeadlineCardsProps {
    cards: CardComponentDataProps[];
}

export interface FourHeadlineCardsThemeProps {
    wrapperGapLarge: number;
    wrapperGapMedium: number;
    wrapperGapSmall: number;
    borderRadius: number;
    boxShadowTypeNormal: string;
    boxShadowTypeActive: string;
    boxShadowTypeHover: string;
    borderWidthTypeNormal: number;
    borderWidthTypeHover: number;
    borderWidthTypeActive: number;
    borderColorTypeNormal: string;
    borderColorTypeHover: string;
    borderColorTypeActive: string;
    backgroundColor: string;
    dividerWidth: number;
    diverColor: string;
    dividerGapInSmallScreen: number;
    dividerGapInMediumScreen: number;
    dividerGapInLargeScreen: number;
    showDivider: boolean;
    baseUnit: number;
}

const createStyle = (themeProps: FourHeadlineCardsThemeProps) => {
  const {
    dividerWidth,
    baseUnit,
    dividerGapInSmallScreen,
    dividerGapInMediumScreen,
    dividerGapInLargeScreen,
    diverColor, showDivider,
    backgroundColor,
    borderRadius,
    wrapperGapLarge,
    wrapperGapMedium,
    wrapperGapSmall } = themeProps;
  return StyleSheet.create({
    wrapper: {
      display: 'grid',
      gridTemplateRows: 'auto',
      backgroundColor,
      borderRadius: `${borderRadius}px`,
      [MEDIA_BREAKPOINTS.large]: {
        gridTemplateColumns: showDivider ? '1fr auto 1fr auto 1fr auto 1fr' : '1fr 1fr 1fr 1fr',
        gridColumnGap: `${dividerGapInLargeScreen * baseUnit}px`,
        padding: `${wrapperGapLarge * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        gridTemplateColumns: showDivider ? '1fr auto 1fr' : '1fr 1fr',
        gridColumnGap: `${dividerGapInMediumScreen * baseUnit}px`,
        gridRowGap: `${dividerGapInMediumScreen * baseUnit}px`,
        padding: `${wrapperGapMedium * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        gridTemplateColumns: '1fr',
        gridRowGap: `${dividerGapInSmallScreen * baseUnit}px`,
        padding: `${wrapperGapSmall * baseUnit}px`,
      },

    },
    dividerBetweenTwoCards: {
      backgroundColor: diverColor,
      [MEDIA_BREAKPOINTS.large]: {
        display: 'block',
        width: `${dividerWidth}px`,
        height: '100%',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        display: 'none',
      },
      [MEDIA_BREAKPOINTS.small]: {
        display: 'block',
        width: '100%',
        height: `${dividerWidth}px`,
      },
    },
    dividerBetweenFourCards: {
      [MEDIA_BREAKPOINTS.large]: {
        display: 'none',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        backgroundColor: diverColor,
        display: 'block',
        width: `${dividerWidth}px`,
        height: '100%',
        margin: `0 ${dividerGapInMediumScreen * baseUnit}px`,
        gridRowEnd: '3',
        gridRowStart: '1',
        gridColumnStart: '2',
      },
      [MEDIA_BREAKPOINTS.small]: {
        display: 'none',
      },
    },
  });
};

export const FourHeadlineCards: React.FunctionComponent<FourHeadlineCardsProps> = props => {
  const { cards } = props;
  const themeProps = useTheme<FourHeadlineCardsThemeProps>(componentConfigThemeKey);
  const {
    borderColorTypeActive,
    borderColorTypeHover,
    borderColorTypeNormal,
    boxShadowTypeActive,
    boxShadowTypeHover,
    boxShadowTypeNormal,
    borderWidthTypeActive,
    borderWidthTypeHover,
    borderWidthTypeNormal } = themeProps;
  const borderBoxShadow = {
    borderColorTypeActive,
    borderColorTypeHover,
    borderColorTypeNormal,
    borderWidthTypeActive,
    borderWidthTypeHover,
    borderWidthTypeNormal,
  };
  const boxShadowStyle = createStyleForBoxShadow({ boxShadowTypeNormal, boxShadowTypeHover, boxShadowTypeActive, borderBoxShadow });
  const { showDivider } = themeProps;
  const style = createStyle(themeProps);
  const themeContextHeadlineCard = useThemeOverride<HeadlineCardThemeProps>({
    horizontalGapMedium: 0,
  }, headlineCardThemeKey);
  return (
    <ErrorBoundary>
      {cards && cards.length > 0 && (
        <div className={css(style.wrapper, boxShadowStyle.style)}>
          <ThemeContext.Provider value={themeContextHeadlineCard}>
            <HeadlineCard {...cards[0]} />
            {showDivider ? <div className={css(style.dividerBetweenTwoCards)} /> : null}
            {showDivider ? <div className={css(style.dividerBetweenFourCards)} /> : null}
            <HeadlineCard {...cards[1]} />
            {showDivider ? <div className={css(style.dividerBetweenTwoCards)} /> : null}
            <HeadlineCard {...cards[2]} />
            {showDivider ? <div className={css(style.dividerBetweenTwoCards)} /> : null}
            <HeadlineCard {...cards[3]} />
          </ThemeContext.Provider>
        </div>
      ) }
    </ErrorBoundary>
  );
};
