import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Thumbnail } from '../../partials/image/image.types';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { useTheme } from '../../../theming/useTheme';
import { AuthorImage } from './AuthorImage/AuthorImage';
import { AuthorPageFeed } from '../articleLists/AuthorPageFeed';
import { AuthorTopGradient } from './AuthorTopGradient';
import { H1, H4, P, semanticCSS } from '../../../typography/semanticTags';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import {
  getLayoutComponentPadding,
  LayoutComponentBaseProps,
} from '../pageLayouts/layout.utils';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { SocialLink } from '../navigation/footer/AdditionalFooterSections/FooterSocialLinks';
import {
  HorizontalSocialButtonsWrapper,
  OldHorizontalSocialButtonsWrapper,
  SocialButton,
} from './AuthorSocial';
import { useHeaderHeights } from '../navigation/header/useHeaderHeights';
import { AuthorPageThemeProps, componentConfigThemeKey } from './Author.theme';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { createImageWidths } from '../../partials/image/image.utils';

export interface AuthorDataProps extends LayoutComponentBaseProps {
  image: Thumbnail;
  name: string;
  socialButtons: SocialLink[] | null;
  oldSocialButtons?: SocialButton[] | null;
  joinDateText: string;
  joinDate: string;
  bio: string | null;
  articles: Array<CardComponentDataProps> | null;
  useFormatMinutesHoursAgo?: boolean;
}

const getStyles = (
  titleColor: string,
  metaDataColor: string,
  bioColor: string,
  baseUnit: BaseUnit,
) => StyleSheet.create({
  title: {
    color: titleColor,
    textAlign: 'center',
    maxWidth: '640px',
    margin: `${baseUnit / 2}px auto`,
  },
  metaData: {
    textAlign: 'center',
    color: metaDataColor,
    maxWidth: '640px',
    margin: 'auto',
    marginTop: `${baseUnit}px`,
    marginBottom: `${baseUnit / 2}px`,
  },
  description: {
    color: bioColor,
    maxWidth: '640px',
    margin: 'auto',
    textAlign: 'center',
  },
});

const getImageAdditionalStyle = (baseUnit: BaseUnit) => StyleSheet.create({
  wrapper: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    boxSizing: 'border-box',
    border: '5px solid white',
    marginTop: `${baseUnit * 2}px`,
    [MEDIA_BREAKPOINTS.large]: {
      width: '160px',
      height: '160px',
    },
    [MEDIA_BREAKPOINTS.medium]: {
      width: '120px',
      height: '120px',
    },
    [MEDIA_BREAKPOINTS.small]: {
      width: '120px',
      height: '120px',
    },
  },
});

const getMetaData = ({ joinDateText, joinDate }: AuthorDataProps) => {
  const base = `${joinDateText} ${joinDate}`;
  return base;
};

export const Author: React.FunctionComponent<AuthorDataProps> = props => {
  const {
    image,
    name,
    socialButtons,
    bio,
    articles,
    paddingFactors,
    oldSocialButtons,
    useFormatMinutesHoursAgo,
  } = props;
  const { titleColor, metaDataColor, bioColor, baseUnit, imageBoxShadowTypeNormal } = useTheme<AuthorPageThemeProps>(componentConfigThemeKey);
  const headerHeights = useHeaderHeights();
  const styles = getStyles(titleColor, metaDataColor, bioColor, baseUnit);
  const paddingStyle = getLayoutComponentPadding(paddingFactors);
  const metaData = getMetaData(props);
  const getHorizontalSocialButtonsWrapperComponent = () => {
    if (socialButtons) {
      return <HorizontalSocialButtonsWrapper buttons={socialButtons} />;
    }
    if (oldSocialButtons) {
      return <OldHorizontalSocialButtonsWrapper buttons={oldSocialButtons} />;
    }
    return null;
  };

  return (
    <ErrorBoundary>
      <div className={css(paddingStyle)}>
        <ErrorBoundary>
          <AuthorTopGradient headerHeights={headerHeights} />
        </ErrorBoundary>
        <ErrorBoundary>
          <AuthorImage
            image={image}
            wrapperStyles={getImageAdditionalStyle(baseUnit)}
            boxShadow={imageBoxShadowTypeNormal}
            widthSizes={{ small: 110, medium: 110, large: 150 }}
            imageWidths={createImageWidths(180, 180, 180)}
          />
        </ErrorBoundary>
        <H1 styles={semanticCSS(styles.title)}>{name}</H1>
        <ErrorBoundary>
          {getHorizontalSocialButtonsWrapperComponent()}
        </ErrorBoundary>
        <H4 styles={semanticCSS(styles.metaData)}>{metaData}</H4>
        {bio ? <P styles={semanticCSS(styles.description)}>{bio}</P> : null}
        <ErrorBoundary>
          <AuthorPageFeed articles={articles} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />
        </ErrorBoundary>
      </div>
    </ErrorBoundary>
  );
};
