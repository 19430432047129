import { Breadcrumb } from './MultiLayerBreadcrumbs';

export const MODAL_LINK_MAPPING: Record<string, Breadcrumb[]> = {
  wellness: [
    {
      displayName: 'Financial Fitness',
      path: '/wellness/financial-fitness',
    },
    {
      displayName: 'Fitness',
      path: '/wellness/fitness',
    },
    {
      displayName: 'Food',
      path: '/wellness/food',
    },
    {
      displayName: 'Mindfulness',
      path: '/wellness/mindfulness',
    },
    {
      displayName: 'Parenting',
      path: '/wellness/parenting',
    },
    {
      displayName: 'Mental Health / Body Positivity',
      path: '/wellness/mental-health-body-positivity',
    },
    {
      displayName: 'Physical Health',
      path: '/wellness/physical-health',
    },
  ],
  culture: [
    {
      displayName: 'Diversity/Inclusion',
      path: '/culture/diversity-inclusion',
    },
    {
      displayName: 'Female Empowerment',
      path: '/female-empowerment',
    },
    {
      displayName: 'Heartwarming Moments',
      path: '/culture/heartwarming-moments',
    },
    {
      displayName: 'Purpose & Activism',
      path: '/culture/purpose-activism',
    },
  ],
  ncaaf: [
    {
      displayName: 'ACC',
      path: '/fannation/bleav/ncaaf/acc',
    },
    {
      displayName: 'BIG 12',
      path: '/fannation/bleav/ncaaf/big-12',
    },
    {
      displayName: 'BIG TEN',
      path: '/fannation/bleav/ncaaf/big-ten',
    },
    {
      displayName: 'HBCU',
      path: '/fannation/bleav/ncaaf/hbcu',
    },
    {
      displayName: 'OTHERS',
      path: '/fannation/bleav/ncaaf/others',
    },
    {
      displayName: 'PAC 10',
      path: '/fannation/bleav/ncaaf/pac-10',
    },
    {
      displayName: 'SEC',
      path: '/fannation/bleav/ncaaf/sec',
    },
  ],
  mlb: [
    {
      displayName: 'AL',
      path: '/fannation/bleav/mlb/al',
    },
    {
      displayName: 'NL',
      path: '/fannation/bleav/mlb/nl',
    },
  ],
};

export const TAG = 'tag';
