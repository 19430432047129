import React from 'react';
import { TYPOGRAPHY_SIZE } from 'mm-theme-configuration/dist/consts';
import { css } from 'aphrodite/no-important';
import { useTheme } from '../../../theming/useTheme';
import { createStyles } from './textStripe.styles';
import { getStyleBySize } from '../../../typography/semanticTags/semanticTags.utils';

interface TextStripeProps {
    text: string;
}

export const TextStripe: React.FunctionComponent<TextStripeProps> = props => {
  const { text } = props;
  const { colors, fontSizesStyles } = useTheme();
  const styles = createStyles(colors);

  const spanFontSizes = {
    fontSizeLarge: TYPOGRAPHY_SIZE.HUGE,
    fontSizeMedium: TYPOGRAPHY_SIZE.HUGE,
    fontSizeSmall: TYPOGRAPHY_SIZE.HUGE,
  };

  return (
    <div className={css(styles.container)}>
      <span className={css(styles.text, getStyleBySize(fontSizesStyles, spanFontSizes))}>
        {text}
      </span>
    </div>
  );
};
