import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { ViewabilityProvider } from '../../viewability/viewabilityHandler';
import { MEDIA_BREAKPOINTS } from '../../mediaQueries.const';

const createStyle = () => StyleSheet.create({
  style: {
    overflow: 'hidden',
    maxWidth: '1440px',
    [MEDIA_BREAKPOINTS.large]: {
      width: '100%',
    },
    [MEDIA_BREAKPOINTS.medium]: {
      width: '100%',
    },
    [MEDIA_BREAKPOINTS.small]: {
      width: 'unset',
    },
  },
});

export const PostWithoutSidebarMainContent: React.FunctionComponent = ({ children }) => {
  return (
    <ViewabilityProvider>
      <article className={css(createStyle().style)}>
        {children}
      </article>
    </ViewabilityProvider>
  );
};
