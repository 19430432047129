import React, { useState } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { MenuIcon } from './icons/MenuIcon';
import { MenuLinks } from './links/MenuLinks';
import { MEDIA_BREAKPOINTS } from '../../../../../../../mediaQueries.const';
import { LinkButtonDataProps } from '../../../../utils';
import { NavigationLink } from '../PrimaryHeader';
import { MenuLinkDataProps } from './links/MenuSubLink';
import { HeaderHeightsPerBreakpoint } from '../../oldHeader.utils';

export interface MenuDataProps {
  links: MenuLinkDataProps[];
  buttons?: Array<LinkButtonDataProps>;
  editions: NavigationLink[] | null;
  editionEndpoint: string;
  headerHeights: HeaderHeightsPerBreakpoint;
}

const getMenuStyle = () => StyleSheet.create({
  style: {
    [MEDIA_BREAKPOINTS.large]: {
      display: 'none',
    },
    [MEDIA_BREAKPOINTS.medium]: {
      display: 'inline-flex',
    },
    [MEDIA_BREAKPOINTS.small]: {
      display: 'inline-flex',
    },
  },
});

export const Menu: React.FunctionComponent<MenuDataProps> = props => {
  const [state, setState] = useState({
    isMenuOpen: false,
    currentOpenLink: -1,
  });
  const { isMenuOpen, currentOpenLink } = state;

  const clickOnMenuIcon = () => {
    setState({
      isMenuOpen: !isMenuOpen,
      currentOpenLink: isMenuOpen ? currentOpenLink : -1,
    });
  };

  const clickOnLinkDropDown = (index: number) => {
    setState({
      isMenuOpen,
      currentOpenLink: currentOpenLink === index ? -1 : index,
    });
  };

  const { links, editions, editionEndpoint, buttons, headerHeights } = props;
  const style = getMenuStyle();
  return (
    <div className={css(style.style)}>
      <MenuIcon isMenuOpen={isMenuOpen} onClick={clickOnMenuIcon} />
      <MenuLinks menuLinks={links} buttons={buttons} editions={editions} isMenuOpen={isMenuOpen} onClick={clickOnLinkDropDown} currentOpenLink={currentOpenLink} editionEndpoint={editionEndpoint} headerHeights={headerHeights} />
    </div>
  );
};
