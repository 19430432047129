import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { AdditionalStyleSheet } from '../../components.utils';

const getStyles = () => {
  return StyleSheet.create({
    icon: {
      display: 'block',
      width: '24px',
      height: '24px',
    },
  });
};

interface ArrowDownIconProps {
  additionalStyle?: AdditionalStyleSheet;
}

export const ArrowDownIcon: React.FunctionComponent<ArrowDownIconProps> = props => {
  const { additionalStyle } = props;
  const styles = getStyles();

  return (
    <svg className={css(styles.icon, additionalStyle?.style)} width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.5732 12.4244L0.101236 2.65005L1.73593 0.900002L10.5731 9.14981L19.4143 0.900002L21.0464 2.65005L10.5731 12.4231L10.5732 12.4244ZM10.5732 17.1094L1.73467 8.85956L0.0999756 10.6096L10.5719 20.384L21.0452 10.6109L19.4131 8.86089L10.5719 17.1107L10.5732 17.1094Z" fill="#CCCCCC" />
    </svg>
  );
};
