import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { TypographySize } from 'mm-theme-configuration/dist/consts';
import { useTheme } from '../../../theming/useTheme';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { AuthorsList } from '../../articleComponents/author/AuthorsList';
import { H1, semanticCSS } from '../../../typography/semanticTags';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { ArticleAuthorsTitle } from '../author/ArticleAuthorsTitle';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { Intro } from '../../articleComponents/Intro';

export const componentConfigurationKey = 'articleTitle';

export interface ArticleTitleThemeProps {
  titleColor: string;
  fontSize: TypographySize;
  baseUnit: BaseUnit;
}

export interface ArticleTitleDataProps {
  title: string;
  intro: string | null;
  by: string;
  authors: AuthorsList | null;
  updatedAt: string;
  updatedAtISO: string;
  showUpdatedAt: boolean;
  createdAt: string;
  createdAtISO: string;
  useFormatMinutesHoursAgo?: boolean;
}

const createStyle = ({ titleColor, baseUnit }: ArticleTitleThemeProps) => StyleSheet.create({
  wrapper: {
    position: 'relative',
  },
  title: {
    color: titleColor,
    marginTop: `${baseUnit}px`,
    marginBottom: `${baseUnit * 0.5}px`,
  },
  sidesPadding: {
    marginTop: `${baseUnit * 0.5}px`,
    marginLeft: 0,
    marginRight: 0,
    [MEDIA_BREAKPOINTS.small]: {
      marginLeft: `${baseUnit}px`,
      marginRight: `${baseUnit}px`,
    },
  },
});

export const ArticleTitle: React.FunctionComponent<ArticleTitleDataProps> = props => {
  const { title, children, intro, ...authorsTitleProps } = props;
  const themeProps = useTheme<ArticleTitleThemeProps>(componentConfigurationKey);
  const { fontSize } = themeProps;
  const style = createStyle(themeProps);
  const fontSizes = {
    fontSizeLarge: fontSize,
    fontSizeMedium: fontSize,
    fontSizeSmall: fontSize,
  };
  return (
    <ErrorBoundary>
      <H1 {...fontSizes} styles={semanticCSS(style.title, style.sidesPadding)}>{title}</H1>
      {intro ? <Intro html={intro} styles={semanticCSS(style.sidesPadding)} /> : null}
      <div className={css(style.wrapper)}>
        <ArticleAuthorsTitle {...authorsTitleProps} />
        {children}
      </div>
    </ErrorBoundary>
  );
};
