import * as React from 'react';
import { EventContext } from '../../../analytics/analytics';

declare global {
  interface Window {
    mmClientApi?: Array<any> | { push: (eventName: string, params: any) => void };
  }
}

export interface AnalyticsAMPDataVars {
  'data-vars-action'?: string;
  'data-vars-label'?: string;
  'data-vars-category'?: string;
}

const DEFAULT_ANALYTICS_ACTION = 'click';
const DEFAULT_ANALYTICS_TYPE = 'click';

export const Link: React.FunctionComponent<React.HTMLProps<HTMLAnchorElement> & AnalyticsAMPDataVars> = props => {
  const { category, action } = React.useContext(EventContext);
  const {
    children,
    onClick,
    href,
  } = props;
  const sendClickAnalytics = () => {
    if (typeof category !== 'undefined') {
      window.mmClientApi = window.mmClientApi || [];
      window.mmClientApi.push('analytics', {
        event_category: category,
        event_action: action || DEFAULT_ANALYTICS_ACTION,
        event_label: href || '',
        event_type: DEFAULT_ANALYTICS_TYPE,
      });
    }
  };

  const onClickHandler: React.EventHandler<any> = (...args) => {
    if (onClick) {
      onClick(...args);
    }
    sendClickAnalytics();
  };

  return (
    <a
      data-mm-click-analytics
      data-vars-action={action || DEFAULT_ANALYTICS_ACTION}
      data-vars-label={href || ''}
      data-vars-category={category}
      data-vars-type={DEFAULT_ANALYTICS_TYPE}
      {...props}
      onClick={onClickHandler}
    >
      {children}
    </a>
  );
};
