import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { style } from '../../../typography/typography.style';
import { useButtonTheme } from './button.utils';
import { AdditionalStyleSheet } from '../../components.utils';

export interface ButtonProps {
  label: string | null;
  onClick: () => void;
  disabled?: boolean;
  additionalStyle?: AdditionalStyleSheet;
}

export const Button: React.FunctionComponent<ButtonProps> = props => {
  const { label, onClick, additionalStyle, disabled = false } = props;

  const { buttonStyle, boxShadowStyle, textStyle } = useButtonTheme(disabled);
  const regularClasses = [buttonStyle.button, boxShadowStyle.style, StyleSheet.create({ style }).style, textStyle, additionalStyle && additionalStyle.style];
  const disabledClasses = [buttonStyle.button, StyleSheet.create({ style }).style, textStyle, additionalStyle && additionalStyle.style];

  return (
    <button
      type="button"
      disabled={disabled}
      className={css(...(disabled ? disabledClasses : regularClasses))}
      onClick={onClick}
    >
      {label}
    </button>
  );
};
