import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { useTheme } from '../../../theming/useTheme';
import { componentConfigThemeKey, RelatedTopicsThemeProps } from './relatedTopics.theme';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import {
  componentConfigurationKey as SectionHeaderConfigThemeKey,
  SectionHeader,
  SectionHeaderComponentThemeProps,
} from '../../wrappers/sectionHeader/SectionHeader';
import {
  ButtonComponentThemeProps, componentConfigThemeKey as LinkButtonConfigThemeKey,
} from '../../components/buttons/button.utils';
import { H3 } from '../../../typography/semanticTags';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { LinkWithButtonStyle } from '../../components/buttons/LinkWithButtonStyle';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { baseUnit } from '../../../theming/baseUnitDefinition';
import { RelatedTopicsWrapper, RelatedTopicsWrapperProps } from './RelatedTopicsWrapper';
import { AdditionalStyleSheet } from '../../components.utils';

const getStyles = (rowGapSmall: number, rowGapMedium: number, rowGapLarge: number, columnGapSmall: number, columnGapMedium: number, columnGapLarge: number, largeHorizontalGap: number, mediumHorizontalGap: number, smallHorizontalGap: number) => {
  return StyleSheet.create({
    linksStyle: {
      display: 'flex',
      flexWrap: 'wrap',
      [MEDIA_BREAKPOINTS.large]: {
        gap: `${rowGapLarge * baseUnit}px ${columnGapLarge * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        gap: `${rowGapMedium * baseUnit}px ${columnGapMedium * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        gap: `${rowGapSmall * baseUnit}px ${columnGapSmall * baseUnit}px`,
      },
    },
    wrapperStyle: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto',
      [MEDIA_BREAKPOINTS.large]: {
        gridRowGap: `${largeHorizontalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        gridRowGap: `${mediumHorizontalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        gridRowGap: `${smallHorizontalGap * baseUnit}px`,
      },
    },
  });
};

const linkSizes = StyleSheet.create({
  style: {
    padding: '7px 8px',
    minWidth: '60px',
    height: 'unset',
  },
}) as AdditionalStyleSheet;

export interface PageLink {
    name: string;
    link: string;
}

export interface RelatedTopicsProps {
    title: string;
    pages: Array<PageLink>;
}

const getAnalyticsEventParams = (page: PageLink) => ({
  category: 'Article',
  action: 'Related Topics clicked',
  label: `${page.name}`,
});

export type RelatedTopicsComponentProps = RelatedTopicsProps & RelatedTopicsWrapperProps;

export const RelatedTopics: React.FunctionComponent<RelatedTopicsComponentProps> = props => {
  const { title, pages, defaultBlocksWidths, dataId, marginBetweenBlocksFactor } = props;

  const relatedTopicsAnalytics = (page: PageLink) => {
    const analyticsEventParams = getAnalyticsEventParams(page);
    return () => {
      window.mmClientApi = window.mmClientApi || [];
      window.mmClientApi.push('analytics', {
        event_category: analyticsEventParams.category,
        event_action: analyticsEventParams.action,
        event_label: analyticsEventParams.label,
      });
    };
  };

  const {
    titleColor,
    titleBackgroundColor,
    titleLargeMarginBottomFactor,
    titleMediumMarginBottomFactor,
    titleSmallMarginBottomFactor,
    titleLargeHorizontalGap,
    titleMediumHorizontalGap,
    titleSmallHorizontalGap,
    titleLargeWrapperGap,
    titleMediumWrapperGap,
    titleSmallWrapperGap,
    linkBackgroundColorNormal,
    linkBackgroundColorHover,
    linkBackgroundColorActive,
    linkTextColorNormal,
    linkTextColorHover,
    linkTextColorActive,
    linkBorderColorNormal,
    linkBorderColorHover,
    linkBorderColorActive,
    linkFontSizeLarge,
    linkFontSizeMedium,
    linkFontSizeSmall,
    linkBoxShadowTypeNormal,
    linkBoxShadowTypeHover,
    linkBoxShadowTypeActive,
    linkBorderRadius,
    linkBorderWidthNormal,
    linkBorderWidthHover,
    linkBorderWidthActive,
    titleFontSizeSmall,
    titleFontSizeMedium,
    titleFontSizeLarge,
    rowGapSmall,
    rowGapMedium,
    rowGapLarge,
    columnGapSmall,
    columnGapMedium,
    columnGapLarge,
    largeHorizontalGap,
    mediumHorizontalGap,
    smallHorizontalGap,
  } = useTheme<RelatedTopicsThemeProps>(componentConfigThemeKey);

  const themeContextSectionHeader = useThemeOverride<SectionHeaderComponentThemeProps>({
    titleColor,
    titleBackgroundColor,
    largeMarginBottomFactor: titleLargeMarginBottomFactor,
    mediumMarginBottomFactor: titleMediumMarginBottomFactor,
    smallMarginBottomFactor: titleSmallMarginBottomFactor,
    largeHorizontalGap: titleLargeHorizontalGap,
    mediumHorizontalGap: titleMediumHorizontalGap,
    smallHorizontalGap: titleSmallHorizontalGap,
    largeWrapperGap: titleLargeWrapperGap,
    mediumWrapperGap: titleMediumWrapperGap,
    smallWrapperGap: titleSmallWrapperGap,
  }, SectionHeaderConfigThemeKey);

  const themeContextLinkButton = useThemeOverride<ButtonComponentThemeProps>({
    backgroundColorNormal: linkBackgroundColorNormal,
    backgroundColorHover: linkBackgroundColorHover,
    backgroundColorActive: linkBackgroundColorActive,
    textColorNormal: linkTextColorNormal,
    textColorHover: linkTextColorHover,
    textColorActive: linkTextColorActive,
    borderColorNormal: linkBorderColorNormal,
    borderColorHover: linkBorderColorHover,
    borderColorActive: linkBorderColorActive,
    fontSizeLarge: linkFontSizeLarge,
    fontSizeMedium: linkFontSizeMedium,
    fontSizeSmall: linkFontSizeSmall,
    boxShadowTypeNormal: linkBoxShadowTypeNormal,
    boxShadowTypeHover: linkBoxShadowTypeHover,
    boxShadowTypeActive: linkBoxShadowTypeActive,
    borderRadius: linkBorderRadius,
    borderWidthNormal: linkBorderWidthNormal,
    borderWidthHover: linkBorderWidthHover,
    borderWidthActive: linkBorderWidthActive,
  }, LinkButtonConfigThemeKey);

  const { wrapperStyle, linksStyle } = getStyles(rowGapSmall, rowGapMedium, rowGapLarge, columnGapSmall, columnGapMedium, columnGapLarge, largeHorizontalGap, mediumHorizontalGap, smallHorizontalGap);

  const createLinkWithButtonArray = () => {
    return pages.map(page => {
      const { category, action, label } = getAnalyticsEventParams(page);
      return (
        <LinkWithButtonStyle
          href={page.link}
          key={page.link}
          onClick={relatedTopicsAnalytics(page)}
          additionalStyle={linkSizes}
          data-vars-action={action}
          data-vars-category={category}
          data-vars-label={label}
        >
          {page.name}
        </LinkWithButtonStyle>
      );
    });
  };


  return (
    <ErrorBoundary>
      <RelatedTopicsWrapper defaultBlocksWidths={defaultBlocksWidths} marginBetweenBlocksFactor={marginBetweenBlocksFactor} dataId={dataId}>
        <div className={css(wrapperStyle)}>
          <ThemeContext.Provider value={themeContextSectionHeader}>
            <SectionHeader moreButtonText={null} moreButtonSvg={null} paddingFactors={{ large: 0, medium: 0, small: 0 }}>
              <H3
                fontSizeLarge={titleFontSizeLarge}
                fontSizeSmall={titleFontSizeSmall}
                fontSizeMedium={titleFontSizeMedium}
              >
                {title}
              </H3>
            </SectionHeader>
          </ThemeContext.Provider>
          <div className={css(linksStyle)}>
            <ThemeContext.Provider value={themeContextLinkButton}>
              {createLinkWithButtonArray()}
            </ThemeContext.Provider>
          </div>
        </div>
      </RelatedTopicsWrapper>
    </ErrorBoundary>
  );
};
