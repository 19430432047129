import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { TypographySize } from 'mm-theme-configuration/dist/consts';
import { HorizontalCard } from '../../partials/cards/horizontalCard/HorizontalCard';
import { componentConfigThemeKey as horizontalCardConfigThemeKey, HorizontalCardThemeProps } from '../../partials/cards/horizontalCard/horizontalCard.theme';
import { componentConfigThemeKey as verticalCardConfigThemeKey, VerticalCardThemeProps } from '../../partials/cards/verticalCard/VerticalCard.theme';
import { VerticalCard } from '../../partials/cards/verticalCard/VerticalCard';
import { useTheme } from '../../../theming/useTheme';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { createImageWidths } from '../../partials/image/image.utils';
import { getLayoutComponentPadding, LayoutComponentBaseProps } from '../pageLayouts/layout.utils';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';

const createStyle = (baseUnit: BaseUnit) => StyleSheet.create({
  container: {
    display: 'grid',
    gridColumnGap: `${baseUnit / 2}px`,
    gridTemplateRows: '1fr',
    gridTemplateColumns: '1fr 1fr',
    [MEDIA_BREAKPOINTS.small]: {
      gridColumnGap: '0',
      gridRowGap: `${baseUnit / 2}px`,
      gridTemplateRows: 'auto',
      gridTemplateColumns: '1fr',
    },
  },
  horizontalCardsWrapper: {
    position: 'relative',
  },
  horizontalCardsContainer: {
    maxHeight: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr 1fr 1fr',
    gridRowGap: `${baseUnit / 2}px`,
  },
  horizontalCardWrapper: {
    minWidth: '0',
    minHeight: '0',
    boxSizing: 'border-box',
    padding: '0.1px',
    position: 'relative',
    ':last-child': {
      marginBottom: '0',
    },
  },
});

interface OneThreeArticleListComponentDataProps extends LayoutComponentBaseProps {
  cardsProps: CardComponentDataProps[];
  useFormatMinutesHoursAgo?: boolean;
}

export const componentConfigThemeKey = 'oneVCThreeHC';

export interface OneThreeArticleListComponentThemeProps {
  baseUnit: BaseUnit;
  showDescriptionInLargeScreen: boolean;
  showDescriptionInMediumScreen: boolean;
  showDescriptionInSmallScreen: boolean;
  verticalTitleFontSizeLarge: TypographySize;
  verticalTitleFontSizeMedium: TypographySize;
  verticalTitleFontSizeSmall: TypographySize;
  horizontalTitleFontSizeLarge: TypographySize;
  horizontalTitleFontSizeMedium: TypographySize;
  horizontalTitleFontSizeSmall: TypographySize;
  showDescriptionInVCLargeScreen: boolean;
  showDescriptionInVCMediumScreen: boolean;
  showDescriptionInVCSmallScreen: boolean;
  showMainCategoryInHCLargeScreen: boolean;
  showMainCategoryInHCMediumScreen: boolean;
  showMainCategoryInHCSmallScreen: boolean;
  showMainCategoryInVCLargeScreen: boolean;
  showMainCategoryInVCMediumScreen: boolean;
  showMainCategoryInVCSmallScreen: boolean;
}

const imageWidthsHorizontalCard = createImageWidths(180, 180, 180);
const imageWidthsVerticalCard = createImageWidths(360, 720, 540);

export const OneThreeArticleList: React.FunctionComponent<OneThreeArticleListComponentDataProps> = ({ cardsProps = [], paddingFactors, useFormatMinutesHoursAgo }) => {
  const [highlight, ...moreCards] = cardsProps;
  const {
    baseUnit,
    showDescriptionInLargeScreen,
    showDescriptionInMediumScreen,
    showDescriptionInSmallScreen,
    verticalTitleFontSizeLarge,
    verticalTitleFontSizeMedium,
    verticalTitleFontSizeSmall,
    horizontalTitleFontSizeLarge,
    horizontalTitleFontSizeMedium,
    horizontalTitleFontSizeSmall,
    showDescriptionInVCLargeScreen,
    showDescriptionInVCMediumScreen,
    showDescriptionInVCSmallScreen,
    showMainCategoryInHCLargeScreen,
    showMainCategoryInHCMediumScreen,
    showMainCategoryInHCSmallScreen,
    showMainCategoryInVCLargeScreen,
    showMainCategoryInVCMediumScreen,
    showMainCategoryInVCSmallScreen,
  } = useTheme<OneThreeArticleListComponentThemeProps>(componentConfigThemeKey);
  const style = createStyle(baseUnit);
  const paddingStyle = getLayoutComponentPadding(paddingFactors);

  const themeContextHorizontalCards = useThemeOverride<HorizontalCardThemeProps>({
    aspectRatioLarge: { x: 4, y: 3 },
    aspectRatioMedium: { x: 4, y: 3 },
    aspectRatioSmall: { x: 1, y: 1 },
    showDescriptionInLargeScreen,
    showDescriptionInMediumScreen,
    showDescriptionInSmallScreen,
    titleFontSizeLarge: horizontalTitleFontSizeLarge,
    titleFontSizeMedium: horizontalTitleFontSizeMedium,
    titleFontSizeSmall: horizontalTitleFontSizeSmall,
    showMainCategoryInLargeScreen: showMainCategoryInHCLargeScreen,
    showMainCategoryInMediumScreen: showMainCategoryInHCMediumScreen,
    showMainCategoryInSmallScreen: showMainCategoryInHCSmallScreen,
  }, horizontalCardConfigThemeKey);

  const themeContextVerticalCard = useThemeOverride<VerticalCardThemeProps>({
    titleFontSizeLarge: verticalTitleFontSizeLarge,
    titleFontSizeMedium: verticalTitleFontSizeMedium,
    titleFontSizeSmall: verticalTitleFontSizeSmall,
    showDescriptionInLargeScreen: showDescriptionInVCLargeScreen,
    showDescriptionInMediumScreen: showDescriptionInVCMediumScreen,
    showDescriptionInSmallScreen: showDescriptionInVCSmallScreen,
    showMainCategoryInLargeScreen: showMainCategoryInVCLargeScreen,
    showMainCategoryInMediumScreen: showMainCategoryInVCMediumScreen,
    showMainCategoryInSmallScreen: showMainCategoryInVCSmallScreen,
  }, verticalCardConfigThemeKey);

  const createCard = (cardProps: CardComponentDataProps, i: number) => (
    <div className={css(style.horizontalCardWrapper)} key={i}>
      <ThemeContext.Provider value={themeContextHorizontalCards}>
        <HorizontalCard {...cardProps} imageWidths={imageWidthsHorizontalCard} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />
      </ThemeContext.Provider>
    </div>
  );

  return (
    <ErrorBoundary>
      {cardsProps && cardsProps.length > 0
      && (
      <div className={css(paddingStyle, style.container)}>
        <ThemeContext.Provider value={themeContextVerticalCard}>
          <VerticalCard {...highlight} imageWidths={imageWidthsVerticalCard} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />
        </ThemeContext.Provider>
        <div className={css(style.horizontalCardsWrapper)}>
          <div className={css(style.horizontalCardsContainer)}>
            {moreCards.map(createCard)}
          </div>
        </div>
      </div>
      )}
    </ErrorBoundary>
  );
};
