import { COLOR, Colors, Palette } from 'mm-theme-configuration';

export class PaletteManager {
  palette: Palette;

  constructor(palette: Palette) {
    this.palette = palette;
  }

  getColorByKey(key: string) {
    return this.palette.colors[key as COLOR];
  }

  getColors(): Colors {
    return { ...this.palette.colors };
  }

  getFonts() {
    return this.palette.fonts;
  }

  getFontSizeMap() {
    return { ...this.palette.typography };
  }
}
