import * as React from 'react';
import { css, StyleDeclarationValue, StyleSheet } from 'aphrodite/no-important';
import { TypographySize } from 'mm-theme-configuration/dist/consts';
import { semanticCSS, Span } from '../../../../../typography/semanticTags';
import { TriangleIcon } from '../TriangleIcon';
import { getStyleBySize } from '../../../../../typography/semanticTags/semanticTags.utils';
import { useTheme } from '../../../../../theming/useTheme';
import { Directions } from '../ChevronIcon';
import { getLinkColorsStyle } from './linkStyle.utils';
import { RegisterLinkWithSubMenu } from './linkWithSubHeader.utils';
import { NavigationLink } from '../oldHeader/primaryHeader/PrimaryHeader';

interface LinkWithSubMenuProps {
  title: string;
  hoverStateColor: string;
  activeStateColor: string;
  idleColor: string;
  currentLinkColor: string;
  index: number;
  linkDropdownIconColor: string;
  linkFontSizeLarge: TypographySize;
  linkFontSizeMedium: TypographySize;
  linkFontSizeSmall: TypographySize;
  isInvisibleLink?: boolean;
  isOpen?: boolean;
  registerLinkWithSubMenu?: RegisterLinkWithSubMenu;
  toggleMenu?: (element: HTMLElement, index: number) => void;
  href: string;
  subLinks: NavigationLink[];
}

interface WrapperProps {
  fontStyle: StyleDeclarationValue;
  spanStyle: StyleDeclarationValue;
  labelColorStyle: StyleDeclarationValue;
  onHover: () => void;
  onLeave: () => void;
  onClick: () => void;
  refObject: React.RefObject<HTMLElement>;
  href: string;
}

const getStyles = (isInvisibleLink: boolean) => StyleSheet.create({
  span: {
    visibility: isInvisibleLink ? 'hidden' : 'visible',
    whiteSpace: 'nowrap',
    position: 'relative',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  menu: {
    position: 'absolute',
  },
  subLinks: {
    display: 'none',
  },
});

const FolderWrapper: React.FunctionComponent<WrapperProps> = ({ children, fontStyle, spanStyle, labelColorStyle, onHover, onLeave, onClick, refObject }) => (
  <Span
    styles={semanticCSS(fontStyle, spanStyle, labelColorStyle)}
    onMouseEnter={onHover}
    onMouseLeave={onLeave}
    onClick={onClick}
    reference={refObject}
  >
    {children}
  </Span>

);
const LinkWrapper: React.FunctionComponent<WrapperProps> = ({ children, fontStyle, spanStyle, labelColorStyle, onHover, onLeave, onClick, refObject, href }) => (
  <a
    className={css(fontStyle, spanStyle, labelColorStyle)}
    onMouseEnter={onHover}
    onMouseLeave={onLeave}
    onClick={onClick}
    ref={refObject as React.RefObject<HTMLAnchorElement>}
    href={href}
  >
    {children}
  </a>
);

export const LinkWithSubMenu: React.FunctionComponent<LinkWithSubMenuProps> = props => {
  const { title, hoverStateColor, idleColor, index, linkDropdownIconColor, linkFontSizeLarge, linkFontSizeMedium, linkFontSizeSmall, isInvisibleLink = false, isOpen = false, currentLinkColor, activeStateColor, registerLinkWithSubMenu, toggleMenu, href, subLinks } = props;
  const [isHovering, setIsHovering] = React.useState(false);
  const { fontSizesStyles } = useTheme();
  const styles = getStyles(isInvisibleLink);
  const linkOrFolderRef = React.useRef<HTMLElement>(null);
  const fontStyle = getStyleBySize(fontSizesStyles, {
    fontSizeSmall: linkFontSizeSmall,
    fontSizeMedium: linkFontSizeMedium,
    fontSizeLarge: linkFontSizeLarge,
  });
  const linkColorsStyle = getLinkColorsStyle(activeStateColor, hoverStateColor, idleColor, currentLinkColor, false);

  React.useEffect(() => {
    if (typeof registerLinkWithSubMenu === 'function') {
      registerLinkWithSubMenu(linkOrFolderRef, index);
    }
  }, [registerLinkWithSubMenu, index]);

  const onHover = () => {
    setIsHovering(true);
  };

  const onLeave = () => {
    setIsHovering(false);
  };

  const onClick = () => {
    if (linkOrFolderRef.current !== null && typeof toggleMenu === 'function') {
      toggleMenu(linkOrFolderRef.current, index);
    }
  };

  const triangleDirection = isOpen ? Directions.UP : Directions.DOWN;
  const isFolder = href === '-';
  const WrapperComponent = isFolder ? FolderWrapper : LinkWrapper;
  return (
    <WrapperComponent
      fontStyle={fontStyle}
      spanStyle={styles.span}
      labelColorStyle={linkColorsStyle.link}
      onHover={onHover}
      onLeave={onLeave}
      onClick={onClick}
      refObject={linkOrFolderRef}
      href={href}
    >
      {title}
      <TriangleIcon
        hoverColor={hoverStateColor}
        color={linkDropdownIconColor}
        isHovering={isHovering}
        direction={triangleDirection}
      />
      {/* this is added for seo needs only. This list is not visible on screen */}
      <ul className={css(styles.subLinks)}>
        {
          subLinks && subLinks.map((navigationLink, ind) => {
            return (
              <li key={ind}>
                <a href={navigationLink.link.href}>{navigationLink.link.text}</a>
              </li>
            );
          })
        }
      </ul>
    </WrapperComponent>

  );
};
