import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { LogoProps } from '../../components/navigation/header/oldHeader/Logo';
import { NavigationLink } from '../../components/navigation/header/oldHeader/primaryHeader/PrimaryHeader';
import { CtaButton, CtaDataProps } from '../../components/navigation/header/CtaButton';
import { useTheme } from '../../../theming/useTheme';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { HeaderHeightsPerBreakpoint } from '../../components/navigation/header/oldHeader/oldHeader.utils';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { AMPLogo } from './AMPLogo';
import { AMPPrimaryLinks } from './AMPPrimaryLinks';
import { AMPEditions } from './AMPEditions';
import { AMPMenuIcon } from './AMPMenuIcon';
import { createStyleForBoxShadow } from '../../../theming/boxShadow';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { componentConfigThemeKey, HeaderThemeProps } from '../../components/navigation/header/Header.theme';

/* eslint-disable jsx-a11y/no-noninteractive-tabindex */

interface AMPPrimaryHeaderDataProps {
  links: NavigationLink[] | null;
  logo: LogoProps;
  editions: NavigationLink[] | null;
  cta: CtaDataProps | null;
  shouldShowClosedMenuIcon: boolean;
  pagePath: string;
  editionEndpoint: string;
  headerHeights: HeaderHeightsPerBreakpoint;
  logoHeights: HeaderHeightsPerBreakpoint;
}

const getPrimaryHeaderStyle = (backgroundColor: string, baseUnit: BaseUnit, headerHeights: HeaderHeightsPerBreakpoint) => StyleSheet.create({
  wrapper: {
    backgroundColor,
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 100,
    [MEDIA_BREAKPOINTS.large]: {
      height: `${headerHeights.large * baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      height: `${headerHeights.medium * baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      height: `${headerHeights.small * baseUnit}px`,
    },
  },
  itemsBasicContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    height: '100%',
    width: 'fit-content',
    alignItems: 'center',
    gridColumnGap: `${baseUnit}px`,
  },
  itemsRightContainer: {
    alignItems: 'baseline',
    [MEDIA_BREAKPOINTS.large]: {
      paddingRight: `${baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      paddingRight: `${baseUnit / 2}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      paddingRight: `${baseUnit / 2}px`,
    },
  },
  itemsLeftContainer: {
    paddingLeft: `${baseUnit}px`,
  },
  ctaWrapper: {
    [MEDIA_BREAKPOINTS.large]: {
      height: `${headerHeights.large * baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      height: `${headerHeights.medium * baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      height: `${headerHeights.small * baseUnit}px`,
    },
    display: 'flex',
    alignItems: 'center',
  },
});

export const AMPPrimaryHeader: React.FunctionComponent<AMPPrimaryHeaderDataProps> = props => {
  const { firstRowBoxShadow, firstRowBackgroundColor, baseUnit } = useTheme<HeaderThemeProps>(componentConfigThemeKey);
  const { links, logo, cta, shouldShowClosedMenuIcon, editions, pagePath, editionEndpoint, headerHeights, logoHeights } = props;
  const style = getPrimaryHeaderStyle(firstRowBackgroundColor, baseUnit, headerHeights);
  const boxShadowStyle = createStyleForBoxShadow({ boxShadowTypeNormal: firstRowBoxShadow });

  return (
    <ErrorBoundary>
      <div className={css(style.wrapper, boxShadowStyle.style)}>
        <div className={css(style.itemsBasicContainer, style.itemsLeftContainer)}>
          {shouldShowClosedMenuIcon ? <AMPMenuIcon /> : null}
          <AMPLogo {...logo} logoHeights={{ large: logoHeights.large, medium: logoHeights.medium, small: logoHeights.small }} />
          {links ? <AMPPrimaryLinks links={links} pagePath={pagePath} /> : null}
        </div>
        <div className={css(style.itemsBasicContainer, style.itemsRightContainer)}>
          {cta ? (
            <div className={css(style.ctaWrapper)}>
              <CtaButton {...cta} />
            </div>
          ) : null}
          {editions ? <AMPEditions editions={editions} editionEndpoint={editionEndpoint} headerHeights={headerHeights} /> : null}
        </div>
      </div>
    </ErrorBoundary>
  );
};
