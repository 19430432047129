import * as React from 'react';
import { Kasda } from '../../Kasda/Kasda';

export type HreflangItem = {
  langCode: string;
  url: string;
}

interface HreflangTagsProps {
  locale: string;
  canonicalUrl: string;
  alternateUrls: HreflangItem[];
}

export const HreflangTags: React.FunctionComponent<HreflangTagsProps> = ({ locale, canonicalUrl, alternateUrls }) => {
  if (!alternateUrls.length) return null;

  const linkTags = alternateUrls.map((item: HreflangItem, index) => {
    if (typeof item.langCode !== 'string' || typeof item.url !== 'string') return null;

    return <link key={index} rel="alternate" hrefLang={item.langCode} href={item.url} />;
  }).filter(Boolean);

  return (
    <Kasda.Link>
      <link rel="alternate" hrefLang={locale} href={canonicalUrl} />
      {linkTags}
    </Kasda.Link>
  );
};
