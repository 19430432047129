import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { createImageWidths, ImageWidths } from '../../partials/image/image.utils';
import { baseUnit } from '../../../theming/baseUnitDefinition';
import { useTheme } from '../../../theming/useTheme';
import { HorizontalCard } from '../../partials/cards/horizontalCard/HorizontalCard';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { getLayoutComponentPadding, LayoutComponentBaseProps } from '../pageLayouts/layout.utils';

export const componentConfigThemeKey = 'sixHC';
const maximumNumberOfCards = 6;

interface SixHCDataProps extends LayoutComponentBaseProps {
  cards: CardComponentDataProps[] | null;
  imageWidths?: ImageWidths;
  expandable?: boolean;
  useFormatMinutesHoursAgo?: boolean;
}

export interface SixHCThemeProps {
  largeHorizontalGap: number;
  mediumHorizontalGap: number;
  smallHorizontalGap: number;
}

const getStyle = (themeProps: SixHCThemeProps) => {
  const { largeHorizontalGap, mediumHorizontalGap, smallHorizontalGap } = themeProps;
  return StyleSheet.create({
    wrapper: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto',
      height: 'fit-content',
      [MEDIA_BREAKPOINTS.large]: {
        gridRowGap: `${baseUnit * largeHorizontalGap}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        gridRowGap: `${baseUnit * mediumHorizontalGap}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        gridRowGap: `${baseUnit * smallHorizontalGap}px`,
      },
    },
  });
};

const defaultImageWidths = createImageWidths(180, 180, 360);

export const SixHC: React.FunctionComponent<SixHCDataProps> = props => {
  const { cards, paddingFactors, imageWidths = defaultImageWidths, expandable = false, useFormatMinutesHoursAgo } = props;
  const themeProps = useTheme<SixHCThemeProps>(componentConfigThemeKey);
  const style = getStyle(themeProps);
  const paddingStyle = getLayoutComponentPadding(paddingFactors);
  return (
    <ErrorBoundary>
      {cards && cards.length > 0 && (
        <div className={css(paddingStyle, style.wrapper)}>
          {expandable
            ? cards.map(((card, index) => (
              <HorizontalCard key={index} {...card} imageWidths={imageWidths} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />)))
            : cards.map(((card, index) => (
              <HorizontalCard key={index} {...card} imageWidths={imageWidths} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />))).slice(0, maximumNumberOfCards)}
        </div>
      )}
    </ErrorBoundary>
  );
};
