import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { injectScriptTagToHead, MandatoryAdditionalStyle } from '../../components.utils';
import { BlockWidths } from '../../components/pageLayouts/BlockTypes.utils';
import { createBlockStyle } from '../blockMutualStyle';
import { Kasda } from '../../../Kasda/Kasda';

interface FacebookComponentDataProps {
  html: string;
  dataId?: string;
  defaultBlocksWidths: BlockWidths;
}

const createStyle = (defaultBlocksWidths: BlockWidths) => {
  return StyleSheet.create({
    style: {
      ...createBlockStyle(defaultBlocksWidths),
      display: 'flex',
      justifyContent: 'center',
    },
  });
};

export const removeDataWidthFromHtml = (html: string) => html.replace(/data-width="\d+"/, 'data-width=""');

type FacebookComponentProps = FacebookComponentDataProps & MandatoryAdditionalStyle;
const facebookVersion = 'v3.3';
export const FacebookComponent: React.FunctionComponent<FacebookComponentProps> = props => {
  const { html, additionalStyle, dataId, defaultBlocksWidths } = props;
  const facebookStyle = createStyle(defaultBlocksWidths);

  React.useEffect(() => {
    // @ts-ignore TS2339
    if (typeof window.FB !== 'undefined') {
      // @ts-ignore TS2339
      // eslint-disable-next-line no-undef
      FB.init({
        xfbml: true,
        version: facebookVersion,
      });
    } else {
      injectScriptTagToHead(`https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=${facebookVersion}`);
    }
  }, []);

  const htmlWithFluidWidth = removeDataWidthFromHtml(html);
  return (
    <ErrorBoundary>
      <Kasda.Script>
        <script async defer src={`https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=${facebookVersion}`} />
      </Kasda.Script>
      {/* eslint-disable-next-line react/no-danger */}
      <figure data-mm-id={dataId} dangerouslySetInnerHTML={{ __html: htmlWithFluidWidth }} className={css(facebookStyle.style, additionalStyle.style)} />
    </ErrorBoundary>
  );
};

export const Facebook = (props: FacebookComponentProps) => <ErrorBoundary><FacebookComponent {...props} /></ErrorBoundary>;
