import React, { useCallback, useEffect, useRef, useState } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Colors } from 'mm-theme-configuration';
import { TYPOGRAPHY_SIZE } from 'mm-theme-configuration/dist/consts';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { Link } from '../../partials/link/Link';
import { ImageProps, Image } from '../../partials/image/Image';
import { createImageHeightCalculationMethods } from '../../partials/image/image.utils';
import { P, semanticCSS } from '../../../typography/semanticTags';
import { useTheme } from '../../../theming/useTheme';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';

interface ResizingCarouselImageProps extends ImageProps {
  imageLink: string;
  title?: string;
  links?: Array<{text: string; link: string}>;
}

interface ResizingCarouselProps {
  images: ResizingCarouselImageProps[];
}

const getStyles = (colors: Colors) => {
  return StyleSheet.create({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflowX: 'scroll',
      scrollbarWidth: 'none',
      [MEDIA_BREAKPOINTS.medium]: {
        width: '100%',
        overflowX: 'scroll',
        scrollbarWidth: 'none',
      },
    },
    wrapper: {
      display: 'flex',
      width: '100%',
      height: '700px',
      position: 'relative',
      left: '0',
      gap: '25px',
      [MEDIA_BREAKPOINTS.medium]: {
        gap: '0',
        height: '700px',
        '-webkit-overflow-scrolling': 'touch',
        scrollBehavior: 'smooth',
        overflowX: 'scroll',
      },
      [MEDIA_BREAKPOINTS.small]: {
        height: '450px',
      },
    },
    textWrapper: {
      position: 'absolute',
      bottom: '30px',
      left: '26px',
      zIndex: 2,
    },
    title: {
      color: colors.white,
      marginBottom: '15px',
    },
    link: {
      textDecoration: 'underline',
      color: colors.white,
      ':hover': {
        color: colors.secondary,
      },
    },
    linkWrapper: {
      display: 'flex',
      flexDirection: 'row',
    },
    linksWrapper: {
      display: 'flex',
      flexDirection: 'row',
    },
    pipe: {
      color: colors.white,
      margin: '0px 15px',
    },
    slider: {
      marginTop: '25px',
      display: 'flex',
      [MEDIA_BREAKPOINTS.large]: {
        display: 'none',
      },
    },

  });
};

const getImageWrapperStyle = (isHover: boolean, isSelected: boolean, isFirst: boolean) => {
  return StyleSheet.create({
    wrapper: {
      position: 'relative',
      height: '100%',
      width: isHover ? '540px' : '130px',
      transition: 'width 0.4s 0.1s ease-out',
      ...(isHover ? { flexShrink: 0 } : {}),
      [MEDIA_BREAKPOINTS.medium]: {
        display: 'inline-block',
        width: '600px',
        flexShrink: 0,
        paddingLeft: isFirst ? '15px' : '0',
        paddingRight: '15px',
      },
      [MEDIA_BREAKPOINTS.small]: {
        width: '310px',
      },
    },
    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: isHover ? '540px' : '0px',
      height: '100%',
      zIndex: 1,
      background: 'linear-gradient(0deg, #212121 0%, rgba(33, 33, 33, 0.00) 35.5%, rgba(33, 33, 33, 0.00) 100%)',
      transition: isHover ? 'opacity 0.15s 0.35s ease-in' : '',
      opacity: isHover ? 1 : 0,
      [MEDIA_BREAKPOINTS.medium]: {
        width: '600px',
        marginLeft: isFirst ? '15px' : '0',
        transition: 'none',
        opacity: isSelected ? 1 : 0,
      },
      [MEDIA_BREAKPOINTS.small]: {
        width: '310px',
      },
    },
    overlay2: {
      position: 'absolute',
      top: 0,
      left: 0,
      opacity: isHover ? 0 : 1,
      background: 'rgb(241, 230, 221, 0.5)',
      height: '700px',
      width: '100%',
      transition: !isHover ? 'opacity 0.25s 0.2s linear' : '',
      [MEDIA_BREAKPOINTS.medium]: {
        display: 'none',
      },
    },
  });
};

const getSliderStyle = (colors: Colors, isSelected: boolean, index: number) => {
  return StyleSheet.create({
    circle: {
      display: 'inline-block',
      height: '10px',
      width: '10px',
      backgroundColor: isSelected ? colors.darkGrey : '#DBDBDB',
      borderRadius: '50%',
      marginLeft: index === 0 ? '0' : '10px',
      [MEDIA_BREAKPOINTS.small]: {
        height: '8px',
        width: '8px',
        marginLeft: index === 0 ? '0' : '5px',
      },
    },
  });
};

export const ResizingCarousel: React.FunctionComponent<ResizingCarouselProps> = props => {
  const { images } = props;
  const { colors }: { colors: Colors } = useTheme();
  const imagesLenght = images?.length;
  const ref = useRef<HTMLDivElement>(null);
  const imageHeightCalculationMethods = createImageHeightCalculationMethods('100%_HEIGHT', '100%_HEIGHT', '100%_HEIGHT');
  const [hoverIndex, setHoverIndex] = useState(1);
  const [scrollE, setScrollE] = useState(false);
  const [touchPositionX, setTouchPositionX] = useState(0);
  const [touchPositionY, setTouchPositionY] = useState(0);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [lastScrollPosition, setLastScrollPosition] = useState(0);

  const styles = getStyles(colors);

  const largeFontSizes = {
    fontSizeSmall: TYPOGRAPHY_SIZE.LARGE,
    fontSizeMedium: TYPOGRAPHY_SIZE.LARGE,
    fontSizeLarge: TYPOGRAPHY_SIZE.LARGE,
  };

  const normalFontSizes = {
    fontSizeSmall: TYPOGRAPHY_SIZE.NORMAL,
    fontSizeMedium: TYPOGRAPHY_SIZE.NORMAL,
    fontSizeLarge: TYPOGRAPHY_SIZE.NORMAL,
  };

  const onTouchMove = useCallback((e: any) => {
    const currentScrollPosition = window.scrollX || ref?.current?.scrollLeft || 0;
    const selectedElement = document.getElementById(`image-${selectedImageIndex}`);
    const offset = selectedImageIndex === 0 ? 15 : 0;
    const selectedWidth = selectedElement ? selectedElement.offsetWidth - offset : 0;
    const changeTouchPositionX = e.changedTouches[0].clientX;
    const changeTouchPositionY = e.changedTouches[0].clientY;
    const isHorizontalSlide = Math.abs(changeTouchPositionX - touchPositionX) > Math.abs(changeTouchPositionY - touchPositionY);
    let coverSwipeIndex = 0;

    if (!isHorizontalSlide) {
      return;
    }
    e.preventDefault();
    if (scrollE) {
      return;
    }

    // scroll left
    if (ref?.current && changeTouchPositionX < touchPositionX && selectedImageIndex < imagesLenght) {
      if (currentScrollPosition - lastScrollPosition < 2 * selectedWidth && selectedImageIndex === imagesLenght - 1) { // scroll left in the last time
        ref.current.scrollLeft += currentScrollPosition - lastScrollPosition;
        coverSwipeIndex = imagesLenght;
      } else {
        ref.current.scrollLeft += selectedWidth;
        setSelectedImageIndex(prev => prev + 1);
        coverSwipeIndex = selectedImageIndex + 2;
      }
    } else if (ref?.current && changeTouchPositionX > touchPositionX && selectedImageIndex > 0) { // scroll right
      ref.current.scrollLeft -= selectedWidth;
      setSelectedImageIndex(prev => prev - 1);
      coverSwipeIndex = selectedImageIndex;
    }
    setScrollE(true);
    setLastScrollPosition(currentScrollPosition);
    setTouchPositionX(changeTouchPositionX);
    setTouchPositionY(changeTouchPositionY);
    if (window) {
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push({
        event: 'GTM event to GA',
        GA_event_label: `cover_number=[${coverSwipeIndex}]`,
        GA_event_action: 'cover_swipe',
      });
    }
    setTimeout(() => {
      setScrollE(false);
    }, 300);
  }, [imagesLenght, lastScrollPosition, scrollE, selectedImageIndex, touchPositionX, touchPositionY]);

  const onTouchStart = useCallback((e: any) => {
    setTouchPositionX(e.targetTouches[0].clientX);
    setTouchPositionY(e.targetTouches[0].clientY);
  }, []);


  useEffect(() => {
    const wrapperRef = ref.current;
    if (wrapperRef) {
      wrapperRef.addEventListener('touchstart', onTouchStart);
      wrapperRef.addEventListener('touchmove', onTouchMove);
    }
    return () => {
      return wrapperRef?.removeEventListener('touchmove', onTouchMove) && wrapperRef?.removeEventListener('touchstart', onTouchStart);
    };
  }, [onTouchMove, onTouchStart]);

  return (
    <ErrorBoundary>
      <div className={css(styles.root)}>
        <div className={css(styles.wrapper)} ref={ref}>
          {images.map((image, index) => {
            const { imageLink, title, links, ...imageProps } = image;
            const isHover = hoverIndex === index;
            const imageStyles = getImageWrapperStyle(isHover, selectedImageIndex === index, index === 0);
            return (
              <div key={`${title} ${index}`} id={`image-${index}`} className={css(imageStyles.wrapper)} onMouseEnter={() => setHoverIndex(index)}>
                <Link href={imageLink}>
                  <Image
                    {...imageProps}
                    imageHeightCalculationMethods={imageHeightCalculationMethods}
                    imageAspectRatios={{ large: { x: 9, y: 10 }, medium: { x: 9, y: 10 }, small: { x: 9, y: 10 } }}
                  />
                  <div className={css(imageStyles.overlay2)} />
                  <div className={css(imageStyles.overlay)}>
                    <div className={css(styles.textWrapper)}>
                      {title ? <P styles={semanticCSS(styles.title)} {...largeFontSizes}>{title}</P> : null}
                      <div className={css(styles.linksWrapper)}>
                        {links?.map((linkData, linkIndex) => {
                          return (
                            <div className={css(styles.linkWrapper)}>
                              <Link href={linkData.link}>
                                <P styles={semanticCSS(styles.link)} {...normalFontSizes}>{linkData.text}</P>
                              </Link>
                              {linkIndex === 0 && links.length === 2 && (
                              <P {...normalFontSizes} styles={semanticCSS(styles.pipe)}>|</P>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
        {imagesLenght && (
        <div className={css(styles.slider)}>
          {images?.map((value, index) => {
            const isSelected = selectedImageIndex === index;
            const circleStyle = getSliderStyle(colors, isSelected, index);
            return <div className={css(circleStyle.circle)} />;
          })}
        </div>
        )}
      </div>
    </ErrorBoundary>
  );
};
