import { ErrorReporter } from '../errorReporting/errorReporter.type';
import { MissingDataError } from '../errorReporting/errors/MissingDataError';
import { DataTransformationError } from '../errorReporting/errors/DataTransformationError';

let errorReporterInstance: ErrorReporter;
export const initStoreErrorReporter = (errorReporter: ErrorReporter) => {
    errorReporterInstance = errorReporter;
};

const isObject = (obj: any) => obj && typeof obj === 'object';
const hasKey = (obj: Record<string, any>, key: string) => key in obj;

const isNull = (fieldValue: any) => {
  return fieldValue === null;
};

const isUndefined = (fieldValue: any) => {
    return fieldValue === undefined;
};

const isEmptyArrayOrObject = (fieldValue: any) => {
    return typeof fieldValue === 'object' ? !!(fieldValue && Object.keys(fieldValue).length === 0) : false;
};

const isEmptyString = (fieldValue: any) => {
    return typeof fieldValue === 'string' ? fieldValue === '' : false;
};

const isFieldValueNaN = (fieldValue: any) => {
    // eslint-disable-next-line no-restricted-globals
    return typeof fieldValue === 'number' && isNaN(fieldValue);
};

export const isFalsyValue = (fieldValue: any) => {
    return isNull(fieldValue) || isUndefined(fieldValue) || isEmptyString(fieldValue) || isFieldValueNaN(fieldValue) || isEmptyArrayOrObject(fieldValue);
};


class MissingValue {
    breadCrumbs = '';

    constructor(breadCrumbs: string) {
        this.breadCrumbs = breadCrumbs;
    }

    getBreadCrumbs() {
        return this.breadCrumbs;
    }
}

const getUndefined: Function = (breadCrumbs: string) => {
    const Undefined: any = new Proxy(new MissingValue(breadCrumbs), {
        get(target: any, name: string) {
            return name in target ? target[name] : Undefined;
        },
    });
    return Undefined;
};

export function getSafeObject(obj: { [key: string]: any }, breadCrumbs = 'root'): any {
    return new Proxy(obj, {
        get(target, name) {
            if (name === '__target__') {
                return target;
            }
            /* eslint-disable-next-line */
            return hasKey(target, name as string) && !isFalsyValue(target[name as string])
                ? isObject(target[name as string])
                    ? getSafeObject(target[name as string], `${breadCrumbs}->${name as string}`)
                    : target[name as string]
                : getUndefined(`${breadCrumbs}->${name as string}`);
        },
    });
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const reportMissingMandatoryData = (breadCrumbs: string) => {
    const error = new MissingDataError(breadCrumbs);
    errorReporterInstance.reportWarning(`${error.name} : ${error.message}`);
};

const reportExceptionForDataCallback = (message: string) => {
    errorReporterInstance.reportException(new DataTransformationError(message));
};

const handleMissingMandatoryData = (breadCrumbs: string, defaultValue: any) => {
    // reportMissingMandatoryData(breadCrumbs); // TODO: enable missing mandatory fields reporting to Datadog
    return defaultValue;
};

const defaultCbForValue = (value: any) => (isObject(value) ? (value.__target__ || value) : value);

export const mandatory = (fieldValue: any, defaultValue: any, cbForValue: Function = defaultCbForValue) => {
    try {
        return fieldValue instanceof MissingValue ? handleMissingMandatoryData(fieldValue.getBreadCrumbs(), defaultValue) : cbForValue(fieldValue);
    } catch (e) {
        reportExceptionForDataCallback(e);
        return defaultValue;
    }
};

export const nonMandatory = (fieldValue: any, cbForValue: Function = defaultCbForValue) => {
    try {
        return fieldValue instanceof MissingValue ? null : cbForValue(fieldValue);
    } catch (e) {
        reportExceptionForDataCallback(e);
        return null;
    }
};

const handleInvalidData = (fieldName: string, value: any, defaultValue: any) => {
  // errorReporterInstance.reportWarning(`InvalidData: ${fieldName} -> ${value}`); // TODO: enable invalid fields reporting to Datadog
  return defaultValue;
};

export const valid = (fieldName: string, value: any, defaultValue: any, validateFn: (value: any) => boolean) => {
  try {
    return validateFn(value) ? value : handleInvalidData(fieldName, value, defaultValue);
  } catch (e) {
    reportExceptionForDataCallback(e);
    return defaultValue;
  }
};
