export const normalizeCss = () => ({
  a: {
    textDecoration: 'none',
  },
  figure: {
    margin: 0,
  },
  ul: {
    margin: 0,
    padding: 0,
  },
  li: {
    margin: 0,
    padding: 0,
  },
  img: {
    borderStyle: 'none',
  },
  h1: {
    fontSize: '2em',
    margin: '0.67em 0',
    marginBlockEnd: 0,
    marginBlockStart: 0,
  },
  hr: {
    boxSizing: 'content-box',
    height: 0,
  },
  pre: {
    fontFamily: 'monospace, monospace',
    fontSize: '1em',
  },
  'abbr[title]': {
    borderBottom: 'none',
    textDecoration: 'underline',
  },
  strong: {
    fontWeight: 'bold',
  },
  b: {
    fontWeight: 'bolder',
  },
  samp: {
    fontFamily: 'monospace, monospace',
    fontSize: '1em',
  },
  kbd: {
    fontFamily: 'monospace, monospace',
    fontSize: '1em',
  },
  code: {
    fontFamily: 'monospace, monospace',
    fontSize: '1em',
  },
  small: {
    fontSize: '80%',
  },
  sup: {
    fontSize: '75%',
    lineHeight: 0,
    position: 'relative',
    verticalAlign: 'baseline',
    top: '-0.5em',
  },
  sub: {
    fontSize: '75%',
    lineHeight: 0,
    position: 'relative',
    verticalAlign: 'baseline',
    bottom: '-0.25em',
  },
  textarea: {
    fontFamily: 'inherit',
    fontSize: '100%',
    lineHeight: '1.15',
    margin: 0,
  },
  select: {
    fontFamily: 'inherit',
    fontSize: '100%',
    lineHeight: '1.15',
    margin: 0,
    textTransform: 'none',
  },
  button: {
    fontFamily: 'inherit',
    fontSize: '100%',
    lineHeight: '1.15',
    margin: 0,
    overflow: 'visible',
    textTransform: 'none',
    padding: 0,
    border: 'unset',
    backgroundColor: 'unset',
    ':focus': {
      outline: 0,
    },
  },
  input: {
    fontFamily: 'inherit',
    fontSize: '100%',
    lineHeight: '1.15',
    margin: 0,
  },
  optgroup: {
    fontFamily: 'inherit',
    fontSize: '100%',
    lineHeight: '1.15',
    margin: 0,
  },
  'button:-moz-focusring': {
    outline: '1px dotted ButtonText',
  },
  '[type="button"]:-moz-focusring': {
    outline: '1px dotted ButtonText',
  },
  '[type="submit"]:-moz-focusring': {
    outline: '1px dotted ButtonText',
  },
  '[type="reset"]:-moz-focusring': {
    outline: '1px dotted ButtonText',
  },
  fieldset: {
    padding: '0.35em 0.75em 0.625em',
  },
  legend: {
    padding: 0,
  },
  progress: {
    verticalAlign: 'baseline',
  },
  details: {
    display: 'block',
  },
  summary: {
    display: 'list-item',
  },
  template: {
    display: 'none',
  },
  '[hidden]': {
    display: 'none',
  },
  section: {
    width: '-moz-available',
  },
  'body[amp-x-player-experiment="A"] #playerExperiment-A': {
    display: 'block',
  },
  'body[amp-x-player-experiment="A"] #playerExperiment-B': {
    display: 'none',
  },
  'body[amp-x-player-experiment="A"] #playerExperiment-C': {
    display: 'none',
  },
  'body[amp-x-player-experiment="B"] #playerExperiment-A': {
    display: 'none',
  },
  'body[amp-x-player-experiment="B"] #playerExperiment-B': {
    display: 'block',
  },
  'body[amp-x-player-experiment="B"] #playerExperiment-C': {
    display: 'none',
  },
  'body[amp-x-player-experiment="C"] #playerExperiment-A': {
    display: 'none',
  },
  'body[amp-x-player-experiment="C"] #playerExperiment-B': {
    display: 'none',
  },
  'body[amp-x-player-experiment="C"] #playerExperiment-C': {
    display: 'block',
  },
});
