import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { RATIO, TypographySize } from 'mm-theme-configuration/dist/consts';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { createImageWidths } from '../../partials/image/image.utils';
import { useTheme } from '../../../theming/useTheme';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { baseUnit } from '../../../theming/baseUnitDefinition';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { HorizontalCard } from '../../partials/cards/horizontalCard/HorizontalCard';
import { componentConfigThemeKey as horizontalCardConfigThemeKey, HorizontalCardThemeProps } from '../../partials/cards/horizontalCard/horizontalCard.theme';
import { getLayoutComponentPadding, LayoutComponentBaseProps } from '../pageLayouts/layout.utils';
import { SectionDividerWithIcon } from '../divider/SectionDividerWithIcon';

export const componentConfigThemeKey = 'fourHC';

interface FourHCDataProps extends LayoutComponentBaseProps {
    cards: Array<CardComponentDataProps>;
    showDivider?: boolean;
    dividerIcon?: React.FunctionComponent;
}

export interface FourHCThemeProps {
    largeHorizontalGap: number;
    mediumHorizontalGap: number;
    smallHorizontalGap: number;
    titleFontSizeLarge: TypographySize;
    titleFontSizeMedium: TypographySize;
    titleFontSizeSmall: TypographySize;
    metadataFontSizeLarge: TypographySize;
    metadataFontSizeMedium: TypographySize;
    metadataFontSizeSmall: TypographySize;
    descriptionFontSizeLarge: TypographySize;
    descriptionFontSizeMedium: TypographySize;
    descriptionFontSizeSmall: TypographySize;
    cardProportionsLarge: RATIO;
    cardProportionsMedium: RATIO;

}

const createStyle = (themeProps: FourHCThemeProps) => {
  const { largeHorizontalGap, mediumHorizontalGap, smallHorizontalGap } = themeProps;
  return StyleSheet.create({
    wrapper: {
      display: 'grid',
      [MEDIA_BREAKPOINTS.large]: {
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto',
        gridRowGap: `${largeHorizontalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto',
        gridRowGap: `${mediumHorizontalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto',
        gridRowGap: `${smallHorizontalGap * baseUnit}px`,
      },
    },
  });
};

const imageWidths = createImageWidths(180, 360, 540);

export const FourHC: React.FunctionComponent<FourHCDataProps> = props => {
  const { cards, paddingFactors, showDivider, dividerIcon } = props;
  const paddingStyle = getLayoutComponentPadding(paddingFactors);
  const themeProps = useTheme<FourHCThemeProps>(componentConfigThemeKey);
  const {
    titleFontSizeLarge,
    titleFontSizeMedium,
    titleFontSizeSmall,
    metadataFontSizeLarge,
    metadataFontSizeMedium,
    metadataFontSizeSmall,
    descriptionFontSizeLarge,
    descriptionFontSizeMedium,
    descriptionFontSizeSmall,
    cardProportionsLarge,
    cardProportionsMedium,
  } = themeProps;
  const style = createStyle(themeProps);
  const themeContextVC = useThemeOverride<HorizontalCardThemeProps>({
    titleFontSizeLarge,
    titleFontSizeMedium,
    titleFontSizeSmall,
    metadataFontSizeLarge,
    metadataFontSizeMedium,
    metadataFontSizeSmall,
    descriptionFontSizeLarge,
    descriptionFontSizeMedium,
    descriptionFontSizeSmall,
    cardProportionsLarge,
    cardProportionsMedium,
  }, horizontalCardConfigThemeKey);
  return (
    <ErrorBoundary>
      {cards && cards.length > 0
            && (
            <div className={css(paddingStyle, style.wrapper)}>
              <ThemeContext.Provider value={themeContextVC}>
                { cards.slice(0, 4).map((card, index) => {
                  return (
                    <React.Fragment key={index}>
                      {showDivider && <SectionDividerWithIcon iconComponent={dividerIcon} />}
                      {card && <HorizontalCard {...card} imageWidths={imageWidths} />}
                    </React.Fragment>
                  );
                })}
              </ThemeContext.Provider>
            </div>
            ) }
    </ErrorBoundary>
  );
};
