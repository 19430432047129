import * as React from 'react';

interface AMPMediaValidatorProps {
  mediaId: any;
}

const mediaIdValidator = (mediaId: any) => {
  return (typeof mediaId === 'string') && (mediaId.trim() !== '');
};

export const AMPMediaValidator: React.FunctionComponent<AMPMediaValidatorProps> = props => {
  const { mediaId, children } = props;
  const isMediaIdValid = mediaIdValidator(mediaId);
  return <React.Fragment>{isMediaIdValid && children}</React.Fragment>;
};
