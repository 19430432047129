import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { TypographySize, RATIO, SEMANTIC_TAG } from 'mm-theme-configuration/dist/consts';

import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import {
  OnTopCard,
  OnTopCardComponentThemeProps,
  componentConfigThemeKey as onTopCardConfigThemeKey,
} from '../../partials/cards/onTopCard/OnTopCard';
import { useTheme } from '../../../theming/useTheme';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { baseUnit } from '../../../theming/baseUnitDefinition';
import { createImageHeightCalculationMethods, createImageWidths } from '../../partials/image/image.utils';
import { LayoutComponentBaseProps, getLayoutComponentPadding } from '../pageLayouts/layout.utils';

export const componentConfigThemeKey = 'oneTwoOTCHorizontal';

export interface OneTwoOTCHorizontalThemeProps {
    largeVerticalGap: number;
    mediumVerticalGap: number;
    mediumHorizontalGap: number;
    smallHorizontalGap: number;
    mainCardTitleFontSizeLarge: TypographySize;
    mainCardTitleFontSizeMedium: TypographySize;
    mainCardTitleFontSizeSmall: TypographySize;
    secondaryCardsTitleFontSizeLarge: TypographySize;
    secondaryCardsTitleFontSizeMedium: TypographySize;
    secondaryCardsTitleFontSizeSmall: TypographySize;
    aspectRatioLarge: RATIO;
    aspectRatioSmall: RATIO;
    titleSemanticTag: SEMANTIC_TAG;
    showMainCategoryInLargeScreen: boolean;
    showMainCategoryInMediumScreen: boolean;
    showMainCategoryInSmallScreen: boolean;
}

interface OneTwoOTCHorizontalDataProps extends LayoutComponentBaseProps {
    cards: Array<CardComponentDataProps> | null;
}

const getStyles = (theme: OneTwoOTCHorizontalThemeProps) => {
  const { largeVerticalGap, mediumHorizontalGap, mediumVerticalGap, smallHorizontalGap } = theme;
  return StyleSheet.create({
    container: {
      [MEDIA_BREAKPOINTS.large]: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridColumnGap: `${largeVerticalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        display: 'grid',
        gridTemplateRows: '1fr auto',
        gridRowGap: `${mediumHorizontalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        display: 'block',
      },
    },
    lastCardsContainer: {
      [MEDIA_BREAKPOINTS.large]: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridColumnGap: `${largeVerticalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridColumnGap: `${mediumVerticalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        display: 'block',
      },
    },
    secondAndThirdCards: {
      [MEDIA_BREAKPOINTS.large]: {
        height: '100%',
      },
      [MEDIA_BREAKPOINTS.small]: {
        marginTop: `${smallHorizontalGap * baseUnit}px`,
      },
    },
  });
};

const secondAndThirdImageHeightCalculationMethods = createImageHeightCalculationMethods('ASPECT_RATIO', 'ASPECT_RATIO', '100%_HEIGHT');

const addImageHeightCalculationMethodsProp = (cardProps: CardComponentDataProps) => ({
  ...cardProps,
  imageHeightCalculationMethods: secondAndThirdImageHeightCalculationMethods,
});

const imageWidthsMainCard = createImageWidths(360, 720, 720);
const imageWidthsMinorCards = createImageWidths(360, 360, 720);

export const OneTwoOTCHorizontal: React.FunctionComponent<OneTwoOTCHorizontalDataProps> = props => {
  const { paddingFactors } = props;
  const themeProps = useTheme<OneTwoOTCHorizontalThemeProps>(componentConfigThemeKey);
  const { mainCardTitleFontSizeLarge,
    mainCardTitleFontSizeMedium,
    mainCardTitleFontSizeSmall,
    secondaryCardsTitleFontSizeLarge,
    secondaryCardsTitleFontSizeMedium,
    secondaryCardsTitleFontSizeSmall,
    aspectRatioSmall,
    aspectRatioLarge,
    titleSemanticTag,
    showMainCategoryInLargeScreen,
    showMainCategoryInMediumScreen,
    showMainCategoryInSmallScreen,
  } = themeProps;
  const styles = getStyles(themeProps);
  const paddingStyle = getLayoutComponentPadding(paddingFactors);
  const { cards } = props;
  const topCard = cards && cards[0];
  const secondCard = cards && addImageHeightCalculationMethodsProp(cards[1]);
  const thirdCard = cards && addImageHeightCalculationMethodsProp(cards[2]);
  const themeContextFirstCard = useThemeOverride<OnTopCardComponentThemeProps>({
    titleFontSizeLarge: mainCardTitleFontSizeLarge,
    titleFontSizeMedium: mainCardTitleFontSizeMedium,
    titleFontSizeSmall: mainCardTitleFontSizeSmall,
    titleSemanticTag,
    aspectRatioSmall,
    aspectRatioLarge,
    showMainCategoryInLargeScreen,
    showMainCategoryInMediumScreen,
    showMainCategoryInSmallScreen,
  }, onTopCardConfigThemeKey);
  const themeContextSecondAndThirdCards = useThemeOverride<OnTopCardComponentThemeProps>({
    titleFontSizeLarge: secondaryCardsTitleFontSizeLarge,
    titleFontSizeMedium: secondaryCardsTitleFontSizeMedium,
    titleFontSizeSmall: secondaryCardsTitleFontSizeSmall,
    showMainCategoryInLargeScreen,
    showMainCategoryInMediumScreen,
    showMainCategoryInSmallScreen,
  }, onTopCardConfigThemeKey);
  return (
    <ErrorBoundary>
      {cards && cards.length > 0
                && (
                <div className={css(paddingStyle, styles.container)}>
                  <ThemeContext.Provider value={themeContextFirstCard}>
                    { topCard && <OnTopCard {...topCard} imageWidths={imageWidthsMainCard} /> }
                  </ThemeContext.Provider>
                  <div className={css(styles.lastCardsContainer)}>
                    <ThemeContext.Provider value={themeContextSecondAndThirdCards}>
                      <div className={css(styles.secondAndThirdCards)}>
                        { secondCard && <OnTopCard {...secondCard} imageWidths={imageWidthsMinorCards} /> }
                      </div>
                      <div className={css(styles.secondAndThirdCards)}>
                        { thirdCard && <OnTopCard {...thirdCard} imageWidths={imageWidthsMinorCards} /> }
                      </div>
                    </ThemeContext.Provider>
                  </div>
                </div>
                )}
    </ErrorBoundary>
  );
};
