import * as React from 'react';
import { css } from 'aphrodite/no-important';
import { BaseUnit } from '../../../../theming/baseUnitDefinition';

export const footerSectionPadding = (baseUnit: BaseUnit) => ({ padding: `${0.5 * baseUnit}px 0` });

export const generateSeparatorSections = (separatorStyle: object) => (acc: React.ReactElement<any>[], section: React.ReactElement<any> | null, index: number, arr: (React.ReactElement<any> | null)[]) => {
  const res = section as React.ReactElement<any>;
  return index < arr.length - 1 ? [...acc, res, <div key={index} className={css(separatorStyle)} />] : [...acc, res];
};
