import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { useTheme } from '../../../theming/useTheme';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import {
  PostRecommendationsDataProps,
  PostRecommendationsThemeProps,
  useRecommendationScrollTrigger,
  getSectionStyle,
  createPlaceholders,
} from './PostRecommendations.utils';
import { PostRecommendationsHeader } from './PostRecommendationsHeader';
import { OnTopCard } from '../../partials/cards/onTopCard/OnTopCard';
import { EventProvider } from '../../../analytics/analytics';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { createImageWidths } from '../../partials/image/image.utils';

const getStyle = (baseUnit: BaseUnit) => StyleSheet.create({
  card: {
    marginTop: `${baseUnit * 0.5}px`,
  },
});

const imageWidths = createImageWidths(540, 720, 720);

export const OnTopCardsPostRecommendations: React.FunctionComponent<PostRecommendationsDataProps> = props => {
  const { sectionName, sectionCards, onScrolledIntoView } = props;
  const { baseUnit } = useTheme<PostRecommendationsThemeProps>();
  const memoizedOnScrolledIntoView = React.useCallback(onScrolledIntoView, []);
  const wrapper = useRecommendationScrollTrigger(memoizedOnScrolledIntoView);
  const style = getStyle(baseUnit);
  const sectionStyle = getSectionStyle(baseUnit);

  return (
    <ErrorBoundary>
      <section ref={wrapper} className={css(sectionStyle.section)}>
        <EventProvider category="Recommendations" action="click">
          <PostRecommendationsHeader header={sectionName} />
          {
            sectionCards && sectionCards.length !== 0
              ? sectionCards.map((card, index) => (
                <div className={css(style.card)} key={index}>
                  <OnTopCard {...card} imageWidths={imageWidths} />
                </div>
              ))
              : createPlaceholders(4)
          }
        </EventProvider>
      </section>
    </ErrorBoundary>
  );
};
