import { URLParser } from '../utils/URLParser';

export const referrerIsSameAsSite = () => {
  return (traffic) => {
    if (traffic.referrer === '') {
      return false;
    }
    const urlObject = new URLParser(traffic.url);
    const referrerObject = new URLParser(traffic.referrer);
    return urlObject.hostname === referrerObject.hostname;
  };
};
