import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useTheme } from '../../../theming/useTheme';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { semanticCSS, Span } from '../../../typography/semanticTags';
import { BlockWidths } from '../../components/pageLayouts/BlockTypes.utils';
import { AdditionalStyleSheet, Style } from '../../components.utils';
import { createBlockStyle, PaddingOverride } from '../blockMutualStyle';
import { Button } from '../../components/buttons/Button';
import { ArticleBlockThemeProps, componentConfigurationKey } from '../ArticleBlock.theme';

export interface SlideshowTopBarProps {
  prevText: string;
  nextText: string;
  ofText: string;
  slideNumber: number;
  totalSlides: number;
  blockWidths: BlockWidths;
  overrideBlockPadding?: PaddingOverride;
  onClickPrev: () => void;
  onClickNext: () => void;
  scrollToSlideshowTopBar: React.MutableRefObject<null>;
  nextUrl: string;
  prevUrl: string;
}

const getStyles = (baseUnit: BaseUnit, slideNumberTextColor: string, dividerColor: string, blockStyle: Style) => {
  return StyleSheet.create({
    wrapper: {
      ...blockStyle,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: `${baseUnit}px auto 0 auto`,
      position: 'relative',
    },
    divider: {
      height: '1px',
      backgroundColor: dividerColor,
      width: '100%',
      margin: `0 ${baseUnit / 2}px`,
    },
    slideNumber: {
      whiteSpace: 'nowrap',
      color: slideNumberTextColor,
    },
    anchor: {
      position: 'absolute',
      top: '-25px',
    },
    buttonWrapper: {
      position: 'relative',
    },
  });
};

const getButtonStyle = (baseUnit: BaseUnit, shouldShow: boolean, backgroundColor: string, disabledBackgroundColor: string, textColor: string, disabledTextColor: string) => {
  return StyleSheet.create({
    style: {
      padding: `${baseUnit / 2}px ${baseUnit}px`,
      backgroundColor: shouldShow ? backgroundColor : disabledBackgroundColor,
      color: shouldShow ? textColor : disabledTextColor,
    },
  }) as AdditionalStyleSheet;
};

const getLinkStyle = () => (StyleSheet.create({
  style: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'none',
  },
}).style);

export const SlideshowTopBar: React.FunctionComponent<SlideshowTopBarProps> = ({
  slideNumber,
  totalSlides,
  prevText,
  nextText,
  ofText,
  blockWidths,
  overrideBlockPadding,
  onClickNext,
  onClickPrev,
  scrollToSlideshowTopBar,
  prevUrl,
  nextUrl,
}) => {
  const themeProps = useTheme<ArticleBlockThemeProps>(componentConfigurationKey);
  const { baseUnit, slideshowButtonBackgroundColor, slideshowButtonDisabledBackgroundColor, slideshowButtonTextColor, slideshowButtonDisabledTextColor, slideshowNumberColor, slideshowDividerColor } = themeProps;
  const blockStyle = createBlockStyle(blockWidths, overrideBlockPadding);
  const styles = getStyles(baseUnit, slideshowNumberColor, slideshowDividerColor, blockStyle);
  const prevStyle = getButtonStyle(baseUnit, !!prevUrl, slideshowButtonBackgroundColor, slideshowButtonDisabledBackgroundColor, slideshowButtonTextColor, slideshowButtonDisabledTextColor);
  const nextStyle = getButtonStyle(baseUnit, !!nextUrl, slideshowButtonBackgroundColor, slideshowButtonDisabledBackgroundColor, slideshowButtonTextColor, slideshowButtonDisabledTextColor);
  const linkStyle = getLinkStyle();

  return (
    <div className={css(styles.wrapper)}>
      <div className={css(styles.anchor)} ref={scrollToSlideshowTopBar} />
      <div className={css(styles.buttonWrapper)}>
        {/* this <a> tag is added for SEO reasons (internal linking) */}
        <a className={css(linkStyle)} href={prevUrl}>{prevText}</a>
        <Button additionalStyle={prevStyle} disabled={!prevUrl} onClick={onClickPrev} label={prevText} />
      </div>
      <div className={css(styles.divider)} />
      {slideNumber !== null
      && <Span styles={semanticCSS(styles.slideNumber)}>{`${slideNumber} ${ofText} ${totalSlides}`}</Span>}
      <div className={css(styles.divider)} />
      <div className={css(styles.buttonWrapper)}>
        {/* this <a> tag is added for SEO reasons (internal linking) */}
        <a className={css(linkStyle)} href={nextUrl}>{nextText}</a>
        <Button additionalStyle={nextStyle} disabled={!nextUrl} onClick={onClickNext} label={nextText} />
      </div>
    </div>
  );
};
