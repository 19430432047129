import { RATIO, SEMANTIC_TAG, TypographySize } from 'mm-theme-configuration/dist/consts';
import { TranslatedFontSize } from '../../../../theming/fontSizeTranslator';

export const componentConfigThemeKey = 'horizontalCard';

export interface HorizontalCardThemeProps {
  cardBackgroundColor: string;
  cardTitleColorTypeNormal: string;
  cardTitleColorTypeHover: string;
  cardTitleColorTypeActive: string;
  borderRadius: number;
  boxShadowTypeNormal: string;
  boxShadowTypeActive: string;
  boxShadowTypeHover: string;
  borderWidthTypeNormal: number;
  borderWidthTypeHover: number;
  borderWidthTypeActive: number;
  borderColorTypeNormal: string;
  borderColorTypeHover: string;
  borderColorTypeActive: string;
  aspectRatioLarge: RATIO;
  aspectRatioMedium: RATIO;
  aspectRatioSmall: RATIO;
  titleFontSizeLarge: TypographySize;
  titleFontSizeMedium: TypographySize;
  titleFontSizeSmall: TypographySize;
  shouldReverseImageAlignment: boolean;
  descriptionFontSizeLarge: TypographySize;
  descriptionFontSizeMedium: TypographySize;
  descriptionFontSizeSmall: TypographySize;
  pipeColor: string;
  showAuthorInLargeScreen: boolean;
  showAuthorInMediumScreen: boolean;
  showAuthorInSmallScreen: boolean;
  showDescriptionInLargeScreen: boolean;
  showDescriptionInMediumScreen: boolean;
  showDescriptionInSmallScreen: boolean;
  showDateInLargeScreen: boolean;
  showDateInMediumScreen: boolean;
  showDateInSmallScreen: boolean;
  metadataFontSizeLarge: TypographySize;
  metadataFontSizeMedium: TypographySize;
  metadataFontSizeSmall: TypographySize;
  showMetadataOnBottom: boolean;
  metadataMarginTop: number;
  authorColor: string;
  dateColor: string;
  descriptionColor: string;
  wrapperGapLarge: number;
  wrapperGapMedium: number;
  wrapperGapSmall: number;
  verticalGapLarge: number;
  verticalGapMedium: number;
  verticalGapSmall: number;
  horizontalGapLarge: number;
  horizontalGapMedium: number;
  horizontalGapSmall: number;
  titleLineHeightLarge: number;
  titleLineHeightMedium: number;
  titleLineHeightSmall: number;
  titleSemanticTag: SEMANTIC_TAG;
  metadataSemanticTag: SEMANTIC_TAG;
  cardProportionsLarge: RATIO;
  cardProportionsMedium: RATIO;
  cardProportionsSmall: RATIO;
  descriptionSemanticTag: SEMANTIC_TAG;
  showMainCategoryInLargeScreen: boolean;
  showMainCategoryInMediumScreen: boolean;
  showMainCategoryInSmallScreen: boolean;
  mainCategorySemanticTag: SEMANTIC_TAG;
  mainCategoryFontSizeLarge: TypographySize;
  mainCategoryFontSizeMedium: TypographySize;
  mainCategoryFontSizeSmall: TypographySize;
  mainCategoryTextColor: string;
  mainCategoryBackgroundColor: string;
  mainCategoryVerticalGapLarge: number;
  mainCategoryVerticalGapMedium: number;
  mainCategoryVerticalGapSmall: number;
  mainCategoryHorizontalGapLarge: number;
  mainCategoryHorizontalGapMedium: number;
  mainCategoryHorizontalGapSmall: number;
  fontSizesStyles: TranslatedFontSize;
  numberOfLinesForTitle: number;
  numberOfLinesForDescription: number;
  numberOfLinesForAuthors: number;
  isUpdatedAtTimestamp: boolean;
  isMainCategoryEnabled: boolean;
  isClickableLabels: boolean;
  showImageHeightByAspectRatio: boolean;
}
