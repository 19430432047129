import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Breadcrumb } from '../MultiLayerBreadcrumbs';
import { BreadcrumbItem } from './BreadcrumbItem';

interface BreadcrumbModalProps {
  breadcrumbs: Breadcrumb[];
  linkStyle: any;
  listStyle: any;
  modalRef: React.RefObject<HTMLUListElement>;
}

const createStyle = () => {
  return StyleSheet.create({
    modalLink: {
      display: 'list-item',
    },
    modalContainer: {
      top: '40px',
      padding: '5px 15px',
      backgroundColor: 'white',
      borderRadius: '5px',
      boxShadow:
        '0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12), 0 11px 15px -7px rgba(0,0,0,.2)',
      minWidth: '300px',
      position: 'absolute',
      zIndex: 40,
    },
  });
};

export const BreadcrumbModal = ({ modalRef, breadcrumbs, linkStyle, listStyle }: BreadcrumbModalProps) => {
  const style = createStyle();

  return (
    <ul className={css(style.modalContainer)} ref={modalRef}>
      {breadcrumbs.map(({ path, displayName }) => {
        return (
          <li
            className={css(listStyle, style.modalLink)}
            key={displayName}
          >
            <BreadcrumbItem path={path} linkStyle={css(linkStyle)} displayName={displayName} />
          </li>
        );
      })}
    </ul>
  );
};
