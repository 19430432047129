import * as React from 'react';
import { RATIO, TypographySize, SEMANTIC_TAG } from 'mm-theme-configuration/dist/consts';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { SixHC } from './SixHC';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { componentConfigThemeKey as horizontalCardConfigThemeKey, HorizontalCardThemeProps } from '../../partials/cards/horizontalCard/horizontalCard.theme';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { createImageWidths } from '../../partials/image/image.utils';
import { useTheme } from '../../../theming/useTheme';

interface SixHCFourVCDataProps {
    cards: Array<CardComponentDataProps>;
}

export const componentConfigThemeKey = 'sixHCForTwoThirds';

export interface SixHCForTwoThirdsThemeProps {
  aspectRatioLarge: RATIO;
  aspectRatioMedium: RATIO;
  aspectRatioSmall: RATIO;
  titleFontSizeLarge: TypographySize;
  titleFontSizeMedium: TypographySize;
  titleFontSizeSmall: TypographySize;
  metadataFontSizeLarge: TypographySize;
  metadataFontSizeMedium: TypographySize;
  metadataFontSizeSmall: TypographySize;
  titleSemanticTag: SEMANTIC_TAG;
  metadataSemanticTag: SEMANTIC_TAG;
  showDescriptionInLargeScreen: boolean;
  showDescriptionInMediumScreen: boolean;
  showDescriptionInSmallScreen: boolean;
}
const imageWidths = createImageWidths(180, 360, 360);

export const SixHCForTwoThirds: React.FunctionComponent<SixHCFourVCDataProps> = props => {
  const { cards } = props;
  const themeProps = useTheme<SixHCForTwoThirdsThemeProps>(componentConfigThemeKey);
  const themeContextHC = useThemeOverride<HorizontalCardThemeProps>(themeProps, horizontalCardConfigThemeKey);
  return (
    <ErrorBoundary>
      {cards && cards.length > 0
      && (
      <ThemeContext.Provider value={themeContextHC}>
        <SixHC cards={cards} paddingFactors={{ large: 0, medium: 0, small: 0 }} imageWidths={imageWidths} />
      </ThemeContext.Provider>
      )}
    </ErrorBoundary>
  );
};
