import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { NetworkLinkButton } from './NetworkLinkButton';
import { getListByAlphabetOrder, Link as LinkType } from '../networkLinks/networkLinks.utils';
import { networkLinkButtonConfigThemeKey, NetworkLinkButtonThemeProps, networkLinkButtonListConfigThemeKey } from './NetworkLinkButton.theme';
import { useTheme } from '../../../theming/useTheme';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { AdditionalStyleSheet } from '../../components.utils';

const getStyles = () => {
  return StyleSheet.create({
    list: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      gap: '5px',
      listStyle: 'none',
      margin: 0,
      padding: '10px 0',
    },
  });
};

interface NetworkLinkButtonListProps {
  links: LinkType[];
  additionalStyle?: AdditionalStyleSheet;
}

export const NetworkLinkButtonList: React.FunctionComponent<NetworkLinkButtonListProps> = props => {
  const networkLinkButtonListTheme = useTheme<NetworkLinkButtonThemeProps>(networkLinkButtonListConfigThemeKey);
  const networkLinkButtonOverride = useThemeOverride<NetworkLinkButtonThemeProps>(networkLinkButtonListTheme, networkLinkButtonConfigThemeKey);
  const { additionalStyle, links = [] } = props;
  const styles = getStyles();

  if (!links.length) {
    return null;
  }

  const sortedLinks = getListByAlphabetOrder(links);
  return (
    <ErrorBoundary>
      <ul className={`${css(styles.list)} ${css(additionalStyle?.style)}`}>
        {sortedLinks.map((link, index) => (
          <li key={index}>
            <ThemeContext.Provider value={networkLinkButtonOverride}>
              <NetworkLinkButton {...link} />
            </ThemeContext.Provider>
          </li>
        ))}
      </ul>
    </ErrorBoundary>
  );
};
