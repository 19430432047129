import * as React from 'react';
import { css } from 'aphrodite/no-important';
import { Helmet } from 'react-helmet';
import { AMPMediaBaseProps } from './AMPMedia.utils';

export const AMPYoutube: React.FunctionComponent<AMPMediaBaseProps> = props => {
  const { mediaId, additionalStyle } = props;
  return (
    <React.Fragment>
      <Helmet>
        <script async={undefined} custom-element="amp-youtube" src="https://cdn.ampproject.org/v0/amp-youtube-0.1.js" />
      </Helmet>
      <figure className={css(additionalStyle.style)}>
        {/*
         // @ts-ignore TS2339 */}
        <amp-youtube data-videoid={mediaId} layout="responsive" width="480" height="270" />
      </figure>
    </React.Fragment>
  );
};
