import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { ErrorBoundary } from '../errorHandling/ErrorBoundary';
import { MandatoryAdditionalStyle } from '../components.utils';
import { BlocksPaddingOverride, BlockWidths } from '../components/pageLayouts/BlockTypes.utils';
import { createBlockStyle } from './blockMutualStyle';

interface RawHtmlContentComponentProps extends MandatoryAdditionalStyle{
  html: string;
  dataId?: string;
  defaultBlocksWidths: BlockWidths;
  blocksPaddingOverride?: BlocksPaddingOverride;
}

const getWrapperStyle = () => StyleSheet.create({
  style: {
    width: '100%',
  },
}).style;

const getBlockStyle = (defaultBlocksWidths: BlockWidths, blocksPaddingOverride?: BlocksPaddingOverride) => (StyleSheet.create({
  style: {
    ...createBlockStyle(defaultBlocksWidths, blocksPaddingOverride),
  },
}).style);

export const RawHtmlContent: React.FunctionComponent<RawHtmlContentComponentProps> = props => {
  const { html, additionalStyle, dataId, defaultBlocksWidths, blocksPaddingOverride } = props;
  const wrapperStyle = getWrapperStyle();
  const blockStyle = getBlockStyle(defaultBlocksWidths, blocksPaddingOverride);

  return (
    <ErrorBoundary>
      <div
        className={css(blockStyle, wrapperStyle, additionalStyle.style)}
        data-mm-id={dataId}
        dangerouslySetInnerHTML={{ __html: html }} // eslint-disable-line
      />
    </ErrorBoundary>
  );
};
