import * as React from 'react';

export interface Reporter {
  reportException: (err: Error, additionalData?: object) => void;

  reportWarning: (message: string) => void;
}

export class EmptyReporter implements Reporter {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
  reportException(err: Error, additionalData?: object) {

  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
  reportWarning(message: string) {

  }
}


export const ErrorReporting: React.Context<Reporter> = React.createContext(new EmptyReporter());
