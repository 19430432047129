import * as React from 'react';
import { StyleSheet } from 'aphrodite/no-important';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { maxVisibleLinksOnLargeAndXLarge } from '../../components/navigation/header/oldHeader/secondaryHeader/SecondaryHeader';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { generateLinkStyleFromTheme, TextLinkComponent } from '../../partials/link/TextLink';
import { isLastLink } from '../../components/navigation/header/oldHeader/secondaryHeader/SecondaryLink';
import { getStyleBySize, semanticCSS } from '../../../typography/semanticTags/semanticTags.utils';
import { Span } from '../../../typography/semanticTags';
import { useTheme } from '../../../theming/useTheme';
import { NavigationLink } from '../../components/navigation/header/oldHeader/primaryHeader/PrimaryHeader';
import { componentConfigThemeKey, HeaderThemeProps } from '../../components/navigation/header/Header.theme';

interface AMPSecondaryLinkDataProps {
  link: NavigationLink;
  index: number;
  linksLength: number;
  pagePath: string;
}

const getTextStyle = (baseUnit: BaseUnit, isActiveLink: boolean, lastLink: boolean) => {
  return StyleSheet.create({
    link: {
      width: 'max-content',
      [MEDIA_BREAKPOINTS.medium]: {
        paddingRight: lastLink ? `${baseUnit * 2}px` : 0,
      },
      [MEDIA_BREAKPOINTS.small]: {
        paddingRight: lastLink ? `${baseUnit * 2}px` : 0,
      },
    },
  });
};

export const AMPSecondaryLink: React.FunctionComponent<AMPSecondaryLinkDataProps> = props => {
  const { link, pagePath, linksLength, index } = props;
  const { secondRowLinkColorTypeActive, secondRowLinkColorTypeNormal, secondRowLinkColorTypeHover, baseUnit, fontSizesStyles, secondRowLinkFontSizeLarge, secondRowLinkFontSizeMedium, secondRowLinkFontSizeSmall } = useTheme<HeaderThemeProps>(componentConfigThemeKey);
  const { href, text } = link.link;
  const isActiveLink = href === pagePath;

  const linkStyleBasedOnScreen = linksLength > maxVisibleLinksOnLargeAndXLarge && index >= maxVisibleLinksOnLargeAndXLarge - 1
    ? {
      [MEDIA_BREAKPOINTS.large]: {
        display: 'none',
      },
      [MEDIA_BREAKPOINTS.small]: {
        display: 'inline-flex',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        display: 'inline-flex',
      },
    }
    : {};
  const linkStyle = generateLinkStyleFromTheme({
    idleColor: isActiveLink ? secondRowLinkColorTypeActive : secondRowLinkColorTypeNormal,
    activeStateColor: secondRowLinkColorTypeActive,
    hoverStateColor: isActiveLink ? secondRowLinkColorTypeActive : secondRowLinkColorTypeHover,
  }, linkStyleBasedOnScreen);
  const style = getTextStyle(baseUnit, isActiveLink, isLastLink(index, linksLength));
  const fontStyle = getStyleBySize(fontSizesStyles, { fontSizeSmall: secondRowLinkFontSizeSmall, fontSizeMedium: secondRowLinkFontSizeMedium, fontSizeLarge: secondRowLinkFontSizeLarge });

  return (
    <TextLinkComponent href={href} linkStyle={linkStyle}>
      <Span styles={semanticCSS(fontStyle, style.link)}>{text}</Span>
    </TextLinkComponent>
  );
};
