import { AppState, ErrorPageMeccaAppState } from '../../../initialStateRegistration';

export const getErrorPage = (state: AppState) => (state as ErrorPageMeccaAppState).template;

export const getErrorImage = (state: AppState) => getErrorPage(state).image;

export const getErrorTitle = (state: AppState) => getErrorPage(state).title;

export const getErrorSecondaryTitle = (state: AppState) => getErrorPage(state).secondaryTitle;

export const getErrorContent = (state: AppState) => getErrorPage(state).content;

export const getErrorButton = (state: AppState) => getErrorPage(state).button;

export const getMetadataDescription = (state: AppState) => getErrorPage(state).metadataDescription;

export const getMetadataTitle = (state: AppState) => getErrorPage(state).metadataTitle;

export const getSearchEnabled = (state: AppState) => getErrorPage(state).searchEnabled;
