import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { useTheme } from '../../../theming/useTheme';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { useHeaderHeights } from '../../components/navigation/header/useHeaderHeights';
import { BodyTag } from './BodyTag';
import { MainWrapperConfigThemeKey, MainWrapperThemeProps } from './mainWrapper.theme';

interface MainWrapperForHeaderWithSubMenusProps {
    configThemeKey: MainWrapperConfigThemeKey;
    isFullBleed?: boolean;
    noMarginTop?: boolean;
}

export const MainWrapperForNavigation: React.FunctionComponent<MainWrapperForHeaderWithSubMenusProps> = ({ children, configThemeKey, isFullBleed = false, noMarginTop = false }) => {
  const { baseUnit, backgroundColor, paddingFactor, maxWidth } = useTheme<MainWrapperThemeProps>(configThemeKey);
  const headerHeights = useHeaderHeights();

  const style = {
    backgroundColor,
    ...(!isFullBleed && {
      maxWidth: `${maxWidth}px`,
      margin: '0 auto',
      paddingBottom: `${baseUnit}px`,
    }),
    [MEDIA_BREAKPOINTS.large]: {
      marginTop: noMarginTop ? '0px' : `${headerHeights.large * baseUnit + baseUnit * paddingFactor}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      marginTop: noMarginTop ? '0px' : `${headerHeights.medium * baseUnit + baseUnit * paddingFactor}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      marginTop: noMarginTop ? '0px' : `${headerHeights.small * baseUnit + baseUnit * paddingFactor}px`,
    },
  };

  return (
    <React.Fragment>
      <BodyTag mainConfigThemeKey={configThemeKey} />
      <main
        className={css(StyleSheet.create({ style }).style)}
      >
        {children}
      </main>
    </React.Fragment>
  );
};
