import * as React from 'react';
import { css } from 'aphrodite/no-important';
import { Colors } from 'mm-theme-configuration';
import { createStyles } from './loader.styles';
import { useTheme } from '../../../theming/useTheme';

export const Loader = () => {
  const { colors }: { colors: Colors } = useTheme();
  const styles = createStyles(colors.primary);

  return (
    <div className={css(styles.root)}>
      <div className={css(styles.loaderBefore)} />
      <div className={css(styles.loader)} />
      <div className={css(styles.loaderAfter)} />
    </div>
  );
};
