import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { MEDIA_BREAKPOINTS } from '../../mediaQueries.const';
import { BaseUnit } from '../../theming/baseUnitDefinition';
import { useTheme } from '../../theming/useTheme';
import { Kasda } from '../../Kasda/Kasda';

type MarginFactors = {
  left: number;
  right: number;
};

interface OpenWebProps {
  postCanonicalUrl: string;
  md5hashPostCanonicalUrl: string;
  spotId: string;
  tags: string[];
  largeScreenMarginFactors?: MarginFactors;
}

const getStyles = (largeScreenMarginFactors: MarginFactors, baseUnit: BaseUnit) => StyleSheet.create({
  wrapper: {
    boxSizing: 'border-box',
    display: 'grid',
    gridTemplateColumns: 'minmax(0,1fr)',
    [MEDIA_BREAKPOINTS.large]: {
      marginLeft: `${largeScreenMarginFactors.left * baseUnit}px`,
      marginRight: `${largeScreenMarginFactors.right * baseUnit}px`,
    },
  },
});

export const OpenWeb: React.FunctionComponent<OpenWebProps> = ({
  md5hashPostCanonicalUrl, postCanonicalUrl, spotId, tags, largeScreenMarginFactors = {
    left: 0,
    right: 0,
  },
}) => {
  const tagsString = Array.isArray(tags) ? tags.join(', ') : tags;
  const { baseUnit } = useTheme();
  const styles = getStyles(largeScreenMarginFactors, baseUnit);
  return (
    <React.Fragment>
      <Kasda.Script>
        <script
          defer
          src={`https://launcher.spot.im/spot/${spotId}`}
          data-spotim-module="spotim-launcher"
          data-post-url={postCanonicalUrl}
          data-post-id={md5hashPostCanonicalUrl}
          data-article-tags={tagsString}
        />
      </Kasda.Script>
      <div className={css(styles.wrapper)}>
        <div data-spotim-module="pitc" />
        <div data-spotim-module="conversation" data-post-id={md5hashPostCanonicalUrl} data-spot-id={spotId} />
      </div>
    </React.Fragment>
  );
};
