import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { SocialButton } from './ShareMenu';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { CopyLinkButton } from './CopyLinkButton';
import { useTheme } from '../../../theming/useTheme';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { ShareConfigLinks } from './ShareConfigLinks';

const componentConfigurationKey = 'verticalShareMenu';

interface ShareMenuDataProps {
  shareConfig: SocialButton[];
  canonical: string;
  smallScreenOnly?: boolean;
  hideOnSmallScreen?: boolean;
}

interface ShareMenuThemeProps {
  backgroundColor: string;
  baseUnit: BaseUnit;
}

const getStyles = (backgroundColor: string, baseUnit: BaseUnit, smallScreenOnly: boolean | undefined = false, hideOnSmallScreen: boolean | undefined = false) => {
  return StyleSheet.create({
    sticky: {
      [MEDIA_BREAKPOINTS.large]: {
        position: 'sticky',
        top: `${baseUnit * 4}px`,
        display: smallScreenOnly ? 'none' : 'block',
        marginBottom: 0,
        paddingLeft: `${baseUnit}px`,
        marginLeft: 'unset',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        position: 'sticky',
        top: `${baseUnit * 5}px`,
        display: smallScreenOnly ? 'none' : 'block',
        marginBottom: 0,
        paddingLeft: 0,
        marginLeft: `-${baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        position: 'relative',
        top: 'unset',
        display: hideOnSmallScreen ? 'none' : 'block',
        marginBottom: `${baseUnit}px`,
        paddingLeft: 0,
        marginLeft: 'unset',
      },
    },
    wrapperBase: {
      display: 'flex',
      marginTop: `${baseUnit}px`,
    },
    wrapperDefault: {
      [MEDIA_BREAKPOINTS.large]: {
        flexDirection: 'column',
        position: 'absolute',
        transform: 'unset',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        flexDirection: 'column',
        position: 'absolute',
        transform: 'translateX(50px)',
      },
      [MEDIA_BREAKPOINTS.small]: {
        flexDirection: 'row',
        justifyContent: 'center',
        position: 'relative',
        transform: 'unset',
      },
    },
    link: {
      position: 'relative',
      display: 'grid',
      height: '40px',
      width: '40px',
      borderRadius: '100%',
      backgroundColor,
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      ':not(:first-child)': {
        [MEDIA_BREAKPOINTS.large]: {
          marginTop: '10px',
          marginLeft: 0,
        },
        [MEDIA_BREAKPOINTS.medium]: {
          marginTop: '10px',
          marginLeft: 0,
        },
        [MEDIA_BREAKPOINTS.small]: {
          marginTop: 0,
          marginLeft: '10px',
        },
      },
    },
  });
};

export const SwitchableShareMenu: React.FunctionComponent<ShareMenuDataProps> = props => {
  const { shareConfig, canonical, smallScreenOnly, hideOnSmallScreen } = props;
  const { backgroundColor, baseUnit } = useTheme<ShareMenuThemeProps>(componentConfigurationKey);
  const styles = getStyles(backgroundColor, baseUnit, smallScreenOnly, hideOnSmallScreen);

  return (
    <ErrorBoundary>
      <div className={css(styles.sticky)}>
        <div className={css(styles.wrapperBase, styles.wrapperDefault)}>
          <ShareConfigLinks shareConfig={shareConfig} linkStyle={styles.link} />
          <CopyLinkButton canonical={canonical} buttonClass={css(styles.link)} />
        </div>
      </div>
    </ErrorBoundary>
  );
};
