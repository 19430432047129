import * as React from 'react';
import { createImageSrc, createImageWidths, CroppingData } from '../partials/image/image.utils';
import { LARGE_SCREEN_SIZE, MEDIUM_SCREEN_SIZE, SMALL_SCREEN_SIZE } from '../../mediaQueries.const';

export const ampImageWidths = createImageWidths(540, 720, 720);

export const ampAspectRatio = { x: 16, y: 9 };

export const preloadImage = (host: string, path: string, aspectRatio = ampAspectRatio, cropping: CroppingData | null) => {
  return [
    <link key="1" rel="preload" href={createImageSrc(host, path, ampImageWidths.small, aspectRatio, 1, cropping)} media={`(max-width: ${SMALL_SCREEN_SIZE}px)`} as="image" />,
    <link key="2" rel="preload" href={createImageSrc(host, path, ampImageWidths.medium, aspectRatio, 1, cropping)} media={`(max-width: ${MEDIUM_SCREEN_SIZE}px)`} as="image" />,
    <link key="3" rel="preload" href={createImageSrc(host, path, ampImageWidths.large, aspectRatio, 1, cropping)} media={`(min-width: ${LARGE_SCREEN_SIZE}px)`} as="image" />,
  ];
};

export const normalizeDimensions = (aspectRatio = { x: 16, y: 9 }) => {
  return [aspectRatio.x / aspectRatio.y, 1];
};
