export const componentConfigThemeKey = 'aboutPage';

export interface AboutPageThemeProps {
  titleColor: string;
  subHeadlineColor: string;
  textColor: string;
  authorColor: string;
  socialIconsBackgroundColor: string;
  socialIconsNormalColor: string;
  socialIconsHoverColor: string;
  paragraphLinkColor: string;
}
