export const isSocial = (source: string) => source.includes('facebook')
  || source.includes('twitter')
  || source.includes('insta')
  || source.includes('snap')
  || source.includes('reddit')
  || source.includes('whatsapp')
  || source.includes('viber')
  || source.includes('tiktok')
  || source.includes('pinterest')
  || source.includes('disqus')
  || source === 't.co';

export const isSEO = (source: string, medium: string) => source.includes('google') || medium === 'organic';

export const isReferral = (source: string, medium: string) => medium === 'referral'
  || medium === 'RSS'
  || medium === 'pn'
  || medium === 'api'
  || source === 'fotmob';

export const isEmail = (medium: string) => medium === 'email';

export const isDirect = (source: string) => source.includes('direct');
