import { PLATFORM_REGEXES } from '../constants';
import { URLParser } from '../utils/URLParser';

export const referrerHostname = (traffic) => {
  if (traffic.referrer === '') {
    return '';
  }
  const referrerObject = new URLParser(traffic.referrer);
  const { hostname } = referrerObject;
  const match = Object.entries(PLATFORM_REGEXES).find(
    ([, regexes]) => regexes.some(regex => regex.test(hostname)),
  );
  if (match) {
    const [platform] = match;
    return platform;
  }
  return hostname;
};
