import { css, StyleSheet } from 'aphrodite/no-important';
import * as React from 'react';
import { ALIGNMENT, ALIGNMENT_OPTIONS, SEMANTIC_TAG, TypographySize } from 'mm-theme-configuration/dist/consts';
import { DynamicSemanticTag } from '../../../typography/semanticTags/DynamicSemanticTag';
import { semanticCSS } from '../../../typography/semanticTags';
import {
  Author,
  clickableLabelsStyles,
  listAuthors,
  shouldShowSponsored,
  showAuthorInAnyScreenSize,
  showDateInAnyScreenSize,
  showDescriptionInAnyScreenSize,
  showMetadataInAnyScreenSize,
  showPipeInAnyScreenSize,
} from './cards.utils';
import { SponsoredTextHorizontal } from '../SponsoredTextHorizontal';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { baseUnit } from '../../../theming/baseUnitDefinition';
import { ellipsify } from '../../../typography/ellipsify';
import { Link } from '../link/Link';
import { getFormattedPublishDate } from '../../../utils/time.utils';
import { Style } from '../../components.utils';

interface TextNextToCardProps {
  title: string;
  description?: string;
  authors: Author[];
  updatedAt?: string;
  sponsoredText?: string;
  brandName?: string;
  horizontalGapLarge: number;
  horizontalGapMedium: number;
  horizontalGapSmall: number;
  verticalGapLarge: number;
  verticalGapMedium: number;
  verticalGapSmall: number;
  titleSemanticTag: SEMANTIC_TAG;
  titleFontSizeLarge: TypographySize;
  titleFontSizeMedium: TypographySize;
  titleFontSizeSmall: TypographySize;
  numberOfLinesForTitle: number;
  showDescriptionInLargeScreen: boolean;
  showDescriptionInMediumScreen: boolean;
  showDescriptionInSmallScreen: boolean;
  descriptionSemanticTag: SEMANTIC_TAG;
  descriptionFontSizeInLargeScreen: TypographySize;
  descriptionFontSizeInMediumScreen: TypographySize;
  descriptionFontSizeInSmallScreen: TypographySize;
  descriptionColor: string;
  numberOfLinesForDescription: number;
  showAuthorInLargeScreen: boolean;
  showAuthorInMediumScreen: boolean;
  showAuthorInSmallScreen: boolean;
  showDateInLargeScreen: boolean;
  showDateInMediumScreen: boolean;
  showDateInSmallScreen: boolean;
  metadataSemanticTag: SEMANTIC_TAG;
  metadataFontSizeLarge: TypographySize;
  metadataFontSizeMedium: TypographySize;
  metadataFontSizeSmall: TypographySize;
  showMetadataInLargeScreen: boolean;
  showMetadataInMediumScreen: boolean;
  showMetadataInSmallScreen: boolean;
  showMetadataOnBottom: boolean;
  metadataMarginTop: number;
  authorColor: string;
  numberOfLinesForAuthors: number;
  pipeColor: string;
  dateColor: string;
  titleAlignment?: ALIGNMENT;
  metadataAlignment?: ALIGNMENT;
  descriptionAlignment?: ALIGNMENT;
  timestamp?: string;
  timestampISO?: string;
  isClickableAuthors: boolean;
  useFormatMinutesHoursAgo?: boolean;
  descriptionHorizontalGap?: number;
  authorColorHover?: string;
  additionalBottomTextWrapperStyle?: Style;
  titleLineHeightLarge?: number;
  titleLineHeightMedium?: number;
  titleLineHeightSmall?: number;
  titleColor?: string;
}

const createTextWrapperStyle = (horizontalGapLarge: number, horizontalGapMedium: number, horizontalGapSmall: number, verticalGapLarge: number, verticalGapMedium: number, verticalGapSmall: number) => StyleSheet.create({
  style: {
    overflow: 'hidden',
    width: '-webkit-fill-available',
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    [MEDIA_BREAKPOINTS.large]: {
      margin: `${baseUnit * horizontalGapLarge}px ${baseUnit * verticalGapLarge}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      margin: `${baseUnit * horizontalGapMedium}px ${baseUnit * verticalGapMedium}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      margin: `${baseUnit * horizontalGapSmall}px ${baseUnit * verticalGapSmall}px`,
    },
  },
}).style;
const createTopTextWrapperStyle = () => StyleSheet.create({
  style: {
    height: 'fit-content',
  },
}).style;
const createTitleStyle = (numberOfLinesForTitle: number, titleLineHeightLarge?: number, titleLineHeightMedium?: number, titleLineHeightSmall?: number, titleColor?: string) => {
  return StyleSheet.create({
    text: {
      overflow: 'hidden',
      height: 'fit-content',
      color: titleColor,
      [MEDIA_BREAKPOINTS.large]: {
        ...(titleLineHeightLarge && { lineHeight: titleLineHeightLarge }),
      },
      [MEDIA_BREAKPOINTS.medium]: {
        ...(titleLineHeightMedium && { lineHeight: titleLineHeightMedium }),
      },
      [MEDIA_BREAKPOINTS.small]: {
        ...(titleLineHeightSmall && { lineHeight: titleLineHeightSmall }),
      },
      ...ellipsify(numberOfLinesForTitle).style,
    },
  }).text;
};
const createDescriptionStyle = (
  showDescriptionLargeScreen: boolean,
  showDescriptionMediumScreen: boolean,
  showDescriptionSmallScreen: boolean,
  descriptionColor: string,
  numberOfLinesForDescription: number,
  descriptionHorizontalGap?: number,
) => StyleSheet.create({
  style: {
    position: 'relative',
    color: descriptionColor,
    height: 'fit-content',
    ...descriptionHorizontalGap ? { marginTop: `${baseUnit * descriptionHorizontalGap}px` } : { marginTop: `${baseUnit * 0.25}px` },
    [MEDIA_BREAKPOINTS.large]: {
      ...showDescriptionLargeScreen ? ellipsify(numberOfLinesForDescription).style : { display: 'none' },
    },
    [MEDIA_BREAKPOINTS.medium]: {
      ...showDescriptionMediumScreen ? ellipsify(numberOfLinesForDescription).style : { display: 'none' },
    },
    [MEDIA_BREAKPOINTS.small]: {
      ...showDescriptionSmallScreen ? ellipsify(numberOfLinesForDescription).style : { display: 'none' },
    },
  },
}).style;

const createBottomTextWrapperStyle = (showMetadataOnBottom: boolean) => StyleSheet.create({
  style: {
    height: '100%',
    display: 'grid',
    alignItems: showMetadataOnBottom ? 'flex-end' : 'flex-start',
    gridTemplateRows: '1fr auto',
  },
}).style;
const createMetadataStyle = (
  metadataMarginTop: number,
  showMetadataInLargeScreen: boolean,
  showMetadataInMediumScreen: boolean,
  showMetadataInSmallScreen: boolean,
) => StyleSheet.create({
  style: {
    gridTemplateColumns: 'auto auto max-content',
    width: 'fit-content',
    [MEDIA_BREAKPOINTS.large]: {
      display: showMetadataInLargeScreen ? 'inline-grid' : 'none',
      marginTop: `${baseUnit * metadataMarginTop}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      display: showMetadataInMediumScreen ? 'inline-grid' : 'none',
      marginTop: `${baseUnit * metadataMarginTop}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      display: showMetadataInSmallScreen ? 'inline-grid' : 'none',
      marginTop: `${baseUnit * metadataMarginTop}px`,
    },
  },
}).style;
const createAuthorsStyle = (
  showAuthorSmallScreen: boolean,
  showAuthorMediumScreen: boolean,
  showAuthorLargeScreen: boolean,
  authorColor: string,
  numberOfLinesForAuthors: number,
  authorColorHover?: string,
) => StyleSheet.create({
  style: {
    position: 'relative',
    color: authorColor,
    alignSelf: 'flex-end',
    ':hover': {
      ...authorColorHover ? { color: authorColorHover } : { color: 'inherit' },
    },
    [MEDIA_BREAKPOINTS.large]: {
      marginRight: `${baseUnit * 0.25}px`,
      ...showAuthorLargeScreen ? ellipsify(numberOfLinesForAuthors).style : { display: 'none' },
    },
    [MEDIA_BREAKPOINTS.medium]: {
      marginRight: `${baseUnit * 0.25}px`,
      ...showAuthorMediumScreen ? ellipsify(numberOfLinesForAuthors).style : { display: 'none' },
    },
    [MEDIA_BREAKPOINTS.small]: {
      marginRight: `${baseUnit * 0.25}px`,
      ...showAuthorSmallScreen ? ellipsify(numberOfLinesForAuthors).style : { display: 'none' },
    },
  },
}).style;
const createPipeStyle = (
  showDateSmallScreen: boolean,
  showDateMediumScreen: boolean,
  showDateLargeScreen: boolean,
  showAuthorSmallScreen: boolean,
  showAuthorMediumScreen: boolean,
  showAuthorLargeScreen: boolean,
  pipeColor: string,
) => StyleSheet.create({
  style: {
    color: pipeColor,
    alignSelf: 'flex-end',
    [MEDIA_BREAKPOINTS.large]: {
      display: showDateLargeScreen && showAuthorLargeScreen ? 'block' : 'none',
      marginRight: `${baseUnit * 0.25}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      display: showDateMediumScreen && showAuthorMediumScreen ? 'block' : 'none',
      marginRight: `${baseUnit * 0.25}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      display: showDateSmallScreen && showAuthorSmallScreen ? 'block' : 'none',
      marginRight: `${baseUnit * 0.25}px`,
    },
  },
}).style;
const createDateStyle = (
  showDateSmallScreen: boolean,
  showDateMediumScreen: boolean,
  showDateLargeScreen: boolean,
  dateColor: string,
) => StyleSheet.create({
  style: {
    color: dateColor,
    alignSelf: 'flex-end',
    ':hover': {
      color: dateColor,
    },
    [MEDIA_BREAKPOINTS.large]: {
      display: showDateLargeScreen ? 'inline-block' : 'none',
    },
    [MEDIA_BREAKPOINTS.medium]: {
      display: showDateMediumScreen ? 'inline-block' : 'none',
    },
    [MEDIA_BREAKPOINTS.small]: {
      display: showDateSmallScreen ? 'inline-block' : 'none',
    },
  },
}).style;

const createTextAlignmentStyle = (alignment: ALIGNMENT = ALIGNMENT_OPTIONS.START) => {
  switch (alignment) {
    case ALIGNMENT_OPTIONS.CENTER:
      return StyleSheet.create({
        style: {
          textAlign: 'center',
        },
      }).style;
    case ALIGNMENT_OPTIONS.START:
    default:
      return StyleSheet.create({
        style: {
          textAlign: 'left',
        },
      }).style;
  }
};

const createFooterStyle = (metadataAlignment: ALIGNMENT = ALIGNMENT_OPTIONS.START) => StyleSheet.create({
  style: {
    justifySelf: metadataAlignment === ALIGNMENT_OPTIONS.CENTER ? 'center' : 'left',
  },
}).style;

interface AuthorsProps {
  showAuthorInSmallScreen: boolean;
  showAuthorInMediumScreen: boolean;
  showAuthorInLargeScreen: boolean;
  authorColor: string;
  numberOfLinesForAuthors: number;
  isClickableAuthors: boolean;
  authorsList: Author[];
  authorColorHover?: string;
}
const Authors: React.FunctionComponent<AuthorsProps> = ({ authorsList, showAuthorInLargeScreen, showAuthorInMediumScreen, showAuthorInSmallScreen, authorColor, authorColorHover, numberOfLinesForAuthors, isClickableAuthors }) => {
  const { labelsLink } = clickableLabelsStyles();
  const authorsStyle = createAuthorsStyle(showAuthorInSmallScreen, showAuthorInMediumScreen, showAuthorInLargeScreen, authorColor, numberOfLinesForAuthors, authorColorHover);

  const listAuthorLinks = (authorsArray: Author[]) => {
    return authorsArray.map((author, index) => (
      <React.Fragment key={index}>
        { author.link ? <Link href={author.link} className={css(labelsLink)} title={author.name} /> : null}
        { (index === authorsArray.length - 1) ? author.name : `${author.name}, `}
      </React.Fragment>
    ));
  };

  return (
    <div className={css(authorsStyle)}>
      {isClickableAuthors ? listAuthorLinks(authorsList) : listAuthors(authorsList)}
    </div>
  );
};

export const TextNextToImage: React.FunctionComponent<TextNextToCardProps> = props => {
  const {
    children,
    title,
    brandName,
    authors,
    description,
    timestamp,
    timestampISO,
    sponsoredText,
    horizontalGapLarge,
    horizontalGapMedium,
    horizontalGapSmall,
    verticalGapLarge,
    verticalGapMedium,
    verticalGapSmall,
    titleSemanticTag,
    titleFontSizeMedium,
    titleFontSizeSmall,
    titleFontSizeLarge,
    numberOfLinesForTitle,
    showDescriptionInLargeScreen,
    showDescriptionInMediumScreen,
    showDescriptionInSmallScreen,
    descriptionSemanticTag,
    descriptionFontSizeInSmallScreen,
    descriptionFontSizeInLargeScreen,
    descriptionFontSizeInMediumScreen,
    descriptionColor,
    numberOfLinesForDescription,
    showAuthorInLargeScreen,
    showAuthorInMediumScreen,
    showAuthorInSmallScreen,
    showDateInLargeScreen,
    showDateInMediumScreen,
    showDateInSmallScreen,
    metadataSemanticTag,
    metadataFontSizeLarge,
    metadataFontSizeMedium,
    metadataFontSizeSmall,
    showMetadataOnBottom,
    metadataMarginTop,
    showMetadataInLargeScreen,
    showMetadataInMediumScreen,
    showMetadataInSmallScreen,
    authorColor,
    numberOfLinesForAuthors,
    pipeColor,
    dateColor,
    titleAlignment,
    descriptionAlignment,
    metadataAlignment,
    isClickableAuthors,
    useFormatMinutesHoursAgo,
    descriptionHorizontalGap,
    authorColorHover,
    additionalBottomTextWrapperStyle,
    titleLineHeightLarge,
    titleLineHeightMedium,
    titleLineHeightSmall,
    titleColor,
  } = props;
  const textWrapperStyle = createTextWrapperStyle(horizontalGapLarge, horizontalGapMedium, horizontalGapSmall, verticalGapLarge, verticalGapMedium, verticalGapSmall);
  const topTextWrapperStyle = createTopTextWrapperStyle();
  const titleStyle = createTitleStyle(numberOfLinesForTitle, titleLineHeightLarge, titleLineHeightMedium, titleLineHeightSmall, titleColor);
  const descriptionStyle = createDescriptionStyle(showDescriptionInLargeScreen, showDescriptionInMediumScreen, showDescriptionInSmallScreen, descriptionColor, numberOfLinesForDescription, descriptionHorizontalGap);
  const bottomTextWrapperStyle = createBottomTextWrapperStyle(showMetadataOnBottom);
  const metadataStyle = createMetadataStyle(metadataMarginTop, showMetadataInLargeScreen, showMetadataInMediumScreen, showMetadataInSmallScreen);
  const pipeStyle = createPipeStyle(showDateInSmallScreen, showDateInMediumScreen, showDateInLargeScreen, showAuthorInSmallScreen, showAuthorInMediumScreen, showAuthorInLargeScreen, pipeColor);
  const dateStyle = createDateStyle(showDateInSmallScreen, showDateInMediumScreen, showDateInLargeScreen, dateColor);
  const titleAlignmentStyle = createTextAlignmentStyle(titleAlignment);
  const descriptionAlignmentStyle = createTextAlignmentStyle(descriptionAlignment);
  const footerStyle = createFooterStyle(metadataAlignment);
  const showAuthor = showAuthorInAnyScreenSize(authors, showAuthorInLargeScreen, showAuthorInMediumScreen, showAuthorInSmallScreen);
  const showDate = showDateInAnyScreenSize(showDateInLargeScreen, showDateInMediumScreen, showDateInSmallScreen);
  const showSponsored = shouldShowSponsored(sponsoredText, brandName);
  const showBottomText = showSponsored || showMetadataInAnyScreenSize(showAuthor, showDate);
  const authorsComponentProps = {
    showAuthorInLargeScreen,
    showAuthorInMediumScreen,
    showAuthorInSmallScreen,
    authorColor,
    numberOfLinesForAuthors,
    isClickableAuthors,
    authorsList: authors,
    authorColorHover,
  };

  return (
    <div className={css(textWrapperStyle)}>
      <div className={css(topTextWrapperStyle)}>
        <header>
          {children}
          <DynamicSemanticTag
            semanticTag={titleSemanticTag}
            fontSizeLarge={titleFontSizeLarge}
            fontSizeMedium={titleFontSizeMedium}
            fontSizeSmall={titleFontSizeSmall}
            styles={semanticCSS(titleAlignmentStyle, titleStyle)}
          >
            {title}
          </DynamicSemanticTag>
        </header>
        {showDescriptionInAnyScreenSize(showDescriptionInLargeScreen, showDescriptionInMediumScreen, showDescriptionInSmallScreen) ? (
          <DynamicSemanticTag
            semanticTag={descriptionSemanticTag}
            fontSizeLarge={descriptionFontSizeInLargeScreen}
            fontSizeMedium={descriptionFontSizeInMediumScreen}
            fontSizeSmall={descriptionFontSizeInSmallScreen}
            styles={semanticCSS(descriptionAlignmentStyle, descriptionStyle)}
          >
            {description}
          </DynamicSemanticTag>
        ) : null}
      </div>
      {showBottomText ? (
        <div className={css(bottomTextWrapperStyle, additionalBottomTextWrapperStyle)}>
          {showMetadataInAnyScreenSize(showAuthor, showDate) ? (
            <footer className={css(footerStyle)}>
              <DynamicSemanticTag
                semanticTag={metadataSemanticTag}
                fontSizeLarge={metadataFontSizeLarge}
                fontSizeMedium={metadataFontSizeMedium}
                fontSizeSmall={metadataFontSizeSmall}
                styles={semanticCSS(metadataStyle)}
              >
                {showAuthor ? <Authors {...authorsComponentProps} /> : null}
                {showPipeInAnyScreenSize(showAuthor, showDate) ? (<div className={css(pipeStyle)}>|</div>) : null}
                {(showDate && timestampISO && timestamp) ? (<time dateTime={timestampISO} className={css(dateStyle)}>{getFormattedPublishDate(timestampISO, timestamp, useFormatMinutesHoursAgo)}</time>) : null}
              </DynamicSemanticTag>
            </footer>
          ) : null}
          {showSponsored ? <SponsoredTextHorizontal sponsoredText={sponsoredText} brandName={brandName} /> : null}
        </div>
      ) : null}
    </div>
  );
};
