import * as React from 'react';
import { Font, Palette, ThemeConfiguration } from 'mm-theme-configuration/dist/interfaces';
import { ThemeManager } from './themeManager';
import { translateFontSizes } from './fontSizeTranslator';

interface ThemeProviderWithFontsProps {
  palette: Palette;
  themeConfiguration: ThemeConfiguration[];
}

export interface WebFontsComponentProps {
  fontsConfiguration: Font[];
}

export type ThemeContext = { [key: string]: any };
export const ThemeContext: React.Context<ThemeContext> = React.createContext({});

interface MainCategoryContextProps {
  isMainCategoryEnabled: boolean;
}
export const MainCategoryContext: React.Context<MainCategoryContextProps> = React.createContext({ isMainCategoryEnabled: true } as MainCategoryContextProps);


interface ThemeProps {
  configuration: { [key: string]: any };
}


const Theme: React.FunctionComponent<ThemeProps> = ({ configuration, children }) => {
  const fontSizesStyles = translateFontSizes(configuration.fontSizeMap);
  const themeContext = {
    ...configuration,
    fontSizesStyles,
  };
  return (
    <ThemeContext.Provider value={themeContext}>
      {children}
    </ThemeContext.Provider>
  );
};


export const DisableMainCategory: React.FunctionComponent = ({ children }) => {
  return (
    <MainCategoryContext.Provider value={{ isMainCategoryEnabled: false }}>
      {children}
    </MainCategoryContext.Provider>
  );
};

export const ThemeProviderWithFonts = (WebFontsComponent: React.FunctionComponent<WebFontsComponentProps>) => {
  const ThemeProviderWithFontsComponent: React.FunctionComponent<ThemeProviderWithFontsProps> = props => {
    const { palette, themeConfiguration, children } = props;
    const themeManager = new ThemeManager(palette, themeConfiguration);
    const configuration = themeManager.getConfiguration();
    const fontsConfiguration = themeManager.paletteManager.getFonts();

    return (
      <WebFontsComponent fontsConfiguration={fontsConfiguration}>
        <Theme configuration={configuration}>
          {children}
        </Theme>
      </WebFontsComponent>
    );
  };
  return ThemeProviderWithFontsComponent;
};
