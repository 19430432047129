import * as React from 'react';

import { css, StyleSheet } from 'aphrodite/no-important';
import { AdditionalStyleSheet, MandatoryAdditionalStyle } from '../../components.utils';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { BlockWidths } from '../../components/pageLayouts/BlockTypes.utils';
import { createBlockStyle } from '../blockMutualStyle';
import { Thumbnail } from '../../partials/image/image.types';
import { createImageWidths } from '../../partials/image/image.utils';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { useTheme } from '../../../theming/useTheme';
import { VerticalCardThemeProps, componentConfigThemeKey as verticalCardConfigThemeKey } from '../../partials/cards/verticalCard/VerticalCard.theme';
import { VerticalCardProps } from '../../partials/cards/verticalCard/VerticalCard.props';

interface ImportedEmbeddedContentDataProps {
  id: string | number;
  defaultBlocksWidths: BlockWidths;
  title: string;
  articleUrl: string;
  description?: string;
  image: Thumbnail;
}

export type ImportedEmbeddedContentProps = ImportedEmbeddedContentDataProps & MandatoryAdditionalStyle;

const getStyle = (defaultBlocksWidths: BlockWidths) => {
  return StyleSheet.create({
    style: {
      ...createBlockStyle(defaultBlocksWidths),
    },
  }).style as AdditionalStyleSheet;
};

export const ImportedEmbeddedContentWithCard = (Card: React.FunctionComponent<VerticalCardProps>): React.FunctionComponent<ImportedEmbeddedContentProps> => {
  const ImportedEmbeddedContentComponent: React.FunctionComponent<ImportedEmbeddedContentProps> = props => {
    const { id, title, articleUrl, description, image, defaultBlocksWidths, additionalStyle } = props;
    const { colors } = useTheme();
    const themeContextVC = useThemeOverride<VerticalCardThemeProps>({
      showDescriptionInLargeScreen: true,
      showDescriptionInMediumScreen: true,
      showDescriptionInSmallScreen: true,
      borderWidthTypeNormal: 1,
      borderWidthTypeHover: 1,
      borderWidthTypeActive: 1,
      borderColorTypeNormal: colors.lightGrey,
      borderColorTypeHover: colors.lightGrey,
      borderColorTypeActive: colors.lightGrey,
      numberOfLinesForTitle: 5,
      numberOfLinesForDescription: 5,
    }, verticalCardConfigThemeKey);

    return (
      <ErrorBoundary>
        <div {...{ 'data-mm-id': id }} className={css(getStyle(defaultBlocksWidths), additionalStyle.style)}>
          <ThemeContext.Provider value={themeContextVC}>
            <Card
              id={id}
              title={title}
              articleUrl={articleUrl}
              description={description}
              image={image}
              authors={[]}
              mainCategory={null}
              featuredAt=""
              imageWidths={createImageWidths(360, 360, 540)}
            />
          </ThemeContext.Provider>
        </div>
      </ErrorBoundary>
    );
  };
  return ImportedEmbeddedContentComponent;
};
