import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { TypographySize } from 'mm-theme-configuration/dist/consts';
import { MEDIA_BREAKPOINTS } from '../../../../../mediaQueries.const';
import { useTheme } from '../../../../../theming/useTheme';
import { BaseUnit } from '../../../../../theming/baseUnitDefinition';
import { NavigationLink } from '../oldHeader/primaryHeader/PrimaryHeader';
import { RegularHeaderLink } from './RegularHeaderLink';

interface ScrollableNavDropdownProps {
  firstRowHeightInMediumScreen: number;
  firstRowHeightInSmallScreen: number;
  secondRowHeightInMediumScreen: number;
  secondRowHeightInSmallScreen: number;
  linkColorTypeActive: string;
  linkColorTypeHover: string;
  linkColorTypeNormal: string;
  currentLinkColor: string;
  backgroundColor: string;
  links: NavigationLink[];
  pagePath: string;
  linkFontSize: TypographySize;
}

const getStyles = (baseUnit: BaseUnit, firstRowHeightInMediumScreen: number, firstRowHeightInSmallScreen: number, secondRowHeightInMediumScreen: number, secondRowHeightInSmallScreen: number, backgroundColor: string) => {
  return StyleSheet.create({
    menuWrapper: {
      zIndex: 1000,
      position: 'fixed',
      backgroundColor,
      left: 0,
      right: 0,
      bottom: 0,
      padding: `${baseUnit}px`,
      [MEDIA_BREAKPOINTS.large]: {
        display: 'none',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        top: `${(firstRowHeightInMediumScreen + secondRowHeightInMediumScreen) * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        top: `${(firstRowHeightInSmallScreen + secondRowHeightInSmallScreen) * baseUnit}px`,
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
    },
    ul: {
      listStyle: 'none',
      display: 'grid',
      gridRowGap: `${baseUnit}px`,
      gridColumnGap: `${baseUnit}px`,
      [MEDIA_BREAKPOINTS.medium]: {
        gridTemplateColumns: 'repeat(4, 1fr)',
      },
      [MEDIA_BREAKPOINTS.small]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
    },
  });
};

export const ScrollableNavDropdown: React.FunctionComponent<ScrollableNavDropdownProps> = props => {
  const {
    firstRowHeightInMediumScreen,
    firstRowHeightInSmallScreen,
    secondRowHeightInMediumScreen,
    secondRowHeightInSmallScreen,
    linkColorTypeActive,
    linkColorTypeHover,
    linkColorTypeNormal,
    currentLinkColor,
    backgroundColor,
    links,
    pagePath,
    linkFontSize,
  } = props;
  const { baseUnit } = useTheme();
  const styles = getStyles(baseUnit, firstRowHeightInMediumScreen, firstRowHeightInSmallScreen, secondRowHeightInMediumScreen, secondRowHeightInSmallScreen, backgroundColor);
  const fontStyles = {
    linkFontSizeLarge: linkFontSize,
    linkFontSizeMedium: linkFontSize,
    linkFontSizeSmall: linkFontSize,
  };

  return (
    <div className={css(styles.menuWrapper)}>
      <ul className={css(styles.ul)}>
        {
          links.map((navigationLink, index) => {
            const isActive = pagePath === navigationLink.link.href;
            return (
              <li key={index}>
                <RegularHeaderLink currentLinkColor={currentLinkColor} activeStateColor={linkColorTypeActive} hoverStateColor={linkColorTypeHover} idleColor={linkColorTypeNormal} isActive={isActive} href={navigationLink.link.href} text={navigationLink.link.text} {...fontStyles} />
              </li>
            );
          })
        }
      </ul>
    </div>
  );
};
