import * as React from 'react';
import { SEMANTIC_TAG, TypographySize } from 'mm-theme-configuration/dist/consts';
import { css, CSSProperties, StyleSheet } from 'aphrodite/no-important';
import {
  Author,
  clickableLabelsStyles,
  listAuthors,
  MainCategoryData,
  showAuthorInAnyScreenSize,
  showDateInAnyScreenSize,
  showMetadataInAnyScreenSize,
  showMetadataInLargeScreenSize,
  showMetadataInMediumScreenSize,
  showMetadataInSmallScreenSize,
  showPipeInAnyScreenSize,
} from '../cards.utils';
import { TranslatedFontSize } from '../../../../theming/fontSizeTranslator';
import { useTheme } from '../../../../theming/useTheme';
import { MEDIA_BREAKPOINTS } from '../../../../mediaQueries.const';
import { DynamicSemanticTag } from '../../../../typography/semanticTags/DynamicSemanticTag';
import { semanticCSS } from '../../../../typography/semanticTags';
import { ellipsify } from '../../../../typography/ellipsify';
import { Link } from '../../link/Link';
import { baseUnit } from '../../../../theming/baseUnitDefinition';
import { getFormattedPublishDate } from '../../../../utils/time.utils';

export const componentConfigThemeKey = 'headlineCard';

export interface HeadlineCardProps {
  title: string;
  authors: Author[];
  createdAt?: string;
  createdAtISO?: string;
  articleUrl: string;
  updatedAt?: string;
  updatedAtISO?: string;
  mainCategory: MainCategoryData | null;
  useFormatMinutesHoursAgo?: boolean;
}

export interface HeadlineCardThemeProps {
    cardTitleColorTypeNormal: string;
    cardTitleColorTypeHover: string;
    cardTitleColorTypeActive: string;
    titleFontSizeLarge: TypographySize;
    titleFontSizeMedium: TypographySize;
    titleFontSizeSmall: TypographySize;
    pipeColor: string;
    showAuthorInLargeScreen: boolean;
    showAuthorInMediumScreen: boolean;
    showAuthorInSmallScreen: boolean;
    showDateInLargeScreen: boolean;
    showDateInMediumScreen: boolean;
    showDateInSmallScreen: boolean;
    metadataFontSizeLarge: TypographySize;
    metadataFontSizeMedium: TypographySize;
    metadataFontSizeSmall: TypographySize;
    authorColor: string;
    dateColor: string;
    horizontalGapLarge: number;
    horizontalGapMedium: number;
    horizontalGapSmall: number;
    wrapperGapLarge: number;
    wrapperGapMedium: number;
    wrapperGapSmall: number;
    titleSemanticTag: SEMANTIC_TAG;
    metadataSemanticTag: SEMANTIC_TAG;
    fontSizesStyles: TranslatedFontSize;
    baseUnit: number;
    numberOfLinesForTitle: number;
    numberOfLinesForAuthors: number;
    cardBackgroundColor: string;
    showMainCategoryInLargeScreen: boolean;
    showMainCategoryInMediumScreen: boolean;
    showMainCategoryInSmallScreen: boolean;
    mainCategorySemanticTag: SEMANTIC_TAG;
    mainCategoryFontSizeLarge: TypographySize;
    mainCategoryFontSizeMedium: TypographySize;
    mainCategoryFontSizeSmall: TypographySize;
    mainCategoryTextColor: string;
    mainCategoryBackgroundColor: string;
    mainCategoryVerticalGapLarge: number;
    mainCategoryVerticalGapMedium: number;
    mainCategoryVerticalGapSmall: number;
    mainCategoryHorizontalGapLarge: number;
    mainCategoryHorizontalGapMedium: number;
    mainCategoryHorizontalGapSmall: number;
    isUpdatedAtTimestamp: boolean;
    isMainCategoryEnabled: boolean;
    isClickableLabels: boolean;
}

const createStyle = ({
  showAuthorInLargeScreen,
  showAuthorInMediumScreen,
  showAuthorInSmallScreen,
  showDateInLargeScreen,
  showDateInMediumScreen,
  showDateInSmallScreen,
  pipeColor,
  dateColor,
  numberOfLinesForTitle,
  cardBackgroundColor,
  wrapperGapLarge,
  wrapperGapMedium,
  wrapperGapSmall,
}: HeadlineCardThemeProps) => StyleSheet.create({
  wrapper: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    backgroundColor: cardBackgroundColor,
    [MEDIA_BREAKPOINTS.large]: {
      padding: `${baseUnit * wrapperGapLarge}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      padding: `${baseUnit * wrapperGapMedium}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      padding: `${baseUnit * wrapperGapSmall}px`,
    },
  },
  title: {
    height: 'fit-content',
    ...ellipsify(numberOfLinesForTitle).style as CSSProperties,
  },
  metadata: {
    gridTemplateColumns: 'auto auto max-content',
    width: 'fit-content',
    [MEDIA_BREAKPOINTS.large]: {
      display: showMetadataInLargeScreenSize(showAuthorInLargeScreen, showDateInLargeScreen) ? 'inline-grid' : 'none',
    },
    [MEDIA_BREAKPOINTS.medium]: {
      display: showMetadataInMediumScreenSize(showAuthorInMediumScreen, showDateInMediumScreen) ? 'inline-grid' : 'none',
    },
    [MEDIA_BREAKPOINTS.small]: {
      display: showMetadataInSmallScreenSize(showAuthorInSmallScreen, showDateInSmallScreen) ? 'inline-grid' : 'none',
    },
  },
  pipe: {
    color: pipeColor,
    [MEDIA_BREAKPOINTS.large]: {
      display: showDateInLargeScreen && showAuthorInLargeScreen ? 'block' : 'none',
      marginRight: `${baseUnit * 0.25}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      display: showDateInMediumScreen && showAuthorInMediumScreen ? 'block' : 'none',
      marginRight: `${baseUnit * 0.25}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      display: showDateInSmallScreen && showAuthorInSmallScreen ? 'block' : 'none',
      marginRight: `${baseUnit * 0.25}px`,
    },
  },
  date: {
    color: dateColor,
    overflow: 'hidden',
    [MEDIA_BREAKPOINTS.large]: {
      display: showDateInLargeScreen ? 'inline-block' : 'none',
    },
    [MEDIA_BREAKPOINTS.medium]: {
      display: showDateInMediumScreen ? 'inline-block' : 'none',
    },
    [MEDIA_BREAKPOINTS.small]: {
      display: showDateInSmallScreen ? 'inline-block' : 'none',
    },
  },
  footer: {
    alignSelf: 'flex-end',
  },
});

const createArticleLinkStyle = (
  cardTitleColorTypeNormal: string,
  cardTitleColorTypeHover: string,
  cardTitleColorTypeActive: string,
  horizontalGapLarge: number,
  horizontalGapMedium: number,
  horizontalGapSmall: number,
) => StyleSheet.create({
  style: {
    position: 'relative',
    overflow: 'hidden',
    width: '-webkit-fill-available',
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr',
    textDecoration: 'none',
    color: cardTitleColorTypeNormal,
    ':hover': {
      color: cardTitleColorTypeHover,
    },
    ':active': {
      color: cardTitleColorTypeActive,
    },
    [MEDIA_BREAKPOINTS.large]: {
      gridRowGap: `${baseUnit * horizontalGapLarge}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      gridRowGap: `${baseUnit * horizontalGapMedium}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      gridRowGap: `${baseUnit * horizontalGapSmall}px`,
    },
  },
}).style;

const createMainCategoryStyle = (
  showMainCategoryInLargeScreen: boolean,
  showMainCategoryInMediumScreen: boolean,
  showMainCategoryInSmallScreen: boolean,
  mainCategoryHorizontalGapLarge: number,
  mainCategoryHorizontalGapMedium: number,
  mainCategoryHorizontalGapSmall: number,
  mainCategoryVerticalGapLarge: number,
  mainCategoryVerticalGapMedium: number,
  mainCategoryVerticalGapSmall: number,
  mainCategoryBackgroundColor: string,
  mainCategoryTextColor: string,
  horizontalGapLarge: number,
  horizontalGapMedium: number,
  horizontalGapSmall: number,
) => StyleSheet.create({
  style: {
    position: 'relative',
    backgroundColor: mainCategoryBackgroundColor,
    color: mainCategoryTextColor,
    width: 'fit-content',
    [MEDIA_BREAKPOINTS.large]: {
      display: showMainCategoryInLargeScreen ? 'block' : 'none',
      padding: `${mainCategoryHorizontalGapLarge * baseUnit}px ${mainCategoryVerticalGapLarge * baseUnit}px`,
      marginBottom: `${horizontalGapLarge * baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      display: showMainCategoryInMediumScreen ? 'block' : 'none',
      padding: `${mainCategoryHorizontalGapMedium * baseUnit}px ${mainCategoryVerticalGapMedium * baseUnit}px`,
      marginBottom: `${horizontalGapMedium * baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      display: showMainCategoryInSmallScreen ? 'block' : 'none',
      padding: `${mainCategoryHorizontalGapSmall * baseUnit}px ${mainCategoryVerticalGapSmall * baseUnit}px`,
      marginBottom: `${horizontalGapSmall * baseUnit}px`,
    },
  },
}).style;

const createAuthorStyle = (
  numberOfLinesForAuthors: number,
  showAuthorInLargeScreen: boolean,
  showAuthorInMediumScreen: boolean,
  showAuthorInSmallScreen: boolean,
  authorColor: string,
) => StyleSheet.create({
  style: {
    position: 'relative',
    color: authorColor,
    height: 'fit-content',
    [MEDIA_BREAKPOINTS.large]: {
      marginRight: `${baseUnit * 0.25}px`,
      ...showAuthorInLargeScreen ? ellipsify(numberOfLinesForAuthors).style : { display: 'none' },
    },
    [MEDIA_BREAKPOINTS.medium]: {
      marginRight: `${baseUnit * 0.25}px`,
      ...showAuthorInMediumScreen ? ellipsify(numberOfLinesForAuthors).style : { display: 'none' },
    },
    [MEDIA_BREAKPOINTS.small]: {
      marginRight: `${baseUnit * 0.25}px`,
      ...showAuthorInSmallScreen ? ellipsify(numberOfLinesForAuthors).style : { display: 'none' },
    },
  },
}).style;

interface ArticleLinkProps{
  isClickableLayout: boolean;
  articleUrl: string;
  title: string;
}

const ArticleLink: React.FunctionComponent<ArticleLinkProps> = ({ children, isClickableLayout, articleUrl, title }) => {
  const { articleLink } = clickableLabelsStyles();
  const { cardTitleColorTypeNormal,
    cardTitleColorTypeHover,
    cardTitleColorTypeActive,
    horizontalGapLarge,
    horizontalGapMedium,
    horizontalGapSmall } = useTheme<HeadlineCardThemeProps>(componentConfigThemeKey);
  const style = createArticleLinkStyle(cardTitleColorTypeNormal, cardTitleColorTypeHover, cardTitleColorTypeActive, horizontalGapLarge, horizontalGapMedium, horizontalGapSmall);

  return (
    isClickableLayout
      ? (
        <div className={css(style)}>
          <Link className={css(articleLink)} href={articleUrl} title={title} />
          {children}
        </div>
      )
      : (<Link className={css(style)} href={articleUrl}>{children}</Link>)
  );
};

interface MainCategoryProps{
  isMainCategoryClickable: boolean;
  link? : string;
  displayName? : string;
}

const MainCategory: React.FunctionComponent<MainCategoryProps> = ({ isMainCategoryClickable, link, displayName }) => {
  const { showMainCategoryInLargeScreen,
    showMainCategoryInMediumScreen,
    showMainCategoryInSmallScreen,
    mainCategoryHorizontalGapLarge,
    mainCategoryHorizontalGapMedium,
    mainCategoryHorizontalGapSmall,
    mainCategoryVerticalGapLarge,
    mainCategoryVerticalGapMedium,
    mainCategoryVerticalGapSmall,
    mainCategoryBackgroundColor,
    mainCategoryTextColor,
    horizontalGapLarge,
    horizontalGapMedium,
    mainCategorySemanticTag,
    mainCategoryFontSizeLarge,
    mainCategoryFontSizeMedium,
    mainCategoryFontSizeSmall,
    horizontalGapSmall } = useTheme<HeadlineCardThemeProps>(componentConfigThemeKey);
  const mainCategoryStyle = createMainCategoryStyle(
    showMainCategoryInLargeScreen,
    showMainCategoryInMediumScreen,
    showMainCategoryInSmallScreen,
    mainCategoryHorizontalGapLarge,
    mainCategoryHorizontalGapMedium,
    mainCategoryHorizontalGapSmall,
    mainCategoryVerticalGapLarge,
    mainCategoryVerticalGapMedium,
    mainCategoryVerticalGapSmall,
    mainCategoryBackgroundColor,
    mainCategoryTextColor,
    horizontalGapLarge,
    horizontalGapMedium,
    horizontalGapSmall,
  );
  const { labelsLink } = clickableLabelsStyles();
  return (
    <DynamicSemanticTag
      semanticTag={mainCategorySemanticTag}
      fontSizeLarge={mainCategoryFontSizeLarge}
      fontSizeMedium={mainCategoryFontSizeMedium}
      fontSizeSmall={mainCategoryFontSizeSmall}
      styles={semanticCSS(mainCategoryStyle)}
    >
      {isMainCategoryClickable ? <Link className={css(labelsLink)} href={link} title={displayName} /> : null}
      { displayName }
    </DynamicSemanticTag>
  );
};

interface AuthorsProps{
  isAnyAuthorClickable: boolean;
  authors: Author[];

}

const Authors: React.FunctionComponent<AuthorsProps> = ({ isAnyAuthorClickable, authors }) => {
  const { numberOfLinesForAuthors,
    showAuthorInLargeScreen,
    showAuthorInMediumScreen,
    showAuthorInSmallScreen,
    authorColor } = useTheme<HeadlineCardThemeProps>(componentConfigThemeKey);
  const style = createAuthorStyle(numberOfLinesForAuthors, showAuthorInLargeScreen, showAuthorInMediumScreen, showAuthorInSmallScreen, authorColor);

  const { labelsLink } = clickableLabelsStyles();
  const listClickableAuthors = (authorsArray: Array<Author>) => {
    return authorsArray.map((author, index) => {
      return (
        <React.Fragment key={index}>
          {author.link ? <Link href={author.link} className={css(labelsLink)} title={author.name} /> : null}
          {(index === authorsArray.length - 1) ? author.name : `${author.name}, `}
        </React.Fragment>
      );
    });
  };

  return (
    <div className={css(style)}>
      {isAnyAuthorClickable ? listClickableAuthors(authors) : listAuthors(authors)}
    </div>
  );
};

export const HeadlineCard: React.FunctionComponent<HeadlineCardProps> = props => {
  const { title, authors, articleUrl, updatedAt, updatedAtISO, mainCategory, createdAt, createdAtISO, useFormatMinutesHoursAgo } = props;
  const themeProps = useTheme<HeadlineCardThemeProps>(componentConfigThemeKey);
  const {
    titleFontSizeLarge,
    titleFontSizeMedium,
    titleFontSizeSmall,
    titleSemanticTag,
    metadataSemanticTag,
    metadataFontSizeSmall,
    metadataFontSizeMedium,
    metadataFontSizeLarge,
    showDateInSmallScreen,
    showDateInMediumScreen,
    showDateInLargeScreen,
    showAuthorInSmallScreen,
    showAuthorInMediumScreen,
    showAuthorInLargeScreen,
    showMainCategoryInLargeScreen,
    showMainCategoryInMediumScreen,
    showMainCategoryInSmallScreen,
    isUpdatedAtTimestamp,
    isMainCategoryEnabled,
    isClickableLabels,
  } = themeProps;

  const style = createStyle(themeProps);
  const showAuthor = showAuthorInAnyScreenSize(authors, showAuthorInLargeScreen, showAuthorInMediumScreen, showAuthorInSmallScreen);
  const showDate = showDateInAnyScreenSize(showDateInLargeScreen, showDateInMediumScreen, showDateInSmallScreen);
  const timestamp = isUpdatedAtTimestamp ? updatedAt : createdAt;
  const timestampISO = isUpdatedAtTimestamp ? updatedAtISO : createdAtISO;
  const showMainCategory = isMainCategoryEnabled && mainCategory && mainCategory.displayName && (showMainCategoryInSmallScreen || showMainCategoryInMediumScreen || showMainCategoryInLargeScreen);
  const isMainCategoryClickable = isClickableLabels && mainCategory?.link && mainCategory?.isActive;
  const isAnyAuthorClickable = isClickableLabels && authors?.some(author => !!author.link);
  const isClickableLayout = (showMainCategory && isMainCategoryClickable) || (showAuthor && isAnyAuthorClickable);

  const articleLinkProps = {
    isClickableLayout,
    articleUrl,
    title,
  };

  const mainCategoryProps = {
    isMainCategoryClickable: !!isMainCategoryClickable,
    link: mainCategory?.link,
    displayName: mainCategory?.displayName,
  };

  const authorsProps = {
    isAnyAuthorClickable,
    authors,
  };

  return (
    <article className={css(style.wrapper)}>
      <ArticleLink {...articleLinkProps}>
        <header>
          {showMainCategory ? <MainCategory {...mainCategoryProps} /> : null}
          <DynamicSemanticTag
            semanticTag={titleSemanticTag}
            fontSizeLarge={titleFontSizeLarge}
            fontSizeMedium={titleFontSizeMedium}
            fontSizeSmall={titleFontSizeSmall}
            styles={semanticCSS(style.title)}
          >
            {title}
          </DynamicSemanticTag>
        </header>
        {showMetadataInAnyScreenSize(showAuthor, showDate) ? (
          <footer className={css(style.footer)}>
            <DynamicSemanticTag
              semanticTag={metadataSemanticTag}
              fontSizeLarge={metadataFontSizeLarge}
              fontSizeMedium={metadataFontSizeMedium}
              fontSizeSmall={metadataFontSizeSmall}
              styles={semanticCSS(style.metadata)}
            >
              {showAuthor ? <Authors {...authorsProps} /> : null}
              {showPipeInAnyScreenSize(showAuthor, showDate) ? (<div className={css(style.pipe)}>|</div>) : null}
              {(showDate && timestampISO && timestamp) ? (<time dateTime={timestampISO} className={css(style.date)}>{getFormattedPublishDate(timestampISO, timestamp, useFormatMinutesHoursAgo)}</time>) : null}
            </DynamicSemanticTag>
          </footer>
        ) : null}
      </ArticleLink>
    </article>
  );
};
