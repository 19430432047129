import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { AuthorImage } from '../author/AuthorImage/AuthorImage';
import { Link } from '../../partials/link/Link';
import { H3, H4, P, semanticCSS } from '../../../typography/semanticTags';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { useTheme } from '../../../theming/useTheme';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { componentConfigThemeKey } from './About.theme';
import { twitterIcon } from '../../partials/socialMediaIcon/twitterIcon';
import { createImageWidths } from '../../partials/image/image.utils';
import { Thumbnail } from '../../partials/image/image.types';
import { SvgLink } from '../../wrappers/sectionHeader/SectionHeader';
import { SUB_TITLES } from './About.strings';

const TITLE = SUB_TITLES.MEET_TEAM;
const IMAGE_SIZE = 80;

interface AboutAuthorsThemeProps {
  textColor: string;
  authorColor: string;
  subHeadlineColor: string;
  socialIconsBackgroundColor: string;
  socialIconsNormalColor: string;
  socialIconsHoverColor: string;
}

export interface AboutAuthorsProps {
  authorInfo: {
    name: string;
    image?: Thumbnail;
    socialLinks?: SvgLink[] | null;
    bio?: string;
    link?: string;
  };
}

const getStyles = (
  authorColor: string,
  textColor: string,
  subHeadlineColor: string,
  socialIconsNormalColor: string,
  socialIconsHoverColor: string,
) => StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: '16px',
    borderBottom: '1px solid #E5E5E5',
    [MEDIA_BREAKPOINTS.small]: {
      flexDirection: 'column',
    },
  },
  imageContainer: {
    marginRight: '16px',
    [MEDIA_BREAKPOINTS.small]: {
      display: 'flex',
      marginBottom: '16px',
      alignItems: 'center',
    },
  },
  linkContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 16px 0',
    fontSize: '10px',
    color: authorColor,
    fontFamily: 'Montserrat',
    fontWeight: 700,
    ':nth-child(1n) > div > a': {
      margin: '0 4px 0 0',
    },
  },
  header: {
    marginBottom: '16px',
    color: subHeadlineColor,
  },
  authorColor: {
    color: authorColor,
  },
  title: {
    color: authorColor,
    marginBottom: '16px',
    display: 'block',
    [MEDIA_BREAKPOINTS.small]: {
      display: 'none',
    },
  },
  smallTitle: {
    color: authorColor,
    marginLeft: '16px',
    display: 'none',
    [MEDIA_BREAKPOINTS.small]: {
      display: 'block',
    },
  },
  bio: {
    color: textColor,
    marginBottom: '16px',
  },
  socialIcon: {
    marginRight: '4px',
    width: '14px',
    height: '14px',
    fill: socialIconsNormalColor,
    ':hover': {
      fill: socialIconsHoverColor,
    },
  },
});

const getImageAdditionalStyle = () => {
  return StyleSheet.create({
    wrapper: {
      marginBottom: '16px',
      [MEDIA_BREAKPOINTS.small]: {
        marginBottom: '0',
      },
    },
  });
};

export const AboutAuthors = ({ authors }: { authors: AboutAuthorsProps[] }) => {
  const {
    textColor,
    authorColor,
    subHeadlineColor,
    socialIconsNormalColor,
    socialIconsHoverColor,
  } = useTheme<AboutAuthorsThemeProps>(componentConfigThemeKey);
  const styles = getStyles(
    authorColor,
    textColor,
    subHeadlineColor,
    socialIconsNormalColor,
    socialIconsHoverColor,
  );

  const getTwitterHandle = (href: string) => {
    const ind = href.lastIndexOf('.com/') + 5;
    return href.slice(ind, href.length).replace('/', '');
  };

  return (
    <ErrorBoundary>
      <H3 styles={semanticCSS(styles.header)}>{TITLE}</H3>
      {authors.map((author: AboutAuthorsProps, index) => {
        const { name, bio, socialLinks, image, link } = author.authorInfo;
        return (
          <div className={css(styles.container)} key={index}>
            <div className={css(styles.imageContainer)}>
              {image ? (
                <AuthorImage
                  image={image}
                  wrapperStyles={getImageAdditionalStyle()}
                  widthSizes={{ small: IMAGE_SIZE, medium: IMAGE_SIZE, large: IMAGE_SIZE }}
                  imageWidths={createImageWidths(IMAGE_SIZE, IMAGE_SIZE, IMAGE_SIZE)}
                />
              ) : null}
              <H4 styles={semanticCSS(styles.smallTitle)}>
                <Link href={link} className={css(styles.authorColor)}>{name}</Link>
              </H4>
            </div>
            <div>
              <H4 styles={semanticCSS(styles.title)}>
                <Link href={link} className={css(styles.authorColor)}>{name}</Link>
              </H4>
              <P styles={semanticCSS(styles.bio)}>{bio}</P>
              {socialLinks?.map((social, ind) => {
                if (social?.href.includes('twitter') || social?.href.includes('x.com')) {
                  const svg = twitterIcon.replace(/<svg/g, `<svg class="${css(styles.socialIcon)}"`);
                  return (
                    <Link
                      href={social.href}
                      rel="noopener"
                      key={ind}
                      target="_blank"
                      dangerouslySetInnerHTML={{
                        __html: `${svg}@${getTwitterHandle(social.href)}`,
                      }}
                      alt={social.alt}
                      className={css(styles.linkContainer)}
                    />
                  );
                }

                return null;
              })}
            </div>
          </div>
        );
      })}
    </ErrorBoundary>
  );
};
