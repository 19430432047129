import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Colors } from 'mm-theme-configuration';
import { useTheme } from 'mm-ui-components/src/theming/useTheme';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';

const getStyles = (lineColor: string) => {
  return StyleSheet.create({
    dividerContainer: {
      marginTop: '40px',
      height: '30px',
    },
    divider: {
      position: 'absolute',
      height: '1px',
      width: '100%',
      left: '0',
      margin: 0,
      maxWidth: 'none',
      border: 0,
      backgroundColor: lineColor,
      opacity: 0.5,
      boxShadow: '0px -2px 5px 0px rgba(0, 0, 0, 0.25)',
    },
  });
};

export const ArticleDivider: React.FunctionComponent = () => {
  const { colors }: { colors: Colors } = useTheme();
  const styles = getStyles(colors.grey);
  return (
    <ErrorBoundary>
      <div className={css(styles.dividerContainer)}>
        <hr className={css(styles.divider)} />
      </div>
    </ErrorBoundary>
  );
};
