import * as React from 'react';
import { Kasda } from '../../Kasda/Kasda';

interface BreadCrumbsSchemaDataProps {
  homeDisplayName?: string;
  homeLink?: string;
  categoryDisplayName?: string;
  categoryLink?: string;
  thirdDisplayName?: string;
  thirdLink?: string;
}

const getListElement = (position: number, name: string, item: string) => {
  return {
    '@type': 'ListItem',
    position,
    name,
    item,
  };
};
const getSchemaData = (homeDisplayName: string, homeLink: string, categoryDisplayName: string, categoryLink: string, thirdDisplayName?: string, thirdLink?: string) => {
  const itemListElement = [
    getListElement(1, homeDisplayName, homeLink),
    getListElement(2, categoryDisplayName, categoryLink),
  ];

  if (thirdDisplayName && thirdLink) {
    itemListElement.push(getListElement(3, thirdDisplayName, thirdLink));
  }

  return {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement,
  };
};

export const BreadCrumbsSchema: React.FunctionComponent<BreadCrumbsSchemaDataProps> = props => {
  const {
    homeDisplayName, homeLink, categoryDisplayName, categoryLink, thirdDisplayName, thirdLink,
  } = props;
  const schema = homeDisplayName && homeLink && categoryDisplayName && categoryLink ? getSchemaData(homeDisplayName, homeLink, categoryDisplayName, categoryLink, thirdDisplayName, thirdLink) : {};
  return homeDisplayName && homeLink && categoryDisplayName && categoryLink
    ? (
      <Kasda.Script>
        <script title="Breadcrumbs" type="application/ld+json">{JSON.stringify(schema)}</script>
      </Kasda.Script>
    )
    : null;
};
