import React, { useEffect, useState } from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { AdditionalStyleSheet } from '../../../../components.utils';
import { MenuLinkDataProps } from '../oldHeader/primaryHeader/menu/links/MenuSubLink';
import { MenuLinks } from '../header/menu/MenuLinks';
import { Icon } from '../../../../partials/icon/Icon';
import { CloseIcon, HamburgerIcon } from '../../../../partials/icon/icons';
import { getFillIconStyle } from './header.util';

export interface HamburgerMenuProps {
  links: MenuLinkDataProps[];
  additionalStyle?: AdditionalStyleSheet;
  iconColor: string;
}

export const HamburgerMenu: React.FunctionComponent<HamburgerMenuProps> = props => {
  const { links, additionalStyle, iconColor } = props;

  const [state, setState] = useState({
    isMenuOpen: false,
    currentOpenLink: -1,
  });

  useEffect(() => {
    document.body.style.overflow = state.isMenuOpen ? 'hidden' : 'unset';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [state.isMenuOpen]);

  const { isMenuOpen, currentOpenLink } = state;

  const createStyle = () => {
    return StyleSheet.create({
      button: {
        cursor: 'pointer',
        backgroundColor: 'transparent',
        border: 'none',
      },
    });
  };

  const clickOnMenuIcon = () => {
    setState({
      isMenuOpen: !isMenuOpen,
      currentOpenLink: isMenuOpen ? currentOpenLink : -1,
    });
  };

  const clickOnLinkDropDown = (index: number) => {
    setState({
      isMenuOpen,
      currentOpenLink: currentOpenLink === index ? -1 : index,
    });
  };
  const style = createStyle();
  const iconStyle = getFillIconStyle(iconColor);
  return (
    <div className={css(additionalStyle?.style)}>
      <button className={css(style.button)} type="button" onClick={clickOnMenuIcon}>
        {isMenuOpen
          ? <Icon icon={CloseIcon} additionalStyle={iconStyle} hasButton={false} />
          : <Icon icon={HamburgerIcon} additionalStyle={iconStyle} hasButton={false} />}
      </button>
      <MenuLinks menuLinks={links} isMenuOpen={isMenuOpen} onClick={clickOnLinkDropDown} currentOpenLink={currentOpenLink} isFixedHeights />
    </div>
  );
};
