import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useTheme } from '../../theming/useTheme';
import { ErrorBoundary } from '../errorHandling/ErrorBoundary';
import {
  extractWrapperAndContentFromHtml,
  MandatoryAdditionalStyle,
} from '../components.utils';
import { getFontSizesBySemanticTag, getStyleBySize } from '../../typography/semanticTags/semanticTags.utils';
import { TranslatedFontSize } from '../../theming/fontSizeTranslator';
import { BlockWidths } from '../components/pageLayouts/BlockTypes.utils';
import { createBlockStyle } from './blockMutualStyle';
import { ArticleBlockThemeProps, componentConfigurationKey } from './ArticleBlock.theme';
import { baseUnit } from '../../theming/baseUnitDefinition';
import { MEDIA_BREAKPOINTS } from '../../mediaQueries.const';

interface ImportedTableComponentDataProps {
  html: string;
  dataId?: string;
  defaultBlocksWidths: BlockWidths;
}

type ImportedTableComponentProps = ImportedTableComponentDataProps & MandatoryAdditionalStyle;

const getWrapperStyle = (borderColor: string) => StyleSheet.create({
  style: {
    width: '100%',
    border: `1px solid ${borderColor}`,
    borderCollapse: 'collapse',
  },
}).style;


const getStyle = (borderColor: string, rowColor: string, linkColor: string) => {
  return StyleSheet.create({
    th: {
      borderBottom: `1px solid ${borderColor}`,
      borderRight: `1px solid ${borderColor}`,
      padding: 0.75 * baseUnit,
    },
    tr: {
      ':nth-child(even)': {
        backgroundColor: rowColor,
      },
    },
    td: {
      borderRight: `1px solid ${borderColor}`,
      textAlign: 'center',
      padding: 0.25 * baseUnit,
    },
    a: {
      color: linkColor,
      textDecoration: 'none',
    },
  });
};

const applyCssClasses = (html: string, fontSizesStyles: TranslatedFontSize, borderColor: string, rowColor: string, linkColor: string): string => {
  const style = getStyle(borderColor, rowColor, linkColor);
  const typographyStyleP = getStyleBySize(fontSizesStyles, getFontSizesBySemanticTag('p'));
  const typographyStyleHead = getStyleBySize(fontSizesStyles, getFontSizesBySemanticTag('h6'));

  return html
    .replace(/<tr/g, `<tr class="${css(style.tr)}"`)
    .replace(/<th>/g, `<th class="${css(typographyStyleHead, style.th)}">`)
    .replace(/<td/g, `<td class="${css(typographyStyleP, style.td)}"`)
    .replace(/<a/g, `<a class="${css(typographyStyleP, style.a)}"`);
};


const getBlockStyle = (defaultBlocksWidths: BlockWidths) => (StyleSheet.create({
  style: {
    overflowWrap: 'break-word',
    ...createBlockStyle(defaultBlocksWidths),
  },
}).style);

const getScrollStyle = () => (StyleSheet.create({
  style: {
    overflowX: 'scroll',
    width: '100%',
    [MEDIA_BREAKPOINTS.large]: {
      '::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
}).style);

export const ImportedTable: React.FunctionComponent<ImportedTableComponentProps> = props => {
  const { html, additionalStyle, dataId, defaultBlocksWidths } = props;
  const { fontSizesStyles, colors, paragraphLinkColor } = useTheme<ArticleBlockThemeProps>(componentConfigurationKey);
  const { grey: borderColor, lightGrey: rowColor } = colors;
  const { wrapper, content } = extractWrapperAndContentFromHtml(html);
  const Wrapper = wrapper;
  const wrapperStyle = getWrapperStyle(borderColor);
  const styledHtml = applyCssClasses(content, fontSizesStyles, borderColor, rowColor, paragraphLinkColor);

  return (
    <ErrorBoundary>
      <div className={css(getBlockStyle(defaultBlocksWidths))}>
        <div className={css(getScrollStyle())}>
          <Wrapper
            {...{
              dangerouslySetInnerHTML: { __html: styledHtml },
              className: css(wrapperStyle, additionalStyle.style),
              'data-mm-id': dataId,
            }}
          />
        </div>
      </div>
    </ErrorBoundary>
  );
};
