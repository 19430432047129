export const bannerConfigThemeKey = 'banner';

export interface BannerHeightThemeProps {
  imageHeightSmall?: number;
  imageHeightMedium?: number;
  imageHeightLarge?: number;
}
export interface BannerThemeProps extends BannerHeightThemeProps {
  isFullScreenWidth: boolean;
}
