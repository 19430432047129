import React from 'react';
import { Link } from '../../../partials/link/Link';
import { Span } from '../../../../typography/semanticTags';
import { useTheme } from '../../../../theming/useTheme';
import { ArticleBlockThemeProps, componentConfigurationKey } from '../../../articleComponents/ArticleBlock.theme';

interface BreadcrumbItemProps {
  path: string;
  linkStyle: string;
  displayName: string;
}

export const BreadcrumbItem = ({
  path,
  linkStyle,
  displayName,
}: BreadcrumbItemProps) => {
  const {
    breadCrumbsFontSizeLarge,
    breadCrumbsFontSizeMedium,
    breadCrumbsFontSizeSmall,
  } = useTheme<ArticleBlockThemeProps>(componentConfigurationKey);

  return (
    <Link href={path} className={linkStyle}>
      <Span
        fontSizeLarge={breadCrumbsFontSizeLarge}
        fontSizeMedium={breadCrumbsFontSizeMedium}
        fontSizeSmall={breadCrumbsFontSizeSmall}
      >
        {displayName}
      </Span>
    </Link>
  );
};
