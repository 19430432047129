import { useSelector } from 'react-redux';
import { ThemeProviderWithFonts, WebFonts, AmpWebFonts } from 'mm-ui-components';
import React from 'react';
import { getPalette, getThemeConfiguration } from '../../store/config/config.selectors';

const WebThemeComponent = ThemeProviderWithFonts(WebFonts);
export const Theme: React.FunctionComponent = ({ children }) => {
  const themeConfiguration = useSelector(getThemeConfiguration);
  const palette = useSelector(getPalette);
  return <WebThemeComponent {...{ themeConfiguration, palette, children }} />;
};

const AmpThemeComponent = ThemeProviderWithFonts(AmpWebFonts);
export const AmpTheme: React.FunctionComponent = ({ children }) => {
  const themeConfiguration = useSelector(getThemeConfiguration);
  const palette = useSelector(getPalette);
  return <AmpThemeComponent {...{ themeConfiguration, palette, children }} />;
};
