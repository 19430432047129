import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useTheme } from '../../theming/useTheme';
import { ViewabilityProvider } from '../../viewability/viewabilityHandler';

const componentConfigurationKey = 'postMainContent';

interface PostMainContentThemeProps {
    backgroundColor: string;
}

const createEmbedPostStyle = (backgroundColor: string) => StyleSheet.create({
  style: {
    backgroundColor,
    overflow: 'hidden',
    maxWidth: '100%',
    minWidth: '100%',
  },
});

export const EmbedPostMainContent: React.FunctionComponent = ({ children }) => {
  const { backgroundColor } = useTheme<PostMainContentThemeProps>(componentConfigurationKey);

  return (
    <ViewabilityProvider>
      <article className={css(createEmbedPostStyle(backgroundColor).style)}>
        {children}
      </article>
    </ViewabilityProvider>
  );
};
