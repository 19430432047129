import { TypographySize } from 'mm-theme-configuration/dist/consts';
import { TranslatedFontSize } from '../../../theming/fontSizeTranslator';

export const networkLinkButtonConfigThemeKey = 'networkLinkButton';
export const networkLinkButtonListConfigThemeKey = 'networkLinkButtonList';

export interface ThemeColorProps {
  textColorHover: string;
  textColorNormal: string;
  borderColorHover: string;
  borderColorNormal: string;
  backgroundColorHover: string;
  backgroundColorNormal: string;
}

export interface NetworkLinkButtonThemeProps extends ThemeColorProps {
  fontSizeLarge: TypographySize;
  fontSizeMedium: TypographySize;
  fontSizeSmall: TypographySize;
  fontSizesStyles: TranslatedFontSize;
  borderRadius: number;
}
