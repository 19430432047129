import React from 'react';
import { useTheme } from '../../../../../theming/useTheme';

interface Ratio {
  x: number;
  y: number;
}

export interface AnimationLogoHeightsAndWidths {
  height: number;
  reducedHeight: number;
  width: number;
  reducedWidth: number;
}

const reducedLogoHeightFactor = 0.5;

const calculateImageLogoAspectRatio = (logoElement: HTMLImageElement) => {
  return { x: logoElement.getBoundingClientRect().width, y: logoElement.getBoundingClientRect().height };
};

const calculateVideoLogoAspectRatio = (logoElement: HTMLVideoElement) => {
  return { x: logoElement.videoWidth, y: logoElement.videoHeight };
};

export const useAnimationLogoHeightsAndWidths = (initialLogoHeight: number) => {
  const [logoAspectRatio, setLogoAspectRatio] = React.useState<Ratio | null>(null);
  const { baseUnit } = useTheme();
  const ref = React.useCallback(node => {
    if (node) {
      if (node.tagName === 'VIDEO') {
        node.addEventListener('loadedmetadata', () => {
          setLogoAspectRatio(calculateVideoLogoAspectRatio(node));
        });
      } else {
        setLogoAspectRatio(calculateImageLogoAspectRatio(node));
      }
    }
  }, []);

  const animationLogoHeights = {
    height: baseUnit * initialLogoHeight,
    reducedHeight: baseUnit * initialLogoHeight * reducedLogoHeightFactor,
  };

  const animationLogoWidths = {
    width: logoAspectRatio ? animationLogoHeights.height * (logoAspectRatio.x / logoAspectRatio.y) : -1,
    reducedWidth: logoAspectRatio ? animationLogoHeights.reducedHeight * (logoAspectRatio.x / logoAspectRatio.y) : -1,
  };

  return [ref, { ...animationLogoHeights, ...animationLogoWidths }] as [typeof ref, AnimationLogoHeightsAndWidths];
};
