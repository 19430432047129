import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { useTheme } from '../../theming/useTheme';
import { BaseUnit } from '../../theming/baseUnitDefinition';

const componentConfigThemeKey = 'playIcon';

interface PlayIconComponentThemeProps {
  baseUnit: BaseUnit;
  backgroundColor: string;
  triangleColor: string;
}

const playIconStyle = ({ baseUnit, backgroundColor, triangleColor }: PlayIconComponentThemeProps) => StyleSheet.create({
  wrapper: {
    height: '15%',
    minHeight: '24px',
    width: 'auto',
    position: 'absolute',
    top: `${baseUnit * 0.75}px`,
    left: `${baseUnit * 0.75}px`,
  },
  circle: {
    fill: backgroundColor,
  },
  polygon: {
    fill: triangleColor,
  },
});

export const PlayIcon: React.FunctionComponent = () => {
  const themeProps = useTheme<PlayIconComponentThemeProps>(componentConfigThemeKey);
  const style = playIconStyle(themeProps);
  return (
    <svg className={css(style.wrapper)} viewBox="0 0 64 64" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Icon / Video</title>
      <desc>Created with Sketch.</desc>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <circle className={css(style.circle)} fill="#FE5000" cx="32" cy="32" r="32" />
          <polygon className={css(style.polygon)} fill="#FFFFFF" transform="translate(34.000000, 32.000000) rotate(90.000000) translate(-34.000000, -32.000000) " points="34 20 46 44 22 44" />
        </g>
      </g>
    </svg>
  );
};
