import { Store } from 'redux';
import { AppActions, AppState } from '../../../store/initialStateRegistration';
import { ClientAPIReadEventNames } from '../handlerCreators';

export type ReadEventHandler = (state: AppState) => void

export const readEventErrorReporter = (eventName: ClientAPIReadEventNames, readEventHandler: ReadEventHandler) => {
  return (store: Store<AppState, AppActions>) => (callback: Function) => {
    try {
      callback(readEventHandler(store.getState()));
    } catch (e) {
      console.error(`ClientAPI Error in callback for "${eventName}" event. Please check the callback value.`, e);
    }
  };
};
