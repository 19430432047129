import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { RATIO } from 'mm-theme-configuration/dist/consts';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { createImageWidths, ImageWidths } from '../../partials/image/image.utils';
import { baseUnit } from '../../../theming/baseUnitDefinition';
import { useTheme } from '../../../theming/useTheme';
import { HorizontalCard } from '../../partials/cards/horizontalCard/HorizontalCard';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { getLayoutComponentPadding, LayoutComponentBaseProps } from '../pageLayouts/layout.utils';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { HorizontalCardThemeProps, componentConfigThemeKey as horizontalCardConfigThemeKey } from '../../partials/cards/horizontalCard/horizontalCard.theme';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';

export const componentConfigThemeKey = 'expandableHC';

interface ExpandableHCDataProps extends LayoutComponentBaseProps {
    cards: CardComponentDataProps[];
    imageWidths?: ImageWidths;
    showImageHeightByAspectRatio?: boolean;
    imageAspectRatio?: RATIO;
    useFormatMinutesHoursAgo?: boolean;
}

export interface ExpandableHCThemeProps {
    largeHorizontalGap: number;
    mediumHorizontalGap: number;
    smallHorizontalGap: number;
    showDivider: boolean;
    dividerColor: string;
    showDescriptionInLargeScreen: boolean;
    showDescriptionInMediumScreen: boolean;
    showDescriptionInSmallScreen: boolean;
    titleHoverColor: string;
}

const getStyle = (themeProps: ExpandableHCThemeProps) => {
  const { largeHorizontalGap, mediumHorizontalGap, smallHorizontalGap, dividerColor, showDivider } = themeProps;
  return StyleSheet.create({
    wrapper: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto',
      height: 'fit-content',
      [MEDIA_BREAKPOINTS.large]: {
        gridRowGap: `${showDivider ? 0 : largeHorizontalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        gridRowGap: `${showDivider ? 0 : mediumHorizontalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        gridRowGap: `${showDivider ? 0 : smallHorizontalGap * baseUnit}px`,
      },
    },
    divider: {
      height: '1px',
      width: '100%',
      border: 0,
      backgroundColor: dividerColor,
      [MEDIA_BREAKPOINTS.large]: {
        margin: `${(largeHorizontalGap * baseUnit) / 2}px 0`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        margin: `${(mediumHorizontalGap * baseUnit) / 2}px 0`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        margin: `${(smallHorizontalGap * baseUnit) / 2}px 0`,
      },
    },
  });
};

const defaultImageWidths = createImageWidths(180, 180, 360);

export const ExpandableHC: React.FunctionComponent<ExpandableHCDataProps> = props => {
  const { cards, paddingFactors, imageWidths = defaultImageWidths, showImageHeightByAspectRatio, imageAspectRatio, useFormatMinutesHoursAgo = false } = props;
  const themeProps = useTheme<ExpandableHCThemeProps>(componentConfigThemeKey);
  const horizontalCardThemeContext = useThemeOverride<HorizontalCardThemeProps>({
    showImageHeightByAspectRatio,
    ...(imageAspectRatio && { aspectRatioSmall: imageAspectRatio }),
    ...(imageAspectRatio && { aspectRatioMedium: imageAspectRatio }),
    ...(imageAspectRatio && { aspectRatioLarge: imageAspectRatio }),
    showDescriptionInLargeScreen: themeProps.showDescriptionInLargeScreen,
    showDescriptionInMediumScreen: themeProps.showDescriptionInMediumScreen,
    showDescriptionInSmallScreen: themeProps.showDescriptionInSmallScreen,
    cardTitleColorTypeHover: themeProps.titleHoverColor,
  }, horizontalCardConfigThemeKey);
  const style = getStyle(themeProps);
  const paddingStyle = getLayoutComponentPadding(paddingFactors);

  return (
    <ErrorBoundary>
      {cards && cards.length > 0
                && (
                <div className={css(paddingStyle, style.wrapper)}>
                  <ThemeContext.Provider value={horizontalCardThemeContext}>
                    {cards.map(((card, index) => (
                      <React.Fragment key={index}>
                        <HorizontalCard key={card.id} {...card} imageWidths={imageWidths} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />
                        {index === cards.length - 1 || !themeProps.showDivider ? null : (
                          <hr className={css(style.divider)} />
                        )}
                      </React.Fragment>
                    )))}
                  </ThemeContext.Provider>
                </div>
                )}
    </ErrorBoundary>
  );
};
