import { css, StyleSheet } from 'aphrodite/no-important';
import * as React from 'react';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { useTheme } from '../../../theming/useTheme';
import { generateLinkStyleFromTheme, TextLinkComponent } from '../../partials/link/TextLink';
import {
  MenuLinkDataProps,
  MenuSubLink,
} from '../../components/navigation/header/oldHeader/primaryHeader/menu/links/MenuSubLink';
import { ChevronIcon, Directions } from '../../components/navigation/header/ChevronIcon';
import { H4, semanticCSS } from '../../../typography/semanticTags';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { componentConfigThemeKey, HeaderThemeProps } from '../../components/navigation/header/Header.theme';
import { AdditionalStyleSheet } from '../../components.utils';

/* eslint-disable react/no-unknown-property */

const getAmpSideBarLinkStyle = (baseUnit: BaseUnit, idleColor: string) => StyleSheet.create({
  wrapper: {
    [MEDIA_BREAKPOINTS.medium]: {
      margin: `${baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      margin: `${baseUnit}px`,
    },
  },
  label: {
    color: idleColor,
    flex: '0 0 auto',
    display: 'inline-flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100%',
  },
  title: {
    backgroundColor: 'inherit',
    border: 'inherit',
    paddingRight: 0,
    ':focus': {
      outline: 'none',
    },
  },
  titleAndChevronIconWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  subLinksList: {
    marginLeft: '10px',
  },
});

const createStyle = () => StyleSheet.create({
  style: {
    marginTop: '20px',
  },
}) as AdditionalStyleSheet;

const createAmpSideBarSubLink = (linkData: MenuLinkDataProps, index: number) => {
  const { link } = linkData;
  return <MenuSubLink text={link.text} href={link.href} key={index} additionalStyle={createStyle()} />;
};

export const AMPSideBarLink: React.FunctionComponent<MenuLinkDataProps> = props => {
  const { hamburgerMenuLinksNormalColor, baseUnit } = useTheme<HeaderThemeProps>(componentConfigThemeKey);
  const { link, childrenLinks } = props;
  const { href } = link;
  const isFolder = href === '-';
  const style = getAmpSideBarLinkStyle(baseUnit, hamburgerMenuLinksNormalColor);
  const linkStyle = generateLinkStyleFromTheme({ idleColor: hamburgerMenuLinksNormalColor });

  return (
    <section className={css(style.wrapper)}>
      <H4 styles={semanticCSS(style.title)}>
        <div className={css(style.titleAndChevronIconWrapper)}>
          {isFolder ? <div className={css(style.label)}>{link.text}</div> : <TextLinkComponent href={href} linkStyle={linkStyle}>{link.text}</TextLinkComponent>}
          {childrenLinks && childrenLinks.length > 0 ? <ChevronIcon direction={Directions.DOWN} color={hamburgerMenuLinksNormalColor} /> : null}
        </div>
      </H4>
      <ul className={css(style.subLinksList)}>
        {childrenLinks && childrenLinks.map(createAmpSideBarSubLink)}
      </ul>
    </section>
  );
};
