import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

const wasRenderedInServer = (() => {
  if (typeof window !== 'undefined') {
    return document.querySelector('html[kasda]') || false;
  }

  return false;
})();

const isClient = typeof window !== 'undefined';

interface KasdaContent {
  html: string;
  title: string;
  meta: string[];
  links: string[];
  scripts: string[];
  styles: string[];
  noscript: string[];
  htmlAttributes: string;
  bodyAttributes: string;
}

interface FlexibleProps {
  [key: string]: string | number;
}

interface KasdaProps {
  children: React.ReactNode;
  marker?: string;
}

interface TitleProps {
  title: string;
}

type KasdaElement = 'title' | 'meta' | 'link' | 'script' | 'style' | 'noscript' | 'html-attributes' | 'body-attributes';

const decodeHtml = (html: string) => {
  return html
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&#039;/g, "'")
    .replace(/&#x27;/g, "'")
    .replace(/&amp;/g, '&');
};

const simpleHash = (str: string) => {
  let hash = 5381;
  for (let i = 0; i < str.length; i += 1) {
    // eslint-disable-next-line no-bitwise
    hash = ((hash << 5) + hash) + str.charCodeAt(i);
  }
  // eslint-disable-next-line no-bitwise
  const result = hash >>> 0;
  return result.toString(16);
};

const KasdaContent = (type: KasdaElement, html: string) => {
  if (wasRenderedInServer) return null;

  if (isClient) {
    const div = document.createElement('div');
    div.innerHTML = decodeHtml(html);
    div.childNodes.forEach(node => {
      if (node && node instanceof HTMLElement) {
        const tagName = node.tagName.toLowerCase();
        let signature = tagName;

        const newNode = document.createElement(tagName);
        newNode.textContent = node.textContent;
        Array.from(node.attributes).forEach(attr => {
          newNode.setAttribute(attr.name, attr.value);
          signature += attr.name + attr.value;
        });

        const hash = simpleHash(signature);

        if (document.querySelector(`[data-kasda-hash="${hash}"]`)) return;

        newNode.setAttribute('data-kasda-hash', hash);

        document.head.appendChild(newNode);
      }
    });

    return null;
  }

  return (
    // @ts-ignore
    React.createElement(`kasda-${type}`, {
      dangerouslySetInnerHTML: { __html: decodeHtml(html) },
    })
  );
};

const addMissingProps = (element: React.ReactElement, renderedElement: string) => {
  if (!element) return renderedElement;

  const { props } = element;

  const tagRegex = /<([a-zA-Z]+)[\s>]/;

  let result = renderedElement;

  Object.entries(props).forEach(([key, value]) => {
    if (key === 'children') return;
    if (result.includes(key) || result.includes(key.toLowerCase())) return;

    const match = result.match(tagRegex);
    if (!match) return;

    result = result.replace(match[1], `${match[1]} ${key.toLowerCase()}="${value}"`);
  });

  return result;
};

const renderChildrenToString = (children: React.ReactNode, marker?: string) => {
  return React.Children.map(children as React.ReactElement[], child => {
    if (marker) {
      const enhancedChild = React.cloneElement(child as React.ReactElement, { 'kasda-marker': marker });
      const rendered = renderToStaticMarkup(enhancedChild);
      return addMissingProps(enhancedChild, rendered);
    }

    const rendered = renderToStaticMarkup(child);
    return addMissingProps(child, rendered);
  }).join('\n');
};

const Title: React.FC<TitleProps> = ({ title }) => {
  if (wasRenderedInServer) return null;

  if (isClient) {
    document.title = title;
    return null;
  }
  // @ts-ignore
  return <kasda-title><title>{title}</title></kasda-title>;
};

const Meta: React.FC<KasdaProps> = ({ children, marker }) => {
  return KasdaContent('meta', renderChildrenToString(children, marker));
};

const Link: React.FC<KasdaProps> = ({ children, marker }) => {
  return KasdaContent('link', renderChildrenToString(children, marker));
};

const Script: React.FC<KasdaProps> = ({ children, marker }) => {
  return KasdaContent('script', renderChildrenToString(children, marker));
};

const Style: React.FC<KasdaProps> = ({ children, marker }) => {
  return KasdaContent('style', renderChildrenToString(children, marker));
};

const NoScript: React.FC<KasdaProps> = ({ children, marker }) => {
  return KasdaContent('script', renderChildrenToString(children, marker));
};

const HtmlAttributes: React.FC<FlexibleProps> = props => {
  if (wasRenderedInServer) return null;

  if (isClient) {
    const html = document.getElementsByTagName('html')[0];
    Object.entries(props).forEach(([key, value]) => {
      html.setAttribute(key, value as string);
    });

    return null;
  }
  // @ts-ignore
  return <kasda-html-attributes>{ JSON.stringify(props) }</kasda-html-attributes>;
};

const BodyAttributes: React.FC<FlexibleProps> = props => {
  if (wasRenderedInServer) return null;

  if (isClient) {
    const body = document.getElementsByTagName('body')[0];
    Object.entries(props).forEach(([key, value]) => {
      body.setAttribute(key, value as string);
    });

    return null;
  }
  // @ts-ignore
  return <kasda-body-attributes>{ JSON.stringify(props) }</kasda-body-attributes>;
};

export const Kasda = {
  Title,
  Meta,
  Link,
  Script,
  Style,
  NoScript,
  HtmlAttributes,
  BodyAttributes,
};
