import { Cover } from '../store/template/postPage/postPage.reducer';

export interface Style {
    [key: string]: string;
}

export interface AdditionalStyleSheet {
    style: Style;
}

function addLeadingZero(number: number) {
    return number < 10 ? `0${number}` : number;
}

function monthShouldStartFromOne(date: number) {
    return date + 1;
}

export function formatDate(receivedDate: string) {
    const date = new Date(receivedDate);
    const month = addLeadingZero(monthShouldStartFromOne(date.getUTCMonth()));
    const day = addLeadingZero(date.getUTCDate());
    const hours = addLeadingZero(date.getUTCHours());
    const minutes = addLeadingZero(date.getUTCMinutes());
    return `${date.getUTCFullYear()}${month}${day}${hours}${minutes}`;
}

export const getCoverType = (cover: Cover) => {
    if (cover.media) {
        return cover.media.type;
    }
    return 'image';
};

export function getCookieByName(name: string) {
    // @ts-ignore
    return (document.cookie.match(`(^|; )${name}=([^;]*)`) || 0)[2];
}

export function getCountryCode() {
    return getCookieByName('countryCode');
}

export const getWazimoParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return {
        emailSlice: urlParams.get('wzslice'),
        wzguid: urlParams.get('wzguid') || JSON.parse(getCookieByName('_wzem.session') || null),
    };
};

export const buildAmpAdJsonData = (articleId: string, contentTags: Array<string>, distributionChannels: Array<string>, property: string, siteName: string, slug: string) => {
    const obj = {
        targeting: {
            articleId,
            category: slug,
            contentTags,
            distributionChannels,
            siteID: property,
            siteName,
        },
    };
    return JSON.stringify(obj);
};

export const getPageMetaText = (title: string, description: string, currentPage = 1) => (
    currentPage === 1
        ? { title, description }
        : { title: `${title} Page ${currentPage}`, description: `${description} Page ${currentPage}` }
);
