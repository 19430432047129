import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { BaseUnit } from '../../../../theming/baseUnitDefinition';
import { useTheme } from '../../../../theming/useTheme';
import { Span } from '../../../../typography/semanticTags';
import { MEDIA_BREAKPOINTS } from '../../../../mediaQueries.const';
import { LinkWithButtonStyle } from '../../../components/buttons/LinkWithButtonStyle';
import { AdditionalStyleSheet } from '../../../components.utils';
import { ArticleBlockThemeProps, componentConfigurationKey } from '../../ArticleBlock.theme';

interface SlideshoBottomBarProps {
  nextLink?: string;
  nextText: string;
}

const getStyles = (baseUnit: BaseUnit) => {
  return StyleSheet.create({
    wrapper: {
      display: 'flex',
      justifyContent: 'center',
      margin: 'auto',
      [MEDIA_BREAKPOINTS.large]: {
        marginTop: `${baseUnit * 4}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        marginTop: `${baseUnit * 2}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        marginTop: `${baseUnit}px`,
      },
    },
  });
};

const getLinkStyle = (
  baseUnit: BaseUnit,
  nextDisabledBackgroundColor: string,
  nextBackgroundColor: string,
  nextDisabledTextColor: string,
  nextTextColor: string,
  isDisabled: boolean,
) => {
  return StyleSheet.create({
    style: {
      padding: `${baseUnit / 2}px ${baseUnit * 4}px`,
      textAlign: 'center',
      backgroundColor: isDisabled ? nextDisabledBackgroundColor : nextBackgroundColor,
      color: isDisabled ? nextDisabledTextColor : nextTextColor,
    },
  }) as AdditionalStyleSheet;
};

export const SlideshowBottomBarAmp: React.FunctionComponent<SlideshoBottomBarProps> = ({ nextLink, nextText }) => {
  const { baseUnit, slideshowButtonBackgroundColor, slideshowButtonDisabledBackgroundColor, slideshowButtonDisabledTextColor, slideshowButtonTextColor } = useTheme<ArticleBlockThemeProps>(componentConfigurationKey);
  const isDisabled = !nextLink;

  const styles = getStyles(baseUnit);
  const linkStyle = getLinkStyle(baseUnit, slideshowButtonDisabledBackgroundColor, slideshowButtonBackgroundColor, slideshowButtonDisabledTextColor, slideshowButtonTextColor, isDisabled);

  return (
    <div className={css(styles.wrapper)}>
      <LinkWithButtonStyle href={nextLink} additionalStyle={linkStyle} disabled={isDisabled}>
        <Span>{nextText}</Span>
      </LinkWithButtonStyle>
    </div>
  );
};
