import * as React from 'react';
import { css } from 'aphrodite/no-important';
import {
  SemanticTagDataProps,
  SemanticTagThemeProps,
  getStyleBySize,
  getFontSizesBySemanticTag,
  sanitizeAttributes,
} from '../semanticTags/semanticTags.utils';
import { useTheme } from '../../theming/useTheme';

export const H4: React.FunctionComponent<SemanticTagDataProps> = props => {
  const defaultFontSizes = getFontSizesBySemanticTag('h4');
  const { fontSizesStyles } = useTheme<SemanticTagThemeProps>();
  const {
    children,
    styles = [],
    fontSizeSmall = defaultFontSizes.fontSizeSmall,
    fontSizeMedium = defaultFontSizes.fontSizeMedium,
    fontSizeLarge = defaultFontSizes.fontSizeLarge,
    ...attributes
  } = props;
  const styleBySize = getStyleBySize(fontSizesStyles, { fontSizeSmall, fontSizeMedium, fontSizeLarge });

  return (
    <h4 className={css(styleBySize, ...styles)} {...sanitizeAttributes(attributes)}>{children}</h4>
  );
};
