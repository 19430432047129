import { URLParser } from '../utils/URLParser';

export const pathnameIncludesAfterKey = (key) => {
  return (traffic) => {
    const urlObject = new URLParser(traffic.url);
    const pathnameParts = urlObject.pathname.split('/');
    const keyIndex = pathnameParts.indexOf(key);
    return keyIndex !== -1 && keyIndex !== pathnameParts.length - 1;
  };
};
