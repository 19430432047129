import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { OutbrainWidget } from 'react-outbrain-widget';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { useTheme } from '../../../theming/useTheme';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { injectScriptTagToHead } from '../../components.utils';

interface RecommendationsAdvertisementProps {
  outbrainWidgetID: string;
  pageLiveUrl: string;
  disableDefaultStyle?: boolean;
  experiment?: string;
}

interface RecommendationsAdvertisementThemeProps {
  baseUnit: BaseUnit;
}


export const RecommendationsAdvertisement: React.FunctionComponent<RecommendationsAdvertisementProps> = props => {
  const { outbrainWidgetID, pageLiveUrl, disableDefaultStyle, experiment } = props;
  const createStyle = (baseUnit: BaseUnit) => {
    return StyleSheet.create({
      style: {
        paddingTop: `${baseUnit * 2}px`,
        paddingLeft: `${baseUnit}px`,
        paddingRight: `${baseUnit}px`,
        [MEDIA_BREAKPOINTS.large]: {
          paddingLeft: `${baseUnit + 80}px`,
        },
      },
    });
  };

  React.useEffect(() => {
    injectScriptTagToHead('https://widgets.outbrain.com/outbrain.js', 'text/javascript');
  }, []);

  const { baseUnit } = useTheme<RecommendationsAdvertisementThemeProps>();

  const style = createStyle(baseUnit);
  const wrapperProps = disableDefaultStyle ? {} : { className: css(style.style) };
  return (
    <ErrorBoundary>
      <div {...wrapperProps}>
        <OutbrainWidget className="outbrainEl" dataSrc={pageLiveUrl} dataWidgetId={outbrainWidgetID} externalId={experiment} />
      </div>
    </ErrorBoundary>
  );
};
