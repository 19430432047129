import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Colors } from 'mm-theme-configuration';
import { Icon } from '../../partials/icon/Icon';
import { SixtySwimsuitIcon } from '../../partials/icon/icons';
import { AdditionalStyleSheet } from '../../components.utils';
import { useTheme } from '../../../theming/useTheme';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { BREAKING_BARRIERS, CELEBRATE, EMPOWERING_WOMEN, MAKING_HISTORY, YEARS_OF } from './animatedBanner.const';


export const getFillAndStrokeIconStyle = () => StyleSheet.create({
  style: {
    width: '87px',
    height: '87px',
    margin: '0 8px',
    [MEDIA_BREAKPOINTS.medium]: {
      width: '55px',
      height: '55px',
    },
    [MEDIA_BREAKPOINTS.small]: {
      width: '30px',
      height: '30px',
    },

  },
}) as AdditionalStyleSheet;


export const getBannerKeyframes = () => {
  return {
    '0%': {
      transform: 'translateY(-80px)',
      opacity: 1,
    },
    '9%': {
      opacity: 1,
      transform: 'translateY(-10px)',
    },
    '13%': {
      opacity: 1,
      transform: 'translateY(-13px)',
    },
    '18%': {
      opacity: 1,
      transform: 'translateY(-10px)',
    },
    '20%': {
      opacity: 1,
      transform: 'translateY(-10px)',
    },
    '25%': {
      opacity: 1,
      transform: 'translateY(-10px)',
    },
    '29%': {
      opacity: 1,
      transform: 'translateY(30px)',
    },
    '31%': {
      opacity: 1,
      transform: 'translateY(40px)',
    },
    '34%': {
      opacity: 1,
      transform: 'translateY(60px)',
    },
    '37%': {
      opacity: 1,
      transform: 'translateY(70px)',
    },
    '40%': {
      opacity: 1,
      transform: 'translateY(80px)',
    },
    '80%': {
      opacity: 0,
      transform: 'translateY(100px)',
    },
  };
};

const getAnimatedBannerStyles = (colors: Colors) => {
  return StyleSheet.create({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      padding: '40px 0',
      background: 'linear-gradient(0deg, rgba(241, 230, 221, 0.00) 75.08%, #F1E6DD 104.33%)',
      [MEDIA_BREAKPOINTS.medium]: {
        padding: '20px 0',
      },
      [MEDIA_BREAKPOINTS.small]: {
        padding: '20px 0',
      },
    },
    divWrap: {
      overflow: 'hidden',
    },
    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    textWrapper: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      flexDirection: 'column',

    },
    animationWrapper: {
      position: 'relative',
      transform: 'translate(0, 0)',
      height: 'auto',
      width: '-webkit-fill-available',
      overflow: 'hidden',
      padding: '30px',
      [MEDIA_BREAKPOINTS.medium]: {
        padding: '20px',
      },
      [MEDIA_BREAKPOINTS.small]: {
        padding: '15px',
      },
    },
    text: {
      color: colors.primary,
      fontFamily: 'nunito',
      fontSize: '35px',
      fontWeight: 600,
      margin: '0px',
      position: 'absolute',
      animationDuration: '6s',
      opacity: 0,
      animationIterationCount: 'infinite',
      animationTimingFunction: 'ease-in-out',
      [MEDIA_BREAKPOINTS.medium]: {
        fontSize: '25px',
      },
      [MEDIA_BREAKPOINTS.small]: {
        fontSize: '20px',
      },
    },
    title: {
      color: colors.darkGrey,
      fontFamily: 'nunito',
      fontSize: '50px',
      fontWeight: 600,
      lineHeight: '40px',
      margin: '30px 0',
      [MEDIA_BREAKPOINTS.medium]: {
        fontSize: '35px',
        margin: '20px 0',
      },
      [MEDIA_BREAKPOINTS.small]: {
        fontSize: '24px',
        margin: '10px 0',
      },
    },
    animateFirstWord: {
      animationDelay: '0s',
      animationName: getBannerKeyframes(),
    },
    animateSecondWord: {
      animationDelay: '1.95s',
      animationName: getBannerKeyframes(),
    },
    animateThirdWord: {
      animationDelay: '3.9s',
      animationName: getBannerKeyframes(),
    },
  });
};


export const AnimatedBanner = () => {
  const { colors }: { colors: Colors } = useTheme();
  const styles = getAnimatedBannerStyles(colors);
  return (
    <div className={css(styles.wrapper)}>
      <div className={css(styles.titleWrapper)}>
        <span className={css(styles.title)}>{CELEBRATE}</span>
        <Icon icon={SixtySwimsuitIcon} hasButton={false} additionalStyle={getFillAndStrokeIconStyle()} />
        <p className={css(styles.title)}>{YEARS_OF}</p>
      </div>
      <div className={css(styles.animationWrapper)}>
        <div className={css(styles.textWrapper)}>
          <p className={css(styles.text, styles.animateFirstWord)}>{MAKING_HISTORY}</p>
          <p className={css(styles.text, styles.animateSecondWord)}>{BREAKING_BARRIERS}</p>
          <p className={css(styles.text, styles.animateThirdWord)}>{EMPOWERING_WOMEN}</p>
          <div />
        </div>
      </div>
    </div>

  );
};
