import React, { useState, useEffect } from 'react';
import { css } from 'aphrodite/no-important';
import { Colors } from 'mm-theme-configuration';
import { TYPOGRAPHY_SIZE } from 'mm-theme-configuration/dist/consts';
import { H2, H4 } from '../../../typography/semanticTags';
import { generateLinkStyleFromTheme, TextLinkComponent } from '../../partials/link/TextLink';
import { useTheme } from '../../../theming/useTheme';
import { createStyles } from './networkLinks.styles';
import { TranslatedFontSize } from '../../../theming/fontSizeTranslator';
import { getStyleBySize } from '../../../typography/semanticTags/semanticTags.utils';
import {
  getContainerHeightSmallScreen,
  getListByAlphabetOrder,
  Link,
  Logo,
  SMALL_SCREEN_MAX_NUMBER_OF_LINKS_TO_DISPLAY,
} from './networkLinks.utils';

export interface NetworkLinksProps {
    links: Link[];
    logo?: Logo;
    title?: string;
}

const getImageResource = (svg: string) => {
  return `data:image/svg+xml,${encodeURIComponent(svg)}`;
};

export const NetworkLinks = (props: NetworkLinksProps) => {
  const { links, logo, title } = props;
  const [orderedList, setOrderedList] = useState<Link[]>([]);
  const [showAllLinks, setShowAllLinks] = useState<boolean>(false);

  const { colors, fontSizesStyles }: { colors: Colors; fontSizesStyles: TranslatedFontSize } = useTheme();
  const linkStyle = generateLinkStyleFromTheme({ idleColor: colors.primary }, { overflowX: 'hidden', height: 'max-content' });
  const linksContainerHeightSmallScreen = getContainerHeightSmallScreen(fontSizesStyles);
  const buttonText = showAllLinks ? 'SHOW LESS' : 'SHOW MORE';
  const displayShowMoreButton = links.length > SMALL_SCREEN_MAX_NUMBER_OF_LINKS_TO_DISPLAY;
  const styles = createStyles(colors, displayShowMoreButton, showAllLinks, linksContainerHeightSmallScreen);

  useEffect(() => {
    if (links) {
      setOrderedList(getListByAlphabetOrder(links));
    }
  }, [links]);

  const onShowMoreClicked = () => setShowAllLinks(true);

  const onShowLessClicked = () => setShowAllLinks(false);

  const linksAndButtonFontSizes = {
    fontSizeLarge: TYPOGRAPHY_SIZE.BIGGER,
    fontSizeMedium: TYPOGRAPHY_SIZE.BIGGER,
    fontSizeSmall: TYPOGRAPHY_SIZE.BIGGER,
  };

  const titleFontSizes = {
    fontSizeLarge: TYPOGRAPHY_SIZE.HUGE,
    fontSizeMedium: TYPOGRAPHY_SIZE.HUGE,
    fontSizeSmall: TYPOGRAPHY_SIZE.HUGE,
  };

  return (
    <div className={css(styles.placeholder)}>
      <div className={css(styles.container)}>
        {title && (
        <div className={css(styles.titleWrapper)}>
          {logo && logo.src && <img className={css(styles.image)} alt={logo.alt} src={getImageResource(logo.src)} />}
          <H2 className={css(styles.title, getStyleBySize(fontSizesStyles, titleFontSizes))}>{title}</H2>
        </div>
        )}
        <div className={css(styles.linksWrapper)}>
          {orderedList.map(link => {
            const { text, href } = link;
            return (
              <TextLinkComponent href={href} linkStyle={linkStyle} key={link.href}>
                <H4 className={css(styles.linkText, getStyleBySize(fontSizesStyles, linksAndButtonFontSizes))}>
                  {text}
                </H4>
              </TextLinkComponent>
            );
          })}
        </div>
        <H4
          onClick={showAllLinks ? onShowLessClicked : onShowMoreClicked}
          className={css(styles.button, getStyleBySize(fontSizesStyles, linksAndButtonFontSizes))}
        >
          {buttonText}
        </H4>
      </div>
    </div>
  );
};
