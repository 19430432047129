import * as React from 'react';
import { css, StyleDeclarationValue } from 'aphrodite/no-important';
import { Link } from '../../partials/link/Link';
import { SocialButton } from './ShareMenu';

interface ShareConfigLinksDataProps {
    shareConfig: SocialButton[];
    linkStyle: StyleDeclarationValue;
}

export const ShareConfigLinks: React.FunctionComponent<ShareConfigLinksDataProps> = props => {
  const { shareConfig, linkStyle } = props;
  return (
    <React.Fragment>
      { shareConfig.map((button, index) => {
        const { shareURL, src, type } = button;
        return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link
            key={index}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => { window.open(shareURL, '_blank', 'noopener'); return false; }}
            className={css(linkStyle)}
          >
            <img src={src} alt={type} height="16" />
          </Link>
        );
      })}
    </React.Fragment>
  );
};
