import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { AdditionalStyleSheet, OptionalAdditionalStyle } from '../components.utils';
import { MEDIA_BREAKPOINTS } from '../../mediaQueries.const';

export interface AdDataProps {
  id: string | null;
  onSuccess: (() => void) | null;
  dataId?: string;
  setMinHeight?: boolean;
  isSticky?: boolean;
  showInDesktop?: boolean;
  showInTablet?: boolean;
  showInMobile?: boolean;
  adType?: string;
}

export type AdProps = AdDataProps & OptionalAdditionalStyle;

const createStyle = (setMinHeight: boolean, showInDesktop?: boolean, showInTablet?: boolean, showInMobile?: boolean) => StyleSheet.create({
  ad: {
    justifyContent: 'center',
    width: '-webkit-fill-available',
    [MEDIA_BREAKPOINTS.large]: {
      minHeight: setMinHeight ? '270px' : 'unset',
      display: showInDesktop ? 'flex' : 'none',
    },
    [MEDIA_BREAKPOINTS.medium]: {
      minHeight: setMinHeight ? '270px' : 'unset',
      display: showInTablet ? 'flex' : 'none',
    },
    [MEDIA_BREAKPOINTS.small]: {
      minHeight: setMinHeight ? '60px' : 'unset',
      display: showInMobile ? 'flex' : 'none',
    },
  },
});


const createStickyBottomStyle = () => StyleSheet.create({
  style: {
    position: 'sticky',
    bottom: 0,
    backgroundColor: 'transparent',
  },
}) as AdditionalStyleSheet;

const getStyleArray = (isStyle: boolean, styleObj?: AdditionalStyleSheet) => {
  return isStyle ? [styleObj?.style] : [];
};

const Placeholder: React.FunctionComponent<AdProps> = ({ id, onSuccess, additionalStyle, dataId, setMinHeight = false, isSticky = false, showInDesktop = true, showInTablet = true, showInMobile = true, adType }) => {
  React.useEffect(() => {
    if (onSuccess) {
      try {
        onSuccess();
      } catch (e) {
        console.error(`ClientAPI Error in callback for injecting ad event for ad id: ${id}. Please check the callback value.`, e);
      }
    }
  }, [id, onSuccess]);
  const style = createStyle(setMinHeight, showInDesktop, showInTablet, showInMobile);
  const stickyBottomStyle = createStickyBottomStyle();
  const classNames = [style.ad, ...getStyleArray(isSticky, stickyBottomStyle), ...getStyleArray(!!additionalStyle, additionalStyle)];
  const includeId = adType && ['sidebar1', 'sidebar2'].includes(adType);
  const formatId = id?.replace(/-\d+$/, '');
  const idProp = id ? { id } : {};
  return <div {...idProp} data-mm-id={dataId} data-target="ad" className={`${css(...classNames)}${includeId ? ` ${formatId}` : ''}`} />;
};

export const Ad: React.FunctionComponent<AdProps> = React.memo(props => {
  const { id, onSuccess, additionalStyle, dataId, setMinHeight = false, isSticky = false, showInDesktop, showInTablet, showInMobile, adType } = props;
  return (id || setMinHeight) ? <Placeholder adType={adType} id={id} onSuccess={onSuccess} additionalStyle={additionalStyle} dataId={dataId} setMinHeight={setMinHeight} isSticky={isSticky} showInDesktop={showInDesktop} showInTablet={showInTablet} showInMobile={showInMobile} /> : null;
});
