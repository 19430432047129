import { StyleSheet } from 'aphrodite/no-important';


export const getLoaderKeyframes = () => {
  return {
    '0%': {
      boxShadow: '9999px 0 0 -5px',
    },
    '30%': {
      boxShadow: '9999px 0 0 2px',
    },
    '60%, 100%': {
      boxShadow: '9999px 0 0 -5px',
    },
  };
};

export const getBeforeLoaderKeyframes = () => {
  return {
    '0%': {
      boxShadow: '9984px 0 0 -5px',
    },
    '30%': {
      boxShadow: '9984px 0 0 2px',
    },
    '60%, 100%': {
      boxShadow: '9984px 0 0 -5px',
    },
  };
};

export const getAfterLoaderKeyframes = () => {
  return {
    '0%': {
      boxShadow: '10014px 0 0 -5px',
    },
    '30%': {
      boxShadow: '10014px 0 0 2px',
    },
    '60%, 100%': {
      boxShadow: '10014px 0 0 -5px',
    },
  };
};
export const createStyles = (loaderColors: string) => {
  return StyleSheet.create({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      padding: '32px 0',
    },
    loader: {
      position: 'relative',
      left: '-9999px',
      width: '10px',
      height: '10px',
      borderRadius: '5px',
      backgroundColor: loaderColors,
      color: loaderColors,
      marginLeft: '4px',
      boxShadow: '9999px 0 0 -5px',
      animationName: getLoaderKeyframes(),
      animationDelay: '0.25s',
      animationDuration: '1.5s',
      animationIterationCount: 'infinite',
      animationTimingFunction: 'linear',
    },
    loaderBefore: {
      display: 'inline-block',
      position: 'relative',
      left: '-9984px',
      width: '10px',
      height: '10px',
      borderRadius: '5px',
      backgroundColor: loaderColors,
      color: loaderColors,
      boxShadow: '9984px 0 0 -5px',
      animationName: getBeforeLoaderKeyframes(),
      animationDelay: '0s',
      animationDuration: '1.5s',
      animationIterationCount: 'infinite',
      animationTimingFunction: 'linear',
    },
    loaderAfter: {
      display: 'inline-block',
      position: 'relative',
      left: '-10014px',
      width: '10px',
      height: '10px',
      borderRadius: '5px',
      backgroundColor: loaderColors,
      color: loaderColors,
      marginLeft: '4px',
      boxShadow: '10014px 0 0 -5px',
      animationName: getAfterLoaderKeyframes(),
      animationDelay: '0.5s',
      animationDuration: '1.5s',
      animationIterationCount: 'infinite',
      animationTimingFunction: 'linear',
    },
  });
};
