import React, { useEffect, useRef, useState } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Colors } from 'mm-theme-configuration/dist/interfaces';
import { TYPOGRAPHY_SIZE } from 'mm-theme-configuration/dist/consts';
import { getStyleBySize } from '../../../../../typography/semanticTags/semanticTags.utils';
import { TranslatedFontSize } from '../../../../../theming/fontSizeTranslator';
import { useTheme } from '../../../../../theming/useTheme';
import { NavigationLink } from '../oldHeader/primaryHeader/PrimaryHeader';
import { MoreIcon } from '../../../../partials/icon/icons';
import { Icon } from '../../../../partials/icon/Icon';
import { getFillAndStrokeIconStyle } from './header.util';
import { Link } from '../../../../partials/link/Link';
import { Span } from '../../../../../typography/semanticTags';


interface MegaMenuProp {
  megaMenu: NavigationLink[];
  moreIconColor: string;
  siteName?: string;
}

const getLinksStyle = (colors: Colors) => {
  let headerHeight = 78;

  if (typeof window !== 'undefined') {
    const header = document.querySelector('header');
    headerHeight = header ? header.clientHeight : headerHeight;
  }

  return StyleSheet.create({
    menuWrapper: {
      display: 'flex',
      padding: '24px',
      position: 'absolute',
      top: `${headerHeight}px`,
      left: '50%',
      transform: 'translateX(-50%)',
      gap: '24px',
      zIndex: 10,
      boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.20)',
      backgroundColor: colors.white,
      textTransform: 'none',
    },
    columnWrapper: {
      display: 'flex',
      flexDirection: 'row',
    },
    linksAndTitle: {
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
    },
    title: {
      // @ts-ignore
      fontWeight: '700 !important',
    },
    link: {
      color: colors.darkGrey,
      display: 'flex',
      flexWrap: 'wrap',
      position: 'relative',
      alignItems: 'center',
      textDecoration: 'none',
      width: '150px',
      whiteSpace: 'normal',
    },
    liHover: {
      ':hover': {
        color: colors.primary,
      },
    },
    divder: {
      width: '1px',
      backgroundColor: colors.grey,
      padding: '24px 0',
    },
  });
};

const getLiStyle = (listWidth: number) => {
  return StyleSheet.create({
    linksList: {
      listStyleType: 'none',
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      gap: '4px',
      height: '230px',
      width: listWidth,
      flexWrap: 'wrap',
    },
  }).linksList;
};

export const MegaMenu: React.FunctionComponent<MegaMenuProp> = ({ moreIconColor, megaMenu, siteName }) => {
  const iconStyle = getFillAndStrokeIconStyle(moreIconColor, moreIconColor);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { colors, fontSizesStyles }: { colors: Colors; fontSizesStyles: TranslatedFontSize } = useTheme();
  const fontStyle = getStyleBySize(fontSizesStyles, { fontSizeSmall: TYPOGRAPHY_SIZE.TINY, fontSizeMedium: TYPOGRAPHY_SIZE.TINY, fontSizeLarge: TYPOGRAPHY_SIZE.TINY });
  const menuRef = useRef<HTMLDivElement>(null);
  const style = getLinksStyle(colors);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (menuRef.current && event.target instanceof Node) {
        if (!menuRef.current.contains(event.target)) {
          setIsMenuOpen(false);
        }
      }
    };
    if (isMenuOpen) {
      document.addEventListener('click', handleClick);
    } else {
      document.removeEventListener('click', handleClick);
    }

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [isMenuOpen]);

  const singleLinks = megaMenu.filter((link: NavigationLink) => !link?.children);
  const handleSingleLinks = () => {
    return singleLinks.map((link: NavigationLink, key: number) => {
      return (
        <li key={key}>
          <Link className={css(style.link, style.liHover, fontStyle)} href={link.link.href}>
            <span>{link.link.text}</span>
          </Link>
        </li>
      );
    });
  };

  return (
    <>
      <Icon icon={MoreIcon} additionalStyle={iconStyle} onClick={toggleMenu} />
      {isMenuOpen
      && (
      <div ref={menuRef} className={css(style.menuWrapper)}>
        {megaMenu.map((navigationLink: NavigationLink, index: number) => {
          const listWidth = navigationLink.children ? (Math.floor(navigationLink.children.length / 9) + 1) * 150 : 0;
          const linksListStyle = getLiStyle(listWidth);
          return (
            <>
              {navigationLink?.children ? (
                <div key={index} className={css(style.columnWrapper)}>
                  <div className={css(style.linksAndTitle)}>
                    <Link key={index} className={css(style.title, style.link, fontStyle)} href={navigationLink.link.href}>
                      <span>{navigationLink.link.text}</span>
                    </Link>
                    <ul className={css(linksListStyle)}>
                      {navigationLink.children?.map((child: NavigationLink, key: number) => {
                        return (
                          <li key={key}>
                            <Link className={css(style.link, style.liHover, fontStyle)} href={child.link.href}>
                              <span>{child.link.text}</span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  {(index < megaMenu.length - 1) && <span className={css(style.divder)} />}
                </div>
                ) : null}
            </>
          );
        })}
        {singleLinks.length ? (
          <div className={css(style.columnWrapper)}>
            <div className={css(style.linksAndTitle)}>
              <Span styles={[style.title, style.link, fontStyle]}>{`MORE FROM ${siteName?.toUpperCase()}`}</Span>
              <ul className={css(getLiStyle((Math.floor(singleLinks.length / 9) + 1) * 150))}>
                {handleSingleLinks()}
              </ul>
            </div>
          </div>
        ) : null}
      </div>
      )}
    </>
  );
};
