import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { MandatoryAdditionalStyle } from '../../components.utils';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { useViewabilityHandler, ViewabilityProvider } from '../../../viewability/viewabilityHandler';
import {
  injectArticleBlockByIndexEvent,
  setEventEmitter, viewabilityArticleBlockEvent,
} from '../../components/pageLayouts/eventEmitters/eventEmitters.utils';
import { BlockWidths } from '../../components/pageLayouts/BlockTypes.utils';
import { createBlockStyle } from '../blockMutualStyle';

interface InjectedBlockDataProps {
    html: string;
    script: string | undefined;
    dataId: string;
    defaultBlocksWidths: BlockWidths;
}

type InjectedBlockProps = InjectedBlockDataProps & MandatoryAdditionalStyle;

type InjectArticleBlockByIndexCallback = (dataId: string) => void;

const emitInjectArticleBlockByIndexEvent = (dataId: string) => {
  setEventEmitter(injectArticleBlockByIndexEvent, (callback: InjectArticleBlockByIndexCallback) => callback(dataId));
};

const getBlockDataId = (block: Element) => {
  return block.getAttribute('data-mm-id');
};

const sendViewabilityEvent = (element: Element) => {
  const id = getBlockDataId(element);
  setEventEmitter(viewabilityArticleBlockEvent, callback => callback({ id }));
};

const getStyle = (defaultBlocksWidths: BlockWidths) => {
  return StyleSheet.create({
    style: {
      ...createBlockStyle(defaultBlocksWidths),
    },
  }).style;
};

export const InjectedBlock: React.FunctionComponent<InjectedBlockProps> = props => {
  const { html, additionalStyle, dataId, script, defaultBlocksWidths } = props;
  const style = getStyle(defaultBlocksWidths);

  React.useEffect(() => {
    if (script) {
      const scriptElement = document.createElement('script');
      scriptElement.innerHTML = script;
      document.head.appendChild(scriptElement);
    }
  }, [script]);

  React.useEffect(() => {
    emitInjectArticleBlockByIndexEvent(dataId);
  }, [dataId]);

  const ref = useViewabilityHandler(sendViewabilityEvent, 0.5);
  return (
    <ViewabilityProvider>
      <ErrorBoundary>
        {/* eslint-disable-next-line react/no-danger */}
        <figure ref={ref} data-mm-id={dataId} dangerouslySetInnerHTML={{ __html: html }} className={css(additionalStyle.style, style)} />
      </ErrorBoundary>
    </ViewabilityProvider>
  );
};
