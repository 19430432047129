import * as React from 'react';
import { useTheme } from '../../../../theming/useTheme';
import { showAuthorInAnyScreenSize,
  showMetadataInLargeScreenSize,
  showMetadataInMediumScreenSize,
  showMetadataInSmallScreenSize,
} from '../cards.utils';
import { ConditionalRelativeWrap } from '../../ConditionalRelativeWrap';
import { DecorationLine } from '../../DecorationLine';
import { createImageAspectRatios } from '../../image/image.utils';
import { PlayIcon } from '../../PlayIcon';
import { TextNextToImage } from '../TextNextToImage';
import { VerticalCardProps } from './VerticalCard.props';
import { AMPImage } from '../../../AMPArticleComponents';
import { VerticalCardWrapper } from './VerticalCardWrapper';
import { MainCategory } from './VerticalCardMainCategory';
import { componentConfigThemeKey, VerticalCardThemeProps } from './VerticalCard.theme';

export const AMPVerticalCard: React.FunctionComponent<VerticalCardProps> = props => {
  const {
    coverType,
    showDecorationLine = true,
    title,
    image,
    imageWidths,
    authors,
    mainCategory,
    sponsoredText,
    brandName,
    description,
    updatedAt,
    updatedAtISO,
    createdAt,
    createdAtISO,
  } = props;
  const {
    titleFontSizeLarge,
    titleFontSizeSmall,
    titleFontSizeMedium,
    showAuthorInLargeScreen,
    showAuthorInMediumScreen,
    showAuthorInSmallScreen,
    aspectRatioLarge,
    aspectRatioMedium,
    aspectRatioSmall,
    authorColor,
    metadataFontSizeLarge,
    metadataFontSizeMedium,
    metadataFontSizeSmall,
    showMetadataOnBottom,
    metadataMarginTop,
    horizontalGapLarge,
    horizontalGapMedium,
    horizontalGapSmall,
    verticalGapLarge,
    verticalGapMedium,
    verticalGapSmall,
    titleSemanticTag,
    metadataSemanticTag,
    showDateInSmallScreen,
    showDateInMediumScreen,
    showDateInLargeScreen,
    dateColor,
    pipeColor,
    showMainCategoryInLargeScreen,
    showMainCategoryInMediumScreen,
    showMainCategoryInSmallScreen,
    numberOfLinesForTitle,
    numberOfLinesForAuthors,
    showDescriptionInLargeScreen,
    showDescriptionInMediumScreen,
    showDescriptionInSmallScreen,
    numberOfLinesForDescription,
    descriptionColor,
    descriptionFontSizeInLargeScreen,
    descriptionFontSizeInMediumScreen,
    descriptionFontSizeInSmallScreen,
    descriptionSemanticTag,
    titleAlignment,
    descriptionAlignment,
    metadataAlignment,
    isUpdatedAtTimestamp,
    isMainCategoryEnabled,
    isClickableLabels,
  } = useTheme<VerticalCardThemeProps>(componentConfigThemeKey);

  const showMetadataInLargeScreen = showMetadataInLargeScreenSize(showAuthorInLargeScreen, showDateInLargeScreen);
  const showMetadataInMediumScreen = showMetadataInMediumScreenSize(showAuthorInMediumScreen, showDateInMediumScreen);
  const showMetadataInSmallScreen = showMetadataInSmallScreenSize(showAuthorInSmallScreen, showDateInSmallScreen);
  const showMainCategory = isMainCategoryEnabled && mainCategory?.displayName && (showMainCategoryInSmallScreen || showMainCategoryInMediumScreen || showMainCategoryInLargeScreen);
  const isMainCategoryClickable = isClickableLabels && mainCategory?.link && mainCategory?.isActive;
  const showAuthor = showAuthorInAnyScreenSize(authors, showAuthorInLargeScreen, showAuthorInMediumScreen, showAuthorInSmallScreen);
  const isAnyAuthorClickable = isClickableLabels && authors?.some(author => !!author.link);
  const isClickableLayout = (showMainCategory && isMainCategoryClickable) || (showAuthor && isAnyAuthorClickable);
  const timestamp = isUpdatedAtTimestamp ? updatedAt : createdAt;
  const timestampISO = isUpdatedAtTimestamp ? updatedAtISO : createdAtISO;

  const mainCategoryProps = {
    displayName: mainCategory?.displayName,
    link: mainCategory?.link,
    isClickable: !!isMainCategoryClickable,
  };

  return (
    <VerticalCardWrapper {...props} isClickableLayout={isClickableLayout}>
      <ConditionalRelativeWrap shouldWarp={showMainCategory || coverType === 'video'}>
        <AMPImage
          {...image}
          imageWidths={imageWidths}
          imageAspectRatios={createImageAspectRatios(aspectRatioSmall, aspectRatioMedium, aspectRatioLarge)}
          hideFigcaption
        />
        {coverType === 'video' ? <PlayIcon /> : null}
        {showMainCategory ? <MainCategory {... mainCategoryProps} /> : null}
      </ConditionalRelativeWrap>
      {showDecorationLine ? <DecorationLine /> : null}
      <TextNextToImage {...{
        title,
        brandName,
        authors,
        description,
        timestamp,
        timestampISO,
        sponsoredText,
        horizontalGapLarge,
        horizontalGapMedium,
        horizontalGapSmall,
        verticalGapLarge,
        verticalGapMedium,
        verticalGapSmall,
        titleSemanticTag,
        titleFontSizeMedium,
        titleFontSizeSmall,
        titleFontSizeLarge,
        numberOfLinesForTitle,
        showDescriptionInLargeScreen,
        showDescriptionInMediumScreen,
        showDescriptionInSmallScreen,
        descriptionSemanticTag,
        descriptionFontSizeInSmallScreen,
        descriptionFontSizeInLargeScreen,
        descriptionFontSizeInMediumScreen,
        descriptionColor,
        numberOfLinesForDescription,
        showAuthorInLargeScreen,
        showAuthorInMediumScreen,
        showAuthorInSmallScreen,
        showDateInLargeScreen,
        showDateInMediumScreen,
        showDateInSmallScreen,
        metadataSemanticTag,
        metadataFontSizeLarge,
        metadataFontSizeMedium,
        metadataFontSizeSmall,
        showMetadataOnBottom,
        metadataMarginTop,
        showMetadataInLargeScreen,
        showMetadataInMediumScreen,
        showMetadataInSmallScreen,
        authorColor,
        numberOfLinesForAuthors,
        pipeColor,
        dateColor,
        titleAlignment,
        descriptionAlignment,
        metadataAlignment,
        isClickableAuthors: isAnyAuthorClickable,
      }}
      />
    </VerticalCardWrapper>
  );
};
