import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Helmet } from 'react-helmet';
import { OptionalAdditionalStyle } from '../../components.utils';

interface AMPAdDataProps {
    adUnit: string;
    width: number;
    height: number;
    isBottomStick?: boolean;
    ampAdData: string;
    property: string;
}

interface RTCObject {
    [key: string]: any;
}

type AMPAdProps = AMPAdDataProps & OptionalAdditionalStyle;

const createStyle = () => StyleSheet.create({
  ad: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
});

export const AMPAd: React.FunctionComponent<AMPAdProps> = props => {
  const { adUnit, additionalStyle, height, width, isBottomStick = false, ampAdData } = props;
  const style = createStyle();
  const rtcConfig: RTCObject = {
    vendors: {
      aps: {
        PUB_ID: '3777',
        PARAMS: {
          amp: '1',
        },
      },
    },
    urls: ['https://ib.adnxs.com/prebid/amp?tag_id=22053381&w=ATTR(width)&h=ATTR(height)&ow=ATTR(data-override-width)&oh=ATTR(data-override-height)&ms=ATTR(data-multi-size)&slot=ATTR(data-slot)&targeting=TGT&curl=CANONICAL_URL&timeout=TIMEOUT&adcid=ADCID&purl=HREF&consent_string=CONSENT_STRING&gdpr_applies=CONSENT_METADATA(gdprApplies)&addtl_consent=CONSENT_METADATA(additionalConsent)&consent_type=CONSENT_METADATA(consentStringType)&pvid=PAGEVIEWID'],
    timeoutMillis: 750,
  };

  const ADUnit = () => {
    return (
    /* @ts-ignore TS2339: Property 'amp-ad' does not exist on type 'JSX.IntrinsicElements'. */
      <amp-ad
        width={width}
        height={height}
        type="doubleclick"
        data-slot={adUnit}
        json={ampAdData}
        rtc-config={JSON.stringify(rtcConfig)}
      >
        {/* @ts-ignore TS2339: Property 'amp-pixel' does not exist on type 'JSX.IntrinsicElements'. */}
        <amp-pixel src="https://bqstreamer.com/webservice/track/?type=stats&application=amp&module=amp&target=events&version=1&domain=SOURCE_HOST&details=SOURCE_URL&event=ad_view&path=SOURCE_PATH" layout="nodisplay" referrerpolicy="no-referrer" />
        {/* @ts-ignore TS2339: Property 'amp-ad' does not exist on type 'JSX.IntrinsicElements'. */}
      </amp-ad>

    );
  };
  return (
    <React.Fragment>
      <Helmet>
        <script async={undefined} custom-element="amp-ad" src="https://cdn.ampproject.org/v0/amp-ad-0.1.js" />
        {isBottomStick ? <script async={undefined} custom-element="amp-sticky-ad" src="https://cdn.ampproject.org/v0/amp-sticky-ad-1.0.js" /> : null}
      </Helmet>
      <div className={additionalStyle ? css(additionalStyle.style, style.ad) : css(style.ad)}>
        { /*
  // @ts-ignore TS2339: Property 'amp-sticky-ad' does not exist on type 'JSX.IntrinsicElements'. */}
        {isBottomStick ? <amp-sticky-ad layout="nodisplay"><ADUnit /></amp-sticky-ad> : <ADUnit />}
      </div>
    </React.Fragment>
  );
};
