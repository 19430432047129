import React, { useEffect } from 'react';

export const Kueez = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://static.kueezrtb.com/js/widget.js';
    script.id = 'krtbjsw';
    script.setAttribute('data-cfasync', 'false');
    script.crossOrigin = 'anonymous';
    const kueezWrapper = document.getElementById('kueez-wrapper');
    if (kueezWrapper) {
      kueezWrapper.appendChild(script);
    }
  }, []);

  return (
    <div id="kueez-wrapper" />
  );
};
