import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useTheme } from '../../../theming/useTheme';
import { Link } from '../../partials/link/Link';
import { getStyleBySize, semanticCSS } from '../../../typography/semanticTags/semanticTags.utils';
import { Span } from '../../../typography/semanticTags';
import { Link as LinkType } from '../networkLinks/networkLinks.utils';
import { networkLinkButtonConfigThemeKey, NetworkLinkButtonThemeProps, ThemeColorProps } from './NetworkLinkButton.theme';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';

const getStyles = (colors: ThemeColorProps, borderRadius: number) => {
  return StyleSheet.create({
    link: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: colors.textColorNormal,
      border: `1px solid ${colors.borderColorNormal}`,
      borderRadius: `${borderRadius}px`,
      padding: '10px 20px',
      background: colors.backgroundColorNormal,
      ':hover': {
        color: colors.textColorHover,
        background: colors.backgroundColorHover,
        border: `1px solid ${colors.borderColorHover}`,
      },
    },
    label: {
      textAlign: 'center',
      textTransform: 'uppercase',
    },
  });
};

export const NetworkLinkButton: React.FunctionComponent<LinkType> = props => {
  const { text, href } = props;
  const {
    fontSizesStyles,
    fontSizeLarge,
    fontSizeMedium,
    fontSizeSmall,
    textColorHover,
    textColorNormal,
    borderColorHover,
    borderColorNormal,
    backgroundColorHover,
    backgroundColorNormal,
    borderRadius,
  } = useTheme<NetworkLinkButtonThemeProps>(networkLinkButtonConfigThemeKey);
  const colors = {
    textColorHover,
    textColorNormal,
    borderColorHover,
    borderColorNormal,
    backgroundColorHover,
    backgroundColorNormal,
  };

  const fontStyle = getStyleBySize(fontSizesStyles, {
    fontSizeSmall,
    fontSizeMedium,
    fontSizeLarge,
  });
  const styles = getStyles(colors, borderRadius);
  return (
    <ErrorBoundary>
      <Link href={href} className={css(styles.link)}>
        <Span styles={semanticCSS(fontStyle, styles.label)}>{text}</Span>
      </Link>
    </ErrorBoundary>
  );
};
