import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { getLayoutComponentPadding, LayoutComponentBaseProps } from '../pageLayouts/layout.utils';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { useTheme } from '../../../theming/useTheme';
import {
  componentConfigThemeKey as onTopCardConfigThemeKey,
  OnTopCard,
  OnTopCardComponentThemeProps,
} from '../../partials/cards/onTopCard/OnTopCard';
import { createImageHeightCalculationMethods, createImageWidths } from '../../partials/image/image.utils';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';

const componentConfigThemeKey = 'oneTwoTwoOTC';

interface OneTwoTwoOTCProps extends LayoutComponentBaseProps {
    cards: CardComponentDataProps[];
}

interface OneTwoTwoOTCThemeProps {
    largeVerticalGap: number;
    largeHorizontalGap: number;
    mediumVerticalGap: number;
    mediumHorizontalGap: number;
    smallHorizontalGap: number;
    baseUnit: number;
}

const createStyle = (themeProps: OneTwoTwoOTCThemeProps) => {
  const { largeHorizontalGap, largeVerticalGap, mediumHorizontalGap, mediumVerticalGap, smallHorizontalGap, baseUnit } = themeProps;
  const firstCardArea = 'first_card_area';
  const secondCardArea = 'second_card_area';
  const thirdCardArea = 'third_card_area';
  const fourthCardArea = 'fourth_card_area';
  const fifthCardArea = 'fifth_card_area';
  return StyleSheet.create({
    wrapper: {
      display: 'grid',
      [MEDIA_BREAKPOINTS.large]: {
        gridTemplateColumns: '2fr 1fr 1fr',
        gridTemplateRows: '1fr 1fr',
        gridColumnGap: `${largeVerticalGap * baseUnit}px`,
        gridRowGap: `${largeHorizontalGap * baseUnit}px`,
        gridTemplateAreas: `'${firstCardArea} ${secondCardArea} ${thirdCardArea}' '${firstCardArea} ${fourthCardArea} ${fifthCardArea}'`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: 'auto auto auto',
        gridColumnGap: `${mediumVerticalGap * baseUnit}px`,
        gridRowGap: `${mediumHorizontalGap * baseUnit}px`,
        gridTemplateAreas: `'${firstCardArea} ${firstCardArea}' '${secondCardArea} ${thirdCardArea}' '${fourthCardArea} ${fifthCardArea}'`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        gridTemplateColumns: '1fr',
        gridRowGap: `${smallHorizontalGap * baseUnit}px`,
        gridTemplateAreas: `'${firstCardArea}' '${secondCardArea}' '${thirdCardArea}' '${fourthCardArea}' '${fifthCardArea}'`,

      },
    },
    firstCard: {
      gridArea: firstCardArea,
    },
    secondCard: {
      gridArea: secondCardArea,
    },
    thirdCard: {
      gridArea: thirdCardArea,
    },
    fourthCard: {
      gridArea: fourthCardArea,
    },
    fifthCard: {
      gridArea: fifthCardArea,
    },
  });
};

const imageWidthsForMainCard = createImageWidths(360, 720, 540);
const imageWidthsForMinorCard = createImageWidths(360, 360, 360);

const minorCardsImageHeightCalculationMethods = createImageHeightCalculationMethods('ASPECT_RATIO', 'ASPECT_RATIO', '100%_HEIGHT');

export const OneTwoTwoOTC: React.FunctionComponent<OneTwoTwoOTCProps> = props => {
  const { cards, paddingFactors } = props;
  const paddingStyle = getLayoutComponentPadding(paddingFactors);
  const themeProps = useTheme<OneTwoTwoOTCThemeProps>(componentConfigThemeKey);
  const styles = createStyle(themeProps);
  const themeContextFirstCard = useThemeOverride<OnTopCardComponentThemeProps>({
    titleFontSizeSmall: 'large',
    titleFontSizeMedium: 'large',
    titleFontSizeLarge: 'large',
    metadataFontSizeSmall: 'big',
    metadataFontSizeMedium: 'bigger',
    metadataFontSizeLarge: 'bigger',
    horizontalGapSmall: 0.25,
    horizontalGapMedium: 0.5,
    horizontalGapLarge: 0.5,
    aspectRatioSmall: { x: 4, y: 3 },
  }, onTopCardConfigThemeKey);
  const themeContextMinorCard = useThemeOverride<OnTopCardComponentThemeProps>({
    titleFontSizeSmall: 'large',
    titleFontSizeMedium: 'big',
    titleFontSizeLarge: 'big',
    metadataFontSizeSmall: 'big',
    metadataFontSizeMedium: 'big',
    metadataFontSizeLarge: 'big',
    horizontalGapSmall: 0.25,
    horizontalGapMedium: 0.25,
    horizontalGapLarge: 0.25,
  }, onTopCardConfigThemeKey);
  return (
    <ErrorBoundary>
      {cards && cards.length > 0
      && (
      <div className={css(paddingStyle, styles.wrapper)}>
        <ThemeContext.Provider value={themeContextFirstCard}>
          <div className={css(styles.firstCard)}>
            <OnTopCard {...cards[0]} imageWidths={imageWidthsForMainCard} />
          </div>
        </ThemeContext.Provider>
        <ThemeContext.Provider value={themeContextMinorCard}>
          <div className={css(styles.secondCard)}>
            <OnTopCard {...cards[1]} imageWidths={imageWidthsForMinorCard} imageHeightCalculationMethods={minorCardsImageHeightCalculationMethods} />
          </div>
          <div className={css(styles.thirdCard)}>
            <OnTopCard {...cards[2]} imageWidths={imageWidthsForMinorCard} imageHeightCalculationMethods={minorCardsImageHeightCalculationMethods} />
          </div>
          <div className={css(styles.fourthCard)}>
            <OnTopCard {...cards[3]} imageWidths={imageWidthsForMinorCard} imageHeightCalculationMethods={minorCardsImageHeightCalculationMethods} />
          </div>
          <div className={css(styles.fifthCard)}>
            <OnTopCard {...cards[4]} imageWidths={imageWidthsForMinorCard} imageHeightCalculationMethods={minorCardsImageHeightCalculationMethods} />
          </div>
        </ThemeContext.Provider>
      </div>
      )}
    </ErrorBoundary>
  );
};
