import React from 'react';
import { useTheme } from '../../../theming/useTheme';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import {
  componentConfigThemeKeyForArticleContent,
  componentConfigThemeKeyForContent,
  MainWrapperConfigThemeKey,
} from './mainWrapper.theme';
import { Kasda } from '../../../Kasda/Kasda';

const componentConfigThemeKeyForTemplateBody = 'templateBody';
const componentConfigThemeKeyForTemplateArticleBody = 'templateArticleBody';

interface TemplateBodyThemeProps {
  backgroundColor: string;
  baseUnit: BaseUnit;
}

interface BodyTagProps {
  mainConfigThemeKey: MainWrapperConfigThemeKey;
}

export const BodyTag: React.FunctionComponent<BodyTagProps> = ({ mainConfigThemeKey }) => {
  const getBodyTagConfigThemeKey = () => {
    switch (mainConfigThemeKey) {
      case componentConfigThemeKeyForContent:
        return componentConfigThemeKeyForTemplateBody;
      case componentConfigThemeKeyForArticleContent:
        return componentConfigThemeKeyForTemplateArticleBody;
      default:
        return componentConfigThemeKeyForTemplateBody;
    }
  };
  const { backgroundColor } = useTheme<TemplateBodyThemeProps>(getBodyTagConfigThemeKey());

  return (
    <React.Fragment>
      <Kasda.Style>
        <style>{`body {background-color: ${backgroundColor};}`}</style>
      </Kasda.Style>
    </React.Fragment>
  );
};
