import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { ImageSizeType, REGULAR_IMAGE_SIZE_TYPE, Thumbnail } from '../../partials/image/image.types';
import { AdditionalStyleSheet, Style } from '../../components.utils';
import { Image } from '../../partials/image/Image';
import { Jw } from '../media/Jw';
import { Iframe } from '../media/Iframe';
import { MMPlayer } from '../media/MMPlayer';
import { createImageWidths } from '../../partials/image/image.utils';
import { baseUnit } from '../../../theming/baseUnitDefinition';
import { BlocksPaddingOverride, BlockWidths, MMPlayerBlock } from '../../components/pageLayouts/BlockTypes.utils';
import { PaddingOverride } from '../blockMutualStyle';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { ImageWithCaptionAndIcon } from '../../partials/image/ImageWithCaptionAndIcon/ImageWithCaptionAndIcon';

export interface JwCoverBlock {
  videoId: string;
  html: string;
  type: string;
}

export interface HTMLCoverBlock {
  html: string;
  type: string;
}

export interface RawHTMLCoverBlock {
  rawHtml: string;
  type: string;
}

export type Media = RawHTMLCoverBlock | JwCoverBlock | HTMLCoverBlock | MMPlayerBlock;

export interface ArticleMainImageDataProps {
  image: Thumbnail;
  media: Media | null;
  siteName: string;
  templateName: string;
  defaultBlocksWidths: BlockWidths;
  paddingOverride?: BlocksPaddingOverride;
  sizeType?: ImageSizeType;
}

const getMainImageStyle = () => {
  return {
    marginTop: `${baseUnit}px`,
    marginBottom: `${baseUnit * 0.25}px`,
  };
};

const createBlockStyleForImageCover = (defaultBlocksWidths: BlockWidths, paddingOverride: PaddingOverride = { largePadding: 1, mediumPadding: 1, smallPadding: 1 }) => {
  const { largeWidth, mediumWidth, smallWidth } = defaultBlocksWidths;
  const { largePadding, mediumPadding, smallPadding } = paddingOverride;
  return {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '100%',
    boxSizing: 'border-box',
    [MEDIA_BREAKPOINTS.large]: {
      width: `${largeWidth}px`,
      marginRight: `${baseUnit * largePadding}px`,
      marginLeft: `${baseUnit * largePadding}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      width: `${mediumWidth}px`,
      marginRight: `${baseUnit * mediumPadding}px`,
      marginLeft: `${baseUnit * mediumPadding}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      width: `${smallWidth}px`,
      marginRight: `${baseUnit * smallPadding}px`,
      marginLeft: `${baseUnit * smallPadding}px`,
    },
  } as Style;
};


const getCoverStyles = (defaultBlocksWidths: BlockWidths, paddingOverride: PaddingOverride = { largePadding: 1, mediumPadding: 1, smallPadding: 1 }) => {
  const { largePadding, mediumPadding, smallPadding } = paddingOverride;

  return StyleSheet.create({
    wrapper: {
      position: 'relative',
      ...createBlockStyleForImageCover(defaultBlocksWidths),
      [MEDIA_BREAKPOINTS.large]: {
        marginRight: `${baseUnit * largePadding}px`,
        marginLeft: `${baseUnit * largePadding}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        marginRight: `${baseUnit * mediumPadding}px`,
        marginLeft: `${baseUnit * mediumPadding}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        marginRight: `${baseUnit * smallPadding}px`,
        marginLeft: `${baseUnit * smallPadding}px`,
      },
    },
  });
};

const getBlockStyle = () => {
  return StyleSheet.create({
    style: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      margin: 0,
    },
  });
};

const imageWidths = createImageWidths(360, 720, 1080);
const createImageAdditionalStyle = (style: Style) => {
  return StyleSheet.create({
    style: {
      ...style,
    },
  }) as AdditionalStyleSheet;
};

const getMainImage = (image: Thumbnail, credit: string | undefined, caption: string | undefined, style: Style, defaultBlocksWidths: BlockWidths, sizeType: ImageSizeType) => {
  const additionalStyle = createImageAdditionalStyle(style);
  const hasCreditOrCaption = typeof credit === 'string' || typeof caption === 'string';

  return hasCreditOrCaption
    ? <ImageWithCaptionAndIcon image={image} style={style} imageWidths={imageWidths} defaultBlocksWidths={defaultBlocksWidths} sizeType={sizeType} />
    : <Image {...image} additionalStyle={additionalStyle} imageWidths={imageWidths} />;
};

const getMedia = (media: Media, siteName: string, templateName: string) => {
  const additionalStyle = getBlockStyle() as AdditionalStyleSheet;
  const { type } = media;
  switch (type) {
    case 'jw': {
      const { html, videoId } = media as JwCoverBlock;
      return <Jw html={html} additionalStyle={additionalStyle} videoId={videoId} siteName={siteName} templateName={templateName} />;
    }

    case 'youtube': {
      const { html } = media as HTMLCoverBlock;
      return <Iframe html={html} additionalStyle={additionalStyle} />;
    }

    case 'mmPlayer': {
      const { html, version } = media as MMPlayerBlock;
      return <MMPlayer html={html} version={version} additionalStyle={additionalStyle} />;
    }
    default: return null;
  }
};


export const ArticleMainImage: React.FunctionComponent<ArticleMainImageDataProps> = ({ image, media, siteName, templateName, defaultBlocksWidths, paddingOverride, sizeType = REGULAR_IMAGE_SIZE_TYPE }) => {
  const mainImageStyle = getMainImageStyle();
  const { credit, caption } = image;
  const coverStyles = getCoverStyles(defaultBlocksWidths, paddingOverride);

  return (
    <ErrorBoundary>
      <div className={css(coverStyles.wrapper)}>
        {getMainImage(image, credit, caption, mainImageStyle, defaultBlocksWidths, sizeType)}
        {media && getMedia(media, siteName, templateName)}
      </div>
    </ErrorBoundary>
  );
};
