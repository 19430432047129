import { TYPOGRAPHY_SIZE } from 'mm-theme-configuration/dist/consts';
import { Colors } from 'mm-theme-configuration/dist/interfaces';
import {
  ButtonComponentThemeProps, componentConfigThemeKey as LinkButtonConfigThemeKey,
} from '../../components/buttons/button.utils';
import { useTheme } from '../../../theming/useTheme';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { SectionHeaderComponentThemeProps, componentConfigurationKey as SectionHeaderConfigThemeKey } from '../../wrappers/sectionHeader/SectionHeader';

export interface FeedGroupAll {
  displayName: string;
  link: string;
}

export interface Feed {
  name: string;
  link: string;
}

export interface FeedGroup {
  key: string;
  displayName: string;
  link: string;
  feeds: Array<Feed>;
}

export type CurrentGroup = 'all' | string;

export const FEED_GROUP_ALL_KEY = 'all';

export const getFeedGroupByKey = (feedGroups: Array<FeedGroup>, key: string): FeedGroup | undefined => feedGroups.find(feedGroup => feedGroup.key === key);

interface UseLinkButtonThemeContextOverrideProps {
  isSelected?: boolean;
}

export const useLinkButtonThemeContextOverride = ({ isSelected }: UseLinkButtonThemeContextOverrideProps = {}) => {
  const { colors }: { colors: Colors } = useTheme();

  return useThemeOverride<ButtonComponentThemeProps>({
    backgroundColorNormal: isSelected ? colors.primary : colors.lightGrey,
    backgroundColorHover: colors.primary,
    backgroundColorActive: colors.primary,
    backgroundColorNormalSecondary: 'transparent',
    backgroundColorHoverSecondary: 'transparent',
    backgroundColorActiveSecondary: 'transparent',
    textColorNormal: isSelected ? colors.lightGrey : colors.darkGrey,
    textColorHover: colors.lightGrey,
    textColorActive: colors.lightGrey,
    textColorHoverSecondary: colors.primary,
    fontSizeLarge: TYPOGRAPHY_SIZE.TINY,
    fontSizeMedium: TYPOGRAPHY_SIZE.TINY,
    fontSizeSmall: TYPOGRAPHY_SIZE.TINY,
    boxShadowTypeNormal: 'none',
    boxShadowTypeActive: 'none',
    boxShadowTypeHover: 'none',
    borderRadius: 2,
    borderWidthNormal: 0,
    borderWidthHover: 0,
    borderWidthActive: 0,
  }, LinkButtonConfigThemeKey);
};

export const useSectionHeaderThemeContextOverride = () => {
  const { colors }: { colors: Colors } = useTheme();

  return useThemeOverride<SectionHeaderComponentThemeProps>({
    titleBackgroundColor: 'transparent',
    titleColor: colors.primary,
    largeHorizontalGap: 0,
    mediumHorizontalGap: 0,
    smallHorizontalGap: 0,
    showMiddleHorizontalLine: false,
    showSquare: false,
  }, SectionHeaderConfigThemeKey);
};

export const sitemapStoryContainerStyle = {
  padding: 20,
};
