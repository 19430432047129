import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Logo, LogoProps } from '../Logo';
import { useTheme } from '../../../../../../theming/useTheme';
import { SecondaryLinks } from './SecondaryLinks';
import { HeaderHeightsPerBreakpoint } from '../oldHeader.utils';
import { ErrorBoundary } from '../../../../../errorHandling/ErrorBoundary';
import { HUGE_SCREEN_SIZE, MEDIA_BREAKPOINTS } from '../../../../../../mediaQueries.const';
import { createStyleForBoxShadow } from '../../../../../../theming/boxShadow';
import { BaseUnit } from '../../../../../../theming/baseUnitDefinition';
import { AnimationDirection, AnimationDirectionType } from '../../menuAnimations.util';
import { NavigationLink } from '../primaryHeader/PrimaryHeader';
import { componentConfigThemeKey, HeaderThemeProps } from '../../Header.theme';

export const maxVisibleLinksOnLargeAndXLarge = 6;

export interface SecondaryHeaderDataProps {
  links: NavigationLink[] | null;
  logo: LogoProps | null;
  moreLinksText: string;
  animation?: AnimationDirectionType;
  animationDuration?: number;
}

export interface SecondaryHeaderProps extends SecondaryHeaderDataProps{
  pagePath: string;
  headerHeights: HeaderHeightsPerBreakpoint;
  logoHeights: HeaderHeightsPerBreakpoint;
}

const getStyle = (baseUnit: BaseUnit, backgroundColor: string, animationDuration: number, headerHeights: HeaderHeightsPerBreakpoint) => {
  return StyleSheet.create({
    headerWrapper: {
      backgroundColor,
      zIndex: 50,
      position: 'relative',
    },
    header: {
      [MEDIA_BREAKPOINTS.large]: {
        height: `${headerHeights.large * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        height: `${headerHeights.medium * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        height: `${headerHeights.small * baseUnit}px`,
      },
      display: 'grid',
      gridAutoFlow: 'column',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gridColumnGap: `${baseUnit}px`,
      paddingLeft: `${baseUnit}px`,
      maxWidth: `${HUGE_SCREEN_SIZE}px`,
      margin: '0 auto',
      boxSizing: 'border-box',
    },
    foldingUp: {
      transition: `${animationDuration}ms`,
      [MEDIA_BREAKPOINTS.medium]: {
        transform: 'translateY(-100%)',
      },
      [MEDIA_BREAKPOINTS.small]: {
        transform: 'translateY(-100%)',
      },
    },
    foldingDown: {
      transition: `${animationDuration}ms`,
      [MEDIA_BREAKPOINTS.medium]: {
        transform: 'translateY(0)',
        transitionDelay: '200ms',
        transition: `${animationDuration}ms`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        transform: 'translateY(0)',
        transitionDelay: '200ms',
      },
    },
  });
};

type SecondaryHeaderStyle = {
  headerWrapper: object;
  header: object;
  foldingUp: object;
  foldingDown: object;
};

const getClassWithAnimations = (animation: string, baseStyle: SecondaryHeaderStyle, boxShadow: { style: object }) => {
  switch (animation) {
    case AnimationDirection.UP: return css(baseStyle.headerWrapper, boxShadow.style, baseStyle.foldingUp);
    case AnimationDirection.DOWN: return css(baseStyle.headerWrapper, boxShadow.style, baseStyle.foldingDown);
    default: return css(baseStyle.headerWrapper, boxShadow.style);
  }
};

export const SecondaryHeader: React.FunctionComponent<SecondaryHeaderProps> = props => {
  const { links, logo, moreLinksText, pagePath, animationDuration = 0, animation = AnimationDirection.NONE, headerHeights, logoHeights } = props;
  const { baseUnit, secondRowBackgroundColor, secondRowBoxShadow } = useTheme<HeaderThemeProps>(componentConfigThemeKey);
  const style = getStyle(baseUnit, secondRowBackgroundColor, animationDuration, headerHeights);
  const boxShadowStyle = createStyleForBoxShadow({ boxShadowTypeNormal: secondRowBoxShadow });
  const combinedStyle = getClassWithAnimations(animation, style, boxShadowStyle);

  return (
    <ErrorBoundary>
      <div className={combinedStyle}>
        <div className={css(style.header)}>
          {logo ? <Logo {...logo} logoHeights={{ large: logoHeights.large, medium: logoHeights.medium, small: logoHeights.small }} /> : null}
          {links ? <SecondaryLinks links={links} maxVisibleLinks={maxVisibleLinksOnLargeAndXLarge} moreLinksText={moreLinksText} pagePath={pagePath} /> : null}
        </div>
      </div>
    </ErrorBoundary>
  );
};
