import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { MEDIA_BREAKPOINTS } from '../../mediaQueries.const';
import { ViewabilityProvider } from '../../viewability/viewabilityHandler';
import { AdditionalStyleSheet } from '../components.utils';

interface PostMainContentProps {
  additionalStyles?: AdditionalStyleSheet;
}

const createStyle = () => StyleSheet.create({
  style: {
    margin: '0 auto',
    maxWidth: '100%',
    minWidth: '100%',
    [MEDIA_BREAKPOINTS.large]: {
      paddingLeft: '80px',
      maxWidth: 'calc(100% - 80px)',
      minWidth: 'calc(100% - 80px)',
    },
  },
});

export const PostMainContent: React.FunctionComponent<PostMainContentProps> = ({ children, additionalStyles }) => {
  return (
    <ViewabilityProvider>
      <article className={css(createStyle().style, additionalStyles?.style)}>
        {children}
      </article>
    </ViewabilityProvider>
  );
};
