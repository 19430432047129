import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { useTheme } from '../../../theming/useTheme';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { Span } from '../../../typography/semanticTags';
import {
  ArticleBlockThemeProps,
  componentConfigurationKey,
} from '../../articleComponents/ArticleBlock.theme';
import { ChevronThinIcon } from './components/ChevronThinIcon';
import { BreadcrumbItem } from './components/BreadcrumbItem';
import { createBreadcrumbData } from './breadcrumbs.utils';
import { BreadcrumbButton } from './components/BreadcrumbButton';
import { BreadcrumbModal } from './components/BreadcrumbModal';
import { OptionalAdditionalStyle } from '../../components.utils';

export interface Breadcrumb {
  displayName: string;
  path: string;
  children?: Breadcrumb[];
}

export interface MainCategory {
  displayName: string;
  link: string;
  isActive: boolean;
}

interface MultiLayerBreadcrumbsProps extends OptionalAdditionalStyle {
  canonicalURL: string;
  mainCategory?: MainCategory;
}

const createStyle = (
  breadCrumbsTextColor: string,
  breadCrumbsInactiveTextColor: string,
) => {
  return StyleSheet.create({
    container: {
      height: '45px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      position: 'relative',
      padding: '0',
    },
    listItem: {
      display: 'inline-flex',
      listStyle: 'none',
      margin: '0',
      padding: '0',
      alignItems: 'center',
    },
    chevron: {
      display: 'flex',
      justifyContent: 'center',
      marginRight: '5px',
      paddingTop: '7px',
      width: '14px',
      height: '14px',
    },
    link: {
      color: breadCrumbsTextColor,
      width: 'fit-content',
      display: 'block',
      padding: '5px 5px 5px 0',
      whiteSpace: 'nowrap',
      textTransform: 'uppercase',
      ':hover': {
        cursor: 'pointer',
        color: breadCrumbsInactiveTextColor,
      },
    },
    linkDisabled: {
      pointerEvents: 'none',
      cursor: 'default',
      color: breadCrumbsInactiveTextColor,
    },
  });
};

export const MultiLayerBreadcrumbs = (props: MultiLayerBreadcrumbsProps) => {
  const [showChildren, setShowChildren] = React.useState(false);
  const [hover, setHover] = React.useState(false);
  const { canonicalURL, mainCategory, additionalStyle } = props;
  const { breadCrumbsTextColor, breadCrumbsInactiveTextColor } = useTheme<ArticleBlockThemeProps>(componentConfigurationKey);

  const breadcrumbs = React.useMemo(() => {
    return createBreadcrumbData(canonicalURL, mainCategory);
  }, [canonicalURL, mainCategory]);

  const style = createStyle(breadCrumbsTextColor, breadCrumbsInactiveTextColor);
  const modalRef = React.useRef(null);
  return (
    <ErrorBoundary>
      {breadcrumbs.length > 0
      && (
      <nav aria-label="breadcrumbs" className={css(additionalStyle?.style)}>
        <ul className={css(style.container)}>
          {breadcrumbs.map(({ path, displayName, children }, index) => {
            const isLastItem = index === breadcrumbs.length - 1;
            const linkStyle = isLastItem ? css(style.link, style.linkDisabled) : css(style.link);
            return (
              <li className={css(style.listItem)} key={displayName}>
                {!children?.length ? (
                  <BreadcrumbItem
                    path={path}
                    linkStyle={linkStyle}
                    displayName={displayName}
                  />
                ) : (
                  <>
                    <BreadcrumbButton
                      modalRef={modalRef}
                      displayName={displayName}
                      onClick={setShowChildren}
                      setHover={setHover}
                      hover={hover}
                      showChildren={showChildren}
                      style={style.link}
                    />
                    {showChildren ? (
                      <BreadcrumbModal
                        modalRef={modalRef}
                        breadcrumbs={children}
                        linkStyle={style.link}
                        listStyle={style.listItem}
                      />
                    ) : null}
                  </>
                )}
                {!isLastItem ? (
                  <Span className={css(style.chevron)}>
                    <ChevronThinIcon
                      breadCrumbsTextColor={breadCrumbsTextColor}
                    />
                  </Span>
                ) : null}
              </li>
            );
          })}
        </ul>
      </nav>
      )}
    </ErrorBoundary>
  );
};
