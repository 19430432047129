import * as React from 'react';
import { SectionTitleFontSizeProps } from './SectionTitle.utils';
import { useTheme } from '../../../theming/useTheme';
import { H2 } from '../../../typography/semanticTags';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { OptionalAdditionalStyle } from '../../components.utils';

const componentConfigThemeKey = 'secondarySectionTitle';

export const SecondarySectionTitle: React.FunctionComponent<OptionalAdditionalStyle> = props => {
  const { children, additionalStyle } = props;
  const theme = useTheme<SectionTitleFontSizeProps>(componentConfigThemeKey);

  return (
    <ErrorBoundary>
      <H2 {...theme} {...additionalStyle ? { style: additionalStyle.style } : {}}>
        {children}
      </H2>

    </ErrorBoundary>
  );
};
