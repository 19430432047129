import * as React from 'react';
import { Kasda } from '../../Kasda/Kasda';

interface RobotsMetaProps {
  noIndex: boolean;
}

const getCombinedContent = (actualContents: Array<string>) => {
  return actualContents.reduce((combinedContent, content, index) => {
    if (index === 0) {
      return content;
    }
    return `${combinedContent}, ${content}`;
  }, '');
};

export const RobotsMeta: React.FC<RobotsMetaProps> = props => {
  const contents = ['max-image-preview:large'];
  const { noIndex } = props;
  if (noIndex === true) {
    contents.push('noindex', 'nofollow');
  }
  return <Kasda.Meta><meta name="robots" content={getCombinedContent(contents)} /></Kasda.Meta>;
};
