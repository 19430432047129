import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { TypographySize } from 'mm-theme-configuration/src/consts';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { VerticalCard } from '../../partials/cards/verticalCard/VerticalCard';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { useTheme } from '../../../theming/useTheme';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { createImageWidths } from '../../partials/image/image.utils';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { getLayoutComponentPadding, LayoutComponentBaseProps } from '../pageLayouts/layout.utils';
import { VerticalCardThemeProps, componentConfigThemeKey as verticalCardConfigThemeKey,
} from '../../partials/cards/verticalCard/VerticalCard.theme';

export const componentConfigThemeKey = 'threeVCExpandable';

export interface ThreeVCThemeProps {
  titleFontSizeLarge: TypographySize;
  titleFontSizeMedium: TypographySize;
  titleFontSizeSmall: TypographySize;
  metadataFontSizeLarge: TypographySize;
  metadataFontSizeMedium: TypographySize;
  metadataFontSizeSmall: TypographySize;
  largeVerticalGap: number;
  largeHorizontalGap: number;
  mediumVerticalGap: number;
  mediumHorizontalGap: number;
  smallHorizontalGap: number;
  innerCardLargeVerticalGap: number;
  innerCardMediumVerticalGap: number;
  innerCardSmallVerticalGap: number;
  innerCardLargeHorizontalGap: number;
  innerCardMediumHorizontalGap: number;
  innerCardSmallHorizontalGap: number;
  descriptionFontSizeInLargeScreen: TypographySize;
  descriptionFontSizeInMediumScreen: TypographySize;
  descriptionFontSizeInSmallScreen: TypographySize;
  baseUnit: BaseUnit;
}

interface ThreeVCExpandableDataProps extends LayoutComponentBaseProps{
  cards: Array<CardComponentDataProps> | null;
  useFormatMinutesHoursAgo?: boolean;
}

const createStyle = (themeProps: ThreeVCThemeProps) => {
  const { largeVerticalGap, mediumVerticalGap, smallHorizontalGap, largeHorizontalGap, mediumHorizontalGap, baseUnit } = themeProps;
  return StyleSheet.create({
    wrapper: {
      [MEDIA_BREAKPOINTS.large]: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gridTemplateRows: 'auto',
        gridColumnGap: `${largeVerticalGap * baseUnit}px`,
        gridRowGap: `${largeHorizontalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: 'auto',
        gridColumnGap: `${mediumVerticalGap * baseUnit}px`,
        gridRowGap: `${mediumHorizontalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        display: 'grid',
        gridTemplateColumns: 'auto',
        gridTemplateRows: 'auto',
        gridRowGap: `${smallHorizontalGap * baseUnit}px`,
      },
    },
  });
};

const imagesWidths = createImageWidths(360, 360, 360);

export const ThreeVCExpandable: React.FunctionComponent<ThreeVCExpandableDataProps> = props => {
  const { cards, paddingFactors, useFormatMinutesHoursAgo } = props;
  const paddingStyle = getLayoutComponentPadding(paddingFactors);
  const themeProps = useTheme<ThreeVCThemeProps>(componentConfigThemeKey);
  const style = createStyle(themeProps);
  const { innerCardLargeVerticalGap, innerCardMediumVerticalGap, innerCardSmallVerticalGap, innerCardLargeHorizontalGap, innerCardMediumHorizontalGap, innerCardSmallHorizontalGap, titleFontSizeLarge, titleFontSizeMedium, titleFontSizeSmall, metadataFontSizeLarge, metadataFontSizeMedium, metadataFontSizeSmall, descriptionFontSizeInLargeScreen, descriptionFontSizeInMediumScreen, descriptionFontSizeInSmallScreen } = themeProps;
  const themeContextVC = useThemeOverride<VerticalCardThemeProps>({
    titleFontSizeLarge,
    titleFontSizeMedium,
    titleFontSizeSmall,
    metadataFontSizeLarge,
    metadataFontSizeMedium,
    metadataFontSizeSmall,
    descriptionFontSizeInLargeScreen,
    descriptionFontSizeInMediumScreen,
    descriptionFontSizeInSmallScreen,
    verticalGapLarge: innerCardLargeVerticalGap,
    verticalGapMedium: innerCardMediumVerticalGap,
    verticalGapSmall: innerCardSmallVerticalGap,
    horizontalGapLarge: innerCardLargeHorizontalGap,
    horizontalGapMedium: innerCardMediumHorizontalGap,
    horizontalGapSmall: innerCardSmallHorizontalGap,
  }, verticalCardConfigThemeKey);

  return (
    <ErrorBoundary>
      {cards && cards.length > 0
      && (
        <div className={css(paddingStyle, style.wrapper)}>
          <ThemeContext.Provider value={themeContextVC}>
            {cards.map((cardDataProps, index) => {
              return (
                <VerticalCard {...cardDataProps} imageWidths={imagesWidths} key={index} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />
              );
            })}
          </ThemeContext.Provider>
        </div>
      ) }
    </ErrorBoundary>
  );
};
