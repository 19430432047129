import { StyleSheet } from 'aphrodite/no-important';
import React from 'react';
import { Thumbnail } from '../../partials/image/image.types';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { AdditionalStyleSheet } from '../../components.utils';
import { createImageWidths } from '../../partials/image/image.utils';
import { Image } from '../../partials/image/Image';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';

interface AuthorPremiumImageProps {
  image: Thumbnail;
}

const createImageAdditionalStyle = () => {
  return StyleSheet.create({
    style: {
      overflow: 'hidden',
      borderRadius: '100%',
      [MEDIA_BREAKPOINTS.large]: {
        height: '200px',
        width: '200px',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        height: '160px',
        width: '160px',
      },
      [MEDIA_BREAKPOINTS.small]: {
        height: '80px',
        width: '80px',
      },
    },
  }) as AdditionalStyleSheet;
};
export const AuthorPremiumImage: React.FunctionComponent<AuthorPremiumImageProps> = props => {
  const { image } = props;
  const aspectRatio = { x: 1, y: 1 };
  const imageWidths = createImageWidths(360, 360, 360);
  const imageAdditionalStyle = createImageAdditionalStyle();
  return (
    <ErrorBoundary>
      <Image {...image} aspectRatio={aspectRatio} imageWidths={imageWidths} additionalStyle={imageAdditionalStyle} />
    </ErrorBoundary>
  );
};
