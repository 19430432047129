export class ConsoleReporter {
  reportException(err: Error, additionalData = {}) {
    // eslint-disable-next-line no-console
    console.error(err, additionalData);
  }

  reportWarning(message: string) {
    // eslint-disable-next-line no-console
    console.warn(message);
  }
}
