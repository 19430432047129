import { AppDefaultNavigationState } from '../../../initialStateRegistration';

export const getHeader = (state: AppDefaultNavigationState) => state.header;

export const getSubHeader = (state: AppDefaultNavigationState) => getHeader(state)?.subHeader;

export const getThirdHeader = (state: AppDefaultNavigationState) => getHeader(state)?.thirdHeader;

export const getSecondaryLogo = (state: AppDefaultNavigationState) => getHeader(state)?.secondaryLogo;

export const getLatestArticles = (state: AppDefaultNavigationState) => getHeader(state)?.latestArticles;
