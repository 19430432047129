import React from 'react';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { ImportedEmbeddedContentWithCard, ImportedEmbeddedContentProps } from './ImportedEmbeddedContentWithCard';
import { VerticalCard } from '../../partials/cards/verticalCard/VerticalCard';

export const ImportedEmbeddedContent: React.FunctionComponent<ImportedEmbeddedContentProps> = props => {
  const ImportedEmbeddedContentComponent = ImportedEmbeddedContentWithCard(VerticalCard);
  return (
    <ErrorBoundary>
      <ImportedEmbeddedContentComponent {...props} />
    </ErrorBoundary>
  );
};
