import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { TypographySize } from 'mm-theme-configuration/dist/consts';
import { Link } from '../../../../partials/link/Link';
import { Span } from '../../../../../typography/semanticTags';
import { getLinkColorsStyle } from './linkStyle.utils';
import { getStyleBySize } from '../../../../../typography/semanticTags/semanticTags.utils';
import { useTheme } from '../../../../../theming/useTheme';

interface RegularHeaderLinkProps {
  activeStateColor: string;
  hoverStateColor: string;
  idleColor: string;
  currentLinkColor: string;
  isActive: boolean;
  href: string;
  text: string;
  linkFontSizeLarge: TypographySize;
  linkFontSizeMedium: TypographySize;
  linkFontSizeSmall: TypographySize;
  isInvisibleLink?: boolean;
}

const getVisibilityStyle = (isInvisibleLink: boolean) => StyleSheet.create({
  spanWrapper: {
    visibility: isInvisibleLink ? 'hidden' : 'visible',
  },
  span: {
    display: 'flex',
    alignItems: 'center',
  },
});
export const RegularHeaderLink: React.FunctionComponent<RegularHeaderLinkProps> = props => {
  const { href, text, activeStateColor, hoverStateColor, idleColor, isActive, linkFontSizeLarge, linkFontSizeMedium, linkFontSizeSmall, isInvisibleLink = false, currentLinkColor } = props;
  const { fontSizesStyles } = useTheme();
  const linkColorsStyle = getLinkColorsStyle(activeStateColor, hoverStateColor, idleColor, currentLinkColor, isActive);
  const fontStyle = getStyleBySize(fontSizesStyles, { fontSizeSmall: linkFontSizeSmall, fontSizeMedium: linkFontSizeMedium, fontSizeLarge: linkFontSizeLarge });
  const visibilityStyle = getVisibilityStyle(isInvisibleLink);
  return (
    <Link href={href} className={css(visibilityStyle.spanWrapper)}>
      <Span className={css(visibilityStyle.span, fontStyle, linkColorsStyle.link)}>{text}</Span>
    </Link>
  );
};
