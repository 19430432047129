import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Colors } from 'mm-theme-configuration';
import { AdditionalStyleSheet, MandatoryAdditionalStyle } from '../../components.utils';
import { BlockWidths, TableData } from '../../components/pageLayouts/BlockTypes.utils';
import { useTheme } from '../../../theming/useTheme';
import { Paragraph } from '../Paragraph';
import { baseUnit } from '../../../theming/baseUnitDefinition';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { createBlockStyle } from '../blockMutualStyle';

const getStyle = (colors: Colors, defaultBlocksWidths: BlockWidths) => StyleSheet.create({
  tableComponent: {
    overflow: 'auto',
    marginLeft: baseUnit,
    marginRight: baseUnit,
    ...createBlockStyle(defaultBlocksWidths),
  },
  table: {
    borderCollapse: 'collapse',
  },
  tableCell: {
    border: `1px solid ${colors.lightGrey}`,
    borderLeft: 'none',
    borderRight: 'none',
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
    color: colors.darkGrey,
    minWidth: 100,
    maxWidth: 270,
    textAlign: 'left',
  },
  th: {
    fontWeight: 'bold',
    backgroundColor: colors.lightGrey,
  },
});

export const getParagraphAdditionalStyle = () => StyleSheet.create({
  style: {
    [MEDIA_BREAKPOINTS.large]: {
      width: 'auto',
      fontWeight: 'inherit',
    },
    [MEDIA_BREAKPOINTS.medium]: {
      width: 'auto',
      fontWeight: 'inherit',
    },
    [MEDIA_BREAKPOINTS.small]: {
      width: 'auto',
      fontWeight: 'inherit',
    },
    padding: 0,
    margin: 0,
  },
}) as AdditionalStyleSheet;

interface TableProps extends MandatoryAdditionalStyle {
  data: TableData;
  dataId: string;
  defaultBlocksWidths: BlockWidths;
}

export const Table = ({
  data,
  additionalStyle,
  dataId,
  defaultBlocksWidths,
}: TableProps) => {
  const { colors }: { colors: Colors } = useTheme();
  const styles = getStyle(colors, defaultBlocksWidths);
  const paragraphAdditionalStyle = getParagraphAdditionalStyle();
  const [headerRow, ...rows] = data;

  return (
    <div className={css(styles.tableComponent, additionalStyle.style)}>
      <table className={css(styles.table)}>
        <thead>
          <tr>
            {headerRow.map((item, itemIndex) => (
              <th key={itemIndex} className={css(styles.tableCell, styles.th)}>
                <Paragraph
                  key={dataId}
                  html={item}
                  additionalStyle={paragraphAdditionalStyle}
                  dataId={dataId}
                  defaultBlocksWidths={defaultBlocksWidths}
                />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((item, itemIndex) => (
                <td key={itemIndex} className={css(styles.tableCell)}>
                  <Paragraph
                    key={dataId}
                    html={item}
                    additionalStyle={paragraphAdditionalStyle}
                    dataId={dataId}
                    defaultBlocksWidths={defaultBlocksWidths}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
