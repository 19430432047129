
import * as React from 'react';
import { StyleSheet } from 'aphrodite/no-important';

import { Colors } from 'mm-theme-configuration';
import { LinkWithButtonStyle } from '../buttons/LinkWithButtonStyle';
import { AdditionalStyleSheet } from '../../components.utils';
import { useTheme } from '../../../theming/useTheme';
import { TypographySizes, getStyleBySize } from '../../../typography/semanticTags/semanticTags.utils';
import { TranslatedFontSize } from '../../../theming/fontSizeTranslator';

const componentConfigThemeKey = 'tag';

interface TagThemeProps {
  tagActiveColor: string;
  tagHoverColor: string;
}

interface TagProps {
  label: string;
  link?: string | undefined;
}

const createStyle = (colors: Colors, activeColor: string, hoverColor: string) => {
  return StyleSheet.create({
    style: {
      padding: '10px',
      minWidth: 'min-content',
      width: 'max-content',
      textWrap: 'nowrap',
      borderRadius: 0,
      backgroundColor: colors.white,
      color: activeColor,
      borderColor: activeColor,
      border: `1px solid ${activeColor}`,
      boxShadow: 'none',
      textDecoration: 'none',
      ':hover': {
        backgroundColor: colors.white,
        color: hoverColor,
        borderColor: hoverColor,
        boxShadow: 'none',
      },
    },
  });
};

const extendFontSizesStyle = (fontSizesStyles: TranslatedFontSize, fontWeight = 500) => {
  return {
    ...fontSizesStyles,
    [TypographySizes.TINY]: {
      ...fontSizesStyles[TypographySizes.TINY],
      style: {
        ...fontSizesStyles[TypographySizes.TINY].style,
        fontWeight,
      },
    },
  };
};

export const Tag = ({ label, link }: TagProps) => {
  const { colors, fontSizesStyles, tagActiveColor, tagHoverColor } = useTheme<TagThemeProps & { fontSizesStyles: TranslatedFontSize; colors: Colors }>(componentConfigThemeKey);

  const tagFontSizes = getStyleBySize(extendFontSizesStyle(fontSizesStyles), { fontSizeSmall: TypographySizes.TINY, fontSizeMedium: TypographySizes.TINY, fontSizeLarge: TypographySizes.TINY });
  const additionalStyle = createStyle(colors, tagActiveColor, tagHoverColor) as AdditionalStyleSheet;
  return (
    <LinkWithButtonStyle additionalStyle={additionalStyle} additionalTextStyle={tagFontSizes} href={link}>{label}</LinkWithButtonStyle>
  );
};
