import React, { PropsWithChildren } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { BlockWidths } from '../../components/pageLayouts/BlockTypes.utils';
import { createBlockStyle } from '../blockMutualStyle';
import { baseUnit } from '../../../theming/baseUnitDefinition';
import { useViewabilityHandler } from '../../../viewability/viewabilityHandler';
import { AdditionalStyleSheet } from '../../components.utils';

export interface TableOfContentsWrapperProps {
  defaultBlocksWidths: BlockWidths;
  additionalStyle: AdditionalStyleSheet;
  numberOfHeadings: number;
  dataId: string;
}

const createWrapperStyle = (defaultBlocksWidths: BlockWidths) => StyleSheet.create({
  style: {
    marginTop: baseUnit,
    ...createBlockStyle(defaultBlocksWidths),
  },
}).style;

export const TableOfContentsWrapper = ({
  defaultBlocksWidths,
  children,
  dataId,
  numberOfHeadings,
  additionalStyle,
}: PropsWithChildren<TableOfContentsWrapperProps>) => {
  const viewabilityCallback = React.useCallback(() => {
    // @ts-ignore
    window.mmClientApi = window.mmClientApi || [];
    // @ts-ignore
    window.mmClientApi.push('analytics', {
      event_category: 'Article',
      event_action: 'Table Of Contents viewable impression',
      event_label: `Number of links = ${numberOfHeadings}`,
    });
  }, [numberOfHeadings]);

  const ref = useViewabilityHandler(viewabilityCallback, 0.5);
  const wrapperStyle = createWrapperStyle(defaultBlocksWidths);
  return (
    <div ref={ref} className={css(wrapperStyle, additionalStyle.style)} data-mm-id={dataId}>
      {children}
    </div>
  );
};
