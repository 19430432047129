import { RATIO, SEMANTIC_TAG, TypographySize } from 'mm-theme-configuration/dist/consts';
import * as React from 'react';
import { css, CSSProperties, StyleSheet } from 'aphrodite/no-important';
import { useTheme } from '../../../../theming/useTheme';
import { createStyleForBoxShadow } from '../../../../theming/boxShadow';
import { DynamicSemanticTag } from '../../../../typography/semanticTags/DynamicSemanticTag';
import { HUGE_SCREEN_SIZE, LARGE_SCREEN_SIZE, MEDIA_BREAKPOINTS } from '../../../../mediaQueries.const';
import { baseUnit } from '../../../../theming/baseUnitDefinition';
import { semanticCSS } from '../../../../typography/semanticTags';
import { Link } from '../../link/Link';
import { ellipsify } from '../../../../typography/ellipsify';
import {
  createImageAspectRatios,
  createImageWidths,
} from '../../image/image.utils';
import { Thumbnail } from '../../image/image.types';
import { Image } from '../../image/Image';
import { AdditionalStyleSheet } from '../../../components.utils';

export const componentConfigThemeKey = 'announcementCard';
type MaxWidthValues = typeof LARGE_SCREEN_SIZE | typeof HUGE_SCREEN_SIZE;

export interface AnnouncementComponentDataProps {
    title: string;
    seoDescription: string;
    path: string;
    image: Thumbnail;
    lazyLoad?: boolean;
    maxWidth?: MaxWidthValues;
}

export interface AnnouncementCardThemeProps {
    titleColor: string;
    titleFontSizeLarge: TypographySize;
    titleFontSizeMedium: TypographySize;
    titleFontSizeSmall: TypographySize;
    titleSemanticTag: SEMANTIC_TAG;
    subHeadlineColor: string;
    subHeadlineFontSizeLarge: TypographySize;
    subHeadlineFontSizeMedium: TypographySize;
    subHeadlineFontSizeSmall: TypographySize;
    subHeadlineSemanticTag: SEMANTIC_TAG;
    wrapperGapLarge: number;
    wrapperGapMedium: number;
    wrapperGapSmall: number;
    horizontalGapLarge: number;
    horizontalGapMedium: number;
    horizontalGapSmall: number;
    boxShadowTypeNormal: string;
    boxShadowTypeActive: string;
    boxShadowTypeHover: string;
    borderWidthTypeNormal: number;
    borderWidthTypeHover: number;
    borderWidthTypeActive: number;
    borderColorTypeNormal: string;
    borderColorTypeHover: string;
    borderColorTypeActive: string;
    aspectRatioLarge: RATIO;
    aspectRatioMedium: RATIO;
    aspectRatioSmall: RATIO;
}

const numberOfLinesForTitle = 1;
const numberOfLinesForSubHeadlineLargeAndMedium = 1;
const numberOfLinesForSubHeadlineSmall = 2;

const createStyle = ({
  titleColor,
  subHeadlineColor,
  wrapperGapLarge,
  wrapperGapMedium,
  wrapperGapSmall,
  horizontalGapLarge,
  horizontalGapMedium,
  horizontalGapSmall,
}: AnnouncementCardThemeProps, maxWidth: MaxWidthValues = LARGE_SCREEN_SIZE) => StyleSheet.create({
  wrapper: {
    display: 'grid',
    overflow: 'hidden',
    background: 'transparent',
    justifyItems: 'center',
    maxWidth: `${maxWidth}px`,
    margin: 'auto',
  },
  link: {
    position: 'relative',
    textDecoration: 'none',
    display: 'block',
    gridTemplateRows: 'max-content auto',
    maxWidth: `${maxWidth}px`,
    width: '-webkit-fill-available',
    paddingLeft: '10px',
    paddingRight: '10px',
    [MEDIA_BREAKPOINTS.large]: {
      height: '80px',
    },
    [MEDIA_BREAKPOINTS.medium]: {
      height: '80px',
    },
    [MEDIA_BREAKPOINTS.small]: {
      height: '80px',
    },
  },
  innerWrapper: {
    overflow: 'hidden',
    display: 'grid',
    background: 'transparent',
    position: 'relative',
    [MEDIA_BREAKPOINTS.large]: {
      padding: `${wrapperGapLarge * baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      padding: `${wrapperGapMedium * baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      padding: `${wrapperGapSmall * baseUnit}px`,
    },
  },
  title: {
    color: titleColor,
    height: 'fit-content',
    ...ellipsify(numberOfLinesForTitle).style as CSSProperties,
    [MEDIA_BREAKPOINTS.large]: {
      paddingBottom: `${horizontalGapLarge * baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      paddingBottom: `${horizontalGapMedium * baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      paddingBottom: `${horizontalGapSmall * baseUnit}px`,
    },
  },
  seoDescription: {
    color: subHeadlineColor,
    height: 'fit-content',
    [MEDIA_BREAKPOINTS.large]: {
      ...ellipsify(numberOfLinesForSubHeadlineLargeAndMedium).style as CSSProperties,

    },
    [MEDIA_BREAKPOINTS.medium]: {
      ...ellipsify(numberOfLinesForSubHeadlineLargeAndMedium).style as CSSProperties,

    },
    [MEDIA_BREAKPOINTS.small]: {
      ...ellipsify(numberOfLinesForSubHeadlineSmall).style as CSSProperties,
    },
  },
});

export const AnnouncementCard: React.FunctionComponent<AnnouncementComponentDataProps> = props => {
  const { title, seoDescription, path, image, lazyLoad = false, maxWidth } = props;
  const themeProps = useTheme<AnnouncementCardThemeProps>(componentConfigThemeKey);
  const {
    titleFontSizeLarge,
    titleFontSizeMedium,
    titleFontSizeSmall,
    titleSemanticTag,
    subHeadlineFontSizeLarge,
    subHeadlineFontSizeMedium,
    subHeadlineFontSizeSmall,
    subHeadlineSemanticTag,
    boxShadowTypeActive,
    boxShadowTypeHover,
    boxShadowTypeNormal,
    borderColorTypeActive,
    borderColorTypeHover,
    borderColorTypeNormal,
    borderWidthTypeActive,
    borderWidthTypeHover,
    borderWidthTypeNormal,
    aspectRatioLarge,
    aspectRatioMedium,
    aspectRatioSmall,
  } = themeProps;

  const borderBoxShadow = {
    borderColorTypeActive,
    borderColorTypeHover,
    borderColorTypeNormal,
    borderWidthTypeActive,
    borderWidthTypeHover,
    borderWidthTypeNormal,
  };
  const boxShadowStyle = createStyleForBoxShadow({ boxShadowTypeNormal, boxShadowTypeHover, boxShadowTypeActive, borderBoxShadow });
  const style = createStyle(themeProps, maxWidth);
  const imageWidths = createImageWidths(360, 720, 1080);
  const createImageAdditionalStyle = () => StyleSheet.create({
    style: {
      position: 'absolute',
      top: '0',
      bottom: '0',
      margin: '0',
      [MEDIA_BREAKPOINTS.large]: {
        left: '10px',
        right: '10px',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        left: '10px',
        right: '10px',
      },
      [MEDIA_BREAKPOINTS.small]: {
        left: '10px',
        right: '10px',
      },
    },
  }) as AdditionalStyleSheet;

  return (
    <div className={css(style.wrapper, boxShadowStyle.style)}>
      <Link className={css(style.link)} href={path}>
        <Image
          {...image}
          lazyLoad={lazyLoad}
          imageAspectRatios={createImageAspectRatios(aspectRatioSmall, aspectRatioMedium, aspectRatioLarge)}
          imageWidths={imageWidths}
          additionalStyle={createImageAdditionalStyle()}
        />
        <div className={css(style.innerWrapper)}>
          <DynamicSemanticTag
            semanticTag={titleSemanticTag}
            fontSizeLarge={titleFontSizeLarge}
            fontSizeMedium={titleFontSizeMedium}
            fontSizeSmall={titleFontSizeSmall}
            styles={semanticCSS(style.title)}
          >
            {title}
          </DynamicSemanticTag>
          <DynamicSemanticTag
            semanticTag={subHeadlineSemanticTag}
            fontSizeLarge={subHeadlineFontSizeLarge}
            fontSizeMedium={subHeadlineFontSizeMedium}
            fontSizeSmall={subHeadlineFontSizeSmall}
            styles={semanticCSS(style.seoDescription)}
          >
            {seoDescription}
          </DynamicSemanticTag>
        </div>
      </Link>
    </div>
  );
};
