import * as React from 'react';
import { Kasda } from '../../Kasda/Kasda';

interface FacebookMetaProps {
  appId: string | null;
  pageId: string | null;
}

export const FacebookMeta = ({ appId, pageId }: FacebookMetaProps) => (
  <Kasda.Meta>
    {appId ? <meta property="fb:app_id" content={appId} /> : null}
    {pageId ? <meta property="fb:pages" content={pageId} /> : null}
  </Kasda.Meta>
);
