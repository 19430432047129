import { Palette, CONFIGURATION_TYPES, ThemeConfiguration } from 'mm-theme-configuration/dist/interfaces';
import { RATIO } from 'mm-theme-configuration/dist/consts';
import { PaletteManager } from './paletteManager';
import { translate } from './themeConfigurationTranslator';
import { getElevationType } from './boxShadow';

export class ThemeManager {
  paletteManager: PaletteManager;

  themeConfiguration: ThemeConfiguration[];

  constructor(palette: Palette, themeConfiguration: ThemeConfiguration[]) {
    this.themeConfiguration = themeConfiguration;
    this.paletteManager = new PaletteManager(palette);
  }

  instructions = {
    [CONFIGURATION_TYPES.COLOR]: (key: string) => this.paletteManager.getColorByKey(key),
    [CONFIGURATION_TYPES.NUMBER]: (key: number) => key,
    [CONFIGURATION_TYPES.BOX_SHADOW]: (key: string) => getElevationType(key),
    [CONFIGURATION_TYPES.TYPOGRAPHY]: (key: string) => key,
    [CONFIGURATION_TYPES.BOOLEAN]: (key: boolean) => key,
    [CONFIGURATION_TYPES.ALIGNMENT]: (key: string) => key,
    [CONFIGURATION_TYPES.RATIO]: (key: RATIO) => key,
    [CONFIGURATION_TYPES.SEMANTIC_TAG]: (key: string) => key,
    [CONFIGURATION_TYPES.FACTOR]: (key: number) => key,
  };

  getConfiguration() {
    return {
      ...translate(this.instructions, this.themeConfiguration),
      fontSizeMap: this.paletteManager.getFontSizeMap(),
      colors: this.paletteManager.getColors(),
    };
  }
}
