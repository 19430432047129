import React, { useCallback, useState } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { ChevronIcon, Directions } from '../../ChevronIcon';
import { DropDownMenu } from '../DropDownMenu';
import { useTheme } from '../../../../../../theming/useTheme';
import { ErrorBoundary } from '../../../../../errorHandling/ErrorBoundary';
import { MEDIA_BREAKPOINTS } from '../../../../../../mediaQueries.const';
import { H4 } from '../../../../../../typography/semanticTags';
import { NavigationLink } from './PrimaryHeader';
import { componentConfigThemeKey, HeaderThemeProps } from '../../Header.theme';

export interface EditionDataProps {
  text: string;
  href: string;
}

export interface EditionsDataProps {
  editions: NavigationLink[];
  editionEndpoint: string;
}

const getEditionsStyle = (color: string) => {
  return StyleSheet.create({
    editionsWrapper: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      position: 'relative',
      [MEDIA_BREAKPOINTS.medium]: {
        display: 'none',
      },
      [MEDIA_BREAKPOINTS.small]: {
        display: 'none',
      },
    },
    editions: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      width: '144px',
      color,
      textDecoration: 'none',
      backgroundColor: 'transparent',
      border: 'none',
      outline: 'none',
      ':hover': {
        cursor: 'pointer',
      },
    },
  });
};

export const getActiveEdition = (editions: NavigationLink[], editionEndpoint: string): NavigationLink => {
  return editions.find(edition => edition.link.href === editionEndpoint) || editions[0];
};

export const Editions: React.FunctionComponent<EditionsDataProps> = props => {
  const { editions, editionEndpoint } = props;
  const { editionsIconNormalColor } = useTheme<HeaderThemeProps>(componentConfigThemeKey);
  const style = getEditionsStyle(editionsIconNormalColor);
  const activeEdition = getActiveEdition(editions, editionEndpoint);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const editionsClickHandler = useCallback(() => {
    setIsMenuOpen(!isMenuOpen);
  }, [isMenuOpen]);

  return (
    <ErrorBoundary>
      <div className={css(style.editionsWrapper)}>
        <button type="button" className={css(style.editions)} onClick={editionsClickHandler}>
          <H4>{activeEdition.link.text}</H4>
          <ChevronIcon direction={isMenuOpen ? Directions.UP : Directions.DOWN} color={editionsIconNormalColor} />
        </button>
        {isMenuOpen && <DropDownMenu menuLinks={editions} configThemeKey="editionsMenu" activeEndpoint={editionEndpoint} />}
      </div>
    </ErrorBoundary>
  );
};
