export const SOCIAL_PLATFORMS = {
  FACEBOOK: 'facebook',
  TWITTER: 'twitter',
  INSTAGRAM: 'instagram',
  REDDIT: 'reddit',
};

export const SEARCH_PLATFORMS = {
  GOOGLE: 'google',
  BING: 'bing',
  YAHOO: 'yahoo',
};

export const PLATFORM_REGEXES = {
  [SOCIAL_PLATFORMS.FACEBOOK]: [/^facebook\./, /\.facebook\./],
  [SOCIAL_PLATFORMS.TWITTER]: [/^twitter\./, /\.twitter\./, /^t\.co/],
  [SOCIAL_PLATFORMS.INSTAGRAM]: [/^instagram\./, /\.instagram\./],
  [SOCIAL_PLATFORMS.REDDIT]: [/^reddit\./, /\.reddit\./],
  [SEARCH_PLATFORMS.GOOGLE]: [
    /^google\./,
    /\.google\./,
    /googleapi/,
    /googlequicksearchbox/,
    /amp-12up-com/,
    /amp\.12up\.com/,
  ],
  [SEARCH_PLATFORMS.BING]: [/^bing\./, /\.bing\./],
  [SEARCH_PLATFORMS.YAHOO]: [/^yahoo\./, /\.yahoo\./],
};

export const TRAFFIC_SOURCES = {
  UNKNOWN: 'unknown',
  INFLUENCER: 'influencer',
  APP: 'app',
  EMBED_PARTNER: 'embed_partner',
  SYNDICATION: 'syndication',
  PAID: 'paid',
  SOCIAL: 'social',
  PARTNER: 'tr_partner',
  ORGANIC: 'organic',
  NAVIGATION: 'navigation',
  RSS: 'rss',
  SHARE: 'share',
  EMAIL: 'email',
  REFERRAL: 'referral',
  DIRECT: 'direct',
};

export const TRAFFIC_INFO_KEY = 'trafficInfo';
