import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { MandatoryAdditionalStyle } from '../../components.utils';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { BlockWidths } from '../../components/pageLayouts/BlockTypes.utils';
import { createBlockStyle } from '../blockMutualStyle';
import { Kasda } from '../../../Kasda/Kasda';

interface TwitterDataProps {
  html: string;
  dataId?: string;
  defaultBlocksWidths: BlockWidths;
}

type TwitterProps = TwitterDataProps & MandatoryAdditionalStyle;

const getBlockStyle = (defaultBlocksWidths: BlockWidths) => {
  return StyleSheet.create({
    style: {
      ...createBlockStyle(defaultBlocksWidths),
      display: 'flex',
      justifyContent: 'center',
    },
  }).style;
};

const TwitterScript = `window.twttr = (function(d, s, id) {
  var js, fjs = d.getElementsByTagName(s)[0],
    t = window.twttr || {};
  if (d.getElementById(id)) return t;
  js = d.createElement(s);
  js.id = id;
  js.src = "https://platform.twitter.com/widgets.js";
  fjs.parentNode.insertBefore(js, fjs);

  t._e = [];
  t.ready = function(f) {
    t._e.push(f);
  };

  return t;
}(document, "script", "twitter-wjs"));`;

export const TwitterComponent: React.FunctionComponent<TwitterProps> = props => {
  const { html, additionalStyle, dataId, defaultBlocksWidths } = props;

  const twitterStyle = getBlockStyle(defaultBlocksWidths);

  React.useEffect(() => {
    // @ts-ignore TS2339
    if (typeof window.twttr !== 'undefined' && typeof window.twttr.widgets !== 'undefined') {
      // @ts-ignore TS2339
      window.twttr.widgets.load();
    } else {
      const script = document.createElement('script');
      script.innerHTML = TwitterScript;
      document.head.appendChild(script);
    }
  }, []);

  return (
    <ErrorBoundary>
      <Kasda.Script>
        <script>{TwitterScript}</script>
      </Kasda.Script>
      {/* eslint-disable-next-line react/no-danger */}
      <figure data-mm-id={dataId} dangerouslySetInnerHTML={{ __html: html }} className={css(twitterStyle, additionalStyle.style)} />
    </ErrorBoundary>
  );
};

export const Twitter = (props: TwitterProps) => <ErrorBoundary><TwitterComponent {...props} /></ErrorBoundary>;
