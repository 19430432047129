import { StyleSheet } from 'aphrodite/no-important';
import { Colors } from 'mm-theme-configuration';
import { baseUnit } from '../../../theming/baseUnitDefinition';

export const createStyles = (colors: Colors) => {
  const { primary, lightGrey, black } = colors;
  return StyleSheet.create({
    titleWrapper: {
      marginRight: '0px',
    },
    articlesWrapper: {
      color: primary,
    },
    greySectionDivider: {
      borderBottom: `1px solid ${lightGrey}`,
      padding: '10px 0px',
    },
    postWrapper: {
      display: 'flex',
      flexDirection: 'row',
      gap: baseUnit,
      alignItems: 'center',
    },
    articleItem: {
      width: '-webkit-fill-available',
      margin: 0,
      color: black,
    },
  });
};
