import { sameDate } from '../../../utils/time.utils';
import { Thumbnail } from '../../partials/image/image.types';

export const CLIP_PATH_SMALL = 'clipPathSmall';
export const CLIP_PATH_LARGE = 'clipPathLarge';

export interface Author {
  name: string;
  link?: string;
  image?: Thumbnail | null;
  bio?: string;
}
export const shouldDisplayUpdatedAt = (showUpdatedAt: boolean, updatedAt: string, createdAt: string) => {
  if (showUpdatedAt) {
    return !sameDate(createdAt, updatedAt);
  }

  return false;
};
