import React, { SyntheticEvent, useState } from 'react';
import { css } from 'aphrodite/no-important';
import { TYPOGRAPHY_SIZE } from 'mm-theme-configuration/dist/consts';
import { useTheme } from '../../../theming/useTheme';
import { createStyles } from './search.styles';
import { getStyleBySize } from '../../../typography/semanticTags/semanticTags.utils';
import { SearchSvg } from './SearchSvg';

interface SearchProps {
  onSearchClick: (searchValue: string) => void;
}

export const Search: React.FunctionComponent<SearchProps> = props => {
  const { onSearchClick } = props;
  const [inputValue, setInputValue] = useState<string>('');
  const { colors, fontSizesStyles } = useTheme();
  const styles = createStyles(colors);

  const inputFontSizes = {
    fontSizeLarge: TYPOGRAPHY_SIZE.LARGE,
    fontSizeMedium: TYPOGRAPHY_SIZE.LARGE,
    fontSizeSmall: TYPOGRAPHY_SIZE.LARGE,
  };

  const onSearchIconClick = () => {
    onSearchClick(inputValue);
  };

  const onInput = (e: SyntheticEvent<HTMLInputElement>) => {
    setInputValue(e.currentTarget.value || '');
  };

  const onKeyDown = (e: SyntheticEvent<HTMLInputElement>) => {
    const isEnterPressed = (!(e as unknown as KeyboardEvent).shiftKey && (e as unknown as KeyboardEvent).key.toLowerCase() === 'enter');
    if (isEnterPressed) {
      e.preventDefault();
      onSearchClick(inputValue);
    }
  };

  return (
    <div className={css(styles.container)}>
      <input
        className={css(styles.input, getStyleBySize(fontSizesStyles, inputFontSizes))}
        placeholder="Search"
        onInput={onInput}
        onKeyDown={onKeyDown}
      />
      <div className={css(styles.iconContainer)} onClick={onSearchIconClick}>
        <SearchSvg />
      </div>
    </div>
  );
};
