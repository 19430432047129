import React, { useEffect, useState } from 'react';
import { TYPOGRAPHY_SIZE, SEMANTIC_TAG_VALUES, ALIGNMENT_OPTIONS } from 'mm-theme-configuration/dist/consts';
import { Colors } from 'mm-theme-configuration';
import { CardComponentDataProps } from '../cards.utils';
import { useTheme } from '../../../../theming/useTheme';
import { TextNextToImage } from '../TextNextToImage';
import { getIsMobileClient } from '../../../components.utils';


export interface LetterCardProps {
    card: CardComponentDataProps;
    letterTitle?: string;
}


export const LetterCardText = ({ card }: LetterCardProps) => {
  const { title, description, authors, updatedAt, createdAt, updatedAtISO, createdAtISO } = card;
  const timestamp = updatedAt || createdAt;
  const timestampISO = updatedAtISO || createdAtISO;
  const { colors }: {colors: Colors} = useTheme();
  const [titleNumLines, setTitleNumLines] = useState(3);
  useEffect(() => {
    setTitleNumLines(getIsMobileClient() ? 2 : 3);
  }, []);

  return (
    <TextNextToImage {...{
      title,
      authors: authors?.slice(0, 1),
      description: `"${description}"`,
      timestamp,
      timestampISO,
      horizontalGapLarge: 0,
      horizontalGapMedium: 0,
      horizontalGapSmall: 0,
      verticalGapLarge: 0,
      verticalGapMedium: 0,
      verticalGapSmall: 0,
      titleSemanticTag: SEMANTIC_TAG_VALUES.H3,
      titleFontSizeMedium: TYPOGRAPHY_SIZE.BIGGER,
      titleFontSizeSmall: TYPOGRAPHY_SIZE.BIGGER,
      titleFontSizeLarge: TYPOGRAPHY_SIZE.BIGGER,
      titleDescriptionHoverColor: colors.primary,
      numberOfLinesForTitle: titleNumLines,
      showDescriptionInLargeScreen: true,
      showDescriptionInMediumScreen: true,
      showDescriptionInSmallScreen: true,
      descriptionSemanticTag: SEMANTIC_TAG_VALUES.H5,
      descriptionFontSizeInSmallScreen: TYPOGRAPHY_SIZE.NORMAL,
      descriptionFontSizeInLargeScreen: TYPOGRAPHY_SIZE.NORMAL,
      descriptionFontSizeInMediumScreen: TYPOGRAPHY_SIZE.NORMAL,
      descriptionColor: colors.darkGrey,
      numberOfLinesForDescription: 10,
      showAuthorInLargeScreen: true,
      showAuthorInMediumScreen: true,
      showAuthorInSmallScreen: true,
      showDateInLargeScreen: true,
      showDateInMediumScreen: true,
      showDateInSmallScreen: true,
      descriptionHorizontalGap: 0.75,
      metadataSemanticTag: SEMANTIC_TAG_VALUES.H5,
      metadataFontSizeLarge: TYPOGRAPHY_SIZE.TINY,
      metadataFontSizeMedium: TYPOGRAPHY_SIZE.TINY,
      metadataFontSizeSmall: TYPOGRAPHY_SIZE.TINY,
      showMetadataInLargeScreen: true,
      showMetadataInMediumScreen: true,
      showMetadataInSmallScreen: true,
      showMetadataOnBottom: false,
      metadataMarginTop: 0.75,
      authorColor: colors.darkGrey,
      numberOfLinesForAuthors: 1,
      pipeColor: colors.darkGrey,
      dateColor: colors.darkGrey,
      titleAlignment: ALIGNMENT_OPTIONS.LEFT,
      metadataAlignment: ALIGNMENT_OPTIONS.LEFT,
      isClickableAuthors: true,
      titleColor: colors.darkGrey,
    }}
    />
  );
};
