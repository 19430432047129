import { ConsentString } from 'consent-string';
import { v4 as uuid } from 'uuid';

declare global {
    interface Window {
      __cmp: Function | undefined;
      __tcfapi: Function | undefined;
    }
}

export function getCookieByName(name: string) {
  // @ts-ignore
  return (document.cookie.match(`(^|; )${name}=([^;]*)`) || 0)[2];
}

export function setCookie(name: string, value: string | number) {
  document.cookie = `${name}=${value}`;
}

export function readPublisherConsentCookie() {
  if (!window.__tcfapi) {
    return getCookieByName('eupubconsent');
  }
  return '';
}

export function updateGdprApplies() {
  if (!window.__tcfapi) {
    // eslint-disable-next-line no-underscore-dangle
    if (typeof window.__cmp === 'undefined') {
      throw new Error('window doesn\'t have __cmp');
    }
    // eslint-disable-next-line no-underscore-dangle,@typescript-eslint/no-explicit-any,@typescript-eslint/no-unused-vars
    window.__cmp('getConsentData', null, (data: {gdprApplies: any}, success: boolean) => {
      setCookie('gdprApplies', data.gdprApplies ? 1 : 0);
    });
  }
}

export const initSessionId = () => {
  const thirtyMinutes = 30 * 60;
  const sessionId = getCookieByName('mm-session-id');
  if (sessionId) {
    document.cookie = `mm-session-id=${sessionId}; max-age=${thirtyMinutes}; path=/`;
  } else {
    const newSessionId = uuid();
    document.cookie = `mm-session-id=${newSessionId}; max-age=${thirtyMinutes}; path=/`;
  }
};

export function getAnalyticsConsent(consentString: string) {
  const consentData = new ConsentString(consentString);
  const measurementPurpose = 17;
  // @ts-ignore
  return !!consentData.getPurposesAllowed().find(purpose => purpose === measurementPurpose);
}

export function getGdprApplies() {
  if (!window.__tcfapi) {
    return getCookieByName('gdprApplies') || '1';
  }
  return '';
}
