import { ALIGNMENT, RATIO, SEMANTIC_TAG, TypographySize } from 'mm-theme-configuration/dist/consts';
import { TranslatedFontSize } from '../../../../theming/fontSizeTranslator';
import { Style } from '../../../components.utils';

export const componentConfigThemeKey = 'verticalCard';

export type MainCategoryThemeProps = {
    showMainCategoryInLargeScreen: boolean;
    showMainCategoryInMediumScreen: boolean;
    showMainCategoryInSmallScreen: boolean;
    mainCategorySemanticTag: SEMANTIC_TAG;
    mainCategoryFontSizeLarge: TypographySize;
    mainCategoryFontSizeMedium: TypographySize;
    mainCategoryFontSizeSmall: TypographySize;
    mainCategoryTextColor: string;
    mainCategoryBackgroundColor: string;
    mainCategoryVerticalGapLarge: number;
    mainCategoryVerticalGapMedium: number;
    mainCategoryVerticalGapSmall: number;
    mainCategoryHorizontalGapLarge: number;
    mainCategoryHorizontalGapMedium: number;
    mainCategoryHorizontalGapSmall: number;
    mainCategoryAlignment: ALIGNMENT;
    isMainCategoryEnabled: boolean;
    showMainCategoryAboveText: boolean;
}

export type VerticalCardThemeProps = MainCategoryThemeProps & {
    cardBackgroundColor: string;
    cardTitleColorTypeNormal: string;
    cardTitleColorTypeHover: string;
    cardTitleColorTypeActive: string;
    borderRadius: number;
    boxShadowTypeNormal: string;
    boxShadowTypeActive: string;
    boxShadowTypeHover: string;
    borderWidthTypeNormal: number;
    borderWidthTypeHover: number;
    borderWidthTypeActive: number;
    borderColorTypeNormal: string;
    borderColorTypeHover: string;
    borderColorTypeActive: string;
    aspectRatioLarge: RATIO;
    aspectRatioMedium: RATIO;
    aspectRatioSmall: RATIO;
    titleFontSizeLarge: TypographySize;
    titleFontSizeMedium: TypographySize;
    titleFontSizeSmall: TypographySize;
    pipeColor: string;
    showAuthorInLargeScreen: boolean;
    showAuthorInMediumScreen: boolean;
    showAuthorInSmallScreen: boolean;
    showDateInSmallScreen: boolean;
    showDateInMediumScreen: boolean;
    showDateInLargeScreen: boolean;
    metadataFontSizeLarge: TypographySize;
    metadataFontSizeMedium: TypographySize;
    metadataFontSizeSmall: TypographySize;
    showMetadataOnBottom: boolean;
    metadataMarginTop: number;
    authorColor: string;
    dateColor: string;
    wrapperGapLarge: number;
    wrapperGapMedium: number;
    wrapperGapSmall: number;
    verticalGapLarge: number;
    verticalGapMedium: number;
    verticalGapSmall: number;
    horizontalGapLarge: number;
    horizontalGapMedium: number;
    horizontalGapSmall: number;
    titleSemanticTag: SEMANTIC_TAG;
    metadataSemanticTag: SEMANTIC_TAG;
    fontSizesStyles: TranslatedFontSize;
    numberOfLinesForTitle: number;
    numberOfLinesForAuthors: number;
    showDescriptionInLargeScreen: boolean;
    showDescriptionInMediumScreen: boolean;
    showDescriptionInSmallScreen: boolean;
    numberOfLinesForDescription: number;
    descriptionColor: string;
    descriptionFontSizeInLargeScreen: TypographySize;
    descriptionFontSizeInMediumScreen: TypographySize;
    descriptionFontSizeInSmallScreen: TypographySize;
    descriptionSemanticTag: SEMANTIC_TAG;
    titleAlignment: ALIGNMENT;
    metadataAlignment: ALIGNMENT;
    descriptionAlignment: ALIGNMENT;
    isUpdatedAtTimestamp: boolean;
    isClickableLabels: boolean;
    descriptionHorizontalGap: number;
    authorColorHover: string;
    additionalBottomTextWrapperStyle: Style;
};
