import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { TypographySize } from 'mm-theme-configuration/dist/consts';
import { useTheme } from '../../../theming/useTheme';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { P, semanticCSS } from '../../../typography/semanticTags';
import { baseUnit } from '../../../theming/baseUnitDefinition';
import { getLayoutComponentPadding, LayoutComponentBaseProps } from '../../components/pageLayouts/layout.utils';
import { MoreButton } from './MoreButton';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';

export const componentConfigurationKey = 'sectionHeader';

export interface Link {
  href: string;
  text: string;
}

export interface SvgLink {
  src: string;
  alt: string;
  href: string;
}

export interface StrikeThrowHorizontalLineStyle {
  strikeThrowHorizontalLineColor: string;
  showStrikeThrowHorizontalLine: boolean;
}

export interface MiddleHorizontalLineStyle {
  middleHorizontalLineColor: string;
  showMiddleHorizontalLine: boolean;
}

export interface SectionHeaderComponentThemeProps {
  titleColor: string;
  titleBackgroundColor: string;
  borderTopColor: string;
  borderTopWidth: number;
  borderBottomColor: string;
  borderBottomWidth: number;
  squareColor: string;
  showSquare: boolean;
  showBorderTop: boolean;
  showBorderBottom: boolean;
  largeVerticalGap: number;
  mediumVerticalGap: number;
  smallVerticalGap: number;
  largeHorizontalGap: number;
  mediumHorizontalGap: number;
  smallHorizontalGap: number;
  largeWrapperGap: number;
  mediumWrapperGap: number;
  smallWrapperGap: number;
  showStrikeThrowHorizontalLine: boolean;
  strikeThrowHorizontalLineColor: string;
  showMiddleHorizontalLine: boolean;
  middleHorizontalLineColor: string;
  descriptionColor: string;
  descriptionFontSizeLarge: TypographySize;
  descriptionFontSizeMedium: TypographySize;
  descriptionFontSizeSmall: TypographySize;
  largeMarginBottomFactor: number;
  mediumMarginBottomFactor: number;
  smallMarginBottomFactor: number;
}

export interface SectionHeaderDataProps {
  description?: string;
  moreButtonText: Link | null;
  moreButtonSvg: SvgLink | null;
  backgroundComponent?: React.FunctionComponent;
}

export type SectionHeaderComponentProps = SectionHeaderDataProps & LayoutComponentBaseProps;
const createStyles = ({
  titleColor,
  titleBackgroundColor,
  borderTopColor,
  squareColor,
  showBorderTop,
  showBorderBottom,
  borderTopWidth,
  showStrikeThrowHorizontalLine,
  strikeThrowHorizontalLineColor,
  middleHorizontalLineColor,
  largeVerticalGap,
  mediumVerticalGap,
  smallVerticalGap,
  largeHorizontalGap,
  mediumHorizontalGap,
  smallHorizontalGap,
  largeWrapperGap,
  mediumWrapperGap,
  smallWrapperGap,
  descriptionColor,
  borderBottomColor,
  borderBottomWidth,
  largeMarginBottomFactor,
  mediumMarginBottomFactor,
  smallMarginBottomFactor,
}: SectionHeaderComponentThemeProps) => {
  return StyleSheet.create({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      flexWrap: 'wrap',
      flexDirection: 'column',
      [MEDIA_BREAKPOINTS.large]: {
        marginBottom: `${baseUnit * largeMarginBottomFactor}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        marginBottom: `${baseUnit * mediumMarginBottomFactor}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        marginBottom: `${baseUnit * smallMarginBottomFactor}px`,
      },
    },
    titleItem: {
      flex: '1 1 auto',
      color: titleColor,
      display: 'inline-flex',
      alignItems: 'center',
      width: '100%',
      position: 'relative',
      zIndex: 0,
      borderTop: showBorderTop ? `${baseUnit * borderTopWidth}px solid ${borderTopColor}` : 'unset',
      borderBottom: showBorderBottom ? `${baseUnit * borderBottomWidth}px solid ${borderBottomColor}` : 'unset',
      backgroundColor: titleBackgroundColor,
      [MEDIA_BREAKPOINTS.large]: {
        padding: `${largeHorizontalGap * baseUnit}px ${largeWrapperGap * baseUnit}px ${largeHorizontalGap * baseUnit}px ${largeWrapperGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        padding: `${mediumHorizontalGap * baseUnit}px ${mediumWrapperGap * baseUnit}px ${mediumHorizontalGap * baseUnit}px ${mediumWrapperGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        padding: `${smallHorizontalGap * baseUnit}px ${smallWrapperGap * baseUnit}px ${smallHorizontalGap * baseUnit}px ${smallWrapperGap * baseUnit}px`,
      },
      ':before': {
        ...showStrikeThrowHorizontalLine ? {
          content: '""',
          right: 0,
          left: 0,
          position: 'absolute',
          zIndex: -1,
          width: '100%',
          [MEDIA_BREAKPOINTS.large]: {
            borderTop: `${0.75 * baseUnit}px solid ${strikeThrowHorizontalLineColor}`,
          },
          [MEDIA_BREAKPOINTS.medium]: {
            borderTop: `${0.5 * baseUnit}px solid ${strikeThrowHorizontalLineColor}`,
          },
          [MEDIA_BREAKPOINTS.small]: {
            borderTop: `${0.5 * baseUnit}px solid ${strikeThrowHorizontalLineColor}`,
          },
        } : null,
      },
    },
    square: {
      backgroundColor: squareColor,
      marginRight: `${baseUnit * 0.5}px`,
      [MEDIA_BREAKPOINTS.large]: {
        width: '28px',
        height: '28px',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        width: '24px',
        height: '24px',
      },
      [MEDIA_BREAKPOINTS.small]: {
        width: '20px',
        height: '20px',
      },
    },
    middleHorizontalLineContainer: {
      flexGrow: 1,
      ':before': {
        content: '""',
        display: 'block',
        borderTop: `2px solid ${middleHorizontalLineColor}`,
        flex: '2 0 0',
        [MEDIA_BREAKPOINTS.large]: {
          marginRight: `${largeVerticalGap * baseUnit}px`,
          marginLeft: `${largeVerticalGap * baseUnit}px`,
        },
        [MEDIA_BREAKPOINTS.medium]: {
          marginRight: `${mediumVerticalGap * baseUnit}px`,
          marginLeft: `${mediumVerticalGap * baseUnit}px`,
        },
        [MEDIA_BREAKPOINTS.small]: {
          marginRight: `${smallVerticalGap * baseUnit}px`,
          marginLeft: `${smallVerticalGap * baseUnit}px`,
        },
      },
    },
    description: {
      color: descriptionColor,
    },
  });
};

export const SectionHeader: React.FunctionComponent<SectionHeaderComponentProps> = props => {
  const themeProps = useTheme<SectionHeaderComponentThemeProps>(componentConfigurationKey);
  const { children, paddingFactors, description, moreButtonText, moreButtonSvg } = props;
  const {
    showSquare,
    showMiddleHorizontalLine,
    descriptionFontSizeLarge,
    descriptionFontSizeMedium,
    descriptionFontSizeSmall } = themeProps;
  const style = createStyles(themeProps);
  const paddingStyle = getLayoutComponentPadding(paddingFactors);
  return (
    <ErrorBoundary>
      <div className={css(paddingStyle, style.container)}>
        <div className={css(style.titleItem)}>
          {showSquare ? <div className={css(style.square)} /> : null }
          {children}
          {showMiddleHorizontalLine ? (<div className={css(style.middleHorizontalLineContainer)} />) : null}
          <MoreButton moreButtonText={moreButtonText} moreButtonSvg={moreButtonSvg} />
        </div>
        {description ? <P styles={semanticCSS(style.description)} fontSizeSmall={descriptionFontSizeSmall} fontSizeMedium={descriptionFontSizeMedium} fontSizeLarge={descriptionFontSizeLarge}>{description}</P> : null}
      </div>
    </ErrorBoundary>
  );
};
