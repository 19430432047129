import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { ALIGNMENT, ALIGNMENT_OPTIONS } from 'mm-theme-configuration/dist/consts';
import { TypographySize } from 'mm-theme-configuration/src/consts';
import { Span, semanticCSS } from '../../typography/semanticTags';
import { useTheme } from '../../theming/useTheme';
import { BaseUnit } from '../../theming/baseUnitDefinition';
import { CardComponentSponsoredProps } from './cards/cards.utils';

export const componentConfigThemeKey = 'sponsoredTextHorizontal';

export interface SponsoredTextHorizontalThemeProps {
  sponsoredTextColor: string;
  brandNameColor: string;
  alignment: ALIGNMENT;
  baseUnit: BaseUnit;
  sponsorFontSizeLarge: TypographySize;
  sponsorFontSizeMedium: TypographySize;
  sponsorFontSizeSmall: TypographySize;
}

const getStyles = ({ baseUnit, sponsoredTextColor, brandNameColor, alignment }: SponsoredTextHorizontalThemeProps) => StyleSheet.create({
  wrapper: {
    textAlign: alignment === ALIGNMENT_OPTIONS.CENTER ? 'center' : 'left',
    marginTop: `${baseUnit / 2}px`,
    height: 'fit-content',
  },
  sponsored: {
    color: sponsoredTextColor,
  },
  brandName: {
    color: brandNameColor,
  },
});

export const SponsoredTextHorizontal: React.FunctionComponent<CardComponentSponsoredProps> = props => {
  const { sponsoredText, brandName } = props;
  const themeProps = useTheme<SponsoredTextHorizontalThemeProps>(componentConfigThemeKey);
  const styles = getStyles(themeProps);

  const { sponsorFontSizeLarge, sponsorFontSizeMedium, sponsorFontSizeSmall } = themeProps;

  return (
    <div className={css(styles.wrapper)}>
      <Span fontSizeLarge={sponsorFontSizeLarge} fontSizeMedium={sponsorFontSizeMedium} fontSizeSmall={sponsorFontSizeSmall} styles={semanticCSS(styles.sponsored)}>{`${sponsoredText} `}</Span>
      <Span fontSizeLarge={sponsorFontSizeLarge} fontSizeMedium={sponsorFontSizeMedium} fontSizeSmall={sponsorFontSizeSmall} styles={semanticCSS(styles.brandName)}>{brandName}</Span>
    </div>
  );
};
