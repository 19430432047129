import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { useTheme } from '../../../theming/useTheme';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { createImageWidths } from '../../partials/image/image.utils';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { getLayoutComponentPadding, LayoutComponentBaseProps } from '../pageLayouts/layout.utils';
import { ThreeVC } from './ThreeVC';
import { OnTopCard, OnTopCardComponentThemeProps, componentConfigThemeKey as otcComponentConfigThemeKey } from '../../partials/cards/onTopCard/OnTopCard';

export const componentConfigThemeKey = 'oneOTCThreeVC';

export interface OneOTCThreeVCThemeProps {
  largeHorizontalGap: number;
  mediumHorizontalGap: number;
  smallHorizontalGap: number;
  baseUnit: BaseUnit;
}

interface OneOTCThreeVCDataProps extends LayoutComponentBaseProps{
  cards: Array<CardComponentDataProps> | null;
}

const createStyle = (themeProps: OneOTCThreeVCThemeProps) => {
  const { largeHorizontalGap, mediumHorizontalGap, smallHorizontalGap, baseUnit } = themeProps;
  return StyleSheet.create({
    wrapper: {
      display: 'grid',
      [MEDIA_BREAKPOINTS.large]: {
        gridRowGap: `${largeHorizontalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        gridRowGap: `${mediumHorizontalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        gridRowGap: `${smallHorizontalGap * baseUnit}px`,
      },
    },
  });
};

const imageWidthsHC = createImageWidths(360, 720, 720);

export const OneOTCThreeVC: React.FunctionComponent<OneOTCThreeVCDataProps> = props => {
  const { cards, paddingFactors } = props;
  const paddingStyle = getLayoutComponentPadding(paddingFactors);
  const themeProps = useTheme<OneOTCThreeVCThemeProps>(componentConfigThemeKey);
  const style = createStyle(themeProps);
  const [firstCard, ...rest] = cards || [];
  const themeContextOTC = useThemeOverride<OnTopCardComponentThemeProps>({
    titleFontSizeLarge: 'gigantic',
    titleFontSizeMedium: 'bigger',
    titleFontSizeSmall: 'bigger',
    metadataFontSizeLarge: 'big',
    metadataFontSizeMedium: 'big',
    metadataFontSizeSmall: 'big',
    verticalGapLarge: 1,
    verticalGapMedium: 1,
    verticalGapSmall: 1,
    horizontalGapLarge: 2,
    horizontalGapMedium: 0.75,
    horizontalGapSmall: 0.75,
    aspectRatioSmall: {
      x: 4,
      y: 3,
    },
    titleAlignment: 'CENTER',
    metadataAlignment: 'CENTER',
  }, otcComponentConfigThemeKey);
  return (
    <ErrorBoundary>
      {cards && cards.length > 0
      && (
        <div className={css(paddingStyle, style.wrapper)}>
          <ThemeContext.Provider value={themeContextOTC}>
            <OnTopCard {...firstCard} imageWidths={imageWidthsHC} />
          </ThemeContext.Provider>
          <ThreeVC cards={rest} paddingFactors={{ large: 0, medium: 0, small: 0 }} />
        </div>
      ) }
    </ErrorBoundary>
  );
};
