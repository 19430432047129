import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { useTheme } from '../../../theming/useTheme';
import { OptionalAdditionalStyle } from '../../components.utils';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import {
  createImageHeightCalculationMethods,
  ImageAspectRatios,
  ImageHeightCalculationMethods,
  ImageHeightCalculationMethodsTypes,
} from './image.utils';
import { componentConfigurationKey, ImageThemeProps } from './Image.theme';

interface ImageWrapperDataProps {
  imageAspectRatios: ImageAspectRatios;
  dataId?: string;
  imageHeightCalculationMethods?: ImageHeightCalculationMethods;
  transparent?: boolean;
}

const defaultImageHeightCalculationMethods = createImageHeightCalculationMethods('ASPECT_RATIO', 'ASPECT_RATIO', 'ASPECT_RATIO');

export const getBaseStyle = (imageAspectRatios: ImageAspectRatios, backgroundColor: string, imageHeightCalculationMethods: ImageHeightCalculationMethods, transparent: boolean | undefined, isOriginalSize = false) => {
  const getBaseStyleByType = (type: ImageHeightCalculationMethodsTypes) => {
    switch (type) {
      case '100%_HEIGHT':
        return {
          paddingTop: 'unset',
          height: '100%',
        };
      case 'ASPECT_RATIO':
      default:
        if (!isOriginalSize) {
          return {
            [MEDIA_BREAKPOINTS.large]: {
              paddingTop: `calc(100% / (${imageAspectRatios.large.x / imageAspectRatios.large.y}))`,
            },
            [MEDIA_BREAKPOINTS.medium]: {
              paddingTop: `calc(100% / (${imageAspectRatios.medium.x / imageAspectRatios.medium.y}))`,
            },
            [MEDIA_BREAKPOINTS.small]: {
              paddingTop: `calc(100% / (${imageAspectRatios.small.x / imageAspectRatios.small.y}))`,
            },
          };
        }
        return {};
    }
  };
  return StyleSheet.create({
    base: {
      backgroundColor: transparent ? 'transparent' : backgroundColor,
      position: 'relative',
      overflow: 'hidden',
      margin: 0,
      boxSizing: 'border-box',
      flex: '1 1 100%',
      minWidth: '0%',
      [MEDIA_BREAKPOINTS.large]: {
        ...getBaseStyleByType(imageHeightCalculationMethods.large),
      },
      [MEDIA_BREAKPOINTS.medium]: {
        ...getBaseStyleByType(imageHeightCalculationMethods.medium),
      },
      [MEDIA_BREAKPOINTS.small]: {
        ...getBaseStyleByType(imageHeightCalculationMethods.small),
      },
    },
  });
};

type ImageWrapperProps = ImageWrapperDataProps & OptionalAdditionalStyle;

export const ImageWrapper: React.FunctionComponent<ImageWrapperProps> = props => {
  const { additionalStyle, dataId, children, imageHeightCalculationMethods = defaultImageHeightCalculationMethods, imageAspectRatios, transparent } = props;
  const { backgroundColor } = useTheme<ImageThemeProps>(componentConfigurationKey);
  const isOriginalSize = imageAspectRatios.small.original && imageAspectRatios.small.original === true;
  const transparentValue = transparent || isOriginalSize;
  const baseStyle = getBaseStyle(imageAspectRatios, backgroundColor, imageHeightCalculationMethods, transparentValue, isOriginalSize);
  return (
    <ErrorBoundary>
      {
        additionalStyle
          ? (
            <figure data-id={dataId} className={css(additionalStyle.style)} data-mm-type="image">
              <div className={css(baseStyle.base)}>{children}</div>
            </figure>
          )
          : (
            <figure data-id={dataId} className={css(baseStyle.base)} data-mm-type="image">
              {children}
            </figure>
          )
      }
    </ErrorBoundary>
  );
};
