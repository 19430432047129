import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { LinkButtonDataProps } from '../utils';
import { getStyleBySize } from '../../../../typography/semanticTags/semanticTags.utils';
import { useTheme } from '../../../../theming/useTheme';
import { componentConfigurationKey, FooterThemeProps } from './Footer.theme';

export interface LinkButtonStyleProps {
    color: string;
    hoverColor: string;
}

type LinkButtonProps = LinkButtonDataProps & LinkButtonStyleProps;

const createStyle = (color: string, hoverColor: string) => StyleSheet.create({
  button: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    textAlign: 'start',
    color,
    ':hover': {
      color: hoverColor,
    },
    ':active': {
      color,
    },
    ':visited': {
      color,
    },
    ':focus': {
      outline: 'none',
    },
  },
});

export const LinkButton: React.FunctionComponent<LinkButtonProps> = props => {
  const { text, onClick, color, hoverColor, cssClassName, attributes } = props;
  const { buttonFontSizeLarge, buttonFontSizeMedium, buttonFontSizeSmall, fontSizesStyles } = useTheme<FooterThemeProps>(componentConfigurationKey);
  /* eslint-disable-next-line no-new-func */
  const getFunction = (onClickValue: string) => new Function(onClickValue);
  const style = createStyle(color, hoverColor);
  const fontStyles = getStyleBySize(fontSizesStyles, { fontSizeLarge: buttonFontSizeLarge, fontSizeMedium: buttonFontSizeMedium, fontSizeSmall: buttonFontSizeSmall });
  const buttonAttributes = {
    ...onClick ? { onClick: getFunction(onClick) } : {},
    ...attributes || {},
    ...cssClassName ? { className: `${css(style.button, fontStyles)} ${cssClassName}` } : { className: css(style.button, fontStyles) },
  };

  return (
    // @ts-ignore
    <button type="button" {...buttonAttributes}>
      {text}
    </button>
  );
};
