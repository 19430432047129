import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { TypographySize } from 'mm-theme-configuration/dist/consts';
import { getLayoutComponentPadding, LayoutComponentBaseProps } from '../pageLayouts/layout.utils';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { useTheme } from '../../../theming/useTheme';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import {
  componentConfigThemeKey as onTopCardConfigThemeKey,
  OnTopCard,
  OnTopCardComponentThemeProps,
} from '../../partials/cards/onTopCard/OnTopCard';
import {
  componentConfigThemeKey as headlineCardConfigThemeKey,
  HeadlineCard,
  HeadlineCardThemeProps,
} from '../../partials/cards/headlineCard/HeadlineCard';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { createImageHeightCalculationMethods, createImageWidths } from '../../partials/image/image.utils';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { baseUnit } from '../../../theming/baseUnitDefinition';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';

export const componentConfigThemeKey = 'oneOTCThreeHLC';

export interface OneOTCThreeHLCProps extends LayoutComponentBaseProps {
  cards: CardComponentDataProps[] | null;
}

export interface OneOTCThreeHLCThemeProps {
  largeVerticalGap: number;
  largeHorizontalGap: number;
  mediumVerticalGap: number;
  mediumHorizontalGap: number;
  smallHorizontalGap: number;
  showMainCategoryInLargeScreen: boolean;
  showMainCategoryInMediumScreen: boolean;
  showMainCategoryInSmallScreen: boolean;
  mainCategoryFontSizeLarge: TypographySize;
  mainCategoryFontSizeMedium: TypographySize;
  mainCategoryFontSizeSmall: TypographySize;
  titleFontSizeLargeForHeadlineCard: TypographySize;
  titleFontSizeMediumForHeadlineCard: TypographySize;
  titleFontSizeSmallForHeadlineCard: TypographySize;
  showDivider: boolean;
  dividerColor: string;
  dividerWidth: number;
}

const createStyle = (themeProps: OneOTCThreeHLCThemeProps) => {
  const { largeHorizontalGap, largeVerticalGap, mediumHorizontalGap, mediumVerticalGap, smallHorizontalGap, showDivider, dividerColor, dividerWidth } = themeProps;
  return StyleSheet.create({
    wrapper: {
      display: 'grid',
      [MEDIA_BREAKPOINTS.large]: {
        gridTemplateColumns: '2fr 1fr',
        gridColumnGap: `${largeVerticalGap * baseUnit}px`,
        gridRowGap: `${largeHorizontalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        gridTemplateColumns: '1fr',
        gridColumnGap: `${mediumVerticalGap * baseUnit}px`,
        gridRowGap: `${mediumHorizontalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        gridTemplateColumns: '1fr',
        gridRowGap: `${smallHorizontalGap * baseUnit}px`,
      },
    },
    minorCardsWrapper: {
      display: 'grid',
      gridTemplateRows: showDivider ? 'repeat(3, 1fr auto)' : 'repeat(3, 1fr)',
      [MEDIA_BREAKPOINTS.large]: {
        gridTemplateColumns: '1fr',
        gridColumnGap: `${largeVerticalGap * baseUnit}px`,
        gridRowGap: `${largeHorizontalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        gridTemplateColumns: '1fr',
        gridColumnGap: `${mediumVerticalGap * baseUnit}px`,
        gridRowGap: `${mediumHorizontalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        gridTemplateColumns: '1fr',
        gridRowGap: `${smallHorizontalGap * baseUnit}px`,
      },
    },
    dividerBetweenCards: {
      backgroundColor: dividerColor,
      display: 'block',
      width: '100%',
      height: `${dividerWidth}px`,
    },
  });
};

const imageWidthsForMainCard = createImageWidths(360, 720, 720);
const onTopCardImageHeightCalculationMethods = createImageHeightCalculationMethods('ASPECT_RATIO', 'ASPECT_RATIO', '100%_HEIGHT');

export const OneOTCThreeHLC: React.FunctionComponent<OneOTCThreeHLCProps> = props => {
  const { cards, paddingFactors = { large: 0.5, medium: 0.5, small: 0 } } = props;
  const paddingStyle = getLayoutComponentPadding(paddingFactors);
  const themeProps = useTheme<OneOTCThreeHLCThemeProps>(componentConfigThemeKey);
  const { showMainCategoryInLargeScreen, showMainCategoryInMediumScreen, showMainCategoryInSmallScreen, titleFontSizeLargeForHeadlineCard, titleFontSizeMediumForHeadlineCard, titleFontSizeSmallForHeadlineCard, mainCategoryFontSizeLarge, mainCategoryFontSizeMedium, mainCategoryFontSizeSmall, showDivider } = themeProps;
  const styles = createStyle(themeProps);
  const themeContextFirstCard = useThemeOverride<OnTopCardComponentThemeProps>({
    titleFontSizeLarge: 'huge',
    titleFontSizeMedium: 'huge',
    titleFontSizeSmall: 'large',
    metadataFontSizeSmall: 'bigger',
    mainCategoryFontSizeLarge,
    mainCategoryFontSizeMedium,
    mainCategoryFontSizeSmall,
    horizontalGapSmall: 0.5,
    horizontalGapMedium: 0.75,
    horizontalGapLarge: 0.75,
    verticalGapSmall: 0.5,
    verticalGapMedium: 0.75,
    verticalGapLarge: 0.75,
    aspectRatioSmall: { x: 16, y: 9 },
    mainCategoryHorizontalGapLarge: 0.25,
    mainCategoryHorizontalGapMedium: 0.25,
    showMainCategoryInLargeScreen,
    showMainCategoryInMediumScreen,
    showMainCategoryInSmallScreen,
  }, onTopCardConfigThemeKey);
  const themeContextMinorCard = useThemeOverride<HeadlineCardThemeProps>({
    titleFontSizeLarge: titleFontSizeLargeForHeadlineCard,
    titleFontSizeMedium: titleFontSizeMediumForHeadlineCard,
    titleFontSizeSmall: titleFontSizeSmallForHeadlineCard,
    metadataFontSizeSmall: 'bigger',
    wrapperGapSmall: 0.5,
    wrapperGapMedium: 0.75,
    wrapperGapLarge: 0.75,
    horizontalGapSmall: 0.25,
    horizontalGapMedium: 0,
    horizontalGapLarge: 0.5,
    showMainCategoryInLargeScreen,
    showMainCategoryInMediumScreen,
    showMainCategoryInSmallScreen,
  }, headlineCardConfigThemeKey);


  return (
    <ErrorBoundary>
      {cards && cards.length > 0 && (
        <div className={css(paddingStyle, styles.wrapper)}>
          <ThemeContext.Provider value={themeContextFirstCard}>
            <OnTopCard
              {...cards[0]}
              imageWidths={imageWidthsForMainCard}
              imageHeightCalculationMethods={onTopCardImageHeightCalculationMethods}
            />
          </ThemeContext.Provider>
          <ThemeContext.Provider value={themeContextMinorCard}>
            <div className={css(styles.minorCardsWrapper)}>
              <HeadlineCard {...cards[1]} />
              {showDivider ? <div className={css(styles.dividerBetweenCards)} /> : null}
              <HeadlineCard {...cards[2]} />
              {showDivider ? <div className={css(styles.dividerBetweenCards)} /> : null}
              <HeadlineCard {...cards[3]} />
              {showDivider ? <div className={css(styles.dividerBetweenCards)} /> : null}
            </div>
          </ThemeContext.Provider>
        </div>
      )}
    </ErrorBoundary>
  );
};
