import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { AdditionalStyleSheet } from '../../../components.utils';
import { SocialButton } from '../../ShareMenu/ShareMenu';
import { SquareShareButton } from './SquareShareButton';
import { Link } from '../../../partials/link/Link';
import { useTheme } from '../../../../theming/useTheme';
import { BaseUnit } from '../../../../theming/baseUnitDefinition';
import { CopyLinkButton } from './CopyLinkButton';
import { MEDIA_BREAKPOINTS } from '../../../../mediaQueries.const';

interface PaddingBottomForShareIconsWrapper {
  large: number;
  medium: number;
  small: number;
}

interface ArticleOnTioTitleShareButtonProps {
  shareConfig: SocialButton[];
  shareIconColor: string;
  shareIconStyle: AdditionalStyleSheet;
  canonical: string;
  paddingBottomForShareIconsWrapper?: PaddingBottomForShareIconsWrapper;
}

const getAnimationDelayClass = (index: number) => {
  return StyleSheet.create({
    animationModifier: {
      animationDelay: `${index * 50}ms`,
    },
  });
};

const socialButtonAnimation = {
  '0%': {
    opacity: 0,
  },
  '100%': {
    opacity: 1,
  },
};

const getStyles = (baseUnit: BaseUnit, paddingBottomForShareIconsWrapper: PaddingBottomForShareIconsWrapper) => {
  return StyleSheet.create({
    wrapper: {
      display: 'flex',
      flexDirection: 'column-reverse',
      position: 'absolute',
      alignItems: 'center',
      zIndex: 1000,
      [MEDIA_BREAKPOINTS.large]: {
        bottom: `${baseUnit * 3.5}px`,
        right: `${baseUnit}px`,
        width: '30px',
        paddingBottom: `${paddingBottomForShareIconsWrapper.large}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        bottom: `${baseUnit * 3.5}px`,
        right: `${baseUnit / 2}px`,
        width: '30px',
        paddingBottom: `${paddingBottomForShareIconsWrapper.medium}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        bottom: `${baseUnit * 2}px`,
        right: '5px',
        width: '20px',
        paddingBottom: `${paddingBottomForShareIconsWrapper.small}px`,
      },
    },
    socialButton: {
      opacity: 0,
      animationName: [socialButtonAnimation],
      animationDuration: '500ms',
      animationFillMode: 'forwards',
      cursor: 'pointer',
      textAlign: 'center',
      [MEDIA_BREAKPOINTS.large]: {
        height: `${baseUnit * 1.5}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        height: `${baseUnit * 1.5}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        height: `${baseUnit}px`,
      },
      ':not(:first-child):not(:last-child)': {
        [MEDIA_BREAKPOINTS.large]: {
          margin: `${baseUnit * 0.75}px 0`,
        },
        [MEDIA_BREAKPOINTS.medium]: {
          margin: `${baseUnit * 0.75}px 0`,
        },
        [MEDIA_BREAKPOINTS.small]: {
          margin: `${baseUnit * 0.5}px 0`,
        },
      },
      ':first-child': {
        [MEDIA_BREAKPOINTS.large]: {
          margin: `${baseUnit * 0.75}px 0`,
        },
        [MEDIA_BREAKPOINTS.medium]: {
          margin: `${baseUnit * 0.75}px 0`,
        },
        [MEDIA_BREAKPOINTS.small]: {
          margin: `${baseUnit * 0.5}px 0`,
        },
      },
      ':last-child': {
        [MEDIA_BREAKPOINTS.large]: {
          margin: `${baseUnit * 0.75}px 0`,
        },
        [MEDIA_BREAKPOINTS.medium]: {
          margin: `${baseUnit * 0.75}px 0`,
        },
        [MEDIA_BREAKPOINTS.small]: {
          margin: `${baseUnit * 0.5}px 0`,
        },
      },
    },
  });
};

const getShareButtons = (buttons: SocialButton[], styles: { [key: string]: any }) => buttons.map((button, index) => {
  const { shareURL, src, type } = button;
  const animationDurationClass = getAnimationDelayClass(index);

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
      className={css(styles.socialButton, animationDurationClass.animationModifier)}
      key={index}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => { window.open(shareURL, '_blank', 'noopener'); return false; }}
    >
      <img src={src} alt={type} className={css(styles.socialIcon)} height="100%" />
    </Link>
  );
});

const useToggleSocialMenu = (): [() => void, boolean] => {
  const [isOpen, setIsOpen] = React.useState(false);
  const onToggle = () => { setIsOpen(prev => !prev); };

  return [onToggle, isOpen];
};

export const ArticleOnTopTitleShareButton: React.FunctionComponent<ArticleOnTioTitleShareButtonProps> = ({ shareConfig, shareIconColor, shareIconStyle, canonical, paddingBottomForShareIconsWrapper = { large: 0, medium: 0, small: 0 } }) => {
  const [onToggle, isOpen] = useToggleSocialMenu();
  const { baseUnit } = useTheme();
  const styles = getStyles(baseUnit, paddingBottomForShareIconsWrapper);
  const copyLinkAnimationClass = getAnimationDelayClass(shareConfig.length);

  return (
    <React.Fragment>
      {isOpen && (
        <div className={css(styles.wrapper)}>
          {getShareButtons(shareConfig, styles)}
          <CopyLinkButton canonical={canonical} buttonClass={[styles.socialButton, copyLinkAnimationClass.animationModifier]} />
        </div>
      )}
      <SquareShareButton shareConfig={shareConfig} shareIconColor={shareIconColor} additionalStyle={shareIconStyle} onClick={onToggle} isOpen={isOpen} />
    </React.Fragment>
  );
};
