import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { baseUnit } from '../../../theming/baseUnitDefinition';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { HorizontalCard } from '../../partials/cards/horizontalCard/HorizontalCard';
import {
  componentConfigThemeKey as horizontalCardConfigThemeKey,
  HorizontalCardThemeProps,
} from '../../partials/cards/horizontalCard/horizontalCard.theme';
import { getLayoutComponentPadding, LayoutComponentBaseProps } from '../pageLayouts/layout.utils';
import { VerticalCard } from '../../partials/cards/verticalCard/VerticalCard';
import { getArticlesInChunks } from './articleLists.utils';
import { createImageWidths } from '../../partials/image/image.utils';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { useTheme } from '../../../theming/useTheme';
import { VerticalCardThemeProps, componentConfigThemeKey as verticalCardConfigThemeKey } from '../../partials/cards/verticalCard/VerticalCard.theme';

export const componentConfigThemeKey = 'fourHCFourVC';
const maximumNumberOfCards = 4;

interface FourHCFourVCDataProps extends LayoutComponentBaseProps {
  cards: Array<CardComponentDataProps> | null;
}

export interface FourHCFourVCThemeProps {
  largeVerticalGap: number;
  largeHorizontalGap: number;
  mediumVerticalGap: number;
  mediumHorizontalGap: number;
  smallHorizontalGap: number;
}


const createStyle = (themeProps: FourHCFourVCThemeProps) => {
  const { largeHorizontalGap, largeVerticalGap, mediumHorizontalGap, mediumVerticalGap, smallHorizontalGap } = themeProps;

  return StyleSheet.create({
    wrapperHC: {
      display: 'grid',
      marginTop: `${baseUnit}px`,
      [MEDIA_BREAKPOINTS.large]: {
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto',
        gridRowGap: `${largeHorizontalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto',
        gridRowGap: `${mediumHorizontalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto',
        gridRowGap: `${smallHorizontalGap * baseUnit}px`,
      },
    },
    wrapperVC: {
      display: 'grid',
      marginTop: `${baseUnit}px`,
      [MEDIA_BREAKPOINTS.large]: {
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: 'auto',
        gridRowGap: `${largeHorizontalGap * baseUnit}px`,
        gridColumnGap: `${largeVerticalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: 'auto',
        gridRowGap: `${mediumHorizontalGap * baseUnit}px`,
        gridColumnGap: `${mediumVerticalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto',
        gridRowGap: `${smallHorizontalGap * baseUnit}px`,
      },
    },
  });
};

const imageWidthsHC = createImageWidths(180, 360, 540);
const imageWidthsVC = createImageWidths(360, 360, 540);

export const FourHCFourVC: React.FunctionComponent<FourHCFourVCDataProps> = props => {
  const { cards, paddingFactors } = props;
  const themeProps = useTheme<FourHCFourVCThemeProps>(componentConfigThemeKey);
  const style = createStyle(themeProps);
  const paddingStyle = getLayoutComponentPadding(paddingFactors);
  const themeContextHC = useThemeOverride<HorizontalCardThemeProps>({
    titleFontSizeLarge: 'large',
    titleFontSizeMedium: 'bigger',
    titleFontSizeSmall: 'bigger',
    metadataFontSizeLarge: 'big',
    metadataFontSizeMedium: 'big',
    metadataFontSizeSmall: 'big',
    descriptionFontSizeLarge: 'normal',
    descriptionFontSizeMedium: 'normal',
    descriptionFontSizeSmall: 'normal',
    showDescriptionInLargeScreen: true,
    showDescriptionInMediumScreen: true,
    verticalGapLarge: 1,
    verticalGapMedium: 1,
    verticalGapSmall: 0.75,
    horizontalGapLarge: 0.75,
    horizontalGapMedium: 0.75,
    horizontalGapSmall: 0.5,
    cardProportionsLarge: { x: 1, y: 1 },
    cardProportionsMedium: { x: 1, y: 1 },
    cardProportionsSmall: { x: 1, y: 1 },
  }, horizontalCardConfigThemeKey);
  const themeContextVC = useThemeOverride<VerticalCardThemeProps>({
    titleFontSizeLarge: 'large',
    titleFontSizeMedium: 'bigger',
    titleFontSizeSmall: 'bigger',
    metadataFontSizeLarge: 'big',
    metadataFontSizeMedium: 'big',
    metadataFontSizeSmall: 'big',
    verticalGapLarge: 1,
    verticalGapMedium: 1,
    verticalGapSmall: 0.75,
    horizontalGapLarge: 0.75,
    horizontalGapMedium: 0.75,
    horizontalGapSmall: 0.5,
    showDescriptionInLargeScreen: true,
    showDescriptionInMediumScreen: true,
  }, verticalCardConfigThemeKey);

  const createGrids = (cardsProps: CardComponentDataProps[], index: number) => {
    return (index % 2 === 0
      ? (
        <div className={css(style.wrapperHC, paddingStyle)} key={index}>
          <ThemeContext.Provider value={themeContextHC}>
            {cardsProps && cardsProps.map((card => (
              <HorizontalCard key={card.id} {...card} imageWidths={imageWidthsHC} />))).slice(0, maximumNumberOfCards)}
          </ThemeContext.Provider>
        </div>
      )
      : (
        <div className={css(style.wrapperVC, paddingStyle)} key={index}>
          <ThemeContext.Provider value={themeContextVC}>
            {cardsProps && cardsProps.map((card => (
              <VerticalCard key={card.id} {...card} imageWidths={imageWidthsVC} />))).slice(0, maximumNumberOfCards)}
          </ThemeContext.Provider>
        </div>
      )
    );
  };

  return (
    <ErrorBoundary>
      {cards && cards.length > 0 && getArticlesInChunks(cards, 4).map(createGrids)}
    </ErrorBoundary>
  );
};
