import React, { useState } from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { TYPOGRAPHY_SIZE, TypographySize } from 'mm-theme-configuration/dist/consts';
import { generateLinkStyleFromTheme, TextLinkComponent } from '../../../../partials/link/TextLink';
import { MEDIA_BREAKPOINTS } from '../../../../../mediaQueries.const';
import { Span, semanticCSS } from '../../../../../typography/semanticTags';
import { SemanticTagThemeProps, getStyleBySize } from '../../../../../typography/semanticTags/semanticTags.utils';
import { BaseUnit } from '../../../../../theming/baseUnitDefinition';
import { TranslatedFontSize } from '../../../../../theming/fontSizeTranslator';

interface MenuLinkProps extends SemanticTagThemeProps {
  linkColor: string;
  hoverColor: string;
  activeColor: string;
  href: string;
  text: string;
  baseUnit: BaseUnit;
  isActive: boolean;
  configThemeKey?: string;
  fontSizeSmall: TypographySize;
  fontSizeMedium: TypographySize;
  fontSizeLarge: TypographySize;
}

const getWrapperStyle = (baseUnit: BaseUnit) => StyleSheet.create({
  wrapper: {
    [MEDIA_BREAKPOINTS.large]: {
      padding: `${baseUnit / 4}px 0`,
    },
  },
});

const getContentStyle = (configThemeKey: string | undefined, hovering: boolean, isActive: boolean, fontSizesStyles: TranslatedFontSize, fontSizeSmall: TypographySize, fontSizeMedium: TypographySize, fontSizeLarge: TypographySize) => {
  switch (configThemeKey) {
    case 'editionsMenu': {
      return getStyleBySize(fontSizesStyles, { fontSizeSmall: TYPOGRAPHY_SIZE.BIGGER, fontSizeMedium: TYPOGRAPHY_SIZE.BIGGER, fontSizeLarge: TYPOGRAPHY_SIZE.BIGGER });
    }
    case 'moreLinks': {
      const fontSizesForActive = { fontSizeSmall: TYPOGRAPHY_SIZE.BIGGER, fontSizeMedium: TYPOGRAPHY_SIZE.BIGGER, fontSizeLarge: TYPOGRAPHY_SIZE.BIGGER };
      const fontSizesForNotActive = { fontSizeSmall, fontSizeMedium, fontSizeLarge };
      return getStyleBySize(fontSizesStyles, isActive ? fontSizesForActive : fontSizesForNotActive);
    }
    default: return getStyleBySize(fontSizesStyles, { fontSizeSmall: TYPOGRAPHY_SIZE.NORMAL, fontSizeMedium: TYPOGRAPHY_SIZE.NORMAL, fontSizeLarge: TYPOGRAPHY_SIZE.NORMAL });
  }
};

export const DropDownMenuLink: React.FunctionComponent<MenuLinkProps> = props => {
  const { linkColor, hoverColor, activeColor, href, text, baseUnit, isActive, fontSizesStyles, fontSizeSmall, fontSizeMedium, fontSizeLarge, configThemeKey } = props;
  const [hovering, setHovering] = useState(false);
  const wrapperStyle = getWrapperStyle(baseUnit);
  const contentStyle = getContentStyle(configThemeKey, hovering, isActive, fontSizesStyles, fontSizeSmall, fontSizeMedium, fontSizeLarge);
  const linkStyle = generateLinkStyleFromTheme({
    idleColor: isActive ? activeColor : linkColor,
    activeStateColor: activeColor,
    hoverStateColor: isActive ? activeColor : hoverColor,
  }, { width: '100%' });

  return (
    <li onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)} className={css(wrapperStyle.wrapper)}>
      <TextLinkComponent href={href} linkStyle={linkStyle}>
        <Span styles={semanticCSS(contentStyle)}>{text}</Span>
      </TextLinkComponent>
    </li>
  );
};
