import * as React from 'react';

import { StyleSheet } from 'aphrodite/no-important';
import { Ad } from '../ads/Ad';
import { AdditionalStyleSheet, OptionalAdditionalStyle } from '../components.utils';
import { ErrorBoundary } from '../errorHandling/ErrorBoundary';
import { BlockWidths } from '../components/pageLayouts/BlockTypes.utils';
import { createBlockStyle } from './blockMutualStyle';

interface ArticleAdDataProps {
  id: string | null;
  defaultBlocksWidths: BlockWidths;
  dataId: string;
}

type ArticleAdProps = ArticleAdDataProps & OptionalAdditionalStyle;

const getStyle = (defaultBlocksWidths: BlockWidths, additionalStyle: AdditionalStyleSheet) => {
  return StyleSheet.create({
    style: {
      ...createBlockStyle(defaultBlocksWidths),
      ...additionalStyle.style,
    },
  }) as AdditionalStyleSheet;
};
export const ArticleAd: React.FunctionComponent<ArticleAdProps> = props => {
  const { defaultBlocksWidths, additionalStyle } = props;
  const adStyle = getStyle(defaultBlocksWidths, additionalStyle || { style: {} });
  return <ErrorBoundary><Ad {...props} additionalStyle={adStyle} onSuccess={null} /></ErrorBoundary>;
};
