import * as React from 'react';
import { Helmet } from 'react-helmet';

export const AMPConsent: React.FunctionComponent = () => {
  const consentJson = {
    uiConfig: { overlay: false },
    consentRequired: false,
    clientConfig: {
      gdprAppliesGlobally: true,
      config: {
        app: {
          apiKey: '3810dd55-0181-4ddc-952e-59a8c9a36fe4',
        },
      },

    },
    geoOverride: {
      eea: {
        consentRequired: true,
      },
      usca: {
        consentRequired: true,
      },
    },
  };
  const geoJson = {
    ISOCountryGroups: {
      eea: ['preset-eea', 'unknown'],
      usca: ['preset-us-ca'],
    },
  };

  return (
    <React.Fragment>
      <Helmet>
        <script
          async={undefined}
          custom-element="amp-consent"
          src="https://cdn.ampproject.org/v0/amp-consent-0.1.js"
        />
        <meta name="amp-consent-blocking" content="amp-analytics,amp-ad" />
        <script
          async={undefined}
          custom-element="amp-geo"
          src="https://cdn.ampproject.org/v0/amp-geo-0.1.js"
        />
      </Helmet>
      {/*
      // @ts-ignoreTS2339: Property 'amp-consent' does not exist on type 'JSX.IntrinsicElements'. */}
      <amp-consent id="didomi" layout="nodisplay" type="didomi">
        <script type="application/json" dangerouslySetInnerHTML={{ __html: JSON.stringify(consentJson) }} />
        {/*
      //@ts-ignoreTS2339: Property 'amp-consent' does not exist on type 'JSX.IntrinsicElements'. */}
      </amp-consent>

      {/*
      // @ts-ignoreTS2339: Property 'amp-consent' does not exist on type 'JSX.IntrinsicElements'. */}
      <amp-geo layout="nodisplay">
        <script type="application/json" dangerouslySetInnerHTML={{ __html: JSON.stringify(geoJson) }} />
        {/*
      //@ts-ignoreTS2339: Property 'amp-consent' does not exist on type 'JSX.IntrinsicElements'. */}
      </amp-geo>
    </React.Fragment>
  );
};
