import { css, StyleSheet } from 'aphrodite/no-important';
import * as React from 'react';
import { useTheme } from '../../../theming/useTheme';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { H2 } from '../../../typography/semanticTags';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { BottomPageRecommendationsThemeProps, componentConfigurationKey } from './BottomPageRecommendations.theme';

interface RecommendationHeaderDataProps {
  header: string;
}

const getStyle = (color: string, baseUnit: BaseUnit) => StyleSheet.create({
  square: {
    width: `${baseUnit}px`,
    height: `${baseUnit}px`,
    marginRight: `${baseUnit * 0.5}px`,
    backgroundColor: color,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color,
    marginTop: `${baseUnit * 2}px`,
  },
});
export const PostRecommendationsHeader: React.FunctionComponent<RecommendationHeaderDataProps> = ({ header }) => {
  const { titleColor, baseUnit } = useTheme<BottomPageRecommendationsThemeProps>(componentConfigurationKey);
  const style = getStyle(titleColor, baseUnit);
  return (
    <ErrorBoundary>
      <div className={css(style.container)}>
        <div className={css(style.square)} />
        <H2>{header}</H2>
      </div>
    </ErrorBoundary>
  );
};
