import * as React from 'react';
import {
  Font,
  fontFaceTypeName,
  FontTypeStylesheetUrl,
  stylesheetUrlTypeName,
} from 'mm-theme-configuration/dist/interfaces';
import { WebFontsComponentProps } from '../../theming/ThemeProviderWithFonts';
import { FontFace } from './FontFace';
import { Kasda } from '../../Kasda/Kasda';

interface LinkProps {
  fontConfiguration: FontTypeStylesheetUrl;
}

const FontLink: React.FunctionComponent<LinkProps> = ({ fontConfiguration }) => {
  const stylesheetByProvider = fontConfiguration.stylesheetUrl;
  return (
    <>
      <Kasda.Link>
        <link rel="stylesheet" as="style" href={stylesheetByProvider} />
      </Kasda.Link>
    </>
  );
};

const createFontFamilyLinkOrFontFace = (fontConfiguration: Font, index: number) => {
  switch (fontConfiguration.type) {
    case stylesheetUrlTypeName:
      return <FontLink fontConfiguration={fontConfiguration} key={index} />;
    case fontFaceTypeName: {
      return <FontFace fontConfiguration={fontConfiguration} key={index} />;
    }
    default:
      return null;
  }
};

// @ts-ignore TS2339
const haveAllWebFontsLoaded = (fontsConfiguration: Font[]) => fontsConfiguration.every(font => document.fonts.check(`1em ${font.family}`));

export const WebFontsLoadingFlagContext = React.createContext(false);

export const WebFonts: React.FunctionComponent<WebFontsComponentProps> = props => {
  const { fontsConfiguration, children } = props;
  const [webFontsLoadingFlag, setWebFontsLoadingFlag] = React.useState(false);

  const waitForFontsToRender = React.useCallback(() => {
    setTimeout(() => {
      setWebFontsLoadingFlag(true);
    }, 100);
  }, []);

  const waitForFontsToLoad = React.useCallback(() => {
    const interval = setInterval(() => {
      if (haveAllWebFontsLoaded(fontsConfiguration)) {
        clearInterval(interval);
        waitForFontsToRender();
      }
    }, 60);
  }, [waitForFontsToRender, fontsConfiguration]);

  React.useEffect(() => {
    // @ts-ignore TS2339
    const documentFonts = document.fonts;
    if (documentFonts) {
      if (haveAllWebFontsLoaded(fontsConfiguration)) {
        waitForFontsToRender();
      } else {
        documentFonts.ready.then(waitForFontsToLoad);
      }
    }
  }, [fontsConfiguration, waitForFontsToRender, waitForFontsToLoad]);

  return (
    <WebFontsLoadingFlagContext.Provider value={webFontsLoadingFlag}>
      {fontsConfiguration.map(createFontFamilyLinkOrFontFace)}
      {children}
    </WebFontsLoadingFlagContext.Provider>
  );
};
