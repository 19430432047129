import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Colors } from 'mm-theme-configuration';
import { TYPOGRAPHY_SIZE } from 'mm-theme-configuration/dist/consts';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { AdditionalStyleSheet } from '../../components.utils';
import { Div } from '../../../typography/semanticTags/Div';
import { semanticCSS } from '../../../typography/semanticTags';
import { useTheme } from '../../../theming/useTheme';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { ArrowDownAnimation } from './ArrowDownAnimation';
import { ArrowDownIcon } from './ArrowDownIcon';

interface KeepScrollProps {
  additionalStyle?: AdditionalStyleSheet;
  isIconAnimated?: boolean;
}

const getStyles = (textColor: string) => {
  return StyleSheet.create({
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      padding: '0px 20px',
      flexDirection: 'row',
      columnGap: '20px',

      [MEDIA_BREAKPOINTS.small]: {
        flexDirection: 'column',
        rowGap: '10px',
      },
    },
    title: {
      color: textColor,
    },
  });
};

const getFirstIconStyle = () => {
  return StyleSheet.create({
    style: {
      display: 'block',
      [MEDIA_BREAKPOINTS.small]: {
        display: 'none',
      },
    },
  }) as AdditionalStyleSheet;
};


const KeepScrollText = 'Keep scrolling for more content below';

export const KeepScroll: React.FunctionComponent<KeepScrollProps> = props => {
  const { additionalStyle, isIconAnimated } = props;
  const { colors }: { colors: Colors } = useTheme();
  const styles = getStyles(colors.primary);
  const firstIconStyle = getFirstIconStyle();

  return (
    <ErrorBoundary>
      <div className={css(styles.wrapper, additionalStyle?.style)}>
        {isIconAnimated
          ? <ArrowDownAnimation additionalStyle={firstIconStyle} />
          : <ArrowDownIcon additionalStyle={firstIconStyle} />}
        <Div
          styles={semanticCSS(styles.title)}
          fontSizeSmall={TYPOGRAPHY_SIZE.BIGGER}
          fontSizeMedium={TYPOGRAPHY_SIZE.BIGGER}
          fontSizeLarge={TYPOGRAPHY_SIZE.BIGGER}
        >
          {KeepScrollText}
        </Div>
        {isIconAnimated ? <ArrowDownAnimation /> : <ArrowDownIcon />}
      </div>
    </ErrorBoundary>
  );
};
