import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { useTheme } from '../../../theming/useTheme';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import {
  componentConfigThemeKey as onTopCardConfigThemeKey,
  OnTopCard,
  OnTopCardComponentThemeProps,
} from '../../partials/cards/onTopCard/OnTopCard';
import { HorizontalCard } from '../../partials/cards/horizontalCard/HorizontalCard';
import { VerticalCard } from '../../partials/cards/verticalCard/VerticalCard';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { createImageWidths } from '../../partials/image/image.utils';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import {
  componentConfigThemeKey as horizontalCardConfigThemeKey,
  HorizontalCardThemeProps,
} from '../../partials/cards/horizontalCard/horizontalCard.theme';
import { AuthorPageThemeProps, componentConfigThemeKey } from '../author/Author.theme';
import { VerticalCardThemeProps, componentConfigThemeKey as verticalCardConfigThemeKey } from '../../partials/cards/verticalCard/VerticalCard.theme';

const numOnTop = 4;
const numHorizontal = 8;
const numVertical = 4;

interface AuthorPageFeedDataProps {
  articles: Array<CardComponentDataProps> | null;
  useFormatMinutesHoursAgo?: boolean;
}

const getStyles = (baseUnit: BaseUnit) => StyleSheet.create({
  wrapper: {
    maxWidth: '640px',
    margin: 'auto',
    display: 'grid',
    gridTemplateColumns: '1fr',
    marginTop: `${baseUnit}px`,
    gridRowGap: `${baseUnit}px`,
  },
});

const imageWidthsOnTopCard = createImageWidths(360, 1080, 1080);
const imageWidthsHorizontalCard = createImageWidths(180, 360, 360);
const imageWidthsVerticalCard = createImageWidths(360, 1080, 1080);

const arrangeCards = (articles: Array<CardComponentDataProps>, useFormatMinutesHoursAgo?: boolean) => {
  const structure = [numOnTop, numHorizontal, numVertical];
  let section = 0;
  let counter = 0;
  return articles.reduce((acc: any[], article: CardComponentDataProps) => {
    let Component;

    switch (section) {
      case 0:
        Component = <OnTopCard key={article.id} {...article} imageWidths={imageWidthsOnTopCard} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />;
        break;
      case 1:
        Component = <HorizontalCard key={article.id} {...article} imageWidths={imageWidthsHorizontalCard} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />;
        break;
      case 2:
        Component = <VerticalCard key={article.id} {...article} imageWidths={imageWidthsVerticalCard} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />;
        break;
      default:
        Component = null;
        break;
    }

    counter += 1;
    if (counter >= structure[section]) {
      counter = 0;
      section = section === structure.length - 1 ? 0 : section + 1;
    }

    return [...acc, Component];
  }, []);
};

export const AuthorPageFeed: React.FunctionComponent<AuthorPageFeedDataProps> = props => {
  const { showMainCategoryInLargeScreen, showMainCategoryInMediumScreen, showMainCategoryInSmallScreen, baseUnit } = useTheme<AuthorPageThemeProps>(componentConfigThemeKey);
  const { articles, useFormatMinutesHoursAgo } = props;
  const themeContextOTC = useThemeOverride<OnTopCardComponentThemeProps>({
    showMainCategoryInLargeScreen,
    showMainCategoryInMediumScreen,
    showMainCategoryInSmallScreen,
  }, onTopCardConfigThemeKey);
  const themeContextHC = useThemeOverride<HorizontalCardThemeProps>({
    showMainCategoryInLargeScreen,
    showMainCategoryInMediumScreen,
    showMainCategoryInSmallScreen,
  }, horizontalCardConfigThemeKey);
  const themeContextVC = useThemeOverride<VerticalCardThemeProps>({
    showMainCategoryInLargeScreen,
    showMainCategoryInMediumScreen,
    showMainCategoryInSmallScreen,
  }, verticalCardConfigThemeKey);

  const newContext = {
    ...themeContextOTC,
    [horizontalCardConfigThemeKey]: { ...themeContextHC[horizontalCardConfigThemeKey] },
    [verticalCardConfigThemeKey]: { ...themeContextVC[verticalCardConfigThemeKey] },
  };

  const styles = getStyles(baseUnit);
  return (
    <ErrorBoundary>
      {articles && articles.length > 0
          && (
          <div className={css(styles.wrapper)}>
            <ThemeContext.Provider value={newContext}>
              {arrangeCards(articles, useFormatMinutesHoursAgo)}
            </ThemeContext.Provider>
          </div>
          ) }
    </ErrorBoundary>
  );
};
