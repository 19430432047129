import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { LinkButtonDataProps } from '../utils';
import { MEDIA_BREAKPOINTS } from '../../../../mediaQueries.const';
import { useTheme } from '../../../../theming/useTheme';
import { LinkButton } from './LinkButton';
import { getStyleBySize } from '../../../../typography/semanticTags/semanticTags.utils';
import { generateLinkStyleFromTheme, TextLinkComponent } from '../../../partials/link/TextLink';
import { componentConfigurationKey, FooterThemeProps } from './Footer.theme';
import { BaseUnit } from '../../../../../src/theming/baseUnitDefinition';

interface Link {
  text: string;
  href: string;
}

interface NavigationLink {
  link: Link;
  children: NavigationLink[] | null;
}

export interface FooterLinksSectionBaseProps {
  buttons?: Array<LinkButtonDataProps>;
  links?: NavigationLink[];
}

type LinksSectionProps = FooterLinksSectionBaseProps;

const getStyle = (baseUnit: BaseUnit, largePaddingFactor: number, mediumPaddingFactor: number, smallPaddingFactor: number) => {
  return StyleSheet.create({
    section: {
      [MEDIA_BREAKPOINTS.large]: {
        paddingTop: `${largePaddingFactor * baseUnit}px`,
        paddingBottom: `${largePaddingFactor * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        paddingTop: `${mediumPaddingFactor * baseUnit}px`,
        paddingBottom: `${mediumPaddingFactor * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        paddingTop: `${smallPaddingFactor * baseUnit}px`,
        paddingBottom: `${smallPaddingFactor * baseUnit}px`,
      },
      display: 'flex',
      justifyContent: 'center',
    },
    linksWrapper: {
      display: 'inline-grid',
      [MEDIA_BREAKPOINTS.large]: {
        gridTemplateColumns: 'repeat(5, auto)',
        gridGap: `${baseUnit * 0.5}px ${baseUnit * 4}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        gridTemplateColumns: 'repeat(4, auto)',
        gridGap: `${baseUnit * 0.5}px ${baseUnit * 2}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        gridTemplateColumns: 'auto auto',
        gridGap: `${baseUnit * 0.5}px ${baseUnit * 2}px`,
      },
    },
  });
};

export const FooterLinksSection: React.FunctionComponent<LinksSectionProps> = props => {
  const { linksIdleColor, linksActiveStateColor, linksHoverStateColor, linksFontSizeLarge, linksFontSizeMedium, linksFontSizeSmall, linksPaddingFactorLargeScreen, linksPaddingFactorMediumScreen, linksPaddingFactorSmallScreen, baseUnit, fontSizesStyles } = useTheme<FooterThemeProps>(componentConfigurationKey);
  const { links, buttons } = props;
  const style = getStyle(baseUnit, linksPaddingFactorLargeScreen, linksPaddingFactorMediumScreen, linksPaddingFactorSmallScreen);
  const fontStyles = getStyleBySize(fontSizesStyles, { fontSizeLarge: linksFontSizeLarge, fontSizeMedium: linksFontSizeMedium, fontSizeSmall: linksFontSizeSmall });
  const linkColorsStyle = generateLinkStyleFromTheme({ activeStateColor: linksActiveStateColor, hoverStateColor: linksHoverStateColor, idleColor: linksIdleColor });

  return (
    <div className={css(style.section)}>
      <div className={css(style.linksWrapper)}>
        {links ? links.map((navigationLink, index) => <div key={index} className={css(fontStyles)}><TextLinkComponent linkStyle={linkColorsStyle} href={navigationLink.link.href}>{navigationLink.link.text}</TextLinkComponent></div>) : null}
        {buttons ? buttons.map((button, index) => <LinkButton key={index} text={button.text} onClick={button.onClick} attributes={button.attributes} cssClassName={button.cssClassName} color={linksIdleColor} hoverColor={linksHoverStateColor} />) : null}
        <div className={css(fontStyles)}>
          <div style={{ color: '#fff' }} id="teconsent" />
        </div>
      </div>
    </div>

  );
};
