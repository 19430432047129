import { predicates } from '../predicates/index';
import { selectors } from '../selectors/index';
import { TRAFFIC_SOURCES } from '../constants';

export const embedPartner = {
  rule: predicates.and(
    predicates.pathnameIncludesKey('embed'),
    predicates.searchParamsIncludeKey('partner'),
  ),
  extractor: (traffic) => {
    const trafficSource = TRAFFIC_SOURCES.EMBED_PARTNER;
    const trafficId = selectors.searchParamValue(traffic, 'partner');

    return {
      trafficSource,
      trafficId,
    };
  },
};
