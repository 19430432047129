import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { RegularHeaderLink } from '../../navigation/header/header/RegularHeaderLink';
import { TypographySizes } from '../../../../typography/semanticTags/semanticTags.utils';
import { MEDIA_BREAKPOINTS } from '../../../../mediaQueries.const';
import { useTheme } from '../../../../theming/useTheme';
import { SportradarProp, SportradarPropsType } from '../sportradar.types';
import { MATCH_STATUS_NAV, NAV_CONFIG, TICKER_LEAGUES } from './matchNav.const';
import { ErrorBoundary } from '../../../errorHandling/ErrorBoundary';

interface MatchNavProps {
  canonicalURL: string;
  sportradarProps: SportradarPropsType;
}

const createStyle = (hoverColor: string) => StyleSheet.create({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'nowrap',
    padding: '10px 0',
    overflowX: 'auto',
    listStyle: 'none',
    borderBottom: '1px solid rgba(0,0,0,.12)',
    [MEDIA_BREAKPOINTS.small]: {
      padding: '10px 15px',
    },
  },
  selected: {
    textDecoration: 'underline',
    textDecorationThickness: '3px',
    textUnderlineOffset: '12px',
    ':hover': {
      color: hoverColor,
    },
  },
  listItem: {
    marginRight: '15px',
  },
});

export const MatchNav = ({ canonicalURL, sportradarProps }: MatchNavProps) => {
  const { colors } = useTheme<any>();
  const styles = createStyle(colors.primary);
  const linkProps = {
    activeStateColor: colors.secondary,
    hoverStateColor: colors.primary,
    idleColor: colors.secondary,
    currentLinkColor: colors.secondary,
    linkFontSizeLarge: TypographySizes.NORMAL,
    linkFontSizeMedium: TypographySizes.NORMAL,
    linkFontSizeSmall: TypographySizes.NORMAL,
  };

  const createParams = () => {
    const { matchStatus, matchId, seasonId, league, teamAId, teamBId, usSport } = sportradarProps;
    const mapProps: SportradarProp = {
      matchId,
      seasonId,
      sportId: league && TICKER_LEAGUES[league],
      matchStatus,
      awayTeamUid: teamBId,
      homeTeamUid: teamAId,
      usSport,
    };

    return Object.entries(mapProps)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
  };

  const buildLink = (link: { text: string; param: string }) => {
    if (link.text === NAV_CONFIG.TICKETS.text) {
      return NAV_CONFIG.TICKETS.href;
    }

    return `${canonicalURL}?${createParams()}&tab=${link.param}`;
  };

  const buildNavList = () => {
    const configureNavType: any = sportradarProps.league === 'soccer'
      ? `soccer_${sportradarProps.matchStatus}`
      : sportradarProps.matchStatus;

    return MATCH_STATUS_NAV[configureNavType]?.map(
      (link: any, index: number) => {
        const isActive = link?.param === sportradarProps.tab;
        const activeStyle = isActive ? styles.selected : null;
        const url = buildLink(link);

        return (
          <li
            key={`team-nav${index}`}
            className={css(styles.listItem, activeStyle)}
          >
            <RegularHeaderLink
              {...linkProps}
              href={url}
              text={link.text}
              isActive={isActive}
            />
          </li>
        );
      }
    );
  };

  return (
    <ErrorBoundary>
      {sportradarProps?.matchStatus && (
        <nav>
          <ul className={css(styles.container)}>{buildNavList()}</ul>
        </nav>
      )}
    </ErrorBoundary>
  );
};
