import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { CurrentGroup, FeedGroup, FeedGroupAll, FEED_GROUP_ALL_KEY, useLinkButtonThemeContextOverride } from './sitemap.utils';
import { LinkWithButtonStyle } from '../buttons/LinkWithButtonStyle';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { baseUnit } from '../../../theming/baseUnitDefinition';
import { AdditionalStyleSheet } from '../../components.utils';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';

const createStyles = () => StyleSheet.create({
  FeedsNavbar: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: baseUnit / 4,
    [MEDIA_BREAKPOINTS.medium]: {
      maxWidth: 500,
    },
    [MEDIA_BREAKPOINTS.small]: {
      maxWidth: 250,
    },
  },
  feedGroup: {
    padding: 0,
  },
});

export const createFeedStyles = () => StyleSheet.create({
  style: {
    minWidth: 'auto',
    height: baseUnit * 1.5,
    whiteSpace: 'nowrap',
    width: baseUnit * 1.5,
    padding: 0,
  },
}) as AdditionalStyleSheet;

export interface FeedsNavbarProps {
  feedGroups: Array<FeedGroup>;
  currentGroup: CurrentGroup;
  feedGroupAll: FeedGroupAll;
}

export const FeedsNavbar = ({ feedGroups, currentGroup, feedGroupAll }: FeedsNavbarProps) => {
  const styles = createStyles();
  const feedStyles = createFeedStyles();
  const themeContextLinkButton = useLinkButtonThemeContextOverride();
  const themeContextLinkButtonSelected = useLinkButtonThemeContextOverride({ isSelected: true });

  return (
    <div className={css(styles.FeedsNavbar)}>
      <ThemeContext.Provider value={currentGroup === FEED_GROUP_ALL_KEY ? themeContextLinkButtonSelected : themeContextLinkButton}>
        <LinkWithButtonStyle
          href={feedGroupAll.link}
          key={feedGroupAll.link}
          additionalStyle={feedStyles}
        >
          {feedGroupAll.displayName}
        </LinkWithButtonStyle>
      </ThemeContext.Provider>
      {feedGroups.map(({ key, displayName, link, feeds }) => (
        <div key={link}>
          <ThemeContext.Provider value={key === currentGroup ? themeContextLinkButtonSelected : themeContextLinkButton}>
            <LinkWithButtonStyle
              href={link}
              additionalStyle={feedStyles}
              disabled={feeds.length === 0}
            >
              {displayName}
            </LinkWithButtonStyle>
          </ThemeContext.Provider>
        </div>
      ))}
    </div>
  );
};
