import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useTheme } from '../../../../theming/useTheme';
import { Image } from '../../../partials/image/Image';
import { createImageAspectRatios, createImageWidths } from '../../../partials/image/image.utils';
import { CardOverlay } from '../../../partials/CardOverlay';
import { Thumbnail } from '../../../partials/image/image.types';
import { DynamicSemanticTag } from '../../../../typography/semanticTags/DynamicSemanticTag';
import { MEDIA_BREAKPOINTS } from '../../../../mediaQueries.const';
import { baseUnit } from '../../../../theming/baseUnitDefinition';
import {
  componentConfigThemeKey,
  SectionOnTopTitleThemeProps,
  SectionOnTopTitleWrapper,
} from './SectionOnTopTitleWrapper';

interface SectionOnTopTitleDataProps {
  title: string;
  subtitle: string;
  logo?: {
    src: string;
    alt: string;
  };
  link?: string;
  image: Thumbnail;
  isSponsoredLink?: boolean;
}

const imageWidths = createImageWidths(360, 1080, 1080);

const createContentWrapperStyle = (theme: SectionOnTopTitleThemeProps) => {
  const { horizontalGapLarge, verticalGapLarge, horizontalGapMedium, verticalGapMedium, horizontalGapSmall, verticalGapSmall } = theme;
  const actualHorizontalGapLarge = baseUnit * horizontalGapLarge;
  const actualVerticalGapLarge = baseUnit * verticalGapLarge;
  const actualHorizontalGapMedium = baseUnit * horizontalGapMedium;
  const actualVerticalGapMedium = baseUnit * verticalGapMedium;
  const actualHorizontalGapSmall = baseUnit * horizontalGapSmall;
  const actualVerticalGapSmall = baseUnit * verticalGapSmall;
  const largeWidthForCentering = `calc(100% - ${actualVerticalGapLarge * 2}px)`;
  const mediumWidthForCentering = `calc(100% - ${actualVerticalGapMedium * 2}px)`;
  const smallWidthForCentering = `calc(100% - ${actualVerticalGapSmall * 2}px)`;
  return StyleSheet.create({
    style: {
      overflow: 'hidden',
      display: 'grid',
      background: 'transparent',
      position: 'absolute',
      bottom: 0,
      justifyContent: 'center',
      textAlign: 'center',
      justifyItems: 'center',
      [MEDIA_BREAKPOINTS.large]: {
        width: largeWidthForCentering,
        margin: `${actualHorizontalGapLarge}px ${actualVerticalGapLarge}px`,
        gridRowGap: `${actualHorizontalGapLarge}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        width: mediumWidthForCentering,
        margin: `${actualHorizontalGapMedium}px ${actualVerticalGapMedium}px`,
        gridRowGap: `${actualHorizontalGapMedium}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        width: smallWidthForCentering,
        margin: `${actualHorizontalGapSmall}px ${actualVerticalGapSmall}px`,
        gridRowGap: `${actualHorizontalGapSmall}px`,
      },
    },
  }).style;
};

const createLogoStyle = () => StyleSheet.create({
  style: {
    display: 'inline-flex',
    width: 'auto',
    [MEDIA_BREAKPOINTS.large]: {
      height: `${4 * baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      height: `${3 * baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      height: `${3 * baseUnit}px`,
    },
  },
}).style;

export const SectionOnTopTitle: React.FunctionComponent<SectionOnTopTitleDataProps> = props => {
  const { title, subtitle, logo, link, image, isSponsoredLink = false } = props;
  const theme = useTheme<SectionOnTopTitleThemeProps>(componentConfigThemeKey);
  const { aspectRatioSmall, aspectRatioMedium, aspectRatioLarge, overlayColor, titleSemanticTag, titleFontSizeLarge, titleFontSizeMedium, titleFontSizeSmall, subtitleSemanticTag, subtitleFontSizeLarge, subtitleFontSizeMedium, subtitleFontSizeSmall } = theme;
  const contentWrapperStyle = createContentWrapperStyle(theme);
  const logoStyle = createLogoStyle();
  return (
    <SectionOnTopTitleWrapper link={link} isSponsoredLink={isSponsoredLink}>
      <Image
        {...image}
        lazyLoad={false}
        imageAspectRatios={createImageAspectRatios(aspectRatioSmall, aspectRatioMedium, aspectRatioLarge)}
        imageWidths={imageWidths}
      />
      <CardOverlay overlayColor={overlayColor} />
      <div className={css(contentWrapperStyle)}>
        {logo ? <img className={css(logoStyle)} alt={logo.alt} src={`data:image/svg+xml,${encodeURIComponent(logo.src)}`} /> : null}
        <DynamicSemanticTag
          semanticTag={titleSemanticTag}
          fontSizeLarge={titleFontSizeLarge}
          fontSizeMedium={titleFontSizeMedium}
          fontSizeSmall={titleFontSizeSmall}
        >
          {title}
        </DynamicSemanticTag>
        <DynamicSemanticTag
          semanticTag={subtitleSemanticTag}
          fontSizeLarge={subtitleFontSizeLarge}
          fontSizeMedium={subtitleFontSizeMedium}
          fontSizeSmall={subtitleFontSizeSmall}
        >
          {subtitle}
        </DynamicSemanticTag>
      </div>
    </SectionOnTopTitleWrapper>
  );
};
