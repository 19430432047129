import { predicates } from '../predicates/index';
import { selectors } from '../selectors/index';
import { TRAFFIC_SOURCES } from '../constants';

export const navigation = {
  rule: predicates.referrerIsSameAsSite(),
  extractor: (traffic) => {
    const trafficSource = TRAFFIC_SOURCES.NAVIGATION;
    const trafficId = selectors.referrerHostname(traffic);

    return {
      trafficSource,
      trafficId,
    };
  },
};
