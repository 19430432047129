import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import {
  SecondaryHeaderProps,
} from '../../components/navigation/header/oldHeader/secondaryHeader/SecondaryHeader';
import { useTheme } from '../../../theming/useTheme';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { HeaderHeightsPerBreakpoint } from '../../components/navigation/header/oldHeader/oldHeader.utils';
import { AMPLogo } from './AMPLogo';
import { AMPSecondaryLinks } from './AMPSecondaryLinks';
import { createStyleForBoxShadow } from '../../../theming/boxShadow';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { componentConfigThemeKey, HeaderThemeProps } from '../../components/navigation/header/Header.theme';

const createStyle = (baseUnit: BaseUnit, backgroundColor: string, headerHeights: HeaderHeightsPerBreakpoint) => {
  return StyleSheet.create({
    wrapper: {
      backgroundColor,
      position: 'relative',
      [MEDIA_BREAKPOINTS.large]: {
        height: `${headerHeights.large * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        height: `${headerHeights.medium * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        height: `${headerHeights.small * baseUnit}px`,
      },
      display: 'grid',
      gridAutoFlow: 'column',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'flex-start',
      zIndex: 50,
      gridColumnGap: `${baseUnit}px`,
      paddingLeft: `${baseUnit}px`,
    },
  });
};

export const AMPSecondaryHeader: React.FunctionComponent<SecondaryHeaderProps> = props => {
  const { baseUnit, secondRowBackgroundColor, secondRowBoxShadow } = useTheme<HeaderThemeProps>(componentConfigThemeKey);
  const { moreLinksText, links, logo, pagePath, logoHeights, headerHeights } = props;
  const style = createStyle(baseUnit, secondRowBackgroundColor, headerHeights);
  const boxShadowStyle = createStyleForBoxShadow({ boxShadowTypeNormal: secondRowBoxShadow });

  return (
    <ErrorBoundary>
      <div className={css(style.wrapper, boxShadowStyle.style)}>
        {logo ? <AMPLogo {...logo} logoHeights={{ large: logoHeights.large, medium: logoHeights.medium, small: logoHeights.small }} /> : null}
        {links ? <AMPSecondaryLinks links={links} moreLinksText={moreLinksText} pagePath={pagePath} headerHeights={headerHeights} /> : null}
      </div>
    </ErrorBoundary>
  );
};
