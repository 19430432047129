import { useEffect } from 'react';
import { markPerformance, measurePerformance } from '../../performance';

export function useMeasureHydrateTime() {
    markPerformance('start-hydrate');
    useEffect(() => {
        markPerformance('end-hydrate');
        measurePerformance('hydrate-duration', 'start-hydrate', 'end-hydrate');
    }, []);
}
