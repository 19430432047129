import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { generateLinkStyleFromTheme, TextLinkComponent } from '../../../partials/link/TextLink';
import { useTheme } from '../../../../theming/useTheme';
import { HUGE_SCREEN_SIZE } from '../../../../mediaQueries.const';
import { P, semanticCSS } from '../../../../typography/semanticTags';
import { componentConfigurationKey, FooterThemeProps } from './Footer.theme';
import { BaseUnit } from '../../../../theming/baseUnitDefinition';

export interface BottomFooterBarDataProps {
  year: number;
  rightsReservedText: string;
  poweredByLink: {
    text: string;
    href: string;
  };
}

const getBottomFooterBarStyle = (backgroundColor: string, textColor: string, baseUnit: BaseUnit) => StyleSheet.create({
  style: {
    color: textColor,
    padding: `${baseUnit * 0.5}px ${baseUnit}px`,
    boxSizing: 'border-box',
    textAlign: 'center',
    margin: '0 auto',
    maxWidth: `${HUGE_SCREEN_SIZE}px`,
  },
  wrapper: {
    backgroundColor,
  },
});

export const BottomFooterBar: React.FunctionComponent<BottomFooterBarDataProps> = props => {
  const { bottomBarBackgroundColor, bottomBarTextColor, baseUnit } = useTheme<FooterThemeProps>(componentConfigurationKey);
  const bottomFooterBarStyle = getBottomFooterBarStyle(bottomBarBackgroundColor, bottomBarTextColor, baseUnit);
  const { year, rightsReservedText, poweredByLink } = props;
  const noHrefStyle = { textDecoration: poweredByLink.href ? 'underline' : 'none', pointerEvents: poweredByLink.href ? 'auto' : 'none' };
  const linkStyle = generateLinkStyleFromTheme({ idleColor: bottomBarTextColor, activeStateColor: bottomBarTextColor, hoverStateColor: bottomBarTextColor }, noHrefStyle);

  return (
    <div className={css(bottomFooterBarStyle.wrapper)}>
      <P styles={semanticCSS(bottomFooterBarStyle.style)} fontSizeLarge="tiny" fontSizeMedium="tiny" fontSizeSmall="tiny">
        {`© ${year} `}
        <TextLinkComponent href={poweredByLink.href} linkStyle={linkStyle} rel="noopener" target="_blank">
          {poweredByLink.text}
        </TextLinkComponent>
        {` - ${rightsReservedText}`}
      </P>
    </div>
  );
};
