import React, { useState, useRef, useEffect } from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { Colors } from 'mm-theme-configuration';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { Tag } from '../../components/tag/Tag';
import { useTheme } from '../../../theming/useTheme';
import { TypographySizes, getStyleBySize } from '../../../typography/semanticTags/semanticTags.utils';
import { TranslatedFontSize } from '../../../theming/fontSizeTranslator';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { ArrowSvg } from '../../components/carousel/ArrowSvg';

interface InThisStoryData {
  link: string;
  displayName: string;
}

export interface InThisStoryProps {
  title?: string | undefined;
  inThisStory: InThisStoryData[];
}

const getinThisStoryStyle = (colorWhite: string) => {
  return StyleSheet.create({
    wrapper: {
      position: 'relative',
      width: 'fit-content',
    },
    list: {
      position: 'relative',
      display: 'flex',
      scrollbarWidth: 'none',
      scrollbarBehavior: 'smooth',
      height: '41px',
      padding: 0,
      '::-webkit-scrollbar': {
        display: 'none',
      },
      listStyleType: 'none',
      overflowX: 'scroll',
      [MEDIA_BREAKPOINTS.large]: {
        width: 620,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        width: 620,
      },
      [MEDIA_BREAKPOINTS.small]: {
        width: 335,
      },
    },
    title: {
      [MEDIA_BREAKPOINTS.large]: {
        fontWeight: 500,
        lineHeight: '22px',
        marginBottom: 15,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        fontWeight: 500,
        lineHeight: '22px',
        marginBottom: 5,
      },
      [MEDIA_BREAKPOINTS.small]: {
        fontWeight: 500,
        lineHeight: '22px',
        marginBottom: 5,
      },
    },
    tag: {
      float: 'left',
      marginRight: 5,
      position: 'relative',
    },
    arrowButton: {
      background: `linear-gradient(270deg, ${colorWhite} 34%, rgba(255, 255, 255, 0) 100%)`,

      position: 'absolute',
      height: '40px',
      width: '80px',
      opacity: 0.85,
      zIndex: 100,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      cursor: 'pointer',
      [MEDIA_BREAKPOINTS.medium]: {
        display: 'none',
      },
      [MEDIA_BREAKPOINTS.small]: {
        display: 'none',
      },
    },
    arrowPrev: {
      left: '0',
      bottom: '0',
      paddingLeft: '15px',
      justifyContent: 'flex-end',
      transform: 'translateX(0px) rotate(180deg)',
    },
    arrowNext: {
      right: '0',
      bottom: '0',
      paddingLeft: '15px',
      transform: 'translateX(24px)',
    },
    buttonWrapper: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      position: 'relative',
    },
  });
};

export const InThisStoryComponent = ({ title, inThisStory = [] }: InThisStoryProps) => {
  const { colors, fontSizesStyles }: { colors: Colors; fontSizesStyles: TranslatedFontSize } = useTheme();
  const arrowIconColor = colors.black;
  const styles = getinThisStoryStyle(colors.white);
  const tagFontSizes = getStyleBySize(fontSizesStyles, { fontSizeSmall: TypographySizes.TINY, fontSizeMedium: TypographySizes.TINY, fontSizeLarge: TypographySizes.TINY });
  const tagsListElementRef = useRef<HTMLUListElement>(null);
  const itemRef = useRef<HTMLLIElement>(null);
  const [showPrev, setShowPrev] = useState(false);
  const [showNext, setShowNext] = useState(false);
  const componentTitle = !title ? 'In this story:' : title;

  const onNextClick = () => {
    if (tagsListElementRef?.current && itemRef?.current) {
      const cardSize = itemRef?.current?.offsetWidth;
      tagsListElementRef.current.scrollLeft += cardSize + 15;
    }
  };

  const onPrevClick = () => {
    if (tagsListElementRef?.current && itemRef?.current) {
      const cardSize = itemRef?.current?.offsetWidth;
      tagsListElementRef.current.scrollLeft -= cardSize + 15;
    }
  };

  const setButtonsVisability = () => {
    if (!tagsListElementRef.current) return;
    setShowPrev(tagsListElementRef.current.scrollLeft > 0);
    setShowNext(tagsListElementRef.current.scrollLeft < tagsListElementRef.current.scrollWidth - tagsListElementRef.current.offsetWidth);
  };

  useEffect(() => {
    const wrapperRef = tagsListElementRef?.current;
    if (wrapperRef) {
      wrapperRef.addEventListener('scroll', setButtonsVisability);
      setButtonsVisability();
    }
    return () => {
      return wrapperRef?.removeEventListener('scroll', setButtonsVisability);
    };
  }, [tagsListElementRef]);

  useEffect(() => {
    window.addEventListener('resize', setButtonsVisability);
    return () => {
      window.removeEventListener('resize', setButtonsVisability);
    };
  }, [tagsListElementRef]);


  return (
    <ErrorBoundary>
      <div className={css(styles.wrapper)}>
        <div className={css([tagFontSizes, styles.title])}>{componentTitle}</div>
        <ul className={css(styles.list)} ref={tagsListElementRef}>
          { inThisStory.map(({ displayName: label, link }: InThisStoryData, index: number) => {
            return (
              <li key={`${label}-${index}`} className={css(styles.tag)} ref={itemRef}>
                <Tag {...{ label, link }} />
              </li>
            );
          })}
        </ul>

        <div className={css(styles.buttonWrapper)}>
          {showPrev && (
          <div className={css([styles.arrowButton, styles.arrowPrev])} onClick={onPrevClick}>
            <ArrowSvg
              arrowIconColor={arrowIconColor}
            />
          </div>
          )}
          {showNext
            && (
            <div className={css([styles.arrowButton, styles.arrowNext])} onClick={onNextClick}>
              <ArrowSvg
                arrowIconColor={arrowIconColor}
              />
            </div>
            )}
        </div>
        <div />
      </div>
    </ErrorBoundary>
  );
};
