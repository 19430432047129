import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { RATIO, TypographySize } from 'mm-theme-configuration/dist/consts';
import { getLayoutComponentPadding, LayoutComponentBaseProps } from '../pageLayouts/layout.utils';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { useTheme } from '../../../theming/useTheme';
import {
  componentConfigThemeKey as onTopCardConfigThemeKey,
  OnTopCard,
  OnTopCardComponentThemeProps,
} from '../../partials/cards/onTopCard/OnTopCard';
import { createImageHeightCalculationMethods, createImageWidths } from '../../partials/image/image.utils';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { VerticalCard } from '../../partials/cards/verticalCard/VerticalCard';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { VerticalCardThemeProps, componentConfigThemeKey as verticalCardConfigThemeKey } from '../../partials/cards/verticalCard/VerticalCard.theme';

export const componentConfigThemeKey = 'fourVCOneOTC';

interface FourVCOneOTCProps extends LayoutComponentBaseProps {
    cards: CardComponentDataProps[];
}

export interface FourVCOneOTCThemeProps {
    largeVerticalGap: number;
    largeHorizontalGap: number;
    mediumVerticalGap: number;
    mediumHorizontalGap: number;
    smallHorizontalGap: number;
    baseUnit: number;
    authorColor: string;
    primaryTitleFontSizeSmall: TypographySize;
    primaryTitleFontSizeMedium: TypographySize;
    primaryTitleFontSizeLarge: TypographySize;
    primaryMetadataFontSizeSmall: TypographySize;
    primaryMetadataFontSizeMedium: TypographySize;
    primaryMetadataFontSizeLarge: TypographySize;
    primaryHorizontalGapSmall: number;
    primaryHorizontalGapMedium: number;
    primaryHorizontalGapLarge: number;
    aspectRatioSmall: RATIO;
    secondaryTitleFontSizeSmall: TypographySize;
    secondaryTitleFontSizeMedium: TypographySize;
    secondaryTitleFontSizeLarge: TypographySize;
    secondaryMetadataFontSizeSmall: TypographySize;
    secondaryMetadataFontSizeMedium: TypographySize;
    secondaryMetadataFontSizeLarge: TypographySize;
    secondaryHorizontalGapSmall: number;
    secondaryHorizontalGapMedium: number;
    secondaryHorizontalGapLarge: number;
    showMainCategoryInLargeScreen: boolean;
    showMainCategoryInMediumScreen: boolean;
    showMainCategoryInSmallScreen: boolean;
}

const createStyle = (themeProps: FourVCOneOTCThemeProps) => {
  const { largeHorizontalGap, largeVerticalGap, mediumHorizontalGap, mediumVerticalGap, smallHorizontalGap, baseUnit } = themeProps;
  const mainCardArea = 'main_card_area';
  const firstCardArea = 'first_card_area';
  const secondCardArea = 'second_card_area';
  const thirdCardArea = 'third_card_area';
  const fourthCardArea = 'fourth_card_area';
  return StyleSheet.create({
    wrapper: {
      display: 'grid',
      [MEDIA_BREAKPOINTS.large]: {
        gridTemplateColumns: '1fr 1fr 2fr',
        gridTemplateRows: '1fr 1fr',
        gridColumnGap: `${largeVerticalGap * baseUnit}px`,
        gridRowGap: `${largeHorizontalGap * baseUnit}px`,
        gridTemplateAreas: `'${firstCardArea} ${secondCardArea} ${mainCardArea}' '${thirdCardArea} ${fourthCardArea} ${mainCardArea}'`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: 'auto auto auto',
        gridColumnGap: `${mediumVerticalGap * baseUnit}px`,
        gridRowGap: `${mediumHorizontalGap * baseUnit}px`,
        gridTemplateAreas: `'${mainCardArea} ${mainCardArea}' '${firstCardArea} ${secondCardArea}' '${thirdCardArea} ${fourthCardArea}'`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        gridTemplateColumns: '1fr',
        gridRowGap: `${smallHorizontalGap * baseUnit}px`,
        gridTemplateAreas: `'${mainCardArea}' '${firstCardArea}' '${secondCardArea}' '${thirdCardArea}' '${fourthCardArea}'`,

      },
    },
    mainCard: {
      gridArea: mainCardArea,
    },
    firstCard: {
      gridArea: firstCardArea,
    },
    secondCard: {
      gridArea: secondCardArea,
    },
    thirdCard: {
      gridArea: thirdCardArea,
    },
    fourthCard: {
      gridArea: fourthCardArea,
    },
    minorCard: {
      [MEDIA_BREAKPOINTS.small]: {
        paddingLeft: `${0.5 * baseUnit}px`,
        paddingRight: `${0.5 * baseUnit}px`,
      },
    },
  });
};

const imageWidthsForMainCard = createImageWidths(360, 720, 540);
const imageWidthsForMinorCard = createImageWidths(360, 360, 360);

const onTopCardImageHeightCalculationMethods = createImageHeightCalculationMethods('ASPECT_RATIO', 'ASPECT_RATIO', '100%_HEIGHT');

export const FourVCOneOTC: React.FunctionComponent<FourVCOneOTCProps> = props => {
  const { cards, paddingFactors = { large: 0.5, medium: 0.5, small: 0 } } = props;
  const paddingStyle = getLayoutComponentPadding(paddingFactors);
  const themeProps = useTheme<FourVCOneOTCThemeProps>(componentConfigThemeKey);
  const styles = createStyle(themeProps);
  const { authorColor,
    primaryTitleFontSizeSmall,
    primaryTitleFontSizeMedium,
    primaryTitleFontSizeLarge,
    primaryMetadataFontSizeSmall,
    primaryMetadataFontSizeMedium,
    primaryMetadataFontSizeLarge,
    primaryHorizontalGapSmall,
    primaryHorizontalGapMedium,
    primaryHorizontalGapLarge,
    aspectRatioSmall,
    secondaryTitleFontSizeSmall,
    secondaryTitleFontSizeMedium,
    secondaryTitleFontSizeLarge,
    secondaryMetadataFontSizeSmall,
    secondaryMetadataFontSizeMedium,
    secondaryMetadataFontSizeLarge,
    secondaryHorizontalGapSmall,
    secondaryHorizontalGapMedium,
    secondaryHorizontalGapLarge,
    showMainCategoryInLargeScreen,
    showMainCategoryInMediumScreen,
    showMainCategoryInSmallScreen } = themeProps;
  const themeContextFirstCard = useThemeOverride<OnTopCardComponentThemeProps>({
    titleFontSizeLarge: primaryTitleFontSizeLarge,
    titleFontSizeMedium: primaryTitleFontSizeMedium,
    titleFontSizeSmall: primaryTitleFontSizeSmall,
    metadataFontSizeLarge: primaryMetadataFontSizeLarge,
    metadataFontSizeMedium: primaryMetadataFontSizeMedium,
    metadataFontSizeSmall: primaryMetadataFontSizeSmall,
    horizontalGapLarge: primaryHorizontalGapLarge,
    horizontalGapMedium: primaryHorizontalGapMedium,
    horizontalGapSmall: primaryHorizontalGapSmall,
    aspectRatioLarge: { x: 3, y: 4 },
    aspectRatioSmall,
    authorColor,
    showMainCategoryInLargeScreen,
    showMainCategoryInMediumScreen,
    showMainCategoryInSmallScreen,
  }, onTopCardConfigThemeKey);
  const themeContextMinorCard = useThemeOverride<VerticalCardThemeProps>({
    titleFontSizeLarge: secondaryTitleFontSizeLarge,
    titleFontSizeMedium: secondaryTitleFontSizeMedium,
    titleFontSizeSmall: secondaryTitleFontSizeSmall,
    metadataFontSizeLarge: secondaryMetadataFontSizeLarge,
    metadataFontSizeMedium: secondaryMetadataFontSizeMedium,
    metadataFontSizeSmall: secondaryMetadataFontSizeSmall,
    horizontalGapLarge: secondaryHorizontalGapLarge,
    horizontalGapMedium: secondaryHorizontalGapMedium,
    horizontalGapSmall: secondaryHorizontalGapSmall,
    showMainCategoryInLargeScreen,
    showMainCategoryInMediumScreen,
    showMainCategoryInSmallScreen,
  }, verticalCardConfigThemeKey);
  return (
    <ErrorBoundary>
      { cards && cards.length > 0
      && (
      <div className={css(paddingStyle, styles.wrapper)}>
        <ThemeContext.Provider value={themeContextMinorCard}>
          <div className={css(styles.firstCard, styles.minorCard)}>
            <VerticalCard {...cards[1]} imageWidths={imageWidthsForMinorCard} />
          </div>
          <div className={css(styles.secondCard, styles.minorCard)}>
            <VerticalCard {...cards[2]} imageWidths={imageWidthsForMinorCard} />
          </div>
          <div className={css(styles.thirdCard, styles.minorCard)}>
            <VerticalCard {...cards[3]} imageWidths={imageWidthsForMinorCard} />
          </div>
          <div className={css(styles.fourthCard, styles.minorCard)}>
            <VerticalCard {...cards[4]} imageWidths={imageWidthsForMinorCard} />
          </div>
        </ThemeContext.Provider>
        <ThemeContext.Provider value={themeContextFirstCard}>
          <div className={css(styles.mainCard)}>
            <OnTopCard {...cards[0]} imageWidths={imageWidthsForMainCard} imageHeightCalculationMethods={onTopCardImageHeightCalculationMethods} lazyLoad />
          </div>
        </ThemeContext.Provider>
      </div>
      )}
    </ErrorBoundary>
  );
};
