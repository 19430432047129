import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { TypographySize } from 'mm-theme-configuration/src/consts';
import { useTheme } from '../../../theming/useTheme';
import { Image } from '../../partials/image/Image';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { AdditionalStyleSheet } from '../../components.utils';
import { Thumbnail } from '../../partials/image/image.types';
import { Link } from '../../partials/link/Link';
import { H1, P, Span, semanticCSS } from '../../../typography/semanticTags';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { createImageWidths } from '../../partials/image/image.utils';
import { TranslatedFontSize } from '../../../theming/fontSizeTranslator';
import { createStyleForBoxShadow } from '../../../theming/boxShadow';
import { getStyleBySize } from '../../../typography/semanticTags/semanticTags.utils';
import { Search } from '../search/Search';

const componentConfigThemeKey = 'errorPage';

interface ErrorComponentProps {
  image: Thumbnail;
  title: string;
  secondaryTitle: string;
  content: string;
  button: {
    text: string;
    href: string;
  };
  search?: {
    propertyEndpoint: string;
  };
}

interface ErrorComponentThemeProps {
  backgroundColorNormal: string;
  backgroundColorHover: string;
  backgroundColorActive: string;
  buttonTextColorNormal: string;
  buttonTextColorHover: string;
  buttonTextColorActive: string;
  borderRadius: number;
  borderWidthNormal: number;
  borderWidthHover: number;
  borderWidthActive: number;
  borderColorNormal: string;
  borderColorHover: string;
  borderColorActive: string;
  boxShadowTypeNormal: string;
  boxShadowTypeHover: string;
  boxShadowTypeActive: string;
  baseUnit: BaseUnit;
  fontSizesStyles: TranslatedFontSize;
  titleColor: string;
  textColor: string;
  buttonFontSizeLarge: TypographySize;
  buttonFontSizeMedium: TypographySize;
  buttonFontSizeSmall: TypographySize;
}

const createErrorStyle = (theme: ErrorComponentThemeProps) => {
  const { baseUnit,
    titleColor,
    textColor,
    buttonTextColorNormal,
    buttonTextColorHover,
    buttonTextColorActive,
    backgroundColorNormal,
    backgroundColorHover,
    backgroundColorActive,
    borderRadius,
  } = theme;

  return StyleSheet.create({
    wrapperStyle: {
      paddingTop: `${2 * baseUnit}px`,
    },
    title: {
      textAlign: 'center',
      color: titleColor,
    },
    secondaryTitle: {
      textAlign: 'center',
      color: titleColor,
      [MEDIA_BREAKPOINTS.large]: {
        marginTop: 0,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        marginTop: `${baseUnit / 2}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        marginTop: `${baseUnit / 2}px`,
      },
    },
    content: {
      textAlign: 'center',
      color: textColor,
    },
    buttonWrapper: {
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      marginTop: `${baseUnit}px`,
    },
    button: {
      display: 'flex',
      height: '40px',
      paddingRight: `${baseUnit}px`,
      paddingLeft: `${baseUnit}px`,
      alignItems: 'center',
      color: buttonTextColorNormal,
      ':hover': {
        backgroundColor: backgroundColorHover,
        color: buttonTextColorHover,
      },
      ':active': {
        backgroundColor: backgroundColorActive,
        color: buttonTextColorActive,
      },
      backgroundColor: backgroundColorNormal,
      borderRadius: `${borderRadius}px`,
      textDecoration: 'none',
    },
    searchWrapper: {
      padding: '40px 0px 20px',
      margin: 'auto',
      [MEDIA_BREAKPOINTS.large]: {
        width: '930px',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        width: '660px',
      },
      [MEDIA_BREAKPOINTS.small]: {
        width: '335px',
      },
    },
  });
};

const createImageErrorStyle = (theme: ErrorComponentThemeProps) => {
  const { baseUnit } = theme;
  return StyleSheet.create({
    style: {
      margin: `0px auto ${baseUnit}px auto`,
      [MEDIA_BREAKPOINTS.large]: {
        width: '300px',
        height: '300px',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        width: '180px',
        height: '180px',
      },
      [MEDIA_BREAKPOINTS.small]: {
        width: '180px',
        height: '180px',
      },
    },
  });
};

const imageWidths = createImageWidths(360, 360, 360);

export const Error: React.FunctionComponent<ErrorComponentProps> = props => {
  const { title, image, secondaryTitle, content, button, search } = props;
  const themeProps = useTheme<ErrorComponentThemeProps>(componentConfigThemeKey);
  const aspectRatio = { x: 1, y: 1 };
  const errorStyle = createErrorStyle(themeProps);
  const imageErrorStyle = createImageErrorStyle(themeProps) as AdditionalStyleSheet;
  const { fontSizesStyles, boxShadowTypeNormal, boxShadowTypeHover, boxShadowTypeActive, borderColorNormal, borderColorActive, borderColorHover, borderWidthNormal, borderWidthActive, borderWidthHover, buttonFontSizeLarge, buttonFontSizeMedium, buttonFontSizeSmall } = themeProps;
  const borderBoxShadow = {
    borderColorTypeActive: borderColorActive,
    borderColorTypeHover: borderColorHover,
    borderColorTypeNormal: borderColorNormal,
    borderWidthTypeActive: borderWidthActive,
    borderWidthTypeHover: borderWidthHover,
    borderWidthTypeNormal: borderWidthNormal,
  };
  const boxShadowStyle = createStyleForBoxShadow({ boxShadowTypeNormal, boxShadowTypeHover, boxShadowTypeActive, borderBoxShadow });
  const textStyle = getStyleBySize(fontSizesStyles, { fontSizeSmall: buttonFontSizeSmall, fontSizeMedium: buttonFontSizeMedium, fontSizeLarge: buttonFontSizeLarge });

  const onSearchClick = (searchQuery: string) => {
    if (search) {
      const url = new URL(`https://${search.propertyEndpoint}`);
      url.pathname += '/search';
      url.searchParams.set('query', searchQuery);
      window.location.href = url.href;
    }
  };

  return (
    <ErrorBoundary>
      <div className={css(errorStyle.wrapperStyle)}>
        <div className={css(imageErrorStyle.style)}>
          <Image {...image} additionalStyle={imageErrorStyle} aspectRatio={aspectRatio} imageWidths={imageWidths} transparent />
        </div>
        <H1 styles={semanticCSS(errorStyle.title)}>{title}</H1>
        <P styles={semanticCSS(errorStyle.secondaryTitle)}>{secondaryTitle}</P>
        <P styles={semanticCSS(errorStyle.content)}>{content}</P>
        <div className={css(errorStyle.buttonWrapper)}>
          <Link href={button.href} className={css(errorStyle.button, boxShadowStyle.style)}><Span className={css(textStyle)}>{button.text}</Span></Link>
        </div>
        {search
        && (
        <div className={css(errorStyle.searchWrapper)}>
          <Search onSearchClick={onSearchClick} />
        </div>
        )}
      </div>
    </ErrorBoundary>
  );
};
