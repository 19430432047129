import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { useTheme } from '../../../theming/useTheme';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { AdditionalStyleSheet } from '../../components.utils';
import { Button } from '../../components/buttons/Button';
import { ArticleBlockThemeProps, componentConfigurationKey } from '../ArticleBlock.theme';

interface SlideshoBottomBarProps {
  nextText: string;
  onClick: () => void;
  isDisabled: boolean;
}

const getStyles = (baseUnit: BaseUnit) => {
  return StyleSheet.create({
    wrapper: {
      display: 'flex',
      justifyContent: 'center',
      margin: 'auto',
      [MEDIA_BREAKPOINTS.large]: {
        marginTop: `${baseUnit * 4}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        marginTop: `${baseUnit * 2}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        marginTop: `${baseUnit}px`,
      },
    },
  });
};

const getLinkStyle = (
  baseUnit: BaseUnit,
  buttonDisabledBackgroundColor: string,
  buttonBackgroundColor: string,
  buttonDisabledTextColor: string,
  buttonTextColor: string,
  isDisabled: boolean,
) => {
  return StyleSheet.create({
    style: {
      padding: `${baseUnit / 2}px ${baseUnit * 4}px`,
      textAlign: 'center',
      backgroundColor: isDisabled ? buttonDisabledBackgroundColor : buttonBackgroundColor,
      color: isDisabled ? buttonDisabledTextColor : buttonTextColor,
    },
  }) as AdditionalStyleSheet;
};

export const SlideshowBottomBar: React.FunctionComponent<SlideshoBottomBarProps> = ({ nextText, isDisabled, onClick }) => {
  const { baseUnit, slideshowButtonBackgroundColor, slideshowButtonDisabledBackgroundColor, slideshowButtonTextColor, slideshowButtonDisabledTextColor } = useTheme<ArticleBlockThemeProps>(componentConfigurationKey);

  const styles = getStyles(baseUnit);
  const additionalStyle = getLinkStyle(baseUnit, slideshowButtonDisabledBackgroundColor, slideshowButtonBackgroundColor, slideshowButtonDisabledTextColor, slideshowButtonTextColor, isDisabled);

  return (
    <div className={css(styles.wrapper)}>
      <Button additionalStyle={additionalStyle} disabled={isDisabled} onClick={onClick} label={nextText} />
    </div>
  );
};
