import React from 'react';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { AMPVerticalCard } from '../../partials/cards/verticalCard/AMPVerticalCard';
import { ImportedEmbeddedContentWithCard, ImportedEmbeddedContentProps } from './ImportedEmbeddedContentWithCard';

export const AMPImportedEmbeddedContent: React.FunctionComponent<ImportedEmbeddedContentProps> = props => {
  const ImportedEmbeddedContentComponent = ImportedEmbeddedContentWithCard(AMPVerticalCard);
  return (
    <ErrorBoundary>
      <ImportedEmbeddedContentComponent {...props} />
    </ErrorBoundary>
  );
};
