import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useTheme } from '../../../theming/useTheme';
import { LinkImageProps, LinkImage } from './LinkImage';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { fourImageLinkConfigThemeKey, imageLinkConfigThemeKey, LinkImageThemeProps } from './LinkImage.theme';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { AdditionalStyleSheet } from '../../components.utils';

const getStyles = () => {
  return StyleSheet.create({
    gridContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      gridGap: '15px',
      [MEDIA_BREAKPOINTS.small]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
    },
  });
};

interface FourLinkImageLayoutProps {
  linkImages: LinkImageProps[];
  additionalStyle?: AdditionalStyleSheet;
}

export const FourLinkImageLayout: React.FunctionComponent<FourLinkImageLayoutProps> = ({ linkImages, additionalStyle }: FourLinkImageLayoutProps) => {
  const styles = getStyles();
  const fourLinkImageTheme = useTheme<LinkImageThemeProps>(fourImageLinkConfigThemeKey);
  const linkImageThemeOverride = useThemeOverride<LinkImageThemeProps>(fourLinkImageTheme, imageLinkConfigThemeKey);

  return (
    <ErrorBoundary>
      <div className={css(styles.gridContainer, additionalStyle?.style)}>
        {linkImages.map((linkImage, index) => (
          <div key={index}>
            <ThemeContext.Provider value={linkImageThemeOverride}>
              <LinkImage {...linkImage} />
            </ThemeContext.Provider>
          </div>
        ))}
      </div>
    </ErrorBoundary>
  );
};
