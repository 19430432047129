import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useTheme } from '../../../theming/useTheme';
import { ArticleBlockThemeProps, componentConfigurationKey } from '../ArticleBlock.theme';
import { getQuoteSvg } from './utils';
import { BaseUnit } from '../../../theming/baseUnitDefinition';

export const getStylesForQuoteIcon = (baseUnit: BaseUnit) => {
  return StyleSheet.create({
    quote: {
      margin: `0 ${baseUnit}px`,
    },
  });
};
export const AMPQuoteIconForDivider: React.FunctionComponent = () => {
  const { baseUnit, dividerLogoColor } = useTheme<ArticleBlockThemeProps>(componentConfigurationKey);
  const styles = getStylesForQuoteIcon(baseUnit);
  const svg = getQuoteSvg(dividerLogoColor);
  const imgSrc = `data:image/svg+xml,${encodeURIComponent(svg)}`;
  return (
    <React.Fragment>
      {/*
  // @ts-ignore TS2339. */}
      <amp-img
        class={css(styles.quote)}
        src={imgSrc}
        alt="Quote Icon"
        layout="fixed"
        width={baseUnit * 1.5}
        height={baseUnit * 1.5}
      />
    </React.Fragment>
  );
};
