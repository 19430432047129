import { CoreParams, init } from './init';
import { markPerformance } from './performance/index';
import { ErrorReporter } from './errorReporter.type';

declare global {
  interface Window {
    mmClientApi?: Array<any> | { push: (eventName: string, params: any) => void };
  }
}

export const initCore = (params: CoreParams, errorReporter: ErrorReporter) => {
  markPerformance('core-start');
  init(params, errorReporter);
};

export * from './init';
export { sendPageView } from './analytics/analytics';
