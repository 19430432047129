import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { useTheme } from '../../../theming/useTheme';
import { LinkWithButtonStyle } from './LinkWithButtonStyle';
import { AdditionalStyleSheet } from '../../components.utils';

const createStyles = (baseUnit: BaseUnit, isSingleButton: boolean) => StyleSheet.create({
  container: {
    display: 'grid',
    justifyContent: 'center',
    gridTemplateColumns: isSingleButton ? '1fr' : '1fr 1fr',
    margin: '0 auto',
    overflowAnchor: 'none',
    [MEDIA_BREAKPOINTS.large]: {
      marginTop: `${baseUnit * 2}px`,
      marginBottom: `${baseUnit}px`,
      columnGap: `${baseUnit * 0.75}px`,
      width: isSingleButton ? '350px' : '450px',
    },
    [MEDIA_BREAKPOINTS.medium]: {
      marginTop: `${baseUnit * 1.5}px`,
      marginBottom: `${baseUnit * 0.5}px`,
      columnGap: `${baseUnit * 0.75}px`,
      width: isSingleButton ? '350px' : '450px',
    },
    [MEDIA_BREAKPOINTS.small]: {
      marginTop: `${baseUnit}px`,
      marginBottom: `${baseUnit}px`,
      paddingLeft: '10px',
      paddingRight: '10px',
      columnGap: `${baseUnit / 2}px`,
      width: '-webkit-fill-available',
    },
  },
});

const linkPadding = StyleSheet.create({
  style: {
    padding: '8px 16px',
  },
}) as AdditionalStyleSheet;

interface PaginationButtonGroupProps {
    prevURL: string | null;
    nextURL: string | null;
    prevText: string;
    nextText: string;
}

interface ButtonGroupThemeProps {
    baseUnit: BaseUnit;
}

export const PaginationButtonGroup: React.FunctionComponent<PaginationButtonGroupProps> = ({ prevURL, nextURL, prevText, nextText }) => {
  const { baseUnit } = useTheme<ButtonGroupThemeProps>();
  const showPrev = !!prevURL;
  const showNext = !!nextURL;
  const style = createStyles(baseUnit, !(showPrev && showNext));

  return (
    <div className={css(style.container)}>
      {showPrev ? (
        <LinkWithButtonStyle href={prevURL || undefined} additionalStyle={linkPadding} secondary>
          {prevText}
        </LinkWithButtonStyle>
      ) : null }
      {showNext ? (
        <LinkWithButtonStyle href={nextURL || undefined} additionalStyle={linkPadding}>
          {nextText}
        </LinkWithButtonStyle>
      ) : null }
    </div>
  );
};
