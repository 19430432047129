import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { Link } from '../../../../partials/link/Link';
import { baseUnit } from '../../../../../theming/baseUnitDefinition';
import { MEDIA_BREAKPOINTS } from '../../../../../mediaQueries.const';
import {
  SocialSectionDataProps,
  SocialLink,
} from '../../footer/AdditionalFooterSections/FooterSocialLinks';

interface SocialLinksProps {
  socialLinks?: SocialLink[] | null;
  oldSocialSection?: SocialSectionDataProps| null;
  verticalGapInLargeScreen: number;
  reduceHeightAnimationDuration: number;
  socialLinksColor: string;
  socialLinksHoverColor: string;
}

const getStyles = (numSocialLinks: number, verticalGapInLargeScreen: number, reduceHeightAnimationDuration: number, socialLinksColor: string, socialLinksHoverColor: string) => StyleSheet.create({
  wrapper: {
    gridTemplateColumns: `repeat(${numSocialLinks}, 1fr)`,
    alignItems: 'center',
    textAlign: 'center',
    [MEDIA_BREAKPOINTS.large]: {
      display: 'grid',
      paddingLeft: `${baseUnit * verticalGapInLargeScreen}px`,
      gridColumnGap: `${baseUnit * verticalGapInLargeScreen}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      display: 'none',
    },
    [MEDIA_BREAKPOINTS.small]: {
      display: 'none',
    },
  },
  img: {
    height: `${baseUnit * 0.75}px`,
    transition: `${reduceHeightAnimationDuration}ms`,
  },
  svg: {
    height: `${baseUnit * 0.75}px`,
    transition: `${reduceHeightAnimationDuration}ms`,
    fill: socialLinksColor,
    ':hover': {
      fill: socialLinksHoverColor,
    },
  },
});

export const SocialLinks: React.FunctionComponent<SocialLinksProps> = props => {
  const { socialLinks, oldSocialSection, verticalGapInLargeScreen, reduceHeightAnimationDuration, socialLinksColor, socialLinksHoverColor } = props;

  // eslint-disable-next-line no-nested-ternary
  const socialLinksLength = socialLinks
    ? socialLinks.length
    : oldSocialSection
      ? oldSocialSection.imageLinks.length
      : 0;
  const styles = getStyles(socialLinksLength, verticalGapInLargeScreen, reduceHeightAnimationDuration, socialLinksColor, socialLinksHoverColor);
  return socialLinks
    ? (
      <div className={css(styles.wrapper)}>
        {
          socialLinks.map((link, index) => {
            const svg = link.src.replace(/<svg/g, `<svg class="${css(styles.svg)}"`);
            return (
              <Link href={link.href} key={index} rel="noopener" target="_blank" dangerouslySetInnerHTML={{ __html: svg }} alt={link.alt} />
            );
          })
        }
      </div>
    )
    : (
      <div className={css(styles.wrapper)}>
        {
          oldSocialSection && oldSocialSection.imageLinks.map((link, index) => (
            <Link href={link.href} key={index} rel="noopener" target="_blank">
              <img className={css(styles.img)} src={link.src} alt={link.alt} />
            </Link>
          ))
        }
      </div>
    );
};
