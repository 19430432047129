import { AppState, PostPageOdesaAppState } from '../../../initialStateRegistration';
import { createBlockNames } from '../utils/createBlockNames';

const getPostPage = (state: AppState) => (state as PostPageOdesaAppState).template;

export const getTitle = (state: AppState) => getPostPage(state).title;

export const getIntro = (state: AppState) => getPostPage(state).intro;

export const getBy = (state: AppState) => getPostPage(state).by;

export const getUpdatedText = (state: AppState) => getPostPage(state).updatedText;

export const getAuthors = (state: AppState) => getPostPage(state).authors;

export const getAuthorsUsernames = (state: AppState) => getPostPage(state).authors?.map(author => author.username) || [];

export const getOwner = (state: AppState) => getPostPage(state).owner;

export const getOwnerName = (state: AppState) => getOwner(state).name;

export const getOwnerUsername = (state: AppState) => getOwner(state).username;

export const getCover = (state: AppState) => getPostPage(state).cover;

export const getImageCover = (state: AppState) => getCover(state).image;

export const getBody = (state: AppState) => getPostPage(state).body;

export const getShareConfig = (state: AppState) => getPostPage(state).shareConfig;

export const getArticleId = (state: AppState) => getPostPage(state).articleId;

export const getBlockNames = (state: AppState) => createBlockNames(getBody(state));

export const getChannels = (state: AppState) => getPostPage(state).channels;

export const getCreatedAt = (state: AppState) => getPostPage(state).createdAt;

export const getCreatedAtISO = (state: AppState) => getPostPage(state).createdAtISO;

export const getUpdatedAt = (state: AppState) => getPostPage(state).updatedAt;

export const getUpdatedAtISO = (state: AppState) => getPostPage(state).updatedAtISO;

export const getShowUpdatedAt = (state: AppState) => getPostPage(state).showUpdatedAt;

export const getTags = (state: AppState) => getPostPage(state).tags;

export const getSponsor = (state: AppState) => getPostPage(state).sponsor;

export const getCommercialTags = (state: AppState) => getPostPage(state).commercialTags;

export const getDfpCategory = (state: AppState) => getPostPage(state).dfpCategory;

export const getNoIndex = (state: AppState) => getPostPage(state).noIndex;

export const getNumberOfWordsTyped = (state: AppState) => getPostPage(state).numberOfWordsTyped;

export const getContentTypesToQuantity = (state: AppState) => getPostPage(state).contentTypesToQuantity;

export const getOgDescription = (state: AppState) => getPostPage(state).ogDescription;

export const getOgTitle = (state: AppState) => getPostPage(state).ogTitle;

export const getOgImage = (state: AppState) => getPostPage(state).ogImage;

export const getTwitterDescription = (state: AppState) => getPostPage(state).twitterDescription;

export const getTwitterTitle = (state: AppState) => getPostPage(state).twitterTitle;

export const getTwitterImage = (state: AppState) => getPostPage(state).twitterImage;

export const getMetadataDescription = (state: AppState) => getPostPage(state).metadataDescription;

export const getSlideshowData = (state: AppState) => getPostPage(state).slideshow;

export const getBreadCrumbs = (state: AppState) => getPostPage(state).breadCrumbs;

export const getOpenWebId = (state: AppState) => getPostPage(state).openWebId;

export const getSeoDescription = (state: AppState) => getPostPage(state).seoDescription;

export const getSeoTitle = (state: AppState) => getPostPage(state).seoTitle;

export const getShowRecommendationsAdvertisement = (state: AppState) => getPostPage(state).showRecommendationsAdvertisement;

export const getOutbrainWidgetID = (state: AppState) => getPostPage(state).outbrainWidgetID;

export const getOutbrainFiniteScrollWidgetID = (state: AppState) => getPostPage(state).outbrainFiniteScrollWidgetID;

export const getMd5hashPostCanonicalUrl = (state: AppState) => getPostPage(state).md5hashPostCanonicalUrl;

export const getFiniteScrollArticles = (state: AppState) => getPostPage(state).finiteScrollArticles;

export const getLoadingArticles = (state: AppState) => getPostPage(state).loadingArticles;

export const getFiniteScrollArticlesURLs = (state: AppState) => getPostPage(state).finiteScrollArticlesURLs;

export const getDisclaimerText = (state: AppState) => getPostPage(state).disclaimerText;

export const getReviewDisclaimer = (state: AppState) => getPostPage(state).reviewDisclaimer;

export const getShouldDisplayDisclaimerAtBottom = (state: AppState) => getPostPage(state).shouldDisplayDisclaimerAtBottom;

export const getShouldAddMMPlayerPlaceholder = (state: AppState) => getPostPage(state).shouldAddMMPlayerPlaceholder;

export const getShouldSetMinHeightForTopAd = (state: AppState) => getPostPage(state).shouldSetMinHeightForTopAd;

export const getFiniteScrollDataExists = (state: AppState) => getPostPage(state).finiteScrollDataExists;

export const getFaqData = (state: AppState) => (getBody(state) || []).filter((block: any) => block.type === 'faq');

export const getLiveBlogData = (state: AppState) => (getBody(state) || []).filter((block: any) => block.type === 'live-blog');

export const getTopic = (state: AppState) => getPostPage(state).topic;

export const getCustomVertical = (state: AppState) => getPostPage(state).customVertical;

export const getShouldShowHeroImage = (state: AppState) => getPostPage(state).shouldShowHeroImage;
