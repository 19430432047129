import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useTheme } from '../../../theming/useTheme';
import { Link } from '../../partials/link/Link';
import { Image, ImageProps } from '../../partials/image/Image';
import { getStyleBySize } from '../../../typography/semanticTags/semanticTags.utils';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { imageLinkConfigThemeKey, LinkImageThemeProps } from './LinkImage.theme';
import { createImageAspectRatios, ImageAspectRatios } from '../../partials/image/image.utils';

export interface LinkImageProps extends ImageProps {
  linkSrc: string;
  text?: string;
  customRatios?: ImageAspectRatios;
}

const getStyles = (textColor: string, textColorHover: string) => {
  return StyleSheet.create({
    linkImageText: {
      color: textColor,
      ':hover': {
        color: textColorHover,
      },
      paddingBottom: '5px',
      paddingTop: '10px',
    },
  });
};

export const LinkImage: React.FunctionComponent<LinkImageProps> = props => {
  const { linkSrc, text, customRatios, ...imageProps } = props;
  const { small: ratioSmall, medium: ratioMedium, large: ratioLarge } = customRatios || {};
  const linkImageTheme = useTheme<LinkImageThemeProps>(imageLinkConfigThemeKey);
  const {
    textColor,
    textColorHover,
    fontSizeLarge,
    fontSizeMedium,
    fontSizeSmall,
    hasText,
    fontSizesStyles,
    aspectRatioLarge,
    aspectRatioMedium,
    aspectRatioSmall,
  } = linkImageTheme;

  const aspectRatio = createImageAspectRatios(ratioSmall || aspectRatioSmall, ratioMedium || aspectRatioMedium, ratioLarge || aspectRatioLarge);

  const fontStyle = getStyleBySize(fontSizesStyles, { fontSizeSmall, fontSizeMedium, fontSizeLarge });
  const styles = getStyles(textColor, textColorHover);
  return (
    <ErrorBoundary>
      <Link href={linkSrc}>
        <Image
          {...imageProps}
          imageAspectRatios={aspectRatio}
        />
        {hasText && text && <div className={css(styles.linkImageText, fontStyle)}>{text}</div>}
      </Link>
    </ErrorBoundary>
  );
};
