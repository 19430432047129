import React from 'react';
import { ImageLink } from '../image/image.types';
import { Link } from '../link/Link';

interface ConditionalLinkWrapperProps {
  link: ImageLink | null;
  dataId: string;
}
export const ConditionalImageLinkWrapper: React.FunctionComponent<ConditionalLinkWrapperProps> = props => {
  const { link, children, dataId } = props;
  const attributes = link ? {
    ...(link.relAttributeValue ? { rel: link.relAttributeValue } : {}),
    ...(link.targetAttributeValue ? { target: link.targetAttributeValue } : {}),
    'data-mm-id': dataId,
  } : {};
  return (
    link
      ? <Link href={link.linkURL} {...attributes}>{children}</Link>
      : <div data-mm-id={dataId}>{children}</div>
  );
};
