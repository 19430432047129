import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { MEDIA_BREAKPOINTS } from '../../../../../mediaQueries.const';
import { Author, CLIP_PATH_LARGE, CLIP_PATH_SMALL } from '../../author.utils';
import { Image } from '../../../../partials/image/Image';
import { createImageWidths } from '../../../../partials/image/image.utils';
import { AdditionalStyleSheet } from '../../../../components.utils';
import { BaseUnit } from '../../../../../theming/baseUnitDefinition';
import { useTheme } from '../../../../../theming/useTheme';

const getImageStyle = (baseUnit: BaseUnit) => StyleSheet.create({
  wrapper: {
    whiteSpace: 'nowrap',
    display: 'flex',
    [MEDIA_BREAKPOINTS.large]: {
      marginRight: `${baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      marginRight: `${baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      marginRight: `${baseUnit / 2}px`,
    },
  },
});

const getImageAdditionalStyle = () => {
  return StyleSheet.create({
    style: {
      borderRadius: '100%',
      display: 'block',
      overflow: 'hidden',
      [MEDIA_BREAKPOINTS.large]: {
        width: '70px',
        ':not(:first-child)': {
          clipPath: `url(#${CLIP_PATH_LARGE})`,
          marginLeft: '-10px',
        },
      },
      [MEDIA_BREAKPOINTS.medium]: {
        width: '70px',
        ':not(:first-child)': {
          clipPath: `url(#${CLIP_PATH_LARGE})`,
          marginLeft: '-10px',
        },
      },
      [MEDIA_BREAKPOINTS.small]: {
        width: '50px',
        ':not(:first-child)': {
          clipPath: `url(#${CLIP_PATH_SMALL})`,
          marginLeft: '-8px',
        },
      },
    },
  });
};

interface AuthorsWithRoundImageAuthorImagesProps {
  authors: Author[] | null;
  componentConfigurationKey: string;
}

interface AuthorsWithRoundImageAuthorImagesThemeProps {
  baseUnit: BaseUnit;
}

const AuthorsWithRoundImageAuthorImages = ({
  authors,
  componentConfigurationKey,
}: AuthorsWithRoundImageAuthorImagesProps) => {
  const { baseUnit } = useTheme<AuthorsWithRoundImageAuthorImagesThemeProps>(componentConfigurationKey);
  const imageWidths = createImageWidths(50, 50, 80);
  const additionalStyle = getImageAdditionalStyle() as AdditionalStyleSheet;
  const imageStyle = getImageStyle(baseUnit);

  return (
    <div className={css(imageStyle.wrapper)}>
      {authors
        && authors
          .filter(({ image }) => typeof image === 'object')
          .map((author: Author, index: number) => {
            return (
              author.image && (
                <Image
                  {...author.image}
                  imageWidths={imageWidths}
                  key={index}
                  additionalStyle={additionalStyle}
                  aspectRatio={{ x: 1, y: 1 }}
                />
              )
            );
          })}
    </div>
  );
};

export default AuthorsWithRoundImageAuthorImages;
