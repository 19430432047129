import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { TypographySize } from 'mm-theme-configuration/dist/consts';
import { MEDIA_BREAKPOINTS } from '../../../../../mediaQueries.const';
import { BaseUnit } from '../../../../../theming/baseUnitDefinition';
import { useTheme } from '../../../../../theming/useTheme';
import { NavigationLink } from '../oldHeader/primaryHeader/PrimaryHeader';
import { SubMenuReducerProps } from './subMenuReducer';
import { ErrorBoundary } from '../../../../errorHandling/ErrorBoundary';
import { RegisterLinkWithSubMenu } from './linkWithSubHeader.utils';
import { MoreButton } from './MoreButton';
import { AdditionalStyleSheet } from '../../../../components.utils';
import { LinkWithSubMenu } from './LinkWithSubMenu';
import { RegularHeaderLink } from './RegularHeaderLink';

interface FixedNavProps {
  rowHeight: number;
  navHeightLarge: number;
  ulRef: React.RefObject<HTMLUListElement>;
  links: NavigationLink[] | null;
  pagePath: string;
  activeStateColor: string;
  hoverStateColor: string;
  idleColor: string;
  currentLinkColor: string;
  linkFontSizeLarge: TypographySize;
  linkFontSizeMedium: TypographySize;
  linkFontSizeSmall: TypographySize;
  linkDropdownIconColor: string;
  subMenuState: SubMenuReducerProps;
  indexOfFirstInvisibleLink: number;
  linkBackgroundColor: string;
  moreButtonText: string;
  registerLinkWithSubMenu: RegisterLinkWithSubMenu;
}

const getLinkBackgroundOffset = (subMenuState: SubMenuReducerProps) => {
  if (subMenuState.element && subMenuState.index >= 0) {
    const offset = subMenuState.element.getBoundingClientRect().left;
    return offset;
  }

  return 0;
};

const getStyles = (navHeightLarge: number, baseUnit: BaseUnit, linkBackgroundColor: string, rowHeight: number, subMenuState: SubMenuReducerProps) => {
  const backgroundWidth = subMenuState.element ? subMenuState.element.getBoundingClientRect().width : 0;
  const elementOffset = getLinkBackgroundOffset(subMenuState);

  return StyleSheet.create({
    fixedNav: {
      height: `${navHeightLarge}px`,
      [MEDIA_BREAKPOINTS.large]: {
        display: 'block',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        display: 'none',
      },
      [MEDIA_BREAKPOINTS.small]: {
        display: 'none',
      },
    },
    navHiddenLayer: {
      overflow: 'hidden',
      height: `${navHeightLarge}px`,
    },
    menuContainer: {
      position: 'relative',
    },
    fixedUl: {
      display: 'inline',
      padding: 0,
    },
    menuWrapper: {
      position: 'relative',
    },
    linkBackground: {
      position: 'absolute',
      display: elementOffset === 0 ? 'none' : 'block',
      backgroundColor: linkBackgroundColor,
      height: `${rowHeight * baseUnit}px`,
      top: 0,
      left: `${elementOffset - baseUnit / 2}px`,
      width: `${backgroundWidth + baseUnit}px`,
      [MEDIA_BREAKPOINTS.medium]: {
        display: 'none',
      },
      [MEDIA_BREAKPOINTS.small]: {
        display: 'none',
      },
    },
  });
};

const getLinksStyle = (baseUnit: BaseUnit) => StyleSheet.create({
  li: {
    display: 'inline-block',
    position: 'relative',
    whiteSpace: 'nowrap',
    ':not(:first-child)': {
      marginLeft: `${baseUnit}px`,
    },
    ':last-child': {
      [MEDIA_BREAKPOINTS.medium]: {
        marginRight: `${baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        marginRight: `${baseUnit}px`,
      },
    },
    ':first-child': {
      [MEDIA_BREAKPOINTS.medium]: {
        marginLeft: `${baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        marginLeft: `${baseUnit}px`,
      },
    },
  },
});

const showMoreButtonStyle = (buttonOffset: number, shouldShowMoreButton: boolean): AdditionalStyleSheet => {
  return StyleSheet.create({
    style: {
      [MEDIA_BREAKPOINTS.large]: {
        display: shouldShowMoreButton ? 'flex' : 'none',
        transform: `translateX(-${buttonOffset}px)`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        display: 'none',
      },
      [MEDIA_BREAKPOINTS.small]: {
        display: 'none',
      },
    },
  }) as AdditionalStyleSheet;
};

type MoreButtonCreateMethod = (isMenuOpen: boolean, index: number) => React.ReactElement;

const getMoreButton = (
  ref: React.RefObject<HTMLButtonElement>,
  text: string,
  style: AdditionalStyleSheet,
  fontSizeSmall: TypographySize,
  fontSizeMedium: TypographySize,
  fontSizeLarge: TypographySize,
  hoverColor: string,
  normalColor: string,
  activeColor: string,
  iconColor: string,
  registerLinkWithSubMenu: RegisterLinkWithSubMenu,
) => (isMenuOpen: boolean, index: number) => {
  return (
    <MoreButton
      refObject={ref}
      moreButtonText={text}
      additionalStyle={style}
      fontSizeSmall={fontSizeSmall}
      fontSizeMedium={fontSizeMedium}
      fontSizeLarge={fontSizeLarge}
      colorTypeHover={hoverColor}
      colorTypeNormal={normalColor}
      colorTypeActive={activeColor}
      dropdownIconColor={iconColor}
      registerLinkWithSubMenu={registerLinkWithSubMenu}
      index={index}
      isMenuOpen={isMenuOpen}
    />
  );
};

const getLink = (
  hasSubMenu: boolean,
  currentLinkColor: string,
  activeStateColor: string,
  hoverStateColor: string,
  idleColor: string,
  linkDropdownIconColor: string,
  isActive: boolean,
  href: string,
  title: string,
  openLinkIndex: number,
  index: number,
  indexOfFirstInvisibleLink: number,
  linkFontSizeSmall: TypographySize,
  linkFontSizeMedium: TypographySize,
  linkFontSizeLarge: TypographySize,
  registerLinkWithSubMenu: RegisterLinkWithSubMenu,
  subLinks: NavigationLink[],
) => {
  const fontStyles = { linkFontSizeSmall, linkFontSizeMedium, linkFontSizeLarge };
  return hasSubMenu
    ? <LinkWithSubMenu currentLinkColor={currentLinkColor} activeStateColor={activeStateColor} title={title} hoverStateColor={hoverStateColor} idleColor={openLinkIndex === index ? hoverStateColor : idleColor} index={index} linkDropdownIconColor={linkDropdownIconColor} {...fontStyles} isInvisibleLink={indexOfFirstInvisibleLink < index || indexOfFirstInvisibleLink === index} isOpen={index === openLinkIndex} registerLinkWithSubMenu={registerLinkWithSubMenu} href={href} subLinks={subLinks} />
    : <RegularHeaderLink currentLinkColor={currentLinkColor} activeStateColor={activeStateColor} hoverStateColor={hoverStateColor} idleColor={idleColor} isActive={isActive} href={href} text={title} {...fontStyles} isInvisibleLink={indexOfFirstInvisibleLink < index || indexOfFirstInvisibleLink === index} />;
};

const getLinkList = (props: FixedNavProps, baseUnit: BaseUnit, buttonRef: React.RefObject<HTMLButtonElement>, moreButtonCreateMethod: MoreButtonCreateMethod) => {
  const {
    links,
    pagePath,
    currentLinkColor,
    activeStateColor,
    hoverStateColor,
    idleColor,
    linkDropdownIconColor,
    linkFontSizeSmall,
    linkFontSizeMedium,
    linkFontSizeLarge,
    indexOfFirstInvisibleLink,
    subMenuState,
    registerLinkWithSubMenu,
  } = props;
  if (!links) return [];

  const openLinkIndex = subMenuState.index;
  const isMoreLinksOpen = subMenuState.element === buttonRef.current;

  return links.map((link, index) => {
    const isActive = pagePath === link.link.href;
    const style = getLinksStyle(baseUnit);
    const hasSubMenu = (link.children || []).length > 0;
    const showMenu = index === indexOfFirstInvisibleLink;

    return (
      <React.Fragment key={index}>
        {
          showMenu && (
            <li className={css(style.li)}>
              {moreButtonCreateMethod(isMoreLinksOpen, index)}
            </li>
          )
        }
        <li className={css(style.li)}>
          {
            getLink(hasSubMenu, currentLinkColor, activeStateColor, hoverStateColor, idleColor, linkDropdownIconColor, isActive, link.link.href, link.link.text, openLinkIndex, index, indexOfFirstInvisibleLink, linkFontSizeSmall, linkFontSizeMedium, linkFontSizeLarge, registerLinkWithSubMenu, link.children || [])
          }
        </li>
      </React.Fragment>
    );
  });
};

export const FixedNav: React.FunctionComponent<FixedNavProps> = props => {
  const { baseUnit } = useTheme();
  const { navHeightLarge, ulRef, rowHeight, subMenuState, linkBackgroundColor, linkFontSizeSmall, linkFontSizeMedium, linkFontSizeLarge, hoverStateColor, idleColor, activeStateColor, linkDropdownIconColor, moreButtonText, registerLinkWithSubMenu } = props;

  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const buttonStyle = showMoreButtonStyle(0, true);
  const moreButtonMethod = getMoreButton(buttonRef, moreButtonText, buttonStyle, linkFontSizeSmall, linkFontSizeMedium, linkFontSizeLarge, hoverStateColor, idleColor, activeStateColor, linkDropdownIconColor, registerLinkWithSubMenu);

  const styles = getStyles(navHeightLarge, baseUnit, linkBackgroundColor, rowHeight, subMenuState);
  return (
    <ErrorBoundary>
      <div className={css(styles.linkBackground)} />
      <nav className={css(styles.fixedNav)}>
        <div className={css(styles.navHiddenLayer)}>
          <ul className={css(styles.fixedUl)} ref={ulRef}>
            {getLinkList(props, baseUnit, buttonRef, moreButtonMethod)}
          </ul>
        </div>
      </nav>
    </ErrorBoundary>
  );
};
