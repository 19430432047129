const getURLObject = () => new URL(window.location.href);

const getURLQueryParam = (key: string) => {
  return getURLObject().searchParams.get(key);
};

const getURLPathnameValueAfterKey = (key: string) => {
  const pathnameParts = getURLObject().pathname.split('/');
  const indexOfKey = pathnameParts.indexOf(key);
  return indexOfKey !== -1 ? pathnameParts[indexOfKey + 1] : null;
};

const getAffiliateId = () => getURLQueryParam('a_aid') || getURLPathnameValueAfterKey('partners');
const getTest = () => getURLQueryParam('test');

export const getPageURL = () => ({
  affiliateId: getAffiliateId(),
  test: getTest(),
});
