export const GET_PAGE_DATA = 'getPageData' as const;
export const GET_PALETTE_DATA = 'getPaletteData' as const;
export const INJECT_ARTICLE_BLOCK_BY_INDEX_ARRAY = 'injectArticleBlockByIndexArray' as const;
export const INJECT_VIDEO_LOGO = 'injectVideoLogo' as const;
export const INJECT_BUTTON_TO_FOOTER_LINKS_SECTION = 'injectButtonToFooterLinksSection' as const;
export const INJECT_BUTTON_TO_MENU = 'injectButtonToMenu' as const;
export const INJECT_ACCESSIBILITY_ICON_TO_HEADER = 'injectAccessibilityIcon' as const;
export const INJECT_ADS = 'injectAdPlaceholders';

export type ClientAPIEventNames =
  typeof GET_PAGE_DATA |
  typeof GET_PALETTE_DATA |
  typeof INJECT_ARTICLE_BLOCK_BY_INDEX_ARRAY |
  typeof INJECT_VIDEO_LOGO |
  typeof INJECT_BUTTON_TO_FOOTER_LINKS_SECTION |
  typeof INJECT_BUTTON_TO_MENU |
  typeof INJECT_ACCESSIBILITY_ICON_TO_HEADER
| typeof INJECT_ADS;
