import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { useTheme } from '../../../theming/useTheme';

const componentConfigurationKey = 'copyLinkButton';

interface CopyLinkButtonDataProps {
  canonical: string;
  buttonClass: string;
}

interface CopyLinkButtonThemeProps {
  color: string;
}

const getStyles = () => {
  return StyleSheet.create({
    copyLinkIcon: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: '16px',
      transition: '200ms ease-in-out',
    },
    copyLinkIconAction: {
      left: '-150%',
    },
    successIcon: {
      left: '150%',
    },
    successIconAction: {
      left: '50%',
    },
  });
};

const copyToClipboard = (text: string) => {
  const el = document.createElement('textarea');
  el.value = text;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.opacity = '0';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const CopyLinkButton: React.FunctionComponent<CopyLinkButtonDataProps> = props => {
  const { canonical, buttonClass } = props;
  const { color } = useTheme<CopyLinkButtonThemeProps>(componentConfigurationKey);
  const styles = getStyles();
  let timeout: NodeJS.Timeout;

  const [wasCopied, setWasCopied] = React.useState(false);

  const onClickCopy = () => {
    copyToClipboard(canonical);
    clearTimeout(timeout);
    setWasCopied(true);
    timeout = setTimeout(() => {
      setWasCopied(false);
    }, 1000);
  };

  const copyIconAction = wasCopied ? styles.copyLinkIconAction : styles.copyLinkIcon;
  const succcessIconAction = wasCopied ? styles.successIconAction : styles.successIcon;

  return (
    <ErrorBoundary>
      <button type="button" className={buttonClass} onClick={onClickCopy}>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" className={css(styles.copyLinkIcon, copyIconAction)}>
          <g fill={color}>
            <path d="M4.3,49.8c-0.9,0-1.8-0.3-2.6-0.9C0.6,48.1,0,46.8,0,45.5L0,4.3C0,1.9,1.9,0,4.3,0l41.2,0c2.4,0,4.3,1.9,4.3,4.3l0,6.5 l-7.1,0l0-3.7l-35.6,0l0,35.5l3.8,0l0,7.1l-6.3,0C4.5,49.8,4.4,49.8,4.3,49.8z" />
            <path d="M18.6,64c-2.4,0-4.3-1.9-4.3-4.3l0-41.1c0-2.4,1.9-4.3,4.3-4.3l41,0c2.4,0,4.3,1.9,4.3,4.3L64,59.7c0,2.4-1.9,4.3-4.3,4.3 L18.6,64z M21.3,21.3l0,35.5l35.5,0l-0.1-35.5L21.3,21.3z M56.8,18.5C56.8,18.5,56.8,18.5,56.8,18.5L56.8,18.5z" />
          </g>
        </svg>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" className={css(styles.copyLinkIcon, succcessIconAction)}>
          <g fill={color}>
            <path d="M59.5,10.8" />
            <path d="M23.1,56L1,32.9c-1.4-1.4-1.3-3.7,0.1-5.1c1.4-1.4,3.7-1.4,5,0.1l17,17.8L57.9,9.1c1.4-1.4,3.6-1.5,5-0.1 c1.4,1.4,1.4,3.7,0.1,5.1L23.1,56z" />
            <path d="M23.4,43.4" />
          </g>
        </svg>
      </button>
    </ErrorBoundary>
  );
};
