import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { TypographySize } from 'mm-theme-configuration/src/consts';
import { ErrorBoundary } from '../errorHandling/ErrorBoundary';
import { semanticCSS, Span } from '../../typography/semanticTags';
import { useTheme } from '../../theming/useTheme';
import { getFormattedPublishDate } from '../../utils/time.utils';

const componentConfigurationKey = 'articleTitle';
interface ArticleTitleThemeProps {
  metaDataColor: string;
  fontSizeSmall: TypographySize;
}

interface PublishDateProps {
  updatedAt: string;
  updatedAtISO: string;
  showUpdatedAt: boolean;
  createdAt: string;
  createdAtISO: string;
  useFormatMinutesHoursAgo?: boolean;
}

const createStyles = (metaDataColor: string) => StyleSheet.create({
  divider: {
    margin: '5px',
  },
  container: {
    margin: '20px 0',
  },
  text: {
    color: metaDataColor,
  },
});

export const PostDates = (props: PublishDateProps) => {
  const { updatedAt, updatedAtISO, createdAtISO, createdAt, showUpdatedAt, useFormatMinutesHoursAgo = false } = props;
  const { metaDataColor, fontSizeSmall } = useTheme<ArticleTitleThemeProps>(
    componentConfigurationKey,
  );
  const style = createStyles(metaDataColor);

  return (
    <ErrorBoundary>
      <div className={css(style.container)}>
        <Span
          styles={semanticCSS(style.text)}
          fontSizeSmall={fontSizeSmall}
          fontSizeMedium={fontSizeSmall}
          fontSizeLarge={fontSizeSmall}
        >
          Published
          {' '}
          <time dateTime={createdAtISO}>{getFormattedPublishDate(createdAtISO, createdAt, useFormatMinutesHoursAgo)}</time>
        </Span>
        {showUpdatedAt ? (
          <>
            <Span
              styles={semanticCSS(style.text, style.divider)}
              fontSizeSmall={fontSizeSmall}
              fontSizeMedium={fontSizeSmall}
              fontSizeLarge={fontSizeSmall}
            >
              |
            </Span>
            <Span
              styles={semanticCSS(style.text)}
              fontSizeSmall={fontSizeSmall}
              fontSizeMedium={fontSizeSmall}
              fontSizeLarge={fontSizeSmall}
            >
              Modified
              {' '}
              <time dateTime={updatedAtISO}>{updatedAt}</time>
            </Span>
          </>
        ) : null}
      </div>
    </ErrorBoundary>
  );
};
