import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useInView } from 'react-intersection-observer';
import { MandatoryAdditionalStyle } from '../../components.utils';
import { useTheme } from '../../../theming/useTheme';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { ArticleBlockThemeProps, componentConfigurationKey } from '../ArticleBlock.theme';

export interface IframeDataProps {
  html: string;
  dataId?: string;
}

type IframeComponentProps = IframeDataProps & MandatoryAdditionalStyle;
export type IframeProps = IframeDataProps & MandatoryAdditionalStyle;


const createIframeWrapperStyle = (backgroundColor: string) => StyleSheet.create({
  style: {
    backgroundColor,
    paddingTop: `calc(100% / (${16 / 9}))`,
    position: 'relative',
  },
});

const createIframeStyle = () => StyleSheet.create({
  style: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
});

const parseIframeHtml = (html: string) => {
  const iframeStyle = createIframeStyle();
  return html
    .replace(/<iframe/, `<iframe class="${css(iframeStyle.style)}"`);
};

export const Iframe: React.FunctionComponent<IframeComponentProps> = props => {
  const { html, additionalStyle, dataId } = props;
  const { placeholderBackgroundColor } = useTheme<ArticleBlockThemeProps>(componentConfigurationKey);
  const iframeWrapperStyle = createIframeWrapperStyle(placeholderBackgroundColor);

  const { ref, inView } = useInView({
    root: null,
    rootMargin: '50px 0px 0px 0px',
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <ErrorBoundary>
      <div ref={ref} className={css(additionalStyle.style)} data-mm-id={dataId} data-mm-type="embed">
        {/* eslint-disable-next-line react/no-danger */}
        {inView
          && (
          <figure
            className={css(iframeWrapperStyle.style)}
            dangerouslySetInnerHTML={{ __html: parseIframeHtml(html) }}
          />
          )}
      </div>
    </ErrorBoundary>
  );
};
