import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { injectScriptTagToHead, MandatoryAdditionalStyle } from '../../components.utils';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { BlockWidths } from '../../components/pageLayouts/BlockTypes.utils';
import { createBlockStyle } from '../blockMutualStyle';
import { Kasda } from '../../../Kasda/Kasda';

interface ContentEnrichmentDataProps {
    html: string;
    dataId?: string;
    defaultBlocksWidths: BlockWidths;
}

type ContentEnrichmentProps = ContentEnrichmentDataProps & MandatoryAdditionalStyle;

declare global {
  interface Window {
    __mm_content_enrichment__?: any;
  }
}

const getStyle = (defaultBlocksWidths: BlockWidths) => {
  return StyleSheet.create({
    style: {
      ...createBlockStyle(defaultBlocksWidths),
    },
  }).style;
};

function renderContentEnrichmentEmbeds() {
  // eslint-disable-next-line no-underscore-dangle
  if (window.__mm_content_enrichment__) {
    // eslint-disable-next-line no-underscore-dangle
    window.__mm_content_enrichment__.renderEmbeds();
  } else {
    injectScriptTagToHead('https://assets.minutemediacdn.com/content-enrichment/embeds.js');
  }
}

export const ContentEnrichment: React.FunctionComponent<ContentEnrichmentProps> = props => {
  const { html, additionalStyle, dataId, defaultBlocksWidths } = props;
  const style = getStyle(defaultBlocksWidths);

  React.useEffect(() => {
    renderContentEnrichmentEmbeds();
  }, []);

  return (
    <ErrorBoundary>
      <Kasda.Script>
        <script async src="https://assets.minutemediacdn.com/content-enrichment/embeds.js" />
      </Kasda.Script>
      <div className={css(additionalStyle.style, style)}>
        {/* eslint-disable-next-line react/no-danger */}
        <figure data-mm-id={dataId} dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </ErrorBoundary>
  );
};
