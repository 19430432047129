import React, { useCallback, useEffect, useRef, useState } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Colors } from 'mm-theme-configuration';
import { RATIO, TYPOGRAPHY_SIZE } from 'mm-theme-configuration/dist/consts';
import { useTheme } from '../../../theming/useTheme';
import { carouselConfigThemeKey, CarouselThemeProps } from './Carousel.theme';
import { VerticalCard } from '../../partials/cards/verticalCard/VerticalCard';
import { LinkImage, LinkImageProps } from '../../components/linkImage/LinkImage';
import { ArrowSvg } from './ArrowSvg';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import {
  componentConfigThemeKey as verticalCardConfigThemeKey,
  VerticalCardThemeProps,
} from '../../partials/cards/verticalCard/VerticalCard.theme';
import { imageLinkConfigThemeKey, LinkImageThemeProps } from '../linkImage/LinkImage.theme';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { createImageWidths } from '../../partials/image/image.utils';

export type CarouselItemType = 'LinkImage' | 'VerticalCard';
export type CarouselItemProps = CardComponentDataProps | LinkImageProps
const CARD_RATIO = { x: 16, y: 9 } as RATIO;
const IMAGE_LINK_RATIO = { x: 1, y: 1 } as RATIO;
const imageWidths = createImageWidths(360, 360, 360);

interface CarouselProps {
    itemType: CarouselItemType;
    itemsProps: CarouselItemProps[];
    useFormatMinutesHoursAgo?: boolean;
}

const isLinkImageProps = (itemType: CarouselItemType) => {
  return itemType === 'LinkImage';
};

const getStyles = (colors: Colors, themeColors: Partial<CarouselThemeProps>, itemType: CarouselItemType, carouselImageHasText: boolean) => {
  const iconImageBottom = carouselImageHasText ? '150px' : '120px';
  return StyleSheet.create({

    carouselContainer: {
      maxWidth: '1080px',
      display: 'grid',
    },
    buttonWrapper: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      position: 'relative',
      bottom: isLinkImageProps(itemType) ? iconImageBottom : '190px',
    },
    carouselHeader: {
      marginBottom: '20px',
      color: colors.secondary,
      display: 'flex',
      justifyContent: 'space-between',
    },

    carouselMainContent: {
      position: 'relative',
      width: '100%',
    },

    carouselItemsContainer: {
      '-webkit-overflow-scrolling': 'touch',
      display: 'flex',
      flexWrap: 'nowrap',
      columnGap: '15px',
      width: '100%',
      overflowX: 'scroll',
      boxSizing: 'border-box',
      scrollBehavior: 'smooth',
      scrollbarWidth: 'none',
      paddingBottom: '5px',
    },

    carouselSingleItemContainer: {
      flex: '0 0 auto',
      width: isLinkImageProps(itemType) ? '180px' : '306px',
    },

    arrowButton: {
      position: 'absolute',
      height: '48px',
      width: '48px',
      borderRadius: '50%',
      backgroundColor: themeColors.arrowButtonColorNormal,
      opacity: 0.85,
      zIndex: 100,
      top: '50%',
      transform: 'translateY(-50%)',
      boxShadow: '0px 0px 8px 0px #00000026',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      ':hover': {
        backgroundColor: themeColors.arrowButtonColorHover,
        opacity: 1,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        display: 'none',
      },
      [MEDIA_BREAKPOINTS.small]: {
        display: 'none',
      },
    },

    arrowPrev: {
      left: '0',
      transform: 'translateX(-24px) rotate(180deg)',
    },
    arrowNext: {
      right: '0',
      transform: 'translateX(24px)',
    },


  });
};


export const Carousel: React.FunctionComponent<CarouselProps> = (
  { itemType, itemsProps, useFormatMinutesHoursAgo },
) => {
  const { colors }: { colors: Colors } = useTheme();
  const ref = useRef<HTMLDivElement>(null);
  const itemRef = useRef<HTMLDivElement>(null);
  const [showPrev, setShowPrev] = useState(false);
  const [showNext, setShowNext] = useState(true);
  const {
    arrowButtonColorNormal,
    arrowButtonColorHover,
    arrowIconColor,
    carouselImageHasText,
  } = useTheme<CarouselThemeProps>(carouselConfigThemeKey);
  const themeCardData = {
    showAuthorInLargeScreen: true,
    showAuthorInMediumScreen: true,
    showAuthorInSmallScreen: true,
    fontSizeLarge: TYPOGRAPHY_SIZE.BIG,
    fontSizeMedium: TYPOGRAPHY_SIZE.BIG,
    fontSizeSmall: TYPOGRAPHY_SIZE.BIG,
    metadataFontSizeLarge: TYPOGRAPHY_SIZE.TINY,
    metadataFontSizeMedium: TYPOGRAPHY_SIZE.TINY,
    metadataFontSizeSmall: TYPOGRAPHY_SIZE.TINY,
    cardTitleColorTypeHover: colors.primary,
    cardTitleColorTypeNormal: colors.secondary,
    titleFontSizeMedium: TYPOGRAPHY_SIZE.BIG,
    titleFontSizeSmall: TYPOGRAPHY_SIZE.BIG,
    titleFontSizeLarge: TYPOGRAPHY_SIZE.BIG,
    aspectRatioLarge: CARD_RATIO,
    aspectRatioMedium: CARD_RATIO,
    aspectRatioSmall: CARD_RATIO,
    authorColor: colors.grey,
    authorColorHover: colors.primary,
  };

  const themeImageData = {
    hasText: carouselImageHasText,
    aspectRatioLarge: IMAGE_LINK_RATIO,
    aspectRatioMedium: IMAGE_LINK_RATIO,
    aspectRatioSmall: IMAGE_LINK_RATIO,
  };

  const verticalCardTheme = useThemeOverride<VerticalCardThemeProps>(themeCardData, verticalCardConfigThemeKey);
  const linkImageTheme = useThemeOverride<LinkImageThemeProps>(themeImageData, imageLinkConfigThemeKey);

  const themeColors = { arrowButtonColorNormal, arrowButtonColorHover };

  const renderItem = (itemProps: CarouselItemProps, index: number) => {
    if (isLinkImageProps(itemType)) {
      return (
        <ThemeContext.Provider value={linkImageTheme}>
          <LinkImage key={index} {...itemProps as LinkImageProps} />
        </ThemeContext.Provider>
      );
    }
    return (
      <ThemeContext.Provider value={verticalCardTheme}>
        <VerticalCard key={index} {...itemProps as CardComponentDataProps} imageWidths={imageWidths} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />
      </ThemeContext.Provider>
    );
  };

  const styles = getStyles(colors, themeColors, itemType, carouselImageHasText);

  const onNextClick = () => {
    if (ref?.current && itemRef?.current) {
      const cardSize = itemRef?.current?.offsetWidth;
      ref.current.scrollLeft += cardSize + 15;
    }
  };

  const onPrevClick = () => {
    if (ref?.current && itemRef?.current) {
      const cardSize = itemRef?.current?.offsetWidth;
      ref.current.scrollLeft -= cardSize + 15;
    }
  };


  const setButtonsVisability = useCallback(() => {
    if (ref?.current) {
      setShowPrev(ref.current.scrollLeft > 0);
      setShowNext(ref.current.scrollLeft < ref.current.scrollWidth - ref.current.offsetWidth);
    }
  }, []);

  useEffect(() => {
    const wrapperRef = ref.current;
    if (wrapperRef) {
      wrapperRef.addEventListener('scroll', setButtonsVisability);
    }
    return () => {
      return wrapperRef?.removeEventListener('scroll', setButtonsVisability);
    };
  }, [setButtonsVisability]);

  useEffect(() => {
    window.addEventListener('resize', setButtonsVisability);
    return () => {
      window.removeEventListener('resize', setButtonsVisability);
    };
  }, [setButtonsVisability]);

  return (
    <div className={css(styles.carouselContainer)}>
      <div className={css(styles.carouselMainContent, styles.carouselItemsContainer)} ref={ref}>
        {itemsProps.map((itemProps, index) => (
          <div
            key={index}
            className={css(styles.carouselSingleItemContainer)}
            ref={itemRef}
          >
            {renderItem(itemProps, index)}
          </div>
        ))}
      </div>
      <div className={css(styles.buttonWrapper)}>
        {showPrev && (
        <div className={css([styles.arrowButton, styles.arrowPrev])} onClick={onPrevClick}>
          <ArrowSvg
            arrowIconColor={arrowIconColor}
          />
        </div>
        )}
        {showNext
          && (
          <div className={css([styles.arrowButton, styles.arrowNext])} onClick={onNextClick}>
            <ArrowSvg
              arrowIconColor={arrowIconColor}
            />
          </div>
          )}
      </div>
    </div>
  );
};
