import * as React from 'react';
import { StyleSheet } from 'aphrodite/no-important';
import { useTheme } from '../../../theming/useTheme';
import { P, semanticCSS } from '../../../typography/semanticTags';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { AuthorPremiumThemeProps, componentConfigThemeKey } from './AuthorPremium.theme';

interface AuthorPremiumBylineProps {
  byline: string;
}

const getStyles = (color: string, baseUnit: BaseUnit) => StyleSheet.create({
  p: {
    color,
    marginTop: `${baseUnit / 2}px`,
  },
});

export const AuthorPremiumByline: React.FunctionComponent<AuthorPremiumBylineProps> = ({ byline }) => {
  const { baseUnit, bylineTextColor, bylineFontSizeLarge, bylineFontSizeMedium, bylineFontSizeSmall } = useTheme<AuthorPremiumThemeProps>(componentConfigThemeKey);
  const styles = getStyles(bylineTextColor, baseUnit);
  return <P styles={semanticCSS(styles.p)} fontSizeLarge={bylineFontSizeLarge} fontSizeMedium={bylineFontSizeMedium} fontSizeSmall={bylineFontSizeSmall}>{byline}</P>;
};
