import React, { useEffect, useState } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { MEDIA_BREAKPOINTS } from '../../mediaQueries.const';
import { BlockWidths } from '../components/pageLayouts/BlockTypes.utils';
import { AdditionalStyleSheet, getIsMobileClient } from '../components.utils';
import { createBlockStyle } from '../articleComponents/blockMutualStyle';

interface MMPlayerPlaceHolderProps {
  renderForMobile?: boolean;
  renderForTabletAndDesktop?: boolean;
  defaultBlocksWidths?: BlockWidths;
  additionalStyle?: AdditionalStyleSheet;
  mmVideoPlaceholderId?: string;
  isMobileViewer: boolean;
}

const videoRatio = {
  x: 16,
  y: 9,
};

const getDefaultBlocksWidthsStyle = (defaultBlocksWidths: BlockWidths) => {
  return StyleSheet.create({
    style: {
      ...createBlockStyle(defaultBlocksWidths),
    },
  }).style;
};

const createWrapperStyle = () => StyleSheet.create({
  style: {
    backgroundColor: 'transparent',
    position: 'relative',
    overflow: 'hidden',
    boxSizing: 'border-box',
    minWidth: '0%',
    [MEDIA_BREAKPOINTS.large]: {
      paddingTop: `calc(100% / (${videoRatio.x / videoRatio.y}))`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      paddingTop: `calc(100% / (${videoRatio.x / videoRatio.y}))`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      paddingTop: `calc(100% / (${videoRatio.x / videoRatio.y}))`,
    },
  },
}).style;

const createPlaceholderStyle = () => StyleSheet.create({
  style: {
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    minWidth: '100%',
    minHeight: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
  },
}).style;

const createDisplayStyleByScreenSize = (isMobile: boolean, renderForMobile: boolean, renderForTabletAndDesktop: boolean) => StyleSheet.create({
  style: {
    display: ((!isMobile && renderForTabletAndDesktop) || (isMobile && renderForMobile)) ? 'block' : 'none',
  },
}).style;

const BASE_ID = 'mm-player-placeholder';
const createMMVideoPlaceholderId = (renderForMobile: boolean, mmVideoPlaceholderId?: string) => {
  if (mmVideoPlaceholderId) {
    return mmVideoPlaceholderId;
  }
  return renderForMobile ? `${BASE_ID}-smallAndMedium-screens` : `${BASE_ID}-large-screen`;
};
export const MMPlayerPlaceHolder: React.FunctionComponent<MMPlayerPlaceHolderProps> = props => {
  const { renderForMobile = false, renderForTabletAndDesktop = false, additionalStyle, defaultBlocksWidths, mmVideoPlaceholderId, isMobileViewer } = props;
  const wrapperStyle = createWrapperStyle();
  const placeholderStyle = createPlaceholderStyle();
  const id = createMMVideoPlaceholderId(renderForMobile, mmVideoPlaceholderId);
  const [isMobile, setIsMobile] = useState(isMobileViewer);
  const displayStyle = createDisplayStyleByScreenSize(isMobile, renderForMobile, renderForTabletAndDesktop);

  useEffect(() => {
    setIsMobile(getIsMobileClient());
  }, []);

  return (!renderForMobile && !renderForTabletAndDesktop) || (renderForMobile && renderForTabletAndDesktop) ? null : (
    <figure className={css(defaultBlocksWidths ? getDefaultBlocksWidthsStyle(defaultBlocksWidths) : null, displayStyle)}>
      <div className={css(wrapperStyle, additionalStyle ? additionalStyle.style : null, displayStyle)}>
        <div id={id} className={css(placeholderStyle, displayStyle)} />
      </div>
    </figure>
  );
};
