import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { MEDIA_BREAKPOINTS } from '../../../../../mediaQueries.const';
import { baseUnit } from '../../../../../theming/baseUnitDefinition';

interface ItemsWithSeparatorProps {
  verticalGapInSmallScreen: number;
  verticalGapInMediumScreen: number;
  verticalGapInLargeScreen: number;
  separatorColor: string;
  numberOfItems: number;
}

const createStyles = (
  verticalGapInSmallScreen: number,
  verticalGapInMediumScreen: number,
  verticalGapInLargeScreen: number,
  separatorColor: string,
  numberOfItems: number,
) => StyleSheet.create({
  wrapper: {
    position: 'relative',
    height: '100%',
    display: 'grid',
    alignItems: 'center',
    [MEDIA_BREAKPOINTS.large]: {
      gridColumnGap: `${verticalGapInLargeScreen * baseUnit}px`,
      gridTemplateColumns: `repeat(${numberOfItems + 1}, auto)`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      marginLeft: `${verticalGapInMediumScreen * baseUnit}px`,
      gridColumnGap: `${verticalGapInMediumScreen * baseUnit}px`,
      gridTemplateColumns: `repeat(${numberOfItems + 1}, auto)`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      marginLeft: `${verticalGapInSmallScreen * baseUnit}px`,
      gridColumnGap: `${verticalGapInSmallScreen * baseUnit}px`,
      gridTemplateColumns: `repeat(${numberOfItems}, auto)`,
    },
  },
  separator: {
    backgroundColor: separatorColor,
    height: '50%',
    width: '1px',
    [MEDIA_BREAKPOINTS.medium]: {
      display: 'none',
    },
    [MEDIA_BREAKPOINTS.small]: {
      display: 'none',
    },
  },
});
export const ItemsWithSeparator: React.FunctionComponent<ItemsWithSeparatorProps> = props => {
  const { verticalGapInSmallScreen, verticalGapInMediumScreen, verticalGapInLargeScreen, separatorColor, numberOfItems, children } = props;
  const styles = createStyles(verticalGapInSmallScreen, verticalGapInMediumScreen, verticalGapInLargeScreen, separatorColor, numberOfItems);
  return (
    <div className={css(styles.wrapper)}>
      <div className={css(styles.separator)} />
      {children}
    </div>
  );
};
