import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { SponsorComponentDataProps } from './Sponsor';
import { useTheme } from '../../theming/useTheme';
import { ArticleBlockThemeProps, componentConfigurationKey } from './ArticleBlock.theme';
import { ErrorBoundary } from '../errorHandling/ErrorBoundary';
import { H5, semanticCSS } from '../../typography/semanticTags';
import { Link } from '../partials/link/Link';
import { BaseUnit } from '../../theming/baseUnitDefinition';
import { MEDIA_BREAKPOINTS } from '../../mediaQueries.const';

const createSponsorStyle = (baseUnit: BaseUnit, textColor: string, sponsorBackgroundColor: string) => StyleSheet.create({
  logo: {
    [MEDIA_BREAKPOINTS.large]: {
      height: `${2 * baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      height: `${2 * baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      height: `${baseUnit}px`,
    },
  },
  text: {
    color: textColor,
  },
  wrapper: {
    backgroundColor: sponsorBackgroundColor,
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    justifyItems: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    [MEDIA_BREAKPOINTS.large]: {
      height: `${4.25 * baseUnit}px`,
      columnGap: `${baseUnit * 0.5}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      height: `${4.25 * baseUnit}px`,
      columnGap: `${baseUnit * 0.5}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      height: `${2.25 * baseUnit}px`,
      columnGap: `${baseUnit * 0.25}px`,
    },
  },
  link: {
    display: 'flex',
  },
});

export const SponsorWithBackground: React.FunctionComponent<SponsorComponentDataProps> = props => {
  const { text, logo, brandName, href } = props;
  const { sponsorWithBackgroundTextColor, sponsorBackgroundColor, baseUnit } = useTheme<ArticleBlockThemeProps>(componentConfigurationKey);
  const style = createSponsorStyle(baseUnit, sponsorWithBackgroundTextColor, sponsorBackgroundColor);
  return (
    <ErrorBoundary>
      <div className={css(style.wrapper)}>
        <H5 styles={semanticCSS(style.text)}>
          {text}
        </H5>
        <Link href={href} rel="nofollow sponsored" className={css(style.link)}>
          {logo ? <img className={css(style.logo)} alt={brandName} src={`data:image/svg+xml,${encodeURIComponent(logo)}`} /> : null }
        </Link>
      </div>
    </ErrorBoundary>
  );
};
