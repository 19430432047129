import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { SEMANTIC_TAG, TypographySize } from 'mm-theme-configuration/dist/consts';
import { useTheme } from '../../theming/useTheme';
import { ErrorBoundary } from '../errorHandling/ErrorBoundary';
import {
  MandatoryAdditionalStyle,
  AdditionalStyleSheet,
  extractWrapperAndContentFromHtml,
} from '../components.utils';
import {
  getFontSizesBySemanticTag,
  getStyleBySize,
} from '../../typography/semanticTags/semanticTags.utils';
import { TranslatedFontSize } from '../../theming/fontSizeTranslator';
import { BlockWidths } from '../components/pageLayouts/BlockTypes.utils';
import { createBlockStyle } from './blockMutualStyle';
import { ArticleBlockThemeProps, componentConfigurationKey } from './ArticleBlock.theme';
import { baseUnit } from '../../theming/baseUnitDefinition';

interface ParagraphComponentDataProps {
  html: string;
  dataId: string;
  defaultBlocksWidths: BlockWidths;
}

interface FontSizesObject {
  fontSizeSmall: TypographySize;
  fontSizeMedium: TypographySize;
  fontSizeLarge: TypographySize;
}

type ParagraphComponentProps = ParagraphComponentDataProps & MandatoryAdditionalStyle;

const getStyle = (textColor: string, linkColor: string) => {
  return (element: string) => {
    switch (element) {
      case 'a': return StyleSheet.create({
        style: {
          color: linkColor,
          textDecoration: 'none',
        },
      }).style;

      case 'ol':
      case 'ul': return StyleSheet.create({
        style: {
          padding: 0,
          color: textColor,
        },
      }).style;
      case 'li': return StyleSheet.create({
        style: {
          marginLeft: baseUnit,
        },
      }).style;
      case 'p':
      case 'h2':
      case 'h3':
      case 'h4': return StyleSheet.create({
        style: {
          color: textColor,
        },
      }).style;
      default: return null;
    }
  };
};

const getAllTypographyStyles = (fontSizesStyles: TranslatedFontSize, h2FontSizes: FontSizesObject, h3FontSizes: FontSizesObject, h4FontSizes: FontSizesObject) => {
  const typographyStyleP = getStyleBySize(fontSizesStyles, getFontSizesBySemanticTag('p'));
  const typographyStyleH2 = getStyleBySize(fontSizesStyles, h2FontSizes);
  const typographyStyleH3 = getStyleBySize(fontSizesStyles, h3FontSizes);
  const typographyStyleH4 = getStyleBySize(fontSizesStyles, h4FontSizes);

  return ({
    p: typographyStyleP,
    h2: typographyStyleH2,
    h3: typographyStyleH3,
    h4: typographyStyleH4,
  });
};

const getTypographyStyleForLinkByWrapperElement = (wrapper: string, typographyStyles: any) => {
  switch (wrapper) {
    case 'h2':
      return typographyStyles.h2;
    case 'h3':
      return typographyStyles.h3;
    case 'h4':
      return typographyStyles.h4;
    default:
      return typographyStyles.p;
  }
};

const applyCssClasses = (html: string, paragraphTextColor: string, paragraphLinkColor: string, fontSizesStyles: TranslatedFontSize, h2FontSizes: FontSizesObject, h3FontSizes: FontSizesObject, h4FontSizes: FontSizesObject, wrapper: string): string => {
  const style = getStyle(paragraphTextColor, paragraphLinkColor);
  const typographyStyles = getAllTypographyStyles(fontSizesStyles, h2FontSizes, h3FontSizes, h4FontSizes);
  return html
    .replace(/<p/g, `<p class="${css(typographyStyles.p)}"`)
    .replace(/<h2/g, `<h2 class="${css(typographyStyles.h2)}"`)
    .replace(/<h3/g, `<h3 class="${css(typographyStyles.h3)}"`)
    .replace(/<h4/g, `<h4 class="${css(typographyStyles.h4)}"`)
    .replace(/<ol/g, `<ol class="${css(typographyStyles.p, style('ol'))}"`)
    .replace(/<ul/g, `<ul class="${css(typographyStyles.p, style('ul'))}"`)
    .replace(/<li/g, `<li class="${css(style('li'))}"`)
    .replace(/<a/g, `<a class="${css(getTypographyStyleForLinkByWrapperElement(wrapper, typographyStyles), style('a'))}"`);
};

const getBlockStyle = (defaultBlocksWidths: BlockWidths) => {
  return StyleSheet.create({
    style: {
      overflowWrap: 'break-word',
      ...createBlockStyle(defaultBlocksWidths),
    },
  }).style;
};

const getWrapperAsElement = (wrapper: string, content: string, style: { [key: string]: any } | null, additionalStyle: AdditionalStyleSheet, dataId: string | undefined, fontSizesStyles: TranslatedFontSize, h2FontSizes: FontSizesObject, h3FontSizes: FontSizesObject, h4FontSizes: FontSizesObject, defaultBlocksWidths: BlockWidths) => {
  const typographyStyles = getAllTypographyStyles(fontSizesStyles, h2FontSizes, h3FontSizes, h4FontSizes);
  const wrapperTypographyStyle = getTypographyStyleForLinkByWrapperElement(wrapper as SEMANTIC_TAG, typographyStyles);
  const marginStyle = getBlockStyle(defaultBlocksWidths);
  return React.createElement(
    wrapper,
    {
      dangerouslySetInnerHTML: { __html: content },
      className: css(wrapperTypographyStyle, style, marginStyle, additionalStyle.style),
      'data-mm-id': dataId,
      id: wrapper === 'h2' ? dataId : undefined,
    },
  );
};

export const Paragraph: React.FunctionComponent<ParagraphComponentProps> = props => {
  const { html, additionalStyle, dataId, defaultBlocksWidths } = props;
  const {
    paragraphTextColor, paragraphLinkColor, fontSizesStyles,
    h2FontSizeLarge, h2FontSizeMedium, h2FontSizeSmall,
    h3FontSizeLarge, h3FontSizeMedium, h3FontSizeSmall,
    h4FontSizeLarge, h4FontSizeMedium, h4FontSizeSmall,
  } = useTheme<ArticleBlockThemeProps>(componentConfigurationKey);
  const h2FontSizes = { fontSizeSmall: h2FontSizeSmall, fontSizeMedium: h2FontSizeMedium, fontSizeLarge: h2FontSizeLarge };
  const h3FontSizes = { fontSizeSmall: h3FontSizeSmall, fontSizeMedium: h3FontSizeMedium, fontSizeLarge: h3FontSizeLarge };
  const h4FontSizes = { fontSizeSmall: h4FontSizeSmall, fontSizeMedium: h4FontSizeMedium, fontSizeLarge: h4FontSizeLarge };

  const { wrapper, content } = extractWrapperAndContentFromHtml(html);
  const wrapperStyle = getStyle(paragraphTextColor, paragraphLinkColor)(wrapper);

  return (
    <ErrorBoundary>
      {content && getWrapperAsElement(wrapper, applyCssClasses(content, paragraphTextColor, paragraphLinkColor, fontSizesStyles, h2FontSizes, h3FontSizes, h4FontSizes, wrapper), wrapperStyle, additionalStyle, dataId, fontSizesStyles, h2FontSizes, h3FontSizes, h4FontSizes, defaultBlocksWidths)}
    </ErrorBoundary>
  );
};
