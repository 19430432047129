import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { injectVideoLogoSuccessfully, setEventEmitter } from '../../../pageLayouts/eventEmitters/eventEmitters.utils';
import { Link } from '../../../../partials/link/Link';
import { MEDIA_BREAKPOINTS } from '../../../../../mediaQueries.const';
import { AnimationDirection, AnimationDirectionType } from '../menuAnimations.util';
import { useTheme } from '../../../../../theming/useTheme';
import { AnimationLogoHeightsAndWidths, useAnimationLogoHeightsAndWidths } from './useAnimationLogoHeightsAndWidths';
import { BaseUnit } from '../../../../../theming/baseUnitDefinition';

export interface InjectedVideoLogoApiProps {
    videoPath: string;
}

export interface InjectedVideoLogoDataProps {
    href: string;
    menuAnimation?: AnimationDirectionType;
    reduceHeightAnimationDuration?: number;
    firstRowHeightInSmallScreen: number;
    firstRowHeightInMediumScreen: number;
    firstRowHeightInLargeScreen: number;
}

type InjectedVideoLogoProps = InjectedVideoLogoApiProps & InjectedVideoLogoDataProps;

const getInjectedVideoLogoStyle = (reduceHeightAnimationDuration: number, menuAnimation: AnimationDirectionType, baseUnit: BaseUnit, firstRowHeightInMediumScreen: number, firstRowHeightInSmallScreen: number, largeLogoHeightsAndWidths: AnimationLogoHeightsAndWidths) => {
  return StyleSheet.create({
    wrapper: {
      [MEDIA_BREAKPOINTS.large]: {
        height: menuAnimation === AnimationDirection.UP ? `${largeLogoHeightsAndWidths.reducedHeight}px` : `${largeLogoHeightsAndWidths.height}px`,
        minWidth: menuAnimation === AnimationDirection.UP ? `${largeLogoHeightsAndWidths.reducedWidth}px` : `${largeLogoHeightsAndWidths.width}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        height: `${baseUnit * firstRowHeightInMediumScreen}px`,
        width: 'auto',
      },
      [MEDIA_BREAKPOINTS.small]: {
        height: `${baseUnit * firstRowHeightInSmallScreen}px`,
        width: 'auto',
      },
      transition: `${reduceHeightAnimationDuration}ms`,
    },
    video: {
      height: '100%',
      alignItems: 'center',
      display: 'inline-flex',
    },
    source: {
      height: '100%',
    },
  });
};

export const VideoLogo: React.FunctionComponent<InjectedVideoLogoProps> = props => {
  const { baseUnit } = useTheme();
  const { videoPath, href, reduceHeightAnimationDuration = 0, menuAnimation = AnimationDirection.NONE, firstRowHeightInLargeScreen, firstRowHeightInMediumScreen, firstRowHeightInSmallScreen } = props;

  React.useEffect(() => {
    setEventEmitter(injectVideoLogoSuccessfully, (callback: Function) => callback());
  }, [videoPath]);

  const [ref, largeLogoHeightsAndWidths] = useAnimationLogoHeightsAndWidths(firstRowHeightInLargeScreen);
  const style = getInjectedVideoLogoStyle(reduceHeightAnimationDuration, menuAnimation, baseUnit, firstRowHeightInMediumScreen, firstRowHeightInSmallScreen, largeLogoHeightsAndWidths);

  return (
    <Link className={css(style.wrapper)} href={href}>
      {/*
  // @ts-ignore TS2322 */}
      <video ref={ref} className={css(style.video)} loop autoPlay muted="true" playsinline="true">
        <source className={css(style.source)} src={videoPath} type="video/mp4" />
      </video>
    </Link>
  );
};
