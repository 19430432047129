import * as React from 'react';
import { ErrorBoundary } from '../errorHandling/ErrorBoundary';
import { useTheme } from '../../theming/useTheme';
import { Kasda } from '../../Kasda/Kasda';

const componentConfigurationKey = 'mobileTopBar';

interface MobileTopBarColorProps {
  backgroundColor: string;
}

export const MobileTopBarColor: React.FunctionComponent = () => {
  const { backgroundColor } = useTheme<MobileTopBarColorProps>(componentConfigurationKey);
  return (
    <ErrorBoundary>
      <Kasda.Meta>
        <meta name="theme-color" content={backgroundColor} />
      </Kasda.Meta>
    </ErrorBoundary>
  );
};
