import { StyleSheet } from 'aphrodite/no-important';
import { Colors } from 'mm-theme-configuration';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';

export const createStyles = (colors: Colors) => {
  const { primary, grey, white } = colors;
  return StyleSheet.create({
    container: {
      flexDirection: 'row',
      display: 'flex',
      width: '100%',
      [MEDIA_BREAKPOINTS.large]: {
        height: '65px',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        height: '48px',
      },
      [MEDIA_BREAKPOINTS.small]: {
        height: '48px',
      },
    },
    input: {
      border: `1px solid ${grey}`,
      padding: '0 25px',
      boxSizing: 'border-box',
      width: '100%',
      color: 'rgba(10, 21, 41, .8)',
    },
    iconContainer: {
      backgroundColor: primary,
      borderColor: primary,
      margin: '0px',
      color: white,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      cursor: 'pointer',
      [MEDIA_BREAKPOINTS.large]: {
        width: '68px',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        width: '48px',
      },
      [MEDIA_BREAKPOINTS.small]: {
        width: '48px',
      },
    },
  });
};
