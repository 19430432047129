import * as React from 'react';
import { useTheme } from '../../../../theming/useTheme';
import { componentConfigThemeKey, HeaderThemeProps } from './Header.theme';
import { HeaderHeightsPerBreakpoint } from './oldHeader/oldHeader.utils';

interface HeaderHeightsContextProps {
  headerTwoRowsHeights: HeaderHeightsPerBreakpoint;
  headerFirstRowHeights: HeaderHeightsPerBreakpoint;
}

interface AmpHeaderHeightsProps {
  doesSecondaryHeaderExist: boolean;
}

interface HeaderHeightsProps {
  doesSubHeaderExist: boolean;
}

const headerHeightsContextValue = {
  headerTwoRowsHeights: {
    large: 8,
    medium: 5,
    small: 5,
  },
  headerFirstRowHeights: {
    large: 4,
    medium: 3,
    small: 3,
  },
};

export const HeaderHeightsContext = React.createContext<HeaderHeightsContextProps>(headerHeightsContextValue);

const createHeaderHeights = (firstRowHeightInLargeScreen: number, firstRowHeightInMediumScreen: number,
  firstRowHeightInSmallScreen: number, secondRowHeightInLargeScreen: number,
  secondRowHeightInMediumScreen: number, secondRowHeightInSmallScreen: number,
  doesSecondaryHeaderExist: boolean) => {
  return {
    headerTwoRowsHeights: {
      large: doesSecondaryHeaderExist ? firstRowHeightInLargeScreen + secondRowHeightInLargeScreen : firstRowHeightInLargeScreen,
      medium: doesSecondaryHeaderExist ? firstRowHeightInMediumScreen + secondRowHeightInMediumScreen : firstRowHeightInMediumScreen,
      small: doesSecondaryHeaderExist ? firstRowHeightInSmallScreen + secondRowHeightInSmallScreen : firstRowHeightInSmallScreen,
    },
    headerFirstRowHeights: {
      large: firstRowHeightInLargeScreen,
      medium: firstRowHeightInMediumScreen,
      small: firstRowHeightInSmallScreen,
    },
  };
};

export const AmpHeaderHeights: React.FunctionComponent<AmpHeaderHeightsProps> = props => {
  const { children, doesSecondaryHeaderExist } = props;
  const {
    firstRowHeightInLargeScreen,
    firstRowHeightInMediumScreen,
    firstRowHeightInSmallScreen,
    secondRowHeightInLargeScreen,
    secondRowHeightInMediumScreen,
    secondRowHeightInSmallScreen,
  } = useTheme<HeaderThemeProps>(componentConfigThemeKey);
  const ampHeaderHeights = createHeaderHeights(firstRowHeightInLargeScreen, firstRowHeightInMediumScreen, firstRowHeightInSmallScreen, secondRowHeightInLargeScreen, secondRowHeightInMediumScreen, secondRowHeightInSmallScreen, doesSecondaryHeaderExist);
  return (
    <HeaderHeightsContext.Provider value={ampHeaderHeights}>
      {children}
    </HeaderHeightsContext.Provider>
  );
};

export const HeaderHeights: React.FunctionComponent<HeaderHeightsProps> = props => {
  const { children, doesSubHeaderExist } = props;
  const {
    firstRowHeightInLargeScreen,
    firstRowHeightInMediumScreen,
    firstRowHeightInSmallScreen,
    secondRowHeightInLargeScreen,
    secondRowHeightInMediumScreen,
    secondRowHeightInSmallScreen,
  } = useTheme<HeaderThemeProps>(componentConfigThemeKey);
  const headerHeights = createHeaderHeights(firstRowHeightInLargeScreen, firstRowHeightInMediumScreen, firstRowHeightInSmallScreen, secondRowHeightInLargeScreen, secondRowHeightInMediumScreen, secondRowHeightInSmallScreen, doesSubHeaderExist);
  return (
    <HeaderHeightsContext.Provider value={headerHeights}>
      {children}
    </HeaderHeightsContext.Provider>
  );
};

export const useHeaderHeights = (): HeaderHeightsPerBreakpoint => {
  const { headerTwoRowsHeights } = React.useContext(HeaderHeightsContext);
  return headerTwoRowsHeights;
};

export const useFirstRowHeaderHeights = (): HeaderHeightsPerBreakpoint => {
  const { headerFirstRowHeights } = React.useContext(HeaderHeightsContext);
  return headerFirstRowHeights;
};
