import { BaseUnit } from '../../../theming/baseUnitDefinition';

export const componentConfigThemeKey = 'authorPage';

export interface AuthorPageThemeProps {
  titleColor: string;
  metaDataColor: string;
  bioColor: string;
  baseUnit: BaseUnit;
  backgroundGradientColor: string;
  imageBoxShadowTypeNormal: string;
  socialIconsBackgroundColor: string;
  socialIconsNormalColor: string;
  socialIconsHoverColor: string;
  showMainCategoryInLargeScreen: boolean;
  showMainCategoryInMediumScreen: boolean;
  showMainCategoryInSmallScreen: boolean;
}
