import * as React from 'react';
import { css } from 'aphrodite/no-important';
import { RATIO, TypographySize } from 'mm-theme-configuration/src/consts';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { createImageWidths } from '../../partials/image/image.utils';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { HorizontalCard } from '../../partials/cards/horizontalCard/HorizontalCard';
import { componentConfigThemeKey as horizontalCardConfigThemeKey, HorizontalCardThemeProps } from '../../partials/cards/horizontalCard/horizontalCard.theme';
import { getLayoutComponentPadding, LayoutComponentBaseProps } from '../pageLayouts/layout.utils';
import { useTheme } from '../../../theming/useTheme';

export const componentConfigThemeKey = 'oneHC';

interface OneHCDataProps extends LayoutComponentBaseProps {
    cards: Array<CardComponentDataProps>;
    useFormatMinutesHoursAgo?: boolean;
}

export interface OneHCThemeProps {
  titleFontSizeLarge: TypographySize;
  titleFontSizeMedium: TypographySize;
  titleFontSizeSmall: TypographySize;
  metadataFontSizeLarge: TypographySize;
  metadataFontSizeMedium: TypographySize;
  metadataFontSizeSmall: TypographySize;
  shouldReverseImageAlignment: boolean;
  cardProportionsLarge: RATIO;
  cardProportionsMedium: RATIO;
  cardProportionsSmall: RATIO;
  verticalGapLarge: number;
  verticalGapMedium: number;
  verticalGapSmall: number;
  cardBackgroundColor: string;
  cardTitleColorTypeNormal: string;
  cardTitleColorTypeHover: string;
  cardTitleColorTypeActive: string;
  descriptionColor: string;
  mainCategoryTextColor: string;
  mainCategoryBackgroundColor: string;
  mainCategoryVerticalGapLarge: number;
  mainCategoryVerticalGapMedium: number;
  mainCategoryVerticalGapSmall: number;
  authorColor: string;
  dateColor: string;
  pipeColor: string;
  showMainCategoryInLargeScreen: boolean;
  showMainCategoryInMediumScreen: boolean;
  showMainCategoryInSmallScreen: boolean;
  showDateInLargeScreen: boolean;
  showDateInMediumScreen: boolean;
  showDateInSmallScreen: boolean;
}

const imageWidths = createImageWidths(180, 360, 540);

export const OneHC: React.FunctionComponent<OneHCDataProps> = props => {
  const { cards, paddingFactors, useFormatMinutesHoursAgo } = props;
  const paddingStyle = getLayoutComponentPadding(paddingFactors);
  const { titleFontSizeLarge, titleFontSizeMedium, titleFontSizeSmall, metadataFontSizeLarge, metadataFontSizeMedium,
    metadataFontSizeSmall, shouldReverseImageAlignment, cardProportionsLarge, cardProportionsMedium, cardProportionsSmall,
    verticalGapLarge, verticalGapMedium, verticalGapSmall, cardBackgroundColor, cardTitleColorTypeNormal, cardTitleColorTypeHover,
    cardTitleColorTypeActive, descriptionColor, mainCategoryTextColor, mainCategoryBackgroundColor,
    authorColor, dateColor, pipeColor, showDateInLargeScreen, showDateInMediumScreen, showDateInSmallScreen, mainCategoryVerticalGapLarge,
    mainCategoryVerticalGapMedium, mainCategoryVerticalGapSmall, showMainCategoryInLargeScreen,
    showMainCategoryInMediumScreen, showMainCategoryInSmallScreen } = useTheme<OneHCThemeProps>(componentConfigThemeKey);
  const themeContextVC = useThemeOverride<HorizontalCardThemeProps>({
    titleFontSizeLarge,
    titleFontSizeMedium,
    titleFontSizeSmall,
    metadataFontSizeLarge,
    metadataFontSizeMedium,
    metadataFontSizeSmall,
    shouldReverseImageAlignment,
    cardProportionsLarge,
    cardProportionsMedium,
    cardProportionsSmall,
    verticalGapLarge,
    verticalGapMedium,
    verticalGapSmall,
    horizontalGapLarge: 0.75,
    horizontalGapMedium: 0.75,
    horizontalGapSmall: 0.75,
    cardBackgroundColor,
    cardTitleColorTypeNormal,
    cardTitleColorTypeHover,
    cardTitleColorTypeActive,
    descriptionColor,
    mainCategoryTextColor,
    mainCategoryBackgroundColor,
    authorColor,
    dateColor,
    pipeColor,
    showDateInLargeScreen,
    showDateInMediumScreen,
    showDateInSmallScreen,
    showMainCategoryInLargeScreen,
    showMainCategoryInMediumScreen,
    showMainCategoryInSmallScreen,
    mainCategoryVerticalGapLarge,
    mainCategoryVerticalGapMedium,
    mainCategoryVerticalGapSmall,
  }, horizontalCardConfigThemeKey);
  return (
    <ErrorBoundary>
      {cards && cards.length > 0
        && (
        <div className={css(paddingStyle)}>
          <ThemeContext.Provider value={themeContextVC}>
            <HorizontalCard {...cards[0]} imageWidths={imageWidths} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />
          </ThemeContext.Provider>
        </div>
        ) }
    </ErrorBoundary>
  );
};
