import React from 'react';
import { TypographySize } from 'mm-theme-configuration/dist/consts';
import { NavigationLink } from '../oldHeader/primaryHeader/PrimaryHeader';

export interface SubMenuReducerProps {
  links: NavigationLink[] | null;
  element: HTMLElement | null;
  index: number;
  linkFontSize: TypographySize | null;
  backgroundColor: string;
}

interface SubMenuActionProps {
  data: SubMenuReducerProps;
}

const subMenuReducerInitialState: SubMenuReducerProps = {
  links: null,
  element: null,
  index: -1,
  linkFontSize: null,
  backgroundColor: '',
};

export const subMenuReducer = (state: SubMenuReducerProps, action: SubMenuActionProps) => {
  if (action.data.element === state.element) {
    return {
      links: null,
      element: null,
      index: -1,
      linkFontSize: null,
      backgroundColor: '',
    };
  }

  return action.data;
};

export const useSubMenu = () => {
  return React.useReducer(subMenuReducer, subMenuReducerInitialState);
};
