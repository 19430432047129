import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useTheme } from '../../../theming/useTheme';
import { ArticleBlockThemeProps, componentConfigurationKey } from '../ArticleBlock.theme';
import { getQuoteSvg } from './utils';
import { BaseUnit } from '../../../theming/baseUnitDefinition';

export const getStylesForQuoteIcon = (baseUnit: BaseUnit) => {
  return StyleSheet.create({
    quote: {
      margin: `0 ${baseUnit}px`,
      width: `${baseUnit * 1.5}px`,
    },
  });
};

export const QuoteIconForDivider: React.FunctionComponent = () => {
  const { baseUnit, dividerLogoColor } = useTheme<ArticleBlockThemeProps>(componentConfigurationKey);
  const styles = getStylesForQuoteIcon(baseUnit);
  const svg = getQuoteSvg(dividerLogoColor);
  const imgSrc = `data:image/svg+xml,${encodeURIComponent(svg)}`;
  return (
    <img className={css(styles.quote)} src={imgSrc} alt="" />
  );
};
