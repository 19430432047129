import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { RATIO } from 'mm-theme-configuration/dist/consts';
import { createIntersectionObserver } from './LazyImage.utils';
import { defaultBlurRadius, BlurredImage } from './BlurredImage';
import { imageStyle, originalImageSize } from '../image.utils';
import { EagerImage } from '../eagerImage/EagerImage';
import { CalculatedThumbnail, ImageStyleSheet } from '../image.types';
import { LARGE_SCREEN_SIZE, MEDIUM_SCREEN_SIZE, SMALL_SCREEN_SIZE } from '../../../../mediaQueries.const';

export const statusLabels = {
  initial: 'initial',
  ready: 'ready',
};

interface LazyImageState {
  status: string;
}

const lazyImageStyle = StyleSheet.create({
  style: {
    willChange: 'opacity, transform',
  },
  initial: {
    opacity: 0,
  },
  ready: {
    opacity: 1,
  },
}) as ImageStyleSheet;

export class LazyImageComponent extends React.Component<CalculatedThumbnail, LazyImageState> {
  observer: any = null;

  blurRef: React.RefObject<HTMLCanvasElement>;

  imageRef: React.RefObject<HTMLImageElement>;

  imageLargeSourceRef: React.RefObject<HTMLSourceElement>;

  imageMediumSourceRef: React.RefObject<HTMLSourceElement>;

  imageSmallSourceRef: React.RefObject<HTMLSourceElement>;

  constructor(props: CalculatedThumbnail) {
    super(props);
    this.imageRef = React.createRef();
    this.imageLargeSourceRef = React.createRef();
    this.imageMediumSourceRef = React.createRef();
    this.imageSmallSourceRef = React.createRef();
    this.blurRef = React.createRef();
    this.state = {
      status: statusLabels.initial,
    };
  }

  componentWillUnmount() {
    this.observer = this.stopObservingIntersections();
  }


  getImageStyle(aspectRatio?: RATIO) {
    const { status } = this.state;
    const originalSizeStyle = aspectRatio?.original && aspectRatio.original === true ? originalImageSize.style : null;

    return css(imageStyle.base, lazyImageStyle[status], lazyImageStyle.style, originalSizeStyle);
  }

  getBlurRadius() {
    return defaultBlurRadius;
  }

  getBlurredImageRef = (ref: React.RefObject<HTMLCanvasElement>) => {
    this.blurRef = ref;
    this.observer = this.createIntersectionObserver();
  };

  intersectionCallback = (entries: any[]) => entries
    .filter(entry => entry.intersectionRatio > 0)
    .forEach(() => this.loadImage());


  createIntersectionObserver() {
    return createIntersectionObserver(
      this.intersectionCallback,
      this.blurRef.current,
    );
  }

  stopObservingIntersections() {
    if (this.observer && this.blurRef.current) {
      this.observer.unobserve(this.blurRef.current);
    }
  }

  loadImage() {
    const { fallbackSrc, srcSetLargeScreen, srcSetMediumScreen, srcSetSmallScreen } = this.props;
    const img: any = this.imageRef.current;
    const imgLargeSourceRef: any = this.imageLargeSourceRef.current;
    const imgMediumSourceRef: any = this.imageMediumSourceRef.current;
    const imgSmallSourceRef: any = this.imageSmallSourceRef.current;
    img.onload = () => this.setState({ status: statusLabels.ready });
    this.stopObservingIntersections();

    img.src = fallbackSrc;
    imgLargeSourceRef.srcset = srcSetLargeScreen;
    imgLargeSourceRef.media = `(min-width: ${LARGE_SCREEN_SIZE}px)`;
    imgMediumSourceRef.srcset = srcSetMediumScreen;
    imgMediumSourceRef.media = `(max-width: ${MEDIUM_SCREEN_SIZE}px)`;
    imgSmallSourceRef.srcset = srcSetSmallScreen;
    imgSmallSourceRef.media = `(max-width: ${SMALL_SCREEN_SIZE}px)`;
  }

  render() {
    const { status } = this.state;
    const { alt, title, lowResUrl, aspectRatio } = this.props;
    return (
      <React.Fragment>
        <BlurredImage src={lowResUrl} blur={this.getBlurRadius()} getRefCallback={this.getBlurredImageRef} status={status} />
        <picture className={this.getImageStyle(aspectRatio)}>
          <source ref={this.imageSmallSourceRef} />
          <source ref={this.imageMediumSourceRef} />
          <source ref={this.imageLargeSourceRef} />
          <img
            className={this.getImageStyle(aspectRatio)}
            ref={this.imageRef}
            alt={alt}
            title={title}
          />
        </picture>
        <noscript>
          <EagerImage {...this.props} />
        </noscript>
      </React.Fragment>
    );
  }
}

export const LazyImage = LazyImageComponent;
