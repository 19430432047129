import { predicates } from '../predicates/index';
import { TRAFFIC_SOURCES } from '../constants';

export const app = {
  rule: predicates.searchParamEqualsValue('app', '1'),
  extractor: () => {
    return {
      trafficSource: TRAFFIC_SOURCES.APP,
      trafficId: null,
    };
  },
};
