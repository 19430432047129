import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { BaseUnit } from '../../../../../theming/baseUnitDefinition';
import { useTheme } from '../../../../../theming/useTheme';

interface EditionsIconThemeAndLogicProps {
  editionsIconNormalColor: string;
  editionsIconHoverColor: string;
}

export interface EditionsIconDataProps {
  src: string;
  alt: string;
}

type EditionsProps = EditionsIconThemeAndLogicProps & EditionsIconDataProps

const createStyle = (editionsIconHoverColor: string, editionsIconNormalColor: string, baseUnit: BaseUnit) => {
  return StyleSheet.create({
    colors: {
      fill: editionsIconNormalColor,
      ':hover': {
        fill: editionsIconHoverColor,
      },
    },
    svg: {
      height: `${baseUnit * 0.75}px`,
      width: 'auto',
    },
    wrapper: {
      display: 'flex',
    },
  });
};

export const EditionsIcon: React.FunctionComponent<EditionsProps> = props => {
  const { src, alt, editionsIconHoverColor, editionsIconNormalColor } = props;
  const { baseUnit } = useTheme();
  const style = createStyle(editionsIconHoverColor, editionsIconNormalColor, baseUnit);
  const svg = src
    .replace(/<svg/g, `<svg class="${css(style.svg, style.colors)}"`);
  return (
    <div className={css(style.wrapper)} aria-label={alt} dangerouslySetInnerHTML={{ __html: svg }} />
  );
};
