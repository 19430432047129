import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { useTheme } from '../../../theming/useTheme';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { LARGE_SCREEN_SIZE } from '../../../mediaQueries.const';

const createStyles = (baseUnit: BaseUnit) => StyleSheet.create({
  container: {
    display: 'flex',
    justifyContent: 'center',
    maxWidth: `${LARGE_SCREEN_SIZE}px`,
    margin: '0 auto',
    marginTop: `${baseUnit}px`,
    overflowAnchor: 'none',
  },
});

interface ButtonWrapperComponentThemeProps {
  baseUnit: BaseUnit;
}

export const LoadMoreButtonWrapper: React.FunctionComponent = ({ children }) => {
  const { baseUnit } = useTheme<ButtonWrapperComponentThemeProps>();
  const style = createStyles(baseUnit);

  return (
    <div className={css(style.container)}>
      {children}
    </div>
  );
};
