import * as React from 'react';
import { Kasda } from '../../Kasda/Kasda';

interface PageDescriptionProps {
  favicon: string;
  description: string;
  title: string;
  pageUrl: string;
  ampURL: string | null;
  prevUrl: string | null;
  nextUrl: string | null;
}

export const PageDescription: React.FunctionComponent<PageDescriptionProps> = ({ description, favicon, pageUrl, title, ampURL, prevUrl, nextUrl }) => (
  <>
    <Kasda.Title title={title} />
    <Kasda.Meta>
      <meta charSet="utf-8" />
      <meta name="description" content={description} />
    </Kasda.Meta>
    <Kasda.Link>
      <link rel="icon" href={favicon} type="image/png" />
      <link rel="canonical" href={pageUrl} />
      {ampURL ? <link rel="amphtml" href={ampURL} /> : null}
      {prevUrl ? <link rel="prev" href={prevUrl} /> : null}
      {nextUrl ? <link rel="next" href={nextUrl} /> : null}
    </Kasda.Link>
  </>
);
