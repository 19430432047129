import * as React from 'react';
import { Kasda } from '../../Kasda/Kasda';

type FaqObj = {
  question: string;
  answer: string;
}

interface FAQSchemaDataProps {
  faqData: FaqObj[];
}

const getSchema = (faqData: FaqObj[]) => {
  const faqsForSchema = faqData.filter(faq => !!faq.question && !!faq.answer);
  return {
    '@context': 'http://schema.org',
    '@type': 'FAQPage',
    mainEntity: faqsForSchema.map(faq => ({
      '@type': 'Question',
      name: faq.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: faq.answer,
      },
    })),
  };
};

export const FAQSchema = ({ faqData }: FAQSchemaDataProps) => {
  if (!faqData.length) return null;
  const scriptContent = getSchema(faqData);

  return (
    <Kasda.Script>
      <script type="application/ld+json">{JSON.stringify(scriptContent)}</script>
    </Kasda.Script>
  );
};
