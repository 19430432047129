import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { useTheme } from '../../../../theming/useTheme';
import { DecorationLine } from '../../DecorationLine';
import { ListItem } from '../listItem/ListItem';
import { LIST_STYLE, LIST_WRAPPER_STYLE } from '../lists.const';
import { MEDIA_BREAKPOINTS } from '../../../../mediaQueries.const';
import { createStyleForBoxShadow } from '../../../../theming/boxShadow';
import { CardComponentDataProps } from '../../cards/cards.utils';

const componentConfigurationKey = 'list';
const createStyles = (
  backgroundColor: string,
  borderWidth: number,
  borderRadius: number,
  borderColor: string,
) => StyleSheet.create({
  listWrapper: {
    ...LIST_WRAPPER_STYLE,
    backgroundColor,
    border: `${borderWidth}px solid ${borderColor}`,
    borderRadius: `${borderRadius}px`,
    overflow: 'hidden',
  },
  list: {
    ...LIST_STYLE,
    [MEDIA_BREAKPOINTS.small]: {
      position: 'relative',
    },
  },
});

interface ListComponentDataProps {
  listProps: CardComponentDataProps[];
}

interface ListComponentThemeProps {
  backgroundColor: string;
  borderRadius: number;
  borderWidth: number;
  borderColor: string;
  boxShadowTypeNormal: string;
  boxShadowTypeHover: string;
  boxShadowTypeActive: string;
}

export const List: React.FunctionComponent<ListComponentDataProps> = ({ listProps }) => {
  const {
    backgroundColor,
    borderWidth,
    borderRadius,
    borderColor,
    boxShadowTypeNormal,
    boxShadowTypeHover,
    boxShadowTypeActive,
  } = useTheme<ListComponentThemeProps>(componentConfigurationKey);
  const styles = createStyles(
    backgroundColor,
    borderWidth,
    borderRadius,
    borderColor,
  );
  const boxShadowStyle = createStyleForBoxShadow({ boxShadowTypeNormal, boxShadowTypeHover, boxShadowTypeActive });
  return (
    <div className={css(
      boxShadowStyle.style,
      styles.listWrapper,
    )}
    >
      <DecorationLine />
      <div className={css(styles.list)}>
        {listProps.map((listItemProps, index) => (<ListItem {...listItemProps} key={index} />))}
      </div>
    </div>
  );
};
