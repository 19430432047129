import React, { useState } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { TYPOGRAPHY_SIZE } from 'mm-theme-configuration/dist/consts';
import { useTheme } from '../../../../../../theming/useTheme';
import { TextLinkComponent, generateLinkStyleFromTheme } from '../../../../../partials/link/TextLink';
import { notActiveLinkIdleColor } from '../oldHeader.utils';
import { ErrorBoundary } from '../../../../../errorHandling/ErrorBoundary';
import { MEDIA_BREAKPOINTS } from '../../../../../../mediaQueries.const';
import { Span, semanticCSS } from '../../../../../../typography/semanticTags';
import {
  getStyleBySize,
} from '../../../../../../typography/semanticTags/semanticTags.utils';
import { BaseUnit } from '../../../../../../theming/baseUnitDefinition';
import { TranslatedFontSize } from '../../../../../../theming/fontSizeTranslator';
import { NavigationLink } from './PrimaryHeader';
import { componentConfigThemeKey, HeaderThemeProps } from '../../Header.theme';

const radiusBorder = 4;

const getLinkStyle = (activeBorderBottom: string, isActive = false) => {
  return StyleSheet.create({
    link: {
      height: '100%',
      width: '100%',
      display: 'inline-flex',
      alignItems: 'center',
      position: 'relative',
      ':before': isActive ? {
        height: `${radiusBorder}px`,
        bottom: 0,
        left: 0,
        position: 'absolute',
        right: 0,
        content: "''",
        backgroundColor: activeBorderBottom,
      } : {},
    },
  });
};

const getContainerStyle = (baseUnit: BaseUnit) => {
  return StyleSheet.create({
    linksContainer: {
      display: 'grid',
      gridAutoFlow: 'column',
      height: '100%',
      gridColumnGap: `${baseUnit}px`,
    },
  });
};

export interface PrimaryLinksDataProps {
  links: NavigationLink[];
  pagePath: string;
}

const additionalStyle = {
  [MEDIA_BREAKPOINTS.large]: {
    display: 'block',
  },
  [MEDIA_BREAKPOINTS.medium]: {
    display: 'none',
  },
  [MEDIA_BREAKPOINTS.small]: {
    display: 'none',
  },
};

const getContentStyle = (isActiveLink: boolean, fontSizesStyles: TranslatedFontSize) => {
  const fontSizesActive = { fontSizeSmall: TYPOGRAPHY_SIZE.BIGGER, fontSizeMedium: TYPOGRAPHY_SIZE.BIGGER, fontSizeLarge: TYPOGRAPHY_SIZE.BIGGER };
  const fontSizesNotActive = { fontSizeSmall: TYPOGRAPHY_SIZE.NORMAL, fontSizeMedium: TYPOGRAPHY_SIZE.NORMAL, fontSizeLarge: TYPOGRAPHY_SIZE.NORMAL };
  return isActiveLink ? getStyleBySize(fontSizesStyles, fontSizesActive) : getStyleBySize(fontSizesStyles, fontSizesNotActive);
};

const createLink = (idleColor: string, activeStateColor: string, hoverStateColor: string, activeBorderBottom: string, hoverOtherLinksColors: string, fontSizesStyles: TranslatedFontSize, isHoveredLink: boolean, pagePath: string) => (
  (linkData: NavigationLink, index: number) => {
    const isActiveLink = linkData.link.href === pagePath;
    const style = getLinkStyle(activeBorderBottom, isActiveLink);
    const calculatedIdleColor = isActiveLink ? activeStateColor : notActiveLinkIdleColor(isHoveredLink, hoverOtherLinksColors, idleColor);
    const linkStyle = generateLinkStyleFromTheme({ idleColor: calculatedIdleColor, activeStateColor, hoverStateColor }, additionalStyle);
    const contentStyle = getContentStyle(isActiveLink, fontSizesStyles);
    return (
      <TextLinkComponent key={index} href={linkData.link.href} linkStyle={linkStyle}>
        <Span styles={semanticCSS(contentStyle, style.link)}>{linkData.link.text}</Span>
      </TextLinkComponent>
    );
  }
);


export const PrimaryLinks: React.FunctionComponent<PrimaryLinksDataProps> = props => {
  const [isHoveredLink, setIsHoveredLink] = useState(false);
  const { baseUnit, firstRowLinkColorTypeNormal, firstRowLinkColorTypeActive, firstRowLinkColorTypeHover, firstRowLinkActiveBorderBottom, firstRowHoverOtherLinksColors, fontSizesStyles } = useTheme<HeaderThemeProps>(componentConfigThemeKey);
  const { links, pagePath } = props;
  const style = getContainerStyle(baseUnit);
  return (
    <ErrorBoundary>
      <div className={css(style.linksContainer)} onMouseEnter={() => setIsHoveredLink(true)} onMouseLeave={() => setIsHoveredLink(false)}>
        {links.map(createLink(firstRowLinkColorTypeNormal, firstRowLinkColorTypeActive, firstRowLinkColorTypeHover, firstRowLinkActiveBorderBottom, firstRowHoverOtherLinksColors, fontSizesStyles, isHoveredLink, pagePath))}
      </div>
    </ErrorBoundary>
  );
};
