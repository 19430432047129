import * as React from 'react';
import { useTheme } from '../../../../theming/useTheme';
import { Image, ImageDataProps } from '../../image/Image';
import { createImageAspectRatios } from '../../image/image.utils';
import { HorizontalCardWrapper } from './HorizontalCardWrapper';
import { HorizontalCardThemeProps, componentConfigThemeKey } from './horizontalCard.theme';
import { HorizontalCardProps } from './horizontalCard.props';
import { Thumbnail } from '../../image/image.types';
import { ConditionalRelativeWrap } from '../../ConditionalRelativeWrap';
import { PlayIcon } from '../../PlayIcon';
import { HorizontalCardTextElement } from './HorizontalCardTextElement';
import { showAuthorInAnyScreenSize } from '../cards.utils';

const ImageComponent: React.FunctionComponent<ImageDataProps & {image: Thumbnail; coverType?: string; showImageHeightByAspectRatio?: boolean}> = ({ image, imageWidths, lazyLoad, imageHeightCalculationMethods, coverType, showImageHeightByAspectRatio = false }) => {
  const {
    aspectRatioLarge,
    aspectRatioMedium,
    aspectRatioSmall,
  } = useTheme<HorizontalCardThemeProps>(componentConfigThemeKey);
  return (
    <ConditionalRelativeWrap shouldWarp={coverType === 'video' || showImageHeightByAspectRatio} showImageHeightByAspectRatio={showImageHeightByAspectRatio}>
      <Image
        {...image}
        lazyLoad={lazyLoad}
        imageWidths={imageWidths}
        imageAspectRatios={createImageAspectRatios(aspectRatioSmall, aspectRatioMedium, aspectRatioLarge)}
        imageHeightCalculationMethods={imageHeightCalculationMethods}
      />
      {coverType === 'video' ? <PlayIcon /> : null}
    </ConditionalRelativeWrap>
  );
};

export const HorizontalCard: React.FunctionComponent<HorizontalCardProps> = props => {
  const {
    image,
    lazyLoad = true,
    imageWidths,
    imageHeightCalculationMethods,
    coverType,
    title,
    updatedAt,
    createdAt,
    updatedAtISO,
    createdAtISO,
    authors,
    description,
    sponsoredText,
    brandName,
    mainCategory,
    useFormatMinutesHoursAgo,
  } = props;

  const {
    isUpdatedAtTimestamp,
    isClickableLabels,
    isMainCategoryEnabled,
    showMainCategoryInSmallScreen,
    showMainCategoryInMediumScreen,
    showMainCategoryInLargeScreen,
    shouldReverseImageAlignment,
    showAuthorInLargeScreen,
    showAuthorInMediumScreen,
    showAuthorInSmallScreen,
    showImageHeightByAspectRatio,
  } = useTheme<HorizontalCardThemeProps>(componentConfigThemeKey);

  const timestamp = isUpdatedAtTimestamp ? updatedAt : createdAt;
  const timestampISO = isUpdatedAtTimestamp ? updatedAtISO : createdAtISO;
  const isAnyAuthorClickable = isClickableLabels && authors?.some(author => !!author.link);
  const isMainCategoryClickable = isClickableLabels && mainCategory?.link && mainCategory?.isActive;
  const showMainCategory = isMainCategoryEnabled && mainCategory?.displayName && (showMainCategoryInSmallScreen || showMainCategoryInMediumScreen || showMainCategoryInLargeScreen);
  const showAuthor = showAuthorInAnyScreenSize(authors, showAuthorInLargeScreen, showAuthorInMediumScreen, showAuthorInSmallScreen);
  const isClickableLayout = (showMainCategory && isMainCategoryClickable) || (showAuthor && isAnyAuthorClickable);

  const imageComponentProps = {
    image,
    imageWidths,
    imageHeightCalculationMethods,
    lazyLoad,
    coverType,
    showImageHeightByAspectRatio,
  };

  const textElementProps = {
    title,
    timestamp,
    timestampISO,
    authors,
    description,
    sponsoredText,
    brandName,
    isMainCategoryClickable: !!isMainCategoryClickable,
    showMainCategory: !!showMainCategory,
    mainCategory,
    isAnyAuthorClickable,
  };

  const elements = [
    <ImageComponent key="image" {...imageComponentProps} />,
    <HorizontalCardTextElement key="text" {...textElementProps} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />,
  ];

  return (
    <HorizontalCardWrapper {...props} isClickableLayout={isClickableLayout}>
      {shouldReverseImageAlignment ? elements.reverse() : elements}
    </HorizontalCardWrapper>
  );
};
