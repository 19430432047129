import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { SEMANTIC_TAG, TypographySize } from 'mm-theme-configuration/dist/consts';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import {
  componentConfigThemeKey as headlineCardThemeKey,
  HeadlineCard,
  HeadlineCardThemeProps,
  HeadlineCardProps,
} from '../../partials/cards/headlineCard/HeadlineCard';
import { useTheme } from '../../../theming/useTheme';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { createStyleForBoxShadow } from '../../../theming/boxShadow';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { getStyleBySize } from '../../../typography/semanticTags/semanticTags.utils';
import { TranslatedFontSize } from '../../../theming/fontSizeTranslator';

export const componentConfigThemeKey = 'fiveHLCNumberedList';

interface FiveHLCNumberedListProps {
    cards: CardComponentDataProps[];
}

export interface FiveHLCNumberedListThemeProps {
    wrapperGapLarge: number;
    wrapperGapMedium: number;
    wrapperGapSmall: number;
    borderRadius: number;
    borderWidthTypeNormal: number;
    borderWidthTypeHover: number;
    borderWidthTypeActive: number;
    borderColorTypeNormal: string;
    borderColorTypeHover: string;
    borderColorTypeActive: string;
    boxShadowTypeNormal: string;
    boxShadowTypeActive: string;
    boxShadowTypeHover: string;
    dividerWidth: number;
    dividerColor: string;
    dividerGapInSmallScreen: number;
    dividerGapInMediumScreen: number;
    dividerGapInLargeScreen: number;
    showDivider: boolean;
    showAuthor: boolean;
    showDate: boolean;
    titleSemanticTag: SEMANTIC_TAG;
    titleFontSizeLarge: TypographySize;
    titleFontSizeMedium: TypographySize;
    titleFontSizeSmall: TypographySize;
    numberFontSizeLarge: TypographySize;
    numberFontSizeMedium: TypographySize;
    numberFontSizeSmall: TypographySize;
    numberColor: string;
    baseUnit: number;
    fontSizesStyles: TranslatedFontSize;
}

const createStyle = (themeProps: FiveHLCNumberedListThemeProps) => {
  const {
    dividerWidth,
    baseUnit,
    dividerGapInSmallScreen,
    dividerGapInMediumScreen,
    dividerGapInLargeScreen,
    dividerColor,
    showDivider,
    borderRadius,
    wrapperGapLarge,
    wrapperGapMedium,
    wrapperGapSmall,
    numberColor,
    fontSizesStyles,
    numberFontSizeSmall,
    numberFontSizeMedium,
    numberFontSizeLarge } = themeProps;
  return StyleSheet.create({
    wrapper: {
      display: 'grid',
      gridTemplateRows: showDivider ? 'repeat(5, auto 1fr)' : 'repeat(5, 1fr)',
      alignItems: 'center',
      backgroundColor: 'transparent',
      borderRadius: `${borderRadius}px`,
      gridTemplateColumns: '1fr',
      [MEDIA_BREAKPOINTS.large]: {
        gridRowGap: `${dividerGapInLargeScreen * baseUnit}px`,
        padding: `${wrapperGapLarge * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        gridRowGap: `${dividerGapInMediumScreen * baseUnit}px`,
        padding: `${wrapperGapMedium * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        gridRowGap: `${dividerGapInSmallScreen * baseUnit}px`,
        padding: `${wrapperGapSmall * baseUnit}px`,
      },
    },
    dividerBetweenCards: {
      backgroundColor: dividerColor,
      display: 'block',
      width: '100%',
      height: `${dividerWidth}px`,
    },
    numberColor: {
      color: numberColor,
    },
    numberTextStyle: {
      ...getStyleBySize(fontSizesStyles, {
        fontSizeSmall: numberFontSizeSmall,
        fontSizeMedium: numberFontSizeMedium,
        fontSizeLarge: numberFontSizeLarge,
      }),
    },
    numberAndCardWrapper: {
      flex: '1 1',
      display: 'inline-flex',
      height: 'fit-content',
      alignItems: 'center',
      gap: `${baseUnit}px`,
      padding: `${baseUnit * 0.5}px`,

    },
  });
};

export const FiveHLCNumberedList: React.FunctionComponent<FiveHLCNumberedListProps> = props => {
  const { cards } = props;
  const themeProps = useTheme<FiveHLCNumberedListThemeProps>(componentConfigThemeKey);
  const {
    borderColorTypeActive,
    borderColorTypeHover,
    borderColorTypeNormal,
    boxShadowTypeActive,
    boxShadowTypeHover,
    boxShadowTypeNormal,
    borderWidthTypeActive,
    borderWidthTypeHover,
    borderWidthTypeNormal,
    titleFontSizeLarge,
    titleFontSizeMedium,
    titleFontSizeSmall,
    titleSemanticTag,
    showAuthor,
    showDate,
    fontSizesStyles,
    numberFontSizeSmall,
    numberFontSizeMedium,
    numberFontSizeLarge } = themeProps;
  const borderBoxShadow = {
    borderColorTypeActive,
    borderColorTypeHover,
    borderColorTypeNormal,
    borderWidthTypeActive,
    borderWidthTypeHover,
    borderWidthTypeNormal,
  };
  const boxShadowStyle = createStyleForBoxShadow({ boxShadowTypeNormal, boxShadowTypeHover, boxShadowTypeActive, borderBoxShadow });
  const { showDivider } = themeProps;
  const style = createStyle(themeProps);
  const numberTextStyle = getStyleBySize(fontSizesStyles, {
    fontSizeSmall: numberFontSizeSmall,
    fontSizeMedium: numberFontSizeMedium,
    fontSizeLarge: numberFontSizeLarge,
  });
  const themeContextHeadlineCard = useThemeOverride<HeadlineCardThemeProps>({
    titleFontSizeLarge,
    titleFontSizeMedium,
    titleFontSizeSmall,
    titleSemanticTag,
    numberOfLinesForTitle: 2,
    showAuthorInLargeScreen: showAuthor,
    showAuthorInMediumScreen: showAuthor,
    showAuthorInSmallScreen: showAuthor,
    showDateInLargeScreen: showDate,
    showDateInMediumScreen: showDate,
    showDateInSmallScreen: showDate,
  }, headlineCardThemeKey);
  return (
    <ErrorBoundary>
      {cards && cards.length > 0 && (
        <div className={css(style.wrapper, boxShadowStyle.style)}>
          <ThemeContext.Provider value={themeContextHeadlineCard}>
            {showDivider ? <div className={css(style.dividerBetweenCards)} /> : null}
            <NumberedHeadlineCard cardNumber={1} cardProps={cards[0]} style={style} numberStyle={numberTextStyle} />
            {showDivider ? <div className={css(style.dividerBetweenCards)} /> : null}
            <NumberedHeadlineCard cardNumber={2} cardProps={cards[1]} style={style} numberStyle={numberTextStyle} />
            {showDivider ? <div className={css(style.dividerBetweenCards)} /> : null}
            <NumberedHeadlineCard cardNumber={3} cardProps={cards[2]} style={style} numberStyle={numberTextStyle} />
            {showDivider ? <div className={css(style.dividerBetweenCards)} /> : null}
            <NumberedHeadlineCard cardNumber={4} cardProps={cards[3]} style={style} numberStyle={numberTextStyle} />
            {showDivider ? <div className={css(style.dividerBetweenCards)} /> : null}
            <NumberedHeadlineCard cardNumber={5} cardProps={cards[4]} style={style} numberStyle={numberTextStyle} />
          </ThemeContext.Provider>
        </div>
      ) }
    </ErrorBoundary>
  );
};

interface NumberedHeadlineCardProps {
    cardNumber: number;
    cardProps: HeadlineCardProps;
    style: any;
    numberStyle: any;
}

export const NumberedHeadlineCard: React.FunctionComponent<NumberedHeadlineCardProps> = props => {
  const { cardNumber, cardProps, style, numberStyle } = props;
  return (
    <li className={css(style.numberAndCardWrapper)}>
      <div className={css(style.numberColor, numberStyle)}>{cardNumber}</div>
      <HeadlineCard {...cardProps} />
    </li>
  );
};
