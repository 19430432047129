import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { ImageWidths, useTransformCreditHTML } from '../image.utils';
import { FULL_BLEED_IMAGE_SIZE_TYPE, ImageSizeType, Thumbnail } from '../image.types';
import { Image } from '../Image';
import { CameraIcon } from './cameraIcon';
import { getStyleBySize } from '../../../../typography/semanticTags/semanticTags.utils';
import { useTheme } from '../../../../theming/useTheme';
import { BaseUnit } from '../../../../theming/baseUnitDefinition';
import { MEDIA_BREAKPOINTS } from '../../../../mediaQueries.const';
import { Style } from '../../../components.utils';
import { BlocksPaddingOverride, BlockWidths } from '../../../components/pageLayouts/BlockTypes.utils';
import { createBlockStyle } from '../../../articleComponents/blockMutualStyle';
import { componentConfigurationKey, ImageThemeProps } from '../Image.theme';

interface ImageDataProps {
  dataId?: string;
  image: Thumbnail;
  lazyLoad?: boolean;
  style: Style;
  defaultBlocksWidths: BlockWidths;
  overrideBlockWidths?: BlockWidths;
  sizeType: ImageSizeType;
  blocksPaddingOverride?: BlocksPaddingOverride;
  imageWidths: ImageWidths;
}

const getStyles = (textColor: string, borderColor: string, baseUnit: BaseUnit, defaultBlocksWidths: BlockWidths, captionPaddingFactor: number, showIcon: boolean, showBorderBottom: boolean) => {
  return StyleSheet.create({
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      borderBottom: showBorderBottom ? `1px solid ${borderColor}` : 'none',
      marginBottom: `${baseUnit}px`,
      [MEDIA_BREAKPOINTS.large]: {
        paddingTop: `${baseUnit}px`,
        paddingBottom: `${baseUnit}px`,
        margin: showIcon ? 'auto' : 'unset',
        width: showIcon ? `min(${defaultBlocksWidths.largeWidth - 2 * captionPaddingFactor * baseUnit}px, 100%)` : '100%',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        paddingTop: `${baseUnit}px`,
        paddingBottom: `${baseUnit / 2}px`,
        margin: showIcon ? `0 ${baseUnit}px` : 'unset',
      },
      [MEDIA_BREAKPOINTS.small]: {
        paddingTop: `${baseUnit}px`,
        paddingBottom: `${baseUnit / 2}px`,
        margin: showIcon ? `0 ${baseUnit}px` : 'unset',
      },
    },
    text: {
      color: textColor,
      [MEDIA_BREAKPOINTS.large]: {
        marginLeft: `${baseUnit / 2}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        marginLeft: `${baseUnit / 2}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        marginLeft: `${baseUnit / 4}px`,
      },
    },
  });
};

const createFullBleedStyle = (style: Style) => {
  return StyleSheet.create({
    style: {
      ...style,
    },
  }).style;
};

const getContainerStyle = (style: Style, defaultBlocksWidths: BlockWidths, overrideBlockWidths: BlockWidths | undefined, blocksPaddingOverride: BlocksPaddingOverride | undefined) => {
  const blockStyle = overrideBlockWidths ? createBlockStyle(overrideBlockWidths, blocksPaddingOverride) : createBlockStyle(defaultBlocksWidths, blocksPaddingOverride);

  return StyleSheet.create({
    style: {
      ...style,
      ...blockStyle,
    },
  }).style;
};

const getCaption = (caption: string | undefined, credit: string | undefined) => {
  const hasCaption = typeof caption === 'string';
  const hasCredit = typeof credit === 'string';
  const separator = hasCaption && hasCredit ? ' / ' : '';

  return `${hasCaption ? caption : ''}${separator}${hasCredit ? credit : ''}`;
};

export const ImageWithCaptionAndIcon: React.FunctionComponent<ImageDataProps> = props => {
  const { image, lazyLoad, style, dataId, defaultBlocksWidths, overrideBlockWidths, sizeType, blocksPaddingOverride, imageWidths } = props;
  const { caption, credit } = image;
  const creditHTML = useTransformCreditHTML(credit);
  const { iconColor, textColor, borderBottomColor, fontSizeLarge, fontSizeMedium, fontSizeSmall, baseUnit, fontSizesStyles, captionPaddingFactor, showIcon, showBorderBottom } = useTheme<ImageThemeProps>(componentConfigurationKey);
  const styles = getStyles(textColor, borderBottomColor, baseUnit, defaultBlocksWidths, captionPaddingFactor, showIcon, showBorderBottom);
  const figcaptionText = getStyleBySize(fontSizesStyles, { fontSizeSmall, fontSizeMedium, fontSizeLarge });
  const hasCaptionOrCredit = typeof caption === 'string' || typeof credit === 'string';

  const additionalStyle = sizeType === FULL_BLEED_IMAGE_SIZE_TYPE ? createFullBleedStyle(style)
    : getContainerStyle(style, defaultBlocksWidths, overrideBlockWidths, blocksPaddingOverride);

  return (
    <div className={css(additionalStyle)}>
      <Image imageWidths={imageWidths} {...image} lazyLoad={lazyLoad} dataId={dataId} />
      {
        hasCaptionOrCredit
        && (
          <div className={css(styles.wrapper)}>
            {showIcon ? <CameraIcon color={iconColor} /> : null}
            <figcaption className={css(figcaptionText, styles.text)} dangerouslySetInnerHTML={{ __html: getCaption(caption, creditHTML) }} />
          </div>
        )
      }
    </div>
  );
};
