import React from 'react';
import { css } from 'aphrodite/no-important';
import { Colors } from 'mm-theme-configuration';
import { TYPOGRAPHY_SIZE } from 'mm-theme-configuration/dist/consts';
import { CardComponentDataProps } from '../../../partials/cards/cards.utils';
import { Link } from '../../../partials/link/Link';
import { H3 } from '../../../../typography/semanticTags';
import { AnalyticsEventParams } from '../SuggestedPosts';
import { useTheme } from '../../../../theming/useTheme';
import { getNumberIconFour, getNumberIconOne, getNumberIconThree, getNumberIconTwo } from './articleLine.utils';
import { ArticleBlockThemeProps, componentConfigurationKey } from '../../ArticleBlock.theme';
import { getStyleBySize } from '../../../../typography/semanticTags/semanticTags.utils';

interface ArticleLineProps {
    index: number;
    article: CardComponentDataProps;
    suggestedPostsAnalytics: (linkNumber: number, linkUrl: string) => () => void;
    analyticsEventParams: AnalyticsEventParams;
    styles: { [key: string]: any };
}

const ArticleNumberIcon = ({ index, color }: any) => {
  switch (index) {
    case 0:
      return getNumberIconOne(color);
    case 1:
      return getNumberIconTwo(color);
    case 2:
      return getNumberIconThree(color);
    default:
      return getNumberIconFour(color);
  }
};

export const ArticleLine = (props: ArticleLineProps) => {
  const { index, article, analyticsEventParams, suggestedPostsAnalytics, styles } = props;
  const { fontSizesStyles } = useTheme<ArticleBlockThemeProps>(componentConfigurationKey);

  const { colors }: { colors: Colors } = useTheme();
  const primaryColor = colors.primary;

  const fontSizes = {
    fontSizeLarge: TYPOGRAPHY_SIZE.LARGE,
    fontSizeMedium: TYPOGRAPHY_SIZE.LARGE,
    fontSizeSmall: TYPOGRAPHY_SIZE.LARGE,
  };

  return (
    <div className={css(styles.greySectionDivider)}>
      <Link
        href={article.articleUrl}
        onClick={suggestedPostsAnalytics(index + 1, article.articleUrl)}
        data-vars-action={analyticsEventParams.action}
        data-vars-category={analyticsEventParams.category}
        data-vars-label={analyticsEventParams.label}
        className={css(styles.postWrapper)}
      >
        <ArticleNumberIcon index={index} color={primaryColor} />
        <H3 className={css(styles.articleItem, getStyleBySize(fontSizesStyles, fontSizes))}>
          {article.title}
        </H3>
      </Link>
    </div>
  );
};
