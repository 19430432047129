import * as React from 'react';
import { StyleSheet } from 'aphrodite/no-important';
import { TypographySize } from 'mm-theme-configuration/dist/consts';
import {
  PrimaryLinksDataProps,
} from '../../components/navigation/header/oldHeader/primaryHeader/PrimaryLinks';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { useTheme } from '../../../theming/useTheme';
import { generateLinkStyleFromTheme, TextLinkComponent } from '../../partials/link/TextLink';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { Span, semanticCSS } from '../../../typography/semanticTags';
import { getStyleBySize } from '../../../typography/semanticTags/semanticTags.utils';
import { TranslatedFontSize } from '../../../theming/fontSizeTranslator';
import { NavigationLink } from '../../components/navigation/header/oldHeader/primaryHeader/PrimaryHeader';
import { componentConfigThemeKey, HeaderThemeProps } from '../../components/navigation/header/Header.theme';

const additionalStyle = {
  [MEDIA_BREAKPOINTS.large]: {
    display: 'block',
  },
  [MEDIA_BREAKPOINTS.medium]: {
    display: 'none',
  },
  [MEDIA_BREAKPOINTS.small]: {
    display: 'none',
  },
};

const radiusBorder = 4;

const createStyle = (isActive = false, activeBorderBottom: string) => {
  return StyleSheet.create({
    link: {
      height: '100%',
      width: '100%',
      display: 'inline-flex',
      alignItems: 'center',
      position: 'relative',
      ':before': isActive ? {
        height: `${radiusBorder}px`,
        bottom: 0,
        left: 0,
        position: 'absolute',
        right: 0,
        content: "''",
        backgroundColor: activeBorderBottom,
      } : {},
    },
  });
};

const createLink = (idleColor: string, activeBorderBottom: string, hoverStateColor: string, activeStateColor: string, pagePath: string, fontSizesStyles: TranslatedFontSize, linkFontSizeLarge: TypographySize, linkFontSizeMedium: TypographySize, linkFontSizeSmall: TypographySize) => (navigationLink: NavigationLink, index: number) => {
  const { href, text } = navigationLink.link;
  const isActiveLink = href === pagePath;
  const linkStyle = generateLinkStyleFromTheme({ idleColor, activeStateColor, hoverStateColor }, additionalStyle);
  const style = createStyle(isActiveLink, activeBorderBottom);
  const fontStyle = getStyleBySize(fontSizesStyles, { fontSizeSmall: linkFontSizeSmall, fontSizeMedium: linkFontSizeMedium, fontSizeLarge: linkFontSizeLarge });
  return (
    <TextLinkComponent key={index} href={href} linkStyle={linkStyle}>
      <Span styles={semanticCSS(fontStyle, style.link)}>{text}</Span>
    </TextLinkComponent>
  );
};

export const AMPPrimaryLinks: React.FunctionComponent<PrimaryLinksDataProps> = props => {
  const { firstRowLinkColorTypeNormal, fontSizesStyles, firstRowLinkActiveBorderBottom, firstRowLinkColorTypeHover, firstRowLinkColorTypeActive, firstRowLinkFontSizeLarge, firstRowLinkFontSizeMedium, firstRowLinkFontSizeSmall } = useTheme<HeaderThemeProps>(componentConfigThemeKey);
  const { links, pagePath } = props;
  return (
    <ErrorBoundary>
      {links.map(createLink(firstRowLinkColorTypeNormal, firstRowLinkActiveBorderBottom, firstRowLinkColorTypeHover, firstRowLinkColorTypeActive, pagePath, fontSizesStyles, firstRowLinkFontSizeLarge, firstRowLinkFontSizeMedium, firstRowLinkFontSizeSmall))}
    </ErrorBoundary>
  );
};
