import { StyleDeclaration } from 'aphrodite/no-important';
import { RATIO } from 'mm-theme-configuration/dist/consts';
import { CroppingData } from './image.utils';

export interface CalculatedThumbnail {
  fallbackSrc: string;
  alt: string;
  title: string;
  lowResUrl?: string;
  srcSetLargeScreen: string;
  srcSetMediumScreen: string;
  srcSetSmallScreen: string;
  aspectRatio?: RATIO;
}

export const REGULAR_IMAGE_SIZE_TYPE = 'regular' as const;
export const FULL_BLEED_IMAGE_SIZE_TYPE = 'fullBleed' as const;

export type ImageSizeType = typeof REGULAR_IMAGE_SIZE_TYPE | typeof FULL_BLEED_IMAGE_SIZE_TYPE;

export interface ImageLink {
  linkURL: string;
  targetAttributeValue: string | null;
  relAttributeValue: string | null;
}

export interface Thumbnail {
  host: string;
  path: string;
  alt: string;
  caption?: string;
  credit?: string;
  aspectRatio?: RATIO;
  link?: ImageLink | null;
  cropping?: CroppingData | null;
  rawHtml?: string;
}

export interface ImageStyleSheet {
  [key: string]: StyleDeclaration;
}
