import { StyleDeclaration } from 'aphrodite/no-important';

export const ellipsify = (lines: number) => {
  return {
    style: {
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkitLineClamp': `${lines}`,
      '-webkitBoxOrient': 'vertical',
    } as StyleDeclaration,
  };
};
