import { TypographySize } from 'mm-theme-configuration/dist/consts';

export const componentConfigThemeKey = 'relatedTopics';

export interface RelatedTopicsThemeProps {
    titleColor: string;
    titleBackgroundColor: string;
    titleLargeMarginBottomFactor: number;
    titleMediumMarginBottomFactor: number;
    titleSmallMarginBottomFactor: number;
    titleLargeHorizontalGap: number;
    titleMediumHorizontalGap: number;
    titleSmallHorizontalGap: number;
    titleLargeWrapperGap: number;
    titleMediumWrapperGap: number;
    titleSmallWrapperGap: number;
    linkBackgroundColorNormal: string;
    linkBackgroundColorHover: string;
    linkBackgroundColorActive: string;
    linkTextColorNormal: string;
    linkTextColorHover: string;
    linkTextColorActive: string;
    linkBorderColorNormal: string;
    linkBorderColorHover: string;
    linkBorderColorActive: string;
    linkFontSizeLarge: TypographySize;
    linkFontSizeMedium: TypographySize;
    linkFontSizeSmall: TypographySize;
    linkBoxShadowTypeNormal: string;
    linkBoxShadowTypeHover: string;
    linkBoxShadowTypeActive: string;
    linkBorderRadius: number;
    linkBorderWidthNormal: number;
    linkBorderWidthHover: number;
    linkBorderWidthActive: number;
    titleFontSizeLarge: TypographySize;
    titleFontSizeMedium: TypographySize;
    titleFontSizeSmall: TypographySize;
    rowGapLarge: number;
    rowGapMedium: number;
    rowGapSmall: number;
    columnGapLarge: number;
    columnGapMedium: number;
    columnGapSmall: number;
    largeHorizontalGap: number;
    mediumHorizontalGap: number;
    smallHorizontalGap: number;
}
