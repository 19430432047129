import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { LinkButtonDataProps } from '../../../../../utils';
import { useTheme } from '../../../../../../../../theming/useTheme';
import {
  getStyleBySize,
} from '../../../../../../../../typography/semanticTags/semanticTags.utils';
import { MEDIA_BREAKPOINTS } from '../../../../../../../../mediaQueries.const';
import { componentConfigThemeKey, HeaderThemeProps } from '../../../../Header.theme';

const createStyle = (baseUnit: number, linkColor: string) => StyleSheet.create({
  button: {
    color: linkColor,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [MEDIA_BREAKPOINTS.medium]: {
      margin: `${baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      margin: `${baseUnit}px`,
    },
  },
});

export const MenuButton: React.FunctionComponent<LinkButtonDataProps> = props => {
  const { onClick, text, cssClassName, attributes } = props;
  /* eslint-disable-next-line no-new-func */
  const getFunction = (onClickValue: string) => new Function(onClickValue);
  const { hamburgerMenuLinksNormalColor, baseUnit, fontSizesStyles, hamburgerMenuLinksFontSizeMedium, hamburgerMenuLinksFontSizeSmall } = useTheme<HeaderThemeProps>(componentConfigThemeKey);
  const style = createStyle(baseUnit, hamburgerMenuLinksNormalColor);
  const styleByFontSize = getStyleBySize(fontSizesStyles, { fontSizeLarge: hamburgerMenuLinksFontSizeMedium, fontSizeMedium: hamburgerMenuLinksFontSizeSmall, fontSizeSmall: hamburgerMenuLinksFontSizeSmall });
  const buttonAttributes = {
    ...onClick ? { onClick: getFunction(onClick) } : {},
    ...attributes || {},
    ...cssClassName ? { className: `${css(style.button, styleByFontSize)} ${cssClassName}` } : { className: css(style.button, styleByFontSize) },
  };
  return (
    // @ts-ignore
    <button type="button" {...buttonAttributes}>{text}</button>
  );
};
