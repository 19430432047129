import React from 'react';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { OneOTCThreeVC } from '../../components/articleLists/OneOTCThreeVC';
import { ViewabilityElement } from '../../../viewability/viewabilityHandler';

interface ManualPostsRecommendationProps {
  cards: Array<CardComponentDataProps>;
}

const createOnClickEvent = (cardIndex: number) => {
  return () => {
    window.mmClientApi = window.mmClientApi || [];
    window.mmClientApi.push('analytics', {
      event_category: 'Article',
      event_action: 'manual recommendation clicked',
      event_label: `link=[${cardIndex + 1}]`,
    });
  };
};
export const ManualPostsRecommendation: React.FunctionComponent<ManualPostsRecommendationProps> = props => {
  const { cards } = props;
  const cardsWithOnClickEvents = cards.map((card, index) => ({
    ...card,
    onClick: createOnClickEvent(index),
  }));
  const viewabilityCallback = React.useCallback(() => {
    // @ts-ignore
    window.mmClientApi = window.mmClientApi || [];
    // @ts-ignore
    window.mmClientApi.push('analytics', {
      event_category: 'Article',
      event_action: 'manual recommendation viewable impression',
      event_label: '',
    });
  }, []);

  return (
    <ViewabilityElement viewabilityCallback={viewabilityCallback} threshold={1}>
      <OneOTCThreeVC cards={cardsWithOnClickEvents} />
    </ViewabilityElement>
  );
};
