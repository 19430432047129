import React, { useState } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { LinkButtonDataProps } from '../../../utils';
import { AdditionalStyleSheet } from '../../../../../components.utils';
import { MenuIcon } from '../../oldHeader/primaryHeader/menu/icons/MenuIcon';
import { MenuLinks } from './MenuLinks';
import { MenuLinkDataProps } from '../../oldHeader/primaryHeader/menu/links/MenuSubLink';
import { SocialSectionDataProps } from '../../../footer/AdditionalFooterSections/FooterSocialLinks';
import { MenuSocialLinks } from './MenuSocialLinks';
import { MEDIA_BREAKPOINTS } from '../../../../../../mediaQueries.const';
import { BaseUnit } from '../../../../../../theming/baseUnitDefinition';
import { useTheme } from '../../../../../../theming/useTheme';
import { componentConfigThemeKey, HeaderThemeProps } from '../../Header.theme';

export interface MenuDataProps {
  links: MenuLinkDataProps[];
  socialSection?: SocialSectionDataProps | null;
  oldSocialSection?: SocialSectionDataProps | null;
  buttons?: Array<LinkButtonDataProps>;
  additionalStyle?: AdditionalStyleSheet;
  showSocialTitle: boolean;
}

const getStyles = (dividerColor: string, baseUnit: BaseUnit) => StyleSheet.create({
  divider: {
    backgroundColor: dividerColor,
    height: '1px',
    [MEDIA_BREAKPOINTS.medium]: {
      margin: `${baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      margin: `${baseUnit}px`,
    },
  },
});

export const Menu: React.FunctionComponent<MenuDataProps> = props => {
  const { links, buttons, additionalStyle, socialSection, oldSocialSection, showSocialTitle } = props;
  const shouldShowSocialSection = socialSection && socialSection.imageLinks.length > 0;
  const shouldShowSocial = shouldShowSocialSection || (oldSocialSection && oldSocialSection.imageLinks.length > 0);

  const { baseUnit, hamburgerMenuDividerColor } = useTheme<HeaderThemeProps>(componentConfigThemeKey);

  const [state, setState] = useState({
    isMenuOpen: false,
    currentOpenLink: -1,
  });
  const { isMenuOpen, currentOpenLink } = state;

  const clickOnMenuIcon = () => {
    setState({
      isMenuOpen: !isMenuOpen,
      currentOpenLink: isMenuOpen ? currentOpenLink : -1,
    });
  };

  const clickOnLinkDropDown = (index: number) => {
    setState({
      isMenuOpen,
      currentOpenLink: currentOpenLink === index ? -1 : index,
    });
  };

  const style = getStyles(hamburgerMenuDividerColor, baseUnit);
  return (
    <div className={css(additionalStyle && additionalStyle.style)}>
      <MenuIcon isMenuOpen={isMenuOpen} onClick={clickOnMenuIcon} />
      <MenuLinks menuLinks={links} buttons={buttons} isMenuOpen={isMenuOpen} onClick={clickOnLinkDropDown} currentOpenLink={currentOpenLink}>
        {shouldShowSocial && (
          <React.Fragment>
            <div className={css(style.divider)} />
            {/*
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
            <MenuSocialLinks socialSection={socialSection!} oldSocialSection={oldSocialSection!} showSocialTitle={showSocialTitle} />
          </React.Fragment>
        )}
      </MenuLinks>
    </div>
  );
};
