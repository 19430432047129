import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { RATIO, TypographySize } from 'mm-theme-configuration/dist/consts';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import {
  componentConfigThemeKey as onTopCardThemeKey,
  OnTopCard,
  OnTopCardComponentThemeProps,
} from '../../partials/cards/onTopCard/OnTopCard';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { createImageHeightCalculationMethods, createImageWidths } from '../../partials/image/image.utils';
import { HorizontalCard } from '../../partials/cards/horizontalCard/HorizontalCard';
import {
  componentConfigThemeKey as horizontalCardThemeKey,
  HorizontalCardThemeProps,
} from '../../partials/cards/horizontalCard/horizontalCard.theme';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { getLayoutComponentPadding, LayoutComponentBaseProps } from '../pageLayouts/layout.utils';
import { useTheme } from '../../../theming/useTheme';
import { baseUnit } from '../../../theming/baseUnitDefinition';

export const componentConfigThemeKey = 'threeHCOneOTC';

interface ThreeHCOneOTCDataProps extends LayoutComponentBaseProps {
  cards: Array<CardComponentDataProps>;
}

export interface ThreeHCOneOTCThemeProps {
  largeVerticalGap: number;
  largeHorizontalGap: number;
  mediumHorizontalGap: number;
  smallHorizontalGap: number;
  titleFontSizeSmall: TypographySize;
  titleFontSizeMedium: TypographySize;
  titleFontSizeLarge: TypographySize;
  metadataFontSizeLarge: TypographySize;
  metadataFontSizeMedium: TypographySize;
  metadataFontSizeSmall: TypographySize;
  aspectRatioSmall: RATIO;
  aspectRatioLarge: RATIO;
  aspectRatioMedium: RATIO;
  showDescriptionInLargeScreen: boolean;
  showDescriptionInMediumScreen: boolean;
  authorColor: string;
  insideCardSmallHorizontalGap: number;
  showAuthorInLargeScreenForHC: boolean;
  showAuthorInMediumScreenForHC: boolean;
  showAuthorInSmallScreenForHC: boolean;
  showDividers: boolean;
  dividersColor: string;
}

const imageWidthsOnTopCard = createImageWidths(360, 720, 540);
const imageWidthsHorizontalCard = createImageWidths(360, 360, 360);

const getStyles = (themeProps: ThreeHCOneOTCThemeProps) => {
  const { largeHorizontalGap, largeVerticalGap, mediumHorizontalGap, smallHorizontalGap, showDividers, dividersColor } = themeProps;
  const mainCardArea = 'main_card_area';
  const firstCardArea = 'first_card_area';
  const secondCardArea = 'second_card_area';
  const thirdCardArea = 'third_card_area';
  const dividerStyle = {
    ':after': {
      content: '""',
      display: 'block' as const,
      borderBottom: `1px solid ${dividersColor}`,
      [MEDIA_BREAKPOINTS.large]: {
        paddingTop: `${largeHorizontalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        paddingTop: `${mediumHorizontalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        paddingTop: `${smallHorizontalGap * baseUnit}px`,
      },
    },
    [MEDIA_BREAKPOINTS.large]: {
      paddingBottom: `${largeHorizontalGap * baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      paddingBottom: `${mediumHorizontalGap * baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      paddingBottom: `${smallHorizontalGap * baseUnit}px`,
    },
  };
  return StyleSheet.create({
    section: {
      display: 'grid',
      [MEDIA_BREAKPOINTS.large]: {
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: '1fr 1fr 1fr',
        gridRowGap: `${largeHorizontalGap * baseUnit}px`,
        gridColumnGap: `${largeVerticalGap * baseUnit}px`,
        gridTemplateAreas: `'${firstCardArea} ${mainCardArea}' '${secondCardArea} ${mainCardArea}' '${thirdCardArea} ${mainCardArea}'`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        gridTemplateRows: 'auto auto auto auto',
        gridRowGap: `${mediumHorizontalGap * baseUnit}px`,
        gridTemplateAreas: `'${mainCardArea}' '${firstCardArea}' '${secondCardArea}' '${thirdCardArea}'`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        gridTemplateRows: 'auto auto auto auto',
        gridRowGap: `${smallHorizontalGap * baseUnit}px`,
        gridTemplateAreas: `'${mainCardArea}' '${firstCardArea}' '${secondCardArea}' '${thirdCardArea}'`,
      },
    },
    minorCard: {
      [MEDIA_BREAKPOINTS.small]: {
        paddingLeft: `${0.5 * baseUnit}px`,
        paddingRight: `${0.5 * baseUnit}px`,
      },
    },
    mainCard: {
      gridArea: mainCardArea,
    },
    firstCard: {
      gridArea: firstCardArea,
      ...(showDividers ? dividerStyle : {}),
    },
    secondCard: {
      gridArea: secondCardArea,
      ...(showDividers ? dividerStyle : {}),
    },
    thirdCard: {
      gridArea: thirdCardArea,
    },
  });
};

export const ThreeHCOneOTC: React.FunctionComponent<ThreeHCOneOTCDataProps> = props => {
  const { cards, paddingFactors = { large: 0.5, medium: 0.5, small: 0 } } = props;
  const themeProps = useTheme<ThreeHCOneOTCThemeProps>(componentConfigThemeKey);
  const styles = getStyles(themeProps);
  const paddingStyle = getLayoutComponentPadding(paddingFactors);
  const onTopCardHeightCalculation = createImageHeightCalculationMethods('ASPECT_RATIO', 'ASPECT_RATIO', '100%_HEIGHT');

  const {
    titleFontSizeSmall,
    titleFontSizeMedium,
    titleFontSizeLarge,
    aspectRatioLarge,
    aspectRatioSmall,
    aspectRatioMedium,
    showDescriptionInLargeScreen,
    showDescriptionInMediumScreen,
    metadataFontSizeLarge,
    metadataFontSizeMedium,
    metadataFontSizeSmall,
    authorColor,
    insideCardSmallHorizontalGap,
    showAuthorInSmallScreenForHC,
    showAuthorInLargeScreenForHC,
    showAuthorInMediumScreenForHC,
  } = themeProps;

  const themeContextHorizontalCard = useThemeOverride<HorizontalCardThemeProps>({
    titleFontSizeSmall,
    titleFontSizeMedium,
    titleFontSizeLarge,
    aspectRatioLarge,
    aspectRatioSmall,
    aspectRatioMedium,
    showDescriptionInLargeScreen,
    showDescriptionInMediumScreen,
    metadataFontSizeLarge,
    metadataFontSizeMedium,
    metadataFontSizeSmall,
    showAuthorInSmallScreen: showAuthorInSmallScreenForHC,
    showAuthorInLargeScreen: showAuthorInLargeScreenForHC,
    showAuthorInMediumScreen: showAuthorInMediumScreenForHC,
  }, horizontalCardThemeKey);

  const themeContextOnTopCard = useThemeOverride<OnTopCardComponentThemeProps>({
    horizontalGapSmall: insideCardSmallHorizontalGap,
    titleFontSizeSmall,
    authorColor,
  }, onTopCardThemeKey);

  return (
    <ErrorBoundary>
      {cards && cards.length > 0
        && (
          <div className={css(styles.section, paddingStyle)}>
            <ThemeContext.Provider value={themeContextHorizontalCard}>
              <div className={css(styles.firstCard, styles.minorCard)}>
                <HorizontalCard {...cards[1]} imageWidths={imageWidthsHorizontalCard} />
              </div>
              <div className={css(styles.secondCard, styles.minorCard)}>
                <HorizontalCard {...cards[2]} imageWidths={imageWidthsHorizontalCard} />
              </div>
              <div className={css(styles.thirdCard, styles.minorCard)}>
                <HorizontalCard {...cards[3]} imageWidths={imageWidthsHorizontalCard} />
              </div>
            </ThemeContext.Provider>
            <div className={css(styles.mainCard)}>
              <ThemeContext.Provider value={themeContextOnTopCard}>
                <OnTopCard
                  {...cards[0]}
                  imageWidths={imageWidthsOnTopCard}
                  imageHeightCalculationMethods={onTopCardHeightCalculation}
                  lazyLoad
                />
              </ThemeContext.Provider>
            </div>
          </div>
        )}
    </ErrorBoundary>
  );
};
