import React from 'react';
import { FontTypeFontFace, FontFaceURLData } from 'mm-theme-configuration/dist/interfaces';
import { css, StyleSheet } from 'aphrodite/no-important';

interface FontFaceProps {
  fontConfiguration: FontTypeFontFace;
}

const createFontFaceSRC = (fonts: Array<FontFaceURLData>) => {
  return fonts.reduce((acc, currentFont, index) => {
    if (index === 0) {
      return `url("${currentFont.url}") format("${currentFont.type}")`;
    }
    return `${acc}, url("${currentFont.url}") format("${currentFont.type}")`;
  }, '');
};

export const FontFace: React.FunctionComponent<FontFaceProps> = props => {
  const { fontConfiguration } = props;
  const { family, weight, fontURLs } = fontConfiguration;
  const customFont = {
    fontFamily: family,
    fontWeight: weight,
    src: createFontFaceSRC(fontURLs),
    fontDisplay: 'swap',
  };
  const customFontStyle = StyleSheet.create({
    style: {
      fontFamily: [customFont],
    },
  });
  css(customFontStyle.style);
  return null;
};
