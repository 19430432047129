import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { LARGE_SCREEN_SIZE, MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { AdditionalStyleSheet } from '../../components.utils';

interface ContentAndSidebarProps {
  additionalStyles?: AdditionalStyleSheet;
}

const getStyle = () => {
  return StyleSheet.create({
    wrapper: {
      maxWidth: `${LARGE_SCREEN_SIZE}px`,
      margin: 'auto',
      display: 'grid',
      [MEDIA_BREAKPOINTS.large]: {
        gridTemplateColumns: '2fr 1fr',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        width: '75%',
        gridTemplateColumns: '1fr',
      },
      [MEDIA_BREAKPOINTS.small]: {
        width: '100%',
        gridTemplateColumns: '1fr',
      },
    },
  });
};

export const ContentAndSidebar: React.FunctionComponent<ContentAndSidebarProps> = props => {
  const { children, additionalStyles } = props;
  const style = getStyle();
  return <ErrorBoundary><div className={css(style.wrapper, additionalStyles?.style)}>{children}</div></ErrorBoundary>;
};
