import { URLParser } from '../utils/URLParser';

export const afterPathnameKey = (traffic, key) => {
  const urlObject = new URLParser(traffic.url);
  const pathnameParts = urlObject.pathname.split('/');
  const keyIndex = pathnameParts.indexOf(key);
  if (keyIndex === -1 || keyIndex === pathnameParts.length - 1) {
    return null;
  }
  return pathnameParts[keyIndex + 1];
};
