import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Colors } from 'mm-theme-configuration';
import { Link } from '../../partials/link/Link';
import { useTheme } from '../../../theming/useTheme';

interface AuthorProps {
  name: string;
  link?: string;
  prefix?: string;
}

const getLinkStyle = (color: string) => StyleSheet.create({
  link: {
    color,
    textDecoration: 'underline',
  },
});

export const Author: React.FunctionComponent<AuthorProps> = props => {
  const { name, link, prefix = '' } = props;
  const { colors }: { colors: Colors } = useTheme();
  if (link) {
    const style = getLinkStyle(colors.primary).link;
    return (
      <React.Fragment>
        {prefix}
        <Link href={link} className={css(style)}>{name}</Link>
      </React.Fragment>
    );
  }

  return <React.Fragment>{`${prefix}${name}`}</React.Fragment>;
};
