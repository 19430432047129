import { predicates } from '../predicates/index';
import { selectors } from '../selectors/index';
import { TRAFFIC_SOURCES } from '../constants';

export const rss = {
  rule: predicates.searchParamEqualsValue('utm_source', 'rss'),
  extractor: (traffic) => {
    const trafficSource = TRAFFIC_SOURCES.RSS;
    const trafficId = selectors.referrerHostname(traffic);

    return {
      trafficSource,
      trafficId,
    };
  },
};
