import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { useTheme } from '../../../theming/useTheme';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { BodyTag } from './BodyTag';
import { MainWrapperConfigThemeKey, MainWrapperThemeProps } from './mainWrapper.theme';
import { useHeaderHeights } from '../../components/navigation/header/useHeaderHeights';

interface MainWrapperProps {
  configThemeKey: MainWrapperConfigThemeKey;
}

export const MainWrapperForAmpHeaderNavigation: React.FunctionComponent<MainWrapperProps> = ({ children, configThemeKey }) => {
  const { baseUnit, backgroundColor, maxWidth } = useTheme<MainWrapperThemeProps>(configThemeKey);
  const headerHeights = useHeaderHeights();
  const style = {
    backgroundColor,
    maxWidth: `${maxWidth}px`,
    margin: '0 auto',
    paddingBottom: `${baseUnit}px`,
    [MEDIA_BREAKPOINTS.large]: {
      paddingTop: `${headerHeights.large * baseUnit + baseUnit / 4}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      paddingTop: `${headerHeights.medium * baseUnit + baseUnit / 4}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      paddingTop: `${headerHeights.small * baseUnit + baseUnit / 4}px`,
    },
  };

  return (
    <React.Fragment>
      <BodyTag mainConfigThemeKey={configThemeKey} />
      <main
        className={css(StyleSheet.create({ style }).style)}
      >
        {children}
      </main>
    </React.Fragment>
  );
};
