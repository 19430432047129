import { FontSizeMap, SCREEN_SIZE, ScreenSize, TypographySize } from 'mm-theme-configuration/dist/consts';

interface LineHeightRatios {
  large: number;
  medium: number;
  small: number;
}

const lineHeightRatioMap: { [key in TypographySize]: LineHeightRatios } = {
  gigantic: {
    large: 1.4,
    medium: 1.375,
    small: 1.333,
  },
  huge: {
    large: 1.375,
    medium: 1.333,
    small: 1.4,
  },
  large: {
    large: 1.4,
    medium: 1.4,
    small: 1.5,
  },
  bigger: {
    large: 1.5,
    medium: 1.5,
    small: 1.43,
  },
  big: {
    large: 1.43,
    medium: 1.43,
    small: 1.333,
  },
  normal: {
    large: 1.5,
    medium: 1.5,
    small: 1.43,
  },
  tiny: {
    large: 1.333,
    medium: 1.333,
    small: 1.4,
  },
};

type TypographySizeStyleByScreenSize = {
  breakpoints: {
    [key in ScreenSize]: {
      fontSize: string;
      lineHeight: string;
    }
  };
}

type TypographySizeStyleCrossScreenSizes = {
  style: {
    fontWeight: number;
    fontFamily: string;
  };
}

export type TranslatedFontSize = {
  [key in TypographySize]: TypographySizeStyleByScreenSize & TypographySizeStyleCrossScreenSizes
};

const getFontSize = (size: number) => `${size}px`;
const getLineHeightByFontSize = (fontSize: number, size: TypographySize, screenSize: ScreenSize) => `${Math.round(fontSize * lineHeightRatioMap[size][screenSize])}px`;

export const translateFontSizes = (fontSizeMap: FontSizeMap) => {
  return (Object.keys(fontSizeMap) as TypographySize[]).reduce((sizesObj: TranslatedFontSize, current: TypographySize) => {
    return {
      ...sizesObj,
      [current]: {
        breakpoints: {
          [SCREEN_SIZE.LARGE]: {
            fontSize: getFontSize(fontSizeMap[current].screenSizes.large),
            lineHeight: getLineHeightByFontSize(fontSizeMap[current].screenSizes.large, current, SCREEN_SIZE.LARGE as ScreenSize),
          },
          [SCREEN_SIZE.MEDIUM]: {
            fontSize: getFontSize(fontSizeMap[current].screenSizes.medium),
            lineHeight: getLineHeightByFontSize(fontSizeMap[current].screenSizes.medium, current, SCREEN_SIZE.MEDIUM as ScreenSize),
          },
          [SCREEN_SIZE.SMALL]: {
            fontSize: getFontSize(fontSizeMap[current].screenSizes.small),
            lineHeight: getLineHeightByFontSize(fontSizeMap[current].screenSizes.small, current, SCREEN_SIZE.SMALL as ScreenSize),
          },
        },
        style: {
          fontWeight: fontSizeMap[current].fontWeight,
          fontFamily: fontSizeMap[current].fontFamily,
        },
      },
    };
  }, {} as TranslatedFontSize);
};
