import * as React from 'react';

interface AMPAmplitudeProps {
  amplitudeApiKey: string;
}

export const AMPAmplitude: React.FunctionComponent<AMPAmplitudeProps> = props => {
  const { amplitudeApiKey } = props;
  return (
    <React.Fragment>
      {/*
    // @ts-ignoreTS2339: Property 'amp-analytics' does not exist on type 'JSX.IntrinsicElements'. */}
      <amp-analytics type="amplitude" id="amplitude">
        <script
          type="application/json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify({
            vars: {
              apiKey: amplitudeApiKey,
            },
            triggers: {
              pageView: {
                on: 'visible',
                request: 'event',
                extraUrlParams: {
                  event: {
                    event_type: 'Page View',
                    event_properties: {
                      // eslint-disable-next-line no-template-curly-in-string
                      'Page URL': '${ampdocUrl}',
                    },
                    user_properties: {
                      // eslint-disable-next-line no-template-curly-in-string
                      utm_source: '${queryParam(utm_source)}',
                      // eslint-disable-next-line no-template-curly-in-string
                      utm_campaign: '${queryParam(utm_campaign)}',
                      // eslint-disable-next-line no-template-curly-in-string
                      utm_medium: '${queryParam(utm_medium)}',
                    },
                  },
                },
              },
            },
          }) }}
        />
        {/*
    // @ts-ignoreTS2339: Property 'amp-analytics' does not exist on type 'JSX.IntrinsicElements'. */}
      </amp-analytics>
    </React.Fragment>
  );
};
