import { StyleSheet } from 'aphrodite/no-important';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { AdditionalStyleSheet } from '../../components.utils';

export const createBlockStyle = (baseUnit: BaseUnit, modifier: number) => {
  return {
    marginTop: `${baseUnit * modifier}px`,
  };
};

export const createBlockStyleSheet = (baseUnit: BaseUnit, modifier: number) => StyleSheet.create({
  style: createBlockStyle(baseUnit, modifier),
}) as AdditionalStyleSheet;

export const idGenerator = () => `_${Math.random().toString(36).substr(2, 9)}`;
