import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { BaseUnit } from '../../theming/baseUnitDefinition';
import { useTheme } from '../../theming/useTheme';
import { MEDIA_BREAKPOINTS } from '../../mediaQueries.const';

interface DisqusProps {
  resourceID: string;
  canonical: string;
  disqusSource: string;
}

export const Disqus: React.FunctionComponent<DisqusProps> = props => {
  const { resourceID, canonical, disqusSource } = props;

  const createStyle = (baseUnit: BaseUnit) => {
    return StyleSheet.create({
      disqusWrapper: {
        paddingLeft: `${baseUnit + 80}px`,
        paddingRight: `${baseUnit}px`,
        [MEDIA_BREAKPOINTS.small]: {
          paddingLeft: `${baseUnit}px`,
        },
      },
    });
  };

  const scriptContent = `
    var disqus_config = function () {
    this.page.url = '${canonical}';  
    this.page.identifier = '${resourceID}';
    };
    (function() { 
    var d = document, s = d.createElement('script');
    s.src = 'https://${disqusSource}/embed.js';
    s.setAttribute('data-timestamp', +new Date());
    (d.head || d.body).appendChild(s);
    })();
  `;

  React.useEffect(() => {
    const scriptElement = document.createElement('script');
    scriptElement.innerHTML = scriptContent;
    document.head.appendChild(scriptElement);
  }, [scriptContent]);

  const { baseUnit } = useTheme();
  const { disqusWrapper } = createStyle(baseUnit);

  return (
    <div id="disqus_thread" className={css(disqusWrapper)} />
  );
};
