import { pipelines } from './pipelines/index';
import { TRAFFIC_SOURCES } from './constants';

const check = (rule) => {
  return (traffic) => {
    return rule(traffic);
  };
};

export const checkAllRules = (traffic) => {
  const pipeline = pipelines.find((item) => {
    return check(item.rule)(traffic);
  });
  if (pipeline) {
    return pipeline.extractor(traffic);
  }
  return {
    trafficSource: TRAFFIC_SOURCES.UNKNOWN,
    trafficId: null,
  };
};
