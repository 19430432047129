import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { TypographySize } from 'mm-theme-configuration/src/consts';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { createImageWidths } from '../../partials/image/image.utils';
import { useTheme } from '../../../theming/useTheme';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { baseUnit } from '../../../theming/baseUnitDefinition';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { HorizontalCard } from '../../partials/cards/horizontalCard/HorizontalCard';
import { componentConfigThemeKey as horizontalCardConfigThemeKey, HorizontalCardThemeProps } from '../../partials/cards/horizontalCard/horizontalCard.theme';
import { getLayoutComponentPadding, LayoutComponentBaseProps } from '../pageLayouts/layout.utils';

export const componentConfigThemeKey = 'threeHC';

interface ThreeHCDataProps extends LayoutComponentBaseProps {
    cards: Array<CardComponentDataProps>;
}

export interface ThreeHCThemeProps {
    largeHorizontalGap: number;
    mediumHorizontalGap: number;
    smallHorizontalGap: number;
    descriptionFontSizeLarge: TypographySize;
    descriptionFontSizeMedium: TypographySize;
    descriptionFontSizeSmall: TypographySize;
}

const createStyle = (themeProps: ThreeHCThemeProps) => {
  const { largeHorizontalGap, mediumHorizontalGap, smallHorizontalGap } = themeProps;
  return StyleSheet.create({
    wrapper: {
      display: 'grid',
      [MEDIA_BREAKPOINTS.large]: {
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto',
        gridRowGap: `${largeHorizontalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        gridTemplateColumns: '1fr',
        gridTemplateRows: '1fr 1fr 1fr',
        gridRowGap: `${mediumHorizontalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto',
        gridRowGap: `${smallHorizontalGap * baseUnit}px`,
      },
    },
  });
};

const imageWidths = createImageWidths(180, 360, 540);

export const ThreeHC: React.FunctionComponent<ThreeHCDataProps> = props => {
  const { cards, paddingFactors } = props;
  const paddingStyle = getLayoutComponentPadding(paddingFactors);
  const themeProps = useTheme<ThreeHCThemeProps>(componentConfigThemeKey);
  const { descriptionFontSizeLarge, descriptionFontSizeMedium, descriptionFontSizeSmall } = themeProps;
  const style = createStyle(themeProps);
  const themeContextVC = useThemeOverride<HorizontalCardThemeProps>({
    descriptionFontSizeLarge,
    descriptionFontSizeMedium,
    descriptionFontSizeSmall,
    titleFontSizeLarge: 'large',
    titleFontSizeMedium: 'large',
    titleFontSizeSmall: 'large',
    metadataFontSizeLarge: 'big',
    metadataFontSizeMedium: 'big',
    metadataFontSizeSmall: 'big',
    cardProportionsLarge: { x: 2, y: 1 },
    cardProportionsMedium: { x: 1, y: 1 },
    shouldReverseImageAlignment: true,
    verticalGapLarge: 1,
    verticalGapMedium: 1,
    verticalGapSmall: 1,
    horizontalGapLarge: 0.75,
    horizontalGapMedium: 0.75,
    horizontalGapSmall: 0.75,
  }, horizontalCardConfigThemeKey);
  return (
    <ErrorBoundary>
      {cards && cards.length > 0
            && (
            <div className={css(paddingStyle, style.wrapper)}>
              <ThemeContext.Provider value={themeContextVC}>
                <HorizontalCard {...cards[0]} imageWidths={imageWidths} />
                <HorizontalCard {...cards[1]} imageWidths={imageWidths} />
                <HorizontalCard {...cards[2]} imageWidths={imageWidths} />
              </ThemeContext.Provider>
            </div>
            ) }
    </ErrorBoundary>
  );
};
