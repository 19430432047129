import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Colors } from 'mm-theme-configuration';
import { TYPOGRAPHY_SIZE } from 'mm-theme-configuration/dist/consts';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { Icon } from '../../partials/icon/Icon';
import { CloseIcon } from '../../partials/icon/icons';
import { AdditionalStyleSheet } from '../../components.utils';
import { P, semanticCSS } from '../../../typography/semanticTags';
import { ellipsify } from '../../../typography/ellipsify';
import { useTheme } from '../../../theming/useTheme';
import { getPublishDateOrHoursDisplay } from '../../../utils/time.utils';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';

interface DrawerMenuProps {
  articles: CardComponentDataProps[];
  onClick: () => void;
  articlesNum: string;
}

const getStyles = (colors: Colors) => {
  return StyleSheet.create({
    wrapper: {
      height: '100vh',
      width: '280px',
      backgroundColor: colors.darkGrey,
      position: 'absolute',
      top: 0,
      right: 0,
      zIndex: 1600,
      [MEDIA_BREAKPOINTS.small]: {
        width: '100%',
      },
    },
    innerWrapper: {
      padding: '20px',
    },
    headerWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '5px',
    },
    text: {
      color: colors.lightGrey,
    },
    divider: {
      width: '100%',
      height: '1px',
      backgroundColor: colors.lightGrey,
      margin: '5px 0px 15px 0px',
    },
    link: {
      color: colors.white,
      ...ellipsify(3).style,
    },
    articlesWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
    },
    date: {
      color: '#8B8B8B',
    },
  });
};

const iconStyles = StyleSheet.create({
  style: {
    fill: '#BCB7D2',
  },
}) as AdditionalStyleSheet;

const getFormattedDate = () => {
  const options = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  } as Intl.DateTimeFormatOptions;

  return new Date().toLocaleDateString('en-US', options);
};
export const DrawerMenu: React.FunctionComponent<DrawerMenuProps> = props => {
  const { articles, onClick, articlesNum } = props;
  const { colors }: { colors: Colors } = useTheme();

  const styles = getStyles(colors);

  const tinyFontSizes = {
    fontSizeSmall: TYPOGRAPHY_SIZE.TINY,
    fontSizeMedium: TYPOGRAPHY_SIZE.TINY,
    fontSizeLarge: TYPOGRAPHY_SIZE.TINY,
  };

  const normalFontSizes = {
    fontSizeSmall: TYPOGRAPHY_SIZE.NORMAL,
    fontSizeMedium: TYPOGRAPHY_SIZE.NORMAL,
    fontSizeLarge: TYPOGRAPHY_SIZE.NORMAL,
  };

  return (
    <div className={css(styles.wrapper)}>
      <div className={css(styles.innerWrapper)}>
        <div className={css(styles.headerWrapper)}>
          <P styles={semanticCSS(styles.text)} {...normalFontSizes}>
            {`NEW ARTICLES (${articlesNum})`}
          </P>
          <Icon icon={CloseIcon} additionalStyle={iconStyles} onClick={onClick} />
        </div>
        <P styles={semanticCSS(styles.text)} {...tinyFontSizes}>{getFormattedDate()}</P>
        <div className={css(styles.divider)} />
        <div className={css(styles.articlesWrapper)}>
          {articles.map(article => {
            return (
              <div>
                {(article.updatedAtISO && article.updatedAt)
              && (
              <P
                styles={semanticCSS(styles.date)}
                {...tinyFontSizes}
              >
                {getPublishDateOrHoursDisplay(article.updatedAtISO, article.updatedAt)}
              </P>
              )}
                <a
                  href={article.articleUrl}
                  className={css(styles.link)}
                >
                  <P {...tinyFontSizes}>{article.title}</P>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
