import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { TYPOGRAPHY_SIZE } from 'mm-theme-configuration/dist/consts';
import { Colors } from 'mm-theme-configuration/dist/interfaces';
import { NavigationLink } from '../oldHeader/primaryHeader/PrimaryHeader';
import { Link } from '../../../../partials/link/Link';
import { Subscribe } from './ThreeRowsHeader';
import { useTheme } from '../../../../../theming/useTheme';
import { TranslatedFontSize } from '../../../../../theming/fontSizeTranslator';
import { getStyleBySize } from '../../../../../typography/semanticTags/semanticTags.utils';
import { MEDIA_BREAKPOINTS } from '../../../../../mediaQueries.const';
import { componentConfigThemeKey as threeRowsHeaderConfigThemeKey, ThreeRowsHeaderThemeProps } from './ThreeRowsHeader.theme';

interface FirstRowHeaderProps {
  links: NavigationLink[];
  subscribeLink?: Subscribe;
}

const getFirstRowStyle = (colors: Colors, firstLineBackgroundColor: string) => {
  return StyleSheet.create({
    wrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0 16px 0 16px',
      height: '30px',
      textTransform: 'uppercase',
      backgroundColor: firstLineBackgroundColor,
      [MEDIA_BREAKPOINTS.medium]: {
        height: '40px',
      },
    },
    linksList: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'nowrap',
      columnGap: '16px',
      listStyle: 'none',
      overflowX: 'scroll',
      overflowY: 'hidden',
      whiteSpace: 'nowrap',
      maskImage: 'linear-gradient(90deg, white 95%, transparent)',
      padding: '0 20px 0 0',
      margin: '0px',
      '::-webkit-scrollbar': {
        display: 'none',
      },
      scrollbarWidth: 'none',
    },
    link: {
      color: colors.white,
      textDecoration: 'none',
      ':hover': {
        textDecoration: 'underline',
      },
    },
    subscribe: {
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      paddingLeft: '16px',
    },
  });
};

export const FirstRowHeader: React.FunctionComponent<FirstRowHeaderProps> = props => {
  const { links, subscribeLink } = props;
  const { colors, fontSizesStyles }: { colors: Colors; fontSizesStyles: TranslatedFontSize } = useTheme();
  const { firstLineBackgroundColor } = useTheme<ThreeRowsHeaderThemeProps>(threeRowsHeaderConfigThemeKey);
  const fontStyle = getStyleBySize(fontSizesStyles, { fontSizeSmall: TYPOGRAPHY_SIZE.TINY, fontSizeMedium: TYPOGRAPHY_SIZE.TINY, fontSizeLarge: TYPOGRAPHY_SIZE.TINY });
  const style = getFirstRowStyle(colors, firstLineBackgroundColor);

  return (
    <div className={css(style.wrapper, fontStyle)}>
      <ul className={css(style.linksList)}>
        {links.map((navigationLink: NavigationLink, index: number) => {
          return (
            <li key={index}>
              <Link className={css(style.link, fontStyle)} href={navigationLink.link.href} target="_blank">
                <span>{navigationLink.link.text}</span>
              </Link>
            </li>
          );
        })}
      </ul>
      {subscribeLink && (
        <Link className={css(style.link, style.subscribe, fontStyle)} href={subscribeLink.url}>
          <span>{subscribeLink.linkText}</span>
        </Link>
      )}
    </div>

  );
};
