import * as React from 'react';
import { StyleSheet } from 'aphrodite/no-important';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { AdditionalStyleSheet, Style } from '../../components.utils';
import { MMPlayer } from './MMPlayer';
import { BlockWidths } from '../../components/pageLayouts/BlockTypes.utils';
import { createBlockStyle } from '../blockMutualStyle';

interface MMPlayerDataProps {
  html: string;
  version?: string;
  dataId?: string;
  defaultBlocksWidths: BlockWidths;
}

type ArticleMMPlayerProps = MMPlayerDataProps & AdditionalStyleSheet;

const createStyle = (defaultBlocksWidths: BlockWidths, style: Style) => {
  return StyleSheet.create({
    style: {
      ...style,
      ...createBlockStyle(defaultBlocksWidths),
    },
  }) as AdditionalStyleSheet;
};

export const ArticleMMPlayer: React.FunctionComponent<ArticleMMPlayerProps> = props => {
  const { style, defaultBlocksWidths } = props;
  const additionalStyle = createStyle(defaultBlocksWidths, style);
  return (
    <ErrorBoundary>
      <MMPlayer {...props} additionalStyle={additionalStyle} />
    </ErrorBoundary>
  );
};
