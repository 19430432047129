import { StyleSheet, css } from 'aphrodite/no-important';
import * as React from 'react';
import { AnalyticsAMPDataVars, Link } from '../../partials/link/Link';
import { useButtonTheme } from './button.utils';
import { style } from '../../../typography/typography.style';
import { AdditionalStyleSheet } from '../../components.utils';

const getStyles = (isDisabled: boolean) => {
  return StyleSheet.create({
    style: {
      display: 'inline-flex',
      pointerEvents: isDisabled ? 'none' : 'unset',
    },
  });
};

interface LinkWithButtonStyleProps {
  disabled?: boolean;
  secondary? : boolean;
  additionalStyle?: AdditionalStyleSheet;
  additionalTextStyle?: object;
}

export const LinkWithButtonStyle: React.FunctionComponent<React.HTMLProps<HTMLAnchorElement> & LinkWithButtonStyleProps & AnalyticsAMPDataVars> = ({ children, disabled = false, secondary = false, ...props }) => {
  const { buttonStyle, boxShadowStyle, textStyle } = useButtonTheme(disabled, secondary);
  const linkStyle = getStyles(disabled);
  const { additionalStyle, additionalTextStyle, ...finalProps } = props;

  const regularClasses = [buttonStyle.button, linkStyle.style, boxShadowStyle.style, StyleSheet.create({ style }).style, textStyle, additionalStyle && additionalStyle.style, additionalTextStyle];
  const disabledClasses = [buttonStyle.button, linkStyle.style, StyleSheet.create({ style }).style, textStyle, additionalStyle && additionalStyle.style, additionalTextStyle];

  return (
    <Link
      {...finalProps}
      className={css(...(disabled ? disabledClasses : regularClasses))}
    >
      {children}
    </Link>
  );
};
