import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import {
  getLayoutComponentPadding,
  getSectionStyle,
  LayoutComponentBaseProps,
  MaxWidthValues,
} from '../pageLayouts/layout.utils';
import { baseUnit } from '../../../theming/baseUnitDefinition';

interface HalfScreenWrapperProps extends LayoutComponentBaseProps {
  maxWidth?: MaxWidthValues;
}

const createStyle = () => StyleSheet.create({
  wrapper: {
    display: 'grid',
    boxSizing: 'border-box',
    [MEDIA_BREAKPOINTS.large]: {
      gridTemplateColumns: '1fr 1fr',
      gridColumnGap: `${baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      gridTemplateColumns: '1fr',
      gridRowGap: `${baseUnit * 1.5}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      gridTemplateColumns: '1fr',
      gridRowGap: `${baseUnit * 1.5}px`,
    },
  },
});

export const HalfScreenWrapper: React.FunctionComponent<HalfScreenWrapperProps> = props => {
  const { children, maxWidth, paddingFactors } = props;
  const style = createStyle();
  const sectionStyle = getSectionStyle(maxWidth);
  const paddingStyle = getLayoutComponentPadding(paddingFactors);

  return (
    <div className={css(style.wrapper, sectionStyle, paddingStyle)}>
      {children}
    </div>
  );
};
