import { StyleSheet } from 'aphrodite/no-important';
import React from 'react';
import { AuthorPremiumThemeProps, componentConfigThemeKey } from './AuthorPremium.theme';
import { useTheme } from '../../../theming/useTheme';
import { H1, semanticCSS } from '../../../typography/semanticTags';

const createAuthorPremiumTitleStyle = (titleColor: string) => {
  return StyleSheet.create({
    style: {
      color: titleColor,
    },
  }).style;
};

interface AuthorPremiumTitleProps {
  name: string;
}

export const AuthorPremiumTitle: React.FunctionComponent<AuthorPremiumTitleProps> = props => {
  const { name } = props;
  const themeProps = useTheme<AuthorPremiumThemeProps>(componentConfigThemeKey);
  const { titleFontSizeLarge, titleFontSizeSmall, titleFontSizeMedium, titleColor } = themeProps;
  const authorPremiumTitleStyle = createAuthorPremiumTitleStyle(titleColor);
  return (
    <H1
      fontSizeLarge={titleFontSizeLarge}
      fontSizeSmall={titleFontSizeSmall}
      fontSizeMedium={titleFontSizeMedium}
      styles={semanticCSS(authorPremiumTitleStyle)}
    >
      {name}
    </H1>
  );
};
