import { StyleSheet } from 'aphrodite/no-important';
import { TypographySize } from 'mm-theme-configuration/dist/consts';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { createStyleForBoxShadow } from '../../../theming/boxShadow';
import { TranslatedFontSize } from '../../../theming/fontSizeTranslator';
import { useTheme } from '../../../theming/useTheme';
import { getStyleBySize } from '../../../typography/semanticTags/semanticTags.utils';

export const componentConfigThemeKey = 'button';

export interface ButtonComponentThemeProps {
  backgroundColorNormal: string;
  backgroundColorHover: string;
  backgroundColorActive: string;
  backgroundColorNormalSecondary: string;
  backgroundColorHoverSecondary: string;
  backgroundColorActiveSecondary: string;
  textColorNormal: string;
  textColorHover: string;
  textColorActive: string;
  textColorNormalSecondary: string;
  textColorHoverSecondary: string;
  textColorActiveSecondary: string;
  borderRadius: number;
  borderWidthNormal: number;
  borderWidthHover: number;
  borderWidthActive: number;
  borderColorNormal: string;
  borderColorHover: string;
  borderColorActive: string;
  borderColorNormalSecondary: string;
  borderColorHoverSecondary: string;
  borderColorActiveSecondary: string;
  boxShadowTypeNormal: string;
  boxShadowTypeHover: string;
  boxShadowTypeActive: string;
  fontSizeLarge: TypographySize;
  fontSizeMedium: TypographySize;
  fontSizeSmall: TypographySize;
  baseUnit: BaseUnit;
  fontSizesStyles: TranslatedFontSize;
}

interface ButtonBasicProps {
  backgroundColorNormal: string;
  backgroundColorHover: string;
  backgroundColorActive: string;
  textColorNormal: string;
  textColorHover: string;
  textColorActive: string;
  borderRadius: number;
  baseUnit: BaseUnit;
}

const getStyle = ({
  backgroundColorNormal,
  backgroundColorHover,
  backgroundColorActive,
  textColorNormal,
  textColorHover,
  textColorActive,
  borderRadius,
  baseUnit,
}: ButtonBasicProps, disabled: boolean) => {
  const hoverAndActiveStyle = {
    '@media (hover: hover)': {
      ':hover': {
        backgroundColor: backgroundColorHover,
        color: textColorHover,
      },
    },
    ':active': {
      backgroundColor: backgroundColorActive,
      color: textColorActive,
    },
  };
  return StyleSheet.create({
    button: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: disabled ? 'unset' : 'pointer',
      outline: 'none',
      border: 'none',
      padding: `0 ${baseUnit}px`,
      borderRadius: `${borderRadius}px`,
      backgroundColor: backgroundColorNormal,
      minWidth: '120px',
      height: '40px',
      boxSizing: 'border-box',
      color: textColorNormal,
      ...disabled ? {} : hoverAndActiveStyle,
    },
  });
};

const getButtonStyles = (themeProps: ButtonComponentThemeProps, disabled: boolean, secondary: boolean) => {
  const {
    fontSizesStyles,
    boxShadowTypeNormal,
    boxShadowTypeHover,
    boxShadowTypeActive,
    fontSizeLarge,
    fontSizeMedium,
    fontSizeSmall,
    borderRadius,
    baseUnit,
    borderColorNormal,
    borderColorActive,
    borderColorHover,
    borderWidthNormal,
    borderWidthActive,
    borderWidthHover,
    backgroundColorNormal,
    backgroundColorHover,
    backgroundColorActive,
    textColorNormal,
    textColorHover,
    textColorActive,
    borderColorNormalSecondary,
    borderColorActiveSecondary,
    borderColorHoverSecondary,
    backgroundColorNormalSecondary,
    backgroundColorHoverSecondary,
    backgroundColorActiveSecondary,
    textColorNormalSecondary,
    textColorHoverSecondary,
    textColorActiveSecondary } = themeProps;

  const borderBoxShadowPrimary = {
    borderColorTypeActive: borderColorActive,
    borderColorTypeHover: borderColorHover,
    borderColorTypeNormal: borderColorNormal,
    borderWidthTypeActive: borderWidthActive,
    borderWidthTypeHover: borderWidthHover,
    borderWidthTypeNormal: borderWidthNormal,
  };

  const borderBoxShadowSecondary = {
    borderColorTypeActive: borderColorActiveSecondary,
    borderColorTypeHover: borderColorHoverSecondary,
    borderColorTypeNormal: borderColorNormalSecondary,
    borderWidthTypeActive: borderWidthActive,
    borderWidthTypeHover: borderWidthHover,
    borderWidthTypeNormal: borderWidthNormal,
  };

  return {
    buttonStyle: secondary
      ? getStyle({
        backgroundColorNormal: backgroundColorNormalSecondary,
        backgroundColorHover: backgroundColorHoverSecondary,
        backgroundColorActive: backgroundColorActiveSecondary,
        textColorNormal: textColorNormalSecondary,
        textColorHover: textColorHoverSecondary,
        textColorActive: textColorActiveSecondary,
        borderRadius,
        baseUnit }, disabled)
      : getStyle({
        backgroundColorNormal,
        backgroundColorHover,
        backgroundColorActive,
        textColorNormal,
        textColorHover,
        textColorActive,
        borderRadius,
        baseUnit }, disabled),

    boxShadowStyle: createStyleForBoxShadow({
      boxShadowTypeNormal,
      boxShadowTypeHover,
      boxShadowTypeActive,
      borderBoxShadow: secondary ? borderBoxShadowSecondary : borderBoxShadowPrimary,
    }),
    textStyle: getStyleBySize(fontSizesStyles, {
      fontSizeSmall,
      fontSizeMedium,
      fontSizeLarge,
    }),
  };
};

export const useButtonTheme = (disabled = false, secondary = false) => {
  const themeProps = useTheme<ButtonComponentThemeProps>(componentConfigThemeKey);

  return getButtonStyles(themeProps, disabled, secondary);
};
