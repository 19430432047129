import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { useTheme } from '../../../theming/useTheme';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { SocialButton, ShareMenu } from './ShareMenu';
import { BaseUnit } from '../../../theming/baseUnitDefinition';

const componentConfigurationKey = 'articleShareButton';

interface ShareButtonDataProps {
  shareConfig: SocialButton[];
  canonical: string;
  smallScreenOnly?: boolean;
}

interface ShareButtonThemeProps {
  baseUnit: BaseUnit;
  buttonBackgroundColor: string;
  buttonHoverColor: string;
}

const shareButtonSize = {
  xLarge: 48,
  large: 48,
  medium: 40,
  small: 40,
};

const getStyles = (baseUnit: BaseUnit, backgroundColor: string, buttonHoverColor: string, smallScreenOnly: boolean | undefined = false) => {
  return StyleSheet.create({
    wrapper: {
      position: 'absolute',
      right: 0,
      zIndex: 1000,
      marginRight: `${baseUnit}px`,
      [MEDIA_BREAKPOINTS.large]: {
        width: '40px',
        top: `calc((${shareButtonSize.large}px - 100%)/-2)`,
        display: smallScreenOnly ? 'none' : 'block',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        width: '32px',
        top: `calc((${shareButtonSize.medium}px - 100%)/-2)`,
        display: smallScreenOnly ? 'none' : 'block',
      },
      [MEDIA_BREAKPOINTS.small]: {
        width: '32px',
        top: `calc((${shareButtonSize.small}px - 100%)/-2)`,
        display: 'block',
      },
    },
    shareButton: {
      outline: 'none',
      border: 'none',
      background: 'transparent',
      borderRadius: '100%',
      '-webkit-tap-highlight-color': 'transparent',
      [MEDIA_BREAKPOINTS.large]: {
        width: `${shareButtonSize.large}px`,
        height: `${shareButtonSize.large}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        width: `${shareButtonSize.medium}px`,
        height: `${shareButtonSize.medium}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        width: `${shareButtonSize.small}px`,
        height: `${shareButtonSize.small}px`,
      },
      '@media (hover: hover)': {
        ':hover': {
          backgroundColor: buttonHoverColor,
        },
      },
    },
    shareButtonOpen: {
      backgroundColor: buttonHoverColor,
    },
    shareIcon: {
      margin: 'auto',
      display: 'block',
      cursor: 'pointer',
      [MEDIA_BREAKPOINTS.large]: {
        width: '32px',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        width: '24px',
      },
      [MEDIA_BREAKPOINTS.small]: {
        width: '24px',
      },
    },
  });
};

export const ShareButton: React.FunctionComponent<ShareButtonDataProps> = props => {
  const { shareConfig, canonical, smallScreenOnly } = props;
  const { baseUnit, buttonBackgroundColor, buttonHoverColor } = useTheme<ShareButtonThemeProps>(componentConfigurationKey);
  const styles = getStyles(baseUnit, buttonBackgroundColor, buttonHoverColor, smallScreenOnly);

  const [isOpen, setIsOpen] = React.useState(false);
  const shareButtonClass = isOpen ? css(styles.shareButton, styles.shareButtonOpen) : css(styles.shareButton);

  return (
    <ErrorBoundary>
      <div className={css(styles.wrapper)}>
        <button type="button" className={shareButtonClass} aria-label="share-button">
          <svg viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" className={css(styles.shareIcon)} onClick={() => setIsOpen(!isOpen)}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <path
                d="M9.58105,13.3911 L9.58105,9.7861 C9.58105,9.7861 4.17905,9.4641 5.00000001e-05,14.0001 C5.00000001e-05,14.0001 1.13705,5.6716 9.57955,4.6051 L9.57955,1.0001 L16.00005,7.1946 L9.58105,13.3911 Z"
                fill={buttonBackgroundColor}
                fillRule="nonzero"
              />
            </g>
          </svg>
        </button>
        {isOpen ? <ShareMenu shareConfig={shareConfig} canonical={canonical} /> : null}
      </div>
    </ErrorBoundary>
  );
};
