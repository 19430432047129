import { TypographySize } from 'mm-theme-configuration/dist/consts';
import { MaxWidthValues } from '../pageLayouts/layout.utils';
import { BaseUnit } from '../../../../src/theming/baseUnitDefinition';

export const componentConfigThemeKey = 'premiumAuthorPage';

export interface AuthorPremiumThemeProps {
  backgroundColor: string;
  titleFontSizeLarge: TypographySize;
  titleFontSizeMedium: TypographySize;
  titleFontSizeSmall: TypographySize;
  titleColor: string;
  socialIconsNormalColor: string;
  socialIconsHoverColor: string;
  maxWidth: MaxWidthValues;
  bylineFontSizeLarge: TypographySize;
  bylineFontSizeMedium: TypographySize;
  bylineFontSizeSmall: TypographySize;
  bylineTextColor: string;
  baseUnit: BaseUnit;
  bioFontSizeLarge: TypographySize;
  bioFontSizeMedium: TypographySize;
  bioFontSizeSmall: TypographySize;
  bioColor: string;
}
