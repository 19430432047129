import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { PostAside } from './PostAside';
import { MEDIA_BREAKPOINTS } from '../../mediaQueries.const';
import { useTheme } from '../../theming/useTheme';
import { BaseUnit } from '../../theming/baseUnitDefinition';

interface WidgetContentAndSidebarProps {
  adId1: string | null;
  adId2: string | null;
  adOnSuccess1: (() => void) | null;
  adOnSuccess2: (() => void) | null;
}

const getStyles = (baseUnit: BaseUnit) => {
  return StyleSheet.create({
    wrapper: {
      display: 'grid',
      width: '100%',
      gridTemplateRows: '1fr',
      [MEDIA_BREAKPOINTS.large]: {
        gridTemplateColumns: '1fr 300px',
        gridColumnGap: `${baseUnit * 2}px`,
        marginTop: `${baseUnit * 2}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        gridTemplateColumns: '1fr',
        marginTop: `${baseUnit * 0.75}px`,
        display: 'block',
      },
      [MEDIA_BREAKPOINTS.small]: {
        gridTemplateColumns: '1fr',
        marginTop: `${baseUnit * 0.75}px`,
        display: 'block',
      },
    },
  });
};

export const WidgetContentAndSidebar: React.FunctionComponent<WidgetContentAndSidebarProps> = props => {
  const { adId1, adId2, children, adOnSuccess2, adOnSuccess1 } = props;

  const { baseUnit } = useTheme();
  const styles = getStyles(baseUnit);

  return (
    <div className={css(styles.wrapper)}>
      <main>
        {children}
      </main>
      <PostAside adId1={adId1} adId2={adId2} adOnSuccess1={adOnSuccess1} adOnSuccess2={adOnSuccess2} disableDefaultWrapperPadding />
    </div>
  );
};
