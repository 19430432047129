import * as React from 'react';
import { Helmet } from 'react-helmet';
import {
  Font,
  fontFaceTypeName,
  FontTypeStylesheetUrl,
  stylesheetUrlTypeName,
} from 'mm-theme-configuration/dist/interfaces';
import { WebFontsComponentProps } from '../../theming/ThemeProviderWithFonts';
import { ErrorReporting } from '../errorHandling/ErrorReporting';
import { FontFace } from './FontFace';

interface LinkProps {
  fontConfiguration: FontTypeStylesheetUrl;
}

const FontAmpLink: React.FunctionComponent<LinkProps> = ({ fontConfiguration }) => {
  const errorReporting = React.useContext(ErrorReporting);
  const stylesheetByProvider = fontConfiguration.stylesheetUrl;

  try {
    return (
      <Helmet>
        <link
          rel="stylesheet"
          href={stylesheetByProvider}
        />
      </Helmet>
    );
  } catch (e) {
    errorReporting.reportException(e);
    return null;
  }
};

const createFontFamilyLinkOrFontFace = (fontConfiguration: Font, index: number) => {
  switch (fontConfiguration.type) {
    case stylesheetUrlTypeName:
      return <FontAmpLink key={index} fontConfiguration={fontConfiguration} />;
    case fontFaceTypeName: {
      return <FontFace key={index} fontConfiguration={fontConfiguration} />;
    }
    default:
      return null;
  }
};

export const AmpWebFonts: React.FunctionComponent<WebFontsComponentProps> = props => {
  const { children, fontsConfiguration } = props;
  return (
    <React.Fragment>
      {fontsConfiguration.map(createFontFamilyLinkOrFontFace)}
      {children}
    </React.Fragment>
  );
};
