import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { injectVideoLogoSuccessfully, setEventEmitter } from '../../../../pageLayouts/eventEmitters/eventEmitters.utils';
import { HeaderHeightsPerBreakpoint, reducedLogoHeightFactor } from '../oldHeader.utils';
import { Link } from '../../../../../partials/link/Link';
import { MEDIA_BREAKPOINTS } from '../../../../../../mediaQueries.const';
import { AnimationDirection, AnimationDirectionType } from '../../menuAnimations.util';
import { baseUnit } from '../../../../../../theming/baseUnitDefinition';

export interface InjectedVideoLogoApiProps {
  videoPath: string;
}

export interface InjectedVideoLogoDataProps {
    href: string;
    menuAnimation?: AnimationDirectionType;
    reduceHeightAnimationDuration?: number;
    headerHeights: HeaderHeightsPerBreakpoint;
}

type InjectedVideoLogoProps = InjectedVideoLogoApiProps & InjectedVideoLogoDataProps;

const embeddedVideoLogoPaddingInPx = 20;

const getInjectedVideoLogoStyle = (reduceHeightAnimationDuration: number, menuAnimation: AnimationDirectionType, headerHeights: HeaderHeightsPerBreakpoint) => {
  return StyleSheet.create({
    wrapper: {
      [MEDIA_BREAKPOINTS.large]: {
        height: menuAnimation === AnimationDirection.UP ? `${headerHeights.large * baseUnit * reducedLogoHeightFactor}px` : `${headerHeights.large * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        height: `${headerHeights.medium * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        height: `${headerHeights.small * baseUnit}px`,
      },
      transition: `${reduceHeightAnimationDuration}ms`,
    },
    video: {
      height: '100%',
      alignItems: 'center',
      display: 'inline-flex',
      margin: `0 -${embeddedVideoLogoPaddingInPx}px`,
    },
    source: {
      height: '100%',
    },
  });
};

export const VideoLogo: React.FunctionComponent<InjectedVideoLogoProps> = props => {
  const { videoPath, href, reduceHeightAnimationDuration = 0, menuAnimation = AnimationDirection.NONE, headerHeights } = props;
  const style = getInjectedVideoLogoStyle(reduceHeightAnimationDuration, menuAnimation, headerHeights);

  React.useEffect(() => {
    setEventEmitter(injectVideoLogoSuccessfully, (callback: Function) => callback());
  }, [videoPath]);

  return (
    <Link className={css(style.wrapper)} href={href}>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video className={css(style.video)} loop autoPlay muted>
        <source className={css(style.source)} src={videoPath} type="video/mp4" />
      </video>
    </Link>
  );
};
