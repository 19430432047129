import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Thumbnail } from '../../partials/image/image.types';
import { SocialLink } from '../navigation/footer/AdditionalFooterSections/FooterSocialLinks';
import { useTheme } from '../../../theming/useTheme';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { baseUnit } from '../../../theming/baseUnitDefinition';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { AuthorPremiumImage } from './AuthorPremiumImage';
import { AuthorPremiumThemeProps, componentConfigThemeKey } from './AuthorPremium.theme';
import { AuthorPremiumTitle } from './AuthorPremiumTitle';
import { AuthorPremiumSocialLinks } from './AuthorPremiumSocialLinks';
import { useHeaderHeights } from '../navigation/header/useHeaderHeights';
import { HeaderHeightsPerBreakpoint } from '../navigation/header/oldHeader/oldHeader.utils';
import { AuthorPremiumByline } from './AuthorPremiumByline';
import { MaxWidthValues } from '../pageLayouts/layout.utils';
import { AuthorPremiumBio } from './AuthorPremiumBio';

export interface AuthorPremiumDataProps {
  image: Thumbnail;
  name: string;
  byline?: string;
  socialButtons: SocialLink[] | null;
  bio: string | null;
}

const backgroundHeightForLargeScreen = 220;
const backgroundHeightForMediumScreen = 160;
const backgroundHeightForSmallScreen = 80;

const createBackgroundWrapperStyle = (backgroundColor: string, maxWidth: MaxWidthValues, headerHeights: HeaderHeightsPerBreakpoint) => {
  return StyleSheet.create({
    style: {
      backgroundColor,
      position: 'absolute',
      top: 0,
      margin: 0,
      maxWidth: `${maxWidth}px`,
      width: '-webkit-fill-available',
      [MEDIA_BREAKPOINTS.large]: {
        paddingTop: `${headerHeights.large * baseUnit}px`,
        height: `${backgroundHeightForLargeScreen}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        paddingTop: `${headerHeights.medium * baseUnit}px`,
        height: `${backgroundHeightForMediumScreen}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        paddingTop: `${headerHeights.small * baseUnit}px`,
        height: `${backgroundHeightForSmallScreen}px`,
      },
    },
  }).style;
};

const createAuthorPremiumInfoWrapperStyle = () => {
  return StyleSheet.create({
    style: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      justifyItems: 'center',
      marginBottom: `${baseUnit * 2}px`,
      [MEDIA_BREAKPOINTS.large]: {
        marginTop: `${backgroundHeightForLargeScreen * 0.5}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        marginTop: `${backgroundHeightForMediumScreen * 0.5}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        marginTop: `${backgroundHeightForSmallScreen * 0.5}px`,
      },
    },
  }).style;
};

export const AuthorPremium: React.FunctionComponent<AuthorPremiumDataProps> = props => {
  const { image, name, byline, socialButtons, bio } = props;
  const headerHeights = useHeaderHeights();
  const { backgroundColor, maxWidth } = useTheme<AuthorPremiumThemeProps>(componentConfigThemeKey);
  const backgroundStyle = createBackgroundWrapperStyle(backgroundColor, maxWidth, headerHeights);
  const authorPremiumInfoWrapperStyle = createAuthorPremiumInfoWrapperStyle();
  return (
    <ErrorBoundary>
      <div className={css(backgroundStyle)} />
      <div className={css(authorPremiumInfoWrapperStyle)}>
        <AuthorPremiumImage image={image} />
        <AuthorPremiumTitle name={name} />
        {byline && <AuthorPremiumByline byline={byline} />}
        {socialButtons && <AuthorPremiumSocialLinks socialButtons={socialButtons} />}
        {bio && <AuthorPremiumBio bio={bio} />}
      </div>
    </ErrorBoundary>
  );
};
