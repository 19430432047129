import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { HeaderDataProps } from '../../components/navigation/header/oldHeader/OldHeader';
import { SecondaryHeaderDataProps } from '../../components/navigation/header/oldHeader/secondaryHeader/SecondaryHeader';
import { AMPPrimaryHeader } from './AMPPrimaryHeader';
import { AMPSideBar } from './AMPSideBar';
import { AMPSecondaryHeader } from './AMPSecondaryHeader';
import { PrimaryHeaderDataProps } from '../../components/navigation/header/oldHeader/primaryHeader/PrimaryHeader';
import { useTheme } from '../../../theming/useTheme';
import { getDoubleHeaderHeights } from '../../components/navigation/header/oldHeader/oldHeader.utils';
import { HeaderThemeProps, componentConfigThemeKey } from '../../components/navigation/header/Header.theme';

interface AMPHeaderProps {
  primaryHeader: PrimaryHeaderDataProps;
  secondaryHeader: SecondaryHeaderDataProps | null;
  shouldShowClosedMenuIcon: boolean;
  editionEndpoint: string;
  pagePath: string;
}

const getHeaderStyle = () => StyleSheet.create({
  wrapper: {
    position: 'fixed',
    width: '100%',
    zIndex: 1500,
    top: 0,
  },
});

export const AMPHeader: React.FunctionComponent<AMPHeaderProps> = props => {
  const { primaryHeader, shouldShowClosedMenuIcon, secondaryHeader, editionEndpoint, pagePath } = props;
  const { links, logo, cta, editions } = primaryHeader;
  const style = getHeaderStyle();

  const themeProps = useTheme<HeaderThemeProps>(componentConfigThemeKey);
  const firstRowHeights = getDoubleHeaderHeights(themeProps.firstRowHeightInLargeScreen, themeProps.firstRowHeightInMediumScreen, themeProps.firstRowHeightInSmallScreen);
  const firstRowLogoHeights = getDoubleHeaderHeights(themeProps.firstRowLogoHeightInLargeScreen, themeProps.firstRowLogoHeightInMediumScreen, themeProps.firstRowLogoHeightInSmallScreen);
  const secondRowHeights = getDoubleHeaderHeights(themeProps.secondRowHeightInLargeScreen, themeProps.secondRowHeightInMediumScreen, themeProps.secondRowHeightInSmallScreen);
  const secondRowLogoHeights = getDoubleHeaderHeights(themeProps.secondRowLogoHeightInLargeScreen, themeProps.secondRowLogoHeightInMediumScreen, themeProps.secondRowLogoHeightInSmallScreen);

  return (
    <header className={css(style.wrapper)}>
      <AMPPrimaryHeader
        links={links}
        logo={logo}
        cta={cta}
        shouldShowClosedMenuIcon={shouldShowClosedMenuIcon}
        editions={editions}
        pagePath={pagePath}
        editionEndpoint={editionEndpoint}
        headerHeights={firstRowHeights}
        logoHeights={firstRowLogoHeights}
      />
      {secondaryHeader ? <AMPSecondaryHeader {...secondaryHeader} pagePath={pagePath} headerHeights={secondRowHeights} logoHeights={secondRowLogoHeights} /> : null}
    </header>
  );
};


export const AMPHeaderAndSideBar: React.FunctionComponent<HeaderDataProps> = props => {
  const { secondaryHeader, primaryHeader, editionEndpoint, pagePath } = props;
  const { editions, menu } = primaryHeader;
  return (
    <React.Fragment>
      <AMPHeader primaryHeader={primaryHeader} secondaryHeader={secondaryHeader} shouldShowClosedMenuIcon={!!menu} editionEndpoint={editionEndpoint} pagePath={pagePath} />
      {menu ? <AMPSideBar menu={menu.links} editions={editions} editionEndpoint={editionEndpoint} /> : null}
    </React.Fragment>
  );
};
