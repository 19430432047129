import { StyleSheet } from 'aphrodite/no-important';
import { Colors } from 'mm-theme-configuration';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';

export const createStyles = (colors: Colors) => {
  const { white, black } = colors;
  return StyleSheet.create({
    container: {
      backgroundColor: black,
      alignItems: 'center',
      display: 'flex',
      width: '100%',
      margin: '10px 0px',
      [MEDIA_BREAKPOINTS.large]: {
        minHeight: '125px',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        minHeight: '125px',
      },
      [MEDIA_BREAKPOINTS.small]: {
        minHeight: '66px',
      },
    },
    text: {
      alignItems: 'flex-start',
      color: white,
      [MEDIA_BREAKPOINTS.large]: {
        padding: '10px 30px',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        padding: '10px 30px',
      },
      [MEDIA_BREAKPOINTS.small]: {
        padding: '10px 20px',
      },
    },
  });
};
