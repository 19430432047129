import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { css, StyleSheet } from 'aphrodite/no-important';
import { MenuLinkDataProps, MenuSubLink } from './MenuSubLink';
import { subLinkEnterDuration, subLinkEnterTimeOut, subLinksTotalDelay } from './menuLinks.utils';
import { useTheme } from '../../../../../../../../theming/useTheme';

const MAX_SUB_LINKS_FOR_ONE_COLUMN = 10;

interface MenuSubLinksProps {
  links: MenuLinkDataProps[];
  isLinkOpen: boolean;
}

const getSubLinkStyle = (index: number, currentSubLinkDelay: number) => StyleSheet.create({
  enter: {
    opacity: 0,
  },
  enterActive: {
    opacity: 1,
    transition: `opacity ${subLinkEnterDuration}ms ease-out`,
    transitionDelay: `${currentSubLinkDelay * index}ms`,
  },
});

const createMenuSubLinks = (numberOfSubLinks: number, isLinkOpen: boolean) => (menuLink: MenuLinkDataProps, index: number) => {
  const style = getSubLinkStyle(index, subLinksTotalDelay / numberOfSubLinks);
  return (
    <CSSTransition
      key={index}
      in={isLinkOpen}
      unmountOnExit
      mountOnEnter
      timeout={{
        enter: subLinkEnterTimeOut,
        exit: 0,
      }}
      classNames={{
        enter: css(style.enter),
        enterActive: css(style.enterActive),
      }}
    >
      <MenuSubLink href={menuLink.link.href} text={menuLink.link.text} />
    </CSSTransition>
  );
};

const getSubLinksWrapperStyle = (numberOfLinks: number, baseUnit: number) => {
  const showTwoColumns = numberOfLinks > MAX_SUB_LINKS_FOR_ONE_COLUMN;
  return StyleSheet.create({
    wrapper: {
      display: 'grid',
      padding: `${baseUnit / 2}px 0`,
      gridRowGap: `${baseUnit}px`,
      gridColumnGap: `${baseUnit}px`,
      gridTemplateColumns: showTwoColumns ? '1fr 1fr' : '1fr',
      gridTemplateRows: showTwoColumns ? `repeat(${Math.round(numberOfLinks / 2)}, auto)` : 'auto',
    },
  });
};

export const MenuSubLinks: React.FunctionComponent<MenuSubLinksProps> = props => {
  const { isLinkOpen, links } = props;
  const { baseUnit } = useTheme();
  const numberOfLinks = links.length;
  const style = getSubLinksWrapperStyle(numberOfLinks, baseUnit);

  return (
    <ul className={css(style.wrapper)}>
      {links.map(createMenuSubLinks(numberOfLinks, isLinkOpen))}
    </ul>
  );
};
