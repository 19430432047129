import * as React from 'react';
import { TypographySize } from 'mm-theme-configuration/dist/consts';
import { css, StyleSheet } from 'aphrodite/no-important';
import { getActiveEdition } from '../oldHeader/primaryHeader/Editions';
import { MEDIA_BREAKPOINTS } from '../../../../../mediaQueries.const';
import { useTheme } from '../../../../../theming/useTheme';
import { BaseUnit } from '../../../../../theming/baseUnitDefinition';
import { getStyleBySize } from '../../../../../typography/semanticTags/semanticTags.utils';
import { Link } from '../../../../partials/link/Link';
import { Directions, TriangleIcon } from '../TriangleIcon';
import { NavigationLink } from '../oldHeader/primaryHeader/PrimaryHeader';
import { EditionsIcon, EditionsIconDataProps } from './EditionsIcon';

interface EditionsProps {
  editions: NavigationLink[];
  editionEndpoint: string;
  editionsMenuWidthInSmallScreen: number;
  editionsMenuWidthInMediumScreen: number;
  editionsMenuWidthInLargeScreen: number;
  fontSizeSmall: TypographySize;
  fontSizeMedium: TypographySize;
  fontSizeLarge: TypographySize;
  colorTypeHover: string;
  colorTypeNormal: string;
  colorTypeActive: string;
  dropdownIconColor: string;
  editionsMenuBackgroundColor: string;
  editionsIcon: EditionsIconDataProps | null;
  editionsIconNormalColor: string;
  editionsIconHoverColor: string;
}

const createStyles = ({
  colorTypeActive,
  colorTypeNormal,
  colorTypeHover,
  editionsMenuBackgroundColor,
}: EditionsProps, baseUnit: BaseUnit, editionsMenuToggle: boolean, langArrLen: number) => StyleSheet.create({
  button: {
    display: 'flex',
    color: colorTypeNormal,
    alignItems: 'center',
    cursor: 'pointer',
    ':hover': {
      color: colorTypeHover,
    },
    ':active': {
      color: colorTypeActive,
    },
  },
  link: {
    color: colorTypeNormal,
    ':hover': {
      color: colorTypeHover,
    },
    ':active': {
      color: colorTypeActive,
    },
  },
  ul: {
    backgroundColor: editionsMenuBackgroundColor,
    position: 'absolute',
    top: '100%',
    right: 0,
    boxSizing: 'border-box',
    listStyle: 'none',
    padding: '15px 30px',
    margin: 0,
    display: editionsMenuToggle ? 'block' : 'none',
    zIndex: 100,
    whiteSpace: 'nowrap',
    columnCount: Math.ceil(langArrLen / 7),
    minHeight: 283,
    gap: 30,
    [MEDIA_BREAKPOINTS.small]: {
      width: '100%',
      position: 'fixed',
      maxHeight: 'none',
      height: '100vh',
      display: editionsMenuToggle ? 'flex' : 'none',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignContent: 'flex-start',
      gap: 0,
    },
  },
  li: {
    textAlign: 'left',
    marginBottom: `${baseUnit}px`,
    [MEDIA_BREAKPOINTS.small]: {
      flex: '50%',
    },
  },
});

export const Editions: React.FunctionComponent<EditionsProps> = props => {
  const { baseUnit, fontSizesStyles } = useTheme();
  const {
    editions,
    editionEndpoint,
    fontSizeSmall,
    fontSizeMedium,
    fontSizeLarge,
    dropdownIconColor,
    colorTypeHover,
    editionsIcon,
    editionsIconNormalColor,
    editionsIconHoverColor,
  } = props;
  const [editionsMenuToggle, setEditionsMenuToggle] = React.useState(false);
  const styles = createStyles(props, baseUnit, editionsMenuToggle, editions.length);
  const activeEdition = getActiveEdition(editions, editionEndpoint);
  const fontStyle = getStyleBySize(fontSizesStyles, { fontSizeSmall, fontSizeMedium, fontSizeLarge });

  const scrollHandler = () => {
    if (editionsMenuToggle) {
      setEditionsMenuToggle(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', scrollHandler);

    return () => window.removeEventListener('scroll', scrollHandler);
  });

  const createEditionLink = (edition: NavigationLink, index: number) => (
    <li className={css(styles.li)} key={index}>
      <Link href={edition.link.href} className={css(styles.link, fontStyle)}>
        {edition.link.text}
      </Link>
    </li>
  );
  const EditionsMenu: React.FunctionComponent = () => (
    <ul className={css(styles.ul)}>
      {editions.map(createEditionLink)}
    </ul>
  );

  return (
    <React.Fragment>
      <button
        type="button"
        className={css(styles.button, fontStyle)}
        onClick={() => setEditionsMenuToggle(!editionsMenuToggle)}
      >
        {editionsIcon ? (
          <EditionsIcon
            {...editionsIcon}
            editionsIconNormalColor={editionsIconNormalColor}
            editionsIconHoverColor={editionsIconHoverColor}
          />
        ) : activeEdition.link.text}
        <TriangleIcon
          direction={editionsMenuToggle ? Directions.UP : Directions.DOWN}
          color={dropdownIconColor}
          hoverColor={colorTypeHover}
        />
      </button>
      <EditionsMenu />
    </React.Fragment>
  );
};
