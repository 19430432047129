import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useTheme } from '../../../theming/useTheme';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { CopyLinkButton } from './CopyLinkButton';
import { Link } from '../../partials/link/Link';
import { BaseUnit } from '../../../theming/baseUnitDefinition';

const componentConfigurationKey = 'articleShareButton';

export interface SocialButton {
  src: string;
  shareURL: string;
  type: string;
}

interface ShareMenuDataProps {
  shareConfig: SocialButton[];
  canonical: string;
}

interface ShareMenuThemeProps {
  baseUnit: BaseUnit;
  buttonBackgroundColor: string;
}

const socialButtonAnimation = {
  '0%': {
    opacity: 0,
  },
  '100%': {
    opacity: 1,
  },
};

const getStyles = (baseUnit: BaseUnit, backgroundColor: string) => {
  return StyleSheet.create({
    shareMenu: {
      marginTop: `${baseUnit / 2}px`,
      [MEDIA_BREAKPOINTS.large]: {
        width: '48px',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        width: '40px',
      },
      [MEDIA_BREAKPOINTS.small]: {
        width: '40px',
      },
    },
    socialButton: {
      backgroundColor,
      border: 'none',
      borderRadius: '100%',
      position: 'relative',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      cursor: 'pointer',
      outline: 'none',
      overflow: 'hidden',
      opacity: 0,
      animationName: [socialButtonAnimation],
      animationDuration: '500ms',
      animationFillMode: 'forwards',
      [MEDIA_BREAKPOINTS.large]: {
        marginBottom: `${baseUnit / 4}px`,
        width: '40px',
        height: '40px',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        marginBottom: `${baseUnit / 4}px`,
        width: '40px',
        height: '40px',
      },
      [MEDIA_BREAKPOINTS.small]: {
        marginBottom: `${baseUnit / 3}px`,
        width: '36px',
        height: '36px',
      },
    },
    socialIcon: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      [MEDIA_BREAKPOINTS.large]: {
        height: '20px',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        height: '20px',
      },
      [MEDIA_BREAKPOINTS.small]: {
        height: '16px',
      },
    },
  });
};

const getAnimationDelayClass = (index: number) => {
  return StyleSheet.create({
    animationModifier: {
      animationDelay: `${index * 50}ms`,
    },
  });
};

const getShareButtons = (buttons: SocialButton[], styles: { [key: string]: any }) => buttons.map((button, index) => {
  const { shareURL, src, type } = button;
  const animationDurationClass = getAnimationDelayClass(index);

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
      className={css(styles.socialButton, animationDurationClass.animationModifier)}
      key={index}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => { window.open(shareURL, '_blank', 'noopener'); return false; }}
    >
      <img src={src} alt={type} className={css(styles.socialIcon)} />
    </Link>
  );
});

export const ShareMenu: React.FunctionComponent<ShareMenuDataProps> = props => {
  const { shareConfig, canonical } = props;
  const { baseUnit, buttonBackgroundColor } = useTheme<ShareMenuThemeProps>(componentConfigurationKey);
  const styles = getStyles(baseUnit, buttonBackgroundColor);
  const copyLinkAnimationClass = getAnimationDelayClass(shareConfig.length);

  return (
    <ErrorBoundary>
      <div className={css(styles.shareMenu)}>
        {getShareButtons(shareConfig, styles)}
        <CopyLinkButton canonical={canonical} buttonClass={css(styles.socialButton, copyLinkAnimationClass.animationModifier)} />
      </div>
    </ErrorBoundary>
  );
};
