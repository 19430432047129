import React from 'react';
import { css } from 'aphrodite/no-important';
import { Colors } from 'mm-theme-configuration';
import { getStyleBySize, TypographySizes } from '../../../typography/semanticTags/semanticTags.utils';
import { createStyles } from './NetworkLinkCardFeedPage.styles';
import { TextLinkComponent } from '../../partials/link/TextLink';
import { H3 } from '../../../typography/semanticTags';
import { TranslatedFontSize } from '../../../theming/fontSizeTranslator';
import { useTheme } from '../../../theming/useTheme';
import { componentConfigThemeKey, HorizontalCardThemeProps } from '../../partials/cards/horizontalCard/horizontalCard.theme';

export interface NetworkLink {
  siteName: string;
  sitePrimaryColor: string;
  siteSecondaryColor: string;
  siteURL: string;
  teamName: string;
}

export const NetworkLinkCardFeedPage = (networkLink: NetworkLink) => {
  const { borderRadius } = useTheme<HorizontalCardThemeProps>(componentConfigThemeKey);
  const titleText = `Visit Fansided’s ${networkLink?.teamName} website for more news and updates`;
  const buttonText = `More ${networkLink?.teamName} Here `;
  const { fontSizesStyles, colors }: { colors: Colors; fontSizesStyles: TranslatedFontSize} = useTheme();

  const siteNameFontSizeButton = getStyleBySize(fontSizesStyles, { fontSizeSmall: TypographySizes.BIG, fontSizeMedium: TypographySizes.BIG, fontSizeLarge: TypographySizes.BIG });
  const siteNameFontSizeText = getStyleBySize(fontSizesStyles, { fontSizeSmall: TypographySizes.LARGE, fontSizeMedium: TypographySizes.LARGE, fontSizeLarge: TypographySizes.LARGE });
  const siteNameFontSize = getStyleBySize(fontSizesStyles, { fontSizeSmall: TypographySizes.HUGE, fontSizeMedium: TypographySizes.HUGE, fontSizeLarge: TypographySizes.HUGE });

  const styles = createStyles(colors, networkLink.siteSecondaryColor, networkLink.sitePrimaryColor, borderRadius);
  const imgPath = 'https://images2.minutemediacdn.com/image/upload/q_auto/v1691496220/images/voltaxMediaLibrary/mmsport/13/01h7agfy87mp3djdxaqk.png';
  return (
    <div className={css(styles.paddingWrapper)}>
      <TextLinkComponent href={networkLink.siteURL || ''}>
        <div className={css(styles.cardWrapper)}>
          <div className={css(styles.imageWrapper)}>
            <link rel="preload" as="image" href={imgPath} />
            <img src={imgPath} alt={imgPath} className={css(styles.backgroundImage)} />
            <p className={css(styles.text, siteNameFontSize)}>{networkLink.siteName}</p>
          </div>
          <div className={css(styles.textWrapper, siteNameFontSizeText)}>
            <H3 className={css(styles.h3, siteNameFontSizeText)}>
              {titleText}
            </H3>
            <p className={css(styles.buttonText, siteNameFontSizeButton)}>
              {buttonText}

              <svg className={css(styles.svgImg)} x="0px" y="0px" viewBox="0 0 16 16">
                <g><path d="M12.2,9H0V7h12.2L6.6,1.4L8,0l8,8l-8,8l-1.4-1.4L12.2,9z" /></g>
              </svg>

            </p>
          </div>
        </div>
      </TextLinkComponent>
    </div>
  );
};
