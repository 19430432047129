import * as React from 'react';
import { useTheme } from '../../../../theming/useTheme';
import {
  isRatioIs4On5,
  showAuthorInAnyScreenSize,
  showMetadataInLargeScreenSize,
  showMetadataInMediumScreenSize,
  showMetadataInSmallScreenSize,
} from '../cards.utils';
import { ConditionalRelativeWrap } from '../../ConditionalRelativeWrap';
import { Image } from '../../image/Image';
import { DecorationLine } from '../../DecorationLine';
import { createImageAspectRatios } from '../../image/image.utils';
import { PlayIcon } from '../../PlayIcon';
import { TextNextToImage } from '../TextNextToImage';
import { VerticalCardWrapper } from './VerticalCardWrapper';
import {
  MainCategory,
} from './VerticalCardMainCategory';
import { componentConfigThemeKey, VerticalCardThemeProps } from './VerticalCard.theme';
import { VerticalCardProps } from './VerticalCard.props';

export const VerticalCard: React.FunctionComponent<VerticalCardProps> = props => {
  const {
    coverType,
    lazyLoad = true,
    showDecorationLine = true,
    title,
    image,
    imageWidths,
    authors,
    mainCategory,
    sponsoredText,
    brandName,
    description,
    updatedAt,
    updatedAtISO,
    createdAt,
    createdAtISO,
    useFormatMinutesHoursAgo,
  } = props;
  const {
    titleFontSizeLarge,
    titleFontSizeSmall,
    titleFontSizeMedium,
    showAuthorInLargeScreen: showAuthorInLarge,
    showAuthorInMediumScreen: showAuthorInMedium,
    showAuthorInSmallScreen: showAuthorInSmall,
    aspectRatioLarge,
    aspectRatioMedium,
    aspectRatioSmall,
    authorColor,
    metadataFontSizeLarge,
    metadataFontSizeMedium,
    metadataFontSizeSmall,
    showMetadataOnBottom,
    metadataMarginTop,
    horizontalGapLarge,
    horizontalGapMedium,
    horizontalGapSmall,
    verticalGapLarge,
    verticalGapMedium,
    verticalGapSmall,
    titleSemanticTag,
    metadataSemanticTag,
    showDateInSmallScreen: showDateInSmall,
    showDateInMediumScreen: showDateInMedium,
    showDateInLargeScreen: showDateInLarge,
    dateColor,
    pipeColor,
    showMainCategoryInLargeScreen,
    showMainCategoryInMediumScreen,
    showMainCategoryInSmallScreen,
    numberOfLinesForTitle,
    numberOfLinesForAuthors,
    showDescriptionInLargeScreen: showDescriptionInLarge,
    showDescriptionInMediumScreen: showDescriptionInMedium,
    showDescriptionInSmallScreen: showDescriptionInSmall,
    numberOfLinesForDescription,
    descriptionColor,
    descriptionFontSizeInLargeScreen,
    descriptionFontSizeInMediumScreen,
    descriptionFontSizeInSmallScreen,
    descriptionSemanticTag,
    titleAlignment,
    descriptionAlignment,
    metadataAlignment,
    isUpdatedAtTimestamp,
    isMainCategoryEnabled,
    isClickableLabels,
    descriptionHorizontalGap,
    authorColorHover,
    additionalBottomTextWrapperStyle,
    showMainCategoryAboveText,
  } = useTheme<VerticalCardThemeProps>(componentConfigThemeKey);

  const showMetadataInLargeScreen = showMetadataInLargeScreenSize(showAuthorInLarge, showDateInLarge) && !isRatioIs4On5(aspectRatioLarge);
  const showMetadataInMediumScreen = showMetadataInMediumScreenSize(showAuthorInMedium, showDateInMedium) && !isRatioIs4On5(aspectRatioMedium);
  const showMetadataInSmallScreen = showMetadataInSmallScreenSize(showAuthorInSmall, showDateInSmall) && !isRatioIs4On5(aspectRatioSmall);
  const showAuthorInLargeScreen = showAuthorInLarge && !isRatioIs4On5(aspectRatioLarge);
  const showAuthorInMediumScreen = showAuthorInMedium && !isRatioIs4On5(aspectRatioMedium);
  const showAuthorInSmallScreen = showAuthorInSmall && !isRatioIs4On5(aspectRatioSmall);
  const showDescriptionInLargeScreen = showDescriptionInLarge && !isRatioIs4On5(aspectRatioLarge);
  const showDescriptionInMediumScreen = showDescriptionInMedium && !isRatioIs4On5(aspectRatioMedium);
  const showDescriptionInSmallScreen = showDescriptionInSmall && !isRatioIs4On5(aspectRatioSmall);
  const showDateInLargeScreen = showDateInLarge && !isRatioIs4On5(aspectRatioLarge);
  const showDateInMediumScreen = showDateInMedium && !isRatioIs4On5(aspectRatioMedium);
  const showDateInSmallScreen = showDateInSmall && !isRatioIs4On5(aspectRatioSmall);
  const showMainCategory = isMainCategoryEnabled && mainCategory?.displayName && (showMainCategoryInSmallScreen || showMainCategoryInMediumScreen || showMainCategoryInLargeScreen);
  const isMainCategoryClickable = isClickableLabels && mainCategory?.link && mainCategory?.isActive;
  const showAuthor = showAuthorInAnyScreenSize(authors, showAuthorInLargeScreen, showAuthorInMediumScreen, showAuthorInSmallScreen);

  const isAnyAuthorClickable = isClickableLabels && authors?.some(author => !!author.link);
  const isClickableLayout = (showMainCategory && isMainCategoryClickable) || (showAuthor && isAnyAuthorClickable);
  const timestamp = isUpdatedAtTimestamp ? updatedAt : createdAt;
  const timestampISO = isUpdatedAtTimestamp ? updatedAtISO : createdAtISO;

  const styles = {
    label: {
      position: 'relative',
      width: 'fit-content',
      marginBottom: '10px',
    },
  };

  const mainCategoryProps = {
    displayName: mainCategory?.displayName,
    link: mainCategory?.link,
    isClickable: !!isMainCategoryClickable,
  };

  return (
    <VerticalCardWrapper {...props} isClickableLayout={isClickableLayout}>
      <ConditionalRelativeWrap shouldWarp={showMainCategory || coverType === 'video'}>
        <Image
          {...image}
          lazyLoad={lazyLoad}
          imageWidths={imageWidths}
          imageAspectRatios={createImageAspectRatios(aspectRatioSmall, aspectRatioMedium, aspectRatioLarge)}
        />
        {coverType === 'video' ? <PlayIcon /> : null}
        {showMainCategory && !showMainCategoryAboveText ? <MainCategory {...mainCategoryProps} /> : null}
      </ConditionalRelativeWrap>
      {showDecorationLine ? <DecorationLine /> : null}
      <TextNextToImage {...{
        title,
        brandName,
        authors,
        description,
        timestamp,
        timestampISO,
        sponsoredText,
        horizontalGapLarge,
        horizontalGapMedium,
        horizontalGapSmall,
        verticalGapLarge,
        verticalGapMedium,
        verticalGapSmall,
        titleSemanticTag,
        titleFontSizeMedium,
        titleFontSizeSmall,
        titleFontSizeLarge,
        numberOfLinesForTitle,
        showDescriptionInLargeScreen,
        showDescriptionInMediumScreen,
        showDescriptionInSmallScreen,
        descriptionSemanticTag,
        descriptionFontSizeInSmallScreen,
        descriptionFontSizeInLargeScreen,
        descriptionFontSizeInMediumScreen,
        descriptionColor,
        numberOfLinesForDescription,
        showAuthorInLargeScreen,
        showAuthorInMediumScreen,
        showAuthorInSmallScreen,
        showDateInLargeScreen,
        showDateInMediumScreen,
        showDateInSmallScreen,
        metadataSemanticTag,
        metadataFontSizeLarge,
        metadataFontSizeMedium,
        metadataFontSizeSmall,
        showMetadataInLargeScreen,
        showMetadataInMediumScreen,
        showMetadataInSmallScreen,
        showMetadataOnBottom,
        metadataMarginTop,
        authorColor,
        numberOfLinesForAuthors,
        pipeColor,
        dateColor,
        titleAlignment,
        descriptionAlignment,
        metadataAlignment,
        isClickableAuthors: isAnyAuthorClickable,
        useFormatMinutesHoursAgo,
        descriptionHorizontalGap,
        authorColorHover,
        additionalBottomTextWrapperStyle,
      }}
      >
        {
          showMainCategoryAboveText && showMainCategory
          && <MainCategory {...mainCategoryProps} style={styles.label} />
        }
      </TextNextToImage>
    </VerticalCardWrapper>
  );
};
