import { predicates } from '../predicates/index';
import { selectors } from '../selectors/index';
import { SEARCH_PLATFORMS, TRAFFIC_SOURCES } from '../constants';

export const organic = {
  rule: predicates.or(
    ...Object.values(SEARCH_PLATFORMS).map(platform => predicates.referrerIncludesPlatform(platform)),
  ),
  extractor: (traffic) => {
    const trafficSource = TRAFFIC_SOURCES.ORGANIC;
    const trafficId = selectors.referrerHostname(traffic);

    return {
      trafficSource,
      trafficId,
    };
  },
};
