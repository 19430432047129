import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { VerticalCard } from '../../partials/cards/verticalCard/VerticalCard';
import { useTheme } from '../../../theming/useTheme';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { LARGE_SCREEN_SIZE, MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { createImageWidths } from '../../partials/image/image.utils';
import { LayoutComponentBaseProps, getLayoutComponentPadding } from '../pageLayouts/layout.utils';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { VerticalCardThemeProps, componentConfigThemeKey as verticalCardConfigThemeKey } from '../../partials/cards/verticalCard/VerticalCard.theme';
import { OptionalAdditionalStyle } from '../../components.utils';

export const componentConfigThemeKey = 'scrollableArticleList';

const createStyles = (baseUnit: BaseUnit, columnGap: number, verticalCardBorderWidthTypeNormal: number, isWide: boolean, scrollableListBackgroundColor: string, gridTemplateColumns: string) => StyleSheet.create({
  listWrapper: {
    display: 'grid',
    maxWidth: `${LARGE_SCREEN_SIZE}px`,
    backgroundColor: isWide ? scrollableListBackgroundColor : 'none',
  },
  scrollContainer: {
    '-webkit-overflow-scrolling': 'touch',
    display: 'flex',
    justifyContent: 'space-between',
    overflowX: 'scroll',
    overflowY: 'visible',
    [MEDIA_BREAKPOINTS.large]: {
      overflow: 'visible',
      justifyContent: 'center',
      gridTemplateColumns,
      gridColumnGap: `${baseUnit * columnGap}px`,
      display: 'grid',
    },
    [MEDIA_BREAKPOINTS.medium]: {
      padding: `${verticalCardBorderWidthTypeNormal}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      padding: `${verticalCardBorderWidthTypeNormal}px`,
    },
    '::-webkit-scrollbar': {
      display: 'none',
    },
  },
  scrollItem: {
    flex: '0 0 auto',
    flexBasis: `${300 - baseUnit / 2}px`,
    [MEDIA_BREAKPOINTS.medium]: {
      margin: `0 ${baseUnit / 4}px`,
      ':first-child': {
        marginLeft: 0,
      },
      ':last-child': {
        marginRight: 0,
      },
    },
    [MEDIA_BREAKPOINTS.small]: {
      margin: `0 ${baseUnit / 4}px`,
      flexBasis: '225px',
      ':first-child': {
        marginLeft: 0,
      },
      ':last-child': {
        marginRight: 0,
      },
    },
    position: 'relative',
    boxSizing: 'border-box',
    '@media (max-width: 330px)': {
      flexBasis: 'min(85%, 255px)',
    },
  },
});

interface ScrollableArticleListComponentDataProps extends LayoutComponentBaseProps, OptionalAdditionalStyle {
  cardsProps: CardComponentDataProps[];
  isWide?: boolean;
  cardTheme?: Partial<VerticalCardThemeProps>;
  useFormatMinutesHoursAgo?: boolean;
  gridTemplateColumns?: string;
}

interface ScrollableArticleListComponentThemeProps {
  baseUnit: BaseUnit;
  showDateInLargeScreen: boolean;
  showDateInMediumScreen: boolean;
  showDateInSmallScreen: boolean;
  showAuthorInLargeScreen: boolean;
  showAuthorInMediumScreen: boolean;
  showAuthorInSmallScreen: boolean;
  showDescriptionInLargeScreen: boolean;
  showDescriptionInMediumScreen: boolean;
  showDescriptionInSmallScreen: boolean;
  columnGap: number;
}

const imageWidths = createImageWidths(360, 360, 360);

export const ScrollableArticleList: React.FunctionComponent<ScrollableArticleListComponentDataProps> = ({ cardsProps, isWide = false, cardTheme, paddingFactors, useFormatMinutesHoursAgo, gridTemplateColumns = '1fr 1fr 1fr 1fr' }) => {
  const { baseUnit,
    showDateInLargeScreen,
    showDateInMediumScreen,
    showDateInSmallScreen,
    columnGap,
    showAuthorInLargeScreen,
    showAuthorInMediumScreen,
    showAuthorInSmallScreen,
    showDescriptionInLargeScreen,
    showDescriptionInMediumScreen,
    showDescriptionInSmallScreen,
  } = useTheme<ScrollableArticleListComponentThemeProps>(componentConfigThemeKey);
  const { borderWidthTypeNormal } = useTheme<VerticalCardThemeProps>(verticalCardConfigThemeKey);
  const styles = createStyles(baseUnit, columnGap, borderWidthTypeNormal, isWide, cardTheme?.cardBackgroundColor || '', gridTemplateColumns);
  const paddingStyle = getLayoutComponentPadding(paddingFactors);
  const themeData = {
    showDateInLargeScreen,
    showDateInMediumScreen,
    showDateInSmallScreen,
    showAuthorInLargeScreen,
    showAuthorInMediumScreen,
    showAuthorInSmallScreen,
    showDescriptionInLargeScreen,
    showDescriptionInMediumScreen,
    showDescriptionInSmallScreen,
    ...cardTheme };

  const themeContext = useThemeOverride<VerticalCardThemeProps>(themeData, verticalCardConfigThemeKey);

  const createCard = (props: CardComponentDataProps) => {
    const { id } = props;
    return (
      <div className={css(styles.scrollItem)} key={id}>
        <VerticalCard {...props} imageWidths={imageWidths} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />
      </div>
    );
  };

  return (
    <ErrorBoundary>
      {cardsProps && cardsProps.length > 0
            && (
            <div className={css(paddingStyle, styles.listWrapper)}>
              <div className={css(styles.scrollContainer)}>
                <ThemeContext.Provider value={themeContext}>
                  {cardsProps.map(createCard)}
                </ThemeContext.Provider>
              </div>
            </div>
            )}
    </ErrorBoundary>
  );
};
