import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { BaseUnit } from '../../../../theming/baseUnitDefinition';
import { useTheme } from '../../../../theming/useTheme';

interface AccessibilityIconProps {
  accessibilityIconNormalColor: string;
  accessibilityIconHoverColor: string;
  iconHeight?: number;
}

const createStyle = (accessibilityIconHoverColor: string, accessibilityIconNormalColor: string, baseUnit: BaseUnit, iconHeight: number) => {
  return StyleSheet.create({
    svg: {
      fill: accessibilityIconNormalColor,
      ':hover': {
        fill: accessibilityIconHoverColor,
      },
      height: `${iconHeight * baseUnit}px`,
      width: 'auto',
    },
    button: {
      cursor: 'pointer',
    },
  });
};
export const AccessibilityIcon: React.FunctionComponent<AccessibilityIconProps> = props => {
  const { accessibilityIconNormalColor, accessibilityIconHoverColor, iconHeight = 1 } = props;
  const { baseUnit } = useTheme();
  const style = createStyle(accessibilityIconHoverColor, accessibilityIconNormalColor, baseUnit, iconHeight);
  const userWayWidgetId = 'accessibilityWidget';

  return (
    <button type="button" id={userWayWidgetId} aria-label="accessibility" className={css(style.button)}>
      <svg className={css(style.svg, style.button)} width="20" height="21" viewBox="0 0 20 21" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.0002 5.36621C9.5811 5.36621 9.17145 5.23792 8.82301 4.99755C8.47457 4.75719 8.203 4.41554 8.04263 4.01583C7.88227 3.61612 7.84031 3.17629 7.92206 2.75195C8.00382 2.32762 8.20561 1.93784 8.50193 1.63192C8.79826 1.32599 9.17579 1.11765 9.5868 1.03324C9.99781 0.948839 10.4238 0.992159 10.811 1.15773C11.1982 1.32329 11.5291 1.60367 11.7619 1.9634C11.9947 2.32313 12.119 2.74607 12.119 3.17871C12.1184 3.75868 11.8949 4.31472 11.4977 4.72482C11.1005 5.13492 10.5619 5.36559 10.0002 5.36621Z" />
        <path d="M16.6592 5.39741L16.6422 5.40209L16.6263 5.40717C16.5885 5.41811 16.5506 5.42983 16.5128 5.44194C15.8087 5.65522 12.3913 6.64975 9.98384 6.64975C7.7466 6.64975 4.63838 5.79037 3.64405 5.49858C3.54509 5.45907 3.44403 5.42542 3.34136 5.3978C2.62248 5.20248 2.13062 5.95639 2.13062 6.64545C2.13062 7.32787 2.72464 7.65287 3.32434 7.88608V7.89701L6.92707 9.05873C7.29522 9.20444 7.39359 9.35326 7.44164 9.48217C7.59791 9.89584 7.47305 10.715 7.42878 11.0009L7.20933 12.7587L5.99139 19.6412C5.98761 19.6599 5.9842 19.679 5.98118 19.6986L5.97247 19.7482C5.8847 20.379 6.33343 20.9912 7.18322 20.9912C7.92481 20.9912 8.25209 20.4626 8.39397 19.7435C8.53586 19.0244 9.45338 13.5884 9.98308 13.5884C10.5128 13.5884 11.604 19.7435 11.604 19.7435C11.7459 20.4626 12.0731 20.9912 12.8147 20.9912C13.6668 20.9912 14.1155 20.3763 14.0255 19.7435C14.0177 19.6903 14.0081 19.6374 13.9967 19.5849L12.7621 12.7595L12.5431 11.0017C12.3845 9.97787 12.512 9.63959 12.5552 9.5603C12.5563 9.55843 12.5573 9.55647 12.5582 9.55444C12.5991 9.47631 12.7852 9.30131 13.2196 9.13295L16.5976 7.91381C16.6183 7.90811 16.6388 7.90133 16.6588 7.8935C17.2642 7.65912 17.8696 7.33491 17.8696 6.64623C17.8696 5.95756 17.3781 5.20248 16.6592 5.39741Z" />
      </svg>
    </button>
  );
};
