import * as React from 'react';
import { Author } from './Author';

type Author = { name: string; link?: string };
export type AuthorsList = Author[];

interface AuthorsListProps {
  by: string;
  authors: AuthorsList | null;
}

const getPrefix = (index: number) => {
  return index > 0 ? ', ' : '';
};

export const AuthorsList: React.FunctionComponent<AuthorsListProps> = props => {
  const { authors, by } = props;

  return (
    <React.Fragment>
      {`${by} `}
      {authors && authors.map((author, index) => <Author key={index} {...author} prefix={getPrefix(index)} />)}
    </React.Fragment>
  );
};
