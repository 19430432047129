import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Helmet } from 'react-helmet';
import { TYPOGRAPHY_SIZE } from 'mm-theme-configuration/dist/consts';
import { useTheme } from '../../../theming/useTheme';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { ChevronIcon, Directions } from '../../components/navigation/header/ChevronIcon';
import { DropDownMenu } from '../../components/navigation/header/oldHeader/DropDownMenu';
import { H4, semanticCSS } from '../../../typography/semanticTags';
import { getStyleBySize } from '../../../typography/semanticTags/semanticTags.utils';
import { NavigationLink } from '../../components/navigation/header/oldHeader/primaryHeader/PrimaryHeader';
import { HeaderHeightsPerBreakpoint } from '../../components/navigation/header/oldHeader/oldHeader.utils';
import { baseUnit } from '../../../theming/baseUnitDefinition';
import { componentConfigThemeKey, HeaderThemeProps } from '../../components/navigation/header/Header.theme';

export interface AMPMoreLinksProps {
  moreLinksText: string;
  links: NavigationLink[];
  pagePath: string;
  headerHeights: HeaderHeightsPerBreakpoint;
}

export const getStyle = (titleColor: string, headerHeights: HeaderHeightsPerBreakpoint) => {
  return StyleSheet.create({
    wrapper: {
      height: 'inherit',
      [MEDIA_BREAKPOINTS.large]: {
        display: 'block',
      },
      [MEDIA_BREAKPOINTS.small]: {
        display: 'none',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        display: 'none',
      },
    },
    titleWrapper: {
      width: 'fit-content',
      [MEDIA_BREAKPOINTS.large]: {
        height: `${headerHeights.large * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        height: `${headerHeights.medium * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        height: `${headerHeights.small * baseUnit}px`,
      },
      color: titleColor,
      textDecoration: 'none',
      backgroundColor: 'transparent',
      border: 'none',
      outline: 'none',
      ':hover': {
        cursor: 'pointer',
      },
    },
    title: {
      height: '100%',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
    },
    text: {
      marginRight: '9px',
    },
  });
};
export const AMPMoreLinks: React.FunctionComponent<AMPMoreLinksProps> = props => {
  const { secondRowLinkCurrentColor, fontSizesStyles, secondRowLinkDropdownIconColor } = useTheme<HeaderThemeProps>(componentConfigThemeKey);
  const { links, moreLinksText, pagePath, headerHeights } = props;
  const titleStyle = getStyleBySize(fontSizesStyles, { fontSizeSmall: TYPOGRAPHY_SIZE.NORMAL, fontSizeMedium: TYPOGRAPHY_SIZE.NORMAL, fontSizeLarge: TYPOGRAPHY_SIZE.NORMAL });
  const style = getStyle(secondRowLinkCurrentColor, headerHeights);
  return (
    <React.Fragment>
      <Helmet>
        <script async={undefined} custom-element="amp-accordion" src="https://cdn.ampproject.org/v0/amp-accordion-0.1.js" />
      </Helmet>
      <div className={css(style.wrapper)}>
        {/*
  // @ts-ignore TS2339: Property 'amp-accordion' does not exist on type 'JSX.IntrinsicElements'. */}
        <amp-accordion id="accordion-more-links" disable-session-states={undefined}>
          <section>
            <H4 styles={semanticCSS(style.titleWrapper, titleStyle)}>
              <div className={css(style.title)}>
                <div className={css(style.text)}>{moreLinksText}</div>
                <ChevronIcon color={secondRowLinkDropdownIconColor} direction={Directions.DOWN} />
              </div>
            </H4>
            <DropDownMenu menuLinks={links} configThemeKey="moreLinks" activeEndpoint={pagePath} />
          </section>
          {/*
  // @ts-ignore TS2339: Property 'amp-accordion' does not exist on type 'JSX.IntrinsicElements'. */}
        </amp-accordion>
      </div>
    </React.Fragment>
  );
};
