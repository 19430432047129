import { css, StyleSheet } from 'aphrodite/no-important';
import { ALIGNMENT, ALIGNMENT_OPTIONS } from 'mm-theme-configuration/dist/consts';
import * as React from 'react';
import { MEDIA_BREAKPOINTS } from '../../../../mediaQueries.const';
import { baseUnit } from '../../../../theming/baseUnitDefinition';
import { useTheme } from '../../../../theming/useTheme';
import { clickableLabelsStyles } from '../cards.utils';
import { semanticCSS } from '../../../../typography/semanticTags';
import { Link } from '../../link/Link';
import { DynamicSemanticTag } from '../../../../typography/semanticTags/DynamicSemanticTag';
import { componentConfigThemeKey, VerticalCardThemeProps } from './VerticalCard.theme';

export interface MainCategoryProps {
    isClickable: boolean;
    displayName?: string;
    link?: string;
    style?: object;
}

export const createMainCategoryStyle = (
  showMainCategoryInLargeScreen: boolean,
  showMainCategoryInMediumScreen: boolean,
  showMainCategoryInSmallScreen: boolean,
  mainCategoryHorizontalGapLarge: number,
  mainCategoryHorizontalGapMedium: number,
  mainCategoryHorizontalGapSmall: number,
  mainCategoryVerticalGapLarge: number,
  mainCategoryVerticalGapMedium: number,
  mainCategoryVerticalGapSmall: number,
  mainCategoryBackgroundColor: string,
  mainCategoryTextColor: string,
  isMainCategoryClickable: boolean,
  style?: object,
) => StyleSheet.create({
  style: {
    backgroundColor: mainCategoryBackgroundColor,
    color: mainCategoryTextColor,
    position: 'absolute',
    zIndex: isMainCategoryClickable ? 200 : 'unset',
    bottom: 0,
    width: 'fit-content',
    ...style,
    [MEDIA_BREAKPOINTS.large]: {
      display: showMainCategoryInLargeScreen ? 'block' : 'none',
      padding: `${mainCategoryHorizontalGapLarge * baseUnit}px ${mainCategoryVerticalGapLarge * baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      display: showMainCategoryInMediumScreen ? 'block' : 'none',
      padding: `${mainCategoryHorizontalGapMedium * baseUnit}px ${mainCategoryVerticalGapMedium * baseUnit}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      display: showMainCategoryInSmallScreen ? 'block' : 'none',
      padding: `${mainCategoryHorizontalGapSmall * baseUnit}px ${mainCategoryVerticalGapSmall * baseUnit}px`,
    },
  },
}).style;

export const createMainCategoryAlignmentStyle = (alignment: ALIGNMENT = ALIGNMENT_OPTIONS.START) => {
  switch (alignment) {
    case ALIGNMENT_OPTIONS.CENTER:
      return StyleSheet.create({
        style: {
          left: '50%',
          transform: 'translateX(-50%)',
        },
      }).style;
    case ALIGNMENT_OPTIONS.START:
    default:
      return StyleSheet.create({
        style: {
          left: 0,
        },
      }).style;
  }
};

export const MainCategory: React.FunctionComponent<MainCategoryProps> = ({ isClickable, displayName, link, style = {} }) => {
  const {
    showMainCategoryInLargeScreen,
    showMainCategoryInMediumScreen,
    showMainCategoryInSmallScreen,
    mainCategoryHorizontalGapLarge,
    mainCategoryHorizontalGapMedium,
    mainCategoryHorizontalGapSmall,
    mainCategoryVerticalGapLarge,
    mainCategoryVerticalGapMedium,
    mainCategoryVerticalGapSmall,
    mainCategoryBackgroundColor,
    mainCategoryTextColor,
    mainCategorySemanticTag,
    mainCategoryFontSizeLarge,
    mainCategoryFontSizeMedium,
    mainCategoryFontSizeSmall,
    mainCategoryAlignment,
  } = useTheme<VerticalCardThemeProps>(componentConfigThemeKey);
  const { categoryLink } = clickableLabelsStyles();
  const mainCategoryAlignmentStyle = createMainCategoryAlignmentStyle(mainCategoryAlignment);
  const mainCategoryStyle = createMainCategoryStyle(
    showMainCategoryInLargeScreen,
    showMainCategoryInMediumScreen,
    showMainCategoryInSmallScreen,
    mainCategoryHorizontalGapLarge,
    mainCategoryHorizontalGapMedium,
    mainCategoryHorizontalGapSmall,
    mainCategoryVerticalGapLarge,
    mainCategoryVerticalGapMedium,
    mainCategoryVerticalGapSmall,
    mainCategoryBackgroundColor,
    mainCategoryTextColor,
    isClickable,
    style,
  );
  const semanticTagProps = {
    semanticTag: mainCategorySemanticTag,
    fontSizeLarge: mainCategoryFontSizeLarge,
    fontSizeMedium: mainCategoryFontSizeMedium,
    fontSizeSmall: mainCategoryFontSizeSmall,
    styles: semanticCSS(mainCategoryStyle, mainCategoryAlignmentStyle),
  };
  return (isClickable
    ? (
      <Link className={css(categoryLink)} href={link}>
        <DynamicSemanticTag
          {...semanticTagProps}
        >
          {displayName}
        </DynamicSemanticTag>
      </Link>
    )
    : (
      <DynamicSemanticTag
        {...semanticTagProps}
      >
        {displayName}
      </DynamicSemanticTag>
    )
  );
};
