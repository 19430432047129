import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Helmet } from 'react-helmet';
import { Thumbnail } from '../partials/image/image.types';
import { OptionalAdditionalStyle } from '../components.utils';
import { useTheme } from '../../theming/useTheme';
import { ampAspectRatio, ampImageWidths, normalizeDimensions, preloadImage } from './AMPImage.utils';
import { getStyleBySize } from '../../typography/semanticTags/semanticTags.utils';
import { BaseUnit } from '../../theming/baseUnitDefinition';
import {
  createImageAspectRatios,
  getImageSrcAndSrcSet,
  ImageAspectRatios,
  ImageHeightCalculationMethods,
  ImageWidths,
  useTransformCreditHTML,
} from '../partials/image/image.utils';
import { LARGE_SCREEN_SIZE, MEDIUM_SCREEN_SIZE, SMALL_SCREEN_SIZE } from '../../mediaQueries.const';
import { componentConfigurationKey, ImageThemeProps } from '../partials/image/Image.theme';

interface AMPImageProps {
  src: string;
  alt: string;
  width: number;
  height: number;
  srcSetLargeScreen: string;
  srcSetMediumScreen: string;
  srcSetSmallScreen: string;
}

export interface ImageDataProps {
  preload?: boolean;
  dataId?: string;
  imageWidths?: ImageWidths;
  imageAspectRatios?: ImageAspectRatios;
  imageHeightCalculationMethods?: ImageHeightCalculationMethods;
  transparent?: boolean;
  hideFigcaption?: boolean;
}

export type ImageProps = ImageDataProps & OptionalAdditionalStyle & Thumbnail;

export const getStyle = (baseUnit: BaseUnit, figcaptionTextColor: string) => StyleSheet.create({
  figcaption: {
    color: figcaptionTextColor,
    marginTop: baseUnit * 0.25,
    marginLeft: baseUnit * 0.25,
    paddingTop: baseUnit / 2,
    paddingBottom: baseUnit / 2,
  },
  imgStyle: {
    height: '100%',
  },
});

export const getAMPImageProps = (image: ImageProps) => {
  const { alt, caption, host, path, aspectRatio = ampAspectRatio, imageWidths = ampImageWidths, cropping = null } = image;
  const { imageAspectRatios = createImageAspectRatios(aspectRatio, aspectRatio, aspectRatio) } = image;
  const {
    srcSetMediumScreen,
    srcSetLargeScreen,
    fallbackSrc,
    srcSetSmallScreen,
  } = getImageSrcAndSrcSet(imageAspectRatios, imageWidths, host, path, cropping);
  const [width, height] = normalizeDimensions(aspectRatio);
  const actualAlt = alt || caption || '';
  return {
    src: fallbackSrc,
    alt: actualAlt,
    width,
    height,
    srcSetMediumScreen,
    srcSetLargeScreen,
    srcSetSmallScreen,
  };
};

const getAMPImage = (imageProps: AMPImageProps, imgStyle: object) => {
  const { width, height, src, alt, srcSetLargeScreen, srcSetMediumScreen, srcSetSmallScreen } = imageProps;
  return (
    <React.Fragment>
      <Helmet>
        <style amp-custom>
          {`            
            img {
              object-fit:cover;
            }
          `}
        </style>
      </Helmet>
      {/*
         // @ts-ignore TS2339 */}
      <amp-img
        class={css(imgStyle)}
        src={src}
        srcSet={srcSetSmallScreen}
        alt={alt}
        layout="responsive"
        width={width}
        height={height}
        media={`(max-width: ${SMALL_SCREEN_SIZE}px)`}
      />
      {/*
         // @ts-ignore TS2339 */}
      <amp-img
        class={css(imgStyle)}
        src={src}
        srcSet={srcSetMediumScreen}
        alt={alt}
        layout="responsive"
        width={width}
        height={height}
        media={`(min-width: ${SMALL_SCREEN_SIZE + 1}px) and (max-width: ${MEDIUM_SCREEN_SIZE}px)`}
      />
      {/*
         // @ts-ignore TS2339 */}
      <amp-img
        class={css(imgStyle)}
        src={src}
        srcSet={srcSetLargeScreen}
        alt={alt}
        layout="responsive"
        width={width}
        height={height}
        media={`(min-width: ${LARGE_SCREEN_SIZE}px)`}
      />
    </React.Fragment>
  );
};

const getImageCaption = (credit: string | undefined, caption: string | undefined): string => {
  return [caption, credit].filter(value => {
    return typeof value === 'string' && value.trim() !== '';
  }).join(' | ');
};

export const AMPImage: React.FunctionComponent<ImageProps> = props => {
  const { baseUnit, textColor, fontSizesStyles, fontSizeLarge, fontSizeMedium, fontSizeSmall } = useTheme<ImageThemeProps>(componentConfigurationKey);
  const {
    additionalStyle,
    preload,
    credit,
    caption,
    host,
    path,
    aspectRatio = ampAspectRatio,
    dataId,
    hideFigcaption,
    cropping = null,
  } = props;
  const imageProps = getAMPImageProps(props);
  const creditHTML = useTransformCreditHTML(credit);
  const figCaption = getImageCaption(creditHTML, caption);
  const figCaptionStyle = getStyle(baseUnit, textColor);
  const figcaptionText = getStyleBySize(fontSizesStyles, { fontSizeSmall, fontSizeMedium, fontSizeLarge });

  const figureClassNameProp = additionalStyle ? { className: css(additionalStyle.style) } : {};

  return (
    <figure data-id={dataId} {...figureClassNameProp}>
      {preload && preloadImage(host, path, aspectRatio, cropping)}
      {getAMPImage(imageProps, figCaptionStyle.imgStyle)}
      {!hideFigcaption && figCaption && (
      <figcaption
        className={css(figCaptionStyle.figcaption, figcaptionText)}
        dangerouslySetInnerHTML={{ __html: figCaption }}
      />
      )}
    </figure>
  );
};
