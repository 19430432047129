import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { TypographySize } from 'mm-theme-configuration/dist/consts';
import { Link } from '../../../../partials/link/Link';
import { useTheme } from '../../../../../theming/useTheme';
import { BaseUnit } from '../../../../../theming/baseUnitDefinition';
import { getLinkColorsStyle } from './linkStyle.utils';
import { Span } from '../../../../../typography/semanticTags';
import { getStyleBySize } from '../../../../../typography/semanticTags/semanticTags.utils';
import { LinkWithSubMenu } from './LinkWithSubMenu';
import { useSubMenu } from './subMenuReducer';
import { NavigationLink } from '../oldHeader/primaryHeader/PrimaryHeader';

const MAX_NUM_ROWS = 7;

interface SubMenuProps {
  links: NavigationLink[];
  parentElement: HTMLElement;
  activeStateColor: string;
  hoverStateColor: string;
  idleColor: string;
  currentLinkColor: string;
  pagePath: string;
  linkFontSize: TypographySize;
  backgroundColor: string;
  shouldOffsetLeft: boolean;
  animationDuration: number;
  openLinkIndex: number;
  innerLinksBackgroundColor?: string;
  innerLinkFontSize?: TypographySize;
  linkDropdownIconColor: string;
  rowFinalHeightInLargeScreen: number;
  isParentLinkVisibleOnTheRow: boolean;
}

const getPaddingForUlLinks = (baseUnit: BaseUnit) => baseUnit / 2;

const getStyles = (
  backgroundColor: string,
  baseUnit: BaseUnit,
  offset: number,
  numMenuRows: number,
  minWidth: number,
  shouldOffsetLeft: boolean,
  animationDuration: number,
  rowFinalHeightInLargeScreen: number,
  isParentLinkVisibleOnTheRow: boolean,
) => {
  return StyleSheet.create({
    ul: {
      position: 'absolute',
      left: shouldOffsetLeft ? 0 : `${offset - getPaddingForUlLinks(baseUnit)}px`,
      transition: `top ${animationDuration}ms ease 0s`,
      top: isParentLinkVisibleOnTheRow ? `${rowFinalHeightInLargeScreen / 2}px` : `-${getPaddingForUlLinks(baseUnit)}px`,
      backgroundColor,
      listStyle: 'none',
      padding: `${getPaddingForUlLinks(baseUnit)}px`,
      display: 'grid',
      gridRowGap: `${baseUnit / 2}px`,
      gridColumnGap: `${baseUnit * 4}px`,
      gridAutoFlow: 'column',
      gridTemplateRows: `repeat(${numMenuRows}, 1fr)`,
      minWidth: `${minWidth}px`,
      transform: shouldOffsetLeft ? 'translateX(-100%)' : '',
    },
    li: {
      padding: 0,
      position: 'relative',
    },
    link: {
      whiteSpace: 'nowrap',
      cursor: 'pointer',
    },
  });
};

const getOpenListItemStyle = (isSubMenuOpen: boolean, activeMenuBackgroundColor: string, baseUnit: BaseUnit) => {
  if (!isSubMenuOpen) return null;

  return StyleSheet.create({
    li: {
      position: 'relative',
      ':before': {
        content: isSubMenuOpen ? "''" : '',
        backgroundColor: activeMenuBackgroundColor,
        position: 'absolute',
        top: `-${getPaddingForUlLinks(baseUnit)}px`,
        left: `-${getPaddingForUlLinks(baseUnit)}px`,
        bottom: `-${getPaddingForUlLinks(baseUnit)}px`,
        right: `-${getPaddingForUlLinks(baseUnit)}px`,
        zIndex: 0,
      },
    },
  }).li;
};

const getMenuOffset = (parentElement: HTMLElement) => {
  const offset = parentElement.getBoundingClientRect().left;
  return offset;
};

const getMenuMinWidth = (parentElement: HTMLElement) => {
  return parentElement.getBoundingClientRect().width;
};

export const SubMenu: React.FunctionComponent<SubMenuProps> = props => {
  const { links, parentElement, activeStateColor, hoverStateColor, idleColor, currentLinkColor, linkFontSize, backgroundColor, pagePath, shouldOffsetLeft, animationDuration, rowFinalHeightInLargeScreen, openLinkIndex, innerLinkFontSize, innerLinksBackgroundColor, linkDropdownIconColor, isParentLinkVisibleOnTheRow } = props;
  const { baseUnit, fontSizesStyles } = useTheme();
  const [subMenuState, dispatch] = useSubMenu();
  const offset = getMenuOffset(parentElement);
  const numMenuRows = Math.min(links.length, MAX_NUM_ROWS);
  const minWidth = getMenuMinWidth(parentElement);
  const styles = getStyles(backgroundColor, baseUnit, offset, numMenuRows, minWidth, shouldOffsetLeft, animationDuration, rowFinalHeightInLargeScreen, isParentLinkVisibleOnTheRow);
  const fontStyle = getStyleBySize(fontSizesStyles, {
    fontSizeSmall: linkFontSize,
    fontSizeMedium: linkFontSize,
    fontSizeLarge: linkFontSize,
  });
  const isMenuOpen = subMenuState.links && subMenuState.links.length > 0;
  const ulRef = React.useRef<HTMLUListElement>(null);

  const onHoverLink = (element: HTMLElement, index: number) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const menuLinks = links![index].children;
    dispatch({
      data: {
        links: menuLinks,
        element,
        index,
        linkFontSize: innerLinkFontSize || linkFontSize,
        backgroundColor: innerLinksBackgroundColor || backgroundColor,
      },
    });
  };

  const onLeaveLink = () => {
    dispatch({
      data: {
        links: null,
        element: null,
        index: -1,
        linkFontSize: innerLinkFontSize || linkFontSize,
        backgroundColor: innerLinksBackgroundColor || backgroundColor,
      },
    });
  };

  return (
    <React.Fragment>
      <ul className={css(styles.ul)} ref={ulRef}>
        {
          links.map((navigationLink, index) => {
            const isLinkActive = pagePath === navigationLink.link.href;
            const isLinkWithSubMenu = navigationLink.children !== null && navigationLink.children.length > 0;
            const { href } = navigationLink.link;
            return (
              <li
                key={index}
                className={css(getOpenListItemStyle(subMenuState.index === index, innerLinksBackgroundColor || backgroundColor, baseUnit))}
                onMouseEnter={e => (isLinkWithSubMenu && onHoverLink(e.target as HTMLElement, index))}
                onMouseLeave={onLeaveLink}
              >
                {
                  isLinkWithSubMenu ? (
                    <React.Fragment>
                      {
                          isMenuOpen && subMenuState.index === index && (
                          <SubMenu
                            activeStateColor={activeStateColor}
                            hoverStateColor={hoverStateColor}
                            idleColor={idleColor}
                            backgroundColor={innerLinksBackgroundColor || backgroundColor}
                            linkFontSize={innerLinkFontSize || linkFontSize}
                            pagePath={pagePath}
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            links={subMenuState.links!}
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            parentElement={subMenuState.element!}
                            rowFinalHeightInLargeScreen={rowFinalHeightInLargeScreen}
                            animationDuration={animationDuration}
                            shouldOffsetLeft
                            currentLinkColor={currentLinkColor}
                            openLinkIndex={subMenuState.index}
                            linkDropdownIconColor={linkDropdownIconColor}
                            isParentLinkVisibleOnTheRow={false}
                          />
                          )
                        }
                      <LinkWithSubMenu
                        title={navigationLink.link.text}
                        hoverStateColor={hoverStateColor}
                        idleColor={openLinkIndex === index ? hoverStateColor : idleColor}
                        index={index}
                        linkDropdownIconColor={linkDropdownIconColor}
                        linkFontSizeLarge={linkFontSize}
                        linkFontSizeMedium={linkFontSize}
                        linkFontSizeSmall={linkFontSize}
                        isOpen={subMenuState.index === index}
                        activeStateColor={activeStateColor}
                        currentLinkColor={currentLinkColor}
                        href={href}
                        subLinks={navigationLink.children || []}
                      />
                    </React.Fragment>
                  )
                    : (
                      <Link href={href} className={css(styles.link)}>
                        <Span
                          className={css(fontStyle, getLinkColorsStyle(activeStateColor, hoverStateColor, idleColor, currentLinkColor, isLinkActive).link)}
                        >
                          {navigationLink.link.text}
                        </Span>
                      </Link>

                    )
                }
              </li>
            );
          })
        }
      </ul>
    </React.Fragment>
  );
};
