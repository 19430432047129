import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useTheme } from '../../../../../../../../theming/useTheme';
import { generateLinkStyleFromTheme, TextLinkComponent } from '../../../../../../../partials/link/TextLink';
import { P } from '../../../../../../../../typography/semanticTags';
import { componentConfigThemeKey, HeaderThemeProps } from '../../../../Header.theme';
import { OptionalAdditionalStyle } from '../../../../../../../components.utils';

export interface Link {
  href: string;
  text: string;
}

export interface MenuLinkDataProps {
  link: Link;
  childrenLinks: MenuLinkDataProps[] | null;
}

const getMenuSubLinkStyle = (baseUnit: number) => StyleSheet.create({
  style: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginRight: `${baseUnit}px`,
    marginLeft: `${baseUnit * 1.5}px`,
    listStyleType: 'none',
  },
});

export const MenuSubLink: React.FunctionComponent<Link & OptionalAdditionalStyle> = props => {
  const { href, text, additionalStyle } = props;
  const { baseUnit } = useTheme();
  const { hamburgerMenuSubLinksNormalColor, hamburgerMenuSubLinksFontSizeMedium, hamburgerMenuSubLinksFontSizeSmall } = useTheme<HeaderThemeProps>(componentConfigThemeKey);
  const linkStyle = generateLinkStyleFromTheme({ idleColor: hamburgerMenuSubLinksNormalColor });
  const style = getMenuSubLinkStyle(baseUnit);

  return (
    <li className={css(style.style, additionalStyle?.style)}>
      <TextLinkComponent href={href} linkStyle={linkStyle}>
        <P fontSizeMedium={hamburgerMenuSubLinksFontSizeMedium} fontSizeSmall={hamburgerMenuSubLinksFontSizeSmall}>{text}</P>
      </TextLinkComponent>
    </li>
  );
};
