import { Link, LinkButton, NavigationLink, SvgLink } from '../../navigation.utils';
import { INJECT_BUTTON_TO_FOOTER_LINKS_SECTION, InjectButtonToFooterLinksSectionAction } from '../../navigation.actions';

export interface FooterState {
  linksSection: {
    links: Array<NavigationLink>;
    buttons?: Array<LinkButton>;
  };
  bottomFooterBar: {
    year: number;
    rightsReservedText: string;
    poweredByLink: Link;
  };
  logo: SvgLink;
}

export type FooterAction = InjectButtonToFooterLinksSectionAction;
export const footerReducer = (state: FooterState = {} as FooterState, action: FooterAction) => {
  switch (action.type) {
    case INJECT_BUTTON_TO_FOOTER_LINKS_SECTION: {
      const { text, onClick = null, cssClassName = null, attributes = null } = action;
      const { buttons } = state.linksSection;
      return {
        ...state,
        linksSection: {
          ...state.linksSection,
          buttons: [
            ...(buttons || []),
            {
              text,
              onClick,
              cssClassName,
              attributes,
            },
          ],
        },
      };
    }
    default:
      return state;
  }
};
