import * as React from 'react';
import { StyleSheet } from 'aphrodite/no-important';
import { MandatoryAdditionalStyle, Style } from '../components.utils';
import { useTheme } from '../../theming/useTheme';
import { ErrorBoundary } from '../errorHandling/ErrorBoundary';
import { Span, semanticCSS, H2 } from '../../typography/semanticTags';
import { getStyleBySize } from '../../typography/semanticTags/semanticTags.utils';
import { BlockWidths } from '../components/pageLayouts/BlockTypes.utils';
import { createBlockStyle } from './blockMutualStyle';
import { ArticleBlockThemeProps, componentConfigurationKey } from './ArticleBlock.theme';

interface ListItemTitleComponentDataProps {
  listItemNumber: string;
  text: string;
  dataId: string;
  defaultBlocksWidths: BlockWidths;
}

const getStyle = (titleColor: string, numberColor: string, blockStyle: Style) => {
  return StyleSheet.create({
    titleStyle: {
      color: titleColor,
      ...blockStyle,
    },
    numberStyle: {
      color: numberColor,
    },
  });
};

export const ListItemTitle: React.FunctionComponent<ListItemTitleComponentDataProps & MandatoryAdditionalStyle> = props => {
  const { listItemNumber, text, additionalStyle, dataId, defaultBlocksWidths } = props;
  const { listTitleColor, listNumberColor, fontSizesStyles, listTitleFontSizeLarge, listTitleFontSizeMedium, listTitleFontSizeSmall } = useTheme<ArticleBlockThemeProps>(componentConfigurationKey);
  const blockStyle = createBlockStyle(defaultBlocksWidths);
  const { titleStyle, numberStyle } = getStyle(listTitleColor, listNumberColor, blockStyle);
  const contentStyle = getStyleBySize(fontSizesStyles, { fontSizeSmall: listTitleFontSizeSmall, fontSizeMedium: listTitleFontSizeMedium, fontSizeLarge: listTitleFontSizeLarge });
  return (
    <ErrorBoundary>
      <H2
        fontSizeLarge={listTitleFontSizeLarge}
        fontSizeMedium={listTitleFontSizeMedium}
        fontSizeSmall={listTitleFontSizeSmall}
        data-mm-id={dataId}
        id={dataId}
        styles={semanticCSS(additionalStyle.style, titleStyle)}
      >
        <Span styles={semanticCSS(numberStyle, contentStyle)}>
          {`${listItemNumber}. `}
        </Span>
        {text}
      </H2>
    </ErrorBoundary>
  );
};
