import { combineReducers, compose, createStore, applyMiddleware, Reducer, Store } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { AppActions, AppState } from './initialStateRegistration';

declare global {
  interface Window {
    /* eslint-disable-next-line */
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

export const createStoreFromPreloadedState = (preloadedState: AppState, rootReducer: Reducer<AppState>) => {
  const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
  return createStore(
      combineReducers(rootReducer as any),
      preloadedState as any,
    composeEnhancers(
      applyMiddleware(thunkMiddleware),
    ),
  ) as Store<AppState, AppActions>;
};
