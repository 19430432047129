import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { HeaderHeightsPerBreakpoint } from './oldHeader/oldHeader.utils';
import { MEDIA_BREAKPOINTS } from '../../../../mediaQueries.const';
import { ErrorBoundary } from '../../../errorHandling/ErrorBoundary';
import { AnimationDirectionType } from './menuAnimations.util';
import { useHeaderHeights } from './useHeaderHeights';
import { baseUnit } from '../../../../theming/baseUnitDefinition';

interface MontiAnchorProps {
  isSecondaryHeaderEnabled: boolean;
  menuAnimation: AnimationDirectionType;
}

const getStyle = (headerHeights: HeaderHeightsPerBreakpoint) => {
  return StyleSheet.create({
    anchor: {
      position: 'fixed',
      [MEDIA_BREAKPOINTS.large]: {
        top: headerHeights.large * baseUnit,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        top: headerHeights.medium * baseUnit,
      },
      [MEDIA_BREAKPOINTS.small]: {
        top: headerHeights.small * baseUnit,
      },
      left: 0,
      right: 0,
      height: 0,
      transition: '800ms',
    },
    foldingUp: {
      [MEDIA_BREAKPOINTS.medium]: {
        top: 0,
      },
      [MEDIA_BREAKPOINTS.small]: {
        top: 0,
      },
    },
    foldingDown: {
      [MEDIA_BREAKPOINTS.medium]: {
        top: headerHeights.medium * baseUnit,
      },
      [MEDIA_BREAKPOINTS.small]: {
        top: headerHeights.small * baseUnit,
      },
    },
  });
};

export const MontiAnchor: React.FC<MontiAnchorProps> = props => {
  const { menuAnimation } = props;
  const headerHeights = useHeaderHeights();
  const style = getStyle(headerHeights);
  let finalStyle;
  switch (menuAnimation) {
    case 'foldingUp': finalStyle = css(style.anchor, style.foldingUp); break;
    case 'foldingDown': finalStyle = css(style.anchor, style.foldingDown); break;
    default: finalStyle = css(style.anchor);
  }

  return <ErrorBoundary><div className={`${finalStyle} video-top-anchor`} /></ErrorBoundary>;
};
