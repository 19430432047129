import React from 'react';
import { ThemeContext } from './ThemeProviderWithFonts';

export function useThemeOverride<T>(override: Partial<T>, key: string) {
  const themeContext = React.useContext(ThemeContext);
  return {
    ...themeContext,
    [key]: {
      ...themeContext[key],
      ...override,
    },
  };
}
