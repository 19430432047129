import React, { useCallback, useEffect, useState } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { DrawerHeader } from './DrawerHeader';
import { DrawerMenu } from './DrawerMenu';

const maxArticlesNumber = 10;
interface DrawerProps {
  articles: CardComponentDataProps[];
}

const getStyles = () => {
  return StyleSheet.create({
    overlay: {
      width: '100%',
      height: '100vh',
      position: 'absolute',
      opacity: 0.5,
      backgroundColor: 'white',
      zIndex: 1550,
      top: 0,
    },
  });
};


export const Drawer: React.FunctionComponent<DrawerProps> = props => {
  const { articles = [] } = props;
  const [isOpen, setIsOpen] = useState(false);
  const articlesNum = articles?.length < maxArticlesNumber ? `${articles?.length}` : `${articles?.length}+`;
  const styles = getStyles();

  const onClick = () => {
    setIsOpen(prevState => !prevState);
  };

  const closeDrawer = useCallback(() => {
    setIsOpen(false);
  }, []);
  useEffect(() => {
    window.addEventListener('scroll', closeDrawer);
    return () => {
      window.removeEventListener('scroll', closeDrawer);
    };
  }, [closeDrawer]);

  return (
    <div>
      {isOpen && <div className={css(styles.overlay)} onClick={onClick} />}
      <DrawerHeader onClick={onClick} articlesNum={articlesNum} />
      {isOpen && <DrawerMenu articles={articles} onClick={onClick} articlesNum={articlesNum} />}
    </div>
  );
};
