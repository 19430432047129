import { AnalyticsParams } from './analytics.types';

declare global {
  interface Window { dataLayer: Array<any> }
}

function sendToDataLayer(event: string, data: object) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    ...data,
    event,
  });
}

export function sendPageViewToGoogleAnalytics(params: AnalyticsParams, eventName: string) {
  sendToDataLayer(eventName, params);
}

export function sendEventToGoogleAnalytics(event_category: string, event_action: string, event_label: string, params: {[key: string]: any}) {
  const eventData: {[key: string]: string} = {
    GA_event_category: event_category,
    GA_event_action: event_action,
    GA_event_label: event_label,
  };

  if (typeof params === 'object') {
    Object.keys(params)
      .forEach(key => { eventData[`GA_${key}`] = params[key]; });
  }

  sendToDataLayer('GTM event To GA', eventData);
}
