import * as React from 'react';
import { PageDescription } from './PageDescription';
import { FacebookMeta } from './FacebookMeta';
import { TwitterShare } from './TwitterShare';
import { GoogleNewsSchema } from './GoogleNewsSchema';
import { createImageAttributes, PageImage } from './metaTags.utils';
import { RobotsMeta } from './RobotsMeta';
import { OpenGraphArticleTags } from './OpenGraphArticleTags';
import { BreadCrumbsSchema } from './BreadcrumbsSchema';
import { FAQSchema } from './FAQSchema';
import { LiveBlogSchema } from './LiveBlogSchema';

interface PostMetaHeaderTagsProps {
  canonicalUrl: string;
  favicon: PageImage;
  pageDescription: string;
  facebookAppId: string | null;
  facebookPageId: string | null;
  seoTitle: string;
  twitterAccount: string | null;
  siteName: string;
  pageImage: PageImage;
  logo: PageImage;
  authors: { name: string; link?: string }[] | null;
  createdAtISO: string;
  updatedAtISO: string;
  noIndex: boolean;
  ampURL: string | null;
  prevUrl: string | null;
  nextUrl: string | null;
  ogDescription: string;
  ogTitle: string;
  ogImage: PageImage;
  twitterDescription: string;
  twitterTitle: string;
  twitterImage: PageImage;
  seoDescription: string;
  articleSection: string;
  tags: string[];
  breadCrumbs?: {
    homeDisplayName: string;
    homeLink: string;
    categoryDisplayName: string;
    categoryLink: string;
  } | null;
  faqData: { question: string; answer: string }[];
  liveBlogData?: Record<string, string>[];
  articleSchemaVideo?: Record<string, any>;
}

export const PostMetaHeaderTags: React.FunctionComponent<
  PostMetaHeaderTagsProps
> = props => {
  const {
    canonicalUrl,
    favicon,
    pageDescription,
    facebookAppId,
    facebookPageId,
    seoTitle,
    twitterAccount,
    siteName,
    pageImage,
    logo,
    authors,
    createdAtISO,
    updatedAtISO,
    noIndex,
    ampURL,
    prevUrl,
    nextUrl,
    ogDescription,
    ogImage,
    ogTitle,
    twitterDescription,
    twitterImage,
    twitterTitle,
    seoDescription,
    articleSection,
    tags,
    breadCrumbs,
    faqData,
    liveBlogData,
    articleSchemaVideo,
  } = props;
  const pageImageUrl = createImageAttributes(pageImage).imageUrl;
  const logoImageAttributes = logo
    ? createImageAttributes(logo)
    : { imageUrl: '' };
  const faviconImageAttributes = favicon
    ? createImageAttributes(favicon)
    : { imageUrl: '' };
  const facebookImageProperties = createImageAttributes(ogImage);
  const twitterImageProperties = createImageAttributes(twitterImage);

  return (
    <React.Fragment>
      <PageDescription
        pageUrl={canonicalUrl}
        favicon={faviconImageAttributes.imageUrl}
        description={pageDescription}
        title={seoTitle}
        ampURL={ampURL}
        prevUrl={prevUrl}
        nextUrl={nextUrl}
      />
      <FacebookMeta appId={facebookAppId} pageId={facebookPageId} />
      <OpenGraphArticleTags
        pageUrl={canonicalUrl}
        image={facebookImageProperties.imageUrl}
        title={ogTitle}
        siteName={siteName}
        imageHeight={facebookImageProperties.imageHeight}
        imageWidth={facebookImageProperties.imageWidth}
        description={ogDescription}
        createdAtISO={createdAtISO}
        updatedAtISO={updatedAtISO}
        articleSection={articleSection}
        tags={tags}
      />
      <TwitterShare
        title={twitterTitle}
        description={twitterDescription}
        account={twitterAccount}
        image={twitterImageProperties.imageUrl}
        pageUrl={canonicalUrl}
        imageHeight={twitterImageProperties.imageHeight}
        imageWidth={twitterImageProperties.imageWidth}
      />
      <GoogleNewsSchema
        canonical={canonicalUrl}
        title={seoTitle}
        image={pageImage}
        datePublished={createdAtISO}
        dateModified={updatedAtISO}
        authors={authors}
        publisherName={siteName}
        logoUrl={logoImageAttributes.imageUrl}
        seoDescription={seoDescription}
        articleSection={articleSection}
        articleSchemaVideo={articleSchemaVideo}
      />
      {liveBlogData?.length ? (
        <LiveBlogSchema
          pageUrl={canonicalUrl}
          title={seoTitle}
          image={pageImageUrl}
          datePublished={createdAtISO}
          dateModified={updatedAtISO}
          authors={authors}
          publisherName={siteName}
          logoUrl={logoImageAttributes.imageUrl}
          seoDescription={seoDescription}
          liveBlogData={liveBlogData}
        />
      ) : null}
      <BreadCrumbsSchema {...breadCrumbs} />
      <FAQSchema faqData={faqData} />
      <RobotsMeta noIndex={noIndex} />
    </React.Fragment>
  );
};
