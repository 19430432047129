import { predicates } from '../predicates/index';
import { TRAFFIC_SOURCES } from '../constants';

export const direct = {
  rule: predicates.and(
    predicates.not(predicates.referrerExists()),
    predicates.not(
      predicates.or(
        predicates.searchParamsIncludeKey('utm_medium'),
        predicates.searchParamsIncludeKey('utm_source'),
      ),
    ),
  ),
  extractor: () => {
    return {
      trafficSource: TRAFFIC_SOURCES.DIRECT,
      trafficId: null,
    };
  },
};
