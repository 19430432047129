import * as React from 'react';
import { Helmet } from 'react-helmet';
import { css, StyleSheet } from 'aphrodite/no-important';
import {
  getActiveEdition,
} from '../../components/navigation/header/oldHeader/primaryHeader/Editions';
import { useTheme } from '../../../theming/useTheme';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { DropDownMenu } from '../../components/navigation/header/oldHeader/DropDownMenu';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { ChevronIcon, Directions } from '../../components/navigation/header/ChevronIcon';
import { H4, semanticCSS } from '../../../typography/semanticTags';
import { NavigationLink } from '../../components/navigation/header/oldHeader/primaryHeader/PrimaryHeader';
import { HeaderHeightsPerBreakpoint } from '../../components/navigation/header/oldHeader/oldHeader.utils';
import { baseUnit } from '../../../theming/baseUnitDefinition';
import { componentConfigThemeKey, HeaderThemeProps } from '../../components/navigation/header/Header.theme';

export interface AmpEditionsDataProps {
  editions: NavigationLink[];
  editionEndpoint: string;
  headerHeights: HeaderHeightsPerBreakpoint;
}

const getStyle = (color: string, headerHeights: HeaderHeightsPerBreakpoint) => {
  return StyleSheet.create({
    editionsWrapper: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [MEDIA_BREAKPOINTS.medium]: {
        display: 'none',
      },
      [MEDIA_BREAKPOINTS.small]: {
        display: 'none',
      },
    },
    editionsTitleWrapper: {
      width: '144px',
      color,
      textDecoration: 'none',
      backgroundColor: 'transparent',
      border: 'none',
      outline: 'none',
      ':hover': {
        cursor: 'pointer',
      },
      [MEDIA_BREAKPOINTS.large]: {
        height: `${headerHeights.large * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        height: `${headerHeights.medium * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        height: `${headerHeights.small * baseUnit}px`,
      },
    },
    editionsTitle: {
      height: '100%',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
    },
  });
};

export const AMPEditions: React.FunctionComponent<AmpEditionsDataProps> = props => {
  const { editionsIconNormalColor } = useTheme<HeaderThemeProps>(componentConfigThemeKey);
  const { editions, editionEndpoint, headerHeights } = props;
  const activeEdition = getActiveEdition(editions, editionEndpoint);
  const style = getStyle(editionsIconNormalColor, headerHeights);
  return (
    <ErrorBoundary>
      <Helmet>
        <script async={undefined} custom-element="amp-accordion" src="https://cdn.ampproject.org/v0/amp-accordion-0.1.js" />
      </Helmet>
      <div className={css(style.editionsWrapper)}>
        {/*
  // @ts-ignore TS2339: Property 'amp-accordion' does not exist on type 'JSX.IntrinsicElements'. */}
        <amp-accordion id="accordion-editions" disable-session-states={undefined}>
          <section>
            <H4 styles={semanticCSS(style.editionsTitleWrapper)}>
              <div className={css(style.editionsTitle)}>
                <div>{activeEdition.link.text}</div>
                <ChevronIcon direction={Directions.DOWN} color={editionsIconNormalColor} />
              </div>
            </H4>
            <DropDownMenu menuLinks={editions} configThemeKey="editionsMenu" activeEndpoint={editionEndpoint} />
          </section>
          {/*
  // @ts-ignore TS2339: Property 'amp-accordion' does not exist on type 'JSX.IntrinsicElements'. */}
        </amp-accordion>
      </div>
    </ErrorBoundary>
  );
};
