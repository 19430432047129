import * as React from 'react';
import { ArticleMainImage, ArticleMainImageDataProps, RawHTMLCoverBlock } from './ArticleMainImage';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { RawHtmlArticleMainImage } from './RawHtmlArticleMainImage';

export const ConditionalArticleMainImage: React.FunctionComponent<ArticleMainImageDataProps> = props => {
  const { image, defaultBlocksWidths, media, siteName, templateName, paddingOverride, sizeType } = props;
  const rawHtmlMedia = media as RawHTMLCoverBlock;
  const rawHtmlContent = image.rawHtml || rawHtmlMedia?.rawHtml;

  return (
    <ErrorBoundary>
      {rawHtmlContent
        ? <RawHtmlArticleMainImage rawHtml={rawHtmlContent} defaultBlocksWidths={defaultBlocksWidths} paddingOverride={paddingOverride} />
        : <ArticleMainImage image={image} media={media} siteName={siteName} templateName={templateName} defaultBlocksWidths={defaultBlocksWidths} paddingOverride={paddingOverride} sizeType={sizeType} />}
    </ErrorBoundary>
  );
};
