import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { useTheme } from '../../../theming/useTheme';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { Span, semanticCSS } from '../../../typography/semanticTags';
import { componentConfigThemeKey, HeaderThemeProps } from '../../components/navigation/header/Header.theme';

const getMenuIconStyle = (color: string) => StyleSheet.create({
  wrapper: {
    width: '16px',
    height: '16px',
    position: 'relative',
    cursor: 'pointer',
    [MEDIA_BREAKPOINTS.large]: {
      display: 'none',
    },
    [MEDIA_BREAKPOINTS.medium]: {
      display: 'inline-flex',
    },
    [MEDIA_BREAKPOINTS.small]: {
      display: 'inline-flex',
    },
    ':focus': {
      outline: 'none',
    },
  },
  span: {
    display: 'block',
    position: 'absolute',
    backgroundColor: color,
    height: '2px',
    width: '100%',
    opacity: 1,
    left: 0,
  },
  firstSpanClose: {
    top: 0,
  },
  secondSpanClose: {
    top: '7px',
  },
  thirdSpanClose: {
    top: '14px',
  },
});


export const AMPMenuIcon: React.FunctionComponent = () => {
  const { hamburgerMenuIconColor } = useTheme<HeaderThemeProps>(componentConfigThemeKey);
  const menuIcon = getMenuIconStyle(hamburgerMenuIconColor);
  return (
    <ErrorBoundary>
      {/**
        // @ts-ignore */}
      <div tabIndex="0" on="tap:sidebar.open" role="button" className={css(menuIcon.wrapper)}>
        <Span styles={semanticCSS(menuIcon.span, menuIcon.firstSpanClose)} />
        <Span styles={semanticCSS(menuIcon.span, menuIcon.secondSpanClose)} />
        <Span styles={semanticCSS(menuIcon.span, menuIcon.thirdSpanClose)} />
      </div>
    </ErrorBoundary>
  );
};
