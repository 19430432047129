import React from 'react';
import { HorizontalCard } from '../../partials/cards/horizontalCard/HorizontalCard';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { RelatedPostsWithCard, RelatedPostsWithCardProps } from './RelatedPostsWithCard';

export const RelatedPosts: React.FunctionComponent<RelatedPostsWithCardProps> = props => {
  const RelatedPostsComponent = RelatedPostsWithCard(HorizontalCard);
  return (
    <ErrorBoundary>
      <RelatedPostsComponent {...props} />
    </ErrorBoundary>
  );
};
