import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { PrimaryHeader, PrimaryHeaderDataProps } from './primaryHeader/PrimaryHeader';
import { SecondaryHeader, SecondaryHeaderDataProps } from './secondaryHeader/SecondaryHeader';
import { AnimationDirection } from '../menuAnimations.util';
import { MontiAnchor } from '../MontiAnchor';
import { ErrorBoundary } from '../../../../errorHandling/ErrorBoundary';
import { getDoubleHeaderHeights } from './oldHeader.utils';
import { MEDIA_BREAKPOINTS } from '../../../../../mediaQueries.const';
import { HeaderAnimation, HeaderAnimationContext } from '../HeaderAnimation';
import { useTheme } from '../../../../../theming/useTheme';
import { useHeaderHeights } from '../useHeaderHeights';
import { componentConfigThemeKey, HeaderThemeProps } from '../Header.theme';

export interface HeaderLinksDataProps {
  href: string;
  text: string;
  children: HeaderLinksDataProps[];
}

export interface HeaderDataProps {
  primaryHeader: PrimaryHeaderDataProps;
  secondaryHeader: SecondaryHeaderDataProps | null;
  pagePath: string;
  editionEndpoint: string;
}

const getStyle = (animationDuration: number) => {
  return StyleSheet.create({
    header: {
      position: 'fixed',
      width: '100%',
      zIndex: 1500,
      top: 0,
    },
    foldingUp: {
      [MEDIA_BREAKPOINTS.medium]: {
        transition: `${animationDuration}ms`,
        transform: 'translateY(-100%)',
        transitionDelay: `${animationDuration}ms`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        transition: `${animationDuration}ms`,
        transform: 'translateY(-100%)',
        transitionDelay: `${animationDuration}ms`,
      },
    },
    foldingDown: {
      [MEDIA_BREAKPOINTS.medium]: {
        transform: 'translateY(0)',
      },
      [MEDIA_BREAKPOINTS.small]: {
        transform: 'translateY(0)',
      },
    },
  });
};

export const HeaderComponent: React.FunctionComponent<HeaderDataProps> = props => {
  const { menuFoldState, animationDuration } = React.useContext<HeaderAnimationContext>(HeaderAnimationContext);
  const { primaryHeader, secondaryHeader, pagePath, editionEndpoint } = props;
  const { links, logo, editions, cta, menu, videoLogo } = primaryHeader;

  const themeProps = useTheme<HeaderThemeProps>(componentConfigThemeKey);
  const firstRowHeights = getDoubleHeaderHeights(themeProps.firstRowHeightInLargeScreen, themeProps.firstRowHeightInMediumScreen, themeProps.firstRowHeightInSmallScreen);
  const firstRowLogoHeights = getDoubleHeaderHeights(themeProps.firstRowLogoHeightInLargeScreen, themeProps.firstRowLogoHeightInMediumScreen, themeProps.firstRowLogoHeightInSmallScreen);
  const secondRowHeights = getDoubleHeaderHeights(themeProps.secondRowHeightInLargeScreen, themeProps.secondRowHeightInMediumScreen, themeProps.secondRowHeightInSmallScreen);
  const secondRowLogoHeights = getDoubleHeaderHeights(themeProps.secondRowLogoHeightInLargeScreen, themeProps.secondRowLogoHeightInMediumScreen, themeProps.secondRowLogoHeightInSmallScreen);

  const generateSecondaryHeader = () => {
    if (!secondaryHeader) {
      return null;
    }
    return (
      <SecondaryHeader
        moreLinksText={secondaryHeader.moreLinksText}
        links={secondaryHeader.links}
        logo={secondaryHeader.logo}
        pagePath={pagePath}
        animation={menuFoldState}
        animationDuration={animationDuration}
        headerHeights={secondRowHeights}
        logoHeights={secondRowLogoHeights}
      />
    );
  };

  const getHeaderStyleWithAnimation = () => {
    const style = getStyle(animationDuration);

    if (menuFoldState === AnimationDirection.NONE) {
      return css(style.header);
    }

    const animationStyle = menuFoldState === AnimationDirection.UP ? style.foldingUp : style.foldingDown;
    return css(style.header, animationStyle);
  };

  return (
    <ErrorBoundary>
      <MontiAnchor isSecondaryHeaderEnabled={!!secondaryHeader} menuAnimation={menuFoldState} />
      <header className={getHeaderStyleWithAnimation()}>
        <PrimaryHeader {...{ links, logo, editions, cta, menu, videoLogo, pagePath, editionEndpoint, menuAnimation: menuFoldState, reduceHeightAnimationDuration: animationDuration, headerHeights: firstRowHeights, logoHeights: firstRowLogoHeights }} />
        {generateSecondaryHeader()}
      </header>
    </ErrorBoundary>
  );
};

export const OldHeader: React.FunctionComponent<HeaderDataProps> = props => {
  const headerHeights = useHeaderHeights();

  return (
    <ErrorBoundary>
      <HeaderAnimation headerHeights={headerHeights}>
        <HeaderComponent {...props} />
      </HeaderAnimation>
    </ErrorBoundary>
  );
};
