import * as React from 'react';
import { css } from 'aphrodite/no-important';
import { Helmet } from 'react-helmet';
import { AdditionalStyleSheet } from '../../components.utils';

interface AMPMMPlayerProps {
  additionalStyle: AdditionalStyleSheet;
}

const ampExperimentHtml = '<script type="application/json">{"player-experiment":{"variants":{"A": 33.3, "B": 33.3, "C": 33.3}}}</script>';

export const AMPMMPlayerABTest90minEN: React.FunctionComponent<AMPMMPlayerProps> = props => {
  const { additionalStyle } = props;
  return (
    <React.Fragment>
      <Helmet>
        <script async={undefined} custom-element="amp-experiment" src="https://cdn.ampproject.org/v0/amp-experiment-0.1.js" />
        <script async={undefined} custom-element="amp-minute-media-player" src="https://cdn.ampproject.org/v0/amp-minute-media-player-0.1.js" />
        <script async={undefined} custom-element="amp-video-docking" src="https://cdn.ampproject.org/v0/amp-video-docking-0.1.js" />
        <script async={undefined} custom-element="amp-video-iframe" src="https://cdn.ampproject.org/v0/amp-video-iframe-0.1.js" />
      </Helmet>
      {/*
         // @ts-ignore TS2339 */}
      <amp-experiment dangerouslySetInnerHTML={{ __html: ampExperimentHtml }} />
      <figure id="playerExperiment-A" className={css(additionalStyle.style)}>
        {/*
         // @ts-ignore TS2339 */}
        <amp-minute-media-player
          width="16"
          height="9"
          layout="responsive"
          data-content-type="specific"
          data-content-id="01dpjrns1fw8wr8xzr#01dpjrqbptnwzrrx0r"
        />
      </figure>
      <figure id="playerExperiment-B" className={css(additionalStyle.style)}>
        {/*
         // @ts-ignore TS2339 */}
        <amp-minute-media-player
          width="16"
          height="9"
          layout="responsive"
          data-content-type="specific"
          data-content-id="01dpjrns1fw8wr8xzr#01g2hcrs71gh9hmqd4"
        />
      </figure>
      <figure id="playerExperiment-C" className={css(additionalStyle.style)}>
        {/*
         // @ts-ignore TS2339 */}
        <amp-video-iframe
          src="https://vms-players.minutemediaservices.com/amp-video-iframe-content.html"
          width="16"
          height="9"
          layout="responsive"
          data-param-player-id="01g2hcsd76a9x6zjxv"
          data-param-content-id="01dpjrns1fw8wr8xzr"
          dock="#mm-dock-slot"
        />
        <div style={{ left: '1px', position: 'fixed', top: '101px' }}>
          {/*
         // @ts-ignore TS2339 */}
          <amp-layout width="213" height="120" id="mm-dock-slot" />
        </div>
      </figure>
    </React.Fragment>
  );
};
