import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { getLayoutComponentPadding, LayoutComponentBaseProps } from '../pageLayouts/layout.utils';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { useTheme } from '../../../theming/useTheme';
import {
  componentConfigThemeKey as onTopCardConfigThemeKey,
  OnTopCard,
  OnTopCardComponentThemeProps,
} from '../../partials/cards/onTopCard/OnTopCard';
import { createImageWidths } from '../../partials/image/image.utils';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import {
  componentConfigThemeKey as headlineCardConfigThemeKey,
  HeadlineCardThemeProps,
} from '../../partials/cards/headlineCard/HeadlineCard';
import { baseUnit } from '../../../theming/baseUnitDefinition';
import { FourHeadlineCards } from './FourHeadlineCards';

export const componentConfigThemeKey = 'twoOTCFourHLC';

interface TwoOTCFourHLCProps extends LayoutComponentBaseProps {
    cards: CardComponentDataProps[];
}

export interface TwoOTCFourHLCThemeProps {
    largeVerticalGap: number;
    largeHorizontalGap: number;
    mediumVerticalGap: number;
    mediumHorizontalGap: number;
    smallHorizontalGap: number;
}

const createStyle = (
  largeVerticalGap: number,
  largeHorizontalGap: number,
  mediumVerticalGap: number,
  mediumHorizontalGap: number,
  smallHorizontalGap: number,
) => {
  const firstOnTopCardArea = 'first_on_top_card_area';
  const secondOnTopCardArea = 'second_on_top_card_area';

  return StyleSheet.create({
    wrapper: {
      [MEDIA_BREAKPOINTS.large]: {
        display: 'grid',
        gridTemplateRows: '1fr auto',
        gridRowGap: `${largeHorizontalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        display: 'grid',
        gridTemplateRows: '1fr auto',
        gridRowGap: `${mediumHorizontalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        display: 'grid',
        gridTemplateRows: '1fr',
        gridRowGap: `${smallHorizontalGap * baseUnit}px`,
      },
    },
    onTopCardsWrapper: {
      [MEDIA_BREAKPOINTS.large]: {
        display: 'inline-grid',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: '1fr',
        gridColumnGap: `${largeVerticalGap * baseUnit}px`,
        gridTemplateAreas: `'${firstOnTopCardArea} ${secondOnTopCardArea}'`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        display: 'inline-grid',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: '1fr',
        gridColumnGap: `${mediumVerticalGap * baseUnit}px`,
        gridTemplateAreas: `'${firstOnTopCardArea} ${secondOnTopCardArea}'`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridRowGap: `${smallHorizontalGap * baseUnit}px`,
        gridTemplateAreas: `'${firstOnTopCardArea}' '${secondOnTopCardArea}'`,
      },
    },
    firstOnTopCard: {
      gridArea: firstOnTopCardArea,
    },
    secondOnTopCard: {
      gridArea: secondOnTopCardArea,
    },
    minorCard: {
      [MEDIA_BREAKPOINTS.small]: {
        paddingLeft: `${0.5 * baseUnit}px`,
        paddingRight: `${0.5 * baseUnit}px`,
      },
    },
  });
};

const imageWidthsForMainCard = createImageWidths(360, 360, 540);

export const TwoOTCFourHLC: React.FunctionComponent<TwoOTCFourHLCProps> = props => {
  const { cards, paddingFactors } = props;
  const paddingStyle = getLayoutComponentPadding(paddingFactors);
  const themeProps = useTheme<TwoOTCFourHLCThemeProps>(componentConfigThemeKey);
  const headlineCards = { cards: [cards[2], cards[3], cards[4], cards[5]] };
  const { largeVerticalGap,
    largeHorizontalGap,
    mediumVerticalGap,
    mediumHorizontalGap,
    smallHorizontalGap,
  } = themeProps;
  const styles = createStyle(largeVerticalGap, largeHorizontalGap, mediumVerticalGap, mediumHorizontalGap, smallHorizontalGap);
  const themeContextOnTopCards = useThemeOverride<OnTopCardComponentThemeProps>({
    aspectRatioSmall: { x: 4, y: 3 },
    titleFontSizeLarge: 'large',
    titleFontSizeMedium: 'bigger',
    titleFontSizeSmall: 'large',
    metadataFontSizeMedium: 'big',
    metadataFontSizeSmall: 'bigger',
    horizontalGapSmall: 0.5,
  }, onTopCardConfigThemeKey);
  const themeContextHeadlineCards = useThemeOverride<HeadlineCardThemeProps>({
    horizontalGapLarge: 0.5,
    horizontalGapMedium: 0,
    horizontalGapSmall: 0.25,
    titleFontSizeSmall: 'large',
    metadataFontSizeSmall: 'bigger',
  }, headlineCardConfigThemeKey);
  return (
    <ErrorBoundary>
      {cards && cards.length > 0
            && (
            <div className={css(paddingStyle, styles.wrapper)}>
              <ThemeContext.Provider value={themeContextOnTopCards}>
                <div className={css(styles.onTopCardsWrapper)}>
                  <div className={css(styles.firstOnTopCard)}>
                    <OnTopCard {...cards[0]} imageWidths={imageWidthsForMainCard} />
                  </div>
                  <div className={css(styles.secondOnTopCard)}>
                    <OnTopCard {...cards[1]} imageWidths={imageWidthsForMainCard} />
                  </div>
                </div>
              </ThemeContext.Provider>
              <ThemeContext.Provider value={themeContextHeadlineCards}>
                <FourHeadlineCards {...headlineCards} />
              </ThemeContext.Provider>
            </div>
            )}
    </ErrorBoundary>
  );
};
