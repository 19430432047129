import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { MEDIA_BREAKPOINTS } from '../../../../mediaQueries.const';

interface CameraIconProps {
  color: string;
}

const getStyles = (color: string) => StyleSheet.create({
  svg: {
    fill: color,
    [MEDIA_BREAKPOINTS.large]: {
      height: '15px',
    },
    [MEDIA_BREAKPOINTS.medium]: {
      height: '15px',
    },
    [MEDIA_BREAKPOINTS.small]: {
      height: '10px',
    },
  },
});

export const CameraIcon: React.FunctionComponent<CameraIconProps> = ({ color }) => {
  const styles = getStyles(color);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 26" className={css(styles.svg)}>
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <circle cx="16" cy="15" r="5" />
          <path d="M29.09,4.33H23.27L20.36,0H11.64L8.73,4.33H2.91A2.9,2.9,0,0,0,0,7.22V23.11A2.9,2.9,0,0,0,2.91,26H29.09A2.9,2.9,0,0,0,32,23.11V7.22A2.9,2.9,0,0,0,29.09,4.33ZM16,22a7,7,0,1,1,7-7A7,7,0,0,1,16,22Z" />
        </g>
      </g>
    </svg>
  );
};
