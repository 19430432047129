import * as React from 'react';
import { StyleSheet } from 'aphrodite/no-important';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { Jw } from './Jw';
import { AdditionalStyleSheet, Style } from '../../components.utils';
import { BlockWidths } from '../../components/pageLayouts/BlockTypes.utils';
import { createBlockStyle } from '../blockMutualStyle';
import { useTheme } from '../../../theming/useTheme';
import { ArticleBlockThemeProps, componentConfigurationKey } from '../ArticleBlock.theme';

interface JwDataProps {
  html: string;
  siteName: string;
  templateName: string;
  videoId: string;
  dataId?: string;
  defaultBlocksWidths: BlockWidths;
}

type ArticleJwProps = JwDataProps & AdditionalStyleSheet;

const createStyle = (defaultBlocksWidths: BlockWidths, style: Style, backgroundColor: string) => {
  return StyleSheet.create({
    style: {
      ...style,
      ...createBlockStyle(defaultBlocksWidths),
      backgroundColor,
    },
  }) as AdditionalStyleSheet;
};

export const ArticleJw: React.FunctionComponent<ArticleJwProps> = props => {
  const { style, defaultBlocksWidths } = props;
  const { placeholderBackgroundColor } = useTheme<ArticleBlockThemeProps>(componentConfigurationKey);
  const additionalStyle = createStyle(defaultBlocksWidths, style, placeholderBackgroundColor);
  return (
    <ErrorBoundary>
      <Jw {...props} additionalStyle={additionalStyle} />
    </ErrorBoundary>
  );
};
