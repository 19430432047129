import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useTheme } from '../../../theming/useTheme';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { BlockWidths } from '../../components/pageLayouts/BlockTypes.utils';
import { MandatoryAdditionalStyle, Style } from '../../components.utils';
import { createBlockStyle } from '../blockMutualStyle';
import { ArticleBlockThemeProps, componentConfigurationKey } from '../ArticleBlock.theme';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';

interface ArticleDividerDataProps {
  dataId?: string;
  defaultBlocksWidths: BlockWidths;
  iconComponent: React.FunctionComponent;
}

type DividerComponentProps = MandatoryAdditionalStyle & ArticleDividerDataProps;

const getStyles = (baseUnit: BaseUnit, lineColor: string, defaultBlocksWidths: BlockWidths) => {
  return StyleSheet.create({
    wrapper: {
      ...createBlockStyle(defaultBlocksWidths),
      display: 'flex',
      alignItems: 'center',
    },
    line: {
      height: '1px',
      width: '100%',
      border: 0,
      margin: 0,
      backgroundColor: lineColor,
    },
  });
};

export const ArticleDividerWithIcon: React.FunctionComponent<DividerComponentProps> = props => {
  const { baseUnit, dividerLineColor, dividerShowIcon } = useTheme<ArticleBlockThemeProps>(componentConfigurationKey);
  const { defaultBlocksWidths, additionalStyle, iconComponent, dataId } = props;
  const styles = getStyles(baseUnit, dividerLineColor, defaultBlocksWidths);
  const IconComponent = iconComponent;
  return (
    <ErrorBoundary>
      <div className={css(styles.wrapper, additionalStyle.style as Style)} data-mm-id={dataId}>
        {dividerShowIcon ? (
          <React.Fragment>
            <hr className={css(styles.line)} />
            <IconComponent />
            <hr className={css(styles.line)} />
          </React.Fragment>
        )
          : <hr className={css(styles.line, additionalStyle.style as Style)} />}
      </div>
    </ErrorBoundary>
  );
};
