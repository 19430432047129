import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { SocialButton } from '../articleComponents/ShareMenu/ShareMenu';
import { BaseUnit } from '../../theming/baseUnitDefinition';

type AMPSocialButtonProps = SocialButton & {
  baseUnit: BaseUnit;
};

const getStyle = (baseUnit: BaseUnit, type: string, src: string) => {
  const style: { [key: string]: any } = {
    backgroundSize: '32px',
    borderRadius: '100%',
    marginRight: `${baseUnit * 0.5}px`,
    ...(type === 'reddit' && {
      backgroundColor: '#ff4500',
      backgroundImage: `url("${src}")`,
    }),
  };

  return StyleSheet.create({
    style,
  });
};

const preconfiguredProviders = ['twitter', 'facebook', 'email', 'linkedin', 'pinterest', 'tumblr', 'whatsapp', 'reddit'];

const isNotPreconfiguredProvider = (type: string) => preconfiguredProviders.indexOf(type) >= 0;

export const AMPSocialButton: React.FunctionComponent<AMPSocialButtonProps> = props => {
  const { type, shareURL, src, baseUnit } = props;
  const socialShareProps = {
    type,
    ...(isNotPreconfiguredProvider(type) && { 'data-share-endpoint': shareURL }),
  };

  const styles = getStyle(baseUnit, type, src);

  // @ts-ignore TS2339
  return <amp-social-share {...socialShareProps} class={css(styles.style)} width="42" height="42" />;
};
