import React from 'react';

export const ChevronThinIcon = ({ breadCrumbsTextColor }: { breadCrumbsTextColor: string }) => {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M6.47615 4.93164L2.14282 0.598313L2.8095 -0.0683594L7.8095 4.93164L2.8095 9.93164L2.14282 9.26497L6.47615 4.93164Z"
          fill={breadCrumbsTextColor}
        />
      </g>
    </svg>
  );
};
