import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { HorizontalCard } from '../../partials/cards/horizontalCard/HorizontalCard';
import {
  componentConfigThemeKey as horizontalCardConfigThemeKey,
  HorizontalCardThemeProps,
} from '../../partials/cards/horizontalCard/horizontalCard.theme';
import { VerticalCard } from '../../partials/cards/verticalCard/VerticalCard';
import { getLayoutComponentPadding, LayoutComponentBaseProps } from '../pageLayouts/layout.utils';
import { getArticlesInChunks } from './articleLists.utils';
import { useTheme } from '../../../theming/useTheme';
import { baseUnit } from '../../../theming/baseUnitDefinition';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { createImageWidths } from '../../partials/image/image.utils';
import { VerticalCardThemeProps, componentConfigThemeKey as verticalCardConfigThemeKey,
} from '../../partials/cards/verticalCard/VerticalCard.theme';

export const componentConfigThemeKey = 'threeVCThreeHCThreeVCThreeHC';

export interface ThreeVCThreeHCThreeVCThreeHCThemeProps {
  largeHorizontalGap: number;
  largeVerticalGap: number;
  mediumHorizontalGap: number;
  smallHorizontalGap: number;
  showMainCategoryInLargeScreen: boolean;
  showMainCategoryInMediumScreen: boolean;
  showMainCategoryInSmallScreen: boolean;
}

const createStyle = (themeProps: ThreeVCThreeHCThreeVCThreeHCThemeProps) => {
  const { largeHorizontalGap, largeVerticalGap, mediumHorizontalGap, smallHorizontalGap } = themeProps;
  return StyleSheet.create({
    wrapper: {
      display: 'grid',
    },
    verticalCardsGrid: {
      display: 'grid',
      [MEDIA_BREAKPOINTS.large]: {
        gridTemplateColumns: '1fr 1fr 1fr',
        gridColumnGap: `${largeVerticalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        gridTemplateColumns: '1fr',
        gridRowGap: `${mediumHorizontalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        gridTemplateColumns: '1fr',
        gridRowGap: `${smallHorizontalGap * baseUnit}px`,
      },
      marginTop: `${baseUnit}px`,
      marginBottom: `${baseUnit}px`,
    },
    horizontalCardsGrid: {
      display: 'grid',
      [MEDIA_BREAKPOINTS.large]: {
        gridTemplateColumns: '1fr',
        gridRowGap: `${largeHorizontalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        gridTemplateColumns: '1fr',
        gridRowGap: `${mediumHorizontalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        gridTemplateColumns: '1fr',
        gridRowGap: `${smallHorizontalGap * baseUnit}px`,
      },
    },
  });
};

export interface ThreeVCThreeHCThreeVCThreeHCProps extends LayoutComponentBaseProps {
  cards: CardComponentDataProps[] | null;
}

export const ThreeVCThreeHCThreeVCThreeHC: React.FunctionComponent<ThreeVCThreeHCThreeVCThreeHCProps> = props => {
  const { cards, paddingFactors } = props;

  const paddingStyle = getLayoutComponentPadding(paddingFactors);
  const themeProps = useTheme<ThreeVCThreeHCThreeVCThreeHCThemeProps>(componentConfigThemeKey);
  const { showMainCategoryInLargeScreen, showMainCategoryInMediumScreen, showMainCategoryInSmallScreen } = themeProps;
  const style = createStyle(themeProps);

  const themeContextVC = useThemeOverride<VerticalCardThemeProps>({
    titleFontSizeLarge: 'large',
    titleFontSizeMedium: 'large',
    titleFontSizeSmall: 'large',
    metadataFontSizeLarge: 'bigger',
    metadataFontSizeMedium: 'bigger',
    metadataFontSizeSmall: 'bigger',
    mainCategoryFontSizeLarge: 'bigger',
    mainCategoryFontSizeMedium: 'bigger',
    mainCategoryFontSizeSmall: 'big',
    verticalGapLarge: 1,
    verticalGapMedium: 1,
    verticalGapSmall: 0.5,
    horizontalGapLarge: 1,
    horizontalGapMedium: 1,
    horizontalGapSmall: 0.5,
    titleAlignment: 'CENTER',
    descriptionAlignment: 'CENTER',
    metadataAlignment: 'CENTER',
    mainCategoryAlignment: 'START',
    showMainCategoryInLargeScreen,
    showMainCategoryInMediumScreen,
    showMainCategoryInSmallScreen,
  }, verticalCardConfigThemeKey);

  const themeContextHC = useThemeOverride<HorizontalCardThemeProps>({
    titleFontSizeLarge: 'large',
    titleFontSizeMedium: 'large',
    titleFontSizeSmall: 'large',
    metadataFontSizeLarge: 'bigger',
    metadataFontSizeMedium: 'bigger',
    metadataFontSizeSmall: 'bigger',
    mainCategoryFontSizeLarge: 'bigger',
    mainCategoryFontSizeMedium: 'bigger',
    mainCategoryFontSizeSmall: 'big',
    verticalGapLarge: 1,
    verticalGapMedium: 1,
    verticalGapSmall: 0.5,
    horizontalGapLarge: 1,
    horizontalGapMedium: 1,
    horizontalGapSmall: 0.5,
    cardProportionsLarge: { x: 1, y: 1 },
    showMainCategoryInLargeScreen,
    showMainCategoryInMediumScreen,
    showMainCategoryInSmallScreen,
  }, horizontalCardConfigThemeKey);

  const imagesWidthsVC = createImageWidths(360, 360, 360);
  const imagesWidthsHC = createImageWidths(180, 360, 540);

  const createGrids = (cardsProps: CardComponentDataProps[], index: number) => {
    return (index % 2 === 0
      ? (
        <div className={css(paddingStyle, style.verticalCardsGrid)} key={index}>
          <ThemeContext.Provider value={themeContextVC}>
            {cardsProps.map(((card, cardIndex) => (
              <VerticalCard key={cardIndex} {...card} imageWidths={imagesWidthsVC} />)))}
          </ThemeContext.Provider>
        </div>
      )
      : (
        <div className={css(paddingStyle, style.horizontalCardsGrid)} key={index}>
          <ThemeContext.Provider value={themeContextHC}>
            {cardsProps.map(((card, cardIndex) => (
              <HorizontalCard key={cardIndex} {...card} imageWidths={imagesWidthsHC} />)))}
          </ThemeContext.Provider>
        </div>
      )
    );
  };
  return (
    <ErrorBoundary>
      {cards && cards.length > 0 && getArticlesInChunks(cards, 3).map(createGrids)}
    </ErrorBoundary>
  );
};
