import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { useTheme } from '../../../theming/useTheme';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { createImageWidths } from '../../partials/image/image.utils';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { getLayoutComponentPadding, LayoutComponentBaseProps } from '../pageLayouts/layout.utils';
import { OnTopCard, OnTopCardComponentThemeProps, componentConfigThemeKey as onTopCardConfigThemeKey } from '../../partials/cards/onTopCard/OnTopCard';

export const componentConfigThemeKey = 'twoTwoOTC';

export interface TwoTwoOTCThemeProps {
    largeVerticalGap: number;
    largeHorizontalGap: number;
    mediumVerticalGap: number;
    mediumHorizontalGap: number;
    smallHorizontalGap: number;
    baseUnit: BaseUnit;
}

interface TwoTwoOTCDataProps extends LayoutComponentBaseProps{
    cards: Array<CardComponentDataProps> | null;
}

const createStyle = (themeProps: TwoTwoOTCThemeProps) => {
  const { largeHorizontalGap, largeVerticalGap, mediumHorizontalGap, mediumVerticalGap, smallHorizontalGap, baseUnit } = themeProps;
  return StyleSheet.create({
    wrapper: {
      display: 'grid',
      [MEDIA_BREAKPOINTS.large]: {
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: 'auto',
        gridRowGap: `${largeHorizontalGap * baseUnit}px`,
        gridColumnGap: `${largeVerticalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: 'auto',
        gridRowGap: `${mediumHorizontalGap * baseUnit}px`,
        gridColumnGap: `${mediumVerticalGap * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto',
        gridRowGap: `${smallHorizontalGap * baseUnit}px`,
      },
    },
  });
};

const imageWidths = createImageWidths(360, 360, 540);

export const TwoTwoOTC: React.FunctionComponent<TwoTwoOTCDataProps> = props => {
  const { cards, paddingFactors } = props;
  const paddingStyle = getLayoutComponentPadding(paddingFactors);
  const themeProps = useTheme<TwoTwoOTCThemeProps>(componentConfigThemeKey);
  const style = createStyle(themeProps);
  const themeContextOTC = useThemeOverride<OnTopCardComponentThemeProps>({
    titleFontSizeLarge: 'large',
    titleFontSizeMedium: 'bigger',
    titleFontSizeSmall: 'bigger',
    metadataFontSizeLarge: 'big',
    metadataFontSizeMedium: 'big',
    metadataFontSizeSmall: 'big',
    verticalGapLarge: 1,
    verticalGapMedium: 1,
    verticalGapSmall: 0.75,
    horizontalGapLarge: 0.75,
    horizontalGapMedium: 0.75,
    horizontalGapSmall: 0.5,
  }, onTopCardConfigThemeKey);
  return (
    <ErrorBoundary>
      {cards && cards.length > 0
            && (
            <div className={css(paddingStyle, style.wrapper)}>
              <ThemeContext.Provider value={themeContextOTC}>
                <OnTopCard {...cards[0]} imageWidths={imageWidths} />
                <OnTopCard {...cards[1]} imageWidths={imageWidths} />
                <OnTopCard {...cards[2]} imageWidths={imageWidths} />
                <OnTopCard {...cards[3]} imageWidths={imageWidths} />
              </ThemeContext.Provider>
            </div>
            ) }
    </ErrorBoundary>
  );
};
