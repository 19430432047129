import * as React from 'react';
import { SectionTitleFontSizeProps } from './SectionTitle.utils';
import { useTheme } from '../../../theming/useTheme';
import { H1 } from '../../../typography/semanticTags';

const componentConfigThemeKey = 'primarySectionTitle';

export const PrimarySectionTitle: React.FunctionComponent = props => {
  const { children } = props;
  const theme = useTheme<SectionTitleFontSizeProps>(componentConfigThemeKey);

  return (
    <H1 {...theme}>
      {children}
    </H1>
  );
};
