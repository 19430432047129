import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Colors } from 'mm-theme-configuration';
import { TYPOGRAPHY_SIZE, TypographySize } from 'mm-theme-configuration/dist/consts';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { useTheme } from '../../../theming/useTheme';
import { semanticCSS, Span } from '../../../typography/semanticTags';
import { HorizontalCard } from '../../partials/cards/horizontalCard/HorizontalCard';
import { createImageWidths } from '../../partials/image/image.utils';
import { HorizontalCardThemeProps, componentConfigThemeKey as horizontalCardConfigThemeKey } from '../../partials/cards/horizontalCard/horizontalCard.theme';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';

export const componentConfigThemeKey = 'threeHCDynamicList';

export interface ThreeHCDynamicListProps {
  cards: CardComponentDataProps[];
  image?: {
    src: string;
    alt: string;
  };
  title?: string;
  useFormatMinutesHoursAgo?: boolean;
}

export interface ThreeHCDynamicListThemeProps {
  headlineHasText: boolean;
  headlineHasLogo: boolean;
  headlineTextSize: TypographySize;
  headlineTextColor: string;
  titleColor: string;
  authorColor: string;
  backgroundColor: string;
}

const createStyle = (backgroundColor: string, headlineTextColor: string) => {
  return StyleSheet.create({
    container: {
      backgroundColor,
      padding: '15px',
    },
    headline: {
      display: 'flex',
      width: '100%',
      marginBottom: '20px',
      gap: '10px',
      alignItems: 'center',
    },
    cardsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      [MEDIA_BREAKPOINTS.large]: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: '20px 25px',
      },
    },
    title: {
      color: headlineTextColor,
    },
  });
};

export const ThreeHCDynamicList = ({ cards, image, title, useFormatMinutesHoursAgo }: ThreeHCDynamicListProps) => {
  const themeProps = useTheme<ThreeHCDynamicListThemeProps>(componentConfigThemeKey);
  const { colors }: { colors: Colors } = useTheme();
  const styles = createStyle(themeProps.backgroundColor, themeProps.headlineTextColor);
  const themeData: Partial<HorizontalCardThemeProps> = {
    aspectRatioLarge: { x: 16, y: 9 },
    aspectRatioMedium: { x: 16, y: 9 },
    aspectRatioSmall: { x: 16, y: 9 },
    titleFontSizeLarge: TYPOGRAPHY_SIZE.BIG,
    titleFontSizeMedium: TYPOGRAPHY_SIZE.BIG,
    titleFontSizeSmall: TYPOGRAPHY_SIZE.BIG,
    cardTitleColorTypeActive: themeProps.titleColor,
    cardTitleColorTypeHover: colors.primary,
    cardTitleColorTypeNormal: themeProps.titleColor,
    showAuthorInLargeScreen: true,
    showAuthorInMediumScreen: true,
    showAuthorInSmallScreen: true,
    showMainCategoryInLargeScreen: false,
    showMainCategoryInMediumScreen: false,
    showMainCategoryInSmallScreen: false,
    showDateInLargeScreen: true,
    showDateInMediumScreen: true,
    showDateInSmallScreen: true,
    showDescriptionInLargeScreen: false,
    showDescriptionInMediumScreen: false,
    showDescriptionInSmallScreen: false,
    authorColor: themeProps.authorColor,
    cardBackgroundColor: themeProps.backgroundColor,
    numberOfLinesForTitle: 4,
    numberOfLinesForAuthors: 2,
    mainCategoryBackgroundColor: themeProps.backgroundColor,
    showImageHeightByAspectRatio: true,
  };
  const horizontalCardThemeContext = useThemeOverride<HorizontalCardThemeProps>(themeData, horizontalCardConfigThemeKey);
  const imageWidths = createImageWidths(180, 360, 540);

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.headline)}>
        {themeProps.headlineHasText && title
          && (
            <Span
              styles={semanticCSS(styles.title)}
              fontSizeSmall={themeProps.headlineTextSize}
              fontSizeMedium={themeProps.headlineTextSize}
              fontSizeLarge={themeProps.headlineTextSize}
            >
              {title}
            </Span>
          )}
        {themeProps.headlineHasLogo && image?.src
          && <img height={32} src={`data:image/svg+xml,${encodeURIComponent(image.src)}`} alt={image?.alt || 'logo'} />}
      </div>

      <div className={css(styles.cardsWrapper)}>
        <ThemeContext.Provider value={horizontalCardThemeContext}>
          {cards.map((card, index) => {
            return (
              <HorizontalCard {...card} key={index} imageWidths={imageWidths} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />
            );
          })}
        </ThemeContext.Provider>
      </div>
    </div>
  );
};
