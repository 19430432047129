let userId: string | null;

const generateUUID = () => {
  let base = Date.now();
  if (typeof window !== 'undefined' && window.performance) {
    base += window.performance.now();
  }
  return 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'.replace(/[xy]/g, () => {
    /* eslint-disable-next-line no-bitwise */
    const r = (base + Math.random() * 10) % 10 | 0;
    base = Math.floor(base / 10);
    return r.toString(10);
  });
};

export const getIdentifier = () => {
  try {
    if (window.localStorage) {
      userId = window.localStorage.getItem('userIdentifier');
      if (!userId) {
        userId = generateUUID();
        window.localStorage.setItem('userIdentifier', userId);
      }
    }
    // eslint-disable-next-line no-empty
  } catch (e) { }

  userId = userId || generateUUID();
  return userId;
};
