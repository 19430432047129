import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { FooterLinksSection, FooterLinksSectionBaseProps } from './FooterLinksSection';
import { useTheme } from '../../../../../theming/useTheme';
import { generateSeparatorSections } from '../footer.utils';
import { ErrorBoundary } from '../../../../errorHandling/ErrorBoundary';
import { BottomFooterBar, BottomFooterBarDataProps } from '../BottomFooterBar';
import { HUGE_SCREEN_SIZE, MEDIA_BREAKPOINTS } from '../../../../../mediaQueries.const';
import { BaseUnit } from '../../../../../theming/baseUnitDefinition';
import { componentConfigurationKey, FooterThemeProps } from '../Footer.theme';

const SEPARATOR_HEIGHT = 1;

interface FooterDataProps {
  linksSection: FooterLinksSectionBaseProps | null;
  bottomFooterBar: BottomFooterBarDataProps;
}

const getFooterStyle = (baseUnit: BaseUnit, borderTopColor: string, backgroundColor: string) => {
  return StyleSheet.create({
    footer: {
      borderTop: `solid 2px ${borderTopColor}`,
      [MEDIA_BREAKPOINTS.large]: {
        padding: `0 ${0.25 * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        padding: `0 ${0.25 * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        padding: `0 ${baseUnit * 0.75}px`,
      },
      margin: '0 auto',
      maxWidth: `${HUGE_SCREEN_SIZE}px`,
    },
    footerWrapper: {
      backgroundColor,
    },
    footerContent: {
      margin: 'auto',
      [MEDIA_BREAKPOINTS.large]: {
        maxWidth: '852px',
      },
    },
  });
};

const getSeparatorStyle = (separatorColor: string, separatorHeight: number) => {
  return StyleSheet.create({
    style: {
      height: `${separatorHeight}px`,
      backgroundColor: separatorColor,
    },
  });
};

const getLinksSection = (linksSection: FooterLinksSectionBaseProps | null) => {
  return linksSection ? <FooterLinksSection key="footer-links" {...linksSection as FooterLinksSectionBaseProps} /> : null;
};

const getFooterItems = (linksSection: FooterLinksSectionBaseProps | null, separatorColor: string, separatorHeight: number, bottomFooterBar: BottomFooterBarDataProps) => {
  return [
    getLinksSection(linksSection),
    <BottomFooterBar key="bottom-bar" {...bottomFooterBar} />,
  ]
    .filter(section => section !== null)
    .reduce(generateSeparatorSections(getSeparatorStyle(separatorColor, separatorHeight).style), []);
};

export const AmpFooter: React.FunctionComponent<FooterDataProps> = props => {
  const { backgroundColor, ampSeparatorColor, baseUnit } = useTheme<FooterThemeProps>(componentConfigurationKey);
  const footerComponentStyle = getFooterStyle(baseUnit, ampSeparatorColor, backgroundColor);
  const { linksSection, bottomFooterBar } = props;
  return (
    <ErrorBoundary>
      <footer>
        <div className={css(footerComponentStyle.footerWrapper)}>
          <div className={css(footerComponentStyle.footer)}>
            <div className={css(footerComponentStyle.footerContent)}>
              {getFooterItems(linksSection, ampSeparatorColor, SEPARATOR_HEIGHT, bottomFooterBar)}
            </div>
          </div>
        </div>
      </footer>
    </ErrorBoundary>
  );
};
