import { Dispatch } from 'redux';
import { fetchMoreArticles } from '../../../services/pilotServiceApiProvider';
import {
  getEditionEndpoint,
  getImagesCdnHost,
  getLocale,
  getMidnightInTimeZone,
  getTimeZone,
} from '../../config/config.selectors';
import { getRecommendationsNextByKey } from './postPageAmsterdam/postPageAmsterdam.selectors';
import { RecommendationNames } from './postPage.reducer';
import { AppState, PostPageAmsterdamAppState } from '../../initialStateRegistration';
import { receivePostsSuccessfully, receivePostsUnsuccessfully, requestPosts } from '../loadMore.actions';
import { getSafeObject } from '../../storeFromRequestParamsValidation';
import { createFiniteScrollArticleData } from './utils/createFiniteScrollArticleData';
import {
    InjectArticleBlockByIndexArrayParams,
    InjectArticleBlockByIndexParams,
    PostPageDataType, PostsResponse,
} from './types';


export const INJECT_ARTICLE_BLOCK_BY_INDEX_ARRAY = 'inject article block by index array';
export const RECEIVED_FINITE_SCROLL_ARTICLES_SUCCESSFULLY = 'RECEIVED_FINITE_SCROLL_ARTICLES_SUCCESSFULLY';
export const RECEIVED_FINITE_SCROLL_ARTICLES_UNSUCCESSFULLY = 'RECEIVED_FINITE_SCROLL_ARTICLES_UNSUCCESSFULLY';
export const FINITE_SCROLL_ARTICLES_PENDING = 'FINITE_SCROLL_ARTICLES_PENDING';

export const fetchNextPosts = (key: RecommendationNames) => {
  return (dispatch: Dispatch, getState: () => PostPageAmsterdamAppState) => {
    dispatch(requestPosts());
    try {
      const state = getState();
      const endpoint = getRecommendationsNextByKey(key)(state);
      const imageCDNHost = getImagesCdnHost(state);
      const locale = getLocale(state);
      const timeZone = getTimeZone(state);
      const midnightInTimeZone = getMidnightInTimeZone(state);
      const editionEndpoint = getEditionEndpoint(state);
      return fetchMoreArticles(endpoint)
        .then(json => dispatch(receivePostsSuccessfully(json, key, imageCDNHost, midnightInTimeZone, timeZone, locale, editionEndpoint)))
        .catch(error => dispatch(receivePostsUnsuccessfully(error)));
    } catch (e) {
      dispatch(receivePostsUnsuccessfully(e));
      return null;
    }
  };
};


export const receiveFiniteScrollArticlesSuccessfully = (response: PostsResponse, imageCDNHost: string, midnightInTimeZone: string, timeZone: string, locale: string, editionEndpoint: string, postPageData: PostPageDataType, experiment: string) => {
    return {
        type: RECEIVED_FINITE_SCROLL_ARTICLES_SUCCESSFULLY,
        articles: createFiniteScrollArticleData(response.articles[0], imageCDNHost, midnightInTimeZone, timeZone, locale, editionEndpoint, postPageData, experiment),
    };
};


export const receiveFiniteScrollArticlesUnsuccessfully = (error: string) => {
    return {
        type: RECEIVED_FINITE_SCROLL_ARTICLES_UNSUCCESSFULLY,
        error,
    };
};

export const finiteScrollArticlesPending = () => {
    return {
        type: FINITE_SCROLL_ARTICLES_PENDING,
    };
};


export function fetchFiniteScrollArticles<T extends AppState>(endpoint: string | null, postPageData: PostPageDataType, experiment: string) {
    return (dispatch: Dispatch, getState: () => T) => {
        try {
            const state = getState();
            const imageCDNHost = getImagesCdnHost(state);
            const locale = getLocale(state);
            const timeZone = getTimeZone(state);
            const midnightInTimeZone = getMidnightInTimeZone(state);
            const editionEndpoint = getEditionEndpoint(state);
            if (endpoint) {
                dispatch(finiteScrollArticlesPending());
                return fetchMoreArticles(endpoint)
                    .then((json: PostsResponse) => dispatch(receiveFiniteScrollArticlesSuccessfully(getSafeObject(json), imageCDNHost, midnightInTimeZone, timeZone, locale, editionEndpoint, postPageData, experiment)))
                    .catch((error: string) => dispatch(receiveFiniteScrollArticlesUnsuccessfully(error)));
            }
            return null;
        } catch (e) {
            dispatch(receiveFiniteScrollArticlesUnsuccessfully(e));
            return null;
        }
    };
}

const createInjectedBlock = ({ index, html, dataId, script }: InjectArticleBlockByIndexParams) => ({
    index,
    block: {
        type: 'injectedBlock',
        html,
        dataId,
        script,
    },
});

export const injectArticleBlockByIndexArray = (params: InjectArticleBlockByIndexArrayParams) => {
    return {
        type: INJECT_ARTICLE_BLOCK_BY_INDEX_ARRAY,
        blocks: params.map(createInjectedBlock),
    };
};
