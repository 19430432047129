import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useTheme } from '../../../../../../theming/useTheme';
import { MoreLinks } from './MoreLinks';
import { MEDIA_BREAKPOINTS } from '../../../../../../mediaQueries.const';
import { BaseUnit } from '../../../../../../theming/baseUnitDefinition';
import { SecondaryLink } from './SecondaryLink';
import { NavigationLink } from '../primaryHeader/PrimaryHeader';
import { HeaderThemeProps } from '../../Header.theme';

export interface SecondaryLinksLogicProps {
  maxVisibleLinks?: number;
}

interface SecondaryLinksDataProps {
  moreLinksText: string;
  links: NavigationLink[];
  pagePath: string;
}

export type SecondaryLinksProps = SecondaryLinksDataProps & SecondaryLinksLogicProps;

const getContainerStyle = (baseUnit: BaseUnit) => {
  return StyleSheet.create({
    container: {
      display: 'grid',
      gridAutoFlow: 'column',
      alignItems: 'center',
      height: '100%',
      gridColumnGap: `${baseUnit}px`,
      [MEDIA_BREAKPOINTS.medium]: {
        overflowX: 'auto',
      },
      [MEDIA_BREAKPOINTS.small]: {
        overflowX: 'auto',
      },
    },
    dropDownMenuWrapper: {
      height: '100%',
      [MEDIA_BREAKPOINTS.large]: {
        display: 'block',
      },
      [MEDIA_BREAKPOINTS.small]: {
        display: 'none',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        display: 'none',
      },
    },
  });
};

export const isLastLink = (index: number, linksLength: number) => index + 1 === linksLength;

export const SecondaryLinks: React.FunctionComponent<SecondaryLinksProps> = props => {
  const { links, moreLinksText, maxVisibleLinks = 6, pagePath } = props;
  const { baseUnit } = useTheme<HeaderThemeProps>();
  const [isHoveredLink, setIsHoveredLink] = React.useState(false);
  const style = getContainerStyle(baseUnit);
  const moreLinks = links.length > maxVisibleLinks ? links.slice(maxVisibleLinks - 1) : [];

  return (
    <div className={css(style.container)}>
      <div className={css(style.container)} onMouseEnter={() => setIsHoveredLink(true)} onMouseLeave={() => setIsHoveredLink(false)}>
        {links.map((linkData, index) => (
          <SecondaryLink
            key={index}
            linkData={linkData}
            index={index}
            linksLength={links.length}
            isHoveredLink={isHoveredLink}
            pagePath={pagePath}
            maxVisibleLinks={maxVisibleLinks}
          />
        ))}
      </div>
      <div className={css(style.dropDownMenuWrapper)}>
        {moreLinks.length > 0 && <MoreLinks moreLinksText={moreLinksText} links={moreLinks} pagePath={pagePath} />}
      </div>
    </div>
  );
};
