import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { TypographySize } from 'mm-theme-configuration/dist/consts';
import { semanticCSS } from '../../typography/semanticTags/semanticTags.utils';
import { useTheme } from '../../theming/useTheme';
import { TranslatedFontSize } from '../../theming/fontSizeTranslator';
import { BaseUnit } from '../../theming/baseUnitDefinition';
import { extractWrapperAndContentFromHtml } from '../components.utils';
import { Div } from '../../typography/semanticTags/Div';


const componentConfigThemeKey = 'intro';
interface IntroComponentProps {
    html: string;
    styles?: ReturnType<typeof semanticCSS>;
}

export interface IntroComponentThemeProps {
  introTextColor: string;
  introTextSizeInSmallScreen: TypographySize;
  introTextSizeMediumScreen: TypographySize;
  introTextSizeInLargeScreen: TypographySize;
}

const getStyle = (textColor: string, baseUnit: BaseUnit) => {
  return (element: string) => {
    switch (element) {
      case 'ul': return StyleSheet.create({
        style: {
          padding: 0,
          color: textColor,
        },
      }).style;
      case 'li': return StyleSheet.create({
        style: {
          marginLeft: baseUnit,
          color: textColor,
        },
      }).style;
      case 'p': return StyleSheet.create({
        style: {
          color: textColor,
        },
      }).style;
      default: return null;
    }
  };
};

const applyCssClasses = (html: string, textColor: string, fontSizesStyles: TranslatedFontSize, baseUnit: BaseUnit): string => {
  const style = getStyle(textColor, baseUnit);
  return html
    .replace(/<ul/g, `<ul class="${css(style('ul'))}"`)
    .replace(/<li/g, `<li class="${css(style('li'))}"`);
};

export const Intro: React.FunctionComponent<IntroComponentProps> = props => {
  const { html, styles = [] } = props;
  const { fontSizesStyles, baseUnit } = useTheme();
  const { introTextColor, introTextSizeInLargeScreen, introTextSizeInSmallScreen,
    introTextSizeMediumScreen } = useTheme<IntroComponentThemeProps>(componentConfigThemeKey);
  const { wrapper: Wrapper, content } = extractWrapperAndContentFromHtml(html, false);
  const additionalStyle = StyleSheet.create({
    style: {
      margin: `${baseUnit * 0.5}px ${baseUnit}px`,
      color: introTextColor,
    },
  });

  return (
    <Div
      fontSizeSmall={introTextSizeInSmallScreen}
      fontSizeMedium={introTextSizeMediumScreen}
      fontSizeLarge={introTextSizeInLargeScreen}
      styles={semanticCSS(additionalStyle.style, styles)}
    >
      <Wrapper
        {...{
          dangerouslySetInnerHTML: { __html: applyCssClasses(content, introTextColor, fontSizesStyles, baseUnit) },
          key: 'intro',
        }}
      />
    </Div>
  );
};
