import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { HorizontalCard } from '../../partials/cards/horizontalCard/HorizontalCard';
import { createImageWidths } from '../../partials/image/image.utils';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';

interface SearchPageFeedProps {
    articles?: Array<CardComponentDataProps> | null;
}

export const createStyles = () => {
  return StyleSheet.create({
    cardWrapper: {
      paddingBottom: '10px',
    },
  });
};

const imageWidths = createImageWidths(110, 180, 360);

export const SearchPageFeed: React.FunctionComponent<SearchPageFeedProps> = props => {
  const { articles } = props;
  const styles = createStyles();

  return (
    <ErrorBoundary>
      {articles && articles.map(article => (
        <div className={css(styles.cardWrapper)}>
          <HorizontalCard key={article.id} {...article} imageWidths={imageWidths} />
        </div>
      ))}
    </ErrorBoundary>
  );
};
