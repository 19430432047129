import React from 'react';
import { Provider } from 'react-redux';
import { ErrorReporting } from 'mm-ui-components';
import { Store } from 'redux';
import { ErrorReporter } from '../errorReporting/errorReporter.type';
import { AppActions, AppState } from '../store/initialStateRegistration';
import { CoreWebVitals } from '../components/coreWebVitals/CoreWebVitals';
import { Kasda } from '../Kasda';

interface PageProps {
  store: Store<AppState, AppActions>;
  errorProvider: ErrorReporter;
  enableCoreWebVitals?: boolean;
}

export const Page: React.FunctionComponent<PageProps> = ({ store, children, errorProvider, enableCoreWebVitals = true }) => {
  const { locale } = store.getState().config;
  return (
    <React.Fragment>
      <Kasda.HtmlAttributes lang={locale} />
      <Provider store={store}>
        <ErrorReporting.Provider value={errorProvider}>
          {enableCoreWebVitals ? <CoreWebVitals /> : null}
          {children}
        </ErrorReporting.Provider>
      </Provider>
    </React.Fragment>
  );
};
