export class LocalStorage {
  static getItem(keyName) {
    try {
      return JSON.parse(window.localStorage.getItem(keyName));
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  static setItem(keyName, keyValue) {
    try {
      return window.localStorage.setItem(keyName, JSON.stringify(keyValue));
    } catch (e) {
      console.error(e);
    }
  }
}
