import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { Link } from './Link';

const baseStyles = {
  base: {
    flex: '0 0 auto',
    display: 'inline-flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'initial',
    fontFamily: 'inherit',
    height: '100%',
    ':visited': {
      textDecoration: 'none',
      color: 'initial',
    },
    ':active': {
      textDecoration: 'none',
      color: 'initial',
    },
    ':hover': {
      color: 'initial',
    },
  },
};

export interface LinkStyle {
  style: { [key: string]: string | object };
  additionalStyle: { [key: string]: string | object };
}

export interface ThemedLinkStyle {
  idleColor?: string;
  activeStateColor?: string;
  hoverStateColor?: string;
}

export const generateLinkStyleFromTheme = (themedLinkStyle: ThemedLinkStyle, additionalStyle: { [key: string]: string | object } = {}): LinkStyle => {
  return {
    style: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      color: themedLinkStyle.idleColor!,
      textDecoration: 'none',
      ':visited': {
        color: themedLinkStyle.idleColor,
      },
      ':active': {
        color: themedLinkStyle.activeStateColor,
      },
      [MEDIA_BREAKPOINTS.large]: {
        ':hover': {
          color: themedLinkStyle.hoverStateColor,
        },
      },
      [MEDIA_BREAKPOINTS.medium]: {
        ':hover': {
          color: themedLinkStyle.idleColor,
        },
      },
      [MEDIA_BREAKPOINTS.small]: {
        ':hover': {
          color: themedLinkStyle.idleColor,
        },
      },
    },
    additionalStyle,
  };
};

export interface TextLinkComponentProps {
  href: string;
  linkStyle?: LinkStyle;
  target?: string;
  rel?: string;
}

const combineStyleWithBase = (base: { [key: string]: { [key: string]: string | object } }, newStyle: null | LinkStyle): string => {
  return newStyle ? css(StyleSheet.create(base).base, StyleSheet.create(newStyle).style, StyleSheet.create(newStyle).additionalStyle) : css(StyleSheet.create(base).base);
};

export const TextLinkComponent: React.FunctionComponent<TextLinkComponentProps> = props => {
  const {
    children,
    href,
    target,
    rel,
    linkStyle = null,
  } = props;

  const finalStyle = combineStyleWithBase(baseStyles, linkStyle);

  return (
    <Link
      href={href}
      target={target}
      rel={rel}
      className={finalStyle}
    >
      {children}
    </Link>
  );
};
