import { StyleSheet } from 'aphrodite/no-important';
import { AdditionalStyleSheet } from '../../../../components.utils';
import { NavigationLink } from '../oldHeader/primaryHeader/PrimaryHeader';

export const getStrokeIconStyle = (strokeColor: string) => StyleSheet.create({
  style: {
    stroke: strokeColor,
    width: '24px',
    height: '24px',
  },
}) as AdditionalStyleSheet;

export const getFillIconStyle = (fillColor: string) => StyleSheet.create({
  style: {
    fill: fillColor,
    width: '24px',
    height: '24px',
  },
}) as AdditionalStyleSheet;

export const getFillAndStrokeIconStyle = (fillColor: string, strokeColor: string) => StyleSheet.create({
  style: {
    fill: fillColor,
    stroke: strokeColor,
    width: '24px',
    height: '24px',
  },
}) as AdditionalStyleSheet;

export const getChevronIconStyle = (isTransform: boolean, fillColor: string) => StyleSheet.create({
  style: {
    transform: isTransform ? 'rotate(180deg)' : 'none',
    width: '12px',
    height: '12px',
    padding: '0 4px',
    fill: fillColor,
  },
}) as AdditionalStyleSheet;

export const isCurrentPage = (navigationLink: NavigationLink, windowsDetails?: any, feedName?: string) => {
  const url = navigationLink.link.href;
  const link = url.endsWith('/') ? url.slice(0, -1) : url;
  return feedName ? feedName === navigationLink.link.text : (windowsDetails.origin + windowsDetails.path) === link;
};
