import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { MandatoryAdditionalStyle } from '../../components.utils';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { BlockWidths } from '../../components/pageLayouts/BlockTypes.utils';
import { createBlockStyle } from '../blockMutualStyle';
import { Kasda } from '../../../Kasda/Kasda';

interface LiveBlogProps extends MandatoryAdditionalStyle {
    html: string;
    dataId?: string;
    defaultBlocksWidths: BlockWidths;
}

const getStyle = (defaultBlocksWidths: BlockWidths) => {
  return StyleSheet.create({
    style: {
      ...createBlockStyle(defaultBlocksWidths),
    },
  }).style;
};

export const LiveBlog = (props: LiveBlogProps) => {
  const { html, additionalStyle, dataId, defaultBlocksWidths } = props;
  const style = getStyle(defaultBlocksWidths);

  return (
    <ErrorBoundary>
      <Kasda.Script>
        <script async src="https://v.24liveblog.com/24.js" />
      </Kasda.Script>
      <div className={css(additionalStyle.style, style)}>
        {/* eslint-disable-next-line react/no-danger */}
        <figure data-mm-id={dataId} dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </ErrorBoundary>
  );
};
