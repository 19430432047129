import { predicates } from '../predicates/index';
import { selectors } from '../selectors/index';
import { TRAFFIC_SOURCES } from '../constants';

export const share = {
  rule: predicates.and(
    predicates.searchParamIncludesValue('utm_medium', 'share'),
    predicates.searchParamsIncludeKey('utm_source'),
  ),
  extractor: (traffic) => {
    const trafficSource = TRAFFIC_SOURCES.SHARE;
    const trafficId = selectors.searchParamValue(traffic, 'utm_source');

    return {
      trafficSource,
      trafficId,
    };
  },
};
