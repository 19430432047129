export interface AdSlots {
  [adUnitKey: string]: {
    id: string;
    onSuccess: () => void;
  };
}
export const INJECT_ADS = 'inject ads' as const;

export interface InjectAdsAction {
  type: typeof INJECT_ADS;
  adSlotsToInject: AdSlots;
}
export const injectAds = (params: AdSlots): InjectAdsAction => ({
  type: INJECT_ADS,
  adSlotsToInject: params,
});
