import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { VerticalCard } from '../../partials/cards/verticalCard/VerticalCard';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { createImageWidths } from '../../partials/image/image.utils';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { getLayoutComponentPadding, LayoutComponentBaseProps } from '../pageLayouts/layout.utils';
import { componentConfigThemeKey as verticalCardConfigThemeKey, VerticalCardThemeProps,
} from '../../partials/cards/verticalCard/VerticalCard.theme';

interface FourVCTwoLinesProps extends LayoutComponentBaseProps{
    cards: Array<CardComponentDataProps>;
    useFormatMinutesHoursAgo?: boolean;
}

const createStyle = () => {
  return StyleSheet.create({
    wrapper: {
      [MEDIA_BREAKPOINTS.large]: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        gridTemplateRows: 'auto',
        gap: '20px',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: 'auto',
        gap: '20px',
      },
      [MEDIA_BREAKPOINTS.small]: {
        display: 'grid',
        gridTemplateColumns: 'auto',
        gridTemplateRows: 'min-content',
        gap: '20px',
      },
    },
  });
};

const imagesWidths = createImageWidths(360, 360, 360);

export const FourVCTwoLines: React.FunctionComponent<FourVCTwoLinesProps> = props => {
  const { cards, paddingFactors, useFormatMinutesHoursAgo } = props;
  const slicedCard = cards.length >= 8 ? cards.slice(0, 8) : cards.slice(0, 4);
  const paddingStyle = getLayoutComponentPadding(paddingFactors);
  const style = createStyle();

  const themeContextVC = useThemeOverride<VerticalCardThemeProps>({
    titleFontSizeLarge: 'big',
    titleFontSizeMedium: 'big',
    titleFontSizeSmall: 'big',
    showAuthorInLargeScreen: false,
    showAuthorInMediumScreen: false,
    showAuthorInSmallScreen: false,
    showDateInLargeScreen: false,
    showDateInMediumScreen: false,
    showDateInSmallScreen: false,
    showDescriptionInLargeScreen: false,
    showDescriptionInMediumScreen: false,
    showDescriptionInSmallScreen: false,
  }, verticalCardConfigThemeKey);
  return (
    <ErrorBoundary>
      {cards && cards.length > 0
            && (
            <div className={css(paddingStyle, style.wrapper)}>
              <ThemeContext.Provider value={themeContextVC}>
                { slicedCard.map((card, index) => {
                  return (
                    <React.Fragment key={index}>
                      {card && <VerticalCard {...card} imageWidths={imagesWidths} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />}
                    </React.Fragment>
                  );
                })}
              </ThemeContext.Provider>
            </div>
            ) }
    </ErrorBoundary>
  );
};
