export const TEAM_ID: { [key: string]: any } = {
  nba: {
    bulls: 3409,
    bucks: 3410,
    mavericks: 3411,
    rockets: 3412,
    kings: 3413,
    'sacramento-kings': 3413,
    'trail-blazers': 3414,
    grizzlies: 3415,
    suns: 3416,
    nuggets: 3417,
    thunder: 3418,
    pacers: 3419,
    '76ers': 3420,
    knicks: 3421,
    celtics: 3422,
    hawks: 3423,
    pistons: 3424,
    clippers: 3425,
    timberwolves: 3426,
    lakers: 3427,
    warriors: 3428,
    spurs: 3429,
    hornets: 3430,
    wizards: 3431,
    cavaliers: 3432,
    raptors: 3433,
    jazz: 3434,
    heat: 3435,
    nets: 3436,
    magic: 3437,
    pelicans: 5539,
  },
  mlb: {
    cubs: 3627,
    rockies: 3628,
    mets: 3629,
    brewers: 3630,
    cardinals: 3632,
    reds: 3633,
    giants: 3634,
    'san-francisco-giants': 3634,
    phillies: 3635,
    padres: 3636,
    pirates: 3637,
    dodgers: 3638,
    marlins: 3639,
    diamondbacks: 3640,
    mariners: 3641,
    jays: 3642,
    'blue-jays': 3642,
    'white-sox': 3644,
    athletics: 3645,
    'red-sox': 3646,
    rangers: 3647,
    'texas-rangers': 3647,
    tigers: 3648,
    'detroit-tigers': 3648,
    twins: 3649,
    guardians: 3650,
    royals: 3651,
    orioles: 3652,
    rays: 3653,
    yankees: 3654,
    astros: 3655,
    braves: 3656,
    angels: 5929,
    nationals: 5930,
  },
  nfl: {
    '49ers': 'f0e724b0-4cbf-495a-be47-013907608da9',
    bears: '7b112545-38e6-483c-a55c-96cf6ee49cb8',
    bengals: 'ad4ae08f-d808-42d5-a1e6-e9bc4e34d123',
    bills: '768c92aa-75ff-4a43-bcc0-f2798c2e1724',
    broncos: 'ce92bd47-93d5-4fe9-ada4-0fc681e6caa0',
    browns: 'd5a2eb42-8065-4174-ab79-0a6fa820e35e',
    buccaneers: '4254d319-1bc7-4f81-b4ab-b5e6f3402b69',
    cardinals: 'de760528-1dc0-416a-a978-b510d20692ff',
    chargers: '1f6dcffb-9823-43cd-9ff4-e7a8466749b5',
    chiefs: '6680d28d-d4d2-49f6-aace-5292d3ec02c2',
    colts: '82cf9565-6eb9-4f01-bdbd-5aa0d472fcd9',
    commanders: '22052ff7-c065-42ee-bc8f-c4691c50e624',
    cowboys: 'e627eec7-bbae-4fa4-8e73-8e1d6bc5c060',
    dolphins: '4809ecb0-abd3-451d-9c4a-92a90b83ca06',
    eagles: '386bdbf9-9eea-4869-bb9a-274b0bc66e80',
    falcons: 'e6aa13a4-0055-48a9-bc41-be28dc106929',
    giants: '04aa1c9d-66da-489d-b16a-1dee3f2eec4d',
    'new-york-giants': '04aa1c9d-66da-489d-b16a-1dee3f2eec4d',
    jaguars: 'f7ddd7fa-0bae-4f90-bc8e-669e4d6cf2de',
    jets: '5fee86ae-74ab-4bdd-8416-42a9dd9964f3',
    'new-york-jets': '5fee86ae-74ab-4bdd-8416-42a9dd9964f3',
    lions: 'c5a59daa-53a7-4de0-851f-fb12be893e9e',
    packers: 'a20471b4-a8d9-40c7-95ad-90cc30e46932',
    panthers: 'f14bf5cc-9a82-4a38-bc15-d39f75ed5314',
    'carolina-panthers': 'f14bf5cc-9a82-4a38-bc15-d39f75ed5314',
    patriots: '97354895-8c77-4fd4-a860-32e62ea7382a',
    raiders: '7d4fcc64-9cb5-4d1b-8e75-8a906d1e1576',
    rams: '2eff2a03-54d4-46ba-890e-2bc3925548f3',
    ravens: 'ebd87119-b331-4469-9ea6-d51fe3ce2f1c',
    saints: '0d855753-ea21-4953-89f9-0e20aff9eb73',
    seahawks: '3d08af9e-c767-4f88-a7dc-b920c6d2b4a8',
    steelers: 'cb2f9f1f-ac67-424e-9e72-1475cb0ed398',
    tbd: '23ed0bf0-f058-11ee-9989-93cc4251593a',
    texans: '82d2d380-3834-4938-835f-aec541e5ece7',
    titans: 'd26a1ca5-722d-4274-8f97-c92e49c96315',
    vikings: '33405046-04ee-4058-a950-d606f8c30852',
    'dallas-cowboys': 'e627eec7-bbae-4fa4-8e73-8e1d6bc5c060',
  },
  wnba: {
    lynx: 3440,
    mercury: 3444,
    liberty: 3446,
    sparks: 3447,
    storm: 3448,
    wings: 3450,
    fever: 3452,
    mystics: 3454,
    dream: 35545,
    sky: 35546,
    sun: 35547,
    aces: 35550,
  },
  nhl: {
    ducks: 3675,
    'anaheim-ducks': 3675,
    jets: 3676,
    'winnipeg-jets': 3676,
    bruins: 3677,
    sabres: 3678,
    flames: 3679,
    hurricanes: 3680,
    'carolina-hurricanes': 3680,
    blackhawks: 3681,
    avalanche: 3682,
    'blue-jackets': 3683,
    stars: 3684,
    'red-wings': 3685,
    oilers: 3686,
    panthers: 3687,
    'florida-panthers': 3687,
    kings: 3688,
    'los-angeles-kings': 3688,
    wild: 3689,
    canadiens: 3690,
    capitals: 3691,
    canucks: 3692,
    'maple-leafs': 3693,
    lightning: 3694,
    blues: 3695,
    sharks: 3696,
    penguins: 3697,
    coyotes: 3698,
    flyers: 3699,
    senators: 3700,
    rangers: 3701,
    'new-york-rangers': 3701,
    islanders: 3703,
    devils: 3704,
    predators: 3705,
    'golden-knights': 344158,
    kraken: 794340,
    'utah-hockey-club': 1148725,
  },
  ncaab: {
    alabama: 66366,
    arizona: 34645,
    arizonastate: 34654,
    arkansas: 87830,
    auburn: 86622,
    baylor: 41204,
    bostoncollege: 34646,
    byu: 34604,
    cal: 34610,
    cincinnati: 385086,
    clemson: 34614,
    colorado: 66360,
    duke: 34622,
    florida: 41197,
    fsu: 34658,
    georgia: 49869,
    georgiatech: 41219,
    gonzaga: 34620,
    indiana: 66358,
    iowa: 86816,
    kansas: 34634,
    kentucky: 41210,
    louisville: 34662,
    lsu: 34600,
    miami: 86752,
    michigan: 34615,
    michiganstate: 34656,
    minnesota: 34617,
    mississippistate: 34613,
    missouri: 34640,
    ncstate: 66370,
    nebraska: 86762,
    unc: 34608,
    northwestern: 86698,
    notredame: 41198,
    ohiostate: 34650,
    oklahoma: 34624,
    olemiss: 86824,
    oregon: 86814,
    pennstate: 49856,
    pittsburgh: 34638,
    purdue: 34606,
    smu: 86768,
    southcarolina: 86810,
    stanford: 86766,
    syracuse: 34630,
    tennessee: 34633,
    texas: 34616,
    tamu: 34605,
    tcu: 87346,
    'texas-tech': 86812,
    ucf: 86750,
    ucla: 34626,
    uconn: 34598,
    usc: 34647,
    utah: 34644,
    virginia: 66378,
    'wake-forest': 34652,
    washington: 34612,
    westvirginia: 34642,
    wisconsin: 34659,
  },
  ncaaf: {
    arizona: 4343,
    cincinnati: 4316,
    smu: 4305,
    miami: 47357,
    ucf: 4309,
    bostoncollege: 4288,
    clemson: 364698,
    duke: 4296,
    fsu: 4297,
    georgiatech: 4320,
    louisville: 4335,
    ncstate: 4368,
    unc: 4307,
    pittsburgh: 47362,
    syracuse: 4329,
    virginia: 61356,
    'wake-forest': 4327,
    baylor: 4359,
    kansas: 4369,
    oklahoma: 47360,
    tcu: 4303,
    texas: 4332,
    'texas-tech': 4357,
    westvirginia: 4289,
    indiana: 4375,
    iowa: 4322,
    tamu: 4348,
    michiganstate: 4338,
    michigan: 4310,
    minnesota: 4381,
    nebraska: 4282,
    northwestern: 4366,
    ohiostate: 4352,
    pennstate: 4286,
    purdue: 4317,
    wisconsin: 4301,
    byu: 4330,
    uconn: 4382,
    notredame: 4372,
    arizonastate: 4380,
    cal: 4292,
    colorado: 234266,
    oregon: 47372,
    stanford: 4379,
    ucla: 4313,
    usc: 4290,
    utah: 47352,
    washington: 4370,
    alabama: 4312,
    arkansas: 47361,
    auburn: 4294,
    florida: 47359,
    georgia: 47358,
    kentucky: 47363,
    lsu: 4284,
    mississippistate: 4318,
    missouri: 4314,
    olemiss: 4385,
    southcarolina: 4278,
    tennessee: 4328,
    kstate: 4342,
    virginiatech: 4336,
  },
};

export const FANSIDED_COLLEGE_ID: { [key: string]: any } = {
  ncaaf: {
    gamecocks: 4278,
    aggies: 4348,
    seminoles: 4297,
    'tar-heels': 4307,
    hokies: 4336,
    'usf-bulls': 4346,
    buckeyes: 4352,
    'sun-devils': 4380,
    rebels: 4385,
    razorbacks: 47361,
    cornhuskers: 4282,
    badgers: 4301,
    terrapins: 4306,
    wolverines: 4310,
    'boston-bruins': 4313,
    hawkeyes: 4322,
    volunteers: 4328,
    orange: 4329,
    longhorns: 4332,
    jayhawks: 4369,
    // huskies: undefined, // which one?
    hoosiers: 4375,
    sooners: 47360,
    'oklahoma-state-cowboys': 4281,
    'lsu-tigers': 4284,
    'ucla-bruins': 4313,
    'missouri-tigers': 4314,
    'byu-cougars': 4330,
    'kansas-state-wildcats': 4342,
    'arizona-wildcats': 4343,
    'kentucky-wildcats': 47363,
    'oregon-ducks': 47372,
    'clemson-tigers': 364698,
    'blue-devils': 4296,
    beavers: 4299,
    'miami-hurricanes': 47357,
    buffaloes: 234266,
    'crimson-tide': 4312,
    'fighting-irish': 4372,
    gators: 47359,
    mountaineers: 4289,
    'nittany-lions': 4286,
    trojans: 4290,
    'yellow-jackets': 4320,
    'aubum-tigers': 4294,
    knights: 4309,
  },
  ncaab: {
    gamecocks: 86810,
    aggies: 34605,
    'tar-heels': 34608,
    buckeyes: 34650,
    'sun-devils': 34654,
    rebels: 86824,
    razorbacks: 87830,
    cornhuskers: 86762,
    badgers: 34659,
    wolverines: 34615,
    'boston-bruins': 34626,
    hawkeyes: 86816,
    volunteers: 34633,
    orange: 34630,
    longhorns: 34616,
    jayhawks: 34634,
    hoosiers: 66358,
    sooners: 34624,
    'lsu-tigers': 34600,
    'ucla-bruins': 34626,
    'missouri-tigers': 34640,
    'byu-cougars': 34604,
    'kentucky-wildcats': 41210,
    'oregon-ducks': 86814,
    'clemson-tigers': 34614,
    'blue-devils': 34622,
    'miami-hurricanes': 86752,
    buffaloes: 66360,
    'crimson-tide': 66366,
    'fighting-irish': 41198,
    gators: 41197,
    mountaineers: 34642,
    'nittany-lions': 49856,
    trojans: 34647,
    'yellow-jackets': 41219,
    'aubum-tigers': 86622,
    knights: 86750,
    'usf-bulls': 66334,
    hokies: 87336,
    bearcats: 49870,
    huskies: 34598,
    'kansas-state-wildcats': 41202,
    'arizona-wildcats': 34645,
    'washington-state-cougars': 86860,
    'fighting-illini': 34628,
  },
};

// bulldogs, cougars, spartans, wildcats which ncaa team?
