import * as React from 'react';
import { StyleSheet } from 'aphrodite/no-important';
import { TypographySize } from 'mm-theme-configuration/dist/consts';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { useTheme } from '../../../theming/useTheme';
import { createImageWidths, ImageWidths } from '../../partials/image/image.utils';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { LayoutComponentBaseProps } from '../pageLayouts/layout.utils';
import { VerticalCard } from '../../partials/cards/verticalCard/VerticalCard';
import {
  VerticalCardThemeProps,
  componentConfigThemeKey as verticalCardConfigThemeKey,
} from '../../partials/cards/verticalCard/VerticalCard.theme';
import { TypographySizes } from '../../../typography/semanticTags/semanticTags.utils';
import { AdditionalStyleSheet } from '../../components.utils';

export const componentConfigThemeKey = 'oneVCNineHLC';

export interface OneVCThemeProps {
  cardTitleColorTypeNormal: string;
  metadataColor: string;
  metadataColorHover: string;
  HLCTitleColor: string;
  HLCTitleColorHover: string;
  showDivider: boolean;
  HLCBackgroundColor: string;
  dividerColor: string;
  HLCTitleRowsNumber: number;
  HLCDisplayNameFontSizeLarge: TypographySize;
  HLCDisplayNameFontSizeMedium: TypographySize;
  HLCDisplayNameFontSizeSmall: TypographySize;
  HLCTitleFontSizeLarge: TypographySize;
  HLCTitleFontSizeMedium: TypographySize;
  HLCTitleFontSizeSmall: TypographySize;
  VCTitleFontSizeLarge: TypographySize;
  VCTitleFontSizeMedium: TypographySize;
  VCTitleFontSizeSmall: TypographySize;
  VCDescriptionFontSizeLarge: TypographySize;
  VCDescriptionFontSizeMedium: TypographySize;
  VCDescriptionFontSizeSmall: TypographySize;
  HLCLinkSpacing: number;
  HLCLinkContainerPadding: number;
  HLCBorderColor: string;
}

interface OneVCDataProps extends LayoutComponentBaseProps {
  card: CardComponentDataProps;
  useFormatMinutesHoursAgo?: boolean;
  imageWidths?: ImageWidths;
}

const style = StyleSheet.create({
  style: {
    gridTemplateRows: 'inherit',
  },
}) as AdditionalStyleSheet;

const imageWidthsHC = createImageWidths(360, 540, 720);

export const OneVC: React.FunctionComponent<OneVCDataProps> = props => {
  const { card, useFormatMinutesHoursAgo, imageWidths } = props;
  const themeProps = useTheme<OneVCThemeProps>(componentConfigThemeKey);
  const {
    cardTitleColorTypeNormal,
    metadataColor,
    metadataColorHover,
    VCDescriptionFontSizeLarge,
    VCDescriptionFontSizeMedium,
    VCDescriptionFontSizeSmall,
    VCTitleFontSizeLarge,
    VCTitleFontSizeMedium,
    VCTitleFontSizeSmall,
  } = themeProps;

  const themeContextHC = useThemeOverride<VerticalCardThemeProps>(
    {
      titleFontSizeLarge: VCTitleFontSizeLarge,
      titleFontSizeMedium: VCTitleFontSizeMedium,
      titleFontSizeSmall: VCTitleFontSizeSmall,
      descriptionFontSizeInLargeScreen: VCDescriptionFontSizeLarge,
      descriptionFontSizeInMediumScreen: VCDescriptionFontSizeMedium,
      descriptionFontSizeInSmallScreen: VCDescriptionFontSizeSmall,
      metadataFontSizeLarge: TypographySizes.TINY,
      metadataFontSizeMedium: TypographySizes.TINY,
      metadataFontSizeSmall: TypographySizes.TINY,
      cardTitleColorTypeNormal,
      borderRadius: 0,
      showDescriptionInLargeScreen: true,
      showDescriptionInMediumScreen: true,
      showDescriptionInSmallScreen: true,
      verticalGapLarge: 0.5,
      verticalGapMedium: 0.5,
      horizontalGapLarge: 0.5,
      horizontalGapMedium: 0.5,
      numberOfLinesForDescription: 10,
      numberOfLinesForTitle: 10,
      descriptionHorizontalGap: 0.5,
      boxShadowTypeHover: 'none',
      boxShadowTypeNormal: 'none',
      boxShadowTypeActive: 'none',
      isClickableLabels: true,
      dateColor: metadataColor,
      authorColor: metadataColor,
      authorColorHover: metadataColorHover,
      additionalBottomTextWrapperStyle: style.style,
    },
    verticalCardConfigThemeKey,
  );

  return (
    <ErrorBoundary>
      <ThemeContext.Provider value={themeContextHC}>
        <VerticalCard
          {...card}
          imageWidths={!imageWidths ? imageWidthsHC : imageWidths}
          useFormatMinutesHoursAgo={useFormatMinutesHoursAgo}
        />
      </ThemeContext.Provider>
    </ErrorBoundary>
  );
};
