import * as React from 'react';
import { Helmet } from 'react-helmet';

interface AMPComscoreProps {
    comscoreId: string;
}
export const AMPComscore: React.FunctionComponent<AMPComscoreProps> = props => {
  const { comscoreId } = props;
  const scriptJson = {
    vars: {
      c2: comscoreId,
    },
    extraUrlParams: {
      comscorekw: 'amp',
    },
  };
  return (
    <React.Fragment>
      <Helmet>
        <script async={undefined} custom-element="amp-analytics" src="https://cdn.ampproject.org/v0/amp-analytics-0.1.js" />
      </Helmet>
      {/*
    // @ts-ignoreTS2339: Property 'amp-analytics' does not exist on type 'JSX.IntrinsicElements'. */}
      <amp-analytics type="comscore">
        {/* eslint-disable-next-line react/no-danger */}
        <script type="application/json" dangerouslySetInnerHTML={{ __html: JSON.stringify(scriptJson) }} />
        {/*
    // @ts-ignoreTS2339: Property 'amp-analytics' does not exist on type 'JSX.IntrinsicElements'. */}
      </amp-analytics>
    </React.Fragment>
  );
};
