import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useTheme } from '../../../theming/useTheme';
import { componentConfigurationKey, getTrianglesSvg, SectionDividerThemeProps } from './utils';
import { BaseUnit } from '../../../theming/baseUnitDefinition';

export const getStylesForTrianglesIcon = (baseUnit: BaseUnit) => {
  return StyleSheet.create({
    triangles: {
      width: `${baseUnit * 1.2}px`,
    },
  });
};

export const TrianglesIconForDivider: React.FunctionComponent = () => {
  const { baseUnit, dividerLogoColor } = useTheme<SectionDividerThemeProps>(componentConfigurationKey);
  const styles = getStylesForTrianglesIcon(baseUnit);
  const svg = getTrianglesSvg(dividerLogoColor);
  const imgSrc = `data:image/svg+xml,${encodeURIComponent(svg)}`;
  return (
    <img className={css(styles.triangles)} src={imgSrc} alt="" />
  );
};
