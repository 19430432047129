import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { BlockWidths } from '../../components/pageLayouts/BlockTypes.utils';
import { createBlockStyle } from '../blockMutualStyle';
import { useTheme } from '../../../theming/useTheme';
import { baseUnit } from '../../../theming/baseUnitDefinition';
import { RelatedTopicsThemeProps, componentConfigThemeKey } from './relatedTopics.theme';
import { useViewabilityHandler } from '../../../viewability/viewabilityHandler';

export interface RelatedTopicsWrapperProps {
    defaultBlocksWidths: BlockWidths;
    marginBetweenBlocksFactor: number;
    dataId: string;
}

const createWrapperStyle = (defaultBlocksWidths: BlockWidths, backgroundColor: string, marginBetweenBlocksFactor: number) => StyleSheet.create({
  style: {
    backgroundColor,
    marginTop: `${marginBetweenBlocksFactor * baseUnit * 2}px`,
    marginBottom: `${marginBetweenBlocksFactor * baseUnit * 2}px`,
    ...createBlockStyle(defaultBlocksWidths),
  },
}).style;

export const RelatedTopicsWrapper: React.FunctionComponent<RelatedTopicsWrapperProps> = props => {
  const viewabilityCallback = React.useCallback(() => {
    // @ts-ignore
    window.mmClientApi = window.mmClientApi || [];
    // @ts-ignore
    window.mmClientApi.push('analytics', {
      event_category: 'Article',
      event_action: 'Related Topics view-ability',
      event_label: '',
    });
  }, []);

  const ref = useViewabilityHandler(viewabilityCallback, 0.5);
  const { defaultBlocksWidths, marginBetweenBlocksFactor, children, dataId } = props;
  const { titleBackgroundColor } = useTheme<RelatedTopicsThemeProps>(componentConfigThemeKey);
  const wrapperStyle = createWrapperStyle(defaultBlocksWidths, titleBackgroundColor, marginBetweenBlocksFactor);
  return (
    <aside ref={ref} className={css(wrapperStyle)} data-mm-id={dataId}>
      {children}
    </aside>
  );
};
