import { TypographySize, SEMANTIC_TAG, TYPOGRAPHY_SIZE, ScreenSize } from 'mm-theme-configuration/dist/consts';
import { StyleSheet, StyleDeclarationValue, CSSProperties } from 'aphrodite/no-important';
import { MEDIA_BREAKPOINTS } from '../../mediaQueries.const';
import { TranslatedFontSize } from '../../theming/fontSizeTranslator';

type FontSizes = {
  fontSizeLarge: TypographySize;
  fontSizeMedium: TypographySize;
  fontSizeSmall: TypographySize;
}

export const TypographySizes = {
  GIGANTIC: 'gigantic',
  HUGE: 'huge',
  LARGE: 'large',
  BIGGER: 'bigger',
  BIG: 'big',
  NORMAL: 'normal',
  TINY: 'tiny',
} as const;

export const semanticCSS = (...styles: StyleDeclarationValue[]) => styles;

export interface SemanticTagDataProps extends React.HTMLAttributes<HTMLElement> {
  styles?: ReturnType<typeof semanticCSS>;
  fontSizeLarge?: TypographySize;
  fontSizeMedium?: TypographySize;
  fontSizeSmall?: TypographySize;
  reference?: React.RefObject<HTMLElement>;
}

export interface SemanticTagThemeProps {
  fontSizesStyles: TranslatedFontSize;
}

export const getLineHeightBySize = (fontSizesStyle: TranslatedFontSize, fontSize: TypographySize, screenSize: ScreenSize) => {
  return parseFloat(fontSizesStyle[fontSize].breakpoints[screenSize].lineHeight);
};

export const getStyleBySize = (fontSizesStyle: TranslatedFontSize, fontSizes: FontSizes) => {
  const { fontSizeLarge, fontSizeMedium, fontSizeSmall } = fontSizes;
  return StyleSheet.create({
    tagStyle: {
      [MEDIA_BREAKPOINTS.large]: {
        ...fontSizesStyle[fontSizeLarge].style as CSSProperties,
        ...fontSizesStyle[fontSizeLarge].breakpoints.large,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        ...fontSizesStyle[fontSizeMedium].style as CSSProperties,
        ...fontSizesStyle[fontSizeMedium].breakpoints.medium,
      },
      [MEDIA_BREAKPOINTS.small]: {
        ...fontSizesStyle[fontSizeSmall].style as CSSProperties,
        ...fontSizesStyle[fontSizeSmall].breakpoints.small,
      },
      margin: 0,
    },
  }).tagStyle;
};

export const getFontSizesBySemanticTag = (semanticTag: SEMANTIC_TAG) => {
  switch (semanticTag) {
    case 'h1':
      return {
        fontSizeLarge: TYPOGRAPHY_SIZE.GIGANTIC,
        fontSizeMedium: TYPOGRAPHY_SIZE.GIGANTIC,
        fontSizeSmall: TYPOGRAPHY_SIZE.GIGANTIC,
      };
    case 'h2':
      return {
        fontSizeLarge: TYPOGRAPHY_SIZE.HUGE,
        fontSizeMedium: TYPOGRAPHY_SIZE.HUGE,
        fontSizeSmall: TYPOGRAPHY_SIZE.HUGE,
      };
    case 'h3':
      return {
        fontSizeLarge: TYPOGRAPHY_SIZE.LARGE,
        fontSizeMedium: TYPOGRAPHY_SIZE.LARGE,
        fontSizeSmall: TYPOGRAPHY_SIZE.LARGE,
      };
    case 'h4':
      return {
        fontSizeLarge: TYPOGRAPHY_SIZE.BIGGER,
        fontSizeMedium: TYPOGRAPHY_SIZE.BIGGER,
        fontSizeSmall: TYPOGRAPHY_SIZE.BIGGER,
      };
    case 'h5':
      return {
        fontSizeLarge: TYPOGRAPHY_SIZE.BIG,
        fontSizeMedium: TYPOGRAPHY_SIZE.BIG,
        fontSizeSmall: TYPOGRAPHY_SIZE.BIG,
      };
    case 'h6':
      return {
        fontSizeLarge: TYPOGRAPHY_SIZE.TINY,
        fontSizeMedium: TYPOGRAPHY_SIZE.TINY,
        fontSizeSmall: TYPOGRAPHY_SIZE.TINY,
      };
    case 'span':
      return {
        fontSizeLarge: TYPOGRAPHY_SIZE.NORMAL,
        fontSizeMedium: TYPOGRAPHY_SIZE.NORMAL,
        fontSizeSmall: TYPOGRAPHY_SIZE.NORMAL,
      };
    case 'p':
    default:
      return {
        fontSizeLarge: TYPOGRAPHY_SIZE.NORMAL,
        fontSizeMedium: TYPOGRAPHY_SIZE.NORMAL,
        fontSizeSmall: TYPOGRAPHY_SIZE.NORMAL,
      };
  }
};

export const sanitizeAttributes = (attributes: { [key: string]: any }) => {
  const notAllowedAttributes = ['baseUnit', 'fontSizesStyles', 'isMainCategoryEnabled'];
  return Object.keys(attributes).reduce((newAttributes: { [key: string]: any }, attr: string) => {
    return {
      ...newAttributes,
      ...(notAllowedAttributes.includes(attr) ? null : { [attr]: attributes[attr] }),
    };
  }, {});
};
