import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { useTheme } from '../../../theming/useTheme';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { ThreeThreeVC } from './ThreeThreeVC';
import { SixHC } from './SixHC';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { componentConfigThemeKey as horizontalCardKey, HorizontalCardThemeProps } from '../../partials/cards/horizontalCard/horizontalCard.theme';
import { getArticlesInChunks } from './articleLists.utils';

export const componentConfigThemeKey = 'sixSix';

export interface SixSixArticleListComponentThemeProps {
  baseUnit: BaseUnit;
  showDescriptionInMediumScreen: boolean;
  showDescriptionInLargeScreen: boolean;
}

const getSixSixArticleListStyle = (baseUnit: BaseUnit) => {
  return StyleSheet.create({
    verticalCardsGrid: {
      marginBottom: `${baseUnit}px`,
    },
    horizontalCardsGrid: {
      marginBottom: `${baseUnit / 2}px`,
      marginTop: `${baseUnit / 2}px`,
    },
  });
};

export type SectionsOrder = 'vertical-horizontal' | 'horizontal-vertical';

export interface SixSixArticleListProps {
  cardsProps: CardComponentDataProps[] | null;
  useFormatMinutesHoursAgo?: boolean;
}

export const SixSixArticleList: React.FunctionComponent<SixSixArticleListProps> = ({ cardsProps, useFormatMinutesHoursAgo }) => {
  const {
    baseUnit,
    showDescriptionInLargeScreen,
    showDescriptionInMediumScreen,
  } = useTheme<SixSixArticleListComponentThemeProps>(componentConfigThemeKey);
  const SixSixArticleListStyle = getSixSixArticleListStyle(baseUnit);
  const firstSectionStyle = SixSixArticleListStyle.verticalCardsGrid;
  const secondSectionStyle = SixSixArticleListStyle.horizontalCardsGrid;
  const alteredThemeData = { showDescriptionInLargeScreen, showDescriptionInMediumScreen };

  const secondSectionTheme = useThemeOverride<HorizontalCardThemeProps>(alteredThemeData, horizontalCardKey);

  const createGrids = (cards: CardComponentDataProps[], index: number) => {
    return (index % 2 === 0
      ? (
        <div className={css(firstSectionStyle)} key={index}>
          <ThreeThreeVC cards={cards} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />
        </div>
      )
      : (
        <div className={css(secondSectionStyle)} key={index}>
          <ThemeContext.Provider value={secondSectionTheme}>
            <SixHC cards={cards} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />
          </ThemeContext.Provider>
        </div>
      )
    );
  };
  return (
    <ErrorBoundary>
      {cardsProps && cardsProps.length > 0 && getArticlesInChunks(cardsProps, 6).map(createGrids)}
    </ErrorBoundary>
  );
};
