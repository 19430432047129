import * as React from 'react';
import { Helmet } from 'react-helmet';

interface OpenWebAmpProps {
  postId: string;
  spotId: string;
}

export const OpenWebAMP: React.FunctionComponent<OpenWebAmpProps> = ({ postId, spotId }) => {
  return (
    <React.Fragment>
      <Helmet>
        <link href="https://fonts.googleapis.com/css?family=Open+Sans&display=swap" rel="stylesheet" />
        <script async={undefined} custom-template="amp-mustache" src="https://cdn.ampproject.org/v0/amp-mustache-0.2.js" />
        <script async={undefined} custom-element="amp-iframe" src="https://cdn.ampproject.org/v0/amp-iframe-0.1.js" />
        <script async={undefined} custom-element="amp-carousel" src="https://cdn.ampproject.org/v0/amp-carousel-0.1.js" />
        <script async={undefined} custom-element="amp-ad" src="https://cdn.ampproject.org/v0/amp-ad-0.1.js" />
        <script async={undefined} custom-element="amp-list" src="https://cdn.ampproject.org/v0/amp-list-0.1.js" />
        <style amp-custom>
          {`
            .pitc > * {
              box-sizing: border-box;
            }
            .pitc-cover > img {
              object-fit: cover;
              overflow: hidden;
            }
            .pitc-article-title {
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
            }
            .pitc-comment-text {
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
            }
            .pitc-username {
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }
            @media only screen and (min-device-width: 320px) and (max-device-width: 350px) {
              .pitc-comment-text {
                -webkit-line-clamp: 1;
              }
            }
            @media only screen and (min-device-width: 350px) and (max-device-width: 400px) {
              .pitc-comment-text {
                -webkit-line-clamp: 2;
              }
            }
            @media only screen and (min-device-width: 320px) and (max-device-width: 350px) {
              .pitc-article-title {
                -webkit-line-clamp: 1;
              }
            }
            @media only screen and (min-device-width: 350px) and (max-device-width: 400px) {
              .pitc-article-title {
                -webkit-line-clamp: 2;
              }
            }
          `}
        </style>
      </Helmet>
      <div dangerouslySetInnerHTML={{
        __html: `
        <div style="padding:8px">
          <amp-list width="auto" height="400" layout="fixed-height" items="items" src="https://api-2-0.spot.im/v1.0.0/feed/pitc/amp/${spotId}/default?count=8">
              <template type="amp-mustache" id="amp-spotim-rc">
                <div class="pitc">
                    <div style="font-family:Open Sans;height:35px;margin-top:15px;margin-bottom:10px;font-size:18px;font-weight:800;color:#373e44">
                      Popular in the Community
                    </div>
                    <amp-carousel type="slides" width="400" height="400" layout="responsive" autoplay="" delay="6000">
                      {{#values}}
                      <div style="font-family:Open Sans;width:95%;height:95%;position:relative;border-radius:7px;background-color:#fff;box-shadow:0 2px 10px 0 rgba(0, 0, 0, 0.1)">
                          <a style="text-decoration:none;color:#000" href="{{ url }}">
                            <div style="border-top-left-radius:7px;border-top-right-radius:7px;height:50%;width:100%">
                                <amp-img class="pitc-cover" style="border-top-left-radius:7px;border-top-right-radius:7px" src="{{ image }}" width="600" height="300" layout="responsive" alt="{{ title }}">
                                </amp-img>
                                <div style="background-image:linear-gradient( to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.9));width:100%;height:50%;position:absolute;bottom:50%;left:0">
                                </div>
                                <div class="pitc-article-title" style="font-family:Open Sans;margin-bottom:15px;margin-left:15px;margin-right:15px;text-align:left;font-size:16px;font-weight:600;position:absolute;bottom:50%;left:0%;color:#fff;display:-webkit-box;overflow:hidden">
                                  {{ title }}
                                </div>
                            </div>
                            <div style="height:50%;width:100%;padding:15px;font-size:15px;line-height:normal;display:flex;flex-direction:column;justify-content:space-between;box-sizing:border-box;position:relative;font-weight:normal">
                                <div style="font-family:Open Sans;display:flex;flex-direction:row;margin-bottom:12px;line-height:30px;text-align:center">
                                  <amp-img width="30" height="30" src="{{ avatar }}">
                                  </amp-img>
                                  <div class="pitc-username" style="font-family:Open Sans;font-weight:600;margin-left:8px;display:-webkit-box;overflow:hidden;word-break:break-all">
                                      {{ username }}
                                  </div>
                                  <div style="font-family:Open Sans;margin-left:7px;margin-right:5px;color:#727579">
                                      ·
                                  </div>
                                  <div style="font-family:Open Sans;color:#727579">
                                      {{ time }}
                                  </div>
                                </div>
                                <div class="pitc-comment-text" style="font-family:Open Sans;color:#373e44;margin-bottom:15px;display:-webkit-box;overflow:hidden">
                                  {{ messageText }}
                                </div>
                                <div style="font-family:Open Sans;margin-bottom:0;margin-top:auto;display:flex;flex-direction:row;font-size:14px;font-weight:600">
                                  <svg style="height:18px;width:18px;margin-right:5px;display:inline" viewBox="0 0 12 14">
                                      <path fill="#DB3737" fill-rule="nonzero" d="M4.429 14C2.857 13.53.666 11.476.517 9.048.368 6.621 1.2 5.181 2.702 3.56 3.704 2.479 4.415 1.292 4.835 0 6.683 1.454 8.28 4.292 8.28 7.327c.673-.549 1.173-1.355 1.501-2.42 1.391 1.29 2.003 3.278 1.595 5.184C10.97 11.985 9.143 13.53 7.57 14v-.412c1.572-.906 2.59-2.717 2.347-5a5.116 5.116 0 0 1-3.132 1.882c.078-3.045-.39-5.302-1.4-6.771C4.224 5.393 3.14 5.944 2.53 7.327c-1.174 2.66.326 5.355 1.898 6.261V14z">
                                      </path>
                                  </svg>
                                  Top Comment
                                </div>
                            </div>
                          </a>
                      </div>
                      {{/values}}
                    </amp-carousel>
                </div>
              </template>
          </amp-list>
          <amp-layout layout="responsive" width="0" height="0">
          </amp-layout>
          <a data-spmark="ad-choice" href="https://dynamic-cdn.spot.im/yad/optout.html" target="_blank">
              <amp-img src="https://publisher-assets.spot.im/yad/ad-choises.png" width="9px" height="9px">
              </amp-img>
          </a>
          <amp-list width="auto" height="1" layout="fixed-height" items="items" style="text-align:center" src="https://api-2-0.spot.im/v1.0.0/owa-gw/spot/${spotId}/amp/recirculation">
              <template type="amp-mustache">
                {{#isDisplay}}
                <amp-ad type="{{type}}" width="{{width}}" height="{{height}}" data-slot="{{ code }}" json='{"targeting":{"ampRCSpotId":[${spotId}]}}' rtc-config='{"vendors":{"IndexExchange":{"SITE_ID":545698}},"timeoutMillis":1000}'>
                    <div placeholder="">
                    </div>
                    <div fallback="">
                    </div>
                </amp-ad>
                {{/isDisplay}}{{#isAniviewVideo}}
                <amp-ad type="{{type}}" width="{{width}}" height="{{height}}" data-playonview="1" data-pauseonunseen="1" data-publisherid="5e0e296628a061270b21ccab" data-channelid="{{code}}" data-ref1="AV_CDIM1=${spotId}&AV_SCHAIN=1.0,1!spotim.market,{{seller_id}},1,,," data-passbackurl="https://play.aniview.com/57b31584f83f1518108b4568/5e71193539e7c102b4435af3/spotimpassback.js">
                </amp-ad>
                {{/isAniviewVideo}}{{#isSRVideo}}
                <amp-ad type="{{type}}" width="{{width}}" height="{{height}}" data-blade_player_type="{{type}}" data-blade_player_id="{{code}}" data-blade_api_key="587dfc37febaab0002000001" data-blade_macros='{"sub_id":"spotId=${spotId}&source=recirculation_amp"}'>
                </amp-ad>
                {{/isSRVideo}}
              </template>
          </amp-list>
          <amp-layout layout="responsive" width="0" height="0">
          </amp-layout>
          <amp-iframe width="375" height="650" sandbox="allow-scripts allow-same-origin allow-popups allow-top-navigation" layout="responsive" frameborder="0" src="https://amp.spot.im/staging-v2.html?redirect=true&spot_im_highlight_immediate=true&spotId=${spotId}&postId=${postId}&product=conversation">
              <amp-img placeholder="true" height="750" layout="fill" src="//amp.spot.im/loader.png">
              </amp-img>
              <div overflow="" class="spot-im-amp-overflow" tabindex="0" role="button" aria-label="Read more">
                Load more...
              </div>
          </amp-iframe>
        </div>
        `,
      }}
      />
    </React.Fragment>
  );
};
