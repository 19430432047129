import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { MandatoryAdditionalStyle } from '../../components.utils';
import { baseUnit } from '../../../theming/baseUnitDefinition';
import { BlockWidths } from '../../components/pageLayouts/BlockTypes.utils';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import {
  componentConfigurationKey as SectionHeaderConfigThemeKey,
  SectionHeader,
  SectionHeaderComponentThemeProps,
} from '../../wrappers/sectionHeader/SectionHeader';
import { H3 } from '../../../typography/semanticTags';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { useTheme } from '../../../theming/useTheme';
import { componentConfigurationKey, ArticleBlockThemeProps } from '../ArticleBlock.theme';
import { Link } from '../../partials/link/Link';
import { getStyleBySize } from '../../../typography/semanticTags/semanticTags.utils';
import { TableOfContentsWrapper } from './TableOfContentsWrapper';

const getStyles = (linkColor: string) => {
  return StyleSheet.create({
    liItem: {
      color: linkColor,
    },
    listStyle: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '0px',
      paddingLeft: '30px',
      gap: 0.5 * baseUnit,
    },
    linkStyle: {
      width: 'fit-content',
      color: linkColor,
    },
  });
};

interface HeadingItem {
  headingTitle: string;
  blockId: string;
}

interface TableOfContentsComponentDataProps {
    title?: string;
    headings: HeadingItem[];
    dataId: string;
    defaultBlocksWidths: BlockWidths;
}

type TableOfContentsComponentProps = TableOfContentsComponentDataProps & MandatoryAdditionalStyle;

const getAnalyticsEventParams = (numberOfLinks: number) => ({
  category: 'Article',
  action: 'Table Of Contents clicked',
  label: `Number of links = ${numberOfLinks}`,

});

export const TableOfContents: React.FunctionComponent<TableOfContentsComponentProps> = props => {
  const { title, headings, dataId, defaultBlocksWidths, additionalStyle } = props;

  const sendTableOfContentsAnalytics = () => {
    const { category, action, label } = getAnalyticsEventParams(headings.length);
    window.mmClientApi = window.mmClientApi || [];
    window.mmClientApi.push('analytics', {
      event_category: category,
      event_action: action,
      event_label: label,
    });
  };

  const {
    tocTitleColor,
    tocTitleFontSizeLarge,
    tocTitleFontSizeMedium,
    tocTitleFontSizeSmall,
    tocLinkFontSizeLarge,
    tocLinkFontSizeMedium,
    tocLinkFontSizeSmall,
    paragraphLinkColor,
    fontSizesStyles,
  } = useTheme<ArticleBlockThemeProps>(componentConfigurationKey);

  const themeContextSectionHeader = useThemeOverride<SectionHeaderComponentThemeProps>({
    titleColor: tocTitleColor,
    titleBackgroundColor: 'transparent',
    showSquare: false,
    showBorderTop: false,
    showBorderBottom: false,
    showMiddleHorizontalLine: false,
  }, SectionHeaderConfigThemeKey);

  const { listStyle, linkStyle, liItem } = getStyles(paragraphLinkColor);
  const contentStyle = getStyleBySize(fontSizesStyles, { fontSizeSmall: tocLinkFontSizeSmall, fontSizeMedium: tocLinkFontSizeMedium, fontSizeLarge: tocLinkFontSizeLarge });
  const { category, action, label } = getAnalyticsEventParams(headings.length);

  return (
    <ErrorBoundary>
      <TableOfContentsWrapper defaultBlocksWidths={defaultBlocksWidths} additionalStyle={additionalStyle} numberOfHeadings={headings.length} dataId={dataId}>
        {title ? (
          <ThemeContext.Provider value={themeContextSectionHeader}>
            <SectionHeader moreButtonText={null} moreButtonSvg={null} paddingFactors={{ large: 0, medium: 0, small: 0 }}>
              <H3
                fontSizeLarge={tocTitleFontSizeLarge}
                fontSizeMedium={tocTitleFontSizeMedium}
                fontSizeSmall={tocTitleFontSizeSmall}
              >
                {title}
              </H3>
            </SectionHeader>
          </ThemeContext.Provider>
        ) : null}
        <ol className={css(listStyle)}>
          {headings.map(({ headingTitle, blockId }) => (
            <li className={css(liItem, contentStyle)} key={headingTitle}>
              <Link
                href={`#${blockId}`}
                className={css(linkStyle, contentStyle)}
                onClick={sendTableOfContentsAnalytics}
                data-vars-action={action}
                data-vars-category={category}
                data-vars-label={label}
                dangerouslySetInnerHTML={{ __html: headingTitle }}
              />
            </li>
          ))}
        </ol>
      </TableOfContentsWrapper>
    </ErrorBoundary>

  );
};
