import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useTheme } from '../../../theming/useTheme';
import { Image, ImageProps } from '../../partials/image/Image';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { Link } from '../../partials/link/Link';
import { bannerConfigThemeKey, BannerHeightThemeProps, BannerThemeProps } from './Banner.theme';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { Section } from '../articleLists/Section';

export interface BannerProps {
  image: ImageProps;
  linkPath?: string;
}

export const getBannerStyle = (
  isFullScreenWidth: boolean,
  hasLink: boolean,
  imageHeights: BannerHeightThemeProps,
) => {
  return StyleSheet.create({
    bannerWithLink: {
      width: '100%',
    },
    banner: {
      width: '100%',
      cursor: hasLink ? 'pointer' : 'unset',
      outline: 'none',
      border: 'none',
      boxSizing: 'border-box',
      [MEDIA_BREAKPOINTS.large]: {
        height: isFullScreenWidth ? `${imageHeights.imageHeightLarge}px` : 'initial',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        height: isFullScreenWidth ? `${imageHeights.imageHeightMedium}px` : 'initial',
      },
      [MEDIA_BREAKPOINTS.small]: {
        height: isFullScreenWidth ? `${imageHeights.imageHeightSmall}px` : 'initial',
      },
    },
  });
};

export const Banner: React.FunctionComponent<BannerProps> = ({ image, linkPath }) => {
  const { imageHeightCalculationMethods, imageWidths, aspectRatio } = image;
  const { isFullScreenWidth, imageHeightLarge, imageHeightMedium, imageHeightSmall } = useTheme<BannerThemeProps>(bannerConfigThemeKey);
  const imageHeights = { imageHeightLarge, imageHeightMedium, imageHeightSmall };
  const hasLink = !!linkPath;

  const bannerStyle = getBannerStyle(
    isFullScreenWidth,
    hasLink,
    imageHeights,
  );

  const imageAspectRatios = aspectRatio ? {
    large: aspectRatio,
    medium: aspectRatio,
    small: aspectRatio,
  } : undefined;

  const renderImage = () => {
    return (
      <div
        className={css(bannerStyle.banner)}
      >
        <Image
          {...image}
          imageHeightCalculationMethods={isFullScreenWidth ? {
            small: '100%_HEIGHT',
            medium: '100%_HEIGHT',
            large: '100%_HEIGHT',
          } : imageHeightCalculationMethods}
          imageWidths={imageWidths}
          imageAspectRatios={imageAspectRatios}
        />
      </div>
    );
  };

  const renderBanner = () => {
    return hasLink ? (
      <div className={css(bannerStyle.bannerWithLink)}>
        <Link href={linkPath}>
          {renderImage()}
        </Link>
      </div>
    ) : (
      <>
        {renderImage()}
      </>
    );
  };

  return (
    <ErrorBoundary>
      {
        !isFullScreenWidth ? (
          <Section>{renderBanner()}</Section>
        ) : (
          <>{renderBanner()}</>
        )
      }
    </ErrorBoundary>
  );
};
