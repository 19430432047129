import * as React from 'react';
import { getLCP, getFID, getCLS, getFCP, getTTFB, getINP, Metric } from 'web-vitals/base';
import { useSelector } from 'react-redux';
import { getEdition } from '../../store/config/config.selectors';
import { Kasda } from '../../Kasda';

const sendToGoogleAnalytics = ({ name, delta, id }: Metric) => {
  window.mmClientApi = window.mmClientApi || [];
  window.mmClientApi.push('analytics', {
    event_category: 'Web Vitals',
    event_action: name,
    event_label: id,
    event_value: Math.round(name === 'CLS' ? delta * 1000 : delta),
    non_interaction: true,
    transport: 'beacon',
  });
};

export const CoreWebVitals: React.FunctionComponent = () => {
  const property = useSelector(getEdition);
  React.useEffect(() => {
    try {
        getCLS(sendToGoogleAnalytics);
        getFID(sendToGoogleAnalytics);
        getLCP(sendToGoogleAnalytics);
        getFCP(sendToGoogleAnalytics);
        getTTFB(sendToGoogleAnalytics);
        getINP(sendToGoogleAnalytics);
    } catch (e) {
      console.error('CoreWebVitals', e);
    }
  }, [property]);
  return (
    <Kasda.Script>
      <script type="text/javascript">{'!function(){var e,t,n,i,r={passive:!0,capture:!0},a=new Date,o=function(){i=[],t=-1,e=null,f(addEventListener)},c=function(i,r){e||(e=r,t=i,n=new Date,f(removeEventListener),u())},u=function(){if(t>=0&&t<n-a){var r={entryType:"first-input",name:e.type,target:e.target,cancelable:e.cancelable,startTime:e.timeStamp,processingStart:e.timeStamp+t};i.forEach((function(e){e(r)})),i=[]}},s=function(e){if(e.cancelable){var t=(e.timeStamp>1e12?new Date:performance.now())-e.timeStamp;"pointerdown"==e.type?function(e,t){var n=function(){c(e,t),a()},i=function(){a()},a=function(){removeEventListener("pointerup",n,r),removeEventListener("pointercancel",i,r)};addEventListener("pointerup",n,r),addEventListener("pointercancel",i,r)}(t,e):c(t,e)}},f=function(e){["mousedown","keydown","touchstart","pointerdown"].forEach((function(t){return e(t,s,r)}))},p="hidden"===document.visibilityState?0:1/0;addEventListener("visibilitychange",(function e(t){"hidden"===document.visibilityState&&(p=t.timeStamp,removeEventListener("visibilitychange",e,!0))}),!0);o(),self.webVitals={firstInputPolyfill:function(e){i.push(e),u()},resetFirstInputPolyfill:o,get firstHiddenTime(){return p}}}();'}</script>
    </Kasda.Script>
  );
};
