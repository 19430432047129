import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { ALIGNMENT, ALIGNMENT_OPTIONS } from 'mm-theme-configuration/dist/consts';
import { PrimarySectionTitle } from './PrimarySectionTitle';
import { SecondarySectionTitle } from './SecondarySectionTitle';
import { DecorationLine } from '../../partials/DecorationLine';
import { baseUnit } from '../../../theming/baseUnitDefinition';
import { useTheme } from '../../../theming/useTheme';

const componentConfigurationKey = 'titleWithSubtitleAndDivider';

interface TitleWithSubtitleAndDividerProps {
  title: string | null;
  subtitle: string | null;
}

interface TitleWithSubtitleAndDividerThemeProps {
  textAlignment: ALIGNMENT;
}

const decorationLineHeight = 4;

const getTitlesWrapperStyle = (textAlignment: ALIGNMENT) => {
  return StyleSheet.create({
    style: {
      width: '100%',
      display: 'grid',
      gridRowGap: `${baseUnit * 0.5}px`,
      textAlign: textAlignment === ALIGNMENT_OPTIONS.CENTER ? 'center' : 'left',
    },
  }).style;
};

const getWrapperStyle = () => {
  return StyleSheet.create({
    style: {
      width: '100%',
      display: 'grid',
      gridRowGap: `${baseUnit}px`,
    },
  }).style;
};

export const TitleWithSubtitleAndDivider: React.FunctionComponent<TitleWithSubtitleAndDividerProps> = props => {
  const { title, subtitle } = props;
  const { textAlignment } = useTheme<TitleWithSubtitleAndDividerThemeProps>(componentConfigurationKey);
  const titlesWrapperStyle = getTitlesWrapperStyle(textAlignment);
  const wrapperStyle = getWrapperStyle();
  return (
    <div className={css(wrapperStyle)}>
      <div className={css(titlesWrapperStyle)}>
        {title ? <PrimarySectionTitle>{title}</PrimarySectionTitle> : null}
        {subtitle ? <SecondarySectionTitle>{subtitle}</SecondarySectionTitle> : null}
      </div>
      <DecorationLine height={decorationLineHeight} />
    </div>
  );
};
