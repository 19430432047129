
import { initAnalytics, StateAnalyticsParams } from './analytics/analytics';
import { initSessionId } from './init.utils';
import { markPerformance } from './performance';
import { ErrorReporter } from './errorReporter.type';

export type CoreParams = StateAnalyticsParams;

export function init(params: CoreParams, errorProvider: ErrorReporter) {
  try {
    initSessionId();
    initAnalytics(params);
    markPerformance('init-analytics');
  } catch (e) {
    errorProvider.reportException(e as Error);
  }
}
