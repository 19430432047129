import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { useTheme } from '../../../theming/useTheme';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { HeaderHeightsPerBreakpoint } from '../navigation/header/oldHeader/oldHeader.utils';
import { hex2rgba } from '../../../theming/theming.utils';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { AuthorPageThemeProps, componentConfigThemeKey } from './Author.theme';

interface AuthorTopGradientDataProps {
  headerHeights: HeaderHeightsPerBreakpoint;
}

const getOverlayHeight = (baseUnit: BaseUnit, imageHeight: number, menuHeight: number) => {
  return baseUnit * 2.5 + imageHeight / 2 + menuHeight;
};

const getStyles = (baseUnit: BaseUnit, color: string, headerHeights: HeaderHeightsPerBreakpoint) => StyleSheet.create({
  wrapper: {
    position: 'absolute',
    background: `linear-gradient(0deg, rgba(0,0,0,0) 0%, ${hex2rgba(color, 1)} 100%)`,
    top: 0,
    left: 0,
    right: 0,
    [MEDIA_BREAKPOINTS.large]: {
      height: `${getOverlayHeight(baseUnit, 160, headerHeights.large * baseUnit)}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      height: `${getOverlayHeight(baseUnit, 120, headerHeights.medium * baseUnit)}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      height: `${getOverlayHeight(baseUnit, 120, headerHeights.small * baseUnit)}px`,
    },
  },
});

export const AuthorTopGradient: React.FunctionComponent<AuthorTopGradientDataProps> = props => {
  const { headerHeights } = props;
  const { baseUnit, backgroundGradientColor } = useTheme<AuthorPageThemeProps>(componentConfigThemeKey);
  const styles = getStyles(baseUnit, backgroundGradientColor, headerHeights);

  return <ErrorBoundary><div className={css(styles.wrapper)} /></ErrorBoundary>;
};
