import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Thumbnail } from '../partials/image/image.types';
import { useTheme } from '../../theming/useTheme';
import { ErrorBoundary } from '../errorHandling/ErrorBoundary';
import { H5, semanticCSS } from '../../typography/semanticTags';
import { BaseUnit } from '../../theming/baseUnitDefinition';
import { Link } from '../partials/link/Link';
import { ArticleBlockThemeProps, componentConfigurationKey } from './ArticleBlock.theme';

export interface SponsorComponentDataProps {
  text: string;
  image?: Thumbnail;
  logo?: string;
  brandName?: string;
  href?: string;
}

const createSponsorStyle = (baseUnit: BaseUnit, textColor: string) => StyleSheet.create({
  logo: {
    height: '40px',
  },
  text: {
    color: textColor,
    marginBottom: `${0.5 * baseUnit}px`,
  },
  wrapper: {
    textAlign: 'center',
    margin: `${1 * baseUnit}px 0px`,
  },
});

export const Sponsor: React.FunctionComponent<SponsorComponentDataProps> = props => {
  const { text, logo, brandName, href } = props;
  const { sponsorTextColor, baseUnit } = useTheme<ArticleBlockThemeProps>(componentConfigurationKey);
  const style = createSponsorStyle(baseUnit, sponsorTextColor);

  return (
    <ErrorBoundary>
      <div className={css(style.wrapper)}>
        <H5 styles={semanticCSS(style.text)}>
          {text}
        </H5>
        <Link href={href} rel="nofollow sponsored">
          {logo ? <img className={css(style.logo)} alt={brandName} src={`data:image/svg+xml,${encodeURIComponent(logo)}`} /> : null }
        </Link>
      </div>
    </ErrorBoundary>
  );
};
