import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Colors } from 'mm-theme-configuration';
import { TYPOGRAPHY_SIZE } from 'mm-theme-configuration/dist/consts';
import { P } from '../../../typography/semanticTags';
import { Icon } from '../../partials/icon/Icon';
import { TriangleIcon } from '../../partials/icon/icons';
import { AdditionalStyleSheet } from '../../components.utils';
import { useTheme } from '../../../theming/useTheme';

interface DrawerHeaderProps {
  onClick: () => void;
  articlesNum: string;
}

const getStyles = (colors: Colors) => {
  return StyleSheet.create({
    wrapper: {
      width: '100%',
      height: '48px',
      backgroundColor: colors.secondary,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      position: 'absolute',
    },
    buttonWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '15px',
      cursor: 'pointer',
    },
    circle: {
      width: '5px',
      height: '5px',
      backgroundColor: '#EA0404',
      borderRadius: '50%',
      marginRight: '5px',
    },
    triangle: {
      width: '0',
      height: '0',
      borderTop: '5px solid transparent',
      borderBottom: '5px solid transparent',
      borderLeft: '5px solid black',
      marginLeft: '5px',
    },
  });
};

const iconStyle = StyleSheet.create({
  style: {
    marginLeft: '5px',
  },
}) as AdditionalStyleSheet;

export const DrawerHeader: React.FunctionComponent<DrawerHeaderProps> = props => {
  const { onClick, articlesNum = 0 } = props;
  const { colors }: { colors: Colors } = useTheme();

  const styles = getStyles(colors);
  const tinyFontSizes = {
    fontSizeSmall: TYPOGRAPHY_SIZE.TINY,
    fontSizeMedium: TYPOGRAPHY_SIZE.TINY,
    fontSizeLarge: TYPOGRAPHY_SIZE.TINY,
  };

  return (
    <div className={css(styles.wrapper)}>
      <button type="button" className={css(styles.buttonWrapper)} onClick={onClick}>
        <div className={css(styles.circle)} />
        <P {...tinyFontSizes}>
          {`${articlesNum} NEW ARTICLES`}
        </P>
        <Icon icon={TriangleIcon} hasButton={false} width={11} height={11} additionalStyle={iconStyle} />
      </button>
    </div>
  );
};
