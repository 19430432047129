import * as React from 'react';
import { OpenGraph, OpenGraphCommonProps, OpenGraphTypes } from './OpenGraph';
import { Kasda } from '../../Kasda/Kasda';

interface ArticleMetaTagsProps {
    createdAtISO: string;
    updatedAtISO: string;
    articleSection: string;
    tags: string[];
}

type OpenGraphArticleTagsProps = ArticleMetaTagsProps & OpenGraphCommonProps;

export const OpenGraphArticleTags: React.FunctionComponent<OpenGraphArticleTagsProps> = ({
  title,
  siteName,
  image,
  pageUrl,
  imageWidth,
  imageHeight,
  description,
  createdAtISO,
  updatedAtISO,
  articleSection,
  tags }) => {
  const articleTags = tags.map((tag: string, index: number) => <meta key={index} property="article:tag" content={tag} />);
  return (
    <>
      <OpenGraph
        siteName={siteName}
        image={image}
        title={title}
        pageUrl={pageUrl}
        imageHeight={imageHeight}
        imageWidth={imageWidth}
        description={description}
        type={OpenGraphTypes.Article}
      />
      <Kasda.Meta>
        <meta property="article:published_time" content={createdAtISO} />
        <meta property="article:modified_time" content={updatedAtISO} />
        {articleSection ? <meta property="article:section" content={articleSection} /> : null }
        {articleTags}
      </Kasda.Meta>
    </>
  );
};
