import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { TypographySize } from 'mm-theme-configuration/dist/consts';
import { H3 } from '../../../typography/semanticTags';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { useTheme } from '../../../theming/useTheme';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { createImageWidths } from '../../partials/image/image.utils';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import {
  HeadlineCard,
  HeadlineCardThemeProps,
  componentConfigThemeKey as headlineCardConfigThemeKey,
} from '../../partials/cards/headlineCard/HeadlineCard';
import {
  getLayoutComponentPadding,
  LayoutComponentBaseProps,
} from '../pageLayouts/layout.utils';
import { VerticalCard } from '../../partials/cards/verticalCard/VerticalCard';
import {
  VerticalCardThemeProps,
  componentConfigThemeKey as verticalCardConfigThemeKey,
} from '../../partials/cards/verticalCard/VerticalCard.theme';
import { TypographySizes } from '../../../typography/semanticTags/semanticTags.utils';
import { Style } from '../../components.utils';
import { baseUnit } from '../../../theming/baseUnitDefinition';

export const componentConfigThemeKey = 'oneVCNineHLC';

export interface OneVCNineHLCThemeProps {
  cardTitleColorTypeNormal: string;
  metadataColor: string;
  metadataColorHover: string;
  HLCTitleColor: string;
  HLCTitleColorHover: string;
  showDivider: boolean;
  HLCBackgroundColor: string;
  dividerColor: string;
  HLCTitleRowsNumber: number;
  HLCDisplayNameFontSizeLarge: TypographySize;
  HLCDisplayNameFontSizeMedium: TypographySize;
  HLCDisplayNameFontSizeSmall: TypographySize;
  HLCTitleFontSizeLarge: TypographySize;
  HLCTitleFontSizeMedium: TypographySize;
  HLCTitleFontSizeSmall: TypographySize;
  VCTitleFontSizeLarge: TypographySize;
  VCTitleFontSizeMedium: TypographySize;
  VCTitleFontSizeSmall: TypographySize;
  VCDescriptionFontSizeLarge: TypographySize;
  VCDescriptionFontSizeMedium: TypographySize;
  VCDescriptionFontSizeSmall: TypographySize;
  HLCLinkSpacing: number;
  HLCLinkContainerPaddingLarge: number;
  HLCLinkContainerPaddingMedium: number;
  HLCLinkContainerPaddingSmall: number;
  HLCBorderColor: string;
  HLCBorderWidth: number;
}

interface OneVCNineHLCDataProps extends LayoutComponentBaseProps {
  cards: Array<CardComponentDataProps>;
  displayName: string;
  useFormatMinutesHoursAgo?: boolean;
}

const createStyle = (
  HLCTitleColor: string,
  HLCBackgroundColor: string,
  dividerColor: string,
  HLCBorderColor: string,
  HLCLinkContainerPaddingLarge: number,
  HLCLinkContainerPaddingMedium: number,
  HLCLinkContainerPaddingSmall: number,
  HLCLinkSpacing: number,
  showDivider: boolean,
  HLCBorderWidth: number,
) => {
  return StyleSheet.create({
    wrapper: {
      display: 'grid',
      maxWidth: '1060px',
      [MEDIA_BREAKPOINTS.large]: {
        gridTemplateColumns: '2fr 1fr',
        gridColumnGap: `${HLCLinkSpacing * baseUnit}px`,
        padding: '0',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        padding: '0',
        gridRowGap: '20px',
      },
      [MEDIA_BREAKPOINTS.small]: {
        padding: '0',
        gridRowGap: '10px',
      },
    },
    hlcContainer: {
      border: `${HLCBorderWidth}px solid ${HLCBorderColor}`,
      [MEDIA_BREAKPOINTS.large]: {
        padding: `${HLCLinkContainerPaddingLarge * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        padding: `${HLCLinkContainerPaddingMedium * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        padding: `${HLCLinkContainerPaddingSmall * baseUnit}px`,
      },
      backgroundColor: HLCBackgroundColor,
      display: 'grid',
      rowGap: `${!showDivider ? HLCLinkSpacing * baseUnit : 0}px`,
    },
    hlcTitle: {
      color: HLCTitleColor,
      lineHeight: '28px !important',
      marginBottom: '10px',
    },
    divider: {
      height: '1px',
      width: '100%',
      border: 0,
      margin: `${(HLCLinkSpacing * baseUnit) / 2}px 0`,
      backgroundColor: dividerColor,
    },
    bottomWrapper: {
      gridTemplateRows: 'inherit',
    },
    cardWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
  });
};

const imageWidthsHC = createImageWidths(360, 540, 720);

export const OneVCNineHLC: React.FunctionComponent<OneVCNineHLCDataProps> = props => {
  const { cards, paddingFactors, displayName, useFormatMinutesHoursAgo } = props;
  const paddingStyle = getLayoutComponentPadding(paddingFactors);
  const themeProps = useTheme<OneVCNineHLCThemeProps>(componentConfigThemeKey);
  const {
    cardTitleColorTypeNormal,
    metadataColor,
    metadataColorHover,
    HLCTitleColor,
    showDivider,
    HLCBackgroundColor,
    dividerColor,
    HLCTitleFontSizeLarge,
    HLCTitleFontSizeMedium,
    HLCTitleFontSizeSmall,
    HLCTitleColorHover,
    HLCTitleRowsNumber,
    HLCDisplayNameFontSizeLarge,
    HLCDisplayNameFontSizeMedium,
    HLCDisplayNameFontSizeSmall,
    VCDescriptionFontSizeLarge,
    VCDescriptionFontSizeMedium,
    VCDescriptionFontSizeSmall,
    VCTitleFontSizeLarge,
    VCTitleFontSizeMedium,
    VCTitleFontSizeSmall,
    HLCLinkContainerPaddingLarge,
    HLCLinkContainerPaddingMedium,
    HLCLinkContainerPaddingSmall,
    HLCLinkSpacing,
    HLCBorderColor,
    HLCBorderWidth,
  } = themeProps;

  const style = createStyle(
    HLCTitleColor,
    HLCBackgroundColor,
    dividerColor,
    HLCBorderColor,
    HLCLinkContainerPaddingLarge,
    HLCLinkContainerPaddingMedium,
    HLCLinkContainerPaddingSmall,
    HLCLinkSpacing,
    showDivider,
    HLCBorderWidth,
  );
  const [firstCard] = cards || [];

  const themeContextHC = useThemeOverride<VerticalCardThemeProps>(
    {
      titleFontSizeLarge: VCTitleFontSizeLarge,
      titleFontSizeMedium: VCTitleFontSizeMedium,
      titleFontSizeSmall: VCTitleFontSizeSmall,
      descriptionFontSizeInLargeScreen: VCDescriptionFontSizeLarge,
      descriptionFontSizeInMediumScreen: VCDescriptionFontSizeMedium,
      descriptionFontSizeInSmallScreen: VCDescriptionFontSizeSmall,
      metadataFontSizeLarge: TypographySizes.TINY,
      metadataFontSizeMedium: TypographySizes.TINY,
      metadataFontSizeSmall: TypographySizes.TINY,
      cardTitleColorTypeNormal,
      borderRadius: 0,
      showDescriptionInLargeScreen: true,
      showDescriptionInMediumScreen: true,
      showDescriptionInSmallScreen: true,
      verticalGapLarge: 0.5,
      verticalGapMedium: 0.5,
      horizontalGapLarge: 0.5,
      horizontalGapMedium: 0.5,
      numberOfLinesForDescription: 10,
      numberOfLinesForTitle: 10,
      descriptionHorizontalGap: 0.5,
      boxShadowTypeHover: 'none',
      boxShadowTypeNormal: 'none',
      boxShadowTypeActive: 'none',
      isClickableLabels: true,
      dateColor: metadataColor,
      authorColor: metadataColor,
      authorColorHover: metadataColorHover,
      additionalBottomTextWrapperStyle: style.bottomWrapper as Style,
    },
    verticalCardConfigThemeKey,
  );

  const themeContextMinorCard = useThemeOverride<HeadlineCardThemeProps>(
    {
      titleFontSizeLarge: HLCTitleFontSizeLarge,
      titleFontSizeMedium: HLCTitleFontSizeMedium,
      titleFontSizeSmall: HLCTitleFontSizeSmall,
      cardTitleColorTypeHover: HLCTitleColorHover,
      showAuthorInLargeScreen: false,
      showAuthorInMediumScreen: false,
      showAuthorInSmallScreen: false,
      showDateInLargeScreen: false,
      showDateInMediumScreen: false,
      showDateInSmallScreen: false,
      cardBackgroundColor: HLCBackgroundColor,
      numberOfLinesForTitle: HLCTitleRowsNumber,
      wrapperGapLarge: 0,
      wrapperGapMedium: 0,
      wrapperGapSmall: 0,
    },
    headlineCardConfigThemeKey,
  );

  return (
    <ErrorBoundary>
      {cards && cards.length > 0 && (
        <div className={css(paddingStyle, style.wrapper)}>
          <ThemeContext.Provider value={themeContextHC}>
            <VerticalCard
              {...firstCard}
              imageWidths={imageWidthsHC}
              useFormatMinutesHoursAgo={useFormatMinutesHoursAgo}
            />
          </ThemeContext.Provider>
          <ThemeContext.Provider value={themeContextMinorCard}>
            <div className={css(style.hlcContainer)}>
              <H3
                styles={[style.hlcTitle]}
                fontSizeLarge={HLCDisplayNameFontSizeLarge}
                fontSizeSmall={HLCDisplayNameFontSizeMedium}
                fontSizeMedium={HLCDisplayNameFontSizeSmall}
              >
                {displayName}
              </H3>
              {cards.map((card, index) => {
                if (index === 0 || index > 9) {
                  return null;
                }
                return (
                  <div
                    key={`vcnine ${index}`}
                    className={css(style.cardWrapper)}
                  >
                    <HeadlineCard
                      {...card}
                      key={card.id}
                      useFormatMinutesHoursAgo={useFormatMinutesHoursAgo}
                    />
                    {index === 9 || !showDivider ? null : (
                      <hr className={css(style.divider)} />
                    )}
                  </div>
                );
              })}
            </div>
          </ThemeContext.Provider>
        </div>
      )}
    </ErrorBoundary>
  );
};
