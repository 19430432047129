import { CSSProperties } from 'aphrodite/no-important';

export const LIST_WRAPPER_STYLE: CSSProperties = {
  flex: '1',
  position: 'relative',
};

export const LIST_STYLE: CSSProperties = {
  position: 'absolute',
  top: '0',
  left: '0',
  bottom: '0',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  width: '200%', // we preserve width for two columns here to be able to hide wrapping children
  minWidth: '200%',
};

export const DEFAULT_LINE_CLAMP = 2;
