import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useTheme } from '../../../theming/useTheme';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { ArticleBlockThemeProps, componentConfigurationKey } from '../ArticleBlock.theme';

const createSwimsuitStyle = (backgroundColor: string) => StyleSheet.create({
  style: {
    width: '100%',
    height: '100%',
    backgroundColor,
  },
});

export const SwimsuitPlayer = () => {
  const { placeholderBackgroundColor } = useTheme<ArticleBlockThemeProps>(componentConfigurationKey);
  const SwimsuitStyle = createSwimsuitStyle(placeholderBackgroundColor);

  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://players.voltaxservices.io/players/6/code?mm-subId=30';
    script.type = 'text/javascript';
    script.defer = true;
    document.head.appendChild(script);
  }, []);

  return (
    <ErrorBoundary>
      <div className={`${css(SwimsuitStyle.style)} SwimsuitMMPlayer`} id="SwimsuitMMPlayer" />
    </ErrorBoundary>
  );
};
