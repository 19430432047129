import * as React from 'react';
import { StyleSheet } from 'aphrodite/no-important';
import { TYPOGRAPHY_SIZE, TypographySize } from 'mm-theme-configuration/dist/consts';
import { MEDIA_BREAKPOINTS } from '../../../../../../mediaQueries.const';
import { TextLinkComponent, generateLinkStyleFromTheme } from '../../../../../partials/link/TextLink';
import { Span, semanticCSS } from '../../../../../../typography/semanticTags';
import { notActiveLinkIdleColor } from '../oldHeader.utils';
import { baseUnit } from '../../../../../../theming/baseUnitDefinition';
import { TranslatedFontSize } from '../../../../../../theming/fontSizeTranslator';
import { getStyleBySize } from '../../../../../../typography/semanticTags/semanticTags.utils';
import { useTheme } from '../../../../../../theming/useTheme';
import { NavigationLink } from '../primaryHeader/PrimaryHeader';
import { componentConfigThemeKey, HeaderThemeProps } from '../../Header.theme';

interface SecondaryLinkDataProps {
  linksLength: number;
  index: number;
  isHoveredLink: boolean;
  pagePath: string;
  linkData: NavigationLink;
  maxVisibleLinks: number;
}

const getTextStyle = (isLastLink: boolean) => {
  return StyleSheet.create({
    link: {
      width: 'max-content',
      [MEDIA_BREAKPOINTS.medium]: {
        paddingRight: isLastLink ? `${baseUnit * 2}px` : 0,
      },
      [MEDIA_BREAKPOINTS.small]: {
        paddingRight: isLastLink ? `${baseUnit * 2}px` : 0,
      },
    },
  });
};

export const isLastLink = (index: number, linksLength: number) => index + 1 === linksLength;

const getContentStyle = (isActiveLink: boolean, fontSizesStyles: TranslatedFontSize, fontSizeLarge: TypographySize, fontSizeMedium: TypographySize, fontSizeSmall: TypographySize) => {
  const fontSizesActive = { fontSizeSmall: TYPOGRAPHY_SIZE.BIGGER, fontSizeMedium: TYPOGRAPHY_SIZE.BIGGER, fontSizeLarge: TYPOGRAPHY_SIZE.BIGGER };
  const fontSizesNotActive = { fontSizeSmall: fontSizeLarge, fontSizeMedium, fontSizeLarge: fontSizeSmall };
  return isActiveLink ? getStyleBySize(fontSizesStyles, fontSizesActive) : getStyleBySize(fontSizesStyles, fontSizesNotActive);
};

export const SecondaryLink: React.FunctionComponent<SecondaryLinkDataProps> = props => {
  const { linksLength, index, isHoveredLink, pagePath, linkData, maxVisibleLinks = 6 } = props;
  const { secondRowLinkColorTypeActive, secondRowHoverOtherLinksColors, secondRowLinkColorTypeNormal, secondRowLinkColorTypeHover, fontSizesStyles, secondRowLinkFontSizeLarge, secondRowLinkFontSizeMedium, secondRowLinkFontSizeSmall } = useTheme<HeaderThemeProps>(componentConfigThemeKey);
  const isActiveLink = linkData.link.href === pagePath;
  const calculatedIdleColor = isActiveLink ? secondRowLinkColorTypeActive : notActiveLinkIdleColor(isHoveredLink, secondRowHoverOtherLinksColors, secondRowLinkColorTypeNormal);
  const calculatedHoverColor = isActiveLink ? secondRowLinkColorTypeActive : secondRowLinkColorTypeHover;

  const linkStyleBasedOnScreen = linksLength > maxVisibleLinks && index >= maxVisibleLinks - 1
    ? {
      [MEDIA_BREAKPOINTS.large]: {
        display: 'none',
      },
      [MEDIA_BREAKPOINTS.small]: {
        display: 'inline-flex',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        display: 'inline-flex',
      },
    }
    : {};

  const linkStyle = generateLinkStyleFromTheme({ idleColor: calculatedIdleColor, activeStateColor: secondRowLinkColorTypeActive, hoverStateColor: calculatedHoverColor }, linkStyleBasedOnScreen);
  const style = getTextStyle(isLastLink(index, linksLength));
  const contentStyle = getContentStyle(isActiveLink, fontSizesStyles, secondRowLinkFontSizeLarge, secondRowLinkFontSizeMedium, secondRowLinkFontSizeSmall);

  return (
    <TextLinkComponent href={linkData.link.href} linkStyle={linkStyle}>
      <Span styles={semanticCSS(contentStyle, style.link)}>{linkData.link.text}</Span>
    </TextLinkComponent>
  );
};
