import {
  INJECT_ACCESSIBILITY_ICON,
  INJECT_VIDEO_LOGO,
  InjectAccessibilityIconAction,
  InjectVideoLogoAction,
  InjectVideoLogoParams,
} from '../../navigation.actions';
import { NavigationLink, Svg, SvgLink } from '../../navigation.utils';

type Link = {
  text: string;
  href: string;
};

export interface SubHeader {
  logoForLargeScreen: SvgLink | null;
  logoForSmallScreen: SvgLink | null;
  links: NavigationLink[];
  socialSection: SvgLink[] | null;
  moreButtonText: string;
  megaMenu: NavigationLink[];
}

export interface ThirdHeader {
  links: NavigationLink[];
  feedName: string;
}

export interface HeaderState {
  logo: SvgLink;
  links: NavigationLink[];
  editions: NavigationLink[] | null;
  editionsIcon: Svg | null;
  videoLogo?: InjectVideoLogoParams;
  socialSection: SvgLink[] | null;
  moreButtonText: string;
  subHeader: SubHeader | null;
  cta: Link | null;
  secondaryLogo: SvgLink | null;
  thirdHeader: ThirdHeader |null;
  latestArticles?: Array<any>;
}

export type HeaderAction = InjectVideoLogoAction | InjectAccessibilityIconAction;
export const headerReducer = (state: HeaderState = {} as HeaderState, action: HeaderAction) => {
  switch (action.type) {
    case INJECT_VIDEO_LOGO: {
      const { videoPath } = action;
      return {
        ...state,
        videoLogo: {
          videoPath,
        },
      };
    }
    case INJECT_ACCESSIBILITY_ICON: {
      return {
        ...state,
        showAccessibilityIcon: true,
      };
    }
    default:
      return state;
  }
};
