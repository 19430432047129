import { RATIO } from 'mm-theme-configuration/dist/consts';
import * as React from 'react';
import { createImageSrc, ImageWidth } from '../partials/image/image.utils';
import { PageImage } from './metaTags.utils';
import { Kasda } from '../../Kasda/Kasda';
import { getPublisherNameAndLogo } from './SISchemaUtils';

type AuthorsList = { name: string; link?: string }[];

interface GoogleNewsSchemaDataProps {
  canonical: string;
  title: string;
  image: PageImage;
  datePublished: string;
  authors: AuthorsList | null;
  publisherName: string;
  logoUrl: string;
  dateModified: string;
  seoDescription: string;
  articleSection: string;
  articleSchemaVideo?: Record<string, any>;
}

const dateToIsoDate = (date: string) => {
  return (new Date(date)).toISOString();
};

export const getAuthorsListSchema = (authors: AuthorsList) => {
  if (authors.length === 1) {
    return {
      '@type': 'Person',
      name: authors[0].name,
      ...authors[0].link ? { url: authors[0].link } : {},
    };
  }

  return authors.map(author => ({
    '@type': 'Person',
    name: author.name,
    ...author.link ? { url: author.link } : {},
  }));
};

const createImageObject = (image: PageImage, width: ImageWidth, height: number, ratio: RATIO) => {
  const { host, path, cropping = null } = image;
  return {
    '@type': 'ImageObject',
    url: createImageSrc(host, path, width, ratio, 1, cropping),
    width,
    height,
  };
};

const getSchemaImage = (image: PageImage) => {
  return [
    createImageObject(image, 1200, 1200, { x: 1, y: 1 }),
    createImageObject(image, 1200, 675, { x: 16, y: 9 }),
    createImageObject(image, 1200, 900, { x: 4, y: 3 }),
  ];
};

const getSchemaData = (
  canonical: string,
  title: string,
  image: PageImage,
  datePublished: string,
  publisherName: string,
  logoUrl: string,
  dateModified: string,
  seoDescription: string,
  articleSection: string,
  articleSchemaVideo?: Record<string, any>,
) => {
  const type = canonical?.includes('www.mentalfloss.com') ? 'Article' : 'NewsArticle'; // Test type change for mentalfloss seo improvement
  const {
    publisherName: calculatedPublisherName,
    logoUrl: calculatedLogoUrl,
    logoSize,
  } = getPublisherNameAndLogo(publisherName, logoUrl, canonical);

  return {
    '@context': 'http://schema.org',
    '@type': type,
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': canonical,
    },
    headline: title,
    image: getSchemaImage(image),
    datePublished: dateToIsoDate(datePublished),
    dateModified: dateToIsoDate(dateModified),
    publisher: {
      '@type': 'Organization',
      name: calculatedPublisherName,
      logo: {
        '@type': 'ImageObject',
        url: calculatedLogoUrl,
        ...logoSize ? { width: logoSize.width, height: logoSize.height } : {},
      },
    },
    description: seoDescription,
    articleSection,
    ...articleSchemaVideo ? { video: articleSchemaVideo } : {},
  };
};

export const GoogleNewsSchema: React.FunctionComponent<GoogleNewsSchemaDataProps> = props => {
  const { canonical, title, image, datePublished, authors, publisherName, logoUrl, dateModified, seoDescription, articleSection, articleSchemaVideo } = props;
  const schemaWithoutAuthors = getSchemaData(canonical, title, image, datePublished, publisherName, logoUrl, dateModified, seoDescription, articleSection, articleSchemaVideo);
  const schema = authors ? { ...schemaWithoutAuthors, author: getAuthorsListSchema(authors) } : schemaWithoutAuthors;
  return (
    <Kasda.Script>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Kasda.Script>
  );
};
