import * as React from 'react';
import { Helmet } from 'react-helmet';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useTheme } from '../../../theming/useTheme';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { AMPMenuEditions } from './AMPMenuEditions';
import { Span, semanticCSS } from '../../../typography/semanticTags';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { NavigationLink } from '../../components/navigation/header/oldHeader/primaryHeader/PrimaryHeader';
import { MenuLinkDataProps } from '../../components/navigation/header/oldHeader/primaryHeader/menu/links/MenuSubLink';
import { AMPSideBarLink } from './AMPSideBarLink';
import { componentConfigThemeKey, HeaderThemeProps } from '../../components/navigation/header/Header.theme';

/* eslint-disable react/no-unknown-property */
interface AmpSideBarDataProps {
  menu: MenuLinkDataProps[];
  editions: NavigationLink[] | null;
  editionEndpoint: string;
}

const getAmpSideBarStyle = (backgroundColor: string) => StyleSheet.create({
  wrapper: {
    backgroundColor,
    position: 'fixed',
    width: '100%',
    left: 0,
    overflowY: 'auto',
    flexDirection: 'column',
    [MEDIA_BREAKPOINTS.large]: {
      display: 'none',
    },
    [MEDIA_BREAKPOINTS.medium]: {
      display: 'inline-flex',
    },
    [MEDIA_BREAKPOINTS.small]: {
      display: 'inline-flex',
    },
  },
});

const getMenuIconStyle = (baseUnit: BaseUnit, menuIconColor: string) => StyleSheet.create({
  wrapper: {
    width: '16px',
    height: '16px',
    minHeight: '16px',
    position: 'relative',
    transition: '.5s ease-in-out',
    cursor: 'pointer',
    [MEDIA_BREAKPOINTS.medium]: {
      margin: `${baseUnit}px`,
      marginBottom: 0,
    },
    [MEDIA_BREAKPOINTS.small]: {
      margin: `${baseUnit}px`,
      marginBottom: 0,
    },
    ':focus': {
      outline: 'none',
    },
  },
  span: {
    display: 'block',
    position: 'absolute',
    height: '2px',
    width: '100%',
    backgroundColor: menuIconColor,
    opacity: 1,
    left: 0,
    transition: '.25s ease-in-out',
    transformOrigin: 'right center',
  },
  firstSpanOpen: {
    transform: 'rotate(-45deg)',
    width: '130%',
    left: '-6px',
    top: 0,
  },
  secondSpanOpen: {
    top: '7px',
    transform: 'scaleX(0.1)',
    opacity: 0,
  },
  thirdSpanOpen: {
    transform: 'rotate(45deg)',
    top: '14px',
    width: '130%',
    left: '-6px',
  },
});

const createAMPSideBarLink = (menuLink: MenuLinkDataProps, index: number) => {
  const { link, childrenLinks } = menuLink;
  return (
    <AMPSideBarLink link={link} childrenLinks={childrenLinks} key={index} />
  );
};

export const AMPSideBar: React.FunctionComponent<AmpSideBarDataProps> = props => {
  const { hamburgerMenuLinksBackgroundColor, hamburgerMenuDividerColor, hamburgerMenuAmpCloseIconColor, baseUnit } = useTheme<HeaderThemeProps>(componentConfigThemeKey);
  const { menu, editions, editionEndpoint } = props;
  const menuIconStyle = getMenuIconStyle(baseUnit, hamburgerMenuAmpCloseIconColor);
  const ampSideBarStyle = getAmpSideBarStyle(hamburgerMenuLinksBackgroundColor);
  return (
    // @ts-ignore TS2339: Property 'amp-sidebar' does not exist on type 'JSX.IntrinsicElements'.
    <ErrorBoundary>
      <Helmet>
        <script async={undefined} custom-element="amp-sidebar" src="https://cdn.ampproject.org/v0/amp-sidebar-0.1.js" />
        <script async={undefined} custom-element="amp-accordion" src="https://cdn.ampproject.org/v0/amp-accordion-0.1.js" />
      </Helmet>
      {/*
  // @ts-ignore TS2339: Property 'amp-sidebar' does not exist on type 'JSX.IntrinsicElements'. */}
      <amp-sidebar id="sidebar" layout="nodisplay" side="left" class={css(ampSideBarStyle.wrapper)}>
        {/*
  // @ts-ignore //eslint-disable-next-line react/no-unknown-property */}
        <div tabIndex="0" on="tap:sidebar.close,accordion.collapse,editions-accordion.collapse" role="button" className={css(menuIconStyle.wrapper)}>
          <Span styles={semanticCSS(menuIconStyle.span, menuIconStyle.firstSpanOpen)} />
          <Span styles={semanticCSS(menuIconStyle.span, menuIconStyle.secondSpanOpen)} />
          <Span styles={semanticCSS(menuIconStyle.span, menuIconStyle.thirdSpanOpen)} />
        </div>
        {/*
  // @ts-ignore TS2339: Property 'amp-accordion' does not exist on type 'JSX.IntrinsicElements'. */}
        <amp-accordion id="accordion" disable-session-states={undefined}>
          {menu.map(createAMPSideBarLink)}
          {/*
  // @ts-ignore TS2339: Property 'amp-accordion' does not exist on type 'JSX.IntrinsicElements'. */}
        </amp-accordion>
        {editions ? <AMPMenuEditions editionEndpoint={editionEndpoint} editions={editions} baseUnit={baseUnit} dividerColor={hamburgerMenuDividerColor} /> : null}
        {/*
  // @ts-ignore TS2339: Property 'amp-sidebar' does not exist on type 'JSX.IntrinsicElements' */}
      </amp-sidebar>
    </ErrorBoundary>
  );
};
