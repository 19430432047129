import * as React from 'react';
import {
  RATIO,
  TypographySize,
  SEMANTIC_TAG,
} from 'mm-theme-configuration/dist/consts';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { componentConfigThemeKey as horizontalCardConfigThemeKey, HorizontalCardThemeProps } from '../../partials/cards/horizontalCard/horizontalCard.theme';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { createImageWidths } from '../../partials/image/image.utils';
import { NineHC } from './NineHC';
import { useTheme } from '../../../theming/useTheme';

interface NineHCForOneThirdsDataProps {
    cards: Array<CardComponentDataProps>;
}
export const componentConfigThemeKey = 'nineHCForOneThird';

const imageWidths = createImageWidths(180, 180, 180);

export interface NineHCForOneThirdThemeProps {
  aspectRatioLarge: RATIO;
  aspectRatioMedium: RATIO;
  aspectRatioSmall: RATIO;
  titleFontSizeLarge: TypographySize;
  titleFontSizeMedium: TypographySize;
  titleFontSizeSmall: TypographySize;
  metadataFontSizeLarge: TypographySize;
  metadataFontSizeMedium: TypographySize;
  metadataFontSizeSmall: TypographySize;
  titleSemanticTag: SEMANTIC_TAG;
  metadataSemanticTag: SEMANTIC_TAG;
  pipeColor: string;
  showDescriptionInLargeScreen: boolean;
  showDescriptionInMediumScreen: boolean;
  showDescriptionInSmallScreen: boolean;
  verticalGapLarge: number;
  verticalGapMedium: number;
  verticalGapSmall: number;
  horizontalGapLarge: number;
  horizontalGapMedium: number;
  horizontalGapSmall: number;
}

export const NineHCForOneThird: React.FunctionComponent<NineHCForOneThirdsDataProps> = props => {
  const { cards } = props;
  const themeProps = useTheme<NineHCForOneThirdThemeProps>(componentConfigThemeKey);
  const themeContextHC = useThemeOverride<HorizontalCardThemeProps>(themeProps, horizontalCardConfigThemeKey);
  return (
    <ErrorBoundary>
      {cards && cards.length > 0
      && (
      <ThemeContext.Provider value={themeContextHC}>
        <NineHC cards={cards} paddingFactors={{ large: 0, medium: 0, small: 0 }} imageWidths={imageWidths} />
      </ThemeContext.Provider>
      )}
    </ErrorBoundary>
  );
};
