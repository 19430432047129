import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { injectScriptTagToHead, MandatoryAdditionalStyle } from '../../components.utils';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { BlockWidths } from '../../components/pageLayouts/BlockTypes.utils';
import { createBlockStyle } from '../blockMutualStyle';
import { Kasda } from '../../../Kasda/Kasda';

interface InstagramComponentDataProps {
  html: string;
  dataId?: string;
  defaultBlocksWidths: BlockWidths;
}

type InstagramComponentProps = InstagramComponentDataProps & MandatoryAdditionalStyle;
const getBlockStyle = (defaultBlocksWidths: BlockWidths) => {
  return StyleSheet.create({
    style: {
      ...createBlockStyle(defaultBlocksWidths),
    },
  }).style;
};

export const InstagramComponent: React.FunctionComponent<InstagramComponentProps> = props => {
  const { html, additionalStyle, dataId, defaultBlocksWidths } = props;

  const instagramStyle = getBlockStyle(defaultBlocksWidths);

  React.useEffect(() => {
    // @ts-ignore TS2339
    if (typeof window.instgrm !== 'undefined') {
      // @ts-ignore TS2339
      window.instgrm.Embeds.process();
    } else {
      injectScriptTagToHead('https://www.instagram.com/embed.js');
    }
  }, []);

  return (
    <ErrorBoundary>
      <Kasda.Script>
        <script async src="https://www.instagram.com/embed.js" />
      </Kasda.Script>
      {/* eslint-disable-next-line react/no-danger */}
      <figure data-mm-id={dataId} dangerouslySetInnerHTML={{ __html: html }} className={css(instagramStyle, additionalStyle.style)} />
    </ErrorBoundary>
  );
};

export const Instagram = (props: InstagramComponentProps) => <ErrorBoundary><InstagramComponent {...props} /></ErrorBoundary>;
