import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';

interface SeeAllArrowButtonProps {
  arrowColor: string;
  alt: string;
}

const createArrowStyles = () => StyleSheet.create({
  arrow: {
    [MEDIA_BREAKPOINTS.large]: {
      width: '20px',
      height: '20px',
    },
    [MEDIA_BREAKPOINTS.medium]: {
      width: '20px',
      height: '20px',
    },
    [MEDIA_BREAKPOINTS.small]: {
      width: '15px',
      height: '15px',
    },
  },
});

export const ArrowSvg: React.FunctionComponent<SeeAllArrowButtonProps> = props => {
  const { arrowColor, alt } = props;
  const arrowStyle = createArrowStyles();
  return (
    <ErrorBoundary>
      <svg
        className={css(arrowStyle.arrow)}
        aria-label={alt}
        viewBox="0 0 23 26"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g
            transform="translate(-975.000000, -785.000000)"
            stroke={arrowColor}
            strokeWidth="4"
          >
            <g transform="translate(80.000000, 760.000000)">
              <g
                transform="translate(905.000000, 38.000000) rotate(180.000000) translate(-905.000000, -38.000000) translate(895.000000, 28.000000)"
              >
                <line x1="20" y1="10" x2="0" y2="10" strokeLinejoin="bevel" />
                <polyline strokeLinecap="square" points="10 20 0 10 10 0" />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </ErrorBoundary>
  );
};
