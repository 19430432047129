import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { TypographySize } from 'mm-theme-configuration/dist/consts';
import { ellipsify } from '../../../../typography/ellipsify';
import { useTheme } from '../../../../theming/useTheme';
import { MEDIA_BREAKPOINTS } from '../../../../mediaQueries.const';
import { Link } from '../../link/Link';
import { H4, semanticCSS } from '../../../../typography/semanticTags';
import { BaseUnit } from '../../../../theming/baseUnitDefinition';
import { CardComponentDataProps } from '../../cards/cards.utils';

const createStyles = (
  borderColor: string,
  linksColor: string,
  linkHoverColor: string,
  baseUnit: BaseUnit,
) => StyleSheet.create({
  sectionItem: {
    padding: `${baseUnit * 0.5}px 0`,
    margin: `0 ${baseUnit * 0.5}px`,
    /*
      width is calculation of 50% - margins. The list is 200% width
      therefore to fit the visual container we set the item width to 50%
     */
    width: `calc(50% - ${baseUnit}px)`,
    borderTop: `1px solid ${borderColor}`,
    flex: '0 0 auto',
    boxSizing: 'border-box',
    position: 'relative',
    ':first-child': {
      borderTop: 'none',
    },
    ':last-child': {
      [MEDIA_BREAKPOINTS.small]: {
        display: 'none',
      },
    },
  },
  link: {
    textDecoration: 'none',
    boxSizing: 'border-box',
    color: linksColor,
    ':visited': {
      color: linksColor,
      textDecoration: 'none',
    },
    ':active': {
      color: linksColor,
      textDecoration: 'none',
    },
    [MEDIA_BREAKPOINTS.large]: {
      ':hover': {
        color: linkHoverColor,
        textDecoration: 'none',
      },
    },
    [MEDIA_BREAKPOINTS.medium]: {
      ':hover': {
        color: 'initial',
      },
    },
    [MEDIA_BREAKPOINTS.small]: {
      '-webkitLineClamp': '2',
      ':hover': {
        color: 'initial',
      },
    },
  },
});

export interface ListItemComponentThemeProps {
  borderColor: string;
  linkColor: string;
  linkHoverColor: string;
  fontSizeLarge: TypographySize;
  fontSizeMedium: TypographySize;
  fontSizeSmall: TypographySize;
  baseUnit: BaseUnit;
}

const componentConfigurationKey = 'listItem';

export const ListItem: React.FunctionComponent<CardComponentDataProps> = ({ title, articleUrl, numberOfLines = 2 }) => {
  const { borderColor, linkColor, linkHoverColor, fontSizeLarge, fontSizeMedium, fontSizeSmall, baseUnit } = useTheme<ListItemComponentThemeProps>(componentConfigurationKey);
  const styles = createStyles(borderColor, linkColor, linkHoverColor, baseUnit);
  // @ts-ignore TS2345
  const { style } = StyleSheet.create(ellipsify(numberOfLines));
  return (
    <Link
      href={articleUrl}
      className={css(
        styles.link,
        styles.sectionItem,
      )}
      key={articleUrl}
    >
      <H4 fontSizeLarge={fontSizeLarge} fontSizeMedium={fontSizeMedium} fontSizeSmall={fontSizeSmall} styles={semanticCSS(style)}>{title}</H4>
    </Link>
  );
};
