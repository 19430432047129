import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { MandatoryAdditionalStyle } from '../../components.utils';
import { useTheme } from '../../../theming/useTheme';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { BlockWidths } from '../../components/pageLayouts/BlockTypes.utils';
import { createBlockStyle } from '../blockMutualStyle';
import { ArticleBlockThemeProps, componentConfigurationKey } from '../ArticleBlock.theme';

interface IframeDataProps {
    html: string;
    dataId?: string;
    defaultBlocksWidths: BlockWidths;
}

type IframeComponentProps = IframeDataProps & MandatoryAdditionalStyle;
export type IframeProps = IframeDataProps & MandatoryAdditionalStyle;


const createIframeWrapperStyle = (placeholderBackgroundColor: string, defaultBlocksWidths: BlockWidths) => {
  return StyleSheet.create({
    figure: {
      backgroundColor: placeholderBackgroundColor,
    },
    wrapper: {
      ...createBlockStyle(defaultBlocksWidths),
    },
  });
};

export const Gfycat: React.FunctionComponent<IframeComponentProps> = props => {
  const { html, additionalStyle, dataId, defaultBlocksWidths } = props;
  const { placeholderBackgroundColor } = useTheme<ArticleBlockThemeProps>(componentConfigurationKey);
  const style = createIframeWrapperStyle(placeholderBackgroundColor, defaultBlocksWidths);
  return (
    <ErrorBoundary>
      <div className={css(additionalStyle.style, style.wrapper)}>
        {/* eslint-disable-next-line react/no-danger */}
        <figure data-mm-id={dataId} className={css(style.figure)} dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </ErrorBoundary>
  );
};
