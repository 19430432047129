import { StyleSheet } from 'aphrodite/no-important';

export const getLinkColorsStyle = (
  activeStateColor: string,
  hoverStateColor: string,
  idleColor: string,
  currentLinkColor: string,
  isActive: boolean,
) => {
  return StyleSheet.create({
    link: {
      color: isActive ? currentLinkColor : idleColor,
      textDecoration: 'none',
      '@media (hover: hover)': {
        ':hover': {
          color: hoverStateColor,
        },
      },
      ':active': {
        color: activeStateColor,
      },
    },
  });
};
