import { LinkButtonDataProps } from 'mm-ui-components/dist/src/base/components/navigation/utils';
import { MenuLinkDataProps } from 'mm-ui-components/dist/src/base/components/navigation/header/oldHeader/primaryHeader/menu/links/MenuSubLink';
import { SvgLink } from '../../navigation.utils';
import { INJECT_BUTTON_TO_MENU, InjectButtonToMenuAction } from '../../navigation.actions';

export interface MenuState {
  links: MenuLinkDataProps[];
  socialSection: {
    title: string;
    imageLinks: Array<SvgLink>;
  } | null;
  buttons?: Array<LinkButtonDataProps>;
}

export type MenuAction = InjectButtonToMenuAction;
export const menuReducer = (state: MenuState = {} as MenuState, action: MenuAction) => {
  switch (action.type) {
    case INJECT_BUTTON_TO_MENU: {
      const { text, onClick = null, cssClassName = null, attributes = null } = action;
      const { buttons } = state;
      return {
        ...state,
        buttons: [
          ...(buttons || []),
          {
            text,
            onClick,
            cssClassName,
            attributes,
          },
        ],
      };
    }
    default:
      return state;
  }
};
