import * as React from 'react';
import { css } from 'aphrodite/no-important';
import { Helmet } from 'react-helmet';
import { AMPMediaBaseProps } from './AMPMedia.utils';

export const AMPFacebook: React.FunctionComponent<AMPMediaBaseProps> = props => {
  const { mediaId, additionalStyle } = props;
  return (
    <React.Fragment>
      <Helmet>
        <script async={undefined} custom-element="amp-facebook" src="https://cdn.ampproject.org/v0/amp-facebook-0.1.js" />
      </Helmet>
      <figure className={css(additionalStyle.style)}>
        {/*
         // @ts-ignore TS2339 */}
        <amp-facebook width="552" height="303" layout="responsive" data-href={mediaId} />
      </figure>
    </React.Fragment>
  );
};
