import * as React from 'react';
import { Paragraph } from './Paragraph';
import { BlockWidths } from '../components/pageLayouts/BlockTypes.utils';
import { AdditionalStyleSheet } from '../components.utils';

interface FAQProps {
  dataId: string;
  question: string;
  answer: string;
  defaultBlocksWidths: BlockWidths;
  questionAdditionalStyle: AdditionalStyleSheet;
  answerAdditionalStyle: AdditionalStyleSheet;
}

export const FAQ = ({ question, answer, dataId, defaultBlocksWidths, questionAdditionalStyle, answerAdditionalStyle }: FAQProps) => {
  return (
    <div id={dataId}>
      <Paragraph html={`<h3>${question}</h3>`} dataId={dataId} defaultBlocksWidths={defaultBlocksWidths} additionalStyle={questionAdditionalStyle} />
      <Paragraph html={answer} dataId={dataId} defaultBlocksWidths={defaultBlocksWidths} additionalStyle={answerAdditionalStyle} />
    </div>
  );
};
