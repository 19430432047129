import * as React from 'react';
import { StyleSheet } from 'aphrodite/no-important';
import { TypographySize } from 'mm-theme-configuration/dist/consts';
import { useTheme } from '../../../theming/useTheme';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { H1, semanticCSS } from '../../../typography/semanticTags';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { Intro } from '../../articleComponents/Intro';

const componentConfigurationKey = 'articleTitleWithoutMetaData';

interface ArticleTitleThemeProps {
  titleColor: string;
  horizontalPaddingFactorSmall: number;
  horizontalPaddingFactorMedium: number;
  horizontalPaddingFactorLarge: number;
  fontSizeLarge: TypographySize;
  fontSizeMedium: TypographySize;
  fontSizeSmall: TypographySize;
  baseUnit: BaseUnit;
}

interface ArticleTitleDataProps {
  title: string;
  intro: string | null;
}

const createStyle = ({ titleColor, baseUnit, horizontalPaddingFactorLarge, horizontalPaddingFactorMedium, horizontalPaddingFactorSmall }: ArticleTitleThemeProps) => StyleSheet.create({
  wrapper: {
    position: 'relative',
  },
  title: {
    color: titleColor,
    marginTop: `${baseUnit}px`,
    marginBottom: `${baseUnit * 0.5}px`,
    [MEDIA_BREAKPOINTS.small]: {
      padding: `0 ${baseUnit * horizontalPaddingFactorSmall}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      padding: `0 ${baseUnit * horizontalPaddingFactorMedium}px`,
    },
    [MEDIA_BREAKPOINTS.large]: {
      padding: `0 ${baseUnit * horizontalPaddingFactorLarge}px`,
    },
  },
  sidesPadding: {
    marginTop: `${baseUnit * 0.5}px`,
    marginLeft: 0,
    marginRight: 0,
    [MEDIA_BREAKPOINTS.small]: {
      marginLeft: `${baseUnit}px`,
      marginRight: `${baseUnit}px`,
    },
  },
  intro: {
    [MEDIA_BREAKPOINTS.small]: {
      padding: `0 ${baseUnit * horizontalPaddingFactorSmall}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      padding: `0 ${baseUnit * horizontalPaddingFactorMedium}px`,
    },
    [MEDIA_BREAKPOINTS.large]: {
      padding: `0 ${baseUnit * horizontalPaddingFactorLarge}px`,
    },
  },
});

export const ArticleTitleWithoutMetaData: React.FunctionComponent<ArticleTitleDataProps> = props => {
  const { title, intro } = props;
  const themeProps = useTheme<ArticleTitleThemeProps>(componentConfigurationKey);
  const style = createStyle(themeProps);
  const { fontSizeLarge, fontSizeMedium, fontSizeSmall } = themeProps;

  return (
    <ErrorBoundary>
      <H1 fontSizeLarge={fontSizeLarge} fontSizeMedium={fontSizeMedium} fontSizeSmall={fontSizeSmall} styles={semanticCSS(style.title, style.sidesPadding)}>{title}</H1>
      {intro ? <Intro html={intro} styles={semanticCSS(style.intro, style.sidesPadding)} /> : null}
    </ErrorBoundary>
  );
};
