export const NAV_CONFIG = {
  GAME: {
    text: 'Game',
    param: 'game',
  },
  BOX_SCORE: {
    text: 'Box Score',
    param: 'boxScore',
  },
  PLAY_BY_PLAY: {
    text: 'Play by Play',
    param: 'playByPlay',
  },
  BETTING: {
    text: 'Betting',
    param: 'betting',
  },
  TICKETS: {
    text: 'Tickets',
    href: 'https://www.sitickets.com/',
  },
};

export const MATCH_STATUS_NAV: {[key: string]: any} = {
  post: [NAV_CONFIG.GAME, NAV_CONFIG.BOX_SCORE, NAV_CONFIG.PLAY_BY_PLAY],
  pre: [NAV_CONFIG.GAME, NAV_CONFIG.BETTING, NAV_CONFIG.TICKETS],
  live: [NAV_CONFIG.GAME, NAV_CONFIG.BOX_SCORE, NAV_CONFIG.PLAY_BY_PLAY],
  soccer_pre: [NAV_CONFIG.GAME, NAV_CONFIG.BETTING, NAV_CONFIG.TICKETS],
  soccer_post: [NAV_CONFIG.GAME, NAV_CONFIG.PLAY_BY_PLAY],
  soccer_live: [NAV_CONFIG.GAME, NAV_CONFIG.PLAY_BY_PLAY],
};

export const TICKER_LEAGUES: {[key: string]: number} = {
  soccer: 1,
  nba: 2,
  mlb: 3,
  nhl: 4,
  nfl: 5,
  ncaaf: 6,
  ncaab: 7,
  wnba: 2,
};
