import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { MEDIA_BREAKPOINTS, ContentWidth } from '../../../mediaQueries.const';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { baseUnit } from '../../../theming/baseUnitDefinition';

interface ContentWithoutSidebarProps {
  maxWidth: ContentWidth;
  mediumWidth?: number;
}

const horizontalGap = 2;

const getStyle = (maxWidth: ContentWidth, mediumWidth: number | undefined = 800) => {
  return StyleSheet.create({
    wrapper: {
      maxWidth: `${maxWidth}px`,
      margin: 'auto',
      display: 'grid',
      gridRowGap: `${horizontalGap * baseUnit}px`,
      [MEDIA_BREAKPOINTS.medium]: {
        maxWidth: `${mediumWidth}px`,
        width: '100%',
      },
      [MEDIA_BREAKPOINTS.small]: {
        width: '100%',
      },
    },
  });
};

export const ContentWithoutSidebar: React.FunctionComponent<ContentWithoutSidebarProps> = props => {
  const { children, maxWidth, mediumWidth } = props;
  const style = getStyle(maxWidth, mediumWidth);
  return <ErrorBoundary><div className={css(style.wrapper)}>{children}</div></ErrorBoundary>;
};
