import React from 'react';
import { StyleSheet } from 'aphrodite/no-important';
import { P, semanticCSS } from '../../../typography/semanticTags';
import { useTheme } from '../../../theming/useTheme';
import { AuthorPremiumThemeProps, componentConfigThemeKey } from './AuthorPremium.theme';
import { baseUnit } from '../../../theming/baseUnitDefinition';

const createBioStyle = (bioColor: string) => {
  return StyleSheet.create({
    style: {
      color: bioColor,
      maxWidth: '740px',
      marginTop: `${baseUnit}px`,
      textAlign: 'center',
      paddingLeft: `${baseUnit}px`,
      paddingRight: `${baseUnit}px`,
      whiteSpace: 'pre-line',
    },
  }).style;
};

export interface AuthorPremiumBioProps {
  bio: string;
}

export const AuthorPremiumBio = ({ bio }: AuthorPremiumBioProps) => {
  const { bioFontSizeLarge, bioFontSizeMedium, bioFontSizeSmall, bioColor } = useTheme<AuthorPremiumThemeProps>(componentConfigThemeKey);
  const bioStyle = createBioStyle(bioColor);
  return (
    <P
      fontSizeLarge={bioFontSizeLarge}
      fontSizeMedium={bioFontSizeMedium}
      fontSizeSmall={bioFontSizeSmall}
      styles={semanticCSS(bioStyle)}
    >
      {bio}
    </P>
  );
};
