import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { MandatoryAdditionalStyle } from '../../components.utils';
import { useTheme } from '../../../theming/useTheme';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { ArticleBlockThemeProps, componentConfigurationKey } from '../ArticleBlock.theme';
import { Kasda } from '../../../Kasda/Kasda';

export interface MMPlayerDataProps {
  html: string;
  version?: string;
  dataId?: string;
}

export type MMPlayerProps = MMPlayerDataProps & MandatoryAdditionalStyle;


const createMMPlayerWrapperStyle = (backgroundColor: string) => StyleSheet.create({
  style: {
    backgroundColor,
    paddingTop: `calc(100% / (${16 / 9}))`,
    position: 'relative',
    margin: 0,
  },
});

const createMMPlayerStyle = () => StyleSheet.create({
  style: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
});

const MINI_PLAYER_VERSION = 1.1;

const getScriptSrcByPlayerVersion = (version?: string) => {
  const versionAsNum = Number(version || '');
  const isMiniPlayer = !Number.isNaN(versionAsNum) && versionAsNum >= MINI_PLAYER_VERSION;

  return isMiniPlayer
    ? 'https://vms-players.minutemediaservices.com/voltax_player_embed_mount.js'
    : 'https://vms-players.minutemediaservices.com/mplayer_embed_mount.js';
};

export const MMPlayer: React.FunctionComponent<MMPlayerProps> = props => {
  const { html, version, additionalStyle, dataId } = props;
  const { placeholderBackgroundColor } = useTheme<ArticleBlockThemeProps>(componentConfigurationKey);
  const MMPlayerWrapperStyle = createMMPlayerWrapperStyle(placeholderBackgroundColor);
  const MMPlayerStyle = createMMPlayerStyle();
  const scriptSrc = getScriptSrcByPlayerVersion(version);

  return (
    <ErrorBoundary>
      <Kasda.Script>
        <script defer src={scriptSrc} />
      </Kasda.Script>
      <figure data-mm-id={dataId} className={css(additionalStyle.style)}>
        <div className={css(MMPlayerWrapperStyle.style)}>
          {/* eslint-disable-next-line react/no-danger */}
          <div className={css(MMPlayerStyle.style)} dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </figure>
    </ErrorBoundary>
  );
};
