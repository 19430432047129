import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useTheme } from '../../../theming/useTheme';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { Link } from '../../partials/link/Link';
import { SocialLink } from '../navigation/footer/AdditionalFooterSections/FooterSocialLinks';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { AuthorPageThemeProps, componentConfigThemeKey } from './Author.theme';

export interface SocialButton {
  link: string;
  src: string;
  alt: string;
}

interface OldHorizontalSocialButtonsWrapperDataProps {
  buttons: SocialButton[];
}

interface HorizontalSocialButtonsWrapperDataProps {
  buttons: SocialLink[];
}

const getStyle = (baseUnit: BaseUnit, backgroundColor: string) => StyleSheet.create({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  socialButton: {
    display: 'flex',
    width: '32px',
    height: '32px',
    borderRadius: '100%',
    overflow: 'hidden',
    backgroundColor,
    justifyContent: 'center',
    alignItems: 'center',
    margin: `0 ${baseUnit / 4}px`,
  },
});

const createSocialButtonStyle = (socialIconsNormalColor: string, socialIconsHoverColor: string) => {
  return StyleSheet.create({
    style: {
      height: '16px',
      fill: socialIconsNormalColor,
      ':hover': {
        fill: socialIconsHoverColor,
      },
    },
  }).style;
};

export const OldHorizontalSocialButtonsWrapper: React.FunctionComponent<OldHorizontalSocialButtonsWrapperDataProps> = props => {
  const { buttons } = props;
  const { baseUnit, socialIconsBackgroundColor } = useTheme<AuthorPageThemeProps>(componentConfigThemeKey);
  const style = getStyle(baseUnit, socialIconsBackgroundColor);
  return (
    <ErrorBoundary>
      <div className={css(style.wrapper)}>
        {
          buttons.map((button, index) => (
            <Link className={css(style.socialButton)} key={index} href={button.link}>
              <img src={button.src} alt={button.alt} />
            </Link>
          ))
        }
      </div>
    </ErrorBoundary>
  );
};

export const HorizontalSocialButtonsWrapper: React.FunctionComponent<HorizontalSocialButtonsWrapperDataProps> = props => {
  const { buttons } = props;
  const { socialIconsNormalColor, socialIconsHoverColor, socialIconsBackgroundColor, baseUnit } = useTheme<AuthorPageThemeProps>(componentConfigThemeKey);
  const style = getStyle(baseUnit, socialIconsBackgroundColor);
  const socialButtonStyle = createSocialButtonStyle(socialIconsNormalColor, socialIconsHoverColor);
  return (
    <ErrorBoundary>
      <div className={css(style.wrapper)}>
        {buttons.map((link, index) => {
          const svg = link.src.replace(/<svg/g, `<svg class="${css(socialButtonStyle)}"`);
          return (
            <Link
              href={link.href}
              key={index}
              rel="noopener"
              target="_blank"
              dangerouslySetInnerHTML={{ __html: svg }}
              alt={link.alt}
              className={css(style.socialButton)}
            />
          );
        })}
      </div>
    </ErrorBoundary>
  );
};
