import { predicates } from '../predicates/index';
import { selectors } from '../selectors/index';
import { TRAFFIC_SOURCES } from '../constants';

export const referral = {
  rule: predicates.referrerExists(),
  extractor: (traffic) => {
    const trafficSource = TRAFFIC_SOURCES.REFERRAL;
    const trafficId = selectors.referrerHostname(traffic);

    return {
      trafficSource,
      trafficId,
    };
  },
};
