type EventHandler = (params: any) => void;

declare global {
    interface Window {
      __mmClientApiSubscriptions__?: Map<string, any>;
    }
}

export const setEventEmitter = (eventName: string, eventHandler: EventHandler) => {
  if (window.__mmClientApiSubscriptions__ && window.__mmClientApiSubscriptions__.get(eventName)) {
    window.__mmClientApiSubscriptions__.get(eventName).forEach((params: any) => eventHandler(params));
  }
  if (window.__mmClientApiSubscriptions__) {
    window.__mmClientApiSubscriptions__.set(eventName, {
      push: (params: any) => eventHandler(params),
    });
  } else {
    window.__mmClientApiSubscriptions__ = new Map();
    window.__mmClientApiSubscriptions__.set(eventName, {
      push: (params: any) => eventHandler(params),
    });
  }
};
