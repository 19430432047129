export interface HeaderHeightsPerBreakpoint {
  large: number;
  medium: number;
  small: number;
}

export const reducedHeaderFirstRowHeightFactorsForOldHeader = {
  large: (1 / 2),
  medium: (2 / 3),
  small: (2 / 3),
};

export const reducedLogoHeightFactor = 0.5;

export const notActiveLinkIdleColor = (isHoveredLink: boolean, hoverOtherLinksColors: string, idleColor: string) => (
  isHoveredLink ? hoverOtherLinksColors : idleColor
);

export const getDoubleHeaderHeights = (large: number, medium: number, small: number) => ({
  small,
  medium,
  large,
});
