/* eslint no-underscore-dangle: [1, { "allowAfterThis": true }] */
import * as React from 'react';
import { StyleSheet } from 'aphrodite/no-important';
import { globalExtension, GLOBALS } from '../../utils/aphroditeGlobalExtension';
import { normalizeCss } from './appContent.utils';
import { MobileTopBarColor } from '../metaHeaderTags/MobileTopBarColor';

const extended = StyleSheet.extend([globalExtension]);

// This code causes aphrodite to ignore its caching system, and to create a new style object with every render
// The code is necessary due to a bug in aphrodite - the extended stylesheet seems to have issues with multiple re-renders
const potentialMemoryLeak = (prefix: string): string => {
  return prefix + Math.random().toString(36).substring(7);
};

export const AppContent: React.FunctionComponent = props => {
  const normalizedCss = normalizeCss() as { [key: string]: any };
  const combinedStyles = Object.keys(normalizedCss).reduce((acc: { [key: string]: any }, key: string) => {
    return {
      ...acc,
      [key]: {
        ...acc[key],
        ...normalizedCss[key],
      },
    };
  }, {});

  const styles: { [GLOBALS]: any } = extended.StyleSheet.create({
    [GLOBALS]: combinedStyles,
  });

  // eslint-disable-next-line no-underscore-dangle
  if (!styles[GLOBALS]._name) {
    // eslint-disable-next-line no-underscore-dangle
    styles[GLOBALS]._name = potentialMemoryLeak(GLOBALS);
  }

  try {
    extended.css(styles[GLOBALS]);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }

  const { children } = props;
  return (
    <React.Fragment>
      <MobileTopBarColor />
      {children}
    </React.Fragment>
  );
};
