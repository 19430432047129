import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useTheme } from '../../../theming/useTheme';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { BottomPageRecommendationsThemeProps, componentConfigurationKey } from './BottomPageRecommendations.theme';


export const getRecommendationCardPlaceHolderStyle = (baseUnit: BaseUnit, backgroundColor: string) => StyleSheet.create({
  cardPlaceHolderWrapper: {
    width: '100%',
    marginTop: `${baseUnit * 0.5}px`,
  },
  cardPlaceHolder: {
    paddingTop: 'calc(100% / (16 / 9))',
    backgroundColor,
  },
});

export const RecommendationCardPlaceHolder: React.FunctionComponent = () => {
  const { baseUnit, placeholderBackgroundColor } = useTheme<BottomPageRecommendationsThemeProps>(componentConfigurationKey);
  const style = getRecommendationCardPlaceHolderStyle(baseUnit, placeholderBackgroundColor);
  return (
    <ErrorBoundary>
      <div className={css(style.cardPlaceHolderWrapper)}>
        <div className={css(style.cardPlaceHolder)} />
      </div>
    </ErrorBoundary>
  );
};
