import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { MEDIA_BREAKPOINTS } from '../../../../../mediaQueries.const';
import { Link } from '../../../../partials/link/Link';
import { baseUnit } from '../../../../../theming/baseUnitDefinition';
import { AnimationDirection, AnimationDirectionType } from '../menuAnimations.util';
import { HeaderHeightsPerBreakpoint, reducedLogoHeightFactor } from './oldHeader.utils';

export interface LogoProps {
    svgLarge: string;
    svgSmall: string;
    svgLargeViewBox: string;
    svgSmallViewBox: string;
    alt: string;
    href: string;
    logoHeights?: HeaderHeightsPerBreakpoint;
    menuAnimation?: AnimationDirectionType;
    reduceHeightAnimationDuration?: number;
}

const defaultLogoHeights = ({ large: 24, medium: 24, small: 24 });

const getLogoStyle = (logoHeights: HeaderHeightsPerBreakpoint, menuAnimation: AnimationDirectionType, reduceHeightAnimationDuration: number) => {
  return StyleSheet.create({
    base: {
      [MEDIA_BREAKPOINTS.large]: {
        height: menuAnimation === AnimationDirection.UP ? `${logoHeights.large * baseUnit * reducedLogoHeightFactor}px` : `${logoHeights.large * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.medium]: {
        height: `${logoHeights.medium * baseUnit}px`,
      },
      [MEDIA_BREAKPOINTS.small]: {
        height: `${logoHeights.small * baseUnit}px`,
      },
      alignItems: 'center',
      paddingRight: `${baseUnit}px`,
      transition: `${reduceHeightAnimationDuration}ms`,
    },
    largeSvg: {
      [MEDIA_BREAKPOINTS.large]: {
        display: 'inline-flex',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        display: 'none',
      },
      [MEDIA_BREAKPOINTS.small]: {
        display: 'none',
      },
    },
    smallSvg: {
      [MEDIA_BREAKPOINTS.large]: {
        display: 'none',
      },
      [MEDIA_BREAKPOINTS.medium]: {
        display: 'inline-flex',
      },
      [MEDIA_BREAKPOINTS.small]: {
        display: 'inline-flex',
      },
    },
    image: {
      height: '100%',
    },
  });
};

const getImageResource = (svg: string) => {
  return `data:image/svg+xml,${encodeURIComponent(svg)}`;
};

export const Logo: React.FunctionComponent<LogoProps> = props => {
  const { href, alt, svgLarge, svgSmall, logoHeights = defaultLogoHeights, menuAnimation = AnimationDirection.NONE, reduceHeightAnimationDuration = 0 } = props;
  const style = getLogoStyle(logoHeights, menuAnimation, reduceHeightAnimationDuration);

  return (
    <React.Fragment>
      <Link className={css(style.largeSvg, style.base)} href={href}>
        <img className={css(style.image)} alt={alt} src={getImageResource(svgLarge)} />
      </Link>
      <Link className={css(style.smallSvg, style.base)} href={href}>
        <img className={css(style.image)} alt={alt} src={getImageResource(svgSmall)} />
      </Link>
    </React.Fragment>
  );
};
