import * as React from 'react';
import { Helmet } from 'react-helmet';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { AMPComscore } from './AMPComscore';
import { AMPConfigAnalytics } from './AMPConfigAnalytics';
import { AMPAmplitude } from './AMPAmplitude';
import { AMPConsent } from '../AMPConsent';

interface AMPAnalyticsProps {
  analyticsConfigURL: string;
  owner: { name: string };
  articleTitle: string;
  articlePublicationTime: string;
  contentTags: Array<string>;
  articleIntro: boolean;
  siteName: string;
  property: string;
  markupFormat?: string;
  language: string;
  articleId: string;
  articleTemplate: Array<string>;
  distributionChannels: Array<string>;
  mainMedia: string;
  commercialTags: Array<string> | null;
  comscoreId: string;
  articleWordCount: number;
  articleContentCount: { [key: string]: number };
  ownerUsername: string;
  experiments?: Array<string>;
  isSlideshow: boolean;
  amplitudeApiKey?: string;
  mainCategoryEmbed: boolean;
  topic?: string |null;
}

export const AMPAnalytics: React.FunctionComponent<AMPAnalyticsProps> = props => {
  const { comscoreId, analyticsConfigURL, owner, articleId, articlePublicationTime, articleTemplate, articleTitle, articleIntro, contentTags, distributionChannels, mainMedia, language, markupFormat = 'web', commercialTags, articleContentCount, articleWordCount, siteName, property, ownerUsername, experiments = [], isSlideshow, amplitudeApiKey, mainCategoryEmbed, topic } = props;
  const { name: ownerName } = owner;
  return (
    <ErrorBoundary>
      <Helmet>
        <script
          async={undefined}
          custom-element="amp-analytics"
          src="https://cdn.ampproject.org/v0/amp-analytics-0.1.js"
        />
      </Helmet>
      <AMPConfigAnalytics
        analyticsConfigURL={analyticsConfigURL}
        siteName={siteName}
        property={property}
        articleAuthor={ownerName}
        articleId={articleId}
        articlePublicationTime={articlePublicationTime}
        articleTemplate={articleTemplate}
        articleTitle={articleTitle}
        contentTags={contentTags}
        distributionChannels={distributionChannels}
        mainMedia={mainMedia}
        language={language}
        markupFormat={markupFormat}
        commercialTags={commercialTags}
        articleWordCount={articleWordCount}
        articleContentCount={articleContentCount}
        ownerUsername={ownerUsername}
        experiments={experiments}
        isSlideshow={isSlideshow}
        mainCategoryEmbed={mainCategoryEmbed}
        topic={topic}
        articleIntro={articleIntro}
      />
      <AMPConsent />
      {amplitudeApiKey ? <AMPAmplitude amplitudeApiKey={amplitudeApiKey} /> : null}
      <AMPComscore comscoreId={comscoreId} />
    </ErrorBoundary>
  );
};
