import { and } from './and';
import { not } from './not';
import { or } from './or';
import { pathnameIncludesAfterKey } from './pathnameIncludesAfterKey';
import { pathnameIncludesKey } from './pathnameIncludesKey';
import { referrerExists } from './referrerExists';
import { referrerIncludesPlatform } from './referrerIncludesPlatform';
import { referrerIsSameAsSite } from './referrerIsSameAsSite';
import { searchParamEqualsValue } from './searchParamEqualsValue';
import { searchParamsIncludeKey } from './searchParamsIncludeKey';
import { searchParamIncludesValue } from './searchParamIncludesValue';

export const predicates = {
  and,
  not,
  or,
  pathnameIncludesAfterKey,
  pathnameIncludesKey,
  referrerExists,
  referrerIncludesPlatform,
  referrerIsSameAsSite,
  searchParamEqualsValue,
  searchParamsIncludeKey,
  searchParamIncludesValue,
};
