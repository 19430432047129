import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useTheme } from '../../../theming/useTheme';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { componentConfigurationKey, SectionDividerThemeProps } from './utils';

interface SectionDividerDataProps {
    iconComponent?: React.FunctionComponent;
}

const getStyles = (lineColor: string) => {
  return StyleSheet.create({
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      width: '-webkit-fill-available',
    },
    line: {
      height: '1px',
      width: '100%',
      border: 0,
      margin: 0,
      backgroundColor: lineColor,
    },
  });
};

export const SectionDividerWithIcon: React.FunctionComponent<SectionDividerDataProps> = props => {
  const { dividerLineColor, dividerShowIcon } = useTheme<SectionDividerThemeProps>(componentConfigurationKey);
  const { iconComponent } = props;
  const styles = getStyles(dividerLineColor);
  const IconComponent = iconComponent;
  return (
    <ErrorBoundary>
      <div className={css(styles.wrapper)}>
        {dividerShowIcon && !!IconComponent ? (
          <React.Fragment>
            <hr className={css(styles.line)} />
            <IconComponent />
            <hr className={css(styles.line)} />
          </React.Fragment>
        )
          : <hr className={css(styles.line)} />}
      </div>
    </ErrorBoundary>
  );
};
