import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { BaseUnit } from '../../../../theming/baseUnitDefinition';
import { useTheme } from '../../../../theming/useTheme';

export enum Directions {
  UP = '180deg',
  DOWN = '0deg',
  LEFT = '90deg',
  RIGHT = '270deg',
}

interface TriangleIconProps {
  direction?: Directions;
  color?: string;
  hoverColor?: string;
  isHovering?: boolean;
}

const getStyle = (direction: Directions, baseUnit: BaseUnit) => StyleSheet.create({
  caret: {
    width: '10px',
    transform: `rotate(${direction})`,
    transition: 'transform 400ms',
    marginLeft: `${baseUnit * 0.25}px`,
  },
});

const getFillColor = (color: string, hoverColor = '', isHovering = false) => {
  return isHovering ? hoverColor : color;
};

export const TriangleIcon: React.FunctionComponent<TriangleIconProps> = props => {
  const { direction = Directions.DOWN, color = '#FFF', hoverColor, isHovering } = props;
  const { baseUnit } = useTheme();
  const fillColor = getFillColor(color, hoverColor, isHovering);

  return (
    <svg className={css(getStyle(direction, baseUnit).caret)} width="64" height="10px" viewBox="0 0 64 64" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <polygon fill={fillColor} points="0 16 32 48 64 16" />
      </g>
    </svg>
  );
};
