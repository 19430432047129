import { predicates } from '../predicates/index';
import { selectors } from '../selectors/index';
import { SOCIAL_PLATFORMS, TRAFFIC_SOURCES } from '../constants';

export const social = {
  rule: predicates.or(
    ...Object.values(SOCIAL_PLATFORMS).map(platform => predicates.referrerIncludesPlatform(platform)),
    predicates.searchParamEqualsValue('utm_medium', 'fan_page'),
  ),
  extractor: (traffic) => {
    const trafficSource = TRAFFIC_SOURCES.SOCIAL;
    const trafficId = selectors.referrerHostname(traffic);

    return {
      trafficSource,
      trafficId,
    };
  },
};
