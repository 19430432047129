import { RATIO, TypographySize } from 'mm-theme-configuration/dist/consts';
import { TranslatedFontSize } from '../../../theming/fontSizeTranslator';

export const imageLinkConfigThemeKey = 'linkImage';
export const fourImageLinkConfigThemeKey = 'fourLinkImageLayout';
export const scrollableLinkImagesConfigThemeKey = 'scrollableLinkImages';
export interface LinkImageThemeProps {
  hasText: boolean;
  textColor: string;
  textColorHover: string;
  fontSizeLarge: TypographySize;
  fontSizeMedium: TypographySize;
  fontSizeSmall: TypographySize;
  fontSizesStyles: TranslatedFontSize;
  aspectRatioLarge: RATIO;
  aspectRatioMedium: RATIO;
  aspectRatioSmall: RATIO;
}
