import * as React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { RATIO, TYPOGRAPHY_SIZE, TypographySize } from 'mm-theme-configuration/dist/consts';
import { Colors } from 'mm-theme-configuration';
import { LayoutComponentBaseProps } from '../pageLayouts/layout.utils';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { HorizontalCard } from '../../partials/cards/horizontalCard/HorizontalCard';
import {
  componentConfigThemeKey as onTopCardConfigThemeKey,
  OnTopCard,
  OnTopCardComponentThemeProps,
} from '../../partials/cards/onTopCard/OnTopCard';
import {
  createImageWidths,
} from '../../partials/image/image.utils';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import {
  componentConfigThemeKey as horizontalCardConfigThemeKey,
  HorizontalCardThemeProps,
} from '../../partials/cards/horizontalCard/horizontalCard.theme';
import { useTheme } from '../../../theming/useTheme';

const createStyle = () => {
  return StyleSheet.create({
    wrapper: {
      padding: '0 10px',
    },
    threeHcWrapper: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3,1fr)',
      gridRowGap: '20px',
      gridColumnGap: '10px',
      margin: '20px 0',
      [MEDIA_BREAKPOINTS.small]: {
        gridRowGap: '15px',
        gridTemplateColumns: '1fr',

      },
    },
  });
};


interface OneOTCThreeHCDataProps extends LayoutComponentBaseProps {
    cards: Array<CardComponentDataProps>;
}

const imageWidthsForMainCard = createImageWidths(360, 720, 1080);
const imageWidthsForMinorCard = createImageWidths(180, 50, 180);

export interface OneOTCThreeHCHorizontalThemeProps {
  largeHorizontalGap: number;
  mediumHorizontalGap: number;
  smallHorizontalGap: number;
  titleFontSizeLargeForHorizontalCard: TypographySize;
  titleFontSizeMediumForHorizontalCard: TypographySize;
  titleFontSizeSmallForHorizontalCard: TypographySize;
  titleFontSizeLargeForOnTopCard: TypographySize;
  metadataFontSizeSmallForOnTopCard: TypographySize;
  mainCategoryFontSizeSmallForOnTopCard: TypographySize;
  verticalGapLargeForOnTopCard: number;
  horizontalGapLargeForOnTopCard: number;
  verticalGapMediumForOnTopCard: number;
  horizontalGapMediumForOnTopCard: number;
  metadataFontSizeLargeForHorizontalCard: TypographySize;
  metadataFontSizeMediumForHorizontalCard: TypographySize;
  metadataFontSizeSmallForHorizontalCard: TypographySize;
  mainCategoryFontSizeSmallForHorizontalCard: TypographySize;
  verticalGapLargeForHorizontalCard: number;
  verticalGapMediumForHorizontalCard: number;
  verticalGapSmallForHorizontalCard: number;
  horizontalGapLargeForHorizontalCard: number;
  horizontalGapMediumForHorizontalCard: number;
  aspectRatioSmallForHorizontalCard: RATIO;
  aspectRatioMediumForHorizontalCard: RATIO;
  aspectRatioLargeForHorizontalCard: RATIO;
  horizontalCardProportionsLarge: RATIO;
  horizontalCardProportionsMedium: RATIO;
  horizontalCardProportionsSmall: RATIO;
  showAuthorInLargeScreen: boolean;
  showAuthorInMediumScreen: boolean;
  showAuthorInSmallScreen: boolean;
  showDescriptionInLargeScreen: boolean;
  showDescriptionInMediumScreen: boolean;
  showDescriptionInSmallScreen: boolean;
  showDateInLargeScreen: boolean;
  showDateInMediumScreen: boolean;
  showDateInSmallScreen: boolean;
  dividerColor: string;
  dividerWidth: number;
  showDividersInLargeScreen: boolean;
  showDividersInMediumScreen: boolean;
  showDividersInSmallScreen: boolean;
}

export const OneOTCThreeHCHorizontal: React.FunctionComponent<OneOTCThreeHCDataProps> = props => {
  const { colors }: {colors: Colors} = useTheme();
  const style = createStyle();
  const { cards } = props;

  const themeContextOTC = useThemeOverride<OnTopCardComponentThemeProps>({
    titleFontSizeSmall: TYPOGRAPHY_SIZE.LARGE,
    titleFontSizeLarge: TYPOGRAPHY_SIZE.LARGE,
    titleFontSizeMedium: TYPOGRAPHY_SIZE.LARGE,
    metadataFontSizeLarge: TYPOGRAPHY_SIZE.TINY,
    metadataFontSizeMedium: TYPOGRAPHY_SIZE.TINY,
    metadataFontSizeSmall: TYPOGRAPHY_SIZE.TINY,
    authorColor: colors.white,
    mainCategoryTextColor: colors.white,
    mainCategoryFontSizeLarge: TYPOGRAPHY_SIZE.TINY,
    mainCategoryFontSizeMedium: TYPOGRAPHY_SIZE.TINY,
    mainCategoryFontSizeSmall: TYPOGRAPHY_SIZE.TINY,
    showMainCategoryInSmallScreen: true,
    showMainCategoryInMediumScreen: true,
    showMainCategoryInLargeScreen: true,
  }, onTopCardConfigThemeKey);

  const themeContextHC = useThemeOverride<HorizontalCardThemeProps>({
    titleFontSizeLarge: TYPOGRAPHY_SIZE.BIG,
    titleFontSizeMedium: TYPOGRAPHY_SIZE.BIG,
    titleFontSizeSmall: TYPOGRAPHY_SIZE.BIG,
    mainCategoryFontSizeLarge: TYPOGRAPHY_SIZE.TINY,
    mainCategoryFontSizeMedium: TYPOGRAPHY_SIZE.TINY,
    mainCategoryFontSizeSmall: TYPOGRAPHY_SIZE.TINY,
    showMainCategoryInSmallScreen: true,
    showMainCategoryInMediumScreen: true,
    showMainCategoryInLargeScreen: true,
    aspectRatioSmall: { x: 1, y: 1 },
    aspectRatioMedium: { x: 1, y: 1 },
    aspectRatioLarge: { x: 1, y: 1 },
    showAuthorInLargeScreen: false,
    showAuthorInMediumScreen: false,
    showAuthorInSmallScreen: false,
    showDescriptionInLargeScreen: false,
    showDescriptionInMediumScreen: false,
    showDescriptionInSmallScreen: false,
    showDateInLargeScreen: false,
    showDateInMediumScreen: false,
    showDateInSmallScreen: false,
    cardProportionsLarge: { x: 1, y: 1 },
    cardProportionsMedium: { x: 1, y: 3 },
    cardProportionsSmall: { x: 1, y: 1 },
    numberOfLinesForTitle: 3,
    showImageHeightByAspectRatio: true,
    mainCategoryHorizontalGapSmall: 0,
    verticalGapSmall: 0.5,
  }, horizontalCardConfigThemeKey);

  return (
    <ErrorBoundary>
      {cards && cards.length > 0
            && (
            <div className={css(style.wrapper)}>
              <ThemeContext.Provider value={themeContextOTC}>
                <OnTopCard {...cards[0]} imageWidths={imageWidthsForMainCard} />
              </ThemeContext.Provider>
              <div className={css(style.threeHcWrapper)}>
                <ThemeContext.Provider value={themeContextHC}>
                  <HorizontalCard {...cards[1]} imageWidths={imageWidthsForMinorCard} />
                  <HorizontalCard {...cards[2]} imageWidths={imageWidthsForMinorCard} />
                  <HorizontalCard {...cards[3]} imageWidths={imageWidthsForMinorCard} />
                </ThemeContext.Provider>
              </div>
            </div>
            )}
    </ErrorBoundary>
  );
};
