import { ALIGNMENT, TypographySize } from 'mm-theme-configuration/src/consts';
import { Colors } from 'mm-theme-configuration';
import { BaseUnit } from '../../theming/baseUnitDefinition';
import { TranslatedFontSize } from '../../theming/fontSizeTranslator';

export const componentConfigurationKey = 'articleBlock';

export interface ArticleBlockThemeProps {
  baseUnit: BaseUnit;
  fontSizesStyles: TranslatedFontSize;
  colors: Colors;
  placeholderBackgroundColor: string;
  slideshowButtonBackgroundColor: string;
  slideshowButtonDisabledBackgroundColor: string;
  slideshowButtonTextColor: string;
  slideshowButtonDisabledTextColor: string;
  slideshowDividerColor: string;
  slideshowNumberColor: string;
  quoteTextColor: string;
  quoteFontSizeLarge: TypographySize;
  quoteFontSizeMedium: TypographySize;
  quoteFontSizeSmall: TypographySize;
  quoteCiteColor: string;
  quoteShowApostrophes: boolean;
  quoteShowCite: boolean;
  quoteShowIconAndBorder: boolean;
  quoteIconColor: string;
  quoteBorderColor: string;
  quoteAlignment: ALIGNMENT;
  listTitleColor: string;
  listTitleFontSizeLarge: TypographySize;
  listTitleFontSizeMedium: TypographySize;
  listTitleFontSizeSmall: TypographySize;
  listNumberColor: string;
  mainCategoryFontSizeLarge: TypographySize;
  mainCategoryFontSizeMedium: TypographySize;
  mainCategoryFontSizeSmall: TypographySize;
  mainCategoryTextColor: string;
  mainCategoryBackgroundColor: string;
  mainCategoryVerticalGapLarge: number;
  mainCategoryVerticalGapMedium: number;
  mainCategoryVerticalGapSmall: number;
  mainCategoryHorizontalGapLarge: number;
  mainCategoryHorizontalGapMedium: number;
  mainCategoryHorizontalGapSmall: number;
  breadCrumbsTextColor: string;
  breadCrumbsInactiveTextColor: string;
  breadCrumbsFontSizeLarge: TypographySize;
  breadCrumbsFontSizeMedium: TypographySize;
  breadCrumbsFontSizeSmall: TypographySize;
  paragraphTextColor: string;
  paragraphLinkColor: string;
  sponsorTextColor: string;
  dividerLineColor: string;
  dividerLogoColor: string;
  dividerShowIcon: boolean;
  sponsorWithBackgroundTextColor: string;
  sponsorBackgroundColor: string;
  creditLinkColor: string;
  h2FontSizeLarge: TypographySize;
  h2FontSizeMedium: TypographySize;
  h2FontSizeSmall: TypographySize;
  h3FontSizeLarge: TypographySize;
  h3FontSizeMedium: TypographySize;
  h3FontSizeSmall: TypographySize;
  h4FontSizeLarge: TypographySize;
  h4FontSizeMedium: TypographySize;
  h4FontSizeSmall: TypographySize;
  tocTitleColor: string;
  tocTitleFontSizeLarge: TypographySize;
  tocTitleFontSizeMedium: TypographySize;
  tocTitleFontSizeSmall: TypographySize;
  tocLinkFontSizeLarge: TypographySize;
  tocLinkFontSizeMedium: TypographySize;
  tocLinkFontSizeSmall: TypographySize;
}
