/* eslint no-underscore-dangle: [1, { "allowAfterThis": true }] */
import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { Helmet } from 'react-helmet';
import { ThemeContext } from '../../theming/ThemeProviderWithFonts';
import { globalExtension, GLOBALS } from '../../utils/aphroditeGlobalExtension';
import { normalizeCss } from './appContent.utils';
import { ErrorReporting, Reporter } from '../errorHandling/ErrorReporting';
import { MobileTopBarColor } from '../metaHeaderTags/MobileTopBarColor';
import { LARGE_SCREEN_SIZE } from '../../mediaQueries.const';

type Configuration = { [key: string]: any };

const extended = StyleSheet.extend([globalExtension]);

// This code causes aphrodite to ignore its caching system, and to create a new style object with every render
// The code is necessary due to a bug in aphrodite - the extended stylesheet seems to have issues with multiple re-renders
const potentialMemoryLeak = (): string => {
  return GLOBALS + Math.random().toString(36).substring(7);
};

const getStyles = () => StyleSheet.create({
  style: {
    maxWidth: `${LARGE_SCREEN_SIZE}px`,
    margin: 'auto',
  },
});

export const AMPAppContent: React.FunctionComponent = props => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const applyGlobalStyles = (reporter: Reporter, context: Configuration) => {
    const styles: { [GLOBALS]: any } = extended.StyleSheet.create({
      [GLOBALS]: { ...normalizeCss() },
    });
    // eslint-disable-next-line no-underscore-dangle
    styles[GLOBALS]._name = potentialMemoryLeak();

    const componentStyle = getStyles();

    const { children } = props;
    return (
      <React.Fragment>
        <Helmet>
          <body className={extended.css(styles[GLOBALS])} />
        </Helmet>
        <MobileTopBarColor />
        <div className={css(componentStyle.style)}>
          {children}
        </div>
      </React.Fragment>
    );
  };

  return (
    <ErrorReporting.Consumer>{reporter => <ThemeContext.Consumer>{context => applyGlobalStyles(reporter, context)}</ThemeContext.Consumer>}</ErrorReporting.Consumer>
  );
};
