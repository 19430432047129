import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useTheme } from '../../../theming/useTheme';
import { Link } from '../../partials/link/Link';
import { SocialButton } from '../../articleComponents/ShareMenu/ShareMenu';
import { Icon } from '../../partials/icon/Icon';
import { CopyIcon, FacebookIcon, PinterestIcon, PrintIcon, RedditIcon, TwitterIcon } from '../../partials/icon/icons';
import { AdditionalStyleSheet } from '../../components.utils';

const componentConfigThemeKey = 'socialButtons';

interface SocialButtonsThemeProps {
  backgroundColor: string;
  strokeColor: string;
}

interface SocialButtonsProps {
  shareConfig: SocialButton[];
  canonical: string;
}

const getSocialButtonsStyle = (backgroundColor: string, strokeColor: string) => StyleSheet.create({
  wrapper: {
    display: 'flex',
    gap: '15px',
  },
  circle: {
    height: '36px',
    width: '36px',
    border: `2px solid ${strokeColor}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    cursor: 'pointer',
    backgroundColor,
    ':hover': {
      backgroundColor: strokeColor,
    },
  },
});

const getIconStyle = (
  isHover: boolean,
  backgroundColor: string,
  strokeColor: string,
) => StyleSheet.create({
  style: {
    fill: isHover ? backgroundColor : strokeColor,
  },
}) as AdditionalStyleSheet;

const copyToClipboard = (text: string) => {
  const el = document.createElement('textarea');
  el.value = text;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.opacity = '0';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const SocialButtons: React.FunctionComponent<SocialButtonsProps> = props => {
  const { shareConfig, canonical } = props;
  const [hoverButton, setHoverButton] = React.useState<string | null>(null);

  const { backgroundColor, strokeColor } = useTheme<SocialButtonsThemeProps>(
    componentConfigThemeKey,
  );

  const mapTypeToIcon = (type: string) => {
    switch (type) {
      case 'facebook':
        return FacebookIcon;
      case 'twitter':
        return TwitterIcon;
      case 'reddit':
        return RedditIcon;
      case 'pinterest':
        return PinterestIcon;
      default:
        return null;
    }
  };

  const onClickCopy = () => {
    copyToClipboard(canonical);
  };

  const onClickPrint = () => {
    window.print();
    return false;
  };

  const styles = getSocialButtonsStyle(backgroundColor, strokeColor);
  const iconStyles = (type: string) => getIconStyle(hoverButton === type, backgroundColor, strokeColor);

  return (
    <div className={css(styles.wrapper)}>
      {shareConfig.map((config, index) => {
        const { type, shareURL } = config;
        return (
          <React.Fragment key={index}>
            {mapTypeToIcon(type) ? (
              <Link
                key={`${type}${index}`}
                target="_blank"
                rel="noopener noreferrer"
                className={css(styles.circle)}
                href={shareURL}
                onMouseEnter={() => setHoverButton(type)}
                onMouseLeave={() => setHoverButton('')}
              >
                <Icon
                  // @ts-ignore
                  icon={mapTypeToIcon(type)}
                  additionalStyle={iconStyles(type)}
                  hasButton={false}
                />
              </Link>
            ) : null}
          </React.Fragment>
        );
      })}
      <button
        type="button"
        className={css(styles.circle)}
        onMouseEnter={() => setHoverButton('copy')}
        onMouseLeave={() => setHoverButton('')}
        onClick={onClickCopy}
      >
        <Icon icon={CopyIcon} additionalStyle={iconStyles('copy')} hasButton={false} />
      </button>
      <button
        type="button"
        className={css(styles.circle)}
        onMouseEnter={() => setHoverButton('print')}
        onMouseLeave={() => setHoverButton('')}
        onClick={onClickPrint}
      >
        <Icon icon={PrintIcon} additionalStyle={iconStyles('print')} hasButton={false} />
      </button>
    </div>
  );
};
