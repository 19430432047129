import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { TypographySize } from 'mm-theme-configuration/dist/consts';
import { TriangleIcon, Directions } from '../TriangleIcon';
import { AdditionalStyleSheet } from '../../../../components.utils';
import { getStyleBySize } from '../../../../../typography/semanticTags/semanticTags.utils';
import { useTheme } from '../../../../../theming/useTheme';
import { RegisterLinkWithSubMenu } from './linkWithSubHeader.utils';

export interface MoreButtonProps {
  moreButtonText: string;
  additionalStyle: AdditionalStyleSheet;
  refObject: React.RefObject<HTMLButtonElement>;
  fontSizeLarge: TypographySize;
  fontSizeMedium: TypographySize;
  fontSizeSmall: TypographySize;
  colorTypeNormal: string;
  colorTypeHover: string;
  colorTypeActive: string;
  dropdownIconColor: string;
  registerLinkWithSubMenu: RegisterLinkWithSubMenu;
  index: number;
  isMenuOpen?: boolean;
}

const getStyles = (colorTypeHover: string, colorTypeNormal: string, colorTypeActive: string) => {
  return StyleSheet.create({
    button: {
      color: colorTypeNormal,
      border: 'none',
      backgroundColor: 'transparent',
      alignItems: 'center',
      cursor: 'pointer',
      ':hover': {
        color: colorTypeHover,
      },
      ':active': {
        color: colorTypeActive,
      },
    },
  });
};

export const MoreButton: React.FunctionComponent<MoreButtonProps> = props => {
  const { fontSizesStyles } = useTheme();
  const {
    moreButtonText,
    additionalStyle,
    refObject,
    fontSizeLarge,
    fontSizeMedium,
    fontSizeSmall,
    colorTypeHover,
    colorTypeNormal,
    dropdownIconColor,
    colorTypeActive,
    registerLinkWithSubMenu,
    index,
    isMenuOpen = false,
  } = props;
  const styles = getStyles(colorTypeHover, colorTypeNormal, colorTypeActive);
  const fontStyle = getStyleBySize(fontSizesStyles, { fontSizeSmall, fontSizeMedium, fontSizeLarge });
  const triangleDirection = isMenuOpen ? Directions.UP : Directions.DOWN;

  React.useEffect(() => {
    if (typeof registerLinkWithSubMenu === 'function') {
      registerLinkWithSubMenu(refObject, index, true);
    }
  }, [registerLinkWithSubMenu, index, refObject]);

  return (
    <button
      ref={refObject}
      type="button"
      className={css(styles.button, additionalStyle && additionalStyle.style, fontStyle)}
    >
      {moreButtonText}
      <TriangleIcon color={dropdownIconColor} direction={triangleDirection} />
    </button>
  );
};
