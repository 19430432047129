import * as React from 'react';
import { StyleSheet } from 'aphrodite/no-important';
import { useTheme } from '../../../theming/useTheme';
import { Iframe } from './Iframe';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { AdditionalStyleSheet, Style } from '../../components.utils';
import { IframeDataProps } from './ArticleIframe';
import { BlockWidths } from '../../components/pageLayouts/BlockTypes.utils';
import { createBlockStyle } from '../blockMutualStyle';
import { ArticleBlockThemeProps, componentConfigurationKey } from '../ArticleBlock.theme';

type StreamableDataProps = IframeDataProps & AdditionalStyleSheet;

const createStyle = (style: Style, defaultBlocksWidths: BlockWidths) => {
  return StyleSheet.create({
    style: {
      ...style,
      ...createBlockStyle(defaultBlocksWidths),
    },
  }) as AdditionalStyleSheet;
};

export const Streamable: React.FunctionComponent<StreamableDataProps> = props => {
  const { placeholderBackgroundColor } = useTheme<ArticleBlockThemeProps>(componentConfigurationKey);
  const { style, defaultBlocksWidths } = props;
  const additionalStyle = createStyle(style, defaultBlocksWidths);
  return <ErrorBoundary><Iframe {...{ backgroundColor: placeholderBackgroundColor }} {...props} additionalStyle={additionalStyle} /></ErrorBoundary>;
};
