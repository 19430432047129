import React, { useEffect } from 'react';
import { Colors } from 'mm-theme-configuration';
import { css } from 'aphrodite/no-important';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { H3 } from '../../../typography/semanticTags';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { useTheme } from '../../../theming/useTheme';
import { createStyles } from './suggestedPosts.styles';
import {
  componentConfigurationKey as SectionHeaderConfigThemeKey,
  SectionHeader,
  SectionHeaderComponentThemeProps,
} from '../../wrappers/sectionHeader/SectionHeader';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { SuggestedPostsWrapper, SuggestedPostsWrapperProps } from './SuggestedPostsWrapper';
import { ArticleLine } from './articleLine/ArticleLine';

interface SuggestedPostsProps {
    articles: Array<CardComponentDataProps>;
    blockPosition: number;
}

export type SuggestedPostsPropsType = SuggestedPostsProps & SuggestedPostsWrapperProps

export interface AnalyticsEventParams {
  action: string;
  category: string;
  label: string;
}

const getAnalyticsEventParams = (numberOfLinks: number, linkNumber: number, linkUrl: string, total_blocks: number, sourceType: string, blockPosition: number): AnalyticsEventParams => ({
  category: 'Article',
  action: `${sourceType}_recommendation_links_click`,
  label: `after_block=[${blockPosition}];total_blocks=[${total_blocks}];number_of_links=[${numberOfLinks}];clicked_link_number=[${linkNumber}];clicked_link_url=[${linkUrl}]`,
});

export const SuggestedPosts = (props: SuggestedPostsPropsType): JSX.Element => {
  const { articles, defaultBlocksWidths, dataId, total_blocks, sourceType, blockPosition } = props;

  const numberOfLinks = articles ? articles.length : 0;

  useEffect(() => {
    window.mmClientApi = window.mmClientApi || [];
    window.mmClientApi.push('analytics', {
      event_category: 'Article',
      event_action: `${sourceType}_recommendation_links_embed`,
      event_label: `after_block=[${blockPosition}];total_blocks=[${total_blocks}];number_of_links=[${numberOfLinks}]`,
    });
  }, [total_blocks, numberOfLinks, sourceType, blockPosition]);

  const suggestedPostsAnalytics = (linkNumber: number, linkUrl: string) => {
    const analyticsEventParams = getAnalyticsEventParams(numberOfLinks, linkNumber, linkUrl, total_blocks, sourceType, blockPosition);
    return () => {
      window.mmClientApi = window.mmClientApi || [];
      window.mmClientApi.push('analytics', {
        event_category: analyticsEventParams.category,
        event_action: analyticsEventParams.action,
        event_label: analyticsEventParams.label,
      });
    };
  };

  const { colors }: { colors: Colors } = useTheme();
  const styles = createStyles(colors);
  const title = 'Editor\'s Picks';

  const paddingFactors = { large: 0, medium: 0, small: 0 };

  const themeContextSectionHeader = useThemeOverride<SectionHeaderComponentThemeProps>({
    showMiddleHorizontalLine: false,
    showStrikeThrowHorizontalLine: false,
    showSquare: false,
    showBorderTop: false,
    showBorderBottom: true,
    titleColor: colors.primary,
    titleBackgroundColor: 'transparent',
    largeMarginBottomFactor: 0,
    mediumMarginBottomFactor: 0,
    smallMarginBottomFactor: 0,
    largeHorizontalGap: 0.75,
    mediumHorizontalGap: 0.75,
    smallHorizontalGap: 0.75,
    borderBottomColor: colors.lightGrey,
    borderBottomWidth: 0.05,
  }, SectionHeaderConfigThemeKey);

  return (
    <ErrorBoundary>
      <SuggestedPostsWrapper
        defaultBlocksWidths={defaultBlocksWidths}
        dataId={dataId}
        numberOfLinks={numberOfLinks}
        total_blocks={total_blocks}
        sourceType={sourceType}
        blockPosition={blockPosition}
      >
        <div className={css(styles.titleWrapper)}>
          <ThemeContext.Provider value={themeContextSectionHeader}>
            <SectionHeader moreButtonText={null} moreButtonSvg={null} paddingFactors={paddingFactors}>
              <H3>
                {title}
              </H3>
            </SectionHeader>
          </ThemeContext.Provider>
        </div>
        <ul className={css(styles.articlesWrapper)}>
          {articles.map((article, index) => {
            const analyticsEventParams = getAnalyticsEventParams(numberOfLinks, index + 1, article.articleUrl, total_blocks, sourceType, blockPosition);
            return (
              <ArticleLine
                key={article.id}
                index={index}
                article={article}
                analyticsEventParams={analyticsEventParams}
                suggestedPostsAnalytics={suggestedPostsAnalytics}
                styles={styles}
              />
            );
          })}
        </ul>
      </SuggestedPostsWrapper>
    </ErrorBoundary>
  );
};
