import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { List } from '../../partials/lists/list/List';
import { OnTopCard } from '../../partials/cards/onTopCard/OnTopCard';
import { useTheme } from '../../../theming/useTheme';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { BaseUnit } from '../../../theming/baseUnitDefinition';
import { CardComponentDataProps } from '../../partials/cards/cards.utils';
import { createImageWidths } from '../../partials/image/image.utils';
import { LayoutComponentBaseProps, getLayoutComponentPadding } from '../pageLayouts/layout.utils';

const createStyles = (baseUnit: BaseUnit) => StyleSheet.create({
  container: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    gridGap: `${baseUnit / 2}px`,
    [MEDIA_BREAKPOINTS.large]: {
      gridGap: `${baseUnit / 2}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      gridTemplateColumns: '1fr 1fr',
      gridGap: `${baseUnit / 2}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto auto',
      gridGap: `${baseUnit / 2}px`,
    },
  },
});

interface HighlightsArticleListComponentDataProps extends LayoutComponentBaseProps {
  cardsProps: CardComponentDataProps[] | null;
}

interface OnTopCardWithListSectionProps {
  baseUnit: BaseUnit;
}

const imageWidths = createImageWidths(360, 720, 720);

export const HighlightsArticleList: React.FunctionComponent<HighlightsArticleListComponentDataProps> = ({ cardsProps, paddingFactors }) => {
  const { baseUnit } = useTheme<OnTopCardWithListSectionProps>();
  const styles = createStyles(baseUnit);
  const paddingStyle = getLayoutComponentPadding(paddingFactors);
  const [onTopCardProps, ...listProps] = cardsProps || [];
  return (
    <ErrorBoundary>
      {cardsProps && cardsProps.length > 0
      && (
      <div className={css(paddingStyle, styles.container)}>
        <OnTopCard {...onTopCardProps} imageWidths={imageWidths} />
        <List listProps={listProps} />
      </div>
      )}
    </ErrorBoundary>
  );
};
