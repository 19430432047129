import { TypographySize } from 'mm-theme-configuration/src/consts';
import { BaseUnit } from '../../../../theming/baseUnitDefinition';
import { TranslatedFontSize } from '../../../../theming/fontSizeTranslator';

export const componentConfigurationKey = 'footer';

export interface FooterThemeProps {
  backgroundColor: string;
  separatorColor: string;
  ampSeparatorColor: string;
  baseUnit: BaseUnit;
  sectionPaddingFactorLargeScreen: number;
  sectionPaddingFactorMediumScreen: number;
  sectionPaddingFactorSmallScreen: number;
  bottomBarBackgroundColor: string;
  bottomBarTextColor: string;
  bottomBarLinkFontSizeLarge: TypographySize;
  bottomBarLinkFontSizeMedium: TypographySize;
  bottomBarLinkFontSizeSmall: TypographySize;
  mobileStoresTitleColor: string;
  mobileStoresTitleFontSizeLarge: TypographySize;
  mobileStoresTitleFontSizeMedium: TypographySize;
  mobileStoresTitleFontSizeSmall: TypographySize;
  socialTitleColor: string;
  socialLinksCircleColor: string;
  socialLinksIconColor: string;
  socialLinksIconHoverColor: string;
  socialTitleFontSizeLarge: TypographySize;
  socialTitleFontSizeMedium: TypographySize;
  socialTitleFontSizeSmall: TypographySize;
  linksIdleColor: string;
  linksActiveStateColor: string;
  linksHoverStateColor: string;
  linksFontSizeLarge: TypographySize;
  linksFontSizeMedium: TypographySize;
  linksFontSizeSmall: TypographySize;
  linksPaddingFactorLargeScreen: number;
  linksPaddingFactorMediumScreen: number;
  linksPaddingFactorSmallScreen: number;
  buttonFontSizeLarge: TypographySize;
  buttonFontSizeMedium: TypographySize;
  buttonFontSizeSmall: TypographySize;
  fontSizesStyles: TranslatedFontSize;
}
