import * as React from 'react';
import { ArticleTitle } from '../components/titles/ArticleTitle';
import { useTheme } from '../../theming/useTheme';
import { AuthorsList } from '../articleComponents/author/AuthorsList';

export const componentConfigurationKey = 'ampArticleTitleWrapper';

export interface AMPArticleTitleWrapperDataProps {
    title: string;
    intro: string | null;
    by: string;
    authors: AuthorsList | null;
    updatedAt: string;
    updatedAtDate: string;
    updatedAtISO: string;
    showUpdatedAt: boolean;
    createdAt: string;
    createdAtDate: string;
    createdAtISO: string;
}

export interface AMPArticleTitleWrapperThemeProps {
    showOnlyDate: boolean;
}

export const AMPArticleTitleWrapper: React.FunctionComponent<AMPArticleTitleWrapperDataProps> = props => {
  const { title, by, updatedAt, updatedAtDate, updatedAtISO, showUpdatedAt, createdAt, createdAtDate, createdAtISO, children, authors, intro } = props;
  const themeProps = useTheme<AMPArticleTitleWrapperThemeProps>(componentConfigurationKey);
  const { showOnlyDate } = themeProps;
  const createdAtTimestamp = showOnlyDate ? createdAtDate : createdAt;
  const updatedAtTimestamp = showOnlyDate ? updatedAtDate : updatedAt;
  return (
    <ArticleTitle
      title={title}
      intro={intro}
      by={by}
      createdAt={createdAtTimestamp}
      createdAtISO={createdAtISO}
      updatedAt={updatedAtTimestamp}
      updatedAtISO={updatedAtISO}
      showUpdatedAt={showUpdatedAt}
      authors={authors}
    >
      {children}
    </ArticleTitle>
  );
};
