import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useTheme } from '../../../theming/useTheme';
import { MEDIA_BREAKPOINTS } from '../../../mediaQueries.const';
import { ErrorBoundary } from '../../errorHandling/ErrorBoundary';
import { LinkImage, LinkImageProps } from './LinkImage';
import { imageLinkConfigThemeKey, LinkImageThemeProps, scrollableLinkImagesConfigThemeKey } from './LinkImage.theme';
import { ThemeContext } from '../../../theming/ThemeProviderWithFonts';
import { useThemeOverride } from '../../../theming/useThemeOverride';
import { getLayoutComponentPadding, LayoutComponentBaseProps } from '../pageLayouts/layout.utils';
import { ImageAspectRatios } from '../../partials/image/image.utils';
import { AdditionalStyleSheet } from '../../components.utils';

const colGap = '15px';

const getItemWidth = (numItems: number) => {
  switch (numItems) {
    case 5:
      return `calc((100% - ${colGap}*2)/2.5)`;
    case 4:
      return `calc((100% - ${colGap})/2)`;
    default:
      return `calc((100% - ${colGap})/1.25)`;
  }
};

const getStyles = (numItems: number) => {
  return StyleSheet.create({
    container: {
      display: 'flex',
      [MEDIA_BREAKPOINTS.small]: {
        overflowX: 'scroll',
        scrollbarWidth: 'none',
      },
      columnGap: colGap,
    },
    itemWrapper: {
      flex: '1',
      [MEDIA_BREAKPOINTS.small]: {
        width: getItemWidth(numItems),
        flex: '0 0 auto',
      },
    },
  });
};

interface ScrollableLinkImagesProps extends LayoutComponentBaseProps {
  linkImages: LinkImageProps[];
  customRatios?: ImageAspectRatios;
  additionalStyle?: AdditionalStyleSheet;
}

export const ScrollableLinkImages: React.FunctionComponent<ScrollableLinkImagesProps> = props => {
  const { linkImages = [], paddingFactors, customRatios, additionalStyle } = props;
  const scrollableLinkImagesTheme = useTheme<LinkImageThemeProps>(scrollableLinkImagesConfigThemeKey);
  const linkImageThemeOverride = useThemeOverride<LinkImageThemeProps>(scrollableLinkImagesTheme, imageLinkConfigThemeKey);
  const paddingStyle = getLayoutComponentPadding(paddingFactors);

  const styles = getStyles(linkImages.length);

  if (linkImages.length < 2) {
    return null;
  }

  return (
    <ErrorBoundary>
      <div className={css(paddingStyle, styles.container, additionalStyle?.style)}>
        {linkImages.map((linkImage, index) => (
          <div key={index} className={css(styles.itemWrapper)}>
            <ThemeContext.Provider value={linkImageThemeOverride}>
              <LinkImage customRatios={customRatios} {...linkImage} />
            </ThemeContext.Provider>
          </div>
        ))}
      </div>
    </ErrorBoundary>
  );
};
