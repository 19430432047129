import * as React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { ALIGNMENT, ALIGNMENT_OPTIONS } from 'mm-theme-configuration/dist/consts';
import { TypographySize } from 'mm-theme-configuration/src/consts';
import { CardComponentSponsoredProps } from './cards/cards.utils';
import { useTheme } from '../../theming/useTheme';
import { BaseUnit } from '../../theming/baseUnitDefinition';
import { P, semanticCSS } from '../../typography/semanticTags';
import { MEDIA_BREAKPOINTS } from '../../mediaQueries.const';

const componentConfigThemeKey = 'sponsoredTextVertical';

interface SponsoredTextVerticalThemeProps {
  sponsoredTextColor: string;
  alignment: ALIGNMENT;
  baseUnit: BaseUnit;
  sponsorFontSizeLarge: TypographySize;
  sponsorFontSizeMedium: TypographySize;
  sponsorFontSizeSmall: TypographySize;
}

const getStyles = ({ baseUnit, sponsoredTextColor, alignment = ALIGNMENT_OPTIONS.CENTER }: SponsoredTextVerticalThemeProps) => StyleSheet.create({
  wrapper: {
    textAlign: alignment === ALIGNMENT_OPTIONS.CENTER ? 'center' : 'left',
    marginTop: `${baseUnit / 2}px`,
    [MEDIA_BREAKPOINTS.large]: {
      gridRowGap: `${baseUnit / 2}px`,
      marginTop: `${baseUnit / 2}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      gridRowGap: `${baseUnit / 2}px`,
      marginTop: `${baseUnit / 2}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      gridRowGap: `${baseUnit / 4}px`,
      marginTop: `${baseUnit / 4}px`,
    },
  },
  sponsored: {
    color: sponsoredTextColor,
  },
  image: {
    marginTop: `${baseUnit / 2}px`,
    [MEDIA_BREAKPOINTS.large]: {
      height: `${baseUnit * 2}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      height: `${baseUnit * 2}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      height: `${baseUnit}px`,
    },
  },
});

const checkLogoExists = (logo: string | undefined) => typeof logo === 'string' && logo.trim() !== '';

export const SponsoredTextVertical: React.FunctionComponent<CardComponentSponsoredProps> = props => {
  const { sponsoredText, logo, brandName } = props;
  const themeProps = useTheme<SponsoredTextVerticalThemeProps>(componentConfigThemeKey);
  const styles = getStyles(themeProps);

  const hasLogo = checkLogoExists(logo);
  const text = hasLogo ? sponsoredText : `${sponsoredText} ${brandName}`;

  const { sponsorFontSizeLarge, sponsorFontSizeMedium, sponsorFontSizeSmall } = themeProps;

  return (
    <div className={css(styles.wrapper)}>
      <P fontSizeLarge={sponsorFontSizeLarge} fontSizeMedium={sponsorFontSizeMedium} fontSizeSmall={sponsorFontSizeSmall} styles={semanticCSS(styles.sponsored)}>{text}</P>
      {
        hasLogo && <img className={css(styles.image)} alt={brandName} src={`data:image/svg+xml,${encodeURIComponent(logo || '')}`} />
      }
    </div>
  );
};
